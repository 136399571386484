import React from 'react'
import * as R from 'ramda'
import { useSelector } from 'react-redux'

import { View, Paper, Text } from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import Footer from 'domains/application/components/Footer'
import Header from 'domains/application/components/Header'
import { applicationPropSelector } from 'domains/application/selectors/application'
import { APPLICATION_NAME } from 'domains/application/constants/application'
import { myOrganizationPropSelector } from 'domains/authentication/selectors/organizations'

const MESSAGE_MAP = {
  'supplier-freemiumLoginDenied': `Your free trial of the ${APPLICATION_NAME} service from IMS has ended.`,
  'district-freemiumLoginDenied': `Your free trial of the ${APPLICATION_NAME} service from IMS has ended.`,
  'supplier-orgLoginDenied': `The ${APPLICATION_NAME} Service is no longer available to your organization.`,
  'district-orgLoginDenied': `Your access to the ${APPLICATION_NAME} Service has ended.`,
}

export default function LockedOutScreen() {
  const orgType = `${useSelector((s: any) =>
    myOrganizationPropSelector('type')(s, {}),
  )}`
  const lockedOut = `${useSelector(applicationPropSelector('lockedOut'))}`
  const message = R.propOr<string, any, string>(
    '',
    `${orgType.toLowerCase()}-${lockedOut}`,
    MESSAGE_MAP,
  )

  return (
    <View>
      <DocumentTitle title="Trial has ended" />
      <Header />
      <View variant="screen">
        <Paper p={3} flexible="column" minHeight={[400, 500]} mt={3} flex={1}>
          <View flex={1} flexible="column-center">
            <Text
              variant="jumbo"
              fontSize={[36, 28]}
              textAlign="center"
              mb={2}
              data-test="locked-out-msg"
            >
              {message}
            </Text>

            <a
              href="https://www.imsglobal.org/imsmembership.html"
              target="_blank"
              rel="noopener noreferrer"
              data-test="locked-out-link"
            >
              To continue using the service your organization can join 1EdTech
              here.
            </a>
          </View>
        </Paper>

        <Footer />
      </View>
    </View>
  )
}
