import React from 'react'
import { utils } from '@ims/1edtech-frontend-common'

import ComparisonDetailsWrapper from 'domains/comparisons/components/ComparisonDetailsWrapper'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import CharacterizationIcon from 'domains/characterizations/components/CharacterizationIcon'

type CompareCharacterizationDetailsProps = {
  characterization?: ICharacterization | null
}

export default function CompareCharacterizationDetails(
  props: CompareCharacterizationDetailsProps,
) {
  const { characterization } = props
  if (!characterization) {
    return null
  }

  return (
    <ComparisonDetailsWrapper
      image={
        <CharacterizationIcon
          color={characterization.color}
          size={75}
          noResponsive={true}
        />
      }
      title={characterization.name}
      subtitle={utils.date.formatForDisplay(characterization.runDate)}
      format={characterization}
      isCharacterization={true}
    />
  )
}
