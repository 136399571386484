import React from 'react'

import { Text, View, Icon } from 'lib/components'

interface IProps {
  unavailable: boolean
}

export default function ReportUnavailable(props: IProps) {
  if (props.unavailable) {
    return (
      <View width="100%" flexible="column-center">
        <Icon className="fas fa-file" fontSize={48} mb={3} />
        <Text variant="subtitle" fontWeight={700}>
          Report Unavailable
        </Text>
      </View>
    )
  }

  return null
}
