import React from 'react'
import * as R from 'ramda'

import { View } from 'lib/components'
import { ICommonCartridgeLOMAnnotation } from 'domains/commonCartridge/models/lom/ICommonCartridgeLOMAnnotation'
import { ICommonCartridgeDate } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeDate'
import { ICommonCartridgeLanguageText } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeLanguageText'
import CCCharacterizationReportLOMDetail from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMDetail'
import CCCharacterizationReportLOMLanguageText from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMLanguageText'
import CCCharacterizationReportLOMDate from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMDate'
import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  path: string
  data: ICommonCartridgeLOMAnnotation
}

export default function CCCharacterizationReportLOMAnnotation(props: IProps) {
  const { showMissingMetadata } = React.useContext(ShowMissingMetadataContext)
  const entity = R.pathOr('', ['entity'], props.data) as string
  const date = R.pathOr({}, ['date'], props.data) as ICommonCartridgeDate
  const descriptions = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['description'],
    props.data,
  ) as ICommonCartridgeLanguageText[]

  return (
    <View>
      <CCCharacterizationReportLOMDetail
        path={`${props.path}.entity`}
        title="entity"
        value={entity}
      />

      <CCCharacterizationReportLOMDate
        path={`${props.path}.date`}
        title="date"
        data={date}
      />

      {descriptions.map((description, i) => (
        <CCCharacterizationReportLOMLanguageText
          key={`${props.path}.description.${i}`}
          path={
            !utils.hasValue(description)
              ? `${props.path}.description`
              : `${props.path}.description.${i}`
          }
          title={`description [${i + 1}]`}
          data={description}
        />
      ))}
    </View>
  )
}
