import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

export const TRUSTED_APPS_SHAREABLE_ORGS_RECORD = 'trustedAppsShareableOrgs'

const trustedAppsShareableOrgs: records.modules.IModule = {
  record: TRUSTED_APPS_SHAREABLE_ORGS_RECORD,
  responseDataKey: 'orgs',
  schema: new normalizr.schema.Entity(
    TRUSTED_APPS_SHAREABLE_ORGS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(),
  ),
  api: {
    create: () => 'trustedapps/organizations/trustedapps/share/orgsearch',
    get: (id) => `trustedapps/organizations/trustedapps/share/orgsearch/${id}`,
    getList: () => 'trustedapps/organizations/trustedapps/share/orgsearch',
    update: (id) => `trustedapps/organizations/trustedapps/share/orgsearch/${id}`,
    delete: (id) => `trustedapps/organizations/trustedapps/share/orgsearch/${id}`,
  },
}

export default trustedAppsShareableOrgs
