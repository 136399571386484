import { putRequest } from 'lib/api/api'
import { ORGANIZATIONS_RECORD } from 'lib/records/modules/organizations'
import { getRecord } from 'lib/records/workflows/getRecord'

export const setOrgLockoutStatus = async (orgId: number, lockout: boolean) => {
  const { success } = await putRequest(
    `districts/${orgId}/${lockout ? 'lockout' : 'unlock'}`,
  )

  if (success) {
    await getRecord(ORGANIZATIONS_RECORD, orgId)
  }

  return success
}
