import { OrgMembershipTypesDisplay } from 'domains/orgs/constants/organization'
import { IOrgOverrideAction } from 'domains/orgs/models/IOrgOverrideAction'

export interface IPreferredPartner {
  id: number
  name: string
  url: string
}

export type OrgSegments = 'K12' | 'HED' | 'Supplier'
export type OrgCertificationLevels =
  | 'UNCERTIFIED'
  | 'LEVEL_1'
  | 'LEVEL_2'
  | 'LEVEL_3'

export enum OrgCertificationLevelToDisplay {
  UNCERTIFIED = 'Uncertified',
  LEVEL_1 = 'Level 1',
  LEVEL_2 = 'Level 2',
  LEVEL_3 = 'Level 3',
}

export const isCCXCertified = (cert?: OrgCertificationLevels) =>
  cert === 'LEVEL_2' || cert === 'LEVEL_3'

export type OrgType = 'District' | 'Supplier' | 'IMS'

export default interface IOrg {
  id: number
  name: string
  type: OrgType
  logourl: string
  ncesId?: string
  streetAddress1: string
  streetAddress2: string
  city: string
  state: string
  country: string
  ims_id: string
  imsurl: string
  externalurl: string
  externalsitetitle: string
  membershipLevel: OrgMembershipTypesDisplay
  segment: OrgSegments
  shareComparisons: true
  lastActive?: number
  lockoutDate?: number
  referrerId?: number
  referrerName?: string
  preferredPartner: boolean
  preferrers?: IPreferredPartner
  referringOrganisation: boolean
  certificationLevel: OrgCertificationLevels
  trustedAppsLtiEnabled: boolean
  ltiPlatformId?: number
  ltiPlatformName?: string
  actions?: string[]
  overrides?: IOrgOverrideAction[]
  manuallyLockedOut?: boolean
  createdDate: number
}
