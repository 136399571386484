import React from 'react'
import clsx from 'clsx'
import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'

import {
  GRAY_REPORT_STATUS,
  UNSUPPORTED_REPORT_STATUS,
} from 'domains/reports/models/IReportStatuses'
import {
  AnyFormatType,
  ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL,
} from 'domains/formats/constants/formats'
import { ITransformedRESTServiceEndpointCharacterizationReport } from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import ReportStatusIcon from 'domains/reports/components/ReportStatusIcon'
import { PRINT_STATUS_TEXT_MAP } from 'domains/reports/components/PrintReportRestServiceHeader'

interface IProps {
  format?: AnyFormatType
  endpoint: ITransformedRESTServiceEndpointCharacterizationReport
  index: number
  'data-test'?: string
}

export default function PrintRESTEndpointReportHeader(props: IProps) {
  const { endpoint } = props
  const status =
    endpoint.enabled === false && !endpoint.failedCharacterization
      ? UNSUPPORTED_REPORT_STATUS
      : endpoint.status
  const statusText = R.pathOr('', [`${status}`], PRINT_STATUS_TEXT_MAP)
  const hasStatusText = utils.hasValue(statusText)

  const isGBPush =
    props.format === ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL
  return (
    <div
      className={clsx(
        'flex flex-row flex-1 items-center justify-between px-2 py-1',
        'bg-black text-white avoid-print-break',
        {
          'mt-3': props.index > 0,
        },
      )}
      data-test={
        props['data-test'] || `report-endpoint-header-${endpoint.leaf}`
      }
      data-status={status}
    >
      <div className="flex flex-1">
        <h2 className="text-xs font-bold">
          {endpoint.endpointName} ({endpoint.leaf})
        </h2>
      </div>

      <div className="flex flex-1 flex-row items-center">
        {utils.hasValue(endpoint.collectionSize) &&
          utils.hasValue(endpoint.sampleSize) &&
          !isGBPush && (
            <div className="flex flex-1 flex-row items-center">
              <h3 className="text-xs font-bold">
                Total Collection Size:{' '}
                <span className="font-normal">{endpoint.collectionSize}</span>
              </h3>
              <span className="mx-3 font-xl">|</span>
              <h3 className="text-xs font-bold">
                Sample Size:{' '}
                <span className="font-normal">{endpoint.sampleSize}</span>
              </h3>
            </div>
          )}
      </div>

      <div className="flex flex-row items-center">
        <ReportStatusIcon
          status={status}
          circle={status === GRAY_REPORT_STATUS}
          data-test={`${props['data-test']}-status`}
        />
        {hasStatusText && (
          <h1 className="text-xs font-bold ml-2">{statusText}</h1>
        )}
      </div>
    </div>
  )
}
