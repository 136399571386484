import React from 'react'

import { Text, View } from 'lib/components'
import ReportStatusIcon from 'domains/reports/components/ReportStatusIcon'
import { ReportStatus } from 'domains/reports/models/IReportStatuses'

interface IProps {
  label: string
  status?: ReportStatus
  circle?: boolean
  'data-test': string
}

export default function ReportStatusRow(props: IProps) {
  return (
    <View
      flexible="row-space-between"
      p={3}
      mb={2}
      bg="#f7f6fb"
      borderRadius="4px"
      data-test={props['data-test']}
    >
      <Text>{props.label}</Text>
      <ReportStatusIcon status={props.status} circle={props.circle} />
    </View>
  )
}
