import React from 'react'
import { useSelector } from 'react-redux'
import { records } from '@ims/1edtech-frontend-common'

import { USER_ROLES_RECORD } from 'lib/records/modules/userRoles'
import { RootState } from 'lib/store/rootReducer'
import IUserRole from 'domains/users/models/IUserRole'
import { ToolTip } from 'lib/components'

type Props = {
  roleId: string
}

export default function UserRoleWithDescription(props: Props) {
  const role = useSelector((s: RootState) =>
    records.entitiesSelectors.entityByIdSelector(USER_ROLES_RECORD, 'roleId')(
      s,
      props,
    ),
  ) as null | IUserRole

  if (!role) return null

  return (
    <div className="flex flex-row items-center space-x-1">
      <p className="text-sm text-text">{role.label}</p>
      <ToolTip tip={role.description}>
        <i className="fas fa-info-circle text-gray-400 text-sm" />
      </ToolTip>
    </div>
  )
}
