import React from 'react'
import * as R from 'ramda'

import { View } from 'lib/components'
import { utils } from '@ims/1edtech-frontend-common'
import { ICommonCartridgeContribute } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeContribute'
import { ICommonCartridgeLOMMetaMetadata } from 'domains/commonCartridge/models/lom/ICommonCartridgeLOMMetaMetadata'
import CCCharacterizationReportLOMCatalogEntry from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMCatalogEntry'
import CCCharacterizationReportLOMCSVList from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMCSVList'
import CCCharacterizationReportLOMContribute from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMContribute'
import CCCharacterizationReportLOMDetail from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMDetail'
import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'

interface IProps {
  data: ICommonCartridgeLOMMetaMetadata
  path: string
}

export default function CCCharacterizationReportLOMMetaMetadata(props: IProps) {
  const { showMissingMetadata } = React.useContext(ShowMissingMetadataContext)
  const identifiers = R.pathOr([], ['identifier'], props.data)
  const language = R.pathOr('', ['language'], props.data) as string
  const contributes = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['contribute'],
    props.data,
  ) as ICommonCartridgeContribute[]
  const metadataSchemas = R.pathOr(
    showMissingMetadata ? [''] : [],
    ['metadataSchema'],
    props.data,
  ) as string[]

  return (
    <View>
      {identifiers.map((identifier, i) => (
        <CCCharacterizationReportLOMCatalogEntry
          key={`${props.path}.identifier.${i}`}
          path={
            !utils.hasValue(identifier)
              ? `${props.path}.identifier`
              : `${props.path}.identifier.${i}`
          }
          title={`identifier [${i + 1}]`}
          data={identifier}
        />
      ))}

      <CCCharacterizationReportLOMDetail
        title="language"
        value={language}
        path={`${props.path}.language`}
      />

      {contributes.map((contribute, i) => (
        <CCCharacterizationReportLOMContribute
          key={`${props.path}.contribute.${i}`}
          path={
            !utils.hasValue(contribute)
              ? `${props.path}.contribute`
              : `${props.path}.contribute.${i}`
          }
          title={`contribute [${i + 1}]`}
          data={contribute}
        />
      ))}

      {utils.hasValue(metadataSchemas) && (
        <CCCharacterizationReportLOMCSVList
          path={`${props.path}.metadataSchema`}
          title="metadataSchema"
          data={metadataSchemas}
        />
      )}
    </View>
  )
}
