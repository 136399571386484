import React from 'react'
import { View } from 'lib/components'

interface IProps {
  children: any
  smTemplateColumns?: string
  mdTemplateColumns?: string
}

export default function ReportThreeTopSection(props: IProps) {
  const { smTemplateColumns = 'auto', mdTemplateColumns = 'auto' } = props
  return (
    <View
      display="grid"
      gridTemplateColumns={[
        smTemplateColumns,
        mdTemplateColumns,
        '1fr 1fr 1fr',
      ]}
      gridColumnGap={3}
      width="100%"
    >
      {props.children}
    </View>
  )
}
