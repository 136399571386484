import memoize from 'fast-memoize'

import IRESTComparisonReport from 'domains/characterizations/models/IRESTComparisonReport'
import ITransformedRESTCharacterizationReport, {
  ITransformedRESTServiceEndpointCharacterizationReport,
} from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import IRESTServiceComparisonReport from 'domains/characterizations/models/IRESTServiceComparisonReport'
import IRESTServiceEndpointComparisonReport from 'domains/characterizations/models/IRESTServiceEndpointComparisonReport'

export const transformRESTModelCharacterizationReport = memoize(
  (report: IRESTComparisonReport): ITransformedRESTCharacterizationReport => {
    return {
      comparisonId: report.comparisonId,
      runDate: report.runDate,
      unavailable: false,
      failedCharacterization: false,
      hasErrors: false,
      serviceCharacterizations: report.restComparisons.map(
        (serviceReport: IRESTServiceComparisonReport) => {
          return {
            serviceName: serviceReport.serviceName,
            status: serviceReport.status,
            failedCharacterization: false,
            endpointCharacterizations: serviceReport.endpointComparisons.map(
              (endpointReport: IRESTServiceEndpointComparisonReport) => {
                return {
                  endpointName: endpointReport.endpointName,
                  leaf: endpointReport.leaf,
                  status: endpointReport.status,
                  enabled: endpointReport.enabled,
                  notes: endpointReport.notes,
                  errorTitle: endpointReport.errorTitle,
                  errorMessage: endpointReport.errorMessage,
                  failedCharacterization: false,
                  paramCharacterizations: endpointReport.paramComparisons,
                  attributeCharacterizations:
                    endpointReport.attributeComparisons,
                } as ITransformedRESTServiceEndpointCharacterizationReport
              },
            ),
          }
        },
      ),
    }
  },
)
