import * as R from 'ramda'
import { utils, records } from '@ims/1edtech-frontend-common'

import store from 'lib/store'
import { CSV_C_MODELS_RECORD } from 'lib/records/modules/csvCModels'
import { replaceEntityProperty } from 'lib/store/entities.actions'
import {
  modelFileIndexSelector,
  modelFileColumnIndexSelector,
} from 'domains/modeling/selectors/csvCModelToSpec'
import ICSVConsumerModel, {
  ICSVConsumerFile,
  ICSVConsumerFileColumn,
} from 'domains/modeling/models/ICSVConsumerModel'

export const getFileIndexByFileName = (
  modelId: string | number,
  fileName: string | number,
): number => {
  if (R.is(String, fileName)) {
    return modelFileIndexSelector(
      records.entitiesSelectors.entityByIdSelector(
        CSV_C_MODELS_RECORD,
        utils.convertToInt(modelId),
      ),
      'fileName',
    )(store.getState(), { fileName })
  }

  return fileName as number
}

export const getColumnIndexByFileAndColumnName = (
  modelId: string | number,
  fileName: string | number,
  columnName: string | number,
): number => {
  if (R.is(String, columnName)) {
    return modelFileColumnIndexSelector(
      records.entitiesSelectors.entityByIdSelector(
        CSV_C_MODELS_RECORD,
        utils.convertToInt(modelId),
      ),
      'fileName',
      'columnName',
    )(store.getState(), { fileName, columnName })
  }

  return columnName as number
}

export const updateFileAndGetModelInState = (
  modelId: string | number,
  fileIndex: string | number,
  file: ICSVConsumerFile,
) => {
  const model = records.entitiesSelectors.entityByIdSelector(
    CSV_C_MODELS_RECORD,
    utils.convertToInt(modelId),
  )(store.getState())
  return R.over(
    R.lensPath(['files', getFileIndexByFileName(modelId, fileIndex)]),
    () => file,
    model,
  )
}

export const updateCsvCFileColumnModelInState = (
  modelId: string | number,
  fileName: string | number,
  columnName: string | number,
  column: ICSVConsumerFileColumn,
) => {
  store.dispatch(
    replaceEntityProperty(
      [
        CSV_C_MODELS_RECORD,
        modelId,
        'files',
        getFileIndexByFileName(modelId, fileName),
        'columns',
        getColumnIndexByFileAndColumnName(modelId, fileName, columnName),
      ],
      column,
    ),
  )
}

export const removeCsvCFileColumnModelInState = async (
  modelId: string | number,
  fileName: string | number,
  columnName: string | number,
) => {
  const columnIndex = getColumnIndexByFileAndColumnName(
    modelId,
    fileName,
    columnName,
  )
  const fileIndex = getFileIndexByFileName(modelId, fileName)
  const model = records.entitiesSelectors.entityByIdSelector(
    CSV_C_MODELS_RECORD,
    utils.convertToInt(modelId),
  )(store.getState())

  store.dispatch(
    replaceEntityProperty(
      [
        CSV_C_MODELS_RECORD,
        modelId,
        'files',
        getFileIndexByFileName(modelId, fileName),
        'columns',
      ],
      R.compose<
        ICSVConsumerModel,
        ICSVConsumerFile[],
        ICSVConsumerFile[],
        ICSVConsumerFile[]
      >(
        R.addIndex<ICSVConsumerFile, ICSVConsumerFile>(R.map)((column, index) =>
          R.assoc('position', index + 1, column),
        ),
        R.remove(columnIndex, 1),
        R.pathOr([], ['files', fileIndex, 'columns']),
      )(model),
    ),
  )
}
