import React from 'react'
import IProduct from 'domains/products/models/IProduct'
import { ModalWrapper } from 'lib/components'
import ModalTitle from 'lib/components/ModalTitle'
import ProductPPList from 'domains/products/components/ProductPPList'

interface IProps {
  isOpen: boolean
  closeModal: () => any
  product: IProduct
}

export default function ProductPPListModal(props: IProps) {
  if (!props.isOpen) {
    return null
  }

  return (
    <ModalWrapper
      isOpen={props.isOpen}
      title={
        <ModalTitle
          title={`@@ is also available via these Preferred Partners`}
          highlight={props.product.name}
          wordBreak="break-word"
        />
      }
      actions={[
        {
          text: 'OK',
          variant: 'start',
          onClick: props.closeModal,
          extra: { type: 'button' },
        },
      ]}
    >
      <ProductPPList product={props.product} />
    </ModalWrapper>
  )
}
