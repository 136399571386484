import React from 'react'

import IFileSetCharacterizationColumn from 'domains/characterizations/models/IFileSetCharacterizationColumn'
import { ListPlainTextCell } from 'lib/components'
import { getReportMinMaxValue } from 'domains/reports/utils/getReportMinMaxValue'

export default function CharacterizationColumnMinMaxCell(
  props: IFileSetCharacterizationColumn,
) {
  return <ListPlainTextCell>{getReportMinMaxValue(props)}</ListPlainTextCell>
}
