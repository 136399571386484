import React from 'react'
import clsx from 'clsx'

import { Icon } from 'lib/components'
import IProductFormat from 'domains/products/models/IProductFormat'
import IProduct from 'domains/products/models/IProduct'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import CompareProductDetails from 'domains/comparisons/components/CompareProductDetails'
import { getProductFormatByName } from 'domains/products/utils/products'
import { utils } from '@ims/1edtech-frontend-common'
import CompareCharacterizationDetails from 'domains/comparisons/components/CompareCharacterizationDetails'
import { AnySpecFormatType } from 'domains/formats/constants/formats'

type ComparisonDetailsProps = {
  product?: IProduct | null
  format?: AnySpecFormatType
  characterization?: ICharacterization | null | undefined
}

export default function ComparisonDetails(props: ComparisonDetailsProps) {
  return (
    <div
      className={clsx(
        'flex w-full',
        'flex-col items-center space-y-3',
        'md:flex-row md:space-x-3 md:space-y-0',
      )}
    >
      {!utils.hasValue(props.characterization) && (
        <div className="flex flex-1" />
      )}
      <CompareCharacterizationDetails
        characterization={props.characterization}
      />

      <div className="flex flex-col md:h-full items-center justify-center">
        <div className="bg-text rounded w-8 h-8 flex items-center justify-center">
          <Icon className="fas fa-plus" color="white" />
        </div>
      </div>

      {!utils.hasValue(props.product) && <div className="flex flex-1" />}
      <CompareProductDetails
        product={props.product}
        format={
          getProductFormatByName(
            props.format,
            true,
          )(props.product!) as IProductFormat
        }
      />
    </div>
  )
}
