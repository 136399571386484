export interface TrustedAppSurveyResponse {
  id: number
  responseText: string
}

export interface TrustedAppSurveyQuestion {
  id: number
  questionText: string
  questionType: TrustedAppQuestionType
  hideFromTeachers: boolean
  responses: TrustedAppSurveyResponse[]
}

// SINGLE_RESPONSE_FROM_LIST = a question where the user can only select one response from a predefined list
// MULTIPLE_RESPONSES_FROM_LIST = a question where the user can select multiple responses from a predefined list
// MULTIPLE_RESPONSES_FROM_LIST_UPDATABLE = a question where the user can select multiple responses from a predefined list that accepts new responses when answering the question.

export enum TrustedAppQuestionType {
  SINGLE_RESPONSE_FROM_LIST = 'SINGLE_RESPONSE_FROM_LIST',
  MULTIPLE_RESPONSES_FROM_LIST = 'MULTIPLE_RESPONSES_FROM_LIST',
  MULTIPLE_RESPONSES_FROM_LIST_UPDATABLE = 'MULTIPLE_RESPONSES_FROM_LIST_UPDATABLE',
}

export interface GetTrustedAppSurveyQuestionsResponse {
  questions: {
    id: number
    questionText: string
    questionType: TrustedAppQuestionType
    hideFromTeachers: boolean
    responses?: {
      id: number
      responseText: string
    }[]
  }[]
}

export interface UpdateTrustedAppSurveyQuestionsRequest {
  questions: {
    id?: number
    questionText: string
    questionType: TrustedAppQuestionType
    hideFromTeachers: boolean
    responses: {
      id?: number
      responseText: string
    }[]
  }[]
}

export type UpdateTrustedAppSurveyQuestionsResponse =
  GetTrustedAppSurveyQuestionsResponse
