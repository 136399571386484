import React from 'react'
import * as R from 'ramda'
import { Formik, FormikHelpers, FormikProps, Form } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'

import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import { Button, FormInput } from 'lib/components'
import { showToast } from 'lib/utils/toast'
import ILTIConfiguration from 'domains/trustedApps/models/ILTIConfiguration'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import CopyableUrls from 'lib/components/CopyableUrls'

import { updateRecord } from 'lib/records/workflows/updateRecord'
import { deleteRecord } from 'lib/records/workflows/deleteRecord'
import { LTI_CONFIGURATIONS_RECORD } from 'lib/records/modules/ltiConfigurations'
import { utils } from '@ims/1edtech-frontend-common'
import { isLTIISSAndClientIdAvailable } from 'domains/trustedApps/workflows/isLTIISSAndClientIdAvailable'
import { SWITCHBOARD_BASE_URL } from 'lib/api/constants'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Connection name is required'),
  clientId: Yup.string().required('Client Id is required'),
  iss: Yup.string().required('ISS Id is required'),
  authServerUrl: Yup.string()
    .url('OIDC URL must be a valid URL')
    .required('OIDC URL is required'),
  jwksUrl: Yup.string()
    .url('JWKS URL must be a valid URL')
    .required('JWKS URL is required'),
})
const inputLabelProps = {
  overrideTextSize: true,
  className: 'text-sm',
}

interface IProps {
  height: number
  ltiConfiguration: ILTIConfiguration
  onUpdate: () => void
}

export default function TrustedAppsConnectionWidget(props: IProps) {
  const { height, ltiConfiguration, onUpdate } = props
  const orgId = useSelector((s: any) => myOrganizationIdSelector(s, true))

  const onDelete = async () => {
    const success = await deleteRecord(
      LTI_CONFIGURATIONS_RECORD,
      ltiConfiguration.name,
      { orgId },
    )
    if (success) {
      showToast('start', 'Connection deleted successfully')
      onUpdate()
    } else showToast('error', 'Error deleting connection')
  }

  let validateTimeout: any
  const onValidate = (values: ILTIConfiguration) =>
    new Promise((resolve) => {
      let errors = {}
      if (
        utils.hasValue(values.clientId) &&
        utils.hasValue(values.iss) &&
        (values.clientId !== ltiConfiguration.clientId ||
          values.iss !== ltiConfiguration.iss)
      ) {
        clearTimeout(validateTimeout)
        validateTimeout = setTimeout(async () => {
          const isAvailable = await isLTIISSAndClientIdAvailable(
            orgId,
            values.iss,
            values.clientId,
          )
          if (!isAvailable) {
            errors = R.assoc(
              'clientId',
              'This combination of Client ID and Issuer ID is already taken',
              errors,
            )
          }

          resolve(errors)
        }, 350)
      } else {
        resolve(errors)
      }
    })

  const onSave = async (
    values: ILTIConfiguration,
    bag: FormikHelpers<ILTIConfiguration>,
  ) => {
    const { success } = await updateRecord(
      LTI_CONFIGURATIONS_RECORD,
      ltiConfiguration.name,
      {
        ...values,
        orgId,
      },
    )
    if (success) {
      showToast(
        'start',
        'Successfully updated your TrustEd Apps LTI Configuration',
      )
      onUpdate()
    } else {
      showToast('error', 'Failed to update TrustEd Apps LTI Configuration')
    }
    bag.setSubmitting(false)
  }

  const configName = R.pathOr('', ['name'], props.ltiConfiguration)
  const clientId = R.pathOr('', ['clientId'], props.ltiConfiguration)
  const iss = R.pathOr('', ['iss'], props.ltiConfiguration)
  const authServerUrl = R.pathOr('', ['authServerUrl'], props.ltiConfiguration)
  const jwksUrl = R.pathOr('', ['jwksUrl'], props.ltiConfiguration)

  return (
    <DetailsTopWidget
      title={configName}
      dataTest="trustedapps-connection-widget"
      height={height}
    >
      <Formik
        initialValues={{
          name: configName,
          clientId,
          iss,
          authServerUrl,
          jwksUrl,
        }}
        validationSchema={validationSchema}
        onSubmit={onSave}
        validate={onValidate}
      >
        {(bag: FormikProps<ILTIConfiguration>) => {
          return (
            <Form
              className="flex flex-col h-full pt-2"
              onSubmit={bag.handleSubmit}
            >
              <div className="flex flex-1 flex-col">
                <div className="space-y-2">
                  <CopyableUrls
                    label="TrustEd Apps OIDC Login initiation URL"
                    value={`${SWITCHBOARD_BASE_URL}/lti/initialize`}
                    allowCopy
                  />
                  <CopyableUrls
                    label="TrustEd Apps Launch URL"
                    value={`${SWITCHBOARD_BASE_URL}/trustedapps/lti/login`}
                    allowCopy
                  />
                  <CopyableUrls
                    label="TrustEd Apps Public JWK URL"
                    value="https://oauth2server.imsglobal.org/jwks"
                    allowCopy
                    optional
                  />
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 mt-4">
                  <FormInput
                    label="Name"
                    required={true}
                    name="name"
                    handleChange={bag.handleChange}
                    value={bag.values.name}
                    valueDataTest="name"
                    placeholder="Connection Name(e.g. Canvas, Moodle, etc)"
                    aria-label="LTI Configuration Name"
                    inputLabelProps={inputLabelProps}
                    showRequiredOnlyOnSumbit={true}
                  />
                  <FormInput
                    label="Client ID"
                    required={true}
                    name="clientId"
                    handleChange={bag.handleChange}
                    value={bag.values.clientId}
                    valueDataTest="clientId"
                    placeholder="client_id"
                    aria-label="LTI Config Client Id"
                    inputLabelProps={inputLabelProps}
                    showRequiredOnlyOnSumbit={true}
                  />
                  <FormInput
                    label="Issuer ID (iss)"
                    required={true}
                    name="iss"
                    handleChange={bag.handleChange}
                    value={bag.values.iss}
                    valueDataTest="iss"
                    placeholder="issuer"
                    aria-label="LTI Config Client Id"
                    inputLabelProps={inputLabelProps}
                    showRequiredOnlyOnSumbit={true}
                  />
                </div>

                <FormInput
                  label="OIDC URL"
                  required={true}
                  name="authServerUrl"
                  handleChange={bag.handleChange}
                  value={bag.values.authServerUrl}
                  valueDataTest="authServerUrl"
                  placeholder="https://youroidcurl.net"
                  aria-label="LTI Config OIDC Url"
                  inputLabelProps={inputLabelProps}
                />

                <FormInput
                  label="JWKS URL"
                  required={true}
                  name="jwksUrl"
                  handleChange={bag.handleChange}
                  value={bag.values.jwksUrl}
                  valueDataTest="jwksUrl"
                  placeholder="https://yourjwksurl.net"
                  aria-label="LTI Config JWKS Url"
                  inputLabelProps={inputLabelProps}
                />
              </div>

              <div className="flex flex-1 flex-col justify-end mt-2">
                <div className="flex flex-row justify-end p-4">
                  <Button
                    type="button"
                    onClick={onDelete}
                    data-test="delete-connection"
                    variant="error"
                    className="mx-4"
                  >
                    Delete
                  </Button>
                  <Button
                    data-test="save-lti-config-btn"
                    type="submit"
                    pending={bag.isSubmitting}
                    variant="complete"
                  >
                    Update
                  </Button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </DetailsTopWidget>
  )
}
