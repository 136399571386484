import React from 'react'
import IProductFormat from 'domains/products/models/IProductFormat'
import {
  AnySpecFormatType,
  FORMAT_TO_DISPLAY_FORMAT_BASE_NAME,
} from 'domains/formats/constants/formats'
import { Button, View } from 'lib/components'

interface IProps extends IProductFormat {
  onClick: (format: AnySpecFormatType) => any
}

export default function DistrictProductFormatCompareCell(props: IProps) {
  const onClick = () => props.onClick(props.format)

  return (
    <View flexible="row-h-end">
      <Button
        variant="complete"
        onClick={onClick}
        data-test={`product-format-${
          FORMAT_TO_DISPLAY_FORMAT_BASE_NAME[props.format]
        }-compare`}
      >
        Compare
      </Button>
    </View>
  )
}
