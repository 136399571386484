import * as Yup from 'yup'

export const validatePassword = Yup.string()
  .required('Required')
  .min(8, 'Password is too short - min 8 characters')
  .matches(/\d+/, {
    message: 'Password must contain at least one number',
    excludeEmptyString: true,
  })
  .matches(/[a-z]+/, {
    message: 'Password must contain at least one lower case letter',
    excludeEmptyString: true,
  })
  .matches(/[A-Z]+/, {
    message: 'Password must contain at least one upper case letter',
    excludeEmptyString: true,
  })
  .matches(/[*+<>!&#\-$_@%]+/, {
    message:
      'Password must contain at least one special character from the following *+<>!&#-$_@%',
    excludeEmptyString: true,
  })
  .matches(/^[A-Za-z0-9*+<>!&#\-$_@%]+$/, {
    message:
      'Password can only contain alphanumeric characters and any of the following special characters *+<>!&#-$_@%',
    excludeEmptyString: true,
  })
