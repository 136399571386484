import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'
import * as R from 'ramda'

export const TRUSTED_APPS_RATINGS_RECORD = 'trustedAppsRatings'

const trustedAppsRatings: records.modules.IModule = {
  record: TRUSTED_APPS_RATINGS_RECORD,
  schema: new normalizr.schema.Entity(
    TRUSTED_APPS_RATINGS_RECORD,
    {},
    {
      idAttribute: () => '1',
      processStrategy: R.assoc('id', 1),
    },
  ),
  api: {
    create: () => 'trustedapps/organizations/applications/config/ratings',
    get: () => 'trustedapps/organizations/applications/config/ratings',
    getList: () => 'trustedapps/organizations/applications/config/ratings',
    update: () => 'trustedapps/organizations/applications/config/ratings',
    delete: () => 'trustedapps/organizations/applications/config/ratings',
  },
}

export default trustedAppsRatings
