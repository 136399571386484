import React from 'react'

import { View, Text, ToolTip } from 'lib/components'
import IComparison from 'domains/comparisons/models/IComparison'

type ComparisonRosteringMethodCellProps = {
  maxWidth?: string
  maxWidthArray?: (string | number)[]
}

export default function ComparisonRosteringMethodCell(
  props: ComparisonRosteringMethodCellProps & IComparison,
) {
  const {
    id,
    rosteringTool,
    rosteringToolName,
    maxWidth,
    maxWidthArray = [100, 220],
  } = props

  if (!rosteringTool) {
    return <Text fontSize={13}>Direct</Text>
  }

  return (
    <View flexible="row-v-center">
      <ToolTip
        id={`${id}-rostering-method`}
        tip={rosteringToolName}
        delay={250}
      >
        <Text
          textAlign="left"
          overflow="ellipsis"
          maxWidth={maxWidth || maxWidthArray}
          fontSize={13}
        >
          {rosteringToolName}
        </Text>
      </ToolTip>
    </View>
  )
}
