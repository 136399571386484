import React from 'react'

import IFileSetCharacterizationColumn from 'domains/characterizations/models/IFileSetCharacterizationColumn'
import { View, ToolTip, ListPlainTextCell, Icon } from 'lib/components'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'
import { REPORT_POPULATION_ICON_MAP } from 'domains/reports/constants/populationIcons'
const isPrint = isOnPrintScreen()

interface IProps extends IFileSetCharacterizationColumn {
  fileLabel: string
}

export default function CharacterizationColumnPopulationCell(props: IProps) {
  const { fileLabel, headerName, warning, population } = props
  if (isPrint) {
    const color = warning ? 'secondary' : 'text'
    return (
      <ListPlainTextCell color={color} fontWeight={warning ? 700 : 400}>
        {population}
        {warning && (
          <Icon
            ml={1}
            className={REPORT_POPULATION_ICON_MAP[population!]}
            color={color}
            fontSize={11}
          />
        )}
      </ListPlainTextCell>
    )
  }

  return (
    <ToolTip id={`${fileLabel}-${headerName}-population`} tip={warning}>
      <View
        bg={warning ? 'secondary' : ''}
        borderRadius={4}
        px={1}
        py="12px"
        maxWidth={100}
      >
        <ListPlainTextCell
          color={warning ? 'white' : 'text'}
          fontWeight={warning ? 700 : 400}
        >
          {population}
        </ListPlainTextCell>
      </View>
    </ToolTip>
  )
}
