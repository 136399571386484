import { createSelector } from 'reselect'
import { records } from '@ims/1edtech-frontend-common'

import { RootState } from 'lib/store/rootReducer'
import { SPECIFICATIONS_RECORD } from 'lib/records/modules/specifications'
import { SPECIFIC_TO_FULL_FORMAT_MAP } from 'domains/formats/constants/formats'
import ICSVSpecification from 'domains/specifications/models/ICSVSpecification'

export const fullSpecificationSelector = (
  formatSelector: (state: RootState, props: any) => string,
) =>
  createSelector<RootState, any, string, any, ICSVSpecification>(
    formatSelector,
    records.entitiesSelectors.entityListSelector(SPECIFICATIONS_RECORD),
    (format, specifications) => {
      const fullFormatName = SPECIFIC_TO_FULL_FORMAT_MAP[format]
      return specifications[fullFormatName]
    },
  )
