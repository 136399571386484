import React from 'react'
import * as R from 'ramda'
import { utils, records } from '@ims/1edtech-frontend-common'
import { useSelector } from 'react-redux'

import { Dropdown, SearchBox, Menu } from 'lib/components'
import { getRecords } from 'lib/records/workflows/getRecords'
import View from 'lib/components/View'

interface IProps {
  MenuItemComponent: any
  menuItemProps?: any
  onItemSelected: (item: any) => any
  onCleared?: () => any
  recordParams?: any
  record: string
  parentRecordId?: any
  selectedName: string
  autoFocus?: boolean
  placeholder?: string
  'aria-label'?: string
  noHoverRemove?: boolean
  fontSize?: string | number | (string | number)[]
  padding?: string | number | (string | number)[]
  'data-test'?: string
}

export default function SearchDropdownMenu(props: IProps) {
  const searchBox = React.useRef<any>()
  const [loading, setLoading] = React.useState(false)
  const [focused, setFocused] = React.useState(false)
  const [search, setSearch] = React.useState('')
  const [page, setPage] = React.useState(1)
  const recordData = useSelector((state: any) =>
    props.parentRecordId
      ? records.relatedRecordsSelectors.fullRelatedRecordsSelector(
          props.record,
          props.parentRecordId,
        )(state, props)
      : records.recordsSelectors.fullRecordsSelector(props.record)(
          state,
          props,
        ),
  )

  const onGetRecords = React.useCallback(async () => {
    setLoading(true)
    let filter = R.pathOr('', ['filter'], props.recordParams)
    if (utils.hasValue(filter) && utils.hasValue(search)) {
      filter = filter.replace('@@', search)
    } else if (!utils.hasValue(search)) {
      filter = ''
    }
    const usePage = utils.hasValue(search) ? 1 : page
    await getRecords({
      record: props.record,
      force: usePage === 1,
      params: {
        ...(props.recordParams || {}),
        filter,
      },
      page: usePage,
      parentRecordId: props.parentRecordId,
    })
    setLoading(false)
  }, [page, props.recordParams, props.parentRecordId, props.record, search])

  const onCleared = async () => {
    await onGetRecords()
    if (props.onCleared) {
      props.onCleared()
    }
  }

  const onFocused = () => setFocused(true)

  const onBlurred = () => {
    setTimeout(() => {
      setFocused(false)
    }, 200)
  }

  React.useEffect(() => {
    onGetRecords()
  }, []) // eslint-disable-line

  React.useEffect(() => {
    const setSearchValue = R.pathOr<(name: string) => any>(
      () => null,
      ['current', 'setValue'],
      searchBox,
    )
    if (setSearchValue) {
      setSearchValue(props.selectedName)
    }
  }, [props.selectedName])

  const onSearch = (s: string) => {
    setSearch(s)
    if (!utils.hasValue(s)) {
      setPage(1)
    }
  }

  const onScroll = (e: any) => {
    const element = e.target
    if (
      element.scrollHeight - element.scrollTop - element.clientHeight < 200 &&
      utils.hasValue(recordData) &&
      R.pathOr(false, ['paging', 'nextPage'], recordData) !== false &&
      !loading
    ) {
      setPage(page + 1)
    }
  }

  React.useEffect(() => {
    onGetRecords()
  }, [page, search]) // eslint-disable-line

  let menu: any = null
  if (focused) {
    menu = (
      <View onScroll={onScroll} flexible="column-center" bg="white">
        <Menu
          items={recordData.items}
          itemData={props.menuItemProps || {}}
          component={props.MenuItemComponent}
          onClick={props.onItemSelected}
          loadingMore={loading}
        />
      </View>
    )
  }

  return (
    <Dropdown
      target={
        <SearchBox
          ref={searchBox}
          width="100%"
          itemSelected={utils.hasValue(props.selectedName)}
          noIcon={true}
          placeholder={props.placeholder}
          aria-label={props['aria-label'] || 'Search'}
          autoFocus={props.autoFocus}
          noHover={props.noHoverRemove}
          onChange={onSearch}
          onCleared={onCleared}
          onBlur={onBlurred}
          onFocus={onFocused}
          fontSize={props.fontSize}
          padding={props.padding}
          data-test={props['data-test']}
        />
      }
      targetProps={{ width: '100%' }}
      constrainTo="window"
      disableToggleClose={true}
    >
      {menu}
    </Dropdown>
  )
}
