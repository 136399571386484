import React from 'react'
import { Link } from 'react-router-dom'

import { Text, ToolTip } from 'lib/components'
import IProduct from 'domains/products/models/IProduct'
import ProductImageCell from 'domains/products/components/ProductImageCell'
import { getProductRoute } from 'domains/products/navigation/routes'

export default function DistrictProductCatalogNameCell(props: IProduct) {
  return (
    <div className="flex flex-row items-center">
      <div className="mx-2">
        <ProductImageCell {...props} size={50} />
      </div>
      <Link to={getProductRoute(props.id, props.format)}>
        <ToolTip id={`${props.id}-name`} tip={props.name} delay={250}>
          <Text
            textAlign="left"
            color="tertiary"
            overflow="ellipsis"
            maxWidth={[80, 130, 180, 230]}
            fontWeight={500}
          >
            {props.name}
          </Text>
        </ToolTip>
      </Link>
    </div>
  )
}
