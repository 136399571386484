import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

import IRestCModelServiceEndpoint from 'domains/modeling/models/IRestCModelServiceEndpoint'
import { findModelServiceByParentEndpointName } from 'domains/modeling/utils/restCOR1p1utils'
import { REST_C_MODELS_RECORD } from 'lib/records/modules/restCModels'
import store from 'lib/store'
import IRestCModel from 'domains/modeling/models/IRestCModel'
import { IServicesChildEndpoints } from 'domains/modeling/models/IServicesChildEndpoints'
import { SERVICE_PARENT_ENDPOINT_MAP } from 'domains/modeling/constants/restCOneRoster1p1'
import IRestCModelServiceEndpointParam from 'domains/modeling/models/IRestCModelServiceEndpointParam'
import { deleteProductCharacterization } from 'domains/characterizations/workflows/deleteProductCharacterization'
import { formatNameByIdSelector } from 'domains/specifications/selectors/formatSelectors'
import { AnySpecFormatType } from 'domains/formats/constants/formats'
import { trackRestCModelingAnalytics } from 'domains/modeling/utils/trackModelingAnalytics'
import { updateRestCModel } from 'domains/modeling/workflows/updateRestCModel'
import IRestCModelServiceEndpointAttribute from 'domains/modeling/models/IRestCModelServiceEndpointAttribute'

export const updateRestCServices = async (
  modelId: number,
  endpoints: IRestCModelServiceEndpoint[],
  childEndpoints: IServicesChildEndpoints,
  isReset?: boolean,
) => {
  let model = records.entitiesSelectors.entityByIdSelector(
    REST_C_MODELS_RECORD,
    modelId,
  )(store.getState()) as IRestCModel

  const formatName = formatNameByIdSelector(model.formatId)(store.getState())
  await deleteProductCharacterization(
    model.productId,
    formatName as AnySpecFormatType,
  )

  const servicesUpdated: string[] = []
  for (const endpoint of endpoints) {
    const service = findModelServiceByParentEndpointName(model, endpoint.name)!
    const serviceIndex = R.findIndex(
      R.propEq('name', service.name),
      model.services,
    )
    servicesUpdated.push(service.name)
    const parentEndpointName = SERVICE_PARENT_ENDPOINT_MAP[service.name]
    for (let i = 0; i < service.endpoints.length; i++) {
      const serviceEndpoint = service.endpoints[i]
      const updates = R.pick(['notes', 'attributes'], endpoint)
      const enabled =
        serviceEndpoint.name === parentEndpointName || isReset
          ? endpoint.enabled
          : childEndpoints[parentEndpointName][serviceEndpoint.name].enabled

      const params = endpoint.params.reduce((agg, param) => {
        const existingParam = R.find(
          R.propEq('name', param.name),
          serviceEndpoint.params,
        )
        if (existingParam) {
          return [
            ...agg,
            R.assoc(
              'id',
              (existingParam as IRestCModelServiceEndpointParam).id,
              param,
            ) as IRestCModelServiceEndpointParam,
          ]
        }
        return agg
      }, [] as IRestCModelServiceEndpointParam[])
      const attributes = endpoint.attributes.reduce(
        (agg, attribute, attributeIndex) => {
          const existingAttribute = R.has('id', attribute)
            ? serviceEndpoint.attributes[attributeIndex]
            : R.find(
                (
                  serviceEndpointAttribute: IRestCModelServiceEndpointAttribute,
                ) => {
                  if (serviceEndpointAttribute._internalId) {
                    return (
                      R.propOr('', '_internalId', serviceEndpointAttribute) ===
                      attribute._internalId
                    )
                  }
                  return R.propEq(
                    'name',
                    attribute.name,
                    serviceEndpointAttribute,
                  )
                },
                serviceEndpoint.attributes,
              )
          if (existingAttribute) {
            return [
              ...agg,
              R.assoc(
                'id',
                existingAttribute.id,
                attribute,
              ) as IRestCModelServiceEndpointAttribute,
            ]
          }
          return [
            ...agg,
            R.dissoc(
              '_internalId',
              attribute,
            ) as IRestCModelServiceEndpointAttribute,
          ]
        },
        [] as IRestCModelServiceEndpointAttribute[],
      )

      const updatedEndpoint = {
        ...serviceEndpoint,
        ...updates,
        id: serviceEndpoint.id,
        params,
        attributes,
        enabled,
        touched: !isReset,
      }
      model = R.assocPath(
        ['services', serviceIndex, 'endpoints', i],
        updatedEndpoint,
        model,
      )
    }
  }

  await updateRestCModel(modelId, model)

  await trackRestCModelingAnalytics('saved_model_services', {
    services: servicesUpdated,
  })
}
