import React from 'react'
import clsx from 'clsx'

import { Text } from 'lib/components'
import FormatLineItems from 'domains/modeling/components/FormatLineItems'
import IProductFormat from 'domains/products/models/IProductFormat'
import { utils } from '@ims/1edtech-frontend-common'

type ComparisonDetailsWrapperProps = {
  image: any
  title: string
  subtitle: string
  tertiary?: any
  format?: IProductFormat
  isCharacterization?: boolean
}

export default function ComparisonDetailsWrapper(
  props: ComparisonDetailsWrapperProps,
) {
  const { image, title, subtitle, tertiary, format, isCharacterization } = props
  const type = isCharacterization ? 'characterization' : 'product'
  return (
    <div className="bg-background rounded p-4 h-full flex flex-1 flex-col items-center">
      <div
        className={clsx('flex flex-col items-center', {
          'mb-2': tertiary,
          'mb-4': !tertiary,
        })}
      >
        {image}
        <Text
          mt={3}
          textAlign="center"
          variant="subtitle"
          fontWeight={700}
          overflow="ellipsis"
          maxWidth="172px"
          data-test={`comparison-details-${type}-title`}
        >
          {title}
        </Text>
        <Text
          textAlign="center"
          data-test={`comparison-details-${type}-subtitle`}
        >
          {subtitle}
        </Text>
        {tertiary}
      </div>

      {utils.hasValue(format) && (
        <div className="bg-background w-full">
          <FormatLineItems
            format={format!}
            dataTest={`comparison-format-${
              isCharacterization ? 'characterization' : 'product'
            }`}
            hideType={true}
          />
        </div>
      )}
    </div>
  )
}
