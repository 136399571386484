import React from 'react'

import { Icon, Span } from 'lib/components'

interface IProps {
  color: string
  size?: number
  'data-test'?: string
}
export default function CharacterizationErrorDot(props: IProps) {
  const { size = 25, color } = props
  return (
    <Icon
      className="fas fa-circle"
      fontSize={size}
      color={color}
      data-test={props['data-test']}
    >
      <Span fontSize={0}>*</Span>
    </Icon>
  )
}
