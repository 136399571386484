import React from 'react'
import { utils, records } from '@ims/1edtech-frontend-common'

import { useSelector } from 'react-redux'
import { COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_ITEMS_RECORD } from 'lib/records/modules/commonCartridgeCharacterizationReportItems'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import CCCharacterizationReportItemRow from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportItemRow'
import { ICommonCartridgeItem } from 'domains/commonCartridge/models/ICommonCartridgeItem'
import { View } from 'lib/components'

interface IProps {
  id: number
  characterization: ICharacterization
}

export default function CCCharacterizationReportTableItemRow(props: IProps) {
  const item = useSelector(
    records.entitiesSelectors.entityByIdSelector(
      COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_ITEMS_RECORD,
      utils.convertToInt(props.id),
    ),
  ) as ICommonCartridgeItem

  return (
    <View mb={2}>
      <CCCharacterizationReportItemRow
        characterization={props.characterization}
        item={item}
      />
    </View>
  )
}
