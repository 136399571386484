import React from 'react'
import clsx from 'clsx'
import { CheckIcon } from '@heroicons/react/solid'

export type SelectOptionProps = {
  leftContent?: (active: boolean) => any
  centered?: boolean
  label: string
  description?: string
  value: any
  parent?: boolean
  parentId?: any
  small?: boolean
}

type Props = SelectOptionProps & {
  selected: boolean
  active: boolean
  onSelected: (value: any) => any
  OptionComponent?: (
    props: SelectOptionProps & {
      active: boolean
      disabled: boolean
      parent?: boolean
    },
  ) => any
  disabled?: boolean
}

export default function SelectOption({
  selected,
  leftContent,
  centered = false,
  label,
  description,
  value,
  parent = false,
  parentId,
  onSelected,
  active,
  OptionComponent,
  disabled = false,
  small = false,
}: Props) {
  const isChild = !!parentId
  const ref = React.useRef<HTMLDivElement>(null)

  const onClick = () => {
    if (!parent && !disabled) onSelected(value)
  }

  React.useEffect(() => {
    if (active && ref.current) ref.current.scrollIntoView({ block: 'nearest' })
  }, [active])

  if (!OptionComponent) {
    return (
      <div
        className={clsx('cursor-default select-none relative', {
          'text-gray-900 bg-white': !active && !selected && !disabled,
          'text-gray-700': !active && !selected && !disabled,
          'text-white bg-primary': active && !disabled,
          'text-gray-400 cursor-not-allowed': disabled,
          'text-gray-400': disabled,
          'py-2 pl-3 pr-9': !small,
          'py-1 pl-2 pr-7': small,
        })}
        onClick={onClick}
        ref={ref}
        data-test={`select-option-${label.replace(/"/g, '')}`}
      >
        <div className={clsx('flex flex-col')}>
          <div
            className={clsx('flex flex-row items-center space-x-2', {
              'justify-center': centered,
            })}
          >
            {!!leftContent && leftContent(active)}
            <span
              className={clsx('block', {
                'font-semibold': selected || parent,
                'font-normal': !selected,
                'pl-3': !small && isChild,
                'pl-2': small && isChild,
                'text-base': !small,
                'text-xs': small,
              })}
            >
              {label}
            </span>
          </div>
          {!!description && (
            <p
              className={clsx('block text-xs', {
                'pl-3': !small && isChild,
                'pl-2': small && isChild,
                'text-gray-500': !active,
              })}
            >
              {description}
            </p>
          )}
        </div>

        {selected ? (
          <span
            className={clsx(
              'absolute inset-y-0 right-0 flex items-center pr-4',
              {
                'text-primary-500': !active,
                'text-white': active && !disabled,
              },
            )}
          >
            <CheckIcon className="h-5 w-5" aria-hidden="true" />
          </span>
        ) : null}
      </div>
    )
  }

  return (
    <div onClick={onClick} className="cursor-default" ref={ref}>
      <OptionComponent
        label={label}
        value={value}
        active={active}
        disabled={!!disabled}
        parent={parent}
      />
    </div>
  )
}
