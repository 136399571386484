import { putRequest } from 'lib/api/api'
import { UpdateTrustedAppSurveyQuestionsRequest } from '../models/ITrustedAppsCustomQuestions'
import { useState } from 'react'

const updateQuestions = async (
  data: UpdateTrustedAppSurveyQuestionsRequest,
) => {
  await putRequest(
    'trustedapps/organizations/applications/config/surveys',
    data,
  )
}

export const useUpdateCustomQuestions = () => {
  const [pending, setPending] = useState(false)

  const update = async (data: UpdateTrustedAppSurveyQuestionsRequest) => {
    setPending(true)
    try {
      await updateQuestions(data)
    } catch (error) {
      throw error
    } finally {
      setPending(false)
    }
  }

  return [update, pending] as const
}
