import React from 'react'
import { utils } from '@ims/1edtech-frontend-common'

import {
  Text,
  Span,
  Select,
  View,
  FileInput,
  FormInput,
  FormErrorMessage,
} from 'lib/components'
import { ZipFileSources } from 'domains/characterizations/constants/createCharacterizationCSV'

interface IProps {
  zipFile: any
  zipFileURL?: string
  zipFileSource?: ZipFileSources
  username: string
  password: string
  handleChange: (event: any) => void
  setFieldValue: (key: string, value: any) => void
}

export default function CharacterizationFileProvider(props: IProps) {
  const onFileSelected = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      props.setFieldValue('zipFile', e.currentTarget.files[0])
    }
  }

  const renderUploadOption = () => {
    if (props.zipFileSource !== 'upload') {
      return null
    }

    return (
      <View mt={4}>
        <Text fontWeight={700} variant="subtitle" mb={2}>
          File Name<Span color="error">*</Span>
        </Text>
        <View flexible="row-wrap">
          <View mr={2}>
            <FileInput
              text="Browse"
              variant="complete"
              name="zipFile"
              onChange={onFileSelected}
              aria-label="Characterization upload a file"
            />
          </View>
          {utils.hasValue(props.zipFile) && (
            <Text fontWeight={700} overflow="ellipsis" mt={2}>
              {utils.string.ellipsize(props.zipFile.name, 50)}
            </Text>
          )}
        </View>
        <FormErrorMessage name="zipFile" />
      </View>
    )
  }

  const renderURLOption = () => {
    if (props.zipFileSource !== 'url') {
      return null
    }

    return (
      <View mt={3}>
        <FormInput
          label="URL"
          required={true}
          name="zipFileURL"
          placeholder="https://example.com/files/file.zip"
          value={props.zipFileURL || ''}
          valueDataTest="zipFileURL-input"
          handleChange={props.handleChange}
        />

        <View
          display="grid"
          gridTemplateColumns={'1fr 1fr'}
          gridColumnGap={2}
          width="100%"
          mt={3}
        >
          <FormInput
            label="User Name"
            required={true}
            name="username"
            placeholder="username"
            value={props.username || ''}
            valueDataTest="username-input"
            handleChange={props.handleChange}
          />
          <FormInput
            label="Password"
            required={true}
            name="password"
            placeholder="password"
            value={props.password || ''}
            valueDataTest="password-input"
            handleChange={props.handleChange}
            type="password"
          />
        </View>
      </View>
    )
  }

  return (
    <View width="100%">
      <View width="100%">
        <Text fontWeight={700} variant="subtitle" mb={2}>
          File Location<Span color="error">*</Span>
        </Text>
        <Select
          name="zipFileSource"
          value={props.zipFileSource}
          onChange={props.handleChange}
          variant="bold"
          mb={2}
          width="100%"
          data-test="characterization-file-source-select"
        >
          <option value="upload">File Upload</option>
          <option value="url">URL</option>
        </Select>

        {renderUploadOption()}
        {renderURLOption()}
      </View>
    </View>
  )
}
