import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
// @ts-ignore
import buildURL from 'axios/lib/helpers/buildURL'
import * as R from 'ramda'
import { decamelizeKeys, camelizeKeys } from 'humps'
import { utils } from '@ims/1edtech-frontend-common'

import store from 'lib/store'
import { setApplicationProperties } from 'domains/application/ducks/application'
import IUser from 'domains/users/models/IUser'
import IOrg from 'domains/orgs/models/IOrg'
import { mergeOrReplaceEntities } from 'lib/records/workflows/entities'
import organizations from 'lib/records/modules/organizations'
import users from 'lib/records/modules/users'
import { setTokens } from './utils/tokens'
import { getApiUrl, getImsxDescription } from './utils'
import {
  DEFAULT_REQUEST_TIMEOUT,
  FREEMIUM_LOCKED_OUT_MESSAGE,
  ORG_DENIED_LOGIN_MESSAGE,
  TOKENS_STORAGE_KEY,
  isTESTMode,
} from './constants'
import { IApiResponse } from './models'

const paramsSerializer = (obj: any) =>
  buildURL('', decamelizeKeys(obj), null).slice(1)

const getResponseStatus = R.pathOr<number>(0, ['status'])

const isUnauthenticated = R.compose<AxiosResponse, number, boolean>(
  R.equals(401),
  getResponseStatus,
)

const getResponseStructure = (response: AxiosResponse, success: boolean) =>
  R.compose<AxiosResponse, IApiResponse>(R.assoc('success', success))(response)

const formatResponse = (response: IApiResponse) => {
  return getResponseStructure(response.response || response, response.success)
}

const getRawResponse = (response: any) => response.response || response

let refreshPromise: any = null

const api = axios.create({
  timeout: DEFAULT_REQUEST_TIMEOUT,
  transformResponse: [
    // @ts-ignore
    ...axios.defaults.transformResponse,
    (response) => camelizeKeys(response),
  ],
  paramsSerializer,
})

api.interceptors.response.use(
  R.assoc('success', true),
  R.assoc('success', false),
)

const refreshTokens = async (token: string) => {
  const response = (await api.post(
    getApiUrl('POST', 'oauth2server/refreshtoken'),
    {
      refresh_token: token,
    },
  )) as IApiResponse

  if (!isUnauthenticated(response)) {
    setTokens(response)
    const meResponse = await performRequest({
      url: 'login',
      method: 'GET',
    })

    if (meResponse.success) {
      const me = await mergeOrReplaceEntities(
        false,
        users,
        meResponse.data,
        false,
      )
      store.dispatch(setApplicationProperties({ me }))
    }
  }

  return formatResponse(response)
}

interface IPerformRequestOptions {
  method: string
  url: string
  data?: any
  params?: any
  headers?: any
  timeout?: number
  returnRawResponse?: boolean
  config?: any
}

export const performRequest = async (
  options: IPerformRequestOptions,
  isRetry = false,
): Promise<IApiResponse> => {
  const {
    method,
    url,
    data = {},
    params = {},
    headers = {},
    timeout = DEFAULT_REQUEST_TIMEOUT,
    returnRawResponse = false,
    config = {},
  } = options

  const bearer = utils.env.getEnvVariable(
    'BEARER_TOKEN',
    process.env.BEARER_TOKEN,
  )
  const tokens = utils.localStorage.getLocalItem(TOKENS_STORAGE_KEY)
  const { accessToken = false, refreshToken = false } = tokens || {}
  const requestHeaders = R.compose(
    (mutableHeaders: any) =>
      R.isEmpty(headers) ? mutableHeaders : { ...mutableHeaders, ...headers },
    (mutableHeaders: any) =>
      accessToken
        ? R.assoc(
            'Authorization',
            `Bearer ${
              process.env.NODE_ENV === 'development' && !isTESTMode
                ? bearer
                : accessToken
            }`,
            mutableHeaders,
          )
        : mutableHeaders,
  )({})

  const defaultRequestOptions = {
    method,
    url: getApiUrl(method, url),
    headers: requestHeaders,
    timeout,
    ...config,
  }
  const requestOptions = R.compose(
    (mutlableOptions: any) =>
      R.isEmpty(params)
        ? mutlableOptions
        : R.assoc('params', params, mutlableOptions),
    (mutlableOptions: any) =>
      R.isEmpty(data)
        ? mutlableOptions
        : R.assoc('data', data, mutlableOptions),
  )(defaultRequestOptions)

  const axiosResponse = await api.request(requestOptions as AxiosRequestConfig)
  let response = axiosResponse as IApiResponse
  if (response.response) {
    response = R.assoc('success', response.success, response.response)
  }

  const unAuthResponse = isUnauthenticated(response)
  const imsxDescription = getImsxDescription(response)
  let lockedOut: false | string = false
  switch (imsxDescription) {
    case FREEMIUM_LOCKED_OUT_MESSAGE:
      lockedOut = 'freemiumLoginDenied'
      break
    case ORG_DENIED_LOGIN_MESSAGE:
      lockedOut = 'orgLoginDenied'
      break
    default:
      break
  }
  const finalResponse = returnRawResponse
    ? getRawResponse(response)
    : formatResponse(response)

  if (unAuthResponse && utils.hasValue(lockedOut)) {
    const lockedOutUser = R.pathOr(
      {},
      ['data', 'errors', 0, 'user'],
      response,
    ) as IUser
    const lockedOutOrg = R.pathOr(
      {},
      ['data', 'errors', 0, 'organization'],
      response,
    ) as IOrg

    // Important to set user/org entities before setting locked out
    mergeOrReplaceEntities(true, users, lockedOutUser)
    mergeOrReplaceEntities(true, organizations, lockedOutOrg)
    store.dispatch(
      setApplicationProperties({
        lockedOut,
        me: lockedOutUser.id,
      }),
    )
    return finalResponse
  }

  if (unAuthResponse && !isRetry) {
    if (refreshToken) {
      if (!refreshPromise) {
        refreshPromise = refreshTokens(refreshToken)
      }
      if (refreshPromise) {
        await refreshPromise
        refreshPromise = null
      }

      return performRequest(
        {
          method,
          url,
          data,
          params,
          headers,
        },
        true,
      )
    }

    // Otherwise log them out
    if (unAuthResponse) {
      store.dispatch(setApplicationProperties({ me: false }))
    }
  }

  return finalResponse
}
