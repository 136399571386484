import {
  ONER_V1P1_CSV_ROSTERING_EXPORT_BULK,
  ONER_V1P2_CSV_ROSTERING_EXPORT_BULK,
  ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK,
  ONER_V1P1_CSV_RESOURCES_EXPORT_BULK,
  ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL,
} from 'domains/formats/constants/formats'
import useFormatScreen from 'lib/hooks/useFormatScreen'
import PrintOR1P1CSVProviderCharacterizationReportScreen from 'domains/characterizations/screens/PrintOR1P1CSVProviderCharacterizationReportScreen'
import PrintOR1P1RestProviderCharacterizationReportScreen from 'domains/characterizations/screens/PrintOR1P1RestProviderCharacterizationReportScreen'

export default function PrintCharacterizationReportRouterScreen(props: any) {
  return useFormatScreen({
    ...props,
    map: {
      [ONER_V1P1_CSV_ROSTERING_EXPORT_BULK]: PrintOR1P1CSVProviderCharacterizationReportScreen,
      [ONER_V1P2_CSV_ROSTERING_EXPORT_BULK]: PrintOR1P1CSVProviderCharacterizationReportScreen,
      [ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK]: PrintOR1P1CSVProviderCharacterizationReportScreen,
      [ONER_V1P1_CSV_RESOURCES_EXPORT_BULK]: PrintOR1P1CSVProviderCharacterizationReportScreen,
      [ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL]: PrintOR1P1RestProviderCharacterizationReportScreen,
      [ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL]: PrintOR1P1RestProviderCharacterizationReportScreen,
      [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL]: PrintOR1P1RestProviderCharacterizationReportScreen,
      [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL]: PrintOR1P1RestProviderCharacterizationReportScreen,
    },
  })
}
