import * as R from 'ramda'
import { utils, records } from '@ims/1edtech-frontend-common'

import { getRequest } from 'lib/api/api'
import store from 'lib/store'
import { setApplicationProperties } from 'domains/application/ducks/application'
import { applicationPropSelector } from 'domains/application/selectors/application'
import notifications, {
  NOTIFICATIONS_RECORD,
} from 'lib/records/modules/notifications'
import { mergeOrReplaceEntities } from 'lib/records/workflows/entities'

export const getNotificationCount = async () => {
  const meId = applicationPropSelector('me')(store.getState())
  const { success, data, headers } = await getRequest(
    `users/${meId}/notifications?filter=read=false&limit=1&offset=0`,
  )
  if (success) {
    const unreadNotificationCount = utils.convertToInt(
      R.pathOr(0, ['x-total-count'], headers),
      0,
    )
    store.dispatch(setApplicationProperties({ unreadNotificationCount }))

    if (unreadNotificationCount > 0) {
      const items = mergeOrReplaceEntities(
        true,
        notifications,
        utils.array.ensureArray(data),
        true,
      )
      store.dispatch(
        records.recordActions.mergeRecordItems(NOTIFICATIONS_RECORD, items),
      )
    }
  }
}
