import { utils } from '@ims/1edtech-frontend-common'
import {
  CCSYS_BASE_URL,
  CHARSYS_BASE_URL,
  COMPSYS_BASE_URL,
  PROXY_BASE_URL,
  REMOTE_PROXY_BASE_URL,
  SWITCHBOARD_BASE_URL,
  TRUSTED_APPS_BASE_URL,
  isTESTMode,
} from '../constants'

export const getApiUrl = (method: string, url: string) => {
  if (url.startsWith('http')) {
    return url
  }
  const isCIMode = utils.env.getEnvVariable('CI', process.env.CI) || false

  if (
    isCIMode ||
    url.includes('oauth2') ||
    url.includes('freshdesk') ||
    url.includes('proxy/analytics')
  ) {
    return process.env.NODE_ENV === 'development'
      ? `${isTESTMode ? PROXY_BASE_URL : REMOTE_PROXY_BASE_URL}/${url}`
      : `${PROXY_BASE_URL}/${url}`
  }

  if (url.includes('conformancereports')) {
    return `${CHARSYS_BASE_URL}/${url}`
  }

  const csvComparisonReport = url.includes('comparisons/csv/report')
  const restComparisonReport = url.includes('comparisons/rest/report')

  if (
    method.toUpperCase() === 'GET' &&
    (csvComparisonReport || restComparisonReport)
  ) {
    return `${COMPSYS_BASE_URL}/${url}`
  }

  if (url.startsWith('ccsys/')) {
    return `${CCSYS_BASE_URL}/${url.replace('ccsys/', '')}`
  }
  if (url.startsWith('trustedapps/')) {
    return `${TRUSTED_APPS_BASE_URL}/${url.replace('trustedapps/', '')}`
  }
  return `${SWITCHBOARD_BASE_URL}/${url}`
}
