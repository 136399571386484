import React, { useState } from 'react'
import * as R from 'ramda'
import { utils, records } from '@ims/1edtech-frontend-common'

import { List } from 'lib/components'
import { OpenTableRows, IOpenTableRows } from 'lib/hooks/OpenTableRows'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'
import trackProductAnalytics from 'domains/products/utils/trackProductAnalytics'
import { expandProductsByFormats } from 'domains/products/utils/expandProductsByFormats'
import IProduct from 'domains/products/models/IProduct'
import { productHasMultipleSupportedFormats } from 'domains/products/utils/productHasMultipleSupportedFormats'
import ProductCatalogStatusCell from 'domains/products/components/ProductCatalog/ProductCatalogStatusCell'
import ProductCatalogCharacterizationCell from 'domains/products/components/ProductCatalog/ProductCatalogCharacterizationCell'
import { sortProductsByModellingStatus } from 'domains/products/utils/sortProductsByModellingStatus'
import ProductCatalogModelingCell from 'domains/products/components/ProductCatalog/ProductCatalogModelingCell'
import SupplierProductCatalogFormatCell from 'domains/products/components/SupplierProductCatalogFormatCell'
import SupplierProductCatalogSpecVersionCell from 'domains/products/components/SupplierProductCatalogSpecVersionCell'
import SupplierProductCatalogSpecCell from 'domains/products/components/SupplierProductCatalogSpecCell'
import SupplierProductCatalogProductNameCell from 'domains/products/components/SupplierProductCatalogProductNameCell'
import ProductCatalogRosteringCell from 'domains/products/components/ProductCatalog/ProductCatalogRosteringCell'
import { sortProductsByRosteringMethod } from 'domains/products/utils/sortProductsByRosteringMethod'
import {
  IListColumnDefinition,
  getListRowBackground,
} from 'lib/components/List'
import ResetProductDialog from 'domains/products/components/ResetProductDialog'
import { getFirstSupportedProductFormat } from 'domains/products/utils/getFirstSupportedProductFormat'
import ProductCatalogComparisonCountCell from 'domains/products/components/ProductCatalog/ProductCatalogComparisonCountCell'
import ProductCatalogToggleOpenCell from 'domains/products/components/ProductCatalog/ProductCatalogToggleOpenCell'

interface IProps {
  nonRecordProducts?: any[]
  products?: records.recordReducers.IRecordState
  orgId?: number
}

export const getProductSpecColumnDefinition = (isSingleProduct?: boolean) => ({
  title: 'Mode',
  noSort: true,
  CellComponent: SupplierProductCatalogSpecCell,
  shouldRenderEmpty: (p: any, idx: number, isSubRow: boolean) =>
    !isSingleProduct && !isSubRow && productHasMultipleSupportedFormats(p),
})
export const getProductVersionColumnDefinition = (
  isSingleProduct?: boolean,
) => ({
  title: 'Ver',
  noSort: true,
  CellComponent: SupplierProductCatalogSpecVersionCell,
  shouldRenderEmpty: (p: any, idx: number, isSubRow: boolean) =>
    !isSingleProduct && !isSubRow && productHasMultipleSupportedFormats(p),
})
export const getProductFormatColumnDefinition = (
  isSingleProduct?: boolean,
) => ({
  title: 'Format',
  noSort: true,
  CellComponent: SupplierProductCatalogFormatCell,
  shouldRenderEmpty: (p: any, idx: number, isSubRow: boolean) =>
    !isSingleProduct && !isSubRow && productHasMultipleSupportedFormats(p),
})
export default function SupplierProductList(props: IProps) {
  const { nonRecordProducts, products, orgId } = props

  const [resetWarningOpen, setResetWarningOpen] = useState<boolean | IProduct>(
    false,
  )
  const closeResetWarning = () => setResetWarningOpen(false)

  const isSingleProduct = utils.hasValue(nonRecordProducts)

  const sortByModelingStatus =
    'rosteringTool:ascending,rolledUpModellingStatus@,name:ascending'

  const getColumns = (
    openTableRowsOptions: IOpenTableRows,
  ): IListColumnDefinition[] => {
    const columns: IListColumnDefinition[] = [
      {
        title: 'Rostering Method',
        noCenter: isSingleProduct,
        CellComponent: ProductCatalogRosteringCell,
        noSort: isSingleProduct,
        sort: (items, sortAsc) =>
          R.sort(sortProductsByRosteringMethod(sortAsc), items),
        sortKey: utils.sort.addSortValue('rosteringTool@,name:ascending'),
        shouldRenderEmpty: (p, idx, isSubRow) => !isSingleProduct && isSubRow,
      },
      getProductSpecColumnDefinition(isSingleProduct),
      getProductVersionColumnDefinition(isSingleProduct),
      getProductFormatColumnDefinition(isSingleProduct),
      {
        title: 'Modeling',
        CellComponent: ProductCatalogModelingCell,
        cellProps: { isSingleProduct },
        noSort: isSingleProduct,
        sort: (items, sortAsc) =>
          R.sort(sortProductsByModellingStatus(sortAsc), items),
        sortKey: utils.sort.addSortValue(sortByModelingStatus),
      },
      {
        title: 'Characterization',
        CellComponent: ProductCatalogCharacterizationCell,
        cellProps: { isSingleProduct },
        noSort: true,
      },
      {
        title: 'Status',
        CellComponent: ProductCatalogStatusCell,
        cellProps: { isSingleProduct },
        noSort: true,
      },
      {
        title: 'Comp',
        CellComponent: ProductCatalogComparisonCountCell,
        cellProps: { isSingleProduct },
        noSort: true,
        noAutomaticPadding: true,
      },
    ]
    if (isSingleProduct) {
      return columns
    }
    return [
      {
        title: null,
        maxWidth: 36,
        noSort: true,
        cellProps: { openTableRowsOptions },
        shouldRenderEmpty: (p, idx, isSubRow) => isSubRow,
        CellComponent: ProductCatalogToggleOpenCell,
      },
      {
        title: 'Product',
        noHeaderCenter: true,
        maxWidth: [200, 200, 200, 300],
        cellProps: { maxWidthArray: [170, 170, 170, 270] },
        CellComponent: SupplierProductCatalogProductNameCell,
        noSort: isSingleProduct,
        sortKey: (asc: boolean) => `name:${utils.sort.getSortValue(asc)}`,
        shouldRenderEmpty: (p, idx, isSubRow) => !isSingleProduct && isSubRow,
      },
      ...columns,
    ]
  }

  const getRowProps =
    (openTableRowsOptions: IOpenTableRows) =>
    (p: IProduct, index: number, isSubRow: boolean) => {
      const isOpen = openTableRowsOptions.OpenTableRows[p.id]
      const topLevelRow = productHasMultipleSupportedFormats(p)
      if (isSubRow) {
        return {
          bg: 'white',
          collapse: !isOpen,
        }
      }
      if (topLevelRow && isOpen) {
        return {
          isOpen: true,
          bg: getListRowBackground(index),
        }
      }
      return {}
    }

  const getSubItems = (OpenTableRows: any) => (product: IProduct) => {
    if (
      !isSingleProduct &&
      productHasMultipleSupportedFormats(product) &&
      OpenTableRows[product.id]
    ) {
      return expandProductsByFormats(product)
    }

    return []
  }

  const nonRecord = utils.hasValue(nonRecordProducts)

  const renderResetModal = () => {
    if (!resetWarningOpen) {
      return null
    }
    return (
      <ResetProductDialog
        isOpen={utils.hasValue(resetWarningOpen)}
        product={resetWarningOpen as IProduct}
        format={
          (resetWarningOpen as IProduct).format ||
          getFirstSupportedProductFormat(resetWarningOpen as IProduct)!
        }
        onReset={closeResetWarning}
        onCancel={closeResetWarning}
      />
    )
  }

  return (
    <>
      <OpenTableRows>
        {(openTableRowsOptions: IOpenTableRows) => (
          <List
            title={isSingleProduct ? 'Format Details' : 'All Products'}
            noSearch={isSingleProduct}
            record={nonRecord ? undefined : PRODUCTS_RECORD}
            recordState={nonRecord ? undefined : products}
            parentRecordId={nonRecord ? undefined : orgId}
            nonRecordItems={nonRecordProducts}
            columns={getColumns(openTableRowsOptions)}
            rowHeight={68}
            dataTest="product-catalog-list"
            initialSortColumnIndex={6}
            getSubItems={getSubItems(openTableRowsOptions.OpenTableRows)}
            keyExtractor={R.prop('id')}
            trackAnalytics={trackProductAnalytics}
            trackAnalyticsCategory="supplier_product_catalog"
            getRowProps={getRowProps(openTableRowsOptions)}
            searchTemplate="name=~@@"
          />
        )}
      </OpenTableRows>
      {renderResetModal()}
    </>
  )
}
