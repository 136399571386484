import React from 'react'

import { View, Text } from 'lib/components'
import colors from 'lib/styles/colors'
import { PARAM_TO_DISPLAY_NAME } from 'domains/modeling/constants/restCOneRoster1p1'

function Title(props: any) {
  return <Text fontWeight={700}>{props.children}</Text>
}

export default function RESTOperationInformationHeader() {
  return (
    <View
      px={3}
      flexible="row-v-center"
      borderBottom={`1px solid ${colors.reportTableBorder}`}
      height={36}
    >
      <View width={[120, 200]} />
      <View flex={1} flexible="row-center">
        <Title>{PARAM_TO_DISPLAY_NAME.filter}</Title>
      </View>
      <View flex={1} flexible="row-center">
        <Title>{PARAM_TO_DISPLAY_NAME.fields}</Title>
      </View>
      <View flex={1} flexible="row-center">
        <Title>{PARAM_TO_DISPLAY_NAME.sort}</Title>
      </View>
      <View flex={1} flexible="row-center">
        <Title>{PARAM_TO_DISPLAY_NAME.orderBy}</Title>
      </View>
      <View flex={1} flexible="row-center">
        <Title>{PARAM_TO_DISPLAY_NAME.offSet}</Title>
      </View>
      <View flex={1} flexible="row-center">
        <Title>{PARAM_TO_DISPLAY_NAME.limit}</Title>
      </View>
    </View>
  )
}
