import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { ModalWrapper } from 'lib/components'
import OrgTrustEdAppsLTIPlatfromSelector from 'domains/orgs/components/OrgTrustEdAppsLTIPlatfromSelector'

type FormValues = {
  id: string
}

const validationSchema = Yup.object().shape({
  id: Yup.string().required('Required'),
})

type Props = {
  isOpen: boolean
  closeModal: () => any
  onLTIPlatformSelected: (id: number) => any
}

export default function OrganizationLTIProviderModal(props: Props) {
  if (!props.isOpen) {
    return null
  }

  const onSave = (values: FormValues) => {
    props.onLTIPlatformSelected(parseInt(values.id))
  }

  return (
    <Formik
      initialValues={{
        id: '',
      }}
      onSubmit={onSave}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {(bag) => {
        return (
          <ModalWrapper
            isOpen={props.isOpen}
            title="Select an LTI Platform Provider"
            actions={[
              {
                text: 'Save',
                variant: bag.values.id.length < 1 ? 'neutral' : 'start',
                onClick: bag.handleSubmit,
                extra: {
                  type: 'submit',
                  disabled: bag.values.id.length < 1,
                  pending: bag.isSubmitting,
                },
              },
              {
                text: 'Cancel',
                variant: 'neutral',
                onClick: () => {
                  bag.resetForm()
                  props.closeModal()
                },
                extra: {
                  type: 'button',
                  disabled: bag.isSubmitting,
                },
              },
            ]}
          >
            <OrgTrustEdAppsLTIPlatfromSelector
              value={bag.values.id}
              onChange={bag.handleChange}
              addDefaultOption
            />
          </ModalWrapper>
        )
      }}
    </Formik>
  )
}
