import { trackDomainAnalytics } from 'lib/eventTracking'
import {
  ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
  ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
} from 'domains/formats/constants/formats'

const trackModelingAnalytics = trackDomainAnalytics('Modeling')
export default trackModelingAnalytics

export const trackCSVCModelingAnalytics = (action: string, properties = {}) =>
  trackModelingAnalytics(action, {
    ...properties,
    format: ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
  })

export const trackRestCModelingAnalytics = (action: string, properties = {}) =>
  trackModelingAnalytics(action, {
    ...properties,
    format: ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
  })
