import queryString from 'query-string'
import * as R from 'ramda'
import { utils, records } from '@ims/1edtech-frontend-common'

import store from 'lib/store'
import { getRequest } from 'lib/api/api'
import { mergeOrReplaceEntities } from 'lib/records/workflows/entities'
import modules from 'lib/records/modules'
import { clearPaging, setPaging } from 'lib/records/workflows/paging'

export const DEFAULT_PAGE_SIZE = 25

const getLoadingField = (page?: number, replaceItems = false) => {
  if (!page || replaceItems || page === 1) {
    return 'loading'
  }
  return 'loadingNext'
}

export const getRecordPagingDetails = (options: IGetRecordsOptions) => {
  const { record, parentRecordId } = options

  const recordDetails = parentRecordId
    ? records.relatedRecordsSelectors.relatedRecordSelector(
        record,
        parentRecordId,
      )(store.getState(), {})
    : records.recordsSelectors.recordSelector(record)(store.getState())
  return recordDetails.paging || {}
}

export const getPage = (
  options: IGetRecordsOptions,
  paging: records.recordReducers.IRecordPaging | Record<string, unknown>,
): number => {
  const { page = false, getCurrentPage = false } = options
  if (page) {
    return page
  }
  return getCurrentPage
    ? R.propOr(1, 'page', paging)
    : R.propOr(1, 'nextPage', paging)
}

export const getPageSize = (
  options: IGetRecordsOptions,
  paging: records.recordReducers.IRecordPaging | Record<string, unknown>,
): number => {
  const { pageSize = 0 } = options
  if (pageSize > 0) {
    return pageSize
  }
  return R.propOr(DEFAULT_PAGE_SIZE, 'pageSize', paging)
}

export const getOffset = (
  options: IGetRecordsOptions,
  paging: records.recordReducers.IRecordPaging | any,
) => {
  const page = getPage(options, paging)
  const pageSize = getPageSize(options, paging)
  return (page ? page - 1 : 0) * pageSize
}

export const shouldGetRecords = (
  options: IGetRecordsOptions,
  paging: records.recordReducers.IRecordPaging | any,
) => {
  const { page = 0, getCurrentPage = false, params = {} } = options
  const pagingParams = R.propOr({}, 'params', paging)
  const nextPage = R.propOr(null, 'nextPage', paging)
  const total = R.propOr<number, Record<string, unknown>, number>(
    0,
    'total',
    paging,
  )
  if (total > 0 && getOffset(options, paging) >= total) {
    return false
  }
  if (utils.hasValue(params) && !R.equals(params, pagingParams)) {
    return true
  }
  if (!page && nextPage === false && getCurrentPage === false) {
    return false
  }
  return true
}

export const getParams = (
  options: IGetRecordsOptions,
  paging: records.recordReducers.IRecordPaging | Record<string, unknown>,
): Record<string, unknown> => {
  const { params = {}, defaultPagingParams = {} } = options
  if (utils.hasValue(params)) {
    return params
  }
  return R.propOr(defaultPagingParams, 'params', paging)
}

export interface IGetRecordsOptions {
  record: string
  params?: Record<string, unknown>
  defaultPagingParams?: Record<string, unknown>
  parentRecordId?: string | number
  pageSize?: number
  replaceItems?: boolean
  force?: boolean
  page?: number
  getCurrentPage?: boolean
}

export const getRecords = async (options: IGetRecordsOptions) => {
  const {
    record,
    parentRecordId,
    replaceItems = false,
    force = false,
  } = options
  if (force) {
    clearPaging(record, parentRecordId)
  }

  const paging = getRecordPagingDetails(options)
  if (!shouldGetRecords(options, paging)) {
    return
  }
  const thePage = getPage(options, paging)
  const thePageSize = getPageSize(options, paging)
  const theParams = getParams(options, paging)
  const recordModule = modules[record]
  const loadingField = getLoadingField(thePage, replaceItems)
  const setProperties = parentRecordId
    ? records.relatedRecordActions.setRelatedRecordProperties
    : records.recordActions.setRecordProperties
  const mergeItems = parentRecordId
    ? records.relatedRecordActions.mergeRelatedRecordItems
    : records.recordActions.mergeRecordItems

  store.dispatch(
    setProperties(record, { [loadingField]: true }, parentRecordId),
  )
  const url = recordModule.api.getList(theParams, parentRecordId)
  const pagingQueryString = `limit=${thePageSize}&offset=${getOffset(
    options,
    paging,
  )}`
  const response = await getRequest(
    `${url}${
      url.includes('?') ? '&' : '?'
    }${pagingQueryString}&${queryString.stringify(theParams)}`,
  )
  store.dispatch(
    setProperties(record, { status: response.status }, parentRecordId),
  )

  const isNotFound = response.status === 404
  const isBadRequest = response.status === 400
  if (response.success || isNotFound || isBadRequest) {
    const responseData = records.modules.utils.getRecordDataFromResponse(
      recordModule,
      response as any,
    )
    const data =
      isNotFound || isBadRequest || !utils.hasValue(responseData)
        ? []
        : responseData
    const items = mergeOrReplaceEntities(true, recordModule, data, true)

    setPaging(
      record,
      setProperties,
      response,
      parentRecordId,
      thePage,
      thePageSize,
      theParams,
    )
    if (!replaceItems && thePage && thePage > 1) {
      store.dispatch(mergeItems(record, items, parentRecordId))
    } else {
      if (parentRecordId) {
        store.dispatch(
          records.relatedRecordActions.replaceRelatedRecordItems(
            record,
            items,
            parentRecordId,
          ),
        )
      } else {
        store.dispatch(records.recordActions.replaceRecordItems(record, items))
      }
    }
  }

  store.dispatch(
    setProperties(record, { [loadingField]: false }, parentRecordId),
  )

  return response
}
