import React from 'react'
import * as R from 'ramda'
import { useSelector } from 'react-redux'
import { records } from '@ims/1edtech-frontend-common'

import IIMSApplication from 'domains/trustedApps/models/IIMSApplication'
import { Button, Linkify, ModalWrapper } from 'lib/components'
import { IMS_APPLICATIONS_RECORD } from 'lib/records/modules/imsApplications'
import { TRUSTED_APPS_RECORD } from 'lib/records/modules/trustedApplications'
import { utils } from '@ims/1edtech-frontend-common'
import HTML from 'lib/components/HTML'
import {
  getRoute,
  TRUSTED_APPS_ROUTE,
} from 'domains/application/navigation/routes'

interface IProps {
  id: number
  isOpen: boolean
  onRequestClose: () => any
  addApp: (id: number) => any
}

export default function TrustedAppDetailsModal(props: IProps) {
  const imsApplication = useSelector((state: any) =>
    records.entitiesSelectors.entityByIdSelector(IMS_APPLICATIONS_RECORD, 'id')(
      state,
      props,
    ),
  ) as IIMSApplication

  const onViewMore = () => {
    window.open(getRoute(TRUSTED_APPS_ROUTE, props.id))
  }

  const isTrusted = useSelector((state: any) =>
    records.recordsSelectors.recordSelector(TRUSTED_APPS_RECORD)(
      state,
      props.id,
    ),
  ).items.includes(props.id)

  const onSelect = () => {
    props.addApp(props.id)
    props.onRequestClose()
  }

  const description = R.pathOr('', ['productDescription'], imsApplication)
  const orgUrl = R.pathOr('', ['orgExternalUrl'], imsApplication)
  return (
    <ModalWrapper
      isOpen={props.isOpen}
      title={
        <div className="flex flex-row justify-between">
          <div>
            <h1 className="text-2xl">
              {R.pathOr('', ['productName'], imsApplication)}
            </h1>
          </div>

          <div className="flex flex-row justify-between">
            <div className="ml-3 cursor-pointer" onClick={props.onRequestClose}>
              <i className="fas fa-x text-2xl" />
            </div>
          </div>
        </div>
      }
      actions={[]}
    >
      <div>
        {utils.hasValue(description) && (
          <HTML fontSize={16} data-test="trusted-app-description">
            {description}
          </HTML>
        )}

        {utils.hasValue(orgUrl) && (
          <div className="mt-3">
            <Linkify>{orgUrl}</Linkify>
          </div>
        )}

        <div className="mt-4 grid grid-cols-2 gap-3">
          <Button variant="complete" onClick={onViewMore}>
            <p className="text-base">View more detail</p>
          </Button>
          {!isTrusted && (
            <Button variant="complete" onClick={onSelect}>
              <p className="text-base">Select this application</p>
            </Button>
          )}
        </div>
      </div>
    </ModalWrapper>
  )
}
