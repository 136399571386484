import React from 'react'
import { View, Text, Icon, Span } from 'lib/components'
import {
  REQUIRED_TO_ICON_MAP,
  REQUIRED_TO_ICON_COLOR_MAP,
} from 'domains/comparisons/constants/comparisonReports'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'
import {
  GREEN_REPORT_STATUS,
  ReportStatus,
} from 'domains/reports/models/IReportStatuses'
import CharacterizationErrorDot from 'domains/characterizations/components/CharacterizationErrorDot'
import { utils } from '@ims/1edtech-frontend-common'
import PrintMetadataFlag from 'domains/comparisons/components/PrintMetadataFlag'
import { FORMAT_TO_DISPLAY } from 'domains/characterizations/models/CharacterizationReport'
import { getReportMinMaxValue } from 'domains/reports/utils/getReportMinMaxValue'
import colors from 'lib/styles/colors'

const isPrint = isOnPrintScreen()

interface IProps {
  minWidth: number
  dataType: string
  status: ReportStatus
  required: string
  mdSource?: string
  parentName: string
  name: string
  smallestValueLength?: number
  largestValueLength?: number
}

export default function ComparisonFileColumnHeader(props: IProps) {
  const dataTypeDisplay = FORMAT_TO_DISPLAY[props.dataType]
  return (
    <View
      width={props.minWidth}
      minWidth={props.minWidth}
      borderRight={isPrint ? undefined : `solid 1px ${colors.reportRowDivider}`}
      pr={2}
      flexible="row"
    >
      {isPrint && (
        <View flexible="column-center" minWidth={28}>
          {props.status !== GREEN_REPORT_STATUS && (
            <CharacterizationErrorDot color="secondary" size={16} />
          )}
        </View>
      )}
      <View
        flexible={isPrint ? 'row-v-center' : 'row'}
        minWidth={isPrint ? 36 : 28}
        mr={2}
      >
        <Icon
          fontSize={isPrint ? 16 : 20}
          className={REQUIRED_TO_ICON_MAP[props.required]}
          data-icon={REQUIRED_TO_ICON_MAP[props.required]}
          color={REQUIRED_TO_ICON_COLOR_MAP[props.required]}
          mr={1}
          data-test={`comparison-icon-${props.parentName}-${props.name}`}
        />
        {isPrint && utils.hasValue(props.mdSource) && (
          <PrintMetadataFlag
            parentName={props.parentName}
            name={props.name}
            isConsumer={props.mdSource === 'Consumer'}
          />
        )}
      </View>
      <View flexible={isPrint ? 'row-v-center' : undefined}>
        <Text
          fontSize={isPrint ? 10 : 16}
          fontWeight={700}
          mb={isPrint ? 0 : 1}
          maxWidth={props.minWidth}
          wordBreak="break-all"
          data-test={`comparison-name-${props.parentName}-${props.name}`}
        >
          {props.name}{' '}
          {isPrint && (
            <Span
              fontWeight={400}
              fontStyle="italic"
              data-test={`comparison-type-${props.parentName}-${props.name}`}
            >
              ({dataTypeDisplay})
            </Span>
          )}
        </Text>
        {!isPrint && (
          <Text
            fontWeight={400}
            data-test={`comparison-type-${props.parentName}-${props.name}`}
          >
            {dataTypeDisplay}
          </Text>
        )}
        {!isPrint && (
          <Text
            fontWeight={400}
            data-test={`comparison-min-max-${props.parentName}-${props.name}`}
          >
            Min/Max: {getReportMinMaxValue(props)}
          </Text>
        )}
      </View>
    </View>
  )
}
