import React from 'react'

import ISource from 'domains/configuration/models/ISource'
import { ListPlainTextCell } from 'lib/components'

interface IProps extends ISource {
  onClick: (source: ISource) => void
}

export default function SourceIsMemberCell(props: IProps) {
  return <ListPlainTextCell>{props.isMember ? 'Yes' : 'No'}</ListPlainTextCell>
}
