import React from 'react'

import { Button, List } from 'lib/components'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import {
  getProductModelingRoute,
  getProductStartModelingRoute,
} from 'domains/products/navigation/routes'
import {
  productHasModelingStatus,
  isProductCharacterized,
} from 'domains/products/utils/products'
import ProductNameCell from 'domains/dashboard/components/ProductNameCell'
import IProduct from 'domains/products/models/IProduct'
import { History } from 'history'
import { getProductFormatModelId } from 'domains/products/utils/productByModel'
import { NOT_MODELLED_MODELLING_STATUS } from 'domains/products/constants/products'
import { expandProductsByFormats } from 'domains/products/utils/expandProductsByFormats'
import { isProductFormatSupported } from 'domains/products/utils/isProductFormatSupported'
import {
  getProductSpecColumnDefinition,
  getProductVersionColumnDefinition,
  getProductFormatColumnDefinition,
} from 'domains/products/components/SupplierProductList'
import { IListColumnDefinition } from 'lib/components/List'
import LargeWidget from 'domains/dashboard/components/LargeWidget'

interface IProps {
  productsNeedingModelling: IProduct[]
  fadeDelay: number
}

const goToModelingRoute = (
  history: History,
  product: IProduct,
  start = false,
) => () =>
  start
    ? history.push(getProductStartModelingRoute(product.id, product.format!))
    : history.push(
        getProductModelingRoute(
          product.id,
          getProductFormatModelId(product, product.format!),
          product,
        ),
      )

const columns: IListColumnDefinition[] = [
  {
    title: 'Name',
    noSort: true,
    titleRender: <WidgetHeader title="Modeling Needed" />,
    CellComponent: ProductNameCell,
    cellProps: { imageSize: 50 },
    noCenter: true,
  },
  getProductSpecColumnDefinition(true),
  getProductVersionColumnDefinition(true),
  getProductFormatColumnDefinition(true),
  {
    title: 'Status',
    maxWidth: 130,
    noSort: true,
    renderCell: (product: IProduct, _: any, history: any) =>
      productHasModelingStatus(NOT_MODELLED_MODELLING_STATUS)(
        product,
        product.format!,
      ) ? (
        <Button
          py={2}
          width={130}
          variant="start"
          onClick={goToModelingRoute(history, product, true)}
          disabled={!isProductFormatSupported(product.format!)}
        >
          Begin
        </Button>
      ) : (
        <Button
          py={2}
          width={130}
          variant="secondary"
          onClick={goToModelingRoute(history, product)}
        >
          Incomplete
        </Button>
      ),
  },
]

export default function ModelingNeededWidget(props: IProps) {
  const products = props.productsNeedingModelling
    ? expandProductsByFormats(props.productsNeedingModelling)
    : []
  const filtered = products.filter(
    (product: IProduct) => !isProductCharacterized(product, product.format!),
  )
  return (
    <LargeWidget fadeDelay={props.fadeDelay} dataTest="modeling-needed-widget">
      <List
        noWrapper={true}
        rowHeight={72}
        columns={columns}
        nonRecordItems={filtered}
        dataTest="modeling-needed-widget-list"
        blankSlateText="No products in need of modeling"
      />
    </LargeWidget>
  )
}
