import React from 'react'
import { History } from 'history'
import * as R from 'ramda'

import { View, Icon, List } from 'lib/components'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import LargeWidget from 'domains/dashboard/components/LargeWidget'
import ProductNameCell from 'domains/dashboard/components/ProductNameCell'
import IProduct from 'domains/products/models/IProduct'
import { getProductRoute } from 'domains/products/navigation/routes'
import DashboardRecentProductModifiedCell from 'domains/comparisons/components/DashboardRecentProductModifiedCell'
import { IListColumnDefinition } from 'lib/components/List'
import { PUBLISHED_PRODUCT_STATUS } from 'domains/products/constants/products'

const columns: IListColumnDefinition[] = [
  {
    title: 'Name',
    CellComponent: ProductNameCell,
    titleRender: <WidgetHeader title="Recent Products" />,
    noCenter: true,
  },
  {
    title: 'Modified',
    CellComponent: DashboardRecentProductModifiedCell,
  },
  {
    title: 'Status',
    maxWidth: 60,
    renderCell: (product: IProduct) => (
      <View flexible="column-center">
        {R.propOr('', 'rolledUpVisibility', product) ===
        PUBLISHED_PRODUCT_STATUS.toUpperCase() ? (
          <Icon className="fas fa-eye" color="success" />
        ) : (
          <Icon className="fas fa-eye-slash" />
        )}
      </View>
    ),
  },
  {
    title: 'Comp',
    maxWidth: 60,
    accessor: 'comparisonCount',
    forceCenter: true,
  },
]

interface IProps {
  recentProducts: IProduct[]
  history: History
  fadeDelay: number
}

export default function RecentProductsWidget(props: IProps) {
  const onRowClick = (product: IProduct) =>
    props.history.push(getProductRoute(product.id))

  return (
    <LargeWidget fadeDelay={props.fadeDelay} dataTest="recent-products-widget">
      <List
        noWrapper={true}
        columns={columns}
        nonRecordItems={props.recentProducts}
        rowHeight={90}
        noSort={true}
        dataTest="recent-products-widget-list"
        blankSlateText="No Recent Products"
        onRowClick={onRowClick}
      />
    </LargeWidget>
  )
}
