import React from 'react'
import clsx from 'clsx'

import { Button, Icon } from 'lib/components'

interface IProps {
  onClick: () => any
  icon?: string
  noIcon?: boolean
  text?: string
  disabled?: boolean
  'data-test'?: string
  variant?: string
  pending?: boolean
  className?: string
}

export default function ListCallToActionButton(props: IProps) {
  const { variant = 'complete', className } = props
  return (
    <Button
      variant={props.disabled ? 'disabled' : variant}
      py={2}
      px="28px"
      height="42px"
      onClick={props.onClick}
      disabled={props.disabled}
      pending={props.pending}
      data-test={props['data-test'] || 'add-new-btn'}
      className={className}
    >
      <div className="flex flex-row items-center">
        {!props.noIcon && (
          <Icon className={props.icon || 'fas fa-plus-square'} color="white" />
        )}
        <p
          className={clsx('text-white', {
            'ml-2': !props.noIcon,
          })}
        >
          {props.text || 'Add New'}
        </p>
      </div>
    </Button>
  )
}
