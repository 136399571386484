import React from 'react'
import Linkify from 'linkify-it'

import INotification from 'domains/notifications/models/INotification'
import { Text, Span } from 'lib/components'
import { ITextProps } from 'lib/components/Text'
import { utils } from '@ims/1edtech-frontend-common'

interface ITextPart {
  bold: boolean
  link?: string
  value: string
}

interface IProps extends ITextProps {
  notification: INotification
}

export default function NotificationMessage(props: IProps) {
  const {
    notification: { message, emphasisWords },
    ...rest
  } = props

  let content: ITextPart[] = [{ bold: false, value: message }]
  if (utils.hasValue(emphasisWords)) {
    let lastIndex = 0
    content = []

    emphasisWords.forEach((word) => {
      const indexOfWord = message.indexOf(word) - lastIndex
      if (indexOfWord >= 0) {
        const beforeWord = message.substr(lastIndex, indexOfWord)
        lastIndex += beforeWord.length + word.length
        content = [
          ...content,
          { bold: false, value: beforeWord },
          { bold: true, value: word },
        ]
      }
    })

    content = [...content, { bold: false, value: message.substr(lastIndex) }]

    content.forEach((part, index) => {
      const links = Linkify().match(part.value)
      if (!utils.hasValue(links)) {
        return
      }
      links!.forEach((link) => {
        const indexOfWord = part.value.indexOf(link.raw)
        const beforeLink = part.value.substr(0, indexOfWord)
        const afterLink = part.value.substr(indexOfWord + link.raw.length)
        content.splice(index, 1, { bold: part.bold, value: beforeLink })
        content.splice(index + 1, 0, {
          bold: part.bold,
          link: link.url,
          value: link.raw,
        })
        content.splice(index + 2, 0, {
          bold: part.bold,
          value: afterLink,
        })
      })
    })
  }

  return (
    <Text
      fontSize="inherit"
      lineHeight="inherit"
      wordBreak="break-word"
      {...rest}
    >
      {content.map((part, index) => {
        let value: any = part.value
        if (part.link) {
          value = (
            <a
              key={part.link}
              href={part.link}
              target="_blank"
              rel="noopener noreferrer"
              className="break-normal"
            >
              {value}
            </a>
          )
        }
        if (part.bold) {
          return (
            <Span
              {...rest}
              m={0}
              color="inherit"
              fontSize="inherit"
              lineHeight="inherit"
              key={`${part.value}-${index}`}
              fontWeight={700}
              wordBreak="break-word"
            >
              {value}
            </Span>
          )
        }

        return value
      })}
    </Text>
  )
}
