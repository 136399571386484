import React from 'react'
import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'

export const FeatureFlagsContext = React.createContext<string[]>([])

export const isFlagEnabled = (
  enabledFlags: string[],
  flag: string | string[],
) => {
  const flags = utils.array.ensureArray(flag)
  return R.intersection(enabledFlags, flags).length === flags.length
}

export const useIsFeatureEnabled = (flag: string | string[]) => {
  const enabledFlags = React.useContext(FeatureFlagsContext)
  return isFlagEnabled(enabledFlags, flag)
}

type Props = {
  flag: string | string[]
  children?: any
  on?: any
  off?: any
}

export default function FeatureFlag(props: Props) {
  const enabledFlags = React.useContext(FeatureFlagsContext)
  const { flag, children, on: On, off: Off, ...rest } = props

  const isEnabled = isFlagEnabled(enabledFlags, flag)

  if (isEnabled) {
    if (children) {
      return children
    }
    return <On {...rest} />
  }

  if (Off) {
    return <Off {...rest} />
  }

  return null
}
