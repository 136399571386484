import React from 'react'
import * as R from 'ramda'

import { utils } from '@ims/1edtech-frontend-common'
import { View, InputLabel, Text } from 'lib/components'

interface IProps {
  label: string
  labelDataTest?: string
  value: any
  valueDataTest: string
  secondaryValue?: any
  secondaryValueDataTest?: string
}

export default function OrgDetailItem(props: IProps) {
  if (!utils.hasValue(props.value)) {
    return null
  }

  return (
    <View mb={20}>
      <InputLabel data-test={props.labelDataTest}>{props.label}</InputLabel>
      {R.is(String, props.value) && (
        <Text data-test={props.valueDataTest} wordBreak="break-all" mb={2}>
          {props.value}
        </Text>
      )}
      {!R.is(String, props.value) && (
        <View data-test={props.valueDataTest}>{props.value}</View>
      )}
      {utils.hasValue(props.secondaryValue) && (
        <Text
          data-test={props.secondaryValueDataTest}
          wordBreak="break-all"
          mb={2}
        >
          {props.secondaryValue}
        </Text>
      )}
    </View>
  )
}
