import * as normalizr from 'normalizr'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

import { generateDisclosures } from 'domains/commonCartridge/utils/generateDisclosures'
import { utils } from '@ims/1edtech-frontend-common'

export const COMMON_CARTRIDGE_CHARACTERIZATION_REPORTS_RECORD =
  'commonCartridgeCharacterizationReports'

export const getCCLomAndDisclosures = (item: any): [any, string[]] => {
  const preLom = JSON.parse(R.pathOr('{}', ['lom'], item))
  let lom = {}
  for (const key in preLom) {
    if (Object.prototype.hasOwnProperty.call(preLom, key)) {
      const element = preLom[key]
      if (utils.hasValue(element)) {
        lom = R.assoc(key, element, lom)
      }
    }
  }
  const disclosures = generateDisclosures(lom)

  return [lom, disclosures]
}

const commonCartridgeCharacterizationReports: records.modules.IModule = {
  record: COMMON_CARTRIDGE_CHARACTERIZATION_REPORTS_RECORD,
  schema: new normalizr.schema.Entity(
    COMMON_CARTRIDGE_CHARACTERIZATION_REPORTS_RECORD,
    {},
    {
      processStrategy: R.compose((report) => {
        const [lom, disclosures] = getCCLomAndDisclosures(report)
        return {
          ...report,
          lom,
          disclosures,
        }
      }, records.modules.utils.fixIdAttribute),
      idAttribute: 'validationId',
    },
  ),
  api: {
    create: () => '',
    get: (id) => `ccsys/cartridges/${id}`,
    getList: () => '',
    update: () => '',
    delete: () => '',
  },
}

export default commonCartridgeCharacterizationReports
