import React from 'react'

import Badge, { IBadgeProps } from 'lib/components/Badge'
import { Icon } from 'lib/components'

interface IProps extends IBadgeProps {
  onClick: any
}

export default function DeleteButton(props: IProps) {
  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    props.onClick(e)
  }

  return (
    <Badge
      variant="error"
      badgeType="circle"
      width={24}
      height={24}
      p={0}
      {...props}
      onClick={onClick}
    >
      <Icon pt="2px" pl="1px" color="white" className="fas fa-x" />
    </Badge>
  )
}
