import React from 'react'
import * as R from 'ramda'
import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { utils } from '@ims/1edtech-frontend-common'

import { View, Span, Text, InlineAlert } from 'lib/components'
import colors from 'lib/styles/colors'
import {
  COMMON_CARTRIDGE_V1P3,
  COMMON_CARTRIDGE_V1P2,
  THIN_COMMON_CARTRIDGE_V1P2,
  THIN_COMMON_CARTRIDGE_V1P3,
  AnySpecFormatType,
} from 'domains/formats/constants/formats'
import CreateCharacterizationBasicDetails from 'domains/characterizations/components/CreateCharacterization/CreateCharacterizationBasicDetails'
import CreateCharacterizationFileDetails from 'domains/characterizations/components/CreateCharacterization/CreateCharacterizationFileDetails'
import { INewCharacterization } from 'domains/characterizations/models/INewCharacterization'
import { getCharacterizationRoute } from 'domains/characterizations/navigation/routes'
import { RouteComponentProps } from 'react-router'
import trackCharacterizationAnalytics from 'domains/characterizations/utils/trackCharacterizationAnalytics'
import { waitForServer } from 'lib/utils/wait'
import { showToast, ERROR_TOAST } from 'lib/utils/toast'
import {
  OTHER_SOURCE,
  UPLOAD_ZIP_FILE_SOURCE,
  URL_ZIP_FILE_SOURCE,
} from 'domains/characterizations/constants/createCharacterizationCSV'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import ReportThreeTopSection from 'domains/reports/components/ReportThreeTopSection'
import { COMMON_CARTRIDGE_SPEC } from 'domains/specifications/constants/CommonCartridge'
import BaseCommonCartridgeCharacterizationsBreadCrumbs from 'domains/characterizations/components/BaseCommonCartridgeCharacterizationsBreadCrumbs'
import CreateCCCharacterizationSourceDetails from 'domains/characterizations/components/CreateCharacterization/CreateCCCharacterizationSourceDetails'
import { COMMON_CARTRIDGE_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/commonCartridgeCharacterizations'
import { createFileCharacterization } from 'domains/characterizations/workflows/createFileCharacterization'
import {
  getRoute,
  CHARACTERIZATIONS_ROUTE,
} from 'domains/application/navigation/routes'
import { COMMON_CARTRIDGE_BASE_NAV_ROUTE } from 'domains/specifications/constants/specNavigation'

const credsValidation = Yup.string().when(['zipFileSource'], {
  is: (zipFileSource) => {
    return zipFileSource === URL_ZIP_FILE_SOURCE
  },
  then: Yup.string().required('Required'),
  otherwise: Yup.string(),
})
const validationSchema = Yup.object().shape({
  label: Yup.string().required('Required'),
  source: Yup.string().required('Required'),
  otherSource: Yup.string().when(['source'], {
    is: (source) => source === OTHER_SOURCE,
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  format: Yup.mixed()
    .oneOf(
      [
        COMMON_CARTRIDGE_V1P2,
        COMMON_CARTRIDGE_V1P3,
        THIN_COMMON_CARTRIDGE_V1P2,
        THIN_COMMON_CARTRIDGE_V1P3,
      ],
      'Please select a specification',
    )
    .required('Required'),
  zipFileURL: Yup.string().when(['zipFileSource'], {
    is: (zipFileSource) => zipFileSource === URL_ZIP_FILE_SOURCE,
    then: Yup.string().url('Invalid URL').required('Required'),
    otherwise: Yup.string(),
  }),
  zipFile: Yup.mixed().when(['zipFileSource'], {
    is: (zipFileSource) => zipFileSource === UPLOAD_ZIP_FILE_SOURCE,
    then: Yup.mixed()
      .required('Required')
      .test(
        'isZipFile',
        'File is too big. Max 30GB.',
        (zipFile) =>
          utils.hasValue(zipFile) &&
          !utils.files.isFileLargerThan(
            utils.files.ONE_GB_SIZE * 30,
            zipFile.size,
          ),
      )
      .test(
        'isZipFile',
        'Only files with a .imscc extension are allowed',
        (zipFile) =>
          utils.hasValue(zipFile) &&
          utils.files.hasFileExtension(zipFile.name, '.imscc'),
      ),
    otherwise: Yup.mixed(),
  }),
  username: credsValidation,
  password: credsValidation,
})

interface ICreateCharacterization extends INewCharacterization {
  uploadProgress: number
}
const defaultCharacterization: ICreateCharacterization = {
  color: colors.primary,
  label: '',
  notes: '',

  source: '',
  otherSource: '',

  zipFileSource: 'upload',
  zipFile: null,
  zipFileURL: '',

  username: '',
  password: '',

  uploadProgress: 0,
}

const DETAIL_HEIGHT = 720

export default function CreateCommonCartridgeCharacterizationScreen(
  props: RouteComponentProps,
) {
  const isMounted = React.useRef(false)
  const [characterizationId, setCharacterizationId] = React.useState(0)

  React.useEffect(() => {
    isMounted.current = true
    trackCharacterizationAnalytics('started_create_characterization', {
      type: 'Common Cartridge',
    })
    return () => {
      isMounted.current = false
    }
  }, [])

  const onSave = async (
    values: INewCharacterization,
    bag: FormikHelpers<ICreateCharacterization>,
  ) => {
    try {
      const onProgress = (progress: number) =>
        bag.setFieldValue('uploadProgress', progress)

      const characterization = await createFileCharacterization(
        COMMON_CARTRIDGE_CHARACTERIZATIONS_RECORD,
        values,
        onProgress,
      )
      if (!characterization) {
        bag.setSubmitting(false)
        showToast(ERROR_TOAST, 'Failed to create characterization')
      }

      setCharacterizationId(characterization.id)
      const [
        timedOut,
        isComplete,
      ] = await waitForServer(
        COMMON_CARTRIDGE_CHARACTERIZATIONS_RECORD,
        characterization.id,
        (data) => utils.hasValue(R.propOr(false, 'status', data)),
      )

      if (isMounted.current) {
        bag.setSubmitting(false)
        if (timedOut) {
          showToast(
            'info',
            'Your report is still processing. Please check back later.',
          )
          props.history.push(
            getRoute(
              `${CHARACTERIZATIONS_ROUTE}/${COMMON_CARTRIDGE_BASE_NAV_ROUTE}`,
            ),
          )
        } else if (isComplete) {
          props.history.push(
            getCharacterizationRoute(
              characterization.id,
              values.format! as AnySpecFormatType,
            ),
          )
        } else {
          throw new Error('Request to test completion of CC report failed')
        }
      }
    } catch (error) {
      console.log(error) // eslint-disable-line
      showToast(ERROR_TOAST, 'Failed to create characterization')
      bag.setSubmitting(false)
    }
  }

  const renderFinalWidget = (
    values: ICreateCharacterization,
    isSubmitting: boolean,
    handleSubmit: any,
    handleChange: any,
    setFieldValue: any,
  ) => {
    if (!utils.hasValue(values.format)) {
      return (
        <DetailsTopWidget
          title="Characterization..."
          dataTest="characterization-placeholder-widget"
          height={DETAIL_HEIGHT}
        >
          <Text mt={4} fontSize={[16, 16, 16, 18]} lineHeight="26px" ml={2}>
            Complete <Span fontWeight={700}>Characterization Information.</Span>
          </Text>
        </DetailsTopWidget>
      )
    }

    return (
      <CreateCharacterizationFileDetails
        format={values.format || ''}
        zipFileSource={values.zipFileSource}
        zipFile={values.zipFile}
        zipFileURL={values.zipFileURL}
        username={values.username || ''}
        password={values.password || ''}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        uploadProgress={values.uploadProgress}
        characterizationId={characterizationId}
        height={DETAIL_HEIGHT}
      />
    )
  }

  return (
    <View variant="screen">
      <BaseCommonCartridgeCharacterizationsBreadCrumbs
        crumbs={[{ name: 'Add New Common Cartridge' }]}
      />

      <Formik
        initialValues={defaultCharacterization}
        validationSchema={validationSchema}
        onSubmit={onSave}
        enableReinitialize={true}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form encType="multipart/form-data" autoComplete="off">
            <ReportThreeTopSection>
              <CreateCharacterizationBasicDetails
                format={values.format}
                color={values.color}
                label={values.label}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                height={DETAIL_HEIGHT}
                specificationName={COMMON_CARTRIDGE_SPEC}
              />

              <CreateCCCharacterizationSourceDetails
                source={values.source}
                otherSource={values.otherSource}
                notes={values.notes}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                height={DETAIL_HEIGHT}
              />

              {renderFinalWidget(
                values,
                isSubmitting,
                handleSubmit,
                handleChange,
                setFieldValue,
              )}
            </ReportThreeTopSection>

            {utils.hasValue(values.format) && values.format !== 'Select One' && (
              <View mt={2}>
                <InlineAlert
                  variant="info"
                  message={`Uploaded or accessed data is not retained by Compatibility Check and all files are deleted at the end of characterization.`}
                />
              </View>
            )}
          </Form>
        )}
      </Formik>
    </View>
  )
}
