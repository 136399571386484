import * as R from 'ramda'
import { utils, records } from '@ims/1edtech-frontend-common'
import { createSelector } from 'reselect'

import { PRODUCTS_RECORD } from 'lib/records/modules/products'
import IProduct from 'domains/products/models/IProduct'
import { RootState } from 'lib/store/rootReducer'

export const getRosteringToolId = (product: IProduct) =>
  R.pathOr(0, ['rosteringTool', 'id'], product)

export const rosteringToolIdSelector = (
  getId: records.entitiesSelectors.getIdType = 'id',
) =>
  createSelector<RootState, any, IProduct, number | undefined>(
    records.entitiesSelectors.entityByIdSelector(PRODUCTS_RECORD, getId),
    getRosteringToolId,
  )

export const rosteringToolSelector = (
  getId: records.entitiesSelectors.getIdType,
) =>
  createSelector<RootState, any, number | undefined, any, IProduct>(
    rosteringToolIdSelector(getId),
    records.entitiesSelectors.entityListSelector(PRODUCTS_RECORD),
    (id, products) => R.propOr(null, `${id}`, products),
  )

export const rosteringToolPropSelector = (
  prop: string | number | (string | number)[],
  fallback: any = null,
  getId: records.entitiesSelectors.getIdType = 'id',
) =>
  createSelector<RootState, any, IProduct | null, any>(
    rosteringToolSelector(getId),
    R.pathOr(fallback, utils.array.ensureArray(prop)),
  )
