import * as R from 'ramda'

export default interface IIMSCertification {
  imsCertTitle: string
  imsCertType: string
}

export const APIP_PARENT_DISPLAY = 'Accessible Portable Item Protocol'
export const CALIPER_PARENT_DISPLAY = 'Caliper Analytics'
export const COMMON_CARTRIDE_PARENT_DISPLAY = 'Common Cartridge'
export const CASE_PARENT_DISPLAY =
  'Competencies & Academic Standards Exchange (CASE)'
export const CLR_PARENT_DISPLAY = 'Comprehensive Learner Record (CLR)'
export const DATA_PRIVACY_PARENT_DISPLAY = 'Data Privacy'
export const LTI_RESOURCE_SEARCH_PARENT_DISPLAY = 'LTI Resource Search'
export const LIS_PARENT_DISPLAY = 'Learning Information Services (LIS)'
export const LTI_PARENT_DISPLAY = 'Learning Tools Interoperability (LTI)'
export const ONEROSTER_PARENT_DISPLAY = 'OneRoster'
export const OPEN_BADGES_PARENT_DISPLAY = 'Open Badges'
export const QTI_PARENT_DISPLAY = 'Question & Test Interoperability (QTI)'

export const LTI_PREFIX = 'lti_'
export const ONEROSTER_PREFIX = 'oneroster_'
export const CASE_PREFIX = 'case_'
export const OPEN_BADGES_PREFIX = 'openbadges_'
export const CALIPER_PREFIX = 'caliper_'
export const COMMON_CARTRIDGE_PREFIX = 'cc_'
export const LIS_PREFIX = 'lis_'
export const DATA_PRIVACY_PREFIX = 'privacy_'
export const APIP_PREFIX = 'apip_'
export const QTI_PREFIX = 'qti_'
export const CLR_PREFIX = 'clr_'

export const CERT_PREFIX_TO_PARENT: { [key: string]: string } = {
  [LTI_PREFIX]: LTI_PARENT_DISPLAY,
  [ONEROSTER_PREFIX]: ONEROSTER_PARENT_DISPLAY,
  [CASE_PREFIX]: CASE_PARENT_DISPLAY,
  [OPEN_BADGES_PREFIX]: OPEN_BADGES_PARENT_DISPLAY,
  [CALIPER_PREFIX]: CALIPER_PARENT_DISPLAY,
  [COMMON_CARTRIDGE_PREFIX]: COMMON_CARTRIDE_PARENT_DISPLAY,
  [LIS_PREFIX]: LIS_PARENT_DISPLAY,
  [DATA_PRIVACY_PREFIX]: DATA_PRIVACY_PARENT_DISPLAY,
  [APIP_PREFIX]: APIP_PARENT_DISPLAY,
  [QTI_PREFIX]: QTI_PARENT_DISPLAY,
  [CLR_PREFIX]: CLR_PARENT_DISPLAY,
}
export const CERT_PREFIXES: string[] = [
  LTI_PREFIX,
  ONEROSTER_PREFIX,
  CASE_PREFIX,
  OPEN_BADGES_PREFIX,
  CALIPER_PREFIX,
  COMMON_CARTRIDGE_PREFIX,
  LIS_PREFIX,
  DATA_PRIVACY_PREFIX,
  APIP_PREFIX,
  QTI_PREFIX,
  CLR_PREFIX,
]

export const findCertPrefix = (cert: IIMSCertification) =>
  R.find((prefix) => cert.imsCertType.startsWith(`${prefix}`))(CERT_PREFIXES)

export const groupCerts = (
  certs: IIMSCertification[],
): { parent: string; children: IIMSCertification[] }[] => {
  const map = certs.reduce((agg, cert) => {
    const foundPrefix = findCertPrefix(cert)
    if (foundPrefix) {
      const parent = CERT_PREFIX_TO_PARENT[`${foundPrefix}`]
      const currentList = R.pathOr([], [parent], agg)
      return R.assoc(parent, [...currentList, cert], agg)
    }
    return agg
  }, {})
  const list = R.keys(map).map((k) => map[k] as IIMSCertification[])
  list.sort()
  return list.map((children) => {
    const first = children[0]
    const prefix = findCertPrefix(first)
    return {
      parent: CERT_PREFIX_TO_PARENT[`${prefix}`],
      children,
    }
  })
}

export const LTI_V1P3_DISPLAY = 'Learning Tools Interoperability (LTI) v1.3'
export const LTI_V1P3_CERT_TYPE = 'lti_1p3'

export const LTI_ADVANTAGE_V1P0_DISPLAY = 'LTI Advantage Complete'
export const LTI_ADVANTAGE_V1P0_CERT_TYPE = 'lti_adv_1p0'

export const LTI_AGS_V2P0_DISPLAY = 'LTI Assignment & Grade Services 2.0'
export const LTI_AGS_V2P0_CERT_TYPE = 'lti_ags_2p0'

export const LTI_DL_V2P0_DISPLAY = 'LTI Deep Linking 2.0'
export const LTI_DL_V2P0_CERT_TYPE = 'lti_dl_2p0'

export const LTI_DL_V1P0_DISPLAY = 'LTI Deep Linking 1.0'
export const LTI_DL_V1P0_CERT_TYPE = 'lti_dl_1p0'

export const LTI_BO_V1P0_DISPLAY = 'LTI Basic Outcomes 1.0'
export const LTI_BO_V1P0_CERT_TYPE = 'lti_bo_1p0'

export const LTI_RPS_V1P0_DISPLAY =
  'LTI Names and Role Provisioning Services 1.0'
export const LTI_RPS_V1P0_CERT_TYPE = 'lti_rps_1p0'

export const LTI_RPS_V2P0_DISPLAY =
  'LTI Names and Role Provisioning Services 2.0'
export const LTI_RPS_V2P0_CERT_TYPE = 'lti_rps_2p0'

export const LTI_PROCTORING_V1P0_DISPLAY = 'LTI Proctoring Services v1.0'
export const LTI_PROCTORING_V1P0_CERT_TYPE = 'proctoring_1p0'

export const LTI_RS_DISPLAY = 'LTI Resource Search 1.0'
export const LTI_RS_CERT_TYPE = 'lti_rs_1p0'

export const LTI_V1P0_DISPLAY = 'Learning Tools Interoperability (LTI) v1.0'
export const LTI_V1P0_CERT_TYPE = 'lti_1p0'

export const LTI_V1P1_DISPLAY = 'Learning Tools Interoperability (LTI) v1.1'
export const LTI_V1P1_CERT_TYPE = 'lti_1p1'

export const LTI_V1P1P1_DISPLAY = 'Learning Tools Interoperability (LTI) v1.1.1'
export const LTI_V1P1P1_CERT_TYPE = 'lti_1p1p1'

export const LTI_V1P1P2_DISPLAY = 'Learning Tools Interoperability (LTI) v1.1.2'
export const LTI_V1P1P2_CERT_TYPE = 'lti_1p1p2'

export const LTI_V1P2_DISPLAY = 'Learning Tools Interoperability (LTI) v1.2'
export const LTI_V1P2_CERT_TYPE = 'lti_1p2'

export const LTI_V2P0_DISPLAY = 'Learning Tools Interoperability (LTI) v2.0'
export const LTI_V2P0_CERT_TYPE = 'lti_2p0'

export const ONEROSTER_V1P0_DISPLAY = 'OneRoster 1.0'
export const ONEROSTER_V1P0_CERT_TYPE = 'oneroster_1p0'

export const ONEROSTER_V1P1_DISPLAY = 'OneRoster 1.1'
export const ONEROSTER_V1P1_CERT_TYPE = 'oneroster_1p1'

export const ONEROSTER_V1P2_DISPLAY = 'OneRoster 1.2'
export const ONEROSTER_V1P2_CERT_TYPE = 'oneroster_1p2'

export const CASE_V1P0_DISPLAY =
  'Competencies and Academic Standards Exchange (CASE) Service v1.0'
export const CASE_V1P0_CERT_TYPE = 'case_1p0'

export const OPEN_BADGES_V1P0_DISPLAY = 'Open Badges v1.0'
export const OPEN_BADGES_V1P0_CERT_TYPE = 'openbadges_1p0'

export const OPEN_BADGES_V2P0_DISPLAY = 'Open Badges v2.0'
export const OPEN_BADGES_V2P0_CERT_TYPE = 'openbadges_2p0'

export const CALIPER_V1P0_DISPLAY = 'Caliper Analytics v1.0'
export const CALIPER_V1P0_CERT_TYPE = 'caliper_1p0'

export const CALIPER_V1P1_DISPLAY = 'Caliper Analytics v1.1'
export const CALIPER_V1P1_CERT_TYPE = 'caliper_1p1'

export const CALIPER_V1P2_DISPLAY = 'Caliper Analytics v1.2'
export const CALIPER_V1P2_CERT_TYPE = 'caliper_1p2'

export const COMMON_CARTRIDGE_V1P0_DISPLAY = 'Common Cartridge v1.0'
export const COMMON_CARTRIDGE_V1P0_CERT_TYPE = 'cc_v1p0'

export const COMMON_CARTRIDGE_V1P1_DISPLAY = 'Common Cartridge v1.1'
export const COMMON_CARTRIDGE_V1P1_CERT_TYPE = 'cc_v1p1'

export const COMMON_CARTRIDGE_V1P2_DISPLAY = 'Common Cartridge v1.2'
export const COMMON_CARTRIDGE_V1P2_CERT_TYPE = 'cc_v1p2'

export const COMMON_CARTRIDGE_V1P3_DISPLAY = 'Common Cartridge v1.3'
export const COMMON_CARTRIDGE_V1P3_CERT_TYPE = 'cc_v1p3'

export const THIN_COMMON_CARTRIDGE_V1P2_DISPLAY = 'Thin Common Cartridge v1.2'
export const THIN_COMMON_CARTRIDGE_V1P2_CERT_TYPE = 'cc_thin_v1p2'

export const THIN_COMMON_CARTRIDGE_V1P3_DISPLAY = 'Thin Common Cartridge 1.3'
export const THIN_COMMON_CARTRIDGE_V1P3_CERT_TYPE = 'cc_thin_v1p3'

export const LIS_V2P0_DISPLAY = 'Learning Information Services (LIS) v 2.0'
export const LIS_V2P0_CERT_TYPE = 'lis_2p0'

export const DATA_PRIVACY_DISPLAY = 'Data Privacy'
export const DATA_PRIVACY_CERT_TYPE = 'privacy_shield'

export const APIP_V1P0_DISPLAY =
  'Accessible Portable Item Protocol® (APIP) v1.0'
export const APIP_V1P0_CERT_TYPE = 'apip_1p0'

export const QTI_V2P1_DISPLAY = 'Question & Test Interoperability (QTI) v2.1'
export const QTI_V2P1_CERT_TYPE = 'qti_2p1'

export const QTI_V3P0_DISPLAY = 'Question & Test Interoperability (QTI) v3.0'
export const QTI_V3P0_CERT_TYPE = 'qti_3'

export const CLR_V1P1_DISPLAY = 'Comprehensive Learner Record (CLR) v1.0 '
export const CLR_V1P1_CERT_TYPE = 'clr_1p0'

export const CERT_TYPE_PARENT_DISPLAY_MAP = {
  [APIP_V1P0_CERT_TYPE]: APIP_PARENT_DISPLAY,
  [CALIPER_V1P0_CERT_TYPE]: CALIPER_PARENT_DISPLAY,
  [CALIPER_V1P1_CERT_TYPE]: CALIPER_PARENT_DISPLAY,
  [CALIPER_V1P2_CERT_TYPE]: CALIPER_PARENT_DISPLAY,
  [CASE_V1P0_CERT_TYPE]: CASE_PARENT_DISPLAY,
  [CLR_V1P1_CERT_TYPE]: CLR_PARENT_DISPLAY,
  [COMMON_CARTRIDGE_V1P0_CERT_TYPE]: COMMON_CARTRIDE_PARENT_DISPLAY,
  [COMMON_CARTRIDGE_V1P1_CERT_TYPE]: COMMON_CARTRIDE_PARENT_DISPLAY,
  [COMMON_CARTRIDGE_V1P2_CERT_TYPE]: COMMON_CARTRIDE_PARENT_DISPLAY,
  [COMMON_CARTRIDGE_V1P3_CERT_TYPE]: COMMON_CARTRIDE_PARENT_DISPLAY,
  [THIN_COMMON_CARTRIDGE_V1P2_CERT_TYPE]: COMMON_CARTRIDE_PARENT_DISPLAY,
  [THIN_COMMON_CARTRIDGE_V1P3_CERT_TYPE]: COMMON_CARTRIDE_PARENT_DISPLAY,
  [DATA_PRIVACY_CERT_TYPE]: DATA_PRIVACY_PARENT_DISPLAY,
  [LIS_V2P0_CERT_TYPE]: LIS_PARENT_DISPLAY,
  [LTI_ADVANTAGE_V1P0_CERT_TYPE]: LTI_PARENT_DISPLAY,
  [LTI_AGS_V2P0_CERT_TYPE]: LTI_PARENT_DISPLAY,
  [LTI_BO_V1P0_CERT_TYPE]: LTI_PARENT_DISPLAY,
  [LTI_DL_V1P0_CERT_TYPE]: LTI_PARENT_DISPLAY,
  [LTI_DL_V2P0_CERT_TYPE]: LTI_PARENT_DISPLAY,
  [LTI_PROCTORING_V1P0_CERT_TYPE]: LTI_PARENT_DISPLAY,
  [LTI_RPS_V1P0_CERT_TYPE]: LTI_PARENT_DISPLAY,
  [LTI_RPS_V2P0_CERT_TYPE]: LTI_PARENT_DISPLAY,
  [LTI_V1P0_CERT_TYPE]: LTI_PARENT_DISPLAY,
  [LTI_V1P1P1_CERT_TYPE]: LTI_PARENT_DISPLAY,
  [LTI_V1P1P2_CERT_TYPE]: LTI_PARENT_DISPLAY,
  [LTI_V1P1_CERT_TYPE]: LTI_PARENT_DISPLAY,
  [LTI_V1P2_CERT_TYPE]: LTI_PARENT_DISPLAY,
  [LTI_V1P3_CERT_TYPE]: LTI_PARENT_DISPLAY,
  [LTI_V2P0_CERT_TYPE]: LTI_PARENT_DISPLAY,
  [LTI_RS_CERT_TYPE]: LTI_RESOURCE_SEARCH_PARENT_DISPLAY,
  [ONEROSTER_V1P0_CERT_TYPE]: ONEROSTER_PARENT_DISPLAY,
  [ONEROSTER_V1P1_CERT_TYPE]: ONEROSTER_PARENT_DISPLAY,
  [ONEROSTER_V1P2_CERT_TYPE]: ONEROSTER_PARENT_DISPLAY,
  [OPEN_BADGES_V1P0_CERT_TYPE]: OPEN_BADGES_PARENT_DISPLAY,
  [OPEN_BADGES_V2P0_CERT_TYPE]: OPEN_BADGES_PARENT_DISPLAY,
  [QTI_V2P1_CERT_TYPE]: QTI_PARENT_DISPLAY,
  [QTI_V3P0_CERT_TYPE]: QTI_PARENT_DISPLAY,
}

interface ICert {
  name: string
  type: string
}
interface IParentCerts {
  [parent: string]: ICert[]
}
export const CERT_PARENT_DISPLAY_MAP: IParentCerts = {
  [APIP_PARENT_DISPLAY]: [
    {
      name: APIP_V1P0_DISPLAY,
      type: APIP_V1P0_CERT_TYPE,
    },
  ],
  [CALIPER_PARENT_DISPLAY]: [
    {
      name: CALIPER_V1P0_DISPLAY,
      type: CALIPER_V1P0_CERT_TYPE,
    },
    {
      name: CALIPER_V1P1_DISPLAY,
      type: CALIPER_V1P1_CERT_TYPE,
    },
    {
      name: CALIPER_V1P2_DISPLAY,
      type: CALIPER_V1P2_CERT_TYPE,
    },
  ],
  [CASE_PARENT_DISPLAY]: [
    {
      name: CASE_V1P0_DISPLAY,
      type: CASE_V1P0_CERT_TYPE,
    },
  ],
  [CLR_PARENT_DISPLAY]: [
    {
      name: CLR_V1P1_DISPLAY,
      type: CLR_V1P1_CERT_TYPE,
    },
  ],
  [COMMON_CARTRIDE_PARENT_DISPLAY]: [
    {
      name: COMMON_CARTRIDGE_V1P0_DISPLAY,
      type: COMMON_CARTRIDGE_V1P0_CERT_TYPE,
    },
    {
      name: COMMON_CARTRIDGE_V1P1_DISPLAY,
      type: COMMON_CARTRIDGE_V1P1_CERT_TYPE,
    },
    {
      name: COMMON_CARTRIDGE_V1P2_DISPLAY,
      type: COMMON_CARTRIDGE_V1P2_CERT_TYPE,
    },
    {
      name: COMMON_CARTRIDGE_V1P3_DISPLAY,
      type: COMMON_CARTRIDGE_V1P3_CERT_TYPE,
    },
    {
      name: THIN_COMMON_CARTRIDGE_V1P2_DISPLAY,
      type: THIN_COMMON_CARTRIDGE_V1P2_DISPLAY,
    },
    {
      name: THIN_COMMON_CARTRIDGE_V1P3_DISPLAY,
      type: THIN_COMMON_CARTRIDGE_V1P3_DISPLAY,
    },
  ],
  [DATA_PRIVACY_PARENT_DISPLAY]: [
    {
      name: DATA_PRIVACY_DISPLAY,
      type: DATA_PRIVACY_CERT_TYPE,
    },
  ],
  [LIS_PARENT_DISPLAY]: [
    {
      name: LIS_V2P0_DISPLAY,
      type: LIS_V2P0_CERT_TYPE,
    },
  ],
  [LTI_PARENT_DISPLAY]: [
    {
      name: LTI_ADVANTAGE_V1P0_DISPLAY,
      type: LTI_ADVANTAGE_V1P0_CERT_TYPE,
    },
    {
      name: LTI_AGS_V2P0_DISPLAY,
      type: LTI_AGS_V2P0_CERT_TYPE,
    },
    {
      name: LTI_BO_V1P0_DISPLAY,
      type: LTI_BO_V1P0_CERT_TYPE,
    },
    {
      name: LTI_DL_V1P0_DISPLAY,
      type: LTI_DL_V1P0_CERT_TYPE,
    },
    {
      name: LTI_DL_V2P0_DISPLAY,
      type: LTI_DL_V2P0_CERT_TYPE,
    },
    {
      name: LTI_PROCTORING_V1P0_DISPLAY,
      type: LTI_PROCTORING_V1P0_CERT_TYPE,
    },
    {
      name: LTI_RPS_V1P0_DISPLAY,
      type: LTI_RPS_V1P0_CERT_TYPE,
    },
    {
      name: LTI_RPS_V2P0_DISPLAY,
      type: LTI_RPS_V2P0_CERT_TYPE,
    },
    {
      name: LTI_V1P0_DISPLAY,
      type: LTI_V1P0_CERT_TYPE,
    },
    {
      name: LTI_V1P1P1_DISPLAY,
      type: LTI_V1P1P1_CERT_TYPE,
    },
    {
      name: LTI_V1P1P2_DISPLAY,
      type: LTI_V1P1P2_CERT_TYPE,
    },
    {
      name: LTI_V1P1_DISPLAY,
      type: LTI_V1P1_CERT_TYPE,
    },
    {
      name: LTI_V1P2_DISPLAY,
      type: LTI_V1P2_CERT_TYPE,
    },
    {
      name: LTI_V1P3_DISPLAY,
      type: LTI_V1P3_CERT_TYPE,
    },
    {
      name: LTI_V2P0_DISPLAY,
      type: LTI_V2P0_CERT_TYPE,
    },
  ],
  [LTI_RESOURCE_SEARCH_PARENT_DISPLAY]: [
    {
      name: LTI_RS_DISPLAY,
      type: LTI_RS_CERT_TYPE,
    },
  ],
  [ONEROSTER_PARENT_DISPLAY]: [
    {
      name: ONEROSTER_V1P0_DISPLAY,
      type: ONEROSTER_V1P0_CERT_TYPE,
    },
    {
      name: ONEROSTER_V1P1_DISPLAY,
      type: ONEROSTER_V1P1_CERT_TYPE,
    },
    {
      name: ONEROSTER_V1P2_DISPLAY,
      type: ONEROSTER_V1P2_CERT_TYPE,
    },
  ],
  [OPEN_BADGES_PARENT_DISPLAY]: [
    {
      name: OPEN_BADGES_V1P0_DISPLAY,
      type: OPEN_BADGES_V1P0_CERT_TYPE,
    },
    {
      name: OPEN_BADGES_V2P0_DISPLAY,
      type: OPEN_BADGES_V2P0_CERT_TYPE,
    },
  ],
  [QTI_PARENT_DISPLAY]: [
    {
      name: QTI_V2P1_DISPLAY,
      type: QTI_V2P1_CERT_TYPE,
    },
    {
      name: QTI_V3P0_DISPLAY,
      type: QTI_V3P0_CERT_TYPE,
    },
  ],
}
