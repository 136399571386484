import React from 'react'
import * as R from 'ramda'

import ITransformedRESTCharacterizationReport, {
  ITransformedRESTServiceCharacterizationReport,
  ITransformedRESTServiceEndpointCharacterizationReport,
} from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import RESTServiceCharacterizationReport from 'domains/characterizations/components/CharacterizationReport/RESTServiceCharacterizationReport'
import { AnySpecFormatType } from 'domains/formats/constants/formats'
import PrintRESTEndpointReportHeader from 'domains/reports/components/PrintRESTEndpointReportHeader'

interface IProps {
  format: AnySpecFormatType
  report: ITransformedRESTCharacterizationReport
  service: ITransformedRESTServiceCharacterizationReport
  specification: IRestCSpec
}

export default function PrintRESTPCharacterizationReportService(props: IProps) {
  const endpoints = R.pathOr(
    [],
    ['endpointCharacterizations'],
    props.service,
  ) as ITransformedRESTServiceEndpointCharacterizationReport[]
  if (endpoints.length < 1) {
    return null
  }

  return (
    <div
      data-test={`characterization-report-service-${props.service.serviceName}`}
      className="border-2 border-black border-t-0"
    >
      {endpoints.map((endpoint, index) => (
        <div key={`rest-report-endpoint-${endpoint.endpointName}`}>
          <PrintRESTEndpointReportHeader
            format={props.format}
            endpoint={endpoint}
            index={index}
            data-test={`endpoint-report-header-${endpoint.endpointName}`}
          />
          <RESTServiceCharacterizationReport
            isOpen
            report={props.report}
            service={props.service}
            specification={props.specification}
            endpoint={endpoint}
          />
        </div>
      ))}
    </div>
  )
}
