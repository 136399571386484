import { HTMLAttributes } from 'react'
import * as CSS from 'csstype'
import styled from 'styled-components'
import * as SS from 'styled-system'

import { hoverStyle, animationStyle, animationDelay } from 'lib/components/View'
import colors from 'lib/styles/colors'
import { textOverflowStyle } from 'lib/components/Text'

interface ITableProps
  extends HTMLAttributes<HTMLTableElement>,
    SS.ColorProps,
    SS.HeightProps,
    SS.MinHeightProps,
    SS.MaxHeightProps,
    SS.SpaceProps,
    SS.MinWidthProps,
    SS.WidthProps,
    SS.MaxHeightProps {
  color?: CSS.Property.Color
  'data-test'?: string
  cellspacing?: string
  cellpadding?: string
}

// prettier-ignore
const Table = styled.table<ITableProps>`
  display: table;
  table-layout: auto;
  width: 100%;
  border: 0;
  border-collapse: collapse;
  ${SS.color}
  ${SS.minHeight}
  ${SS.height}
  ${SS.maxHeight}
  ${SS.minWidth}
  ${SS.width}
  ${SS.maxWidth}
  ${SS.space}
`
Table.displayName = 'Table'

interface ITableBodyProps
  extends HTMLAttributes<HTMLTableElement>,
    SS.ColorProps,
    SS.HeightProps,
    SS.MinHeightProps,
    SS.MaxHeightProps,
    SS.SpaceProps,
    SS.MinWidthProps,
    SS.WidthProps,
    SS.MaxHeightProps {
  color?: CSS.Property.Color
  'data-test'?: string
}
// prettier-ignore
const TableBody = styled.tbody<ITableBodyProps>`
  overflow: scroll;
  ${SS.color}
  ${SS.minHeight}
  ${SS.height}
  ${SS.maxHeight}
  ${SS.minWidth}
  ${SS.width}
  ${SS.maxWidth}
  ${SS.space}
`
TableBody.displayName = 'TableBody'

interface ITableHeadProps
  extends HTMLAttributes<HTMLTableElement>,
    SS.ColorProps,
    SS.HeightProps,
    SS.MinHeightProps,
    SS.MaxHeightProps,
    SS.SpaceProps,
    SS.MinWidthProps,
    SS.WidthProps,
    SS.MaxHeightProps {
  color?: CSS.Property.Color
  hover?: string
  'data-test'?: string
}
// prettier-ignore
const TableHead = styled.thead<ITableHeadProps>`
  border-bottom: solid 2px ${colors.background};
  ${hoverStyle}
  ${SS.color}
  ${SS.minHeight}
  ${SS.height}
  ${SS.maxHeight}
  ${SS.minWidth}
  ${SS.width}
  ${SS.maxWidth}
  ${SS.space}
`
TableHead.displayName = 'TableHead'

interface ITableRowProps
  extends HTMLAttributes<HTMLTableRowElement>,
    SS.ColorProps,
    SS.BorderTopProps,
    SS.HeightProps,
    SS.MinHeightProps,
    SS.MaxHeightProps,
    SS.SpaceProps,
    SS.MinWidthProps,
    SS.WidthProps,
    SS.MaxHeightProps {
  color?: CSS.Property.Color
  hover?: string
  'data-test'?: string
}
// prettier-ignore
const TableRow = styled.tr<ITableRowProps>`
  transition: all 0.2s;
  ${props => {
    const collpase = !!(props as any).collapse
    return `
      visibility: ${collpase ? 'collapse' : 'visible'};
      @media not all and (min-resolution:.001dpcm)
      { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
        display: ${collpase ? 'none' : 'visible'};
      }}
      opacity: ${collpase ? 0 : 1};
    `
  }}
  ${animationStyle};
  ${animationDelay};

  ${hoverStyle}
  ${SS.color}
  ${SS.borderTop}
  ${SS.minHeight}
  ${SS.height}
  ${SS.maxHeight}
  ${SS.minWidth}
  ${SS.width}
  ${SS.maxWidth}
  ${SS.space}
`
TableRow.displayName = 'TableRow'

interface ITableDefProps
  extends HTMLAttributes<HTMLTableCellElement>,
    SS.ColorProps,
    SS.HeightProps,
    SS.MinHeightProps,
    SS.MaxHeightProps,
    SS.SpaceProps,
    SS.MinWidthProps,
    SS.WidthProps,
    SS.MaxHeightProps {
  color?: CSS.Property.Color
  noAutomaticPadding?: boolean
  'data-test'?: string
}
// prettier-ignore
const TableDefinition = styled.td<ITableDefProps>`
  color: ${colors.text}
  vertical-align: middle;
  &:first-child {
    padding-left: ${props => (props.noAutomaticPadding ? '1px' : '8px')};
  }
  &:last-child {
    padding-right: ${props => (props.noAutomaticPadding ? '1px' : '8px')};
  }

  ${textOverflowStyle}
  ${SS.color}
  ${SS.minHeight}
  ${SS.height}
  ${SS.maxHeight}
  ${SS.minWidth}
  ${SS.width}
  ${SS.maxWidth}
  ${SS.space}
`
TableDefinition.displayName = 'TableDefinition'

const tableHeaderVariant = SS.variant({
  key: 'tableHeaderVariantStyles',
})
export const tableHeaderVariantStyles = {
  sortable: {
    fontWeight: '700',
  },
}

interface ITableHeaderProps
  extends HTMLAttributes<HTMLTableHeaderCellElement>,
    SS.ColorProps,
    SS.HeightProps,
    SS.MinHeightProps,
    SS.MaxHeightProps,
    SS.SpaceProps,
    SS.MinWidthProps,
    SS.WidthProps,
    SS.MaxHeightProps {
  color?: CSS.Property.Color
  variant?: string
  noAutomaticPadding?: boolean
  'data-test'?: string
}
// prettier-ignore
const TableHeader = styled.th<ITableHeaderProps>`
  font-size: 14px;
  font-weight: 400;
  color: #111;
  padding: 8px 0;
  &:first-child {
    padding-left: ${props => (props.noAutomaticPadding ? '1px' : '8px')};
  }
  &:last-child {
    padding-right: ${props => (props.noAutomaticPadding ? '1px' : '8px')};
  }
  ${tableHeaderVariant}
  ${textOverflowStyle}
  ${SS.color}
  ${SS.minHeight}
  ${SS.height}
  ${SS.maxHeight}
  ${SS.minWidth}
  ${SS.width}
  ${SS.maxWidth}
  ${SS.space}
`
TableHeader.displayName = 'TableHeader'

export { Table, TableBody, TableHead, TableRow, TableDefinition, TableHeader }
