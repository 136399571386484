export const LOM_TYPE_LANGUAGE_TEXT = '{languageText}'
export const LOM_TYPE_CATALOG_ENTRY = '{catalogEntry}'
export const LOM_TYPE_SOURCE_VALUE = '{sourceValue}'
export const LOM_TYPE_DATE = '{date}'
export const LOM_TYPE_CONTRIBUTE = '{contribute}'
export const LOM_TYPE_DURATION = '{duration}'
export const LOM_TYPE_REQUIREMENT = '{requirement}'

export const LOM_GENERAL_PATHS = [
  `general.identifier.[].${LOM_TYPE_CATALOG_ENTRY}`,
  `general.title.[].${LOM_TYPE_LANGUAGE_TEXT}`,
  'general.language',
  `general.description.[].${LOM_TYPE_LANGUAGE_TEXT}`,
  `general.keyword.[].${LOM_TYPE_LANGUAGE_TEXT}`,
  `general.coverage.[].${LOM_TYPE_LANGUAGE_TEXT}`,
  `general.structure.${LOM_TYPE_SOURCE_VALUE}`,
  `general.aggregationLevel.${LOM_TYPE_SOURCE_VALUE}`,
]

export const LOM_LIFECYCLE_PATHS = [
  `lifecycle.version.[].${LOM_TYPE_LANGUAGE_TEXT}`,
  `lifecycle.status.${LOM_TYPE_SOURCE_VALUE}`,
  `lifecycle.contribute.[].${LOM_TYPE_CONTRIBUTE}`,
]

export const LOM_META_METADATA_PATHS = [
  `metaMetadata.identifier.[].${LOM_TYPE_CATALOG_ENTRY}`,
  `metaMetadata.contribute.[].${LOM_TYPE_CONTRIBUTE}`,
  `metaMetadata.metadataSchema`,
  'metaMetadata.language',
]

export const LOM_TECHNICAL_PATHS = [
  `technical.format`,
  `technical.size`,
  'technical.location',
  `technical.requirement.[].${LOM_TYPE_REQUIREMENT}`,
  `technical.installationRemarks.[].${LOM_TYPE_LANGUAGE_TEXT}`,
  `technical.otherPlatformRequirements.[].${LOM_TYPE_LANGUAGE_TEXT}`,
  `technical.duration.${LOM_TYPE_DURATION}`,
]

export const LOM_EDUCATIONAL_PATHS = [
  `educational.[].interactivityType.${LOM_TYPE_SOURCE_VALUE}`,
  `educational.[].learningResourceType.[].${LOM_TYPE_SOURCE_VALUE}`,
  `educational.[].interactivityLevel.${LOM_TYPE_SOURCE_VALUE}`,
  `educational.[].semanticDensity.${LOM_TYPE_SOURCE_VALUE}`,
  `educational.[].intendedEndUserRole.[].${LOM_TYPE_SOURCE_VALUE}`,
  `educational.[].context.[].${LOM_TYPE_SOURCE_VALUE}`,
  `educational.[].typicalAgeRange.[].${LOM_TYPE_LANGUAGE_TEXT}`,
  `educational.[].difficulty.${LOM_TYPE_SOURCE_VALUE}`,
  `educational.[].typicalLearningTime.${LOM_TYPE_DURATION}`,
  `educational.[].description.[].${LOM_TYPE_LANGUAGE_TEXT}`,
  `educational.[].language`,
]

export const LOM_RELATION_PATHS = [
  `relation.[].kind.${LOM_TYPE_SOURCE_VALUE}`,
  `relation.[].resource.identifier.[].${LOM_TYPE_CATALOG_ENTRY}`,
  `relation.[].resource.description.[].${LOM_TYPE_LANGUAGE_TEXT}`,
]

export const LOM_ANNOTATION_PATHS = [
  `annotation.[].entity`,
  `annotation.[].date.${LOM_TYPE_DATE}`,
  `annotation.[].description.[].${LOM_TYPE_LANGUAGE_TEXT}`,
]

export const LOM_CLASSIFICATION_PATHS = [
  `classification.[].purpose.${LOM_TYPE_SOURCE_VALUE}`,
  `classification.[].description.[].${LOM_TYPE_LANGUAGE_TEXT}`,
  `classification.[].taxonPath.[].source.[].${LOM_TYPE_LANGUAGE_TEXT}`,
  `classification.[].taxonPath.[].taxon.[].id`,
  `classification.[].taxonPath.[].taxon.[].entry.[].${LOM_TYPE_LANGUAGE_TEXT}`,
  `classification.[].keyword.[].${LOM_TYPE_LANGUAGE_TEXT}`,
]

export const LOM_RIGHTS_PATHS = [
  `rights.cost.${LOM_TYPE_SOURCE_VALUE}`,
  `rights.copyrightAndOtherRestrictions.${LOM_TYPE_SOURCE_VALUE}`,
  `rights.description.[].${LOM_TYPE_LANGUAGE_TEXT}`,
]

export const LOM_PATHS = [
  ...LOM_GENERAL_PATHS,
  ...LOM_LIFECYCLE_PATHS,
  ...LOM_META_METADATA_PATHS,
  ...LOM_TECHNICAL_PATHS,
  ...LOM_EDUCATIONAL_PATHS,
  ...LOM_RELATION_PATHS,
  ...LOM_ANNOTATION_PATHS,
  ...LOM_CLASSIFICATION_PATHS,
  ...LOM_RIGHTS_PATHS,
]
