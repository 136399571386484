import React from 'react'
import * as R from 'ramda'
import { Link } from 'react-router-dom'
import { utils } from '@ims/1edtech-frontend-common'

import { Icon, View, Text } from 'lib/components'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import { getCharacterizationRoute } from 'domains/characterizations/navigation/routes'
import useCCCharacterizationReportCrumbs, {
  ICCCrumb,
} from 'domains/commonCartridge/hooks/useCCCharacterizationReportCrumbs'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import { CCReportTypeIcon } from 'domains/commonCartridge/constants/commonCartridge'
import CCCharacterizationReportShowMissingMDSwitch from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportShowMissingMDSwitch'

const TYPE_TO_ROOT_URL: { [key: string]: string } = {
  Item: 'items',
  Resource: 'resources',
  Standard: 'standards',
}
const TYPE_TO_ROOT_NAME: { [key: string]: string } = {
  Item: 'Table of Contents',
  items: 'Table of Contents',
  Resource: 'Resources',
  resources: 'Resources',
  Standard: 'Available Standards',
  standards: 'Available Standards',
}

interface ICrumbProps {
  characterization: ICharacterization
  index: number
  crumb: ICCCrumb
  isCurrent: boolean
}

function Crumb(props: ICrumbProps) {
  const [crumbs] = useCCCharacterizationReportCrumbs()
  const crumbsForIndex = R.take(
    Math.min(crumbs.length, props.index + 1),
    crumbs,
  )
  const route = getCharacterizationRoute(
    props.characterization.id,
    props.characterization.format,
    [TYPE_TO_ROOT_URL[props.crumb.type], `${props.crumb.id}`],
  )

  return (
    <View flexible="row-v-center" hover="clickable-bg" borderRadius={6}>
      <Link
        to={{ pathname: route, state: { crumbs: crumbsForIndex } }}
        data-test={`cc-crumb-${props.index}`}
      >
        <View
          bg={props.isCurrent ? 'white' : 'transparent'}
          height={38}
          minWidth={38}
          flexible="column-center"
          borderRadius={6}
        >
          <Text
            fontWeight={props.isCurrent ? 700 : undefined}
            color={props.isCurrent ? '#000' : 'textLight'}
          >
            {props.index + 1}
          </Text>
        </View>
      </Link>
    </View>
  )
}

interface IProps {
  title: string
  titleLink?: string
  characterization: ICharacterization
  tocUrl: 'items' | 'standards' | 'resources'
  hideLevels?: boolean
}

export default function CCCharacterizationReportNavigationHeader(
  props: IProps,
) {
  const [crumbs] = useCCCharacterizationReportCrumbs()
  const shouldShowTOCAsParent = crumbs.length - 2 < 0
  const parentIndex = shouldShowTOCAsParent ? 0 : crumbs.length - 1
  const parentCrumb = shouldShowTOCAsParent
    ? {}
    : R.pathOr({}, [crumbs.length - 2], crumbs)
  const parentId = utils.convertToInt(R.pathOr(0, ['id'], parentCrumb), 0)
  const parentName = R.pathOr('', ['title'], parentCrumb)
  const parentType = R.pathOr('', ['type'], parentCrumb)

  const tocRoute = getCharacterizationRoute(
    props.characterization.id,
    props.characterization.format,
    [props.tocUrl],
  )

  const parentRoute =
    parentId > 0 && crumbs.length > 1
      ? getCharacterizationRoute(
          props.characterization.id,
          props.characterization.format,
          [TYPE_TO_ROOT_URL[parentType], `${parentId}`],
        )
      : tocRoute
  const parentRouteTitle =
    utils.hasValue(parentName) && crumbs.length > 1
      ? parentName
      : TYPE_TO_ROOT_NAME[
          utils.hasValue(parentType) ? parentType : props.tocUrl
        ]
  const parentCrumbs = R.take(Math.min(crumbs.length, parentIndex), crumbs)

  const mt5 = crumbs.length > 5
  const eq5 = crumbs.length === 5
  const lte3 = crumbs.length <= 3
  const takeLast = eq5 ? 4 : mt5 ? 3 : lte3 ? crumbs.length - 1 : 3
  const showCrumbs = R.takeLast(takeLast, crumbs) as ICCCrumb[]

  const title = utils.hasValue(props.titleLink) ? (
    <Text fontSize={18} fontWeight={700}>
      <a href={props.titleLink} target="_blank" rel="noopener noreferrer">
        {props.title}
      </a>
      <Icon
        className="fas fa-external-link-alt"
        fontSize={18}
        color="primary"
        ml={1}
      />
    </Text>
  ) : (
    props.title
  )
  return (
    <View
      display="grid"
      gridTemplateColumns={['auto', '1fr auto']}
      gridColumnGap={[0, 3]}
      gridTemplateRows={['1fr 1fr', 'auto']}
      gridRowGap={[3, 0]}
    >
      <View flex={1} mt={3} flexible="row-space-between-wrap">
        <View>
          <Link
            to={{ pathname: parentRoute, state: { crumbs: parentCrumbs } }}
            data-test="back-link"
          >
            <View flexible="row-v-center" mb={2}>
              <Icon
                className="fas fa-long-arrow-alt-left"
                fontSize={16}
                color="primary"
              />
              <Icon
                data-test="back-link-icon"
                className={CCReportTypeIcon[parentType]}
                color="primary"
                ml={2}
                fontSize={14}
              />
              <Text fontWeight={700} ml={2} color="primary">
                {parentRouteTitle}
              </Text>
            </View>
          </Link>

          <WidgetHeader title={title} dataTest="screen-header" />
        </View>

        <CCCharacterizationReportShowMissingMDSwitch />
      </View>

      {!props.hideLevels && utils.hasValue(crumbs) && crumbs.length > 1 && (
        <View
          flexible="row-v-center"
          justifyContent={['flex-start', 'flex-end']}
          bg="#EAEAF4"
          borderRadius={6}
          py={2}
          px={3}
          flex={0}
        >
          <Crumb
            characterization={props.characterization}
            index={0}
            crumb={crumbs[0]}
            isCurrent={R.last(crumbs) === crumbs[0]}
          />
          {crumbs.length > 5 && (
            <Text fontSize={18} mx={1}>
              ...
            </Text>
          )}
          {showCrumbs.map((crumb, index) => (
            <Crumb
              key={`crumb-${index}`}
              characterization={props.characterization}
              index={R.findIndex(R.equals(crumb), crumbs)}
              crumb={crumb}
              isCurrent={R.last(crumbs)?.id === crumb.id}
            />
          ))}
        </View>
      )}
    </View>
  )
}
