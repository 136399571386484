export const NOT_STARTED_STATUS = 'NOT_STARTED'
export const IN_PROGRESS_STATUS = 'IN_PROGRESS'
export const COMPLETE_STATUS = 'COMPLETE'
export const UPDATED_STATUS = 'UPDATED'

export type FileStatuses =
  | 'NOT_STARTED'
  | 'IN_PROGRESS'
  | 'COMPLETE'
  | 'UPDATED'
