import React from 'react'
import { useSelector } from 'react-redux'

import {
  Icon,
  Text,
  View,
  FormInput,
  Span,
  Select,
  FormErrorMessage,
  InputLabel,
} from 'lib/components'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import CharacterizationIcon from 'domains/characterizations/components/CharacterizationIcon'
import ColorPickerModal from 'lib/components/ColorPickerModal'
import { SOURCES_RECORD } from 'lib/records/modules/sources'
import { getRecords } from 'lib/records/workflows/getRecords'
import {
  AnySpecFormatType,
  COMMON_CARTRIDGE_V1P2,
  COMMON_CARTRIDGE_V1P3,
  ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK,
  ONER_V1P1_CSV_RESOURCES_EXPORT_BULK,
  ONER_V1P1_CSV_ROSTERING_EXPORT_BULK,
  ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL_SHORT,
  ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL_SHORT,
  ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL_SHORT,
  ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL_SHORT,
  ONER_V1P2_CSV_ROSTERING_EXPORT_BULK,
  THIN_COMMON_CARTRIDGE_V1P2,
  THIN_COMMON_CARTRIDGE_V1P3,
} from 'domains/formats/constants/formats'
import { OAUTH_1A_REST_AUTH } from 'domains/formats/constants/OneRosterREST'
import { ONE_ROSTER_SPEC } from 'domains/specifications/constants/oneRoster'
import { isSupplierRoleSelector } from 'domains/authentication/selectors/roles'
import {
  CharacterizationProfileTypes,
  CHARACTERIZATION_PROFILE_TO_DISPLAY,
} from 'domains/characterizations/models/ICharacterization'

interface IProps {
  format?: AnySpecFormatType | 'Select One'
  profile?: 'basic' | 'sceds'
  color: string
  label: string
  setFieldValue: (key: string, value: any) => void
  handleChange: (event: any) => void
  height: number
  specificationName?: string
}

export default function CreateCharacterizationBasicDetails(props: IProps) {
  const isSupplier = useSelector(isSupplierRoleSelector)
  const isOneRoster = props.specificationName === ONE_ROSTER_SPEC
  React.useEffect(() => {
    const fetch = async () => {
      await getRecords({ record: SOURCES_RECORD, force: true })
    }
    fetch()
  }, [])

  const onFormatSelected = (e: React.FormEvent<HTMLSelectElement>) => {
    props.setFieldValue('format', e.currentTarget.value)
    props.setFieldValue(
      'restAuthMethod',
      e.currentTarget.value === ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL ||
        e.currentTarget.value ===
          ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL ||
        e.currentTarget.value === ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL
        ? OAUTH_1A_REST_AUTH
        : undefined,
    )
  }

  const [showColorPicker, setShowColorPicker] = React.useState(false)

  const onColorPicked = (color: string) => props.setFieldValue('color', color)

  const toggleColorPicker = () => setShowColorPicker(!showColorPicker)

  const OR_ROSTERING_OPTIONS = [
    <option key="rostering-csv" value={ONER_V1P1_CSV_ROSTERING_EXPORT_BULK}>
      {ONER_V1P1_CSV_ROSTERING_EXPORT_BULK}
    </option>,
    <option
      key="rostering-rest"
      value={ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL}
    >
      {ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL_SHORT}
    </option>
    /* TODO: Flag for OneRoster 1.2 CSV
     * <option key="rostering-1p2-csv" value={ONER_V1P2_CSV_ROSTERING_EXPORT_BULK}>
      {ONER_V1P2_CSV_ROSTERING_EXPORT_BULK}
    </option>
    */
  ]
  const OR_RESOURCES_OPTIONS = [
    <option key="resources-csv" value={ONER_V1P1_CSV_RESOURCES_EXPORT_BULK}>
      {ONER_V1P1_CSV_RESOURCES_EXPORT_BULK}
    </option>,
    <option
      key="resources-rest"
      value={ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL}
    >
      {ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL_SHORT}
    </option>,
  ]
  const OR_GRADEBOOK_OPTIONS = [
    <option key="gradebook-csv" value={ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK}>
      {ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK}
    </option>,
    <option
      key="gradebook-rest"
      value={ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL}
    >
      {ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL_SHORT}
    </option>,
    <option
      key="gradebook-rest-push"
      value={ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL}
    >
      {ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL_SHORT}
    </option>,
  ]
  const baseOptions = [...OR_ROSTERING_OPTIONS, ...OR_RESOURCES_OPTIONS]
  const OR_OPTIONS = isSupplier
    ? [...baseOptions, OR_GRADEBOOK_OPTIONS]
    : baseOptions
  return (
    <DetailsTopWidget
      title="Name & Type"
      dataTest="characterization-name-widget"
      height={props.height}
    >
      <View flexible="column-center" mt={4} mb={5}>
        <View flexible="row" alignItems="flex-end" mr="-45px">
          <CharacterizationIcon
            color={props.color}
            size={120}
            noResponsive={true}
            specificationName={props.specificationName}
          />
          <View
            flexible="row"
            onClick={toggleColorPicker}
            hover="clickable"
            width={80}
          >
            <Icon className="fas fa-pencil-alt" mr={2} />
            <Text variant="caption">Edit Color</Text>
          </View>
          <ColorPickerModal
            color={props.color}
            onChange={onColorPicked}
            onDone={toggleColorPicker}
            open={showColorPicker}
          />
        </View>
      </View>

      <View mb={3}>
        <InputLabel data-test="specification-label">
          Specification<Span color="error">*</Span>
        </InputLabel>
        <Select
          name="format"
          value={props.format}
          onChange={onFormatSelected}
          variant="bold"
          mb={2}
          pr={4}
          whiteSpace="nowrap"
          overflow="ellipsis"
          width="100%"
          data-test="format-select"
          aria-label="Characterization Format/Specification"
        >
          <option value={undefined}>Select One</option>
          {isOneRoster && OR_OPTIONS}
          {!isOneRoster && (
            <>
              <option value={COMMON_CARTRIDGE_V1P2}>
                {COMMON_CARTRIDGE_V1P2}
              </option>
              <option value={COMMON_CARTRIDGE_V1P3}>
                {COMMON_CARTRIDGE_V1P3}
              </option>
              <option value={THIN_COMMON_CARTRIDGE_V1P2}>
                {THIN_COMMON_CARTRIDGE_V1P2}
              </option>
              <option value={THIN_COMMON_CARTRIDGE_V1P3}>
                {THIN_COMMON_CARTRIDGE_V1P3}
              </option>
            </>
          )}
        </Select>
        <FormErrorMessage name="format" />
      </View>

      {isOneRoster && props.format !== ONER_V1P2_CSV_ROSTERING_EXPORT_BULK && (
        <View mb={3}>
          <InputLabel data-test="profile-label">
            Profile<Span color="error">*</Span>
          </InputLabel>
          <Select
            name="profile"
            value={props.profile}
            onChange={props.handleChange}
            variant="bold"
            mb={2}
            pr={4}
            whiteSpace="nowrap"
            overflow="ellipsis"
            width="100%"
            data-test="profile-select"
            aria-label="Characterization Profile selection"
          >
            <option value={CharacterizationProfileTypes.basic}>
              {CHARACTERIZATION_PROFILE_TO_DISPLAY.basic}
            </option>
            <option value={CharacterizationProfileTypes.sceds}>
              {CHARACTERIZATION_PROFILE_TO_DISPLAY.sceds}
            </option>
          </Select>
        </View>
      )}

      <FormInput
        label="Name"
        required={true}
        name="label"
        handleChange={props.handleChange}
        value={props.label}
        valueDataTest="characterization-name"
        placeholder="My Characterization"
        aria-label="Characterization Name"
      />
    </DetailsTopWidget>
  )
}
