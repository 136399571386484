import React from 'react'
import * as R from 'ramda'
import memoize from 'fast-memoize'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { utils, records } from '@ims/1edtech-frontend-common'

import {
  View,
  List,
  Text,
  Span,
  ListCallToActionButton,
  Image,
} from 'lib/components'
import BaseBreadCrumbs from 'domains/application/components/BaseBreadCrumbs'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import trackCharacterizationAnalytics from 'domains/characterizations/utils/trackCharacterizationAnalytics'
import CharacterizationNameCell from 'domains/characterizations/components/CharacterizationNameCell'
import CharacterizationStatusCell from 'domains/characterizations/components/CharacterizationStatusCell'
import CharacterizationNotesCell from 'domains/characterizations/components/CharacterizationNotesCell'
import CharacterizationDeleteCell from 'domains/characterizations/components/CharacterizationDeleteCell'
import {
  getRoute,
  CHARACTERIZATIONS_ROUTE,
} from 'domains/application/navigation/routes'
import Dialog from 'domains/application/modals/Dialog'
import CharacterizationSourceCell from 'domains/characterizations/components/CharacterizationSourceCell'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import { RootState } from 'lib/store/rootReducer'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import { OpenTableRows, IOpenTableRows } from 'lib/hooks/OpenTableRows'
import { IListColumnDefinition } from 'lib/components/List'
import { deleteCharacterization } from 'domains/characterizations/workflows/deleteCharacterization'
import { COMMON_CARTRIDGE_BASE_NAV_ROUTE } from 'domains/specifications/constants/specNavigation'
import { COMMON_CARTRIDGE_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/commonCartridgeCharacterizations'
import { commonCartridgeCharacterizationFormatsFilterSelector } from 'domains/application/selectors/application'
import { getRequest } from 'lib/api/api'
import { IDistrictORCDashboardStatistics } from 'domains/dashboard/models/IDashboardStatistics'
import CommonCartridgeCharacterizationsWidget from 'domains/dashboard/components/CommonCartridgeCharacterizationsWidget'
import CommonCartridgeValidTotalWidget from 'domains/dashboard/components/CommonCartridgeValidTotalWidget'

const keyExtractor = (char: ICharacterization, isSubRow: boolean) =>
  `${char.id}${isSubRow ? '-sub-row' : ''}`

export default function CommonCartridgeCheckDashboardScreen(
  props: RouteComponentProps,
) {
  const [statistics, setStatistics] =
    React.useState<IDistrictORCDashboardStatistics>({
      readyToCompareCount: 0,
      ccInvestigationCount: 0,
      validCCInvestigationCount: 0,
      characterisationsCount: 0,
      characterisationsWithErrorsCount: 0,
      comparisonCount: 0,
      characterisationsNeedingCorrections: [],
      recentCharacterisations: [],
      recentCcInvestigations: [],
      recentComparisons: [],
    })

  const getStats = async () => {
    const response = await getRequest('statistics/district')
    setStatistics(response.data)
  }

  React.useEffect(() => {
    getStats()
  }, [])

  const baseFilterParam = useSelector(
    commonCartridgeCharacterizationFormatsFilterSelector,
  )
  const orgId = useSelector((s: RootState) =>
    myOrganizationIdSelector(s, props),
  )
  const characterizations = useSelector((s: RootState) =>
    records.relatedRecordsSelectors.fullRelatedRecordsSelector(
      COMMON_CARTRIDGE_CHARACTERIZATIONS_RECORD,
      myOrganizationIdSelector,
    )(s, props),
  )
  const [charToDelete, setCharToDelete] =
    React.useState<ICharacterization | null>(null)

  const onCloseDeleteDialog = () => setCharToDelete(null)

  const onOpenDeleteDialog = (char: ICharacterization) => setCharToDelete(char)

  const onDeleteConfirmed = async () => {
    if (charToDelete) {
      await deleteCharacterization(charToDelete.id, charToDelete.format)
      await getStats()
      onCloseDeleteDialog()
    }
  }

  const onToggleNotes =
    (toggle: (id: string | number) => any) =>
    (characterization: ICharacterization) =>
      toggle(characterization.id)

  const onAddNew = () => {
    props.history.push(
      getRoute(
        `/${COMMON_CARTRIDGE_BASE_NAV_ROUTE}${CHARACTERIZATIONS_ROUTE}/${COMMON_CARTRIDGE_BASE_NAV_ROUTE}/create`,
      ),
    )
  }

  const getColumns = memoize(
    (openTableRowsOptions: IOpenTableRows): IListColumnDefinition[] => {
      return [
        {
          title: 'Characterization',
          maxWidth: [240, 240, 240, 350, 400],
          CellComponent: CharacterizationNameCell,
          cellProps: { maxWidthArray: [188, 188, 188, 298, 448] },
          sortKey: utils.sort.addSortValue('name@'),
        },
        {
          title: 'Source',
          maxWidth: [100, 100, 200],
          noHeaderCenter: true,
          noCenter: true,
          CellComponent: CharacterizationSourceCell,
          sortKey: utils.sort.addSortValue('source@,a.name:ascending'),
        },
        {
          title: 'Version',
          maxWidth: [150, 150, 200],
          noHeaderCenter: true,
          noCenter: true,
          accessor: 'format',
          sortKey: utils.sort.addSortValue('format@,a.name:ascending'),
        },
        {
          title: 'Notes',
          maxWidth: 72,
          CellComponent: ({ ...rest }) => (
            <CharacterizationNotesCell
              onClick={onToggleNotes(openTableRowsOptions.onToggleRow)}
              isOpen={R.propOr(
                false,
                (rest as ICharacterization).id.toString(),
                openTableRowsOptions.OpenTableRows,
              )}
              {...(rest as ICharacterization)}
            />
          ),
          noSort: true,
        },
        {
          title: 'Run Date/Time',
          maxWidth: 132,
          noHeaderCenter: true,
          noCenter: true,
          accessor: (char) =>
            utils.date.formatForDisplay(R.propOr(0, 'runDate', char)),
          sortKey: utils.sort.addSortValue('runDate@'),
        },
        {
          title: 'Status',
          maxWidth: 48,
          forceCenter: true,
          CellComponent: CharacterizationStatusCell,
          sortKey: utils.sort.addSortValue('status@,a.name:ascending', true),
        },
        {
          title: 'Delete',
          maxWidth: 48,
          forceCenter: true,
          noSort: true,
          CellComponent: CharacterizationDeleteCell,
          cellProps: { onClick: onOpenDeleteDialog },
        },
      ]
    },
  )

  const renderDeleteMessage = () =>
    charToDelete ? (
      <Text>
        You are about to delete characterization{' '}
        <Span fontWeight={700}>{charToDelete.name}.</Span>
      </Text>
    ) : (
      ''
    )

  const renderSubRow =
    (OpenTableRows: any) => (characterization: ICharacterization) =>
      OpenTableRows[R.prop('id', characterization)] ? (
        <Text
          fontWeight={700}
          p={3}
          data-test={`${characterization.id}-notes-row`}
        >
          {characterization.notes || 'No Notes'}
        </Text>
      ) : (
        false
      )

  return (
    <OpenTableRows>
      {(openTableRowsOptions: IOpenTableRows) => (
        <View variant="screen">
          <DocumentTitle title="Common Cartridge Check" />
          <BaseBreadCrumbs crumbs={[{ name: 'Common Cartridge Check' }]} />

          <View
            display="grid"
            gridTemplateColumns={[
              '1fr',
              'auto 1fr 1fr',
              'auto 1fr 1fr',
              'auto 1fr 1fr 1fr',
            ]}
            gridColumnGap="28px"
            mt={2}
            mb={4}
            ml={2}
          >
            <View key="logo" flexible="column-center">
              <Image src="/images/CCLogo.png" width="auto" height="80px" />
            </View>

            <CommonCartridgeValidTotalWidget
              count={R.pathOr(0, ['validCCInvestigationCount'], statistics)}
              fadeDelay={100}
            />

            <CommonCartridgeCharacterizationsWidget
              commonCartridgeCharacterizationsCount={R.pathOr(
                0,
                ['ccInvestigationCount'],
                statistics,
              )}
              fadeDelay={200}
            />
          </View>

          <List
            title="Common Cartridges"
            record={COMMON_CARTRIDGE_CHARACTERIZATIONS_RECORD}
            recordState={{
              ...characterizations,
              items: R.pathOr([], ['items'], characterizations).filter(
                utils.hasValue,
              ),
            }}
            baseFilterParam={baseFilterParam}
            parentRecordId={orgId}
            columns={getColumns(openTableRowsOptions)}
            rowHeight={68}
            dataTest="characterizations-list"
            renderSubRow={renderSubRow(openTableRowsOptions.OpenTableRows)}
            trackAnalytics={trackCharacterizationAnalytics}
            trackAnalyticsCategory="supplier_characterizations_list"
            initialSortColumnIndex={4}
            initialSortDescending={true}
            searchTemplate="name=~@@ OR filename=~@@ OR organizationName=~@@"
            callToAction={<ListCallToActionButton onClick={onAddNew} />}
            keyExtractor={keyExtractor}
            resetPaging={!charToDelete}
          />
          <Dialog
            isOpen={utils.hasValue(charToDelete)}
            icon="fas fa-trash"
            message={renderDeleteMessage}
            confirmText="Yes, Delete"
            onConfirm={onDeleteConfirmed}
            onDeny={onCloseDeleteDialog}
          />
        </View>
      )}
    </OpenTableRows>
  )
}
