import * as R from 'ramda'

import { INewCharacterization } from 'domains/characterizations/models/INewCharacterization'
import { createRecord } from 'lib/records/workflows/createRecord'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import store from 'lib/store'
import { OR_REST_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/orRESTCharacterizations'
import trackCharacterizationAnalytics from 'domains/characterizations/utils/trackCharacterizationAnalytics'

export const createCharacterizationREST = async (
  characterization: INewCharacterization,
) => {
  const orgId = myOrganizationIdSelector(store.getState(), {})
  const hostName = R.pathOr('', ['hostName'], characterization).replace(
    '/ims/oneroster/v1p1',
    '',
  )
  const { data, success } = await createRecord(
    OR_REST_CHARACTERIZATIONS_RECORD,
    {
      ...characterization,
      hostName,
      orgId,
    },
  )
  trackCharacterizationAnalytics('submitted_characterization', {
    specification: characterization.format,
    source: characterization.source,
    otherSource: characterization.otherSource,
    restAuthMethod: characterization.restAuthMethod,
    hostName,
  })

  return success ? data : success
}
