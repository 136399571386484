import { TRUSTED_APPS_RECORD } from 'lib/records/modules/trustedApplications'
import { deleteRecord } from 'lib/records/workflows/deleteRecord'
import { getRecords } from 'lib/records/workflows/getRecords'

export const deleteTrustedApp = async (id: number) => {
  const success = await deleteRecord(TRUSTED_APPS_RECORD, id)
  if (success) {
    await getRecords({
      record: TRUSTED_APPS_RECORD,
      replaceItems: true,
      force: true,
    })
  }
  return success
}
