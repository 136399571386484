import React from 'react'

import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  title: string
  rightTitleContent?: any
  subtitle?: string
}

export default function VettingSectionHeader(props: IProps) {
  return (
    <div>
      <div className="flex flex-row flex-wrap items-center justify-between">
        <h3 className="font-bold text-xl lg:text-2xl mb-1">{props.title}</h3>
        {props.rightTitleContent}
      </div>
      <div className="border-b border-black w-full" />
      {utils.hasValue(props.subtitle) && (
        <p className="my-4 text-sm">{props.subtitle}</p>
      )}
    </div>
  )
}
