import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'

import { View, Text } from 'lib/components'

export interface IBreadCrumb {
  name: string
  route?: string
  search?: string
  state?: any
}

interface IProps {
  crumbs: IBreadCrumb[]
  beforeNavigate?: (event: any, route: string) => void
}

export default class BreadCrumbs extends PureComponent<IProps> {
  onBeforeNavigate = (route: string) => (e: any) => {
    const { beforeNavigate } = this.props
    if (beforeNavigate) {
      beforeNavigate(e, route)
    }
  }

  getKey = (crumb: IBreadCrumb, index: number) => `crumb-${crumb.name}-${index}`

  renderSeparator(crumb: IBreadCrumb) {
    return (
      <Text
        key={`separator-${crumb.name}`}
        fontSize="16px"
        color="textLight"
        mx="6px"
      >
        /
      </Text>
    )
  }

  renderLinkText = (crumb: IBreadCrumb, index: number) => (
    <Text
      key={this.getKey(crumb, index)}
      data-test={this.getKey(crumb, index)}
      color={!!crumb.route ? 'primary' : 'textLight'}
      fontSize="16px"
      fontWeight={!!crumb.route ? 700 : 400}
    >
      {crumb.name}
    </Text>
  )

  renderLink = (crumb: IBreadCrumb, index: number) => {
    const linkable = !!crumb.route
    const key = this.getKey(crumb, index)
    const innerText = this.renderLinkText(crumb, index)
    const text = linkable ? (
      <Link
        key={`link-${key}`}
        to={{
          pathname: crumb.route,
          search: crumb.search,
          state: crumb.state,
        }}
        onClick={this.onBeforeNavigate(`${crumb.route}`)}
      >
        {innerText}
      </Link>
    ) : (
      innerText
    )

    if (index !== this.props.crumbs.length - 1) {
      return [text, this.renderSeparator(crumb)]
    }

    return text
  }

  render() {
    const links = this.props.crumbs.map(this.renderLink)
    return (
      <View
        flexible="row-wrap"
        py={3}
        role="heading"
        aria-level={1}
        id="breadcrumbs"
      >
        {links}
      </View>
    )
  }
}
