import React from 'react'
import * as R from 'ramda'
import { useSelector } from 'react-redux'
import { utils, records } from '@ims/1edtech-frontend-common'

import { Screen, View, Spinner, Text, Span } from 'lib/components'
import BaseCommonCartridgeCharacterizationsBreadCrumbs from 'domains/characterizations/components/BaseCommonCartridgeCharacterizationsBreadCrumbs'
import useFullCommonCartridgeCharacterization from 'domains/commonCartridge/hooks/useFullCommonCartridgeCharacterization'
import GenericError from 'domains/application/components/GenericError'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import ICommonCartridgeCharacterizationReport from 'domains/commonCartridge/models/ICommonCartridgeCharacterizationReport'
import { getCharacterizationRoute } from 'domains/characterizations/navigation/routes'
import { getNavigationParam } from 'lib/utils/navigation'
import { getRecord } from 'lib/records/workflows/getRecord'
import { COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_STANDARDS_RECORD } from 'lib/records/modules/commonCartridgeCharacterizationReportStandards'
import { ICommonCartridgeStandard } from 'domains/commonCartridge/models/ICommonCartridgeStandard'
import CommonCartridgeCharacterizationReportResourcesList from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CommonCartridgeCharacterizationReportResourcesList'
import DebugReportView from 'domains/reports/components/DebugReportView'
import CCCharacterizationReportDetailsHeader from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportDetailsHeader'
import { CCReportTypeIcon } from 'domains/commonCartridge/constants/commonCartridge'
import CCCharacterizationReportNavigationHeader from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportNavigationHeader'
import CCCharacterizationReportLegend from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportLegend'
import { CC_STANDARD_GUID_NOT_SPECIFIED } from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportStandardRow'

export default function CommonCartridgeCharacterizationReportStandardDetailsScreen(
  props: any,
) {
  const [
    characterizationData,
    reportData,
    pending,
  ] = useFullCommonCartridgeCharacterization(props)
  const [fetchPending, setFetchPending] = React.useState(true)

  const standardId = utils.convertToInt(
    getNavigationParam(props, 'standardId', 0),
  )

  React.useEffect(() => {
    const fetch = async () => {
      await getRecord(
        COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_STANDARDS_RECORD,
        standardId,
      )
      await utils.wait.waitFor(250)
      setFetchPending(false)
    }

    fetch()
  }, [standardId])

  const standard = useSelector(
    records.entitiesSelectors.entityByIdSelector(
      COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_STANDARDS_RECORD,
      standardId,
    ),
  ) as ICommonCartridgeStandard

  if (
    pending ||
    fetchPending ||
    !utils.hasValue(characterizationData) ||
    !utils.hasValue(reportData)
  ) {
    if (
      !pending &&
      !fetchPending &&
      (!utils.hasValue(characterizationData) ||
        !utils.hasValue(reportData) ||
        !utils.hasValue(standard))
    ) {
      return <GenericError />
    }
    return (
      <View variant="screen">
        <Spinner centered={true} />
      </View>
    )
  }

  const characterization = characterizationData as ICharacterization
  const characterizationName = R.pathOr('', ['name'], characterization)
  const report = reportData as ICommonCartridgeCharacterizationReport

  const standardName = R.pathOr(
    CC_STANDARD_GUID_NOT_SPECIFIED,
    ['guid'],
    standard,
  )
  const caseUrl = R.pathOr('', ['caseUrl'], standard)

  return (
    <Screen>
      <BaseCommonCartridgeCharacterizationsBreadCrumbs
        crumbs={[
          {
            name: utils.string.ellipsize(characterizationName, 30),
            route: getCharacterizationRoute(
              characterization.id,
              characterization.format,
            ),
          },
          {
            name: 'Standards',
            route: getCharacterizationRoute(
              characterization.id,
              characterization.format,
              ['standards'],
            ),
          },
          { name: utils.string.ellipsize(standardName, 30) },
        ]}
      />

      <View variant="paper">
        <CCCharacterizationReportLegend />
        <CCCharacterizationReportNavigationHeader
          title={standardName}
          titleLink={caseUrl}
          characterization={characterization}
          tocUrl="standards"
          hideLevels={true}
        />

        <CCCharacterizationReportDetailsHeader
          icon={CCReportTypeIcon.Standard}
          titleBg="ccReportStandardTitleBG"
          title="Standard Details"
          rightTitle={
            <Text>
              <Span fontWeight={700}>Resources: </Span>{' '}
              {R.pathOr(0, ['resourceCount'], standard)}
            </Text>
          }
          bg="ccReportStandardBG"
          details={[
            { data: standard, label: 'label', path: ['value'] },
            { data: standard, label: 'provider', path: ['provider'] },
            { data: standard, label: 'region', path: ['region'] },
            { data: standard, label: 'version', path: ['version'] },
            { data: standard, label: 'resourceLabel', path: ['resourceLabel'] },
            { data: standard, label: 'resourcePart', path: ['resourcePart'] },
          ]}
        />

        <View mt={2} />
        <CommonCartridgeCharacterizationReportResourcesList
          standardId={standardId}
          characterization={characterization}
          report={report}
          noBlankslate={true}
        />
      </View>

      <DebugReportView rawReport={standard || {}} title="Standard JSON" />
    </Screen>
  )
}
