import React from 'react'
import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'

import { View, Span, Text } from 'lib/components'
import { ICommonCartridgeContribute } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeContribute'
import CCCharacterizationReportLOMDetail from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMDetail'
import CCCharacterizationReportLOMKeyValue from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMKeyValue'
import CCCharacterizationReportLOMDate from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMDate'
import { ICommonCartridgeDate } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeDate'

interface IProps {
  path: string
  title: string
  data?: ICommonCartridgeContribute
}

export default function CCCharacterizationReportLOMContribute(props: IProps) {
  if (!utils.hasValue(props.data)) {
    return null
  }

  const role = R.pathOr({}, ['role'], props.data)
  const entity = R.pathOr([], ['entity'], props.data) as string[]
  const date = R.pathOr({}, ['date'], props.data) as ICommonCartridgeDate

  return (
    <CCCharacterizationReportLOMDetail
      path={props.path}
      title={props.title}
      value={
        <View>
          {utils.hasValue(role) && (
            <View>
              <Text fontWeight={700}>role: </Text>
              <View ml={3}>
                <CCCharacterizationReportLOMKeyValue
                  path={`${props.path}.role`}
                  keyName="source"
                  keyValue={R.pathOr(null, ['source'], role)}
                  valueName="value"
                  value={R.pathOr(null, ['value'], role)}
                />
              </View>
            </View>
          )}

          {utils.hasValue(entity) && (
            <Text mb={1} data-test={`${props.path}.entity`}>
              <Span fontWeight={700}>entity: </Span>{' '}
              {utils.array.ensureArray(entity).join(', ')}
            </Text>
          )}

          {utils.hasValue(date) && (
            <View>
              <Text fontWeight={700} mt={2}>
                date:
              </Text>
              <View ml={3}>
                <CCCharacterizationReportLOMDate
                  data={date}
                  path={`${props.path}.date`}
                />
              </View>
            </View>
          )}
        </View>
      }
    />
  )
}
