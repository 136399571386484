export interface IIMSApplicationFilter {
  label: string
  filterKey: string
  value: any
}

export interface IIMSApplicationParentFilter {
  parent: string
  children: IIMSApplicationFilter[]
}

export const getFilterString = (filter: IIMSApplicationFilter) => {
  return `${filter.filterKey}=${filter.value}`
}

export const PARENT_FILTER_PREFIX = 'PARENT_'
