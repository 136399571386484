import React from 'react'
import * as R from 'ramda'

import IRestCModelServiceEndpointAttribute from 'domains/modeling/models/IRestCModelServiceEndpointAttribute'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import IRestCModelService from 'domains/modeling/models/IRestCModelService'
import IRestCModelServiceEndpoint from 'domains/modeling/models/IRestCModelServiceEndpoint'
import useRestCSpecServiceEndpointAttributeCell from 'domains/modeling/hooks/useRestCSpecServiceEndpointAttributeCell'
import SpecificationDescription from 'domains/modeling/components/SpecificationDescription'
import { ListPlainTextCell } from 'lib/components'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'

interface IProps extends IRestCModelServiceEndpointAttribute {
  specification: IRestCSpec
  service: IRestCModelService
  endpoint: IRestCModelServiceEndpoint
  endpointIndex: number
  handleChange: any
}

export default function RestCAttributeDescriptionCell(props: IProps) {
  const [specAttribute] = useRestCSpecServiceEndpointAttributeCell(props)
  const isPrint = isOnPrintScreen()

  return (
    <ListPlainTextCell
      py={3}
      wordBreak="break-word"
      width={isPrint ? 'auto' : 300}
      overflow="auto"
      userSelect="auto"
      whiteSpace="pre-wrap"
    >
      <SpecificationDescription
        description={R.propOr(props.description, 'description', specAttribute)}
      />
    </ListPlainTextCell>
  )
}
