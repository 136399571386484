import React from 'react'
import * as Yup from 'yup'
import { Formik, FormikHelpers } from 'formik'

import { ModalWrapper, View, FormInput, InputLabel } from 'lib/components'
import INotification from 'domains/notifications/models/INotification'
import NotifcationRowComponent from 'domains/notifications/components/NotifcationRowComponent'
import { createRecord } from 'lib/records/workflows/createRecord'
import { NOTIFICATIONS_RECORD } from 'lib/records/modules/notifications'
import { GENERAL_NOTIFICATION } from 'domains/notifications/constants/notifications'
import { showToast } from 'lib/utils/toast'
import { APPLICATION_NAME } from 'domains/application/constants/application'

interface IProps {
  isOpen: boolean
  closeModal: () => any
}

const validationSchema = Yup.object().shape({
  message: Yup.string().required('Required'),
  emphasisWords: Yup.string(),
})

interface IFormValues {
  message: string
  emphasisWords: string
}

export default function CreateAdminNotificationModal(props: IProps) {
  const onSave = async (
    values: IFormValues,
    bag: FormikHelpers<IFormValues>,
  ) => {
    try {
      const { success } = await createRecord(NOTIFICATIONS_RECORD, {
        event: GENERAL_NOTIFICATION,
        ...values,
        emphasisWords: `${values.emphasisWords}`.split(','),
      })

      bag.setSubmitting(false)
      if (success) {
        bag.resetForm()
        props.closeModal()
      } else {
        showToast('error', 'Failed to create notification. Please try again.')
      }
    } catch (error) {
      console.log(error) // eslint-disable-line
      showToast('error', 'Failed to create notification. Please try again.')
    }
  }

  return (
    <Formik
      initialValues={{
        message: '',
        emphasisWords: '',
      }}
      onSubmit={onSave}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {(bag) => {
        return (
          <ModalWrapper
            isOpen={props.isOpen}
            title="New Notification"
            actions={[
              {
                text: 'Save',
                variant: 'start',
                onClick: bag.submitForm,
                extra: {
                  type: 'submit',
                },
              },
              {
                text: 'Cancel',
                variant: 'neutral',
                onClick: () => {
                  bag.resetForm()
                  props.closeModal()
                },
                extra: {
                  type: 'button',
                },
              },
            ]}
            pending={bag.isSubmitting}
          >
            <View minWidth={['none', 600]}>
              <FormInput
                label="Message"
                name="message"
                value={bag.values.message}
                placeholder={`Hey Everyone, ${APPLICATION_NAME} will be undergoing maintenance TONIGHT at 12:00AM EST`}
                handleChange={bag.handleChange}
                valueDataTest="message-input"
                textArea={true}
                inputProps={{ width: '100%', rows: 6 }}
              />
              <FormInput
                label="Emphasis Words"
                name="emphasisWords"
                value={bag.values.emphasisWords}
                placeholder={`${APPLICATION_NAME},TONIGHT,12:00AM EST`}
                handleChange={bag.handleChange}
                valueDataTest="emphasis-words-input"
              />

              <View my={3}>
                <InputLabel data-test="preview-label">Preview</InputLabel>
                <NotifcationRowComponent
                  index={0}
                  notification={
                    {
                      id: 1,
                      event: 'GENERAL',
                      read: false,
                      createdTime: new Date().getTime(),
                      message: bag.values.message,
                      emphasisWords: (bag.values.emphasisWords || '').split(
                        ',',
                      ),
                    } as INotification
                  }
                />
              </View>
            </View>
          </ModalWrapper>
        )
      }}
    </Formik>
  )
}
