import React from 'react'
import * as R from 'ramda'

import { utils } from '@ims/1edtech-frontend-common'
import { ICommonCartridgeLanguageText } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeLanguageText'
import CCCharacterizationReportLOMDetail from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMDetail'
import CCCharacterizationReportLOMLanguageText, {
  CC_LANGUAGE_NOT_SPECIFIED_MESSAGE,
} from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMLanguageText'

interface IProps {
  path: string
  title: string
  data?: ICommonCartridgeLanguageText[]
}

export default function CCCharacterizationReportLOMLanguageTextGrouped(
  props: IProps,
) {
  if (!utils.hasValue(props.data)) {
    return null
  }

  const items = props.data!
  let grouped: { [key: string]: string[] } = {}
  for (const item of items) {
    const lang = R.pathOr(CC_LANGUAGE_NOT_SPECIFIED_MESSAGE, ['language'], item)
    const languageItems = R.pathOr([], [lang], grouped) as string[]
    grouped = R.assoc(lang, [...languageItems, item.text], grouped)
  }

  const languages = R.keys(grouped) as string[]

  return (
    <CCCharacterizationReportLOMDetail
      path={props.path}
      title={props.title}
      value={languages.map((l, i) => {
        return (
          <CCCharacterizationReportLOMLanguageText
            key={`${props.path}.${i}`}
            path={`${props.path}.${i}`}
            data={{
              language: l,
              text: grouped[l].join(', '),
            }}
          />
        )
      })}
    />
  )
}
