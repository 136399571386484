import { utils } from '@ims/1edtech-frontend-common'

import { MODELLING_STATUS_TO_COMPARISON_VALUE } from 'domains/products/constants/products'
import sortProductsByRosteringFirst from 'domains/products/utils/sortProductsByRosteringFirst'
import IProduct from 'domains/products/models/IProduct'
import { getProductFormatPropByName } from 'domains/products/utils/products'

export const sortProductsByModellingStatus = (sortAscending: boolean) => (
  productA: IProduct,
  productB: IProduct,
): number => {
  const rosteringSort = sortProductsByRosteringFirst(productA, productB)
  if (rosteringSort !== false) {
    return rosteringSort
  }
  const [aReturn, bReturn] = utils.sort.getReturnValuesBySortDirection(
    sortAscending,
  )
  const aStatus =
    MODELLING_STATUS_TO_COMPARISON_VALUE[
      getProductFormatPropByName(productA.format!, 'modellingStatus')(productA)
    ]
  const bStatus =
    MODELLING_STATUS_TO_COMPARISON_VALUE[
      getProductFormatPropByName(productB.format!, 'modellingStatus')(productB)
    ]
  if (aStatus === bStatus) {
    return utils.comparePropForSort(true, 'name')(productA, productB)
  }
  return aStatus > bStatus ? aReturn : bReturn
}
