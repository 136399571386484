import React from 'react'
import * as R from 'ramda'
import FileSaver from 'file-saver'

import { getRequest } from 'lib/api/api'
import { showToast } from 'lib/utils/toast'
import { getTokens, getApiUrl } from 'lib/api/utils'

const getXmlHttpRequestHeadersMap = (request: XMLHttpRequest) => {
  const headers = request.getAllResponseHeaders()
  const arr = headers.trim().split(/[\r\n]+/)
  const headerMap: { [key: string]: any } = {}
  arr.forEach((line) => {
    const parts = line.split(': ')
    const header = parts.shift()
    const value = parts.join(': ')
    headerMap[`${header}`] = value
  })
}

const getFilenameFromHeaders = (headers: any) =>
  R.pathOr('', ['content-disposition'], headers)
    .replace('attachment; filename=', '')
    .replace(/['"]+/g, '')

const fetchReportRaw = async (config: {
  url: string
  type?: string
  filename?: string
}) =>
  new Promise<void>(async (resolve, reject) => {
    const fetchType = config.type || 'text/csv;charset=utf-8'
    const tokens = await getTokens()
    const request = new XMLHttpRequest()
    request.open('GET', getApiUrl('GET', config.url))
    request.setRequestHeader('Authorization', `Bearer ${tokens.accessToken}`)
    request.responseType = 'blob'
    request.onreadystatechange = function () {
      if (request.readyState === 4 && request.status === 200) {
        const a = document.createElement('a')
        const blob = new Blob([request.response], { type: fetchType })
        a.href = window.URL.createObjectURL(blob)
        a.download =
          config.filename ||
          getFilenameFromHeaders(getXmlHttpRequestHeadersMap(request))
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        a.parentNode?.removeChild(a)
        resolve()
      } else if (request.status !== 200) {
        reject(new Error(request.statusText))
      }
    }
    request.send()
  }).catch((error) => {
    showToast('error', `Failed to download report: ${error.message}`)
  })

export function useReportDownloadFetcher(config: {
  url: string
  filename?: string
  type?: string
  raw?: boolean
}) {
  const fetchReport = async () => {
    if (config.raw) {
      await fetchReportRaw(config)
      return
    }

    const { success, data, headers } = await getRequest(config.url)

    if (success) {
      const type = config.type || 'text/csv;charset=utf-8'
      const blob = new Blob([`\ufeff${data}`], { type })
      const name = config.filename || getFilenameFromHeaders(headers)
      FileSaver.saveAs(blob, name)
    } else {
      showToast('error', 'Failed to download report.')
    }
  }

  return fetchReport
}

interface IProps {
  startDownload: boolean
  onDownloadComplete?: () => any
  url: string
}

export default function DownloadReport({
  startDownload,
  onDownloadComplete,
  url,
}: IProps) {
  const fetchReport = useReportDownloadFetcher({ url })

  React.useEffect(() => {
    const fetch = async () => {
      await fetchReport()
      if (onDownloadComplete) onDownloadComplete()
    }
    if (startDownload) fetch()
  }, [startDownload, fetchReport, onDownloadComplete, url])

  return null
}
