import * as notifications from 'domains/notifications/constants/notifications'
import { showNotificationCenter } from 'domains/notifications/components/NotificationCenter/NotificationCenter'
import INotification from 'domains/notifications/models/INotification'
import { getRecord } from 'lib/records/workflows/getRecord'
import { OR_REST_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/orRESTCharacterizations'
import { OR_CSV_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/orCSVCharacterizations'
import { getCharacterizationRoute } from 'domains/characterizations/navigation/routes'
import { history } from 'lib/utils/navigation'
import { markNotificationAsRead } from 'domains/notifications/workflows/markNotificationAsRead'
import { getRoute, USERS_ROUTE } from 'domains/application/navigation/routes'
import trackNotificationAnalytics from 'domains/notifications/utils/trackNotificationAnalytics'

export const getNotificationRoute = async (
  notification: INotification,
): Promise<string | null> => {
  switch (notification.event) {
    case notifications.CHARACTERIZATION_COMPLETED_NOTIFICATION: {
      const { id, href } = notification.identifier!
      const isRest = href.includes('rest')
      const { success, data } = await getRecord(
        isRest
          ? OR_REST_CHARACTERIZATIONS_RECORD
          : OR_CSV_CHARACTERIZATIONS_RECORD,
        id,
      )
      if (success) {
        return getCharacterizationRoute(id, data.format)
      }
      return null
    }
    case notifications.USER_CREATED_NOTIFICATION:
      return getRoute(USERS_ROUTE, notification.identifier!.id)
    default:
      return null
  }
}

export const getOnClickNotificationAction = (
  notification: INotification,
  fromNotificationCenter: boolean,
  browserNotification?: Notification,
) => {
  const close = () => {
    if (browserNotification) {
      browserNotification.close()
    }
  }

  const markReadAndShowNotificationCenter = async () => {
    await markNotificationAsRead(notification.id)
    close()
    showNotificationCenter()
    trackNotificationAnalytics('user_clicked_notification', {
      notification,
      fromNotificationCenter,
    })
  }

  switch (notification.event) {
    case notifications.CHARACTERIZATION_COMPLETED_NOTIFICATION: {
      return async () => {
        trackNotificationAnalytics('user_clicked_notification', {
          notification,
          fromNotificationCenter,
        })
        const route = await getNotificationRoute(notification)

        close()
        if (!fromNotificationCenter) {
          await markNotificationAsRead(notification.id)
        }

        if (route) {
          history.push(route)
        } else {
          showNotificationCenter()
        }
      }
    }

    case notifications.COMPARISON_ZOMBIFIED_NOTIFICATION:
      if (fromNotificationCenter) {
        return undefined
      }

      return markReadAndShowNotificationCenter

    case notifications.USER_CREATED_NOTIFICATION:
      return async () => {
        trackNotificationAnalytics('user_clicked_notification', {
          notification,
          fromNotificationCenter,
        })
        const route = await getNotificationRoute(notification)
        close()
        if (!fromNotificationCenter) {
          await markNotificationAsRead(notification.id)
        }
        if (route) {
          history.push(route)
        } else {
          showNotificationCenter()
        }
      }

    case notifications.USER_DELETED_NOTIFICATION:
      if (fromNotificationCenter) {
        return undefined
      }
      return markReadAndShowNotificationCenter

    case notifications.ACCOUNT_EXPIRING_SOON_NOTIFICATION:
      if (fromNotificationCenter) {
        return undefined
      }
      return markReadAndShowNotificationCenter

    default:
      return async () => {
        trackNotificationAnalytics('user_clicked_notification', {
          notification,
          fromNotificationCenter,
        })
        showNotificationCenter()
        close()
      }
  }
}
