import { deleteRequest } from 'lib/api/api'
import { applicationPropSelector } from 'domains/application/selectors/application'
import store from 'lib/store'
import { updateEntityProperty } from 'lib/store/entities.actions'
import { NOTIFICATIONS_RECORD } from 'lib/records/modules/notifications'
import { deleteRecord } from 'lib/records/workflows/deleteRecord'
import { getNotificationCount } from 'domains/notifications/workflows/getNotificationCount'
import { getRecords } from 'lib/records/workflows/getRecords'
import trackNotificationAnalytics from 'domains/notifications/utils/trackNotificationAnalytics'

export const deleteNotification = async (id?: number) => {
  if (!id) {
    const meId = applicationPropSelector('me')(store.getState())
    await deleteRequest(`users/${meId}/notifications`)
    await getRecords({
      record: NOTIFICATIONS_RECORD,
      params: { id: meId, filter: 'archived=false' },
      force: true,
    })
    store.dispatch(updateEntityProperty([NOTIFICATIONS_RECORD], () => ({})))
    trackNotificationAnalytics('deleted_all_notifications')
  } else {
    await deleteRecord(NOTIFICATIONS_RECORD, id)
    trackNotificationAnalytics('deleted_single_notification', { id })
  }

  await getNotificationCount()
}
