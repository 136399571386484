import { myOrganizationPropSelector } from 'domains/authentication/selectors/organizations'
import store from 'lib/store'

export function amIHed(): boolean {
    let isHed = false;
    const segment = myOrganizationPropSelector('segment', '')(store.getState(), {})
    isHed = segment === 'HED'
    return isHed;
}

export function getVocab(key: string, upperFirst?: boolean, plural?: boolean) {
    const value = vocab[key as keyof typeof vocab];
    let str = "";

    const isHed = amIHed();

    str = isHed ? value.hed : value.k12;

    if (plural) {
        str = str + 's';
    }
    if (upperFirst) {
        str = str[0].toUpperCase() + str.slice(1);
    }
    return str;
}

const vocab = {
    school: {
        k12: "school",
        hed: "institution",
    },
    gradeLevel: {
        k12: "grade level",
        hed: "department",
    },
    teacher: {
        k12: "teacher",
        hed: "faculty",
    },
    subject: {
        k12: "subject",
        hed: "department"
    },
    toolUse: {
        k12: "tool use",
        hed: "category"
    },
    grade: {
        k12: "grade",
        hed: "college"
    },
    rostered: {
        k12: "rostered",
        hed: "enrolled"
    },
    district: {
        k12: "district",
        hed: "institution"
    }
}
