import React from 'react'
import * as R from 'ramda'

import useModalState from 'lib/hooks/useModalState'
import ExpansionPanel from 'lib/components/ExpansionPanel'
import CCCharacterizationReportDetailsHeaderDetails from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportDetailsHeaderDetails'
import {
  ICommonCartridgeResource,
  ICommonCartridgeResourceLTIExtension,
  ICommonCartridgeResourceLTINameValue,
} from 'domains/commonCartridge/models/ICommonCartridgeResource'
import { Icon } from 'lib/components'

function ExandableTitle(props: { title: string; isOpen: boolean }) {
  return (
    <div className="flex flex-row items-center">
      <p className="text-text text-base font-bold">{props.title}</p>
      <Icon
        fontSize={16}
        className={`ml-2 fas rotate-caret fa-chevron-down ${
          props.isOpen ? 'down' : ''
        }`}
      />
    </div>
  )
}

type Props = {
  resource: ICommonCartridgeResource
}

export default function CCCharacterizationReportLTIResource(props: Props) {
  const [isCustomsOpen, , , toggleCustomsOpen] = useModalState()
  const [isExtensionsOpen, , , toggleExtensionsOpen] = useModalState()

  if (!props.resource.lti) {
    return null
  }

  const ltiCustoms = R.pathOr(
    [],
    ['lti', 'custom'],
    props.resource,
  ) as ICommonCartridgeResourceLTINameValue[]
  const ltiExtensions = R.pathOr(
    [],
    ['lti', 'extensions'],
    props.resource,
  ) as ICommonCartridgeResourceLTIExtension[]

  return (
    <div className="">
      <CCCharacterizationReportDetailsHeaderDetails
        data={props.resource}
        label="title"
        path={['lti', 'title']}
      />
      <CCCharacterizationReportDetailsHeaderDetails
        data={props.resource}
        label="description"
        path={['lti', 'description']}
      />
      {ltiCustoms.length > 0 && (
        <ExpansionPanel
          isOpen={isCustomsOpen}
          onRequestChange={toggleCustomsOpen}
          summary={<ExandableTitle title="custom" isOpen={isCustomsOpen} />}
          details={ltiCustoms.map((custom, index) => (
            <CCCharacterizationReportDetailsHeaderDetails
              data={props.resource}
              key={`custom-${index}`}
              label={custom.name}
              path={['lti', 'custom', index, 'value']}
              indentLevel={1}
            />
          ))}
        />
      )}
      {ltiExtensions.length > 0 && (
        <ExpansionPanel
          isOpen={isExtensionsOpen}
          onRequestChange={toggleExtensionsOpen}
          summary={
            <ExandableTitle title="extensions" isOpen={isExtensionsOpen} />
          }
          details={ltiExtensions.map((extension, index) => (
            <div key={`extension-${index}`}>
              <CCCharacterizationReportDetailsHeaderDetails
                data={props.resource}
                label={`platform [${index + 1}]`}
                path={['lti', 'extensions', index, 'platform']}
                indentLevel={1}
              />
              {extension.properties.map((property, propertyIndex) => (
                <CCCharacterizationReportDetailsHeaderDetails
                  key={`extension-platform-property-${propertyIndex}`}
                  data={props.resource}
                  label={property.name}
                  path={[
                    'lti',
                    'extensions',
                    index,
                    'properties',
                    propertyIndex,
                    'value',
                  ]}
                  indentLevel={2}
                />
              ))}
            </div>
          ))}
        />
      )}
      <CCCharacterizationReportDetailsHeaderDetails
        data={props.resource}
        label="launch_url"
        path={['lti', 'launchUrl']}
      />
      <CCCharacterizationReportDetailsHeaderDetails
        data={props.resource}
        label="secure_launch_url"
        path={['lti', 'secureLaunchUrl']}
      />
      <CCCharacterizationReportDetailsHeaderDetails
        data={props.resource}
        label="icon"
        path={['lti', 'icon']}
      />
      <CCCharacterizationReportDetailsHeaderDetails
        data={props.resource}
        label="secure_icon"
        path={['lti', 'secureIcon']}
      />
      {!!props.resource.lti?.vendorCode && (
        <CCCharacterizationReportDetailsHeaderDetails
          data={props.resource}
          label="vendor"
          isTitle
          path={['lti', 'vendorCode']}
        />
      )}
      <CCCharacterizationReportDetailsHeaderDetails
        data={props.resource}
        label="code"
        indentLevel={1}
        path={['lti', 'vendorCode']}
      />
      <CCCharacterizationReportDetailsHeaderDetails
        data={props.resource}
        label="name"
        indentLevel={1}
        path={['lti', 'vendorName']}
      />
      <CCCharacterizationReportDetailsHeaderDetails
        data={props.resource}
        label="description"
        indentLevel={1}
        path={['lti', 'vendorDescription']}
      />
      <CCCharacterizationReportDetailsHeaderDetails
        data={props.resource}
        label="url"
        indentLevel={1}
        path={['lti', 'vendorUrl']}
      />
      {!!props.resource.lti?.vendorContactEmail && (
        <CCCharacterizationReportDetailsHeaderDetails
          data={props.resource}
          label="contact"
          isTitle
          indentLevel={1}
          path={['lti', 'vendorContactEmail']}
        />
      )}
      <CCCharacterizationReportDetailsHeaderDetails
        data={props.resource}
        label="email"
        indentLevel={2}
        path={['lti', 'vendorContactEmail']}
      />
    </div>
  )
}
