import React from 'react'
import * as R from 'ramda'

import { View, Text, ToolTip, Icon, Span, Linkify } from 'lib/components'
import {
  ITransformedRESTServiceEndpointCharacterizationReport,
  ITransformedRESTServiceEndpointParamCharacterizationReport,
} from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import {
  ENDPOINT_NAME_TO_FRIENDLY_NAME,
  PARAM_TO_DISPLAY_NAME,
} from 'domains/modeling/constants/restCOneRoster1p1'
import ReportStatusIcon from 'domains/reports/components/ReportStatusIcon'
import { utils } from '@ims/1edtech-frontend-common'
import CharacterizationErrorDot from 'domains/characterizations/components/CharacterizationErrorDot'
import memoize from 'fast-memoize'

const leftMargin = [120, 200]

const findParam = memoize(
  (
    endpoint: ITransformedRESTServiceEndpointCharacterizationReport,
    name: string,
  ) =>
    R.find(
      (param) => param.paramName.toLowerCase() === name.toLowerCase(),
      endpoint.paramCharacterizations,
    ),
)

const paramHasAnyError = memoize(
  (param: ITransformedRESTServiceEndpointParamCharacterizationReport) => {
    const hasNotes = utils.hasValue(param.notes)
    return hasNotes || !param.supported || param.status !== 'GREEN'
  },
)

function ParamStatusIcon(props: any) {
  return (
    <View width={25} height={25} flexible="column-center">
      {props.children}
    </View>
  )
}

interface IParamStatusProps {
  serviceName: string
  endpoint: ITransformedRESTServiceEndpointCharacterizationReport
  paramName: string
}

function ParamStatus(props: IParamStatusProps) {
  if (!props.endpoint.enabled) {
    return null
  }

  const foundParam = findParam(props.endpoint, props.paramName)
  const param = foundParam as ITransformedRESTServiceEndpointParamCharacterizationReport

  const dataTest = `param-status-${props.serviceName}-${props.endpoint.endpointName}-${props.paramName}`
  let content: any = null
  if (!foundParam || !foundParam.supported) {
    content = <ReportStatusIcon status="GRAY" data-test={dataTest} />
  } else if (param.errorMessage) {
    content = (
      <ToolTip id={param.paramName} tip={param.errorMessage}>
        <ReportStatusIcon status={foundParam.status} data-test={dataTest} />
      </ToolTip>
    )
  } else {
    content = (
      <ReportStatusIcon status={foundParam.status} data-test={dataTest} />
    )
  }

  return <ParamStatusIcon>{content}</ParamStatusIcon>
}

function ParamNotes(props: IParamStatusProps) {
  const foundParam = findParam(props.endpoint, props.paramName)

  if (!foundParam) {
    return null
  }
  const param = foundParam as ITransformedRESTServiceEndpointParamCharacterizationReport
  if (!utils.hasValue(param.notes)) {
    return null
  }

  const dataTest = `param-notes-${props.serviceName}-${props.endpoint.endpointName}-${props.paramName}`
  return (
    <View py={2} data-test={dataTest}>
      <Text fontStyle="italic">
        <Span fontSize={14} fontWeight={700}>
          <Icon className="fas fa-info-circle" color="info" mr={1} />
          {PARAM_TO_DISPLAY_NAME[props.paramName]} Notes:{' '}
        </Span>
        <Linkify>{param.notes || ''}</Linkify>
      </Text>
    </View>
  )
}

interface IProps {
  serviceName: string
  endpoint: ITransformedRESTServiceEndpointCharacterizationReport
  index: number
}

export default function RESTEndpointOperationInformation(props: IProps) {
  if (!utils.hasValue(props.endpoint.paramCharacterizations)) {
    return null
  }
  const showErrorDot = R.any(paramHasAnyError)(
    props.endpoint.paramCharacterizations,
  )

  const endpointStatusDataTest = `endpoint-status-${props.serviceName}-${props.endpoint.endpointName}`
  return (
    <View bg={props.index % 2 === 0 ? 'white' : 'background'} px={3}>
      <View flexible="row-v-center" minHeight={48}>
        <View width={leftMargin} flexible="row-v-center">
          <View width={25} height={25}>
            {props.endpoint.enabled && showErrorDot && (
              <CharacterizationErrorDot
                color="secondary"
                size={25}
                data-test={endpointStatusDataTest}
              />
            )}
            {!props.endpoint.enabled && (
              <ReportStatusIcon
                status="GRAY"
                circle={true}
                data-test={endpointStatusDataTest}
              />
            )}
          </View>

          <View flex={1}>
            <Text ml={3}>
              {ENDPOINT_NAME_TO_FRIENDLY_NAME[props.endpoint.endpointName]}
            </Text>
          </View>
        </View>

        <View flex={1} flexible="row-center">
          <ParamStatus
            serviceName={props.serviceName}
            endpoint={props.endpoint}
            paramName="filter"
          />
        </View>

        <View flex={1} flexible="row-center">
          <ParamStatus
            serviceName={props.serviceName}
            endpoint={props.endpoint}
            paramName="fields"
          />
        </View>
        <View flex={1} flexible="row-center">
          <ParamStatus
            serviceName={props.serviceName}
            endpoint={props.endpoint}
            paramName="sort"
          />
        </View>
        <View flex={1} flexible="row-center">
          <ParamStatus
            serviceName={props.serviceName}
            endpoint={props.endpoint}
            paramName="orderBy"
          />
        </View>
        <View flex={1} flexible="row-center">
          <ParamStatus
            serviceName={props.serviceName}
            endpoint={props.endpoint}
            paramName="offset"
          />
        </View>
        <View flex={1} flexible="row-center">
          <ParamStatus
            serviceName={props.serviceName}
            endpoint={props.endpoint}
            paramName="limit"
          />
        </View>
      </View>

      <View ml={leftMargin}>
        <ParamNotes
          serviceName={props.serviceName}
          endpoint={props.endpoint}
          paramName="filter"
        />
        <ParamNotes
          serviceName={props.serviceName}
          endpoint={props.endpoint}
          paramName="fields"
        />
        <ParamNotes
          serviceName={props.serviceName}
          endpoint={props.endpoint}
          paramName="sort"
        />
        <ParamNotes
          serviceName={props.serviceName}
          endpoint={props.endpoint}
          paramName="orderBy"
        />
        <ParamNotes
          serviceName={props.serviceName}
          endpoint={props.endpoint}
          paramName="offset"
        />
        <ParamNotes
          serviceName={props.serviceName}
          endpoint={props.endpoint}
          paramName="limit"
        />
      </View>
    </View>
  )
}
