import React from 'react'
import SmallWidget from 'domains/dashboard/components/SmallWidget'

interface IProps {
  readyToCompareCount: number
  fadeDelay: number
}

export default function ReadyToCompareCharacterizationsWidget(props: IProps) {
  return (
    <SmallWidget
      fadeDelay={props.fadeDelay}
      icon="fas fa-chart-pie"
      iconColor="infoLight"
      title={props.readyToCompareCount}
      subtitle="Conformant Characterizations"
      dataTest="conformant-characterizations-widget"
    />
  )
}
