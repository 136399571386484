import React from 'react'
import * as R from 'ramda'
import { useSelector } from 'react-redux'
import { records } from '@ims/1edtech-frontend-common'

import useFullComparison from 'domains/comparisons/hooks/useFullComparison'
import { View, Spinner } from 'lib/components'
import IComparison from 'domains/comparisons/models/IComparison'
import ComparisonReportTopSection from 'domains/comparisons/components/ComparisonReportTopSection'
import IRESTComparisonReport from 'domains/characterizations/models/IRESTComparisonReport'
import ComparisonReportDetailsHeader from 'domains/comparisons/components/ComparisonReport/ComparisonReportDetailsHeader'
import ModelReportDetails from 'domains/modeling/components/ModelReportDetails'
import useAccordionHeaderState from 'domains/reports/hooks/useAccordionHeaderState'
import RESTServicesComparisonReport from 'domains/comparisons/components/ComparisonReport/RESTServicesComparisonReport'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import { utils } from '@ims/1edtech-frontend-common'
import GenericError from 'domains/application/components/GenericError'
import DebugReportView from 'domains/reports/components/DebugReportView'
import { RootState } from 'lib/store/rootReducer'
import {
  myOrganizationPropSelector,
  isMyOrgFreemiumSelector,
} from 'domains/authentication/selectors/organizations'
import FreemiumLockedComparisonReport from 'domains/comparisons/components/ComparisonReport/FreemiumLockedComparisonReport'

export default function OR1P1RESTComparisonReportScreen(props: any) {
  const [
    comparisonData,
    comparisonReportData,
    specificationData,
    product,
    pending,
  ] = useFullComparison(props)

  const [
    headerStates,
    getHeaderState,
    onToggleHeader,
    onExpandAll,
    onCollapseAll,
  ] = useAccordionHeaderState(
    R.pathOr([], ['restComparisons'], comparisonReportData).map((s: any) => ({
      ...s,
      name: s.serviceName,
    })),
  )

  const isFreemium = useSelector((state: RootState) =>
    isMyOrgFreemiumSelector(state, props),
  )
  const referrerId = useSelector((state: RootState) =>
    myOrganizationPropSelector('referrerId')(state, props),
  )

  const [allExpanded, setAllExpanded] = React.useState(false)
  const onExpandAllServices = () => {
    setAllExpanded(true)
    setAllCollapsed(false)
    onExpandAll()
  }

  const [allCollapsed, setAllCollapsed] = React.useState(true)
  const onCollapseAllServices = () => {
    setAllExpanded(false)
    setAllCollapsed(true)
    onCollapseAll()
  }

  if (pending) {
    return (
      <View variant="screen">
        <Spinner centered={true} />
      </View>
    )
  }

  if (
    !utils.hasValue(comparisonData) ||
    (!R.path(['restComparisons'], comparisonReportData) && !isFreemium)
  ) {
    return <GenericError />
  }

  const comparison = comparisonData as IComparison
  const report = comparisonReportData as IRESTComparisonReport
  const specification = specificationData as IRestCSpec

  const isNonReferral =
    isFreemium &&
    referrerId !== records.modules.getRefId(comparison, 'supplierId', -1)
  const reportBody = isNonReferral ? (
    <FreemiumLockedComparisonReport />
  ) : (
    <View variant="paper">
      <ComparisonReportDetailsHeader
        onExpand={onExpandAllServices}
        onCollapse={onCollapseAllServices}
        data-test="comparison-report-services"
      />

      <ModelReportDetails report={report} format={comparison.format} />

      <RESTServicesComparisonReport
        report={report}
        specification={specification}
        headerStates={headerStates}
        getHeaderState={getHeaderState}
        onToggleHeader={onToggleHeader}
        allExpanded={allExpanded}
        allCollapsed={allCollapsed}
        setAllExpandedState={setAllExpanded}
        setAllCollapsedState={setAllCollapsed}
      />
    </View>
  )

  return (
    <View variant="screen">
      <ComparisonReportTopSection
        comparison={comparison}
        report={report}
        product={product}
        isNonReferralComparison={isNonReferral}
      />
      {reportBody}
      {!isNonReferral && (
        <DebugReportView rawReport={report} title="Compsys Report JSON" />
      )}
    </View>
  )
}
