import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

import { prepareFileCharacterizationForServer } from 'domains/characterizations/utils/prepareFileCharacterizationForServer'

export const COMMON_CARTRIDGE_CHARACTERIZATIONS_RECORD =
  'commonCartridgeCharacterizations'

const commonCartridgeCharacterizations: records.modules.IModule = {
  record: COMMON_CARTRIDGE_CHARACTERIZATIONS_RECORD,
  responseDataKey: 'characterizationReport',
  schema: new normalizr.schema.Entity(
    COMMON_CARTRIDGE_CHARACTERIZATIONS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(),
  ),
  api: {
    create: () => 'characterizations/commoncartridges',
    prepareCreateData: prepareFileCharacterizationForServer,
    get: (id) => `characterizations/commoncartridges/${id}`,
    getList: (_, orgId) => {
      return `organizations/${orgId}/characterizations`
    },
    update: (id) => `characterizations/commoncartridges/${id}`,
    delete: (id) => `characterizations/${id}`,
  },
}

export const getCSVCharacterizationDownloadLink = (id: string | number) =>
  `characterizations/commoncartridges/${id}/download`

export default commonCartridgeCharacterizations
