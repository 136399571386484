import * as formats from 'domains/formats/constants/formats'
import { CSV_COMPARISON_REPORTS_RECORD } from 'lib/records/modules/csvComparisonsReport'
import { REST_COMPARISON_REPORTS_RECORD } from 'lib/records/modules/restComparisonsReport'

export const comparisonFormatToReportRecord = (
  format: formats.AnyFormatType,
) => {
  const specFormat = formats.ANY_FORMAT_TO_SPEC_FORMAT_MAP[format]
  switch (specFormat) {
    case formats.ONER_V1P1_CSV_ROSTERING_EXPORT_BULK:
    case formats.ONER_V1P2_CSV_ROSTERING_EXPORT_BULK:
    case formats.ONER_V1P1_CSV_ROSTERING_IMPORT_BULK:
      return CSV_COMPARISON_REPORTS_RECORD
    case formats.ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL:
    case formats.ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL:
      return REST_COMPARISON_REPORTS_RECORD
    default:
      return CSV_COMPARISON_REPORTS_RECORD
  }
}
