import React from 'react'

import { View } from 'lib/components'
import { displaySpecVersion } from 'domains/products/utils/products'
import {
  FORMAT_TO_DISPLAY_SHORT_NAME,
  FORMAT_TYPE_TO_DISPLAY_NAME,
  FORMAT_TO_DISPLAY_FORMAT_BASE_NAME,
  OR_FORMAT_TO_SPEC_SUBTYPE,
  AnySpecFormatType,
} from 'domains/formats/constants/formats'
import WidgetLineItem, {
  IWidgetLineItemProps,
} from 'domains/reports/components/WidgetLineItem'

interface IFormattable {
  format: string
  specificationName: string
  specificationVersion: string
}
interface IProps {
  beforeItems?: IWidgetLineItemProps[]
  format: IFormattable
  dataTest?: string
  afterItems?: IWidgetLineItemProps[]
  hideType?: boolean
  baseFormat?: boolean
  keyWidth?: number
  wordBreak?: string
}

export default function FormatLineItems(props: IProps) {
  const renderLine = (lineItem: IWidgetLineItemProps, index = 0) => (
    <WidgetLineItem
      {...lineItem}
      key={`${lineItem.label}-${index}`}
      width={props.keyWidth}
      wordBreak={props.wordBreak}
    />
  )

  const {
    beforeItems = [],
    format,
    dataTest,
    afterItems = [],
    hideType,
    baseFormat,
  } = props
  return (
    <View data-test={dataTest} width="100%">
      {(beforeItems || []).map(renderLine)}
      {renderLine({
        label: 'Specification',
        value: `${format.specificationName} - ${
          OR_FORMAT_TO_SPEC_SUBTYPE[format.format as AnySpecFormatType]
        }`,
      })}
      {renderLine({
        label: 'Version',
        value: displaySpecVersion(format.specificationVersion),
      })}
      {renderLine({
        label: 'Format',
        value: baseFormat
          ? FORMAT_TO_DISPLAY_FORMAT_BASE_NAME[
              format.format as AnySpecFormatType
            ]
          : FORMAT_TO_DISPLAY_SHORT_NAME[format.format as AnySpecFormatType],
      })}
      {!hideType &&
        renderLine({
          label: 'Type',
          value:
            FORMAT_TYPE_TO_DISPLAY_NAME[format.format as AnySpecFormatType],
        })}
      {(afterItems || []).map(renderLine)}
    </View>
  )
}
