import React from 'react'
import { useSelector } from 'react-redux'
import { records } from '@ims/1edtech-frontend-common'

import { getRecord } from 'lib/records/workflows/getRecord'

export default function useRecordEntity({
  record,
  id,
  requestParams,
  props,
  noReFetch,
}: {
  record: string
  id: any
  requestParams?: any
  props?: any
  noReFetch?: boolean // If entity already in store, don't get it again
}): [any, boolean] {
  const [pending, setPending] = React.useState(!noReFetch)

  const entity = useSelector((state) =>
    records.entitiesSelectors.entityByIdSelector(record, id)(state, props),
  )
  const hasEntity = !!entity

  React.useEffect(() => {
    const fetch = async () => {
      await getRecord(record, id, requestParams)
      setPending(false)
    }
    if (id && (!noReFetch || !hasEntity)) fetch()
  }, [record, id]) // eslint-disable-line

  return [entity, pending]
}
