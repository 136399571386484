import React from 'react'
import { useSelector } from 'react-redux'
import { utils } from '@ims/1edtech-frontend-common'

import { BreadCrumbs } from 'lib/components'
import {
  DASHBOARD_ROUTE,
  DISTRICT_TRUSTED_APPS_DASHBOARD_ROUTE,
  DISTRICT_TRUSTED_APPS_SHARED_DASHBOARDS_ROUTE,
  getRoute,
} from 'domains/application/navigation/routes'
import { IBreadCrumb } from 'lib/components/BreadCrumbs'
import { navigationParamSelector } from 'lib/utils/navigation'
import useRecordEntity from 'lib/records/hooks/useRecordEntity'
import { ORGANIZATIONS_RECORD } from 'lib/records/modules/organizations'
import IOrg from 'domains/orgs/models/IOrg'

interface IProps {
  crumbs?: IBreadCrumb[]
}

export default function BaseTrustedAppsBreadCrumbs(props: IProps) {
  const parentOrgId = utils.convertToInt(
    useSelector((state: any) =>
      navigationParamSelector('orgId', 0)(state, props as any),
    ),
  )
  const [orgBeingViewed] = useRecordEntity({
    record: ORGANIZATIONS_RECORD,
    id: parentOrgId,
  }) as [IOrg | null, boolean]

  const { crumbs = [] } = props

  return (
    <BreadCrumbs
      crumbs={[
        {
          name: 'Home',
          route: getRoute(DASHBOARD_ROUTE),
        },
        ...(parentOrgId
          ? [
              {
                name: orgBeingViewed
                  ? `TrustEd Apps Shared Dashboard - ${orgBeingViewed.name}`
                  : 'TrustEd Apps Shared Dashboards',
                route: `${DISTRICT_TRUSTED_APPS_SHARED_DASHBOARDS_ROUTE}${
                  parentOrgId ? `?orgId=${parentOrgId}` : ''
                }`,
              },
            ]
          : [
              {
                name: 'TrustEd Apps - My Dashboard',
                route: DISTRICT_TRUSTED_APPS_DASHBOARD_ROUTE,
              },
            ]),
        ...crumbs,
      ]}
    />
  )
}
