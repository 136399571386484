import React from 'react'

import { ListPlainTextCell, ToolTip, View } from 'lib/components'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'
import { ITransformedRESTServiceEndpointAttributeCharacterizationReport } from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import IRestCModel from 'domains/modeling/models/IRestCModel'
import { OR_1P1_REST_ROSTERING_CONSUMER_SERVICES } from 'domains/modeling/constants/restCOneRoster1p1'
import { findSpecServiceEndointAttributeByName } from 'domains/modeling/utils/restCOR1p1utils'
import { WARNING_TO_COLUMN_MAP } from 'domains/characterizations/constants/characterizationReport'

const isPrint = isOnPrintScreen()

interface IProps
  extends ITransformedRESTServiceEndpointAttributeCharacterizationReport {
  serviceName: string
  endpointName: string
  specification: IRestCSpec
  model?: IRestCModel
  width?: string | (string | number)[]
}

export default function RESTCharacterizationAttributeRequiredCell(
  props: IProps,
) {
  const {
    endpointName,
    serviceName,
    attributeName,
    errorTitle,
    errorMessage,
    required,
    specification,
  } = props
  let display = required
  if (!display) {
    const specAttribute = findSpecServiceEndointAttributeByName(
      specification,
      serviceName as OR_1P1_REST_ROSTERING_CONSUMER_SERVICES,
      endpointName,
      attributeName,
    )

    display = specAttribute ? specAttribute.required : ''
  }

  if (errorTitle && WARNING_TO_COLUMN_MAP[errorTitle] === 'Required') {
    const content = (
      <ListPlainTextCell
        color={errorMessage ? (isPrint ? 'secondary' : 'white') : 'text'}
        fontWeight={errorMessage ? 700 : 400}
      >
        {display}
      </ListPlainTextCell>
    )

    if (isPrint) {
      return content
    }

    return (
      <ToolTip
        id={`${endpointName}-${attributeName}-required`}
        tip={errorMessage}
      >
        <View
          bg={errorMessage ? 'secondary' : ''}
          borderRadius={4}
          px={1}
          py="12px"
          maxWidth={100}
        >
          {content}
        </View>
      </ToolTip>
    )
  }

  return <ListPlainTextCell>{display}</ListPlainTextCell>
}
