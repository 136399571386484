import { useEffect, useState } from 'react'
import { utils, records } from '@ims/1edtech-frontend-common'

import { useSelector } from 'react-redux'
import { getNavigationParam } from 'lib/utils/navigation'
import { RootState } from 'lib/store/rootReducer'
import { getFullCSVCharacterization } from 'domains/characterizations/workflows/getFullCSVCharacterization'
import { OR_CSV_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/orCSVCharacterizations'
import { ONEROSTER_CSV_CHARACTERIZATION_REPORTS_RECORD } from 'lib/records/modules/oneRosterCSVCharacterizationReports'
import { characterizationPropSelector } from 'domains/characterizations/selectors/characterizations'
import { getCharacterizationFullSpecSelector } from 'domains/characterizations/selectors/characterizationSpecification'
import { transformCharacterizationCSVReport } from 'domains/characterizations/utils/transformCharacterizationCSVReport'
import ICharacterizationCSVReport from 'domains/characterizations/models/ICharacterizationCSVReport'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import ITransformedCharacterizationCSVReport from 'domains/characterizations/models/ITransformedCharacterizationCSVReport'
import ICSVSpecification from 'domains/specifications/models/ICSVSpecification'

export default function useFullCSVPCharacterization(
  props: any,
  characterizationIdParam = 'id',
): [
  ICharacterization,
  ICharacterizationCSVReport,
  ITransformedCharacterizationCSVReport,
  ICSVSpecification,
  boolean,
] {
  const [pending, setPending] = useState(true)
  const characterizationId = utils.convertToInt(
    getNavigationParam(props, characterizationIdParam),
  )

  useEffect(() => {
    const fetch = async () => {
      await getFullCSVCharacterization(characterizationId)
      setPending(false)
    }
    fetch()
  }, [characterizationId])

  const characterization = useSelector((state: RootState) =>
    records.entitiesSelectors.entityByIdSelector(
      OR_CSV_CHARACTERIZATIONS_RECORD,
      characterizationId,
    )(state, props),
  )
  const characterizationReport = useSelector(
    records.entitiesSelectors.entityByIdSelector(
      ONEROSTER_CSV_CHARACTERIZATION_REPORTS_RECORD,
      characterizationPropSelector('content', characterizationId),
    ),
  ) as ICharacterizationCSVReport
  const specification = useSelector((state: RootState) =>
    getCharacterizationFullSpecSelector(
      OR_CSV_CHARACTERIZATIONS_RECORD,
      characterizationId,
    )(state, props),
  )

  const transformedReport = transformCharacterizationCSVReport(
    characterizationReport as ICharacterizationCSVReport,
  )
  return [
    characterization,
    characterizationReport,
    transformedReport,
    specification,
    pending,
  ]
}
