import colors from 'lib/styles/colors'
import {
  SMALL_SCREEN_SIZE,
  MEDIUM_SCREEN_SIZE,
  LARGE_SCREEN_SIZE,
  EXTRA_LARGE_SCREEN_SIZE,
} from 'lib/styles/screenSizes'
import { alertStyles } from 'lib/components/Alert'
import { badgeStyles, badgeTypeStyles } from 'lib/components/Badge'
import { buttonStyles } from 'lib/components/Button'
import { buttonBadgeStyles } from 'lib/components/ButtonBadge'
import { inputStyles } from 'lib/components/Input'
import { selectVariantStyles } from 'lib/components/Select'
import { textAreaVariantStyles } from 'lib/components/TextArea'
import { tableHeaderVariantStyles } from 'lib/components/Table'
import {
  fontSizes,
  fontWeights,
  lineHeights,
  textStyles,
  textOverflowStyles,
} from 'lib/components/Text'
import {
  animationStyles,
  elevationStyles,
  viewHoverStyles,
  viewVariantStyles,
} from 'lib/components/View'
import {
  IFlexibleStyles,
  flexibleStyles,
} from 'lib/components/styles/flexibleStyles'
import { animations } from 'lib/styles/animations'

export interface ITheme {
  colors: any
  flexibleStyles: IFlexibleStyles
}

const theme = {
  alertStyles,
  backgroundColors: colors,
  badgeStyles,
  badgeTypeStyles,
  breakpoints: [
    SMALL_SCREEN_SIZE,
    MEDIUM_SCREEN_SIZE,
    LARGE_SCREEN_SIZE,
    EXTRA_LARGE_SCREEN_SIZE,
  ],
  buttonBadgeStyles,
  buttonStyles,
  colors,
  animationStyles,
  elevationStyles,
  flexibleStyles,
  fontSizes,
  fontWeights,
  inputStyles,
  lineHeights,
  maxWidths: {
    sm: SMALL_SCREEN_SIZE - 34,
    md: MEDIUM_SCREEN_SIZE - 34,
    lg: LARGE_SCREEN_SIZE - 34,
  },
  minWidths: {
    sm: SMALL_SCREEN_SIZE - 34,
    md: MEDIUM_SCREEN_SIZE - 34,
    lg: LARGE_SCREEN_SIZE - 34,
  },
  selectVariantStyles,
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  tableHeaderVariantStyles,
  textAreaVariantStyles,
  textStyles,
  textOverflowStyles,
  viewVariantStyles,
  viewHoverStyles,
  width: [16, 32, 64, 128, 256],

  animations,
}

export default theme
