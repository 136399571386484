import React, { ChangeEvent, useState, useEffect } from 'react'
import memoize from 'fast-memoize'
import * as R from 'ramda'

import './ListFilter.css'
import { View, Text, SearchBox, ModalWrapper } from 'lib/components'
import Icon from 'lib/components/Icon'
import { utils } from '@ims/1edtech-frontend-common'
import {
  IListFilter,
  IListFilterOption,
  IFilterState,
  buildFilterState,
  onCheckboxValueChange,
  onSelectValueChange,
} from 'lib/components/ListFilter/listFilterUtils'
import ListFilterSection from 'lib/components/ListFilter/ListFilterSection'
import ListSelectFilter from 'lib/components/ListFilter/ListSelectFilter'
import ListCheckboxFilter from 'lib/components/ListFilter/ListCheckboxFilter'

export interface IListFilterProps {
  isOpen: boolean
  title: string
  toggleOpen: () => any
  searchName: string
  trackAnalytics?: (action: string, eventProperties?: any) => any
  trackAnalyticsCategory?: string
  onSearchChange: (search: string) => any
  filters: IListFilter[]
  onFilterChanged: (filter: string) => any
  'data-test'?: string
  isSmall: boolean
  searchValue: string
}

const getCollapsibleClassName = memoize(
  (open) => `collapsible ${open ? 'open' : 'closed'}`,
)

export default function ListFilter(props: IListFilterProps) {
  const [filterString, setFilterString] = useState('')
  const [appliedFilters, setAppliedFilters] = useState<IFilterState>(
    buildFilterState(props.filters),
  )

  useEffect(() => {
    const newFilterString = getAllFilters(appliedFilters)
    setFilterString(newFilterString)
    props.onFilterChanged(newFilterString)
  }, []) // eslint-disable-line

  const getAllFilters = (newFilters?: any) => {
    const theAppliedFilters = newFilters || appliedFilters
    const values = theAppliedFilters ? R.values(theAppliedFilters) : []
    return values
      .filter((item: any) => utils.hasValue(R.propOr('', 'filterString', item)))
      .map(R.prop('filterString'))
      .join(' AND ')
  }

  const updateAppliedFilters = (newAppliedFilters: IFilterState) => {
    const newFilterString = getAllFilters(newAppliedFilters)
    setAppliedFilters(newAppliedFilters)
    setFilterString(newFilterString)
    if (!props.isSmall) {
      props.onFilterChanged(newFilterString)
    }
  }

  const applyFiltersAndClose = () => {
    props.onFilterChanged(filterString)
    props.toggleOpen()
  }

  const onSelectChanged =
    (filter: IListFilter) => (event: ChangeEvent<HTMLSelectElement>) => {
      const { value } = event.target
      const newAppliedFilters = onSelectValueChange(
        filter.title,
        value,
        appliedFilters,
      )
      updateAppliedFilters(newAppliedFilters)
      const { trackAnalytics, trackAnalyticsCategory = 'list' } = props
      if (trackAnalytics) {
        trackAnalytics(`select_filtered_${trackAnalyticsCategory}`, {
          title: filter.title,
          value,
        })
      }
    }

  const onCheckboxChanged =
    (filter: IListFilter) => (option: IListFilterOption, event: any) => {
      const value = R.pathOr<boolean>(false, ['target', 'checked'], event)
      const newAppliedFilters = onCheckboxValueChange(
        filter.title,
        option.value,
        value,
        appliedFilters,
      )
      updateAppliedFilters(newAppliedFilters)
      const { trackAnalytics, trackAnalyticsCategory = 'list' } = props
      if (trackAnalytics) {
        trackAnalytics(`checkbox_filtered_${trackAnalyticsCategory}`, {
          title: filter.title,
          option: option.value,
          value,
        })
      }
    }

  const onSearchChange = (search: string) => {
    props.onSearchChange(search)
  }

  const getDataTest = () => props['data-test'] || 'list-filter'

  const getContent = () => (
    <>
      <ListFilterSection title="Search" divider={true}>
        <SearchBox
          name={props.searchName}
          trackEvent={props.trackAnalytics}
          trackEventAction={props.trackAnalyticsCategory}
          onChange={onSearchChange}
          width="100%"
          value={props.searchValue}
          placeholder=""
        />
      </ListFilterSection>
      {props.filters.map((filter: IListFilter, index: number) => {
        const divider = index !== props.filters.length - 1
        switch (filter.type) {
          case 'SELECT':
            return (
              <ListSelectFilter
                key={filter.title}
                filter={filter}
                renderDivider={divider}
                appliedFilters={appliedFilters}
                dataTest={getDataTest()}
                onChange={onSelectChanged(filter)}
              />
            )
          case 'CHECKBOX':
            return (
              <ListCheckboxFilter
                key={filter.title}
                filter={filter}
                renderDivider={divider}
                appliedFilters={appliedFilters}
                dataTest={getDataTest()}
                onChange={onCheckboxChanged(filter)}
              />
            )
          default:
            return null
        }
      })}
    </>
  )

  const dataTest = getDataTest()
  const renderLargeScreen = () => (
    <View
      id="list-filter"
      data-test={dataTest}
      className={props.isOpen ? 'open' : 'closed'}
      mr={props.isOpen ? 2 : 0}
      pr={props.isOpen ? 2 : 0}
    >
      <View
        flexible={props.isOpen ? 'row-space-between' : 'row-center'}
        height={48}
      >
        <View flexible="row" className={getCollapsibleClassName(props.isOpen)}>
          <Icon className="fas fa-filter" />
          <Text variant="subtitle" fontWeight={700} mx={2} overflow="ellipsis">
            {props.title}
          </Text>
        </View>

        {!props.isOpen && (
          <Icon
            className="fas fa-filter"
            onClick={props.toggleOpen}
            cursor="pointer"
            mr={1}
          />
        )}
        <Icon
          className="fas fa-bars"
          onClick={props.toggleOpen}
          cursor="pointer"
          mr={props.isOpen ? 2 : 0}
          data-test={`${dataTest}-toggle`}
        />
      </View>

      <View className={getCollapsibleClassName(props.isOpen)} mt={3}>
        {getContent()}
      </View>
    </View>
  )

  const renderSmallScreen = () => (
    <View id="list-filter" data-test={dataTest} mx={2}>
      <Icon
        className="fas fa-filter"
        onClick={props.toggleOpen}
        cursor="pointer"
        mr={1}
        data-test={`${dataTest}-toggle`}
      />

      <ModalWrapper
        isOpen={props.isOpen}
        title={props.title}
        actions={[
          {
            text: 'Apply',
            variant: 'start',
            onClick: applyFiltersAndClose,
          },
          {
            text: 'Cancel',
            variant: 'neutral',
            onClick: props.toggleOpen,
          },
        ]}
      >
        {getContent()}
      </ModalWrapper>
    </View>
  )

  return props.isSmall ? renderSmallScreen() : renderLargeScreen()
}
