import { utils } from '@ims/1edtech-frontend-common'
import * as R from 'ramda'
import jwt from 'jsonwebtoken'

import { LOGIN_ROUTE } from 'domains/application/navigation/routes'
import { whoAmI } from 'domains/authentication/workflows/whoAmI'
import IUser from 'domains/users/models/IUser'
import { getRequest } from 'lib/api/api'
import { isResponseIndicatingLockedOut } from 'lib/api/utils'
import { dataSelector } from 'lib/api/utils'

export const FRESHDESK_DATA_KEY = 'FRESHDESK_DATA'

const FRESHDESK_JWT_SECRET = utils.env
  .getEnvVariable(
    'FRESHDESK_JWT_SECRET',
    '-----BEGIN RSA PRIVATE KEY-----__nMIICXgIBAAKBgQDJNBeB2uMq4iVMZYhFZgam+ktn++aOFirLx2TESowGVyZCPHKX__ntTSiKm2trduxNpdvnClmGucE6YbgVTu+yPGGoJ2Qrn9fDaGcoIZakRm/U3NZirG2__n7A/px0h98P+fqwiY3x7sea1XFRI+Aby9VG43vDcqGCbY8RLk1X8V1nfpdQIDAQAB__nAoGBAKL5k/T2NT3+MlIgh/IWw1qrsTrIOydqYz3vA/tPmv5jxzStu/CQsdKNZYvi__nIOr5mk6ogowXTHuZIUxDHgLax29iZZkIEcwVUOGS+jPK+wjwTrW8ZC9z+pIT4SnO__nTRbgJ0ssnKcnZ5N3ybQtoXAoxtiT0KB1I7Z05OhMIajsfAldAkEA6/A71JCjjaTT__nRMw1v5RPygsgKeKP1qEnItkWHh0YCcvLtOcdomWiRrKmgb1qfSGePkCfXHO/NGuF__nieNUcrlelwJBANpPxNl+mZe8+y9Bai8KApsBhmHz1nw5ISkHA/GUjzvSClQciv5Z__nHa7hx2jSjsm0ZrVaT/fIIN3NNVNJNY6aBdMCQQDQtCz4lwlWHlsUHF/fG96IptbI__ntjTucpcf6E/8S1gcMj37xAUexjCJwTYwxdEQ51AHWTqemXSldgcmNgVZhkf5AkBb__nW/qDEknwiDON6RAos0W33IGCSGq//aB07OynkoYGgLW10WQRYPXdE7tBkKwUmRxK__nVIsWpHesmo3wVrjE/ovlAkEA4KkHd2xr1Mp3VLzq5ZHN6GSMwrK9/qqgXBfhaFRv__nb02NCmh9BWZFBrltX6U8qvcwWQpETVEdhhv1i6svsw0gWQ==__n-----END RSA PRIVATE KEY-----',
  )
  .replace(/__n/gm, '\n')
  .replace(/\\n/gm, '\n')
const FRESHDESK_JWT_CALLBACK_URL = utils.env.getEnvVariable(
  'FRESHDESK_JWT_CALLBACK_URL',
  'https://edtech-team.freshworks.com/sp/OIDC/317695374580701359/implicit',
)

// Hardcoded PROD
// const FRESHDESK_JWT_CALLBACK_URL =
//   'https://edtech-team.freshworks.com/sp/OIDC/315550590411488561/implicit'

export const freshdeskJWTLogin = async (nonce: string, state: string) => {
  utils.localStorage.removeLocalItem(FRESHDESK_DATA_KEY)
  await whoAmI()
  const loginResponse = await getRequest('login')

  if (!loginResponse.success) {
    if (isResponseIndicatingLockedOut(loginResponse)) {
      console.log('org is locked out') // eslint-disable-line
      window.location.replace('/')
      return
    }

    window.location.href = `${LOGIN_ROUTE}?freshdesk=true&nonce=${nonce}&state=${state}`
    return
  }

  const me = dataSelector(loginResponse) as IUser
  const company = R.pathOr('Unknown', ['organizationName'], me) as string
  const payload = {
    sub: me.id,
    email: me.email,
    iat: utils.date.getEpochNow(),
    nonce,
    givenname: me.firstName,
    surname: me.lastName,
    company: `${company}`,
  }
  const token = jwt.sign(payload, FRESHDESK_JWT_SECRET, { algorithm: 'RS256' })
  const url = `${FRESHDESK_JWT_CALLBACK_URL}?state=${state}&id_token=${token}`

  window.location.href = url
}
