import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import { Dropdown, Menu, Text, View, Icon } from 'lib/components'
import { logout } from 'domains/authentication/workflows/logout'
import { meSelector } from 'domains/authentication/selectors/me'
import { RouteComponentProps } from 'react-router'
import {
  getRoute,
  USERS_ROUTE,
  ORG_PREF_ROUTE,
} from 'domains/application/navigation/routes'
import {
  isSupplierRoleSelector,
  isOrgAdminSelector,
  isIMSAdminRoleSelector,
} from 'domains/authentication/selectors/roles'
import { RouterContext } from 'domains/application/screens/AppScreen'
import { applicationPropSelector } from 'domains/application/selectors/application'

const dropdownTargetProps = {
  height: '100%',
}

const ProfileHeader = () => {
  const routeProps = useContext(RouterContext)

  const me = useSelector(meSelector)
  const isIMSAdmin = useSelector(isIMSAdminRoleSelector)
  const isSupplier = useSelector(isSupplierRoleSelector)
  const isOrgAdmin = useSelector(isOrgAdminSelector)
  const isLockedOut = useSelector(applicationPropSelector('lockedOut'))

  const getMenuItems = () => {
    const logoutOption = {
      name: 'Logout',
      icon: 'fas fa-sign-out-alt',
      dataTest: 'logout',
    }
    const editProfileOption = {
      name: 'Profile',
      icon: 'fas fa-user',
      dataTest: 'profile',
    }

    if (isLockedOut) {
      return [logoutOption]
    }

    if (!isSupplier && !isIMSAdmin && isOrgAdmin) {
      return [
        editProfileOption,
        {
          name: 'Organization Preferences',
          icon: 'fas fa-sitemap',
          dataTest: 'organization-preferences',
        },
        logoutOption,
      ]
    }
    return [editProfileOption, logoutOption]
  }

  const onMenuItemClicked = ({ name }: { name: string }) => {
    switch (name.toLowerCase()) {
      case 'profile':
        ;(routeProps as RouteComponentProps).history.push(
          getRoute(USERS_ROUTE, me ? me.id : 0),
        )
        break
      case 'logout':
        logout()
        break
      case 'organization preferences':
        ;(routeProps as RouteComponentProps).history.push(
          getRoute(ORG_PREF_ROUTE),
        )
        break
    }
  }

  if (!me) {
    return null
  }

  const { firstName = '', lastName = '', jobTitle = '' } = me

  return (
    <Dropdown
      target={
        <View
          data-test="profile-menu"
          flexible="row-v-center"
          height="100%"
          px={3}
        >
          <View mr={2}>
            <Icon
              mr={2}
              className="fas fa-user-circle"
              color="primary"
              fontSize={40}
            />
          </View>

          <View flexible="column-v-center">
            <Text
              variant="subtitle"
              userSelect="none"
              pb={1}
              data-test="profile-header-name"
            >
              {[firstName, lastName].join(' ')}
            </Text>
            <Text
              variant="caption-small"
              userSelect="none"
              data-test="profile-header-job"
              overflow="ellipsis"
              maxWidth={[120, 200]}
            >
              {jobTitle}
            </Text>
          </View>
        </View>
      }
      targetProps={dropdownTargetProps}
      showChevron={true}
    >
      <Menu onClick={onMenuItemClicked} items={getMenuItems()} />
    </Dropdown>
  )
}

export default ProfileHeader
