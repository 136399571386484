import React, { useEffect, useState } from 'react'
import * as R from 'ramda'
import { RouteComponentProps } from 'react-router'
import { utils } from '@ims/1edtech-frontend-common'

import AppLoadingScreen from 'domains/application/screens/AppLoadingScreen'
import withNavigationProps from 'lib/utils/withNavigationProps'
import { Button, Text, View } from 'lib/components'
import { LOGIN_ROUTE } from 'domains/application/navigation/routes'
import {
  oauth2CodeExchange,
  VERIFIER_TOKEN,
  getOAuth2Verifier,
} from 'domains/authentication/workflows/oauth2Login'

function OAuth2RedirectScreen(props: RouteComponentProps) {
  const [invalidState, setInvalidState] = useState(false)
  const [invalidCode, setInvalidCode] = useState(false)
  const [verifier, setVerifier] = useState('')

  const code = R.propOr(false, 'code', props)
  const hasCode = utils.hasValue(code)

  useEffect(() => {
    const asyncEffect = async () => {
      const v = await getOAuth2Verifier()
      setInvalidCode(!hasCode)
      setInvalidState(!utils.hasValue(v))
      setVerifier(v)
      utils.localStorage.removeLocalItem(VERIFIER_TOKEN)
    }

    asyncEffect()
  }, [hasCode])

  useEffect(() => {
    const exchangeToken = async () => {
      if (!utils.hasValue(verifier)) {
        return
      }
      await oauth2CodeExchange(code as string, verifier)
    }
    exchangeToken()
  }, [invalidState, verifier, code])

  const tryAgain = () => props.history.replace(LOGIN_ROUTE)

  return (
    <AppLoadingScreen
      message={
        invalidState ||
        (invalidCode && (
          <View my={3} flexible="column-center">
            <Text textAlign="center" color="white">
              {invalidCode ? 'Auth Code not found' : ''}
            </Text>
            <Text textAlign="center" color="white">
              {invalidState ? 'State token not found' : ''}
            </Text>
          </View>
        ))
      }
      action={
        invalidState ||
        (invalidCode && (
          <Button variant="start" color="white" onClick={tryAgain}>
            Try Again
          </Button>
        ))
      }
    />
  )
}

export default withNavigationProps(OAuth2RedirectScreen)
