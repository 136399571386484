import React from 'react'
import * as R from 'ramda'

import { View } from 'lib/components'
import { ICommonCartridgeLanguageText } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeLanguageText'
import { ICommonCartridgeDuration } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeDuration'
import { ICommonCartridgeLOMEducational } from 'domains/commonCartridge/models/lom/ICommonCartridgeLOMEducational'
import { ICommonCartridgeSourceValue } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeSourceValue'
import CCCharacterizationReportLOMCSVList from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMCSVList'
import CCCharacterizationReportLOMSourceValue from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMSourceValue'
import CCCharacterizationReportLOMLanguageText from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMLanguageText'
import CCCharacterizationReportLOMDuration from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMDuration'
import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  path: string
  data: ICommonCartridgeLOMEducational
}

export default function CCCharacterizationReportLOMEducational(props: IProps) {
  const { showMissingMetadata } = React.useContext(ShowMissingMetadataContext)
  const interactivityType = R.pathOr(
    {},
    ['interactivityType'],
    props.data,
  ) as ICommonCartridgeSourceValue
  const learningResourceTypes = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['learningResourceType'],
    props.data,
  ) as ICommonCartridgeSourceValue[]
  const interactivityLevel = R.pathOr(
    {},
    ['interactivityLevel'],
    props.data,
  ) as ICommonCartridgeSourceValue
  const semanticDensity = R.pathOr(
    {},
    ['semanticDensity'],
    props.data,
  ) as ICommonCartridgeSourceValue
  const intendedEndUserRoles = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['intendedEndUserRole'],
    props.data,
  ) as ICommonCartridgeSourceValue[]
  const contexts = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['context'],
    props.data,
  ) as ICommonCartridgeSourceValue[]
  const typicalAgeRanges = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['typicalAgeRange'],
    props.data,
  ) as ICommonCartridgeLanguageText[]
  const difficulty = R.pathOr(
    {},
    ['difficulty'],
    props.data,
  ) as ICommonCartridgeSourceValue
  const typicalLearningTime = R.pathOr(
    {},
    ['typicalLearningTime'],
    props.data,
  ) as ICommonCartridgeDuration
  const descriptions = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['description'],
    props.data,
  ) as ICommonCartridgeLanguageText[]
  const languages = R.pathOr(
    showMissingMetadata ? [''] : [],
    ['languages'],
    props.data,
  ) as string[]

  return (
    <View>
      <CCCharacterizationReportLOMSourceValue
        path={`${props.path}.interactivityType`}
        title="interactivityType"
        data={interactivityType}
      />

      {learningResourceTypes.map((lrt, i) => (
        <CCCharacterizationReportLOMSourceValue
          key={`${props.path}.learningResourceType.${i}`}
          path={
            !utils.hasValue(lrt)
              ? `${props.path}.learningResourceType`
              : `${props.path}.learningResourceType.${i}`
          }
          title={`learningResourceType [${i + 1}]`}
          data={lrt}
        />
      ))}

      <CCCharacterizationReportLOMSourceValue
        path={`${props.path}.interactivityLevel`}
        title="interactivityLevel"
        data={interactivityLevel}
      />

      <CCCharacterizationReportLOMSourceValue
        path={`${props.path}.semanticDensity`}
        title="semanticDensity"
        data={semanticDensity}
      />

      {intendedEndUserRoles.map((ieur, i) => (
        <CCCharacterizationReportLOMSourceValue
          key={`${props.path}.intendedEndUserRole.${i}`}
          path={
            !utils.hasValue(ieur)
              ? `${props.path}.intendedEndUserRole`
              : `${props.path}.intendedEndUserRole.${i}`
          }
          title={`intendedEndUserRole [${i + 1}]`}
          data={ieur}
        />
      ))}

      {contexts.map((context, i) => (
        <CCCharacterizationReportLOMSourceValue
          key={`${props.path}.context.${i}`}
          path={
            !utils.hasValue(context)
              ? `${props.path}.context`
              : `${props.path}.context.${i}`
          }
          title={`context [${i + 1}]`}
          data={context}
        />
      ))}

      {typicalAgeRanges.map((tar, i) => (
        <CCCharacterizationReportLOMLanguageText
          key={`${props.path}.typicalAgeRange.${i}`}
          path={
            !utils.hasValue(tar)
              ? `${props.path}.typicalAgeRange`
              : `${props.path}.typicalAgeRange.${i}`
          }
          title={`typicalAgeRange [${i + 1}]`}
          data={tar}
        />
      ))}

      <CCCharacterizationReportLOMSourceValue
        path={`${props.path}.difficulty`}
        title="difficulty"
        data={difficulty}
      />

      <CCCharacterizationReportLOMDuration
        path={`${props.path}.typicalLearningTime`}
        title="typicalLearningTime"
        data={typicalLearningTime}
      />

      {descriptions.map((d, i) => (
        <CCCharacterizationReportLOMLanguageText
          key={`${props.path}.description.${i}`}
          path={
            !utils.hasValue(d)
              ? `${props.path}.description`
              : `${props.path}.description.${i}`
          }
          title={`description [${i + 1}]`}
          data={d}
        />
      ))}

      <CCCharacterizationReportLOMCSVList
        path={`${props.path}.language`}
        title="language"
        data={languages}
      />
    </View>
  )
}
