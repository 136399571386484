import React from 'react'
import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'

import IUser from 'domains/users/models/IUser'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import { View, Text, LargeButton, Span } from 'lib/components'
import CreateEditUserModal from 'domains/users/Modals/CreateEditUserModal'
import Dialog from 'domains/application/modals/Dialog'
import { getFullName } from 'domains/users/utils/user'
import { deleteRecord } from 'lib/records/workflows/deleteRecord'
import { USERS_RECORD } from 'lib/records/modules/users'
import ChangePasswordModal from 'domains/users/Modals/ChangePasswordModal'
import trackUsersAnalytics from 'domains/users/utils/trackUsersAnalytics'
import useModalState from 'lib/hooks/useModalState'
import EditUserPreferencesModal from 'domains/users/Modals/EditUserPreferencesModal'
import { LTI_USERS_STORAGE_KEY } from 'domains/authentication/constants/lti'

interface IProps {
  user: IUser
  me: IUser
  orgId: number
  height: number
  goToUsers: () => any
}

export default function UserActionWidget(props: IProps) {
  const [editUserOpen, openEditUser, closeEditUser] = useModalState()
  const [editPrefsOpen, openEditPrefs, closeEditPrefs] = useModalState()
  const [changePasswordOpen, openChangePassword, closeChangePassword] =
    useModalState()
  const [deleteUserOpen, openDeleteUser, closeDeleteUser] = useModalState()

  const [isLTIUser, setIsLTIUser] = React.useState<null | boolean>(null)

  React.useEffect(() => {
    const ltiUsers = utils.localStorage.getLocalItem(
      LTI_USERS_STORAGE_KEY,
      {},
    ) as any
    setIsLTIUser(!!R.pathOr(false, [props.me.id], ltiUsers))
  }, []) // eslint-disable-line

  const onDeleteConfirmed = async () => {
    const {
      user: { id },
      orgId,
    } = props
    await deleteRecord(USERS_RECORD, id, null, orgId)
    await trackUsersAnalytics('user_deleted', { userId: id, orgId })
    closeDeleteUser()
    props.goToUsers()
  }

  const isMe = props.user.id === props.me.id
  return (
    <DetailsTopWidget
      title="User Actions"
      dataTest="actions-widget"
      height={props.height}
    >
      <View mt={4}>
        <LargeButton
          onClick={openEditUser}
          variant="complete"
          icon="fas fa-edit"
          title="Edit User"
          color="white"
          data-test="edit-user-btn"
        />
        <LargeButton
          onClick={openEditPrefs}
          variant="complete"
          icon="fas fa-sliders-h"
          title="Edit Preferences"
          color="white"
          data-test="edit-preferences-btn"
        />
        {!isLTIUser && (
          <LargeButton
            onClick={openChangePassword}
            variant="complete"
            icon="fas fa-key"
            title="Change Password"
            color="white"
            data-test="change-password-btn"
          />
        )}
        {!isMe && (
          <LargeButton
            onClick={openDeleteUser}
            variant="error"
            icon="fas fa-trash"
            title="Delete User"
            color="white"
            data-test="delete-user-btn"
          />
        )}
      </View>
      <CreateEditUserModal
        isOpen={editUserOpen}
        id={props.user.id}
        closeModal={closeEditUser}
        isCreate={false}
        orgId={props.orgId}
      />
      <ChangePasswordModal
        isOpen={changePasswordOpen}
        id={props.user.id}
        closeModal={closeChangePassword}
      />
      <EditUserPreferencesModal
        id={props.user.id}
        isOpen={editPrefsOpen}
        close={closeEditPrefs}
      />

      {!isMe && (
        <Dialog
          isOpen={deleteUserOpen}
          icon="fas fa-trash"
          message={
            <Text>
              You are about to delete{' '}
              <Span fontWeight={700}>{getFullName(props.user)}&apos;s</Span>{' '}
              account. This action cannot be undone.
            </Text>
          }
          confirmText="Yes, Delete"
          onConfirm={onDeleteConfirmed}
          onDeny={closeDeleteUser}
        />
      )}
    </DetailsTopWidget>
  )
}
