import React from 'react'

type Props = {
  icon?: any
  title: any
  subtitle?: any
  children: any
  'data-test'?: any
}

export default function BlankSlateWithOption(props: Props) {
  return (
    <div
      className="flex flex-col items-center"
      data-test={props['data-test'] || 'blankslate'}
    >
      <div className="flex flex-col items-center space-y-3 py-6 px-4 max-w-sm">
        {props.icon}
        <div className="flex flex-col items-center space-y-1">
          <p className="text-sm text-center font-medium text-gray-900">
            {props.title}
          </p>
          {!!props.subtitle && (
            <p className="text-sm text-center text-gray-500">
              {props.subtitle}
            </p>
          )}
        </div>
        {props.children}
      </div>
    </div>
  )
}
