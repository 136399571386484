import React from 'react'
import * as R from 'ramda'

import { View } from 'lib/components'
import { ICommonCartridgeLanguageText } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeLanguageText'
import { ICommonCartridgeLOMAnnotation } from 'domains/commonCartridge/models/lom/ICommonCartridgeLOMAnnotation'
import CCCharacterizationReportLOMLanguageText from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMLanguageText'
import { ICommonCartridgeSourceValue } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeSourceValue'
import CCCharacterizationReportLOMSourceValue from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMSourceValue'
import CCCharacterizationReportLOMLanguageTextGrouped from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMLanguageTextGrouped'
import { ICommonCartridgeLOMClassificationTaxonPath } from 'domains/commonCartridge/models/lom/ICommonCartridgeLOMClassification'
import CCCharacterizationReportLOMTaxonPath from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMTaxonPath'
import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  path: string
  data: ICommonCartridgeLOMAnnotation
}

export default function CCCharacterizationReportLOMClassification(
  props: IProps,
) {
  const { showMissingMetadata } = React.useContext(ShowMissingMetadataContext)
  const purpose = R.pathOr(
    {},
    ['purpose'],
    props.data,
  ) as ICommonCartridgeSourceValue
  const taxonPaths = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['taxonPath'],
    props.data,
  ) as ICommonCartridgeLOMClassificationTaxonPath[]
  const keywords = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['keyword'],
    props.data,
  ) as ICommonCartridgeLanguageText[]
  const descriptions = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['description'],
    props.data,
  ) as ICommonCartridgeLanguageText[]

  return (
    <View>
      <CCCharacterizationReportLOMSourceValue
        path={`${props.path}.purpose`}
        title="purpose"
        data={purpose}
      />

      {descriptions.map((description, i) => (
        <CCCharacterizationReportLOMLanguageText
          key={`${props.path}.description.${i}`}
          path={
            !utils.hasValue(description)
              ? `${props.path}.description`
              : `${props.path}.description.${i}`
          }
          title={`description [${i + 1}]`}
          data={description}
        />
      ))}

      {taxonPaths.map((taxonPath, i) => (
        <CCCharacterizationReportLOMTaxonPath
          key={`${props.path}.taxonPath.${i}`}
          path={
            !utils.hasValue(taxonPath)
              ? `${props.path}.taxonPath`
              : `${props.path}.taxonPath.${i}`
          }
          title={`taxonPath [${i + 1}]`}
          data={taxonPath}
        />
      ))}

      <CCCharacterizationReportLOMLanguageTextGrouped
        path={`${props.path}.keyword`}
        title="keyword"
        data={keywords}
      />
    </View>
  )
}
