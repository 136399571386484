import React from 'react'
import * as R from 'ramda'

import { View } from 'lib/components'
import {
  ICommonCartridgeCharacterizationReportValidation,
  ICommonCartridgeCharacterizationReportValidationError,
} from 'domains/commonCartridge/models/ICommonCartridgeCharacterizationReport'
import {
  RED_REPORT_STATUS,
  AMBER_REPORT_STATUS,
} from 'domains/reports/models/IReportStatuses'
import CCCharacterizationReportValidationErrorRow from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportValidationErrorRow'

interface IProps {
  validation: ICommonCartridgeCharacterizationReportValidation
}

export default function CCCharacterizationReportValidationErrors(
  props: IProps,
) {
  const errors = R.pathOr(
    [],
    ['errors'],
    props.validation,
  ) as ICommonCartridgeCharacterizationReportValidationError[]
  const warnings = R.pathOr(
    [],
    ['warnings'],
    props.validation,
  ) as ICommonCartridgeCharacterizationReportValidationError[]

  return (
    <View variant="paper">
      {errors.map((error, i) => (
        <View mt={3} key={`validation-${props.validation.name}-error-${i}`}>
          <CCCharacterizationReportValidationErrorRow
            status={RED_REPORT_STATUS}
            item={error}
            data-test={`validation-${props.validation.name}-error-${i}`}
          />
        </View>
      ))}
      {warnings.map((warning, i) => (
        <View mt={3} key={`validation-${props.validation.name}-warning-${i}`}>
          <CCCharacterizationReportValidationErrorRow
            status={AMBER_REPORT_STATUS}
            item={warning}
            data-test={`validation-${props.validation.name}-warning-${i}`}
          />
        </View>
      ))}
    </View>
  )
}
