import React from 'react'
import * as R from 'ramda'
import { utils, records } from '@ims/1edtech-frontend-common'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'

import {
  List,
  Icon,
  DeleteCircle,
  Text,
  Span,
  ListCallToActionButton,
} from 'lib/components'
import { RootState } from 'lib/store/rootReducer'
import { USERS_RECORD } from 'lib/records/modules/users'
import trackUsersAnalytics from 'domains/users/utils/trackUsersAnalytics'
import { IListColumnDefinition } from 'lib/components/List'
import IUser from 'domains/users/models/IUser'
import CreateEditUserModal from 'domains/users/Modals/CreateEditUserModal'
import { deleteRecord } from 'lib/records/workflows/deleteRecord'
import Dialog from 'domains/application/modals/Dialog'
import { meSelector } from 'domains/authentication/selectors/me'
import {
  getRoute,
  USERS_ROUTE,
  ORGANIZATIONS_ROUTE,
} from 'domains/application/navigation/routes'
import * as userIcons from 'domains/users/constants/users'
import UserRolesCell from 'domains/users/components/UserRolesCell'

type Props = {
  orgId: number
  useOrgRoute?: boolean
  isIMSAdminView?: boolean
}

export default function OrgUsersList(props: Props) {
  const history = useHistory()
  const me = useSelector(meSelector)
  const users = useSelector((s: RootState) =>
    records.relatedRecordsSelectors.fullRelatedRecordsSelector(
      USERS_RECORD,
      'orgId',
    )(s, props),
  )

  const [deleteUser, setDeleteUser] = React.useState<false | IUser>(false)
  const [addUser, setAddUser] = React.useState(false)

  let columns: IListColumnDefinition[] = [
    {
      title: null,
      maxWidth: 40,
      renderCell: () => (
        <Icon className="fas fa-user-circle" fontSize={40} color="primary" />
      ),
      forceCenter: true,
      noSort: true,
    },
    {
      title: 'First Name',
      noHeaderCenter: true,
      accessor: 'firstName',
      autoToolTip: true,
      forceCenter: true,
      sortKey: utils.sort.addSortValue('firstName@'),
    },
    {
      title: 'Last Name',
      noHeaderCenter: true,
      accessor: 'lastName',
      autoToolTip: true,
      forceCenter: true,
      sortKey: utils.sort.addSortValue('lastName@'),
    },
    {
      title: 'Job Title',
      noHeaderCenter: true,
      accessor: 'jobTitle',
      autoToolTip: true,
      forceCenter: true,
      sortKey: utils.sort.addSortValue('jobTitle@'),
    },
    {
      title: 'Email',
      noHeaderCenter: true,
      accessor: 'email',
      autoToolTip: true,
      forceCenter: true,
      sortKey: utils.sort.addSortValue('email@'),
    },
    {
      title: 'Last Active',
      noHeaderCenter: true,
      maxWidth: 128,
      accessor: (user: IUser) =>
        user.lastActive
          ? utils.date.formatForDisplay(user.lastActive)
          : 'Never',
      forceCenter: true,
      noSort: true,
    },
  ]

  const deleteColumn = {
    title: 'Delete',
    maxWidth: 48,
    forceCenter: true,
    noSort: true,
    renderCell: (user: IUser) =>
      R.propOr(0, 'id', me) !== user.id ? (
        <DeleteCircle
          item={user}
          onClick={setDeleteUser}
          data-test={`delete-user-${user.id}`}
        />
      ) : null,
  }

  const rolesColumn: IListColumnDefinition = {
    title: 'Roles',
    CellComponent: UserRolesCell,
    forceCenter: true,
    noSort: true,
  }

  if (props.isIMSAdminView) {
    columns = [
      ...columns,
      {
        title: 'Primary',
        maxWidth: 80,
        renderCell: (user: IUser) =>
          user.primaryContact ? (
            <Icon
              className={userIcons.PRIMARY_CONTACT_ICON}
              color={userIcons.PRIMARY_CONTACT_ICON_COLOR}
            />
          ) : (
            <Icon
              className={userIcons.NON_PRIMARY_CONTACT_ICON}
              color={userIcons.NON_PRIMARY_CONTACT_ICON_COLOR}
            />
          ),
        forceCenter: true,
        sortKey: utils.sort.addSortValue(
          'primaryContact@,lastName:ascending',
          true,
        ),
      },
      rolesColumn,
      deleteColumn,
    ]
  } else {
    columns = [...columns, rolesColumn, deleteColumn]
  }

  const onCloseDeleteDialog = () => setDeleteUser(false)

  const onDeleteConfirmed = async () => {
    const userId = (deleteUser as IUser).id
    await trackUsersAnalytics('user_deleted', { userId, orgId: props.orgId })
    await deleteRecord(USERS_RECORD, userId, null, props.orgId)
    onCloseDeleteDialog()
  }

  const onAddUserClicked = () => setAddUser(true)

  const onCloseAddUser = () => setAddUser(false)

  const onUserClicked = (user: IUser) => {
    const { orgId, useOrgRoute } = props
    if (useOrgRoute) {
      const orgUserRoute = `${getRoute(ORGANIZATIONS_ROUTE, orgId)}/users/${
        user.id
      }`
      history.push(orgUserRoute)
    } else {
      history.push(getRoute(USERS_ROUTE, user.id))
    }
  }

  const renderDeleteMessage = () => {
    if (!deleteUser) {
      return null
    }
    const user = deleteUser as IUser
    return (
      <Text>
        You are about to delete{' '}
        <Span fontWeight={700}>
          {user.firstName} {user.lastName}&apos;s
        </Span>{' '}
        account. This action cannot be undone.
      </Text>
    )
  }

  return (
    <>
      <List
        title="Users"
        record={USERS_RECORD}
        recordState={users}
        parentRecordId={props.orgId}
        columns={columns}
        rowHeight={68}
        dataTest={'users-list'}
        trackAnalytics={trackUsersAnalytics}
        trackAnalyticsCategory={'users-list'}
        initialSortColumnIndex={2}
        onRowClick={onUserClicked}
        searchTemplate="lastName=~@@ OR firstName=~@@ OR jobTitle=~@@ OR email=~@@"
        keyExtractor={R.prop('id')}
        callToAction={
          <ListCallToActionButton
            onClick={onAddUserClicked}
            data-test="add-user-btn"
            text="Add User"
          />
        }
      />

      <CreateEditUserModal
        isOpen={addUser}
        closeModal={onCloseAddUser}
        isCreate={addUser}
        orgId={props.orgId}
      />
      <Dialog
        isOpen={!!deleteUser}
        icon="fas fa-trash"
        message={renderDeleteMessage}
        confirmText="Yes, Delete"
        onConfirm={onDeleteConfirmed}
        onDeny={onCloseDeleteDialog}
      />
    </>
  )
}
