import React from 'react'

import { View, Text, Icon } from 'lib/components'
import {
  ReportStatus,
  RED_REPORT_STATUS,
  AMBER_REPORT_STATUS,
} from 'domains/reports/models/IReportStatuses'
import { utils } from '@ims/1edtech-frontend-common'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'

const isPrint = isOnPrintScreen()

interface IProps {
  status: ReportStatus
  title: string
  subtitle?: any
  description?: string
  errors?: string[]
  dataTest?: string
}

const STATUS_TO_BG_MAP: { [key: string]: string } = {
  [RED_REPORT_STATUS]: 'errorLight',
  [AMBER_REPORT_STATUS]: 'reportAmberLight',
}
const STATUS_TO_COLOR_MAP: { [key: string]: string } = {
  [RED_REPORT_STATUS]: 'error',
  [AMBER_REPORT_STATUS]: 'warningAlt',
}
const STATUS_TO_ICON_MAP: { [key: string]: string } = {
  [RED_REPORT_STATUS]: 'fas fa-exclamation-circle',
  [AMBER_REPORT_STATUS]: 'fas fa-check-circle',
}

export default function ReportTopLevelError(props: IProps) {
  const { status, title, subtitle, description, errors, dataTest } = props
  return (
    <View
      p={isPrint ? 0 : 4}
      bg={isPrint ? undefined : STATUS_TO_BG_MAP[status]}
      flexible="row"
      mb={isPrint ? 1 : 3}
      data-test={dataTest}
    >
      {!isPrint && (
        <Icon
          color={STATUS_TO_COLOR_MAP[status]}
          className={STATUS_TO_ICON_MAP[status]}
          fontSize={32}
          mr={3}
          data-test={`${dataTest}-icon`}
          data-icon={STATUS_TO_ICON_MAP[status]}
        />
      )}
      <View>
        <Text
          mt={1}
          mb={3}
          color={isPrint ? undefined : STATUS_TO_COLOR_MAP[status]}
          fontWeight={700}
          fontSize={isPrint ? 14 : 18}
        >
          {title}
        </Text>

        {utils.hasValue(subtitle) && subtitle}

        {utils.hasValue(errors) &&
          (errors || []).map((error, index) => (
            <Text
              key={`error-${index}`}
              fontStyle="italic"
              fontSize={16}
              mt={3}
            >
              {error}
            </Text>
          ))}
        {!utils.hasValue(errors) && (
          <Text fontStyle="italic" fontSize={isPrint ? 12 : 16}>
            {description}
          </Text>
        )}
      </View>
    </View>
  )
}
