import React from 'react'
import * as R from 'ramda'

import { Select, ListPlainTextCell } from 'lib/components'
import { OPTIONAL_REQUIRED_FIELD } from 'domains/modeling/constants/columns'
import IRestCModelServiceEndpointAttribute from 'domains/modeling/models/IRestCModelServiceEndpointAttribute'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import IRestCModelService from 'domains/modeling/models/IRestCModelService'
import IRestCModelServiceEndpoint from 'domains/modeling/models/IRestCModelServiceEndpoint'
import useRestCSpecServiceEndpointAttributeCell from 'domains/modeling/hooks/useRestCSpecServiceEndpointAttributeCell'

interface IProps extends IRestCModelServiceEndpointAttribute {
  specification: IRestCSpec
  service: IRestCModelService
  endpoint: IRestCModelServiceEndpoint
  endpointIndex: number
  handleChange: any
}

export default function RestCAttributeRequiredCell(props: IProps) {
  const { endpointIndex, required, handleChange, name, metadata } = props
  const [
    specAttribute,
    attributeIndex,
  ] = useRestCSpecServiceEndpointAttributeCell(props)

  if (
    metadata ||
    R.propOr('', 'required', specAttribute) === OPTIONAL_REQUIRED_FIELD
  ) {
    return (
      <Select
        onChange={handleChange}
        variant="narrow-bold"
        ml="-18px"
        width={120}
        value={required}
        name={`endpoints[${endpointIndex}].attributes[${attributeIndex}].required`}
        data-test={`${name}-required-select`}
        aria-label={`Required selector for ${name}`}
      >
        <option value="Optional">Optional</option>
        <option value="Required">Required</option>
      </Select>
    )
  }

  return <ListPlainTextCell>{required}</ListPlainTextCell>
}
