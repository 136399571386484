import React from 'react'
import { useField } from 'formik'

import Select, { SelectProps } from 'lib/components/modern/Select/Select'
import FormLabel from 'lib/components/modern/FormLabel'
import FormError from 'lib/components/modern/FormError'

type Props = SelectProps & {
  label: string
  labelExtra?: any
  required?: boolean
}

export default function FormSelect(props: Props) {
  const { label, required = false, ...selectProps } = props
  const [, meta] = useField(props)

  return (
    <div className="">
      <FormLabel
        htmlFor={props.name}
        required={required}
        extra={props.labelExtra}
      >
        {label}
      </FormLabel>
      <Select {...selectProps} />
      {meta.touched && meta.error && (
        <FormError name={props.name}>{meta.error}</FormError>
      )}
    </div>
  )
}
