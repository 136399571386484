import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import * as R from 'ramda'
import { utils, records } from '@ims/1edtech-frontend-common'

import { View, List, Button } from 'lib/components'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'
import ComparisonModal from 'domains/comparisons/modals/ComparisonModal'
import { RootState } from 'lib/store/rootReducer'
import IProduct from 'domains/products/models/IProduct'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import { productsListSelector } from 'domains/products/selectors/productsListSelector'
import { IListColumnDefinition, IListFilterOptions } from 'lib/components/List'
import DistrictProductCatalogNameCell from 'domains/products/components/DistrictProductCatalogNameCell'
import DistrictProductCatalogFormatsCell from 'domains/products/components/DistrictProductCatalogFormatsCell'
import {
  ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
  FORMAT_TO_ICON_MAP,
  ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
} from 'domains/formats/constants/formats'
import DistrictProductCatalogFilterFormat from 'domains/products/components/DistrictProductCatalogFilterFormat'
import { getRequest } from 'lib/api/api'
import { IListFilter } from 'lib/components/ListFilter/listFilterUtils'
import { formatIdByNameSelector } from 'domains/specifications/selectors/formatSelectors'
import DistrictProductCatalogPPCell from 'domains/products/components/DistrictProductCatalogPPCell'
import PreferredPartnerBadge from 'domains/products/components/PreferredPartnerBadge'
import BaseProductsBreadCrumbs from 'domains/products/components/BaseProductsBreadCrumbs'
import DistrictProductCatalogSupplierCell from 'domains/products/components/DistrictProductCatalogSupplierCell'

interface IPropsFromState {
  products: records.recordReducers.IRecordState
  csvFormatId: number
  restFormatId: number
}
const stateMap = createStructuredSelector<RootState, any, IPropsFromState>({
  products: productsListSelector,
  csvFormatId: formatIdByNameSelector(ONER_V1P1_CSV_ROSTERING_IMPORT_BULK),
  restFormatId: formatIdByNameSelector(
    ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
  ),
})

interface IState {
  compareModalOpen: boolean
  compareProductId: number
  suppliers: any[]
}

const rowProps = {
  bg: 'background',
}

export class DistrictProductCatalogScreen extends PureComponent<IPropsFromState> {
  readonly state: IState = {
    compareModalOpen: false,
    compareProductId: 0,
    suppliers: [],
  }

  async componentDidMount() {
    const response = await getRequest('products/published/orgs')
    this.setState({ suppliers: R.pathOr([], ['data', 'orgs'], response) })
  }

  onLaunchComparison = (compareProductId: string | number) => () =>
    this.setState({ compareModalOpen: true, compareProductId })

  onCloseComparison = () => this.setState({ compareModalOpen: false })

  renderCompare = (product: IProduct) =>
    product ? (
      <Button variant="complete" onClick={this.onLaunchComparison(product.id)}>
        Compare
      </Button>
    ) : null

  getColumns = (): IListColumnDefinition[] => [
    {
      title: 'Product',
      maxWidth: [150, 200, 250, 300],
      noHeaderCenter: true,
      noCenter: true,
      CellComponent: DistrictProductCatalogNameCell,
      sortKey: utils.sort.addSortValue('name@'),
    },
    {
      title: 'Supplier',
      maxWidth: [150, 200, 250, 300],
      noHeaderCenter: true,
      noCenter: true,
      CellComponent: DistrictProductCatalogSupplierCell,
      sortKey: utils.sort.addSortValue('organizationName@'),
      autoToolTip: true,
    },
    {
      title: 'Formats',
      maxWidth: 100,
      noHeaderCenter: true,
      noCenter: true,
      CellComponent: DistrictProductCatalogFormatsCell,
      noSort: true,
    },
    {
      title: null,
      maxWidth: 100,
      noHeaderCenter: true,
      noCenter: true,
      CellComponent: DistrictProductCatalogPPCell,
      noSort: true,
    },
    {
      title: null,
      maxWidth: 100,
      renderCell: this.renderCompare,
      accessor: '',
      noSort: true,
    },
  ]

  getRowProps = () => rowProps

  getFilterOptions = (): IListFilterOptions => ({
    title: 'Filter Products',
    filters: [
      {
        title: 'Supplier',
        type: 'SELECT',
        options: [
          { label: 'Select a supplier', value: -1 },
          ...this.state.suppliers.map((supplier) => ({
            label: R.propOr('', 'name', supplier),
            value: R.propOr('', 'id', supplier),
          })),
        ],
        filterKey: 'organization',
        filterOperator: '=',
      } as IListFilter,
      {
        title: 'Formats',
        type: 'CHECKBOX',
        options: [
          {
            label: (
              <DistrictProductCatalogFilterFormat
                format={ONER_V1P1_CSV_ROSTERING_IMPORT_BULK}
                label="CSV"
              />
            ),
            value: this.props.csvFormatId,
          },
          {
            label: (
              <DistrictProductCatalogFilterFormat
                format={ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL}
                label="REST"
              />
            ),
            value: this.props.restFormatId,
          },
        ],
        filterKey: 'format',
        filterOperator: '=',
      } as IListFilter,
    ],
  })

  render() {
    const { products } = this.props
    return (
      <View variant="screen">
        <DocumentTitle title="All Products" />
        <BaseProductsBreadCrumbs />
        <List
          title="All Products"
          dataTest="district-catalog-list"
          columns={this.getColumns()}
          record={PRODUCTS_RECORD}
          recordState={products}
          rowHeight={72}
          spacer={true}
          searchShouldClearSort={true}
          getRowProps={this.getRowProps}
          filterOptions={this.getFilterOptions()}
          searchTemplate="name=@@"
          legends={[
            {
              icon: <PreferredPartnerBadge />,
              label: 'Available via Preferred Partner(s)',
            },
            {
              icon: FORMAT_TO_ICON_MAP[ONER_V1P1_CSV_ROSTERING_IMPORT_BULK],
              label: 'CSV',
            },
            {
              icon: FORMAT_TO_ICON_MAP[
                ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL
              ],
              label: 'REST',
            },
          ]}
        />
        <ComparisonModal
          isOpen={this.state.compareModalOpen}
          id={this.state.compareProductId}
          closeModal={this.onCloseComparison}
          startWithProduct={true}
        />
      </View>
    )
  }
}

export default connect(stateMap)(DistrictProductCatalogScreen)
