import React from 'react'

import { utils } from '@ims/1edtech-frontend-common'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import NonTableList from 'lib/components/NonTableList'
import ICommonCartridgeCharacterizationReport from 'domains/commonCartridge/models/ICommonCartridgeCharacterizationReport'
import { View, Spinner } from 'lib/components'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import { COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_RESOURCES_RECORD } from 'lib/records/modules/commonCartridgeCharacterizationReportResources'
import CCCharacterizationReportTableResourceRow from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportTableResourceRow'

interface IProps {
  isPreview?: boolean
  standardId?: number
  report?: ICommonCartridgeCharacterizationReport
  characterization?: ICharacterization
  title?: string
  query?: string
  searchTemplate?: string
  noBlankslate?: boolean
}

export default function CommonCartridgeCharacterizationReportResourcesList(
  props: IProps,
) {
  const pending =
    !utils.hasValue(props.report) || !utils.hasValue(props.characterization)

  if (pending) {
    if (utils.hasValue(props.title)) {
      return (
        <View variant="paper">
          <WidgetHeader title={props.title} />
          <Spinner centered={true} />
        </View>
      )
    } else {
      return <Spinner centered={true} />
    }
  }

  const parentRecordId = utils.hasValue(props.standardId)
    ? props.standardId
    : props.report!.id
  const list = (
    <NonTableList
      data-test="cc-resource-list"
      record={COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_RESOURCES_RECORD}
      parentRecordId={parentRecordId}
      params={{ isCartridge: !utils.hasValue(props.standardId) }}
      RowComponent={CCCharacterizationReportTableResourceRow}
      rowData={{ characterization: props.characterization }}
      blankSlateText="No Resources"
      noHorizontalScroll={true}
      pageSize={props.isPreview ? 5 : undefined}
      noPagination={props.isPreview}
      pending={pending}
      query={props.query}
      searchTemplate={props.searchTemplate}
      usePageBottomListener={true}
      noBlankslate={props.noBlankslate}
    />
  )

  if (utils.hasValue(props.title)) {
    return (
      <View variant="paper">
        <WidgetHeader title={props.title} />
        <View mt={3} />
        {list}
      </View>
    )
  }

  return list
}
