import React from 'react'
import ReactJson from 'react-json-view'
import { utils } from '@ims/1edtech-frontend-common'

import { View } from 'lib/components'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'

interface IProps {
  noWrapper?: boolean
  rawReport: any
  title?: string
  collapsed?: boolean | number
  groupArraysAfterLength?: number
}

export const isReportDebuggingEnabled = () =>
  utils.env.getEnvVariable('REPORT_DEBUGGING') === 'true' ||
  process.env.NODE_ENV === 'development'

export default function DebugReportView(props: IProps) {
  const {
    noWrapper = false,
    collapsed = true,
    groupArraysAfterLength = 20,
  } = props
  if (!isReportDebuggingEnabled()) {
    return null
  }

  const jsonViwer = (
    <ReactJson
      src={props.rawReport}
      collapsed={collapsed}
      displayObjectSize={false}
      displayDataTypes={false}
      groupArraysAfterLength={groupArraysAfterLength}
    />
  )

  if (noWrapper) {
    return jsonViwer
  }

  return (
    <View variant="paper" mt={3}>
      {utils.hasValue(props.title) && (
        <>
          <WidgetHeader title={props.title} />
          <View mb={3} />
        </>
      )}
      {jsonViwer}
    </View>
  )
}
