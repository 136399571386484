export default interface IRestCModelServiceEndpointAttribute {
  id: number
  name: string
  description: string
  dataType: string
  required: string
  multiplicity: string
  metadata: boolean
  notes: string
  _internalId?: string
}

export const IRestCModelServiceEndpointAttributeFields = [
  'id',
  'name',
  'description',
  'dataType',
  'required',
  'multiplicity',
  'metadata',
  'notes',
]

export const mockRestCModelServiceEndpointAttribute = (
  overrides = {},
): IRestCModelServiceEndpointAttribute => ({
  id: 1,
  name: 'sourcedId',
  description: 'description',
  dataType: 'String',
  required: 'Required',
  multiplicity: '1',
  metadata: false,
  notes: '',
  ...overrides,
})
