import React from 'react'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'
import { useSelector } from 'react-redux'

import { Button, Paper, Screen, Spinner } from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import BaseBreadCrumbs from 'domains/application/components/BaseBreadCrumbs'
import { DISTRICT_TRUSTED_APPS_DASHBOARD_ROUTE } from 'domains/application/navigation/routes'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import useModalState from 'lib/hooks/useModalState'
import ShareTrustedAppsDashboardModal from 'domains/trustedApps/components/ShareTrustedAppsDashboardModal'
import { getRecords } from 'lib/records/workflows/getRecords'
import { TRUSTED_APPS_DASHBOARDS_SHARED_WITH_US_RECORD } from 'lib/records/modules/trustedAppsDashboardsSharedWithUs'
import { RootState } from 'lib/store/rootReducer'
import FormLabel from 'lib/components/modern/FormLabel'
import Select from 'lib/components/modern/Select/Select'
import TrustedAppsList from 'domains/trustedApps/components/TrustedAppsList'
import useQuery from 'lib/hooks/useQuery'
import { RouteComponentProps } from 'react-router'

export default function TrustedAppsSharedDashboardsScreen(
  props: RouteComponentProps,
) {
  const { pathname } = props.location
  const query = useQuery()
  let qOrgId = query.get('orgId')
  if (!qOrgId && pathname.includes('?orgId=')) {
    const locationSearch = new URLSearchParams(pathname.split('shared')[1])
    qOrgId = locationSearch.get('orgId')
  }
  const [queryOrgId, setQueryOrgId] = React.useState<null | number>(
    parseInt(`${qOrgId}`) || null,
  )
  const [sharedModalIsOpen, openSharedModal, closeSharedModal] = useModalState()
  const [selectedOrgId, setSelectedOrgId] = React.useState<null | number>(
    queryOrgId,
  )

  const [initialSelectedOrgName, setInitialSelectedOrgName] = React.useState('')

  const onOrgSelected = (orgId: number) => {
    const foundOrg = orgsSharingWithUsOptions.find((o) => o.value === orgId)
    setInitialSelectedOrgName(foundOrg ? foundOrg.label : '')
    setSelectedOrgId(orgId)
  }

  React.useEffect(() => {
    const search = new URLSearchParams(props.location.search)
    if (selectedOrgId && !search.get('orgId')) {
      search.append('orgId', `${selectedOrgId}`)
      props.history.replace({
        pathname: props.location.pathname,
        search: search.toString(),
      })
    } else if (!selectedOrgId && search.get('orgId')) {
      props.history.replace({
        pathname: props.location.pathname,
        search: '',
      })
    } else if (selectedOrgId && search) {
      search.set('orgId', `${selectedOrgId}`)
      props.history.replace({
        pathname: props.location.pathname,
        search: search.toString(),
      })
    }
  }, [
    selectedOrgId,
    props.location.pathname,
    props.location.search,
    props.history,
  ])
  const orgsSharingWithUs = useSelector((s: RootState) =>
    records.recordsSelectors.fullRecordsSelector(
      TRUSTED_APPS_DASHBOARDS_SHARED_WITH_US_RECORD,
    )(s, {}),
  )

  React.useEffect(() => {
    getRecords({
      record: TRUSTED_APPS_DASHBOARDS_SHARED_WITH_US_RECORD,
      params: { sort: 'ownerOrgName:ascending' },
      pageSize: 500,
    })
  }, []) // eslint-disable-line

  const orgsSharingWithUsLoading = R.pathOr([], ['loading'], orgsSharingWithUs)
  const orgsSharingWithUsOptions = (
    R.pathOr([], ['items'], orgsSharingWithUs) as any[]
  ).map((org) => ({
    label: org.ownerOrgName,
    value: parseInt(org.ownerOrgId),
  }))
  React.useEffect(() => {
    if (
      !orgsSharingWithUsLoading &&
      queryOrgId &&
      initialSelectedOrgName.length < 1
    ) {
      const foundOrg = orgsSharingWithUsOptions.find(
        (o) => o.value === queryOrgId,
      )
      if (foundOrg) setInitialSelectedOrgName(foundOrg.label)
      else setQueryOrgId(0)
    }
  }, [
    queryOrgId,
    initialSelectedOrgName,
    orgsSharingWithUsLoading,
    orgsSharingWithUsOptions,
  ])

  return (
    <Screen>
      <DocumentTitle title="TrustEd Apps - Shared Dashboards" />
      <BaseBreadCrumbs
        crumbs={[
          {
            name: 'TrustEd Apps',
            route: DISTRICT_TRUSTED_APPS_DASHBOARD_ROUTE,
          },
          {
            name: initialSelectedOrgName
              ? `${initialSelectedOrgName} - TrustEd Apps`
              : 'Shared Dashboards',
          },
        ]}
      />

      <div className="flex flex-col space-y-6">
        <Paper>
          <div className="flex flex-col space-y-6">
            <div className="flex flex-col space-y-2 md:flex-row md:items-center md:justify-between md:space-y-0">
              <WidgetHeader title="Shared Dashboards" />
              <Button
                variant="complete"
                onClick={openSharedModal}
                data-test="view-edit-sharing-btn"
              >
                View/Edit Institutions we have shared with
              </Button>
            </div>

            {orgsSharingWithUsLoading && <Spinner centered />}
            {!orgsSharingWithUsLoading && (
              <div>
                {orgsSharingWithUsOptions.length < 1 && (
                  <div
                    className="py-12 flex flex-col w-full items-center space-y-4"
                    data-test="orgs-sharing-blankslate"
                  >
                    <i className="fas fa-eye-slash text-text text-2xl" />
                    <p className="text-lg text-text text-center font-bold">
                      No districts have shared their dashboard with you
                    </p>
                  </div>
                )}
                {orgsSharingWithUsOptions.length > 0 &&
                  ((!!queryOrgId && initialSelectedOrgName.length > 0) ||
                    !queryOrgId) && (
                    <div className="flex flex-col w-full space-y-1">
                      <FormLabel htmlFor="selectedOrgId">
                        View Shared Dashboard
                      </FormLabel>
                      <Select
                        name="selectedOrgId"
                        selected={selectedOrgId}
                        onChange={onOrgSelected}
                        options={orgsSharingWithUsOptions}
                        placeholder="Select One"
                        initialLabel={initialSelectedOrgName}
                      />
                    </div>
                  )}
              </div>
            )}
          </div>
        </Paper>

        {!orgsSharingWithUsLoading &&
          orgsSharingWithUsOptions.length > 0 &&
          !selectedOrgId && (
            <Paper>
              <WidgetHeader
                title="No Dashboard selected..."
                dataTest="trusted-apps-list-title"
              />
            </Paper>
          )}

        {selectedOrgId && (
          <TrustedAppsList parentOrgId={parseInt(`${selectedOrgId}`)} />
        )}
      </div>

      <ShareTrustedAppsDashboardModal
        isOpen={sharedModalIsOpen}
        onRequestClose={closeSharedModal}
      />
    </Screen>
  )
}
