import React, { PureComponent } from 'react'
import { Linkify } from 'lib/components'

interface IProps {
  description?: string
}
export default class SpecificationDescription extends PureComponent<IProps> {
  render() {
    const { description } = this.props
    if (!description) {
      return null
    }

    return <Linkify>{description}</Linkify>
  }
}
