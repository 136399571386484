import React from 'react'

import {
  AnySpecFormatType,
  ANY_FORMAT_TO_SPEC_FORMAT_MAP,
  ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
} from 'domains/formats/constants/formats'
import ProductModelingWrapper from 'domains/modeling/components/ProductModeling/ProductModelingWrapper'
import ProductModelDetailInput from 'domains/modeling/components/ProductModeling/ProductModelDetailInput'
import { IViewProps } from 'lib/components/View'

export interface IProductModelValues {
  oauthSupport?: 0 | 1 | 2 | 3
  oauth2Supported?: boolean
  notes: string
  implementationUrl: string
  onlineHelpUrl: string
  productSupportEmail: string
}
interface IProps extends IViewProps {
  format: AnySpecFormatType
  handleChange: any
  handleBlur: any
  values: IProductModelValues
}

const getNotesLabel = (format: AnySpecFormatType) => {
  switch (ANY_FORMAT_TO_SPEC_FORMAT_MAP[format]) {
    case ONER_V1P1_CSV_ROSTERING_IMPORT_BULK:
      return 'Notes on your fileset:'
    default:
      return 'Notes on your Endpoint set:'
  }
}
const getNotesPlaceholder = (format: AnySpecFormatType) => {
  switch (ANY_FORMAT_TO_SPEC_FORMAT_MAP[format]) {
    case ONER_V1P1_CSV_ROSTERING_IMPORT_BULK:
      return 'Fileset Notes'
    default:
      return 'Endpoint Set Notes'
  }
}

export default function ProductModelDetailEditor(props: IProps) {
  return (
    <ProductModelingWrapper {...props}>
      <ProductModelDetailInput
        label={getNotesLabel(props.format)}
        name="notes"
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        value={props.values.notes}
        placeholder={getNotesPlaceholder(props.format)}
        aria-label={getNotesPlaceholder(props.format)}
        isTextArea={true}
      />
      <ProductModelDetailInput
        label="Implementation Documentation URL"
        name="implementationUrl"
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        value={props.values.implementationUrl}
        placeholder="http://implementationurl.net"
        aria-label="Implementation URL"
      />
      <ProductModelDetailInput
        label="Online Help URL"
        name="onlineHelpUrl"
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        value={props.values.onlineHelpUrl}
        placeholder="http://onlineHelpUrl.net"
        aria-label="Online Help URL"
      />
      <ProductModelDetailInput
        label="Product Support Email"
        name="productSupportEmail"
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        value={props.values.productSupportEmail}
        placeholder="support@yourcompany.com"
        aria-label="Product Support Email"
      />
    </ProductModelingWrapper>
  )
}
