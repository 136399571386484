export const PUBLISHED_PRODUCT_STATUS = 'Published'
export const PRIVATE_PRODUCT_STATUS = 'Private'

export type IProductStatuses = 'Published' | 'Private'

export const NOT_MODELLED_MODELLING_STATUS = 'NotModelled'
export const IN_MODELLING_MODELLING_STATUS = 'InModelling'
export const MODELLED_MODELLING_STATUS = 'Modelled'
export const CHARACTERISED_MODELLING_STATUS = 'Characterised'

export type IProductModelingStatuses =
  | 'NotModelled'
  | 'InModelling'
  | 'Modelled'
  | 'Characterised'

export const NOT_MODELLED_MODELLING_VALUE = 0
export const IN_MODELLING_MODELLING_VALUE = 1
export const MODELLED_MODELLING_VALUE = 2
export const CHARACTERISED_MODELLING_VALUE = 2

export const MODELLING_STATUS_TO_COMPARISON_VALUE: { [key: string]: number } = {
  [NOT_MODELLED_MODELLING_STATUS]: NOT_MODELLED_MODELLING_VALUE,
  [IN_MODELLING_MODELLING_STATUS]: IN_MODELLING_MODELLING_VALUE,
  [MODELLED_MODELLING_STATUS]: MODELLED_MODELLING_VALUE,
  [CHARACTERISED_MODELLING_STATUS]: CHARACTERISED_MODELLING_VALUE,
}
