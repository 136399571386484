import React from 'react'
import { PieChart, Pie, Cell, Tooltip } from 'recharts'
import * as R from 'ramda'

import * as vettings from 'domains/trustedApps/models/IIMSApplicationVetting'
import IIMSApplicationVetting from 'domains/trustedApps/models/IIMSApplicationVetting'

type CustomTooltipProps = {
  active: boolean
  payload: any
  label: any
}
const CustomTooltip = (props: CustomTooltipProps) => {
  if (props.active) {
    const name = R.pathOr(0, ['payload', 0, 'name'], props)
    const value = R.pathOr(0, ['payload', 0, 'value'], props)
    return (
      <div className="bg-gray-100 opacity-80 py-1 px-2 rounded">
        <p className="text-sm">
          {name}: {value}
        </p>
      </div>
    )
  }

  return null
}

type TrustedAppVettingRubricScoreProps = {
  vetting: IIMSApplicationVetting
  categoryPrefix: string
}

export default function TrustedAppVettingRubricScore(
  props: TrustedAppVettingRubricScoreProps,
) {
  const groupedSelections = props.vetting.selections
    .filter((selection) => selection.question.startsWith(props.categoryPrefix))
    .reduce((agg, selection) => {
      const count = R.pathOr(0, [selection.userSelection], agg)
      return R.assoc(`${selection.userSelection}`, count + 1, agg)
    }, {})
  const selections = R.keys(groupedSelections).map((value) => ({
    name: vettings.VETTING_RUBRIC_SCORE_TITLE_MAP[value],
    value: groupedSelections[value],
    color: vettings.VETTING_RUBRIC_SCORE_BG_HEX_MAP[value],
  }))

  const PIE_SIZE = 88
  return (
    <div className="flex flex-col items-center justify-center">
      <PieChart width={PIE_SIZE} height={PIE_SIZE + 1}>
        <Pie
          data={selections}
          cx="50%"
          cy="50%"
          outerRadius={PIE_SIZE / 2}
          dataKey="value"
        >
          {selections.map((entry, index) => {
            return <Cell key={`cell-${index}`} fill={entry.color} />
          })}
        </Pie>
        <Tooltip content={CustomTooltip} />
      </PieChart>
      <p className="mt-2 uppercase text-md text-center text-text">
        {vettings.RUBRIC_PREFIX_TITLE_MAP[props.categoryPrefix]}
      </p>
    </div>
  )
}
