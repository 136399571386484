import store from 'lib/store'
import trackAuthenticationAnalytics from 'domains/authentication/utils/trackAuthenticationAnalytics'
import { setApplicationProperties } from 'domains/application/ducks/application'
import { history } from 'lib/utils/navigation'
import { LOGIN_ROUTE } from 'domains/application/navigation/routes'
import { RESET_STORE } from 'lib/store/rootReducer'
import { cancelNotificationListener } from 'domains/notifications/workflows/listenForNotifications'
import { setTokens } from 'lib/api/utils'
import { clearTrustedAppsFilter } from 'domains/trustedApps/hooks/useTrustedAppsFilter'

export const logout = (goToLogin = true) => {
  cancelNotificationListener()
  setTokens(null)
  store.dispatch({ type: RESET_STORE })
  store.dispatch(
    setApplicationProperties({ me: false, loggedOut: true, initialized: true }),
  )
  clearTrustedAppsFilter()
  if (goToLogin) {
    trackAuthenticationAnalytics('logged_out')
    history.push(LOGIN_ROUTE)
  }
}
