import React from 'react'

export default function IMSApplicationsFilterGroupTitle({
  children,
}: {
  children: string
}) {
  return (
    <p className="text-base font-bold my-1" data-test={`${children}-label`}>
      {children}
    </p>
  )
}
