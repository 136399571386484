import React, { useState, useEffect } from 'react'

import { utils } from '@ims/1edtech-frontend-common'
import { Image, Icon, View, Text } from 'lib/components'
import getOrgLogo from 'domains/orgs/utils/getOrgLogo'

interface IProps {
  logoUrl?: string
  name?: string
  size?: number
  fallbackIcon?: string
  displayError?: string
  'data-test'?: string
}

const DistrictLogo = (props: IProps) => {
  const [hasError, setError] = useState(false)
  const onError = () => setError(true)

  useEffect(() => {
    setError(false)
  }, [props.logoUrl])

  const {
    logoUrl,
    name,
    size = 50,
    fallbackIcon = 'fas fa-school',
    displayError = '',
  } = props

  const icon = (
    <View width={size} height={size} flexible="column-center">
      <Icon
        className={fallbackIcon}
        fontSize={size === 50 ? 18 : Math.round(size / 2)}
        data-test={`${props['data-test']}-icon`}
      />
    </View>
  )

  let content: any = icon

  if (hasError) {
    content = (
      <View flexible="column-center">
        {icon}
        {utils.hasValue(displayError) && (
          <Text
            mt={2}
            color="error"
            variant="caption"
            textAlign="center"
            data-test={`${props['data-test']}-error`}
          >
            {displayError}
          </Text>
        )}
      </View>
    )
  } else if (utils.hasValue(logoUrl)) {
    content = (
      <View width={size} height={size} flexible="column-center">
        <Image
          maxWidth={size}
          maxHeight={size}
          src={getOrgLogo(logoUrl!)}
          alt={`Organization logo for ${name}`}
          onError={onError}
          data-test={props['data-test']}
        />
      </View>
    )
  }

  return (
    <View height={size + 16} flexible="column-center">
      {content}
    </View>
  )
}

export default DistrictLogo
