import { createStore, compose } from 'redux'
import { utils } from '@ims/1edtech-frontend-common'

import rootReducer from 'lib/store/rootReducer'

const composeEnhancers: any =
  process.env.NODE_ENV === 'development' ||
  utils.host.isRunningOnDev ||
  utils.host.isRunningOnStaging
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose

const store = createStore(rootReducer, {}, composeEnhancers())

export default store
