import * as R from 'ramda'
import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

import {
  prepareForCreate,
  prepareForUpdate,
} from 'domains/modeling/utils/csvCModelToServer'
import { ICSVConsumerFile } from 'domains/modeling/models/ICSVConsumerModel'
import { ICSVConsumerFileColumn } from 'domains/modeling/models/ICSVConsumerModel'

export const CSV_C_MODELS_RECORD = 'csvCModels'

const csvCModels: records.modules.IModule = {
  record: CSV_C_MODELS_RECORD,
  schema: new normalizr.schema.Entity(
    CSV_C_MODELS_RECORD,
    {},
    {
      processStrategy: R.compose(
        (model) => ({
          ...model,
          files: model.files.map((file: ICSVConsumerFile) => ({
            ...file,
            columns: R.sort<ICSVConsumerFileColumn>(
              R.ascend(R.prop<string>('position')),
              R.propOr([], 'columns', file),
            ),
          })),
        }),
        records.modules.utils.fixIdAttribute,
      ),
    },
  ),
  api: {
    create: ({ productId }) => `products/${productId}/csvmodels`,
    prepareCreateData: prepareForCreate,

    get: (id, { productId }) => `products/${productId}/csvmodels/${id}`,
    getList: () => '',
    update: (id, { productId }) => `products/${productId}/csvmodels/${id}`,
    prepareUpdateData: prepareForUpdate,
    delete: (id, { productId }) => `products/${productId}/csvmodels/${id}`,
  },
}

export default csvCModels
