import React from 'react'
import * as R from 'ramda'

import ICharacterizationCSVReport from 'domains/characterizations/models/ICharacterizationCSVReport'
import { utils } from '@ims/1edtech-frontend-common'
import { IFileSetValidationSummary } from 'domains/characterizations/models/IFileSetValidation'
import ICharacterizationReportFileSetCharacteriztion from 'domains/characterizations/models/ICharacterizationReportFileSetCharacteriztion'
import CharacterizationSummaryHeader from 'domains/dashboard/components/RecentCharacterizationSummaryWidget/CharacterizationSummaryHeader'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import CharacterizationSummaryItem from './CharacterizationSummaryItem'

type Summary = {
  field?: string
  description: string
}
type Summaries = {
  [filename: string]: Summary[]
}

type CSVPCharacterizationSummaryProps = {
  report: ICharacterizationCSVReport
  characterization: ICharacterization
}

export default function CSVPCharacterizationSummary(
  props: CSVPCharacterizationSummaryProps,
) {
  let summaries: Summaries = {}
  if (
    utils.hasValue(props.report.fileSetValidations) &&
    !utils.hasValue(props.report.fileSetCharacterization)
  ) {
    summaries = (R.pathOr(
      [],
      ['fileSetValidations', 0, 'summary'],
      props.report,
    ) as IFileSetValidationSummary[]).reduce((agg, sum) => {
      const filename = sum.filename || 'Critial Error'
      const existing = R.pathOr([], [filename], agg)
      agg = R.assoc(filename, [...existing, sum], agg)
      return agg
    }, {})
  } else {
    summaries = (R.pathOr(
      [],
      ['fileSetCharacterization'],
      props.report,
    ) as ICharacterizationReportFileSetCharacteriztion[]).reduce(
      (agg, fileChar) => {
        if (!utils.hasValue(fileChar.summary)) {
          return agg
        }

        const existing = R.pathOr([], [fileChar.fileLabel], agg)
        agg = R.assoc(
          fileChar.fileLabel,
          [...existing, ...fileChar.summary!],
          agg,
        )
        return agg
      },
      {},
    )
  }

  return (
    <div className="space-y-4 mt-4">
      {R.keys(summaries)
        .sort((a, b) => (a === b ? 0 : a < b ? -1 : 1))
        .map((filename, index) => {
          const items = summaries[filename]
          return (
            <div key={`summary-${filename}=${index}`}>
              <CharacterizationSummaryHeader
                title={`${filename}`}
                status={props.characterization.status || 'RED'}
              />
              <div className="space-y-1 mt-1">
                {items.map((item, index) => (
                  <CharacterizationSummaryItem
                    key={`summary-${filename}=${index}`}
                    {...item}
                  />
                ))}
              </div>
            </div>
          )
        })}
    </div>
  )
}
