import clsx from 'clsx'
import { Input, ToolTip } from 'lib/components'
import React from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { twMerge } from 'tailwind-merge'
import { CustomRatingIconSelect } from './TrustedAppsCustomRatingSetupIconSelect'
import { CustomRating } from './types'

interface CustomRatingRowProps extends React.HTMLAttributes<HTMLDivElement> {
  containerRef: any
  rating: CustomRating
  onRemove(): void
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined
  onChangeText(text: string): void
  onChangeIconIdentifier(iconIdentifier: string): void
  onChangeIconColor(iconColor: string): void
  onChangeEmbedded(value: boolean): void
}

export function CustomRatingRow({
  rating,
  onRemove,
  containerRef,
  dragHandleProps,
  onChangeIconColor,
  onChangeText,
  onChangeIconIdentifier,
  onChangeEmbedded,
  ...divProps
}: CustomRatingRowProps) {
  return (
    <div
      {...divProps}
      className={twMerge(
        'flex items-center gap-2 p-3 rounded-lg',
        divProps.className,
      )}
      style={{ ...divProps.style, backgroundColor: '#F4F4F4' }}
      ref={containerRef}
      {...dragHandleProps}
    >
      <div className="flex items-center gap-6 w-full">
        <div
          className="h-14 w-3 rounded-full"
          style={{ backgroundColor: '#D9D9D9' }}
        />
        <CustomRatingIconSelect
          iconIdentifier={rating.iconIdentifier}
          iconColor={rating.iconColor}
          onSelectIconIdentifier={onChangeIconIdentifier}
          onSelectIconColor={onChangeIconColor}
        />
        <Input
          value={rating.ratingText}
          onChange={(event) => onChangeText(event.currentTarget.value)}
          className="mr-1 font-bold"
          fontSize={20}
        />
      </div>

      <button
        className="w-8 h-8 rounded-full flex-shrink-0 flex items-center justify-center"
        onClick={() => onChangeEmbedded(!rating.isEmbedded)}
      >
        <ToolTip tip="Visible on public dashboard?">
          <i
            className={clsx('fas text-charcoal hover:text-complete', {
              'fa-eye': rating.isEmbedded,
              'fa-eye-slash': !rating.isEmbedded,
            })}
          />
        </ToolTip>
      </button>

      <button
        className="w-8 h-8 rounded-full flex-shrink-0 flex items-center justify-center"
        onClick={onRemove}
      >
        <i className="fas fa-trash text-charcoal hover:text-error-40" />
      </button>
    </div>
  )
}
