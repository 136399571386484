import * as R from 'ramda'
import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

export const REST_C_MODEL_CHARACTERIZATION_REPORTS_RECORD =
  'restCModelCharacterizationReports'

const csvConsumerModelCharacterizations: records.modules.IModule = {
  record: REST_C_MODEL_CHARACTERIZATION_REPORTS_RECORD,
  noResponseDataKey: true,
  schema: new normalizr.schema.Entity(
    REST_C_MODEL_CHARACTERIZATION_REPORTS_RECORD,
    {},
    {
      processStrategy: R.compose(
        (model) => ({
          ...model,
          // files: model.fileComparisons
          //   .sort(R.ascend(R.prop<string>('filename')))
          //   .map((file: ICSVConsumerFile) => ({
          //     ...file,
          //     columns: R.sort<ICSVConsumerFileColumn>(
          //       R.ascend(R.prop<string>('position')),
          //       R.propOr([], 'columns', file),
          //     ),
          //   })),
        }),
        records.modules.utils.fixIdAttribute,
      ),
      idAttribute: 'comparisonId',
    },
  ),
  api: {
    create: () => 'comparisons/rest/report',
    get: (id) => `comparisons/rest/report/${id}`,
    getList: (page) => `comparisons/rest/report?page=${page}`,
    update: (id) => `comparisons/rest/report/${id}`,
    delete: (id) => `comparisons/rest/report/${id}`,
  },
}

export default csvConsumerModelCharacterizations
