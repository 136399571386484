import React from 'react'
import * as R from 'ramda'

import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import LargeWidget from 'domains/dashboard/components/LargeWidget'
import IComparison from 'domains/comparisons/models/IComparison'
import { View, Text } from 'lib/components'
import ORDashboardRecentComparisonRow from 'domains/comparisons/components/ORDashboardRecentComparisonRow'

interface IProps {
  recentComparisons: IComparison[]
  fadeDelay: number
}

export default function ORCheckDashboardRecentComparisonsWidget(props: IProps) {
  return (
    <LargeWidget
      fadeDelay={props.fadeDelay}
      dataTest="recent-comparisons-widget"
    >
      <View
        flexible="row-space-between"
        p={2}
        borderColor="background"
        borderBottom="solid 1px"
      >
        <WidgetHeader title="Recent Comparisons" />
        <Text fontWeight={700} textAlign="center">
          Status
        </Text>
      </View>
      {R.pathOr([], ['recentComparisons'], props).map((comparison, i) => (
        <ORDashboardRecentComparisonRow
          key={`recent-comparison-${i}`}
          index={i}
          comparison={comparison}
        />
      ))}
    </LargeWidget>
  )
}
