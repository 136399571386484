import * as normalizr from 'normalizr'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

import { prepareRESTCharacterizationForServer } from 'domains/characterizations/utils/prepareRESTCharacterizationForServer'

export const OR_REST_PUSH_CHARACTERIZATIONS_RECORD =
  'orRESTPushCharacterizations'

const orRESTPushCharacterizations: records.modules.IModule = {
  record: OR_REST_PUSH_CHARACTERIZATIONS_RECORD,
  responseDataKey: 'characterizationReport',
  schema: new normalizr.schema.Entity(
    OR_REST_PUSH_CHARACTERIZATIONS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(),
  ),
  api: {
    create: () => 'characterizations/rest/push',
    prepareCreateData: prepareRESTCharacterizationForServer,
    get: (id) => `characterizations/rest/${id}`,
    getList: (params, orgId) => {
      const formatId = R.propOr(false, 'formatId', params)
      if (formatId) {
        return `formats/${formatId}/characterizations`
      }
      return `organizations/${orgId}/characterizations`
    },
    update: (id) => `characterizations/rest/push/${id}`,
    delete: (id) => `conformancereports/rest/push/session/${id}`,
  },
}

export default orRESTPushCharacterizations
