import React from 'react'
import * as R from 'ramda'

import { View } from 'lib/components'
import {
  ICommonCartridgeLOMTechnical,
  ICommonCartridgeOrComposite,
} from 'domains/commonCartridge/models/lom/ICommonCartridgeLOMTechnical'
import { ICommonCartridgeLanguageText } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeLanguageText'
import { ICommonCartridgeDuration } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeDuration'
import CCCharacterizationReportLOMCSVList from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMCSVList'
import CCCharacterizationReportLOMDetail from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMDetail'
import CCCharacterizationReportLOMRequirement from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMRequirement'
import CCCharacterizationReportLOMLanguageText from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMLanguageText'
import CCCharacterizationReportLOMDuration from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMDuration'
import { utils } from '@ims/1edtech-frontend-common'
import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'

interface IProps {
  data: ICommonCartridgeLOMTechnical
  path: string
}

export default function CCCharacterizationReportLOMTechnical(props: IProps) {
  const { showMissingMetadata } = React.useContext(ShowMissingMetadataContext)
  const formats = R.pathOr(
    showMissingMetadata ? [''] : [],
    ['format'],
    props.data,
  ) as string[]
  const size = R.pathOr('', ['size'], props.data) as string
  const locations = R.pathOr(
    showMissingMetadata ? [''] : [],
    ['location'],
    props.data,
  ) as string[]
  const requirements = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['requirement'],
    props.data,
  ) as ICommonCartridgeOrComposite[]
  const installationRemarks = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['installationRemarks'],
    props.data,
  ) as ICommonCartridgeLanguageText[]

  const otherPlatformRequirements = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['otherPlatformRequirements'],
    props.data,
  ) as ICommonCartridgeLanguageText[]

  const duration = R.pathOr(
    {},
    ['duration'],
    props.data,
  ) as ICommonCartridgeDuration

  return (
    <View>
      <CCCharacterizationReportLOMCSVList
        path={`${props.path}.formats`}
        title="formats"
        data={formats}
      />
      <CCCharacterizationReportLOMDetail
        title="size"
        value={size}
        path={`${props.path}.size`}
      />
      <CCCharacterizationReportLOMCSVList
        title="location"
        data={locations}
        path={`${props.path}.location`}
      />
      {requirements.map((requirement, i) => (
        <CCCharacterizationReportLOMRequirement
          key={`${props.path}.requirement.${i}`}
          path={
            !utils.hasValue(requirement)
              ? `${props.path}.requirement`
              : `${props.path}.requirement.${i}`
          }
          title={`requirement [${i + 1}]`}
          data={requirement}
        />
      ))}
      {installationRemarks.map((ir, i) => (
        <CCCharacterizationReportLOMLanguageText
          key={`${props.path}.installationRemarks.${i}`}
          path={
            !utils.hasValue(ir)
              ? `${props.path}.installationRemarks`
              : `${props.path}.installationRemarks.${i}`
          }
          title={`installationRemarks [${i + 1}]`}
          data={ir}
        />
      ))}
      {otherPlatformRequirements.map((opr, i) => (
        <CCCharacterizationReportLOMLanguageText
          key={`${props.path}.otherPlatformRequirements.${i}`}
          path={
            !utils.hasValue(opr)
              ? `${props.path}.otherPlatformRequirements`
              : `${props.path}.otherPlatformRequirements.${i}`
          }
          title={`otherPlatformRequirements [${i + 1}]`}
          data={opr}
        />
      ))}
      <CCCharacterizationReportLOMDuration
        path={`${props.path}.duration`}
        title="duration"
        data={duration}
      />
    </View>
  )
}
