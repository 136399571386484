import React from 'react'
import * as R from 'ramda'

import IIMSApplicationOrganization from 'domains/trustedApps/models/IIMSApplicationOrganization'
import { SelectOptionProps } from 'lib/components/modern/Select/SelectOption'
import { IMS_APPLICATION_ORGANIZATIONS_RECORD } from 'lib/records/modules/imsApplicationOrgs'
import { getRecords } from 'lib/records/workflows/getRecords'

export default function useTrustedAppsSupplierOptions(
  getInitial = true,
  useUuid = false,
): [
  SelectOptionProps[],
  SelectOptionProps[],
  () => void,
  (search?: string) => Promise<SelectOptionProps[]>,
] {
  const [initialSupplierOptions, setInitialSupplierOptions] = React.useState<
    SelectOptionProps[]
  >([])
  const [supplierOptions, setSupplierOptions] = React.useState<
    SelectOptionProps[]
  >([])

  const getSupplierOptions = React.useCallback(
    async (search?: string) => {
      if (search && search.length < 1) return initialSupplierOptions

      const response = await getRecords({
        record: IMS_APPLICATION_ORGANIZATIONS_RECORD,
        params: !!search ? { filter: `name=~${search}` } : {},
        pageSize: 10000,
        force: true,
      })
      if (response?.success) {
        const options = R.pathOr([], ['data', 'applications'], response).map(
          (o: IIMSApplicationOrganization) => ({
            label: o.orgName,
            value: (useUuid ? o.orgUuid :  o.orgName),
          }),
        ) as SelectOptionProps[]
        setSupplierOptions(options)
        return options
      }
      return []
    },
    [initialSupplierOptions, useUuid],
  )

  React.useEffect(() => {
    const getInitialOrgList = async () => {
      const opts = await getSupplierOptions()
      setInitialSupplierOptions(opts)
    }
    if (getInitial) getInitialOrgList()
  }, [getInitial]) // eslint-disable-line

  return [
    initialSupplierOptions,
    supplierOptions,
    // Reset Supplier options to initial
    () => setSupplierOptions(initialSupplierOptions),
    getSupplierOptions,
  ]
}
