import React from 'react'
import { useSelector } from 'react-redux'
import { utils, records } from '@ims/1edtech-frontend-common'

import { Linkify, List, ListPlainTextCell, Screen } from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import BaseBreadCrumbs from 'domains/application/components/BaseBreadCrumbs'
import { DISTRICT_TRUSTED_APPS_DASHBOARD_ROUTE } from 'domains/application/navigation/routes'
import { RootState } from 'lib/store/rootReducer'
import { TRUSTED_APPS_CHANGE_LOG_RECORD } from 'lib/records/modules/trustedAppsChangelog'
import trackTrustedAppsAnalytics from 'domains/trustedApps/utils/trackTrustedAppsAnalytics'
import { IListColumnDefinition } from 'lib/components/List'
import ITrustedAppChangelog from 'domains/trustedApps/models/ITrustedAppChangelog'

const columns: IListColumnDefinition[] = [
  {
    title: 'Date',
    CellComponent: (props: ITrustedAppChangelog) => (
      <ListPlainTextCell>
        {utils.date.formatForDisplay(props.date)}
      </ListPlainTextCell>
    ),
    sortKey: utils.sort.addSortValue('date@', true),
    forceCenter: true,
  },
  {
    title: 'Application',
    accessor: 'applicationName',
    sortKey: utils.sort.addSortValue('applicationName@'),
  },
  {
    title: 'Activity',
    accessor: 'activity',
    sortKey: utils.sort.addSortValue('activity@'),
  },
  {
    title: 'Name',
    CellComponent: (props: ITrustedAppChangelog) => (
      <ListPlainTextCell>
        {props.lastName}, {props.firstName}
      </ListPlainTextCell>
    ),
    sortKey: utils.sort.addSortValue('lastName@,firstName:ascending'),
  },
  {
    title: 'Email',
    CellComponent: (props: ITrustedAppChangelog) => (
      <ListPlainTextCell>
        <Linkify>{props.email}</Linkify>
      </ListPlainTextCell>
    ),
    sortKey: utils.sort.addSortValue('email@'),
  },
]

export default function TrustedAppsChangelogScreen() {
  const trustedAppsChangeLog = useSelector((s: RootState) =>
    records.recordsSelectors.fullRecordsSelector(
      TRUSTED_APPS_CHANGE_LOG_RECORD,
    )(s, {}),
  )

  return (
    <Screen>
      <DocumentTitle title="TrustEd Apps - Changelog" />
      <BaseBreadCrumbs
        crumbs={[
          {
            name: 'TrustEd Apps',
            route: DISTRICT_TRUSTED_APPS_DASHBOARD_ROUTE,
          },
          { name: 'Changelog' },
        ]}
      />

      <List
        title="Changelog"
        record={TRUSTED_APPS_CHANGE_LOG_RECORD}
        recordState={trustedAppsChangeLog}
        columns={columns}
        dataTest="trusted-apps-changelog-list"
        trackAnalytics={trackTrustedAppsAnalytics}
        trackAnalyticsCategory="trusted_apps_changelog_list"
        initialSortColumnIndex={0}
        noSearch
      />
    </Screen>
  )
}
