import { createSelector } from 'reselect'
import { records } from '@ims/1edtech-frontend-common'

import {
  AnyFormatType,
  ANY_FORMAT_TO_SPEC_FORMAT_MAP,
  ONER_V1P1_CSV_ROSTERING_EXPORT_BULK,
  ONER_V1P2_CSV_ROSTERING_EXPORT_BULK,
  ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
} from 'domains/formats/constants/formats'
import { OR_REST_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/orRESTCharacterizations'
import { OR_CSV_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/orCSVCharacterizations'

export const characterizationByFormatSelector = (
  formatProp = 'format',
  idProp = 'id',
) =>
  createSelector(
    records.entitiesSelectors.idSelector(formatProp),
    records.entitiesSelectors.idSelector(idProp),
    records.entitiesSelectors.entityListSelector(
      OR_REST_CHARACTERIZATIONS_RECORD,
    ),
    records.entitiesSelectors.entityListSelector(
      OR_CSV_CHARACTERIZATIONS_RECORD,
    ),
    (format, id, restCharacterizations, characterizations) => {
      switch (ANY_FORMAT_TO_SPEC_FORMAT_MAP[format as AnyFormatType]) {
        case ONER_V1P1_CSV_ROSTERING_EXPORT_BULK:
        case ONER_V1P2_CSV_ROSTERING_EXPORT_BULK:
        case ONER_V1P1_CSV_ROSTERING_IMPORT_BULK:
          return characterizations[id]
        default:
          return restCharacterizations[id]
      }
    },
  )
