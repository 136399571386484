export const ADMIN_ROLE = 'ADMIN'
export const CCX_USER_ROLE = 'CCX_USER'
export const TA_ADMIN_ROLE = 'TA_ADMIN'
export const TA_CONTRIBUTOR_ROLE = 'TA_CONTRIBUTOR'
export const TA_VIEWER_ROLE = 'TA_VIEWER'
export const TA_TEACHER_VIEWER_ROLE = 'TA_TEACHER_VIEWER'

export type UserRole =
  | 'ADMIN'
  | 'CCX_USER'
  | 'TA_ADMIN'
  | 'TA_CONTRIBUTOR'
  | 'TA_VIEWER'
  | 'TA_TEACHER_VIEWER'