import React from 'react'
import { useSelector } from 'react-redux'

import IProduct from 'domains/products/models/IProduct'
import { View } from 'lib/components'
import ProductImageCell from 'domains/products/components/ProductImageCell'
import IProductFormat from 'domains/products/models/IProductFormat'
import ComparisonDetailsWrapper from 'domains/comparisons/components/ComparisonDetailsWrapper'
import { isRosteredByOtherProduct } from 'domains/products/utils/products'
import { getRecord } from 'lib/records/workflows/getRecord'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'
import {
  getRosteringToolId,
  rosteringToolSelector,
} from 'domains/products/selectors/rosteringTool'
import { RootState } from 'lib/store/rootReducer'
import { utils } from '@ims/1edtech-frontend-common'
import CharacterizedByRosteringCell from 'domains/products/components/CharacterizedByRosteringCell'

type CompareProductDetailsProps = {
  product?: IProduct | null
  format?: IProductFormat
}

export default function CompareProductDetails(
  props: CompareProductDetailsProps,
) {
  const { product, format } = props
  const rosteringTool = useSelector((s: RootState) =>
    rosteringToolSelector(['product', 'id'])(s, props),
  )

  React.useEffect(() => {
    if (product && isRosteredByOtherProduct(product)) {
      getRecord(PRODUCTS_RECORD, getRosteringToolId(product))
    }
  }, [product])

  if (!product) {
    return null
  }

  return (
    <ComparisonDetailsWrapper
      image={
        <View
          borderRadius="50%"
          minWidth="75px"
          minHeight="75px"
          overflow="hidden"
          flexible="column-center"
          bg="white"
        >
          <ProductImageCell {...product} size={72} />
        </View>
      }
      title={product.name}
      subtitle={product.organizationName}
      tertiary={
        utils.hasValue(rosteringTool) &&
        !!format && (
          <CharacterizedByRosteringCell
            id={product.id}
            format={format.format}
          />
        )
      }
      format={format}
    />
  )
}
