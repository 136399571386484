import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

export const CSV_COMPARISON_REPORTS_RECORD = 'csvComparisonReports'

const csvComparisonReports: records.modules.IModule = {
  record: CSV_COMPARISON_REPORTS_RECORD,
  noResponseDataKey: true,
  schema: new normalizr.schema.Entity(
    CSV_COMPARISON_REPORTS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(['comparisonId']),
  ),
  api: {
    create: () => 'comparisons/csv/report',
    get: (id) => `comparisons/csv/report/${id}`,
    getList: (_, orgId) => `districts/${orgId}/comparisons`,
    update: (id) => `comparisons/csv/report/${id}`,
    delete: (id) => `comparisons/csv/report/${id}`,
  },
}

export default csvComparisonReports
