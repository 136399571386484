import React, { PureComponent } from 'react'

import { View, ListPlainTextCell } from 'lib/components'
import {
  isRosteredByOtherProduct,
  isProductCharacterized,
  getProductFormatPropByName,
} from 'domains/products/utils/products'
import CharacterizedByRosteringCell from 'domains/products/components/CharacterizedByRosteringCell'
import ListItalicTextOnlyCell from 'domains/products/components/ListItalicTextOnlyCell'
import CharacterizationButton from 'domains/characterizations/components/CharacterizationButton'
import IProduct from 'domains/products/models/IProduct'
import isProductFormatExpired from 'domains/products/utils/isProductFormatExpired'
import { RouteComponentProps } from 'react-router'
import { productHasMultipleSupportedFormats } from 'domains/products/utils/productHasMultipleSupportedFormats'
import { expandProductsByFormats } from 'domains/products/utils/expandProductsByFormats'
import { getFirstSupportedProductFormat } from 'domains/products/utils/getFirstSupportedProductFormat'
import ProductCatalogExpandedRowCell from 'domains/products/components/ProductCatalog/ProductCatalogExpandedRowCell'

interface IProps extends IProduct, RouteComponentProps {
  isSubRow: boolean
  isSingleProduct: boolean
  isOpen?: boolean
}

const initialState = {
  launchCharDialogOpen: false,
}
type State = Readonly<typeof initialState>

export default class ProductCatalogCharacterizationCell extends PureComponent<IProps> {
  readonly state: State = initialState

  onLaunchCharacterizationComfirm = () =>
    this.setState({ launchCharDialogOpen: true })

  getFormat = () =>
    this.props.format || getFirstSupportedProductFormat(this.props)!

  closeLaunchCharDialog = () => this.setState({ launchCharDialogOpen: false })

  isExpired = () => isProductFormatExpired(this.props, this.getFormat())

  renderCharacterizedButton() {
    const format = this.getFormat()
    return (
      <ProductCatalogExpandedRowCell {...this.props}>
        <View flexible="row-center">
          <CharacterizationButton
            date={
              getProductFormatPropByName(
                format,
                'characterizationDate',
                0,
              )(this.props) as number
            }
            product={this.props}
            format={format}
            modelHasAlerts={
              getProductFormatPropByName(
                format,
                'modelHasAlerts',
                false,
              )(this.props) as boolean
            }
            modelHasCriticals={
              getProductFormatPropByName(
                format,
                'modelHasCriticals',
                false,
              )(this.props) as boolean
            }
            modelHasMetadata={
              getProductFormatPropByName(
                format,
                'modelHasMetadata',
                false,
              )(this.props) as boolean
            }
            disabled={this.isExpired() || this.props.removed}
          />
        </View>
      </ProductCatalogExpandedRowCell>
    )
  }

  render() {
    if (isRosteredByOtherProduct(this.props)) {
      const { isOpen } = this.props
      return (
        <ProductCatalogExpandedRowCell {...this.props}>
          <CharacterizedByRosteringCell
            id={this.props.id}
            format={this.getFormat()}
            linkTextProps={{
              color: 'primary',
              fontWeight: isOpen ? 700 : 500,
            }}
          />
        </ProductCatalogExpandedRowCell>
      )
    }

    if (
      !this.props.isSingleProduct &&
      !this.props.isSubRow &&
      productHasMultipleSupportedFormats(this.props)
    ) {
      const supportedFormats = expandProductsByFormats(this.props)
      const modeledCount = supportedFormats.reduce(
        (agg, product) =>
          isProductCharacterized(product, product.format!) ? agg + 1 : agg,
        0,
      )
      return (
        <ProductCatalogExpandedRowCell {...this.props}>
          <ListPlainTextCell>
            {modeledCount}/{supportedFormats.length}
          </ListPlainTextCell>
        </ProductCatalogExpandedRowCell>
      )
    }

    if (isProductCharacterized(this.props, this.getFormat())) {
      return this.renderCharacterizedButton()
    }

    return (
      <ProductCatalogExpandedRowCell {...this.props}>
        <ListItalicTextOnlyCell>
          Unavailable until Modeling Complete
        </ListItalicTextOnlyCell>
      </ProductCatalogExpandedRowCell>
    )
  }
}
