import React from 'react'
import { utils } from '@ims/1edtech-frontend-common'

import ICharacterization, {
  CHARACTERIZATION_PROFILE_TO_DISPLAY,
} from 'domains/characterizations/models/ICharacterization'
import FormatLineItems from 'domains/modeling/components/FormatLineItems'
import { getCharacterizationZipFileName } from 'domains/characterizations/utils/characterization'
import IProduct from 'domains/products/models/IProduct'
import ICSVModelCharacterization from 'domains/characterizations/models/ICSVModelCharacterization'
import {
  ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK,
  ONER_V1P1_CSV_ROSTERING_EXPORT_BULK,
  ONER_V1P2_CSV_ROSTERING_EXPORT_BULK,
} from 'domains/formats/constants/formats'
import { IWidgetLineItemProps } from 'domains/reports/components/WidgetLineItem'
import { isCCXCertified } from 'domains/orgs/models/IOrg'
import CCxCertifiedBadge from 'lib/components/CCxCertifiedBadge'

interface IProps {
  characterization: ICharacterization | ICSVModelCharacterization
  addName?: boolean
  product?: IProduct
  hideType?: boolean
}

export default function CharacterizationsLineItemsDetails(props: IProps) {
  const getBeforeFormatLineItems = () => {
    const { addName = false, product, characterization } = props
    if (addName) {
      return [
        {
          label: 'Name',
          value: product
            ? product.name
            : (characterization as ICharacterization).name,
        },
      ]
    }
    if (product) {
      return [{ label: 'Rostering Method', value: 'Direct' }]
    }

    return []
  }

  const getAfterFormatLineItems = () => {
    const { characterization, product } = props
    const baseItems = [
      {
        label: 'Run Date/Time',
        value: utils.date.formatForDisplay(characterization.runDate),
      },
    ]
    if (product) {
      return baseItems
    }
    const districtChar = characterization as ICharacterization

    const profileDisplay = utils.hasValue(districtChar.profile)
      ? CHARACTERIZATION_PROFILE_TO_DISPLAY[districtChar.profile!]
      : 'Unknown'
    const sourceName = `${districtChar.source}${
      utils.hasValue(districtChar.sourceVersion)
        ? ` - ${districtChar.sourceVersion}`
        : ''
    }`
    const sourceAndProfile = [
      {
        label: 'Source',
        value: (
          <div className="flex flex-row items-center">
            <p className="text-text text-sm">{sourceName}</p>
            {isCCXCertified(districtChar.certifiedSourceLevel) ? (
              <CCxCertifiedBadge className="ml-2" showTooltip />
            ) : null}
          </div>
        ),
      },
      {
        label: 'Profile',
        value: profileDisplay || 'Unknown',
      },
    ].filter(
      (o) => o.label !== 'Profile' || utils.hasValue(districtChar.profile),
    ) as IWidgetLineItemProps[]

    if (
      districtChar.format === ONER_V1P1_CSV_ROSTERING_EXPORT_BULK ||
      districtChar.format === ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK
    ) {
      return [
        ...baseItems,
        {
          label: 'Zip File',
          value: getCharacterizationZipFileName(districtChar.zipFileURL!),
        },
        ...sourceAndProfile,
      ]
    }
    else if (districtChar.format === ONER_V1P2_CSV_ROSTERING_EXPORT_BULK) {
      return [...baseItems, { label: 'Zip File', value: getCharacterizationZipFileName(districtChar.zipFileURL!) }]
    }
    else {
      return [...baseItems, ...sourceAndProfile]
    }
  }

  return (
    <FormatLineItems
      format={props.characterization}
      dataTest="characterization-format-details"
      beforeItems={getBeforeFormatLineItems()}
      afterItems={getAfterFormatLineItems()}
      hideType={props.hideType}
      wordBreak="break-word"
    />
  )
}
