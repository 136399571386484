import IRestCModelServiceEndpointAttribute from 'domains/modeling/models/IRestCModelServiceEndpointAttribute'
import IRestCModelServiceEndpointParam from 'domains/modeling/models/IRestCModelServiceEndpointParam'

export type RestHttpVerbs = 'GET' | 'POST' | 'PUT' | 'DELETE'
export default interface IRestCModelServiceEndpoint {
  id: number
  name: string
  leaf: string
  enabled: boolean
  description: string
  notes: string
  touched: boolean
  params: IRestCModelServiceEndpointParam[]
  attributes: IRestCModelServiceEndpointAttribute[]
}

export const mockRestCModelServiceEndpoint = (
  overrides = {},
): IRestCModelServiceEndpoint => ({
  id: 1,
  name: 'getAllAcademicSessions',
  leaf: '/academicSessions',
  enabled: true,
  notes: '',
  description: '',
  touched: false,
  params: [],
  attributes: [],
  ...overrides,
})
