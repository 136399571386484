import React from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { View, FormInput, ListCallToActionButton } from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import BaseBreadCrumbs from 'domains/application/components/BaseBreadCrumbs'
import { useSelector } from 'react-redux'
import { applicationPropSelector } from 'domains/application/selectors/application'
import { updateEventHeaderConfig } from 'domains/application/workflows/updateConfiguration'
import { showToast } from 'lib/utils/toast'
import {
    EVENT_BUTTON_TEXT,
    EVENT_HEADER_IMAGE_URL,
    EVENT_HEADER_LINK_URL,
} from 'domains/application/models/configuration'

const validationSchema = Yup.object().shape({
    linkUrl: Yup.string().url('Invalid URL').required('Required'),
    imageUrl: Yup.string().url('Invalid URL').required('Required'),
    buttonText: Yup.string().required('Required'),
})

interface IFormValues {
    imageUrl: string
    linkUrl: string
    buttonText: string
}

export default function EventHeaderConfigScreen() {
    const linkUrl: string = useSelector(
        applicationPropSelector(EVENT_HEADER_LINK_URL),
    )

    const imageUrl: string = useSelector(
        applicationPropSelector(EVENT_HEADER_IMAGE_URL),
    )

    const buttonText: string = useSelector(
        applicationPropSelector(EVENT_BUTTON_TEXT),
    )

    const onSave = async (
        values: IFormValues,
        bag: FormikHelpers<IFormValues>,
    ) => {
        const result = await updateEventHeaderConfig(values)
        bag.setSubmitting(false)
        if (!result) {
            showToast('error', 'Failed to update event header configuration')
        } else {
            showToast('start', 'Successfully updated event header configuration')
        }
    }

    return (
        <Formik
            initialValues={{ linkUrl, imageUrl, buttonText }}
            onSubmit={onSave}
            validationSchema={validationSchema}
        >
            {(bag) => (
                <View variant="screen">
                    <DocumentTitle title="Event Header Configuration" />
                    <BaseBreadCrumbs crumbs={[{ name: 'Event Header Configuration' }]} />

                    <div className="flex flex-col">
                        <div className="flex gap-6 flex-grow flex-col md:flex-row">
                            <FormInput
                                label="Link URL"
                                required={true}
                                name="linkUrl"
                                placeholder="https://link-url.com"
                                value={bag.values.linkUrl}
                                valueDataTest="linkUrl-input"
                                handleChange={bag.handleChange}
                                showErrorImmediately={true}
                                className="flex-1"
                            />

                            <FormInput
                                label="Image URL"
                                required={true}
                                name="imageUrl"
                                placeholder="https://image-url.com"
                                value={bag.values.imageUrl}
                                valueDataTest="imageUrl-input"
                                handleChange={bag.handleChange}
                                showErrorImmediately={true}
                                className="flex-1"
                            />
                            <FormInput
                                label="Button Text"
                                required={true}
                                name="buttonText"
                                placeholder="Learn More"
                                value={bag.values.buttonText}
                                valueDataTest="imageUrl-input"
                                handleChange={bag.handleChange}
                                showErrorImmediately={true}
                                className="flex-1"
                            />
                        </div>

                        <ListCallToActionButton
                            text="Save"
                            icon="fas fa-save"
                            onClick={bag.handleSubmit}
                            data-test="save-btn"
                            className="mr-auto"
                        />
                    </div>
                </View>
            )}
        </Formik>
    )
}
