import React, { PureComponent, MouseEvent } from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { Icon, Switch, View, Text } from 'lib/components'
import {
  PUBLISHED_PRODUCT_STATUS,
  PRIVATE_PRODUCT_STATUS,
} from 'domains/products/constants/products'
import { changeProductStatus } from 'domains/products/workflows/changeProductStatus'
import { rosteringToolSelector } from 'domains/products/selectors/rosteringTool'
import Dialog from 'domains/application/modals/Dialog'
import IProduct from 'domains/products/models/IProduct'
import { RootState } from 'lib/store/rootReducer'
import { isProductCharacterized } from 'domains/products/utils/products'
import isProductFormatExpired from 'domains/products/utils/isProductFormatExpired'
import { isProductFormatPublished } from 'domains/products/utils/productFormatStatus'
import {
  AnyFormatType,
  AnySpecFormatType,
} from 'domains/formats/constants/formats'
import { getFirstSupportedProductFormat } from 'domains/products/utils/getFirstSupportedProductFormat'
import { expandProductsByFormats } from 'domains/products/utils/expandProductsByFormats'
import { getRosteredProducts } from 'domains/products/workflows/getRosteredProducts'
import { utils } from '@ims/1edtech-frontend-common'
import PublishRosteringToolModal from 'domains/products/components/PublishRosteringToolModal'
import ProductCatalogExpandedRowCell from 'domains/products/components/ProductCatalog/ProductCatalogExpandedRowCell'

interface IPropsFromState {
  rosteringTool?: IProduct
}
const stateMap = createStructuredSelector<RootState, any, IPropsFromState>({
  rosteringTool: rosteringToolSelector('id'),
})

interface IActions {
  changeProductStatus: typeof changeProductStatus
}
const actionMap = () => ({
  changeProductStatus,
})

interface IProps extends IProduct {
  isSubRow?: boolean
  isOpen?: boolean
  isSingleProduct?: boolean
  outOfDateFontSize?: number
  overrideFormat?: AnyFormatType
  'data-test'?: string
}

interface IState {
  changeStatusDialogOpen: boolean
  pending: boolean
  rosteredProducts: IProduct[]
}

class ProductCatalogStatusCell extends PureComponent<
  IProps & IPropsFromState & IActions
> {
  readonly state: IState = {
    changeStatusDialogOpen: false,
    pending: false,
    rosteredProducts: [],
  }

  onConfirmedStatusChange = async () => {
    const product = this.props as IProduct
    const useFormat = this.getFormat()
    await this.props.changeProductStatus(
      this.props.id,
      isProductFormatPublished(product, useFormat!)
        ? PRIVATE_PRODUCT_STATUS
        : PUBLISHED_PRODUCT_STATUS,
      useFormat!,
    )
    this.onCloseChangeStatusDialog()
  }

  onOpenChangeStatusDialog = async () => {
    const format = this.getFormat() as AnySpecFormatType
    if (isProductFormatPublished(this.props, format)) {
      this.setState({ changeStatusDialogOpen: true, pending: true })
      return
    }

    this.setState({ pending: true })
    const { id } = this.props
    const rosteredProducts = await getRosteredProducts(id, format)
    if (utils.hasValue(rosteredProducts)) {
      this.setState({ rosteredProducts })
    } else {
      this.setState({ changeStatusDialogOpen: true })
    }
  }

  onCloseChangeStatusDialog = () =>
    this.setState({ changeStatusDialogOpen: false, pending: false })

  closePublishRosteringToolModal = () =>
    this.setState({ rosteredProducts: [], pending: false })

  stopPropagation = (e: MouseEvent) => e.stopPropagation()

  getFormat = () => {
    const { overrideFormat, ...rest } = this.props
    const product = rest as IProduct
    return (
      overrideFormat ||
      product.format ||
      getFirstSupportedProductFormat(this.props)
    )
  }

  render() {
    const {
      id,
      name,
      rosteringTool,
      outOfDateFontSize = 13,
      isSingleProduct,
    } = this.props
    const format = this.getFormat() as AnySpecFormatType
    const isFormatPublished = isProductFormatPublished(this.props, format)
    const isExpired = isProductFormatExpired(this.props, format)
    const isProductRemoved = R.pathOr(false, ['removed'], this.props)
    if (!isSingleProduct && !isExpired && !isProductRemoved) {
      const publicCount = expandProductsByFormats(this.props).reduce(
        (agg, product) =>
          isProductFormatPublished(product, product.format as AnySpecFormatType)
            ? agg + 1
            : agg,
        0,
      )
      const isPublic = this.props.isSubRow ? isFormatPublished : publicCount > 0
      return (
        <ProductCatalogExpandedRowCell {...this.props}>
          <Icon
            className={`fas fa-eye${isPublic ? '' : '-slash'}`}
            color={isPublic ? 'primary' : 'textLight'}
            aria-label="Product status is public"
          />
        </ProductCatalogExpandedRowCell>
      )
    }

    if (isProductRemoved) {
      return (
        <ProductCatalogExpandedRowCell {...this.props}>
          <View flexible="row-v-center" py={2}>
            <Icon fontSize={outOfDateFontSize} className="fas fa-ban" />
            <Text fontSize={outOfDateFontSize} ml={1}>
              Removed
            </Text>
          </View>
        </ProductCatalogExpandedRowCell>
      )
    }

    if (isExpired) {
      return (
        <ProductCatalogExpandedRowCell {...this.props}>
          <View flexible="row-v-center" py={2}>
            <Icon
              fontSize={outOfDateFontSize}
              className="fas fa-exclamation-circle"
            />
            <Text fontSize={outOfDateFontSize} ml={1}>
              Out of Date
            </Text>
          </View>
        </ProductCatalogExpandedRowCell>
      )
    }

    if (
      isProductCharacterized(this.props, format) ||
      (rosteringTool && isProductFormatPublished(rosteringTool, format))
    ) {
      return (
        <ProductCatalogExpandedRowCell {...this.props}>
          <View
            flexible="row-center"
            maxWidth={86}
            onClick={this.stopPropagation}
          >
            <Switch
              on={isFormatPublished}
              onIconName="fas fa-eye"
              offIconName="fas fa-eye-slash"
              onChange={this.onOpenChangeStatusDialog}
              dataTest={
                this.props['data-test'] || `status-switch-${id}-${format}`
              }
              aria-label={`Product: ${this.props.name} status toggle`}
              pending={this.state.pending}
              width={70}
            />

            {this.state.changeStatusDialogOpen && (
              <Dialog
                isOpen={this.state.changeStatusDialogOpen}
                icon={!isFormatPublished ? 'fas fa-eye' : 'fas fa-eye-slash'}
                message={
                  isFormatPublished
                    ? `You are about to make ${name} PRIVATE. Any PUBLIC products that use ${name} as a rostering method will be reverted to PRIVATE.`
                    : `You are about to make ${name} PUBLIC.`
                }
                onConfirm={this.onConfirmedStatusChange}
                onDeny={this.onCloseChangeStatusDialog}
                hideContentWhenPending={true}
              />
            )}

            {utils.hasValue(this.state.rosteredProducts) && (
              <PublishRosteringToolModal
                isOpen={true}
                close={this.closePublishRosteringToolModal}
                rosteredProducts={this.state.rosteredProducts}
                format={format}
                product={this.props}
              />
            )}
          </View>
        </ProductCatalogExpandedRowCell>
      )
    }

    return (
      <ProductCatalogExpandedRowCell {...this.props}>
        <Icon
          className="fas fa-eye-slash"
          aria-label="Product status is private"
          data-test={this.props['data-test'] || `status-${id}`}
        />
      </ProductCatalogExpandedRowCell>
    )
  }
}

export default connect(stateMap, actionMap)(ProductCatalogStatusCell)
