import React from 'react'

import SmallWidget from 'domains/dashboard/components/SmallWidget'

interface IProps {
  comparisonCount: number
  fadeDelay: number
}

export default function ComparisonCountWidget(props: IProps) {
  return (
    <SmallWidget
      flexible="column-h-end"
      fadeDelay={props.fadeDelay}
      icon="fas fa-poll"
      iconColor="success"
      title={props.comparisonCount}
      subtitle="Comparisons Made"
      dataTest="comparisons-widget"
    />
  )
}
