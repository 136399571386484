import React from 'react'
import { useSelector } from 'react-redux'
import { utils, records } from '@ims/1edtech-frontend-common'

import { View, List, ListCallToActionButton } from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import BaseBreadCrumbs from 'domains/application/components/BaseBreadCrumbs'
import { SOURCES_RECORD } from 'lib/records/modules/sources'
import { RootState } from 'lib/store/rootReducer'
import ISource from 'domains/configuration/models/ISource'
import SourceIsMemberCell from 'domains/configuration/components/SourceIsMemberCell'
import useModalState from 'lib/hooks/useModalState'
import Dialog from 'domains/application/modals/Dialog'
import TextWithBoldHighlight from 'lib/components/TextWithBoldHighlight'
import { deleteRecord } from 'lib/records/workflows/deleteRecord'
import CreateEditSourceModal from 'domains/configuration/components/CreateEditSourceModal'
import SourceCertifiedCell from 'domains/configuration/components/SourceCertifiedCell'
import GenericDeleteCell from 'domains/configuration/components/GenericDeleteCell'

export default function SourcesScreen(props: any) {
  const sources = useSelector((state: RootState) =>
    records.recordsSelectors.fullRecordsSelector(SOURCES_RECORD)(state, props),
  )

  const [deleteModalOpen, openDeleteModal, closeDeleteModal] = useModalState()
  const [deleteSource, setDeleteSource] = React.useState<null | ISource>(null)
  const onDeleteSource = (source: ISource) => {
    setDeleteSource(source)
    openDeleteModal()
  }
  const onDeleteConfirmed = async () => {
    await deleteRecord(SOURCES_RECORD, deleteSource!.id)
    closeDeleteModal()
  }

  const [editModalOpen, openEditModal, closeEditModal] = useModalState()
  const [editSource, setEditSource] = React.useState<null | ISource>(null)
  const onEditSource = (source: ISource) => {
    setEditSource(source)
    openEditModal()
  }

  const onAddNew = () => {
    setEditSource(null)
    openEditModal()
  }

  const columns = [
    {
      title: 'Name',
      maxWidth: [130, 180, 360, 'auto'],
      accessor: 'sourceName',
      sortKey: utils.sort.addSortValue('sourceName@'),
    },
    {
      title: 'Supplier',
      noHeaderCenter: true,
      maxWidth: [130, 180, 360, 'auto'],
      accessor: 'supplierName',
      sortKey: utils.sort.addSortValue('supplierName@'),
    },
    {
      title: 'Is Member',
      noHeaderCenter: true,
      maxWidth: 72,
      CellComponent: SourceIsMemberCell,
      sortKey: utils.sort.addSortValue('isMember@'),
    },
    {
      title: 'Certified',
      noHeaderCenter: true,
      maxWidth: 72,
      CellComponent: SourceCertifiedCell,
      noSort: true,
    },
    {
      title: null,
      noSort: true,
      forceCenter: true,
      maxWidth: 50,
      CellComponent: GenericDeleteCell,
      cellProps: { onClick: onDeleteSource, nameProp: 'name' },
    },
  ]

  return (
    <View variant="screen">
      <DocumentTitle title="Sources" />
      <BaseBreadCrumbs crumbs={[{ name: 'Sources' }]} />

      <List
        title="Sources"
        record={SOURCES_RECORD}
        recordState={sources}
        columns={columns}
        dataTest="sources-list"
        initialSortColumnIndex={0}
        noSort={true}
        noSearch={true}
        onRowClick={onEditSource}
        callToAction={
          <ListCallToActionButton onClick={onAddNew} text="New Source" />
        }
      />

      <Dialog
        isOpen={deleteModalOpen}
        icon="fas fa-trash"
        message={
          utils.hasValue(deleteSource) ? (
            <TextWithBoldHighlight
              text="You are about to delete @@. Are you sure?"
              highlight={deleteSource!.sourceName}
            />
          ) : (
            ''
          )
        }
        confirmText="Yes, Delete"
        onConfirm={onDeleteConfirmed}
        onDeny={closeDeleteModal}
      />

      <CreateEditSourceModal
        editSource={editSource}
        isOpen={editModalOpen}
        close={closeEditModal}
      />
    </View>
  )
}
