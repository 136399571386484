import React from 'react'
import * as R from 'ramda'

import { View, Text, Icon, Linkify, ToolTip } from 'lib/components'
import IRESTComparisonReport from 'domains/characterizations/models/IRESTComparisonReport'
import IRESTServiceComparisonReport from 'domains/characterizations/models/IRESTServiceComparisonReport'
import IRESTServiceEndpointComparisonReport from 'domains/characterizations/models/IRESTServiceEndpointComparisonReport'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import colors from 'lib/styles/colors'
import {
  STATUS_TO_BADGE_VARIANT_MAP,
  UNSUPPORTED_REPORT_STATUS,
  GRAY_REPORT_STATUS,
  RED_REPORT_STATUS,
} from 'domains/reports/models/IReportStatuses'
import { utils } from '@ims/1edtech-frontend-common'
import ComparisonReportLegend from 'domains/comparisons/components/ComparisonReport/ComparisonReportLegend'
import { ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL } from 'domains/formats/constants/formats'
import RESTServiceEndpointComparisonParamList from 'domains/comparisons/components/ComparisonReport/RESTServiceEndpointComparisonParamList'
import { findSpecServiceEndointByName } from 'domains/modeling/utils/restCOR1p1utils'
import { OR_1P1_REST_ROSTERING_CONSUMER_SERVICES } from 'domains/modeling/constants/restCOneRoster1p1'
import IRESTServiceEndpointAttributeComparisonReport from 'domains/characterizations/models/IRESTServiceEndpointAttributeComparisonReport'
import RESTServiceEndpointAttributeComparisonReport from 'domains/comparisons/components/ComparisonReport/RESTServiceEndpointAttributeComparisonReport'
import memoize from 'fast-memoize'
import IRestCSpecServiceEndpoint from 'domains/specifications/models/IRestCSpecServiceEndpoint'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'
import RESTCharacterizationParamError from 'domains/characterizations/components/CharacterizationReport/RESTCharacterizationParamError'
import CharacterizationErrorDot from 'domains/characterizations/components/CharacterizationErrorDot'

const isPrint = isOnPrintScreen()

const getAttributesSortedAccordingToSpec = memoize(
  (
    specEndpoint: IRestCSpecServiceEndpoint,
    attributes: IRESTServiceEndpointAttributeComparisonReport[],
  ) => {
    if (!utils.hasValue(specEndpoint) || !utils.hasValue(attributes)) {
      return []
    }

    const sortedAttributes: IRESTServiceEndpointAttributeComparisonReport[] = []
    specEndpoint.attributes.forEach((specAttribute) => {
      const attribute = R.find(
        R.propEq('attributeName', specAttribute.name),
        attributes,
      ) as IRESTServiceEndpointAttributeComparisonReport
      if (attribute) {
        sortedAttributes.push(attribute)
      }
    })

    // Add metadata not found in the spec
    attributes.forEach((attribute) => {
      const found = R.find(
        R.propEq('attributeName', attribute.attributeName),
        sortedAttributes,
      ) as IRESTServiceEndpointAttributeComparisonReport
      if (!found) {
        sortedAttributes.push(attribute)
      }
    })

    return sortedAttributes
  },
)

interface IProps {
  isOpen: boolean
  report: IRESTComparisonReport
  service: IRESTServiceComparisonReport
  endpoint: IRESTServiceEndpointComparisonReport
  specification: IRestCSpec
}

export default function RESTServiceEndpointComparisonReport(props: IProps) {
  const specEndpoint = findSpecServiceEndointByName(
    props.specification,
    props.service.serviceName as OR_1P1_REST_ROSTERING_CONSUMER_SERVICES,
    props.endpoint.endpointName,
  )
  if (!specEndpoint) {
    throw Error(
      `Failed to find endpoint in specification. Service: ${props.service.serviceName}. Endpoint: ${props.endpoint.endpointName}`,
    )
  }

  const attributes = getAttributesSortedAccordingToSpec(
    specEndpoint,
    props.endpoint.attributeComparisons,
  )

  const onlyConsumerDisabled =
    !props.endpoint.enabled && props.endpoint.enabledInCharacterisation

  const onlyProviderDisabled =
    props.endpoint.enabled && !props.endpoint.enabledInCharacterisation

  const consumerAndProviderDisabled =
    !props.endpoint.enabled && !props.endpoint.enabledInCharacterisation

  return (
    <View
      mb={2}
      p={3}
      bg="white"
      border={
        props.endpoint
          ? `4px solid ${
              (colors as any)[
                STATUS_TO_BADGE_VARIANT_MAP[
                  props.endpoint.enabled
                    ? props.endpoint.status
                    : UNSUPPORTED_REPORT_STATUS
                ]
              ]
            }`
          : ''
      }
      flex={1}
    >
      {(onlyConsumerDisabled ||
        onlyProviderDisabled ||
        consumerAndProviderDisabled) && (
        <View mb={3}>
          <RESTCharacterizationParamError
            status={
              onlyProviderDisabled ? RED_REPORT_STATUS : GRAY_REPORT_STATUS
            }
            text={props.endpoint.errorMessage || ''}
          />
        </View>
      )}
      <View flexible="column" overflowX={isPrint ? undefined : 'auto'}>
        <View
          display="grid"
          gridTemplateColumns={[
            'auto',
            onlyConsumerDisabled || consumerAndProviderDisabled
              ? 'auto'
              : '1fr 1fr',
          ]}
          gridColumnGap="3"
        >
          <View>
            {utils.hasValue(props.endpoint.notes) && (
              <View
                data-test={`${props.service.serviceName}-${props.endpoint.endpointName}-notes`}
              >
                <Text fontSize={18} fontWeight={700} mb={2}>
                  <Icon className="fas fa-info-circle" color="info" mr={1} />
                  Endpoint Notes:
                </Text>
                <View flexible="row-v-center">
                  <View mr={1}>
                    <ToolTip
                      id={`${props.service.serviceName}-${props.endpoint.endpointName}-notes`}
                      tip="This endpoint contains consumer notes"
                      place="right"
                    >
                      <CharacterizationErrorDot color="secondary" size={18} />
                    </ToolTip>
                  </View>
                  <Text wordBreak="break-word" whiteSpace="pre-line">
                    <Linkify>{props.endpoint.notes || ''}</Linkify>
                  </Text>
                </View>
              </View>
            )}
          </View>

          {!onlyConsumerDisabled && !consumerAndProviderDisabled && (
            <ComparisonReportLegend
              format={ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL}
            />
          )}
        </View>

        {!onlyConsumerDisabled && !consumerAndProviderDisabled && (
          <View>
            <RESTServiceEndpointComparisonParamList endpoint={props.endpoint} />

            <View mt={3}>
              {attributes.map((attribute, index) => (
                <RESTServiceEndpointAttributeComparisonReport
                  index={index}
                  report={props.report}
                  attribute={attribute}
                  endpoint={props.endpoint}
                  service={props.service}
                  specification={props.specification}
                  key={`${props.endpoint.endpointName}-${attribute.attributeName}-attribute-comparison`}
                />
              ))}
            </View>
          </View>
        )}
      </View>
    </View>
  )
}
