import React from 'react'
import clsx from 'clsx'

import CharacterizationErrorDot from 'domains/characterizations/components/CharacterizationErrorDot'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'

const isPrint = isOnPrintScreen()

interface IProps {
  message: string
  'data-test': string
}

export default function CriticalErrorMessage(props: IProps) {
  return (
    <div
      data-test={props['data-test']}
      className={clsx('flex flex-row items-center mt-1', {
        'px-4 py-3 rounded bg-errorLight': !isPrint,
      })}
    >
      <CharacterizationErrorDot color="error" size={isPrint ? 14 : undefined} />
      <p
        className={clsx('ml-3 text-text', {
          'text-sm': !isPrint,
          'text-xs': isPrint,
        })}
      >
        {props.message}
      </p>
    </div>
  )
}
