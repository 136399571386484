import React from 'react'

import { utils } from '@ims/1edtech-frontend-common'
import { ICommonCartridgeSourceValue } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeSourceValue'
import CCCharacterizationReportLOMKeyValue from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMKeyValue'
import CCCharacterizationReportLOMDetail from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMDetail'

interface IProps {
  path: string
  title?: string
  data?: ICommonCartridgeSourceValue
}

export default function CCCharacterizationReportLOMSourceValue(props: IProps) {
  if (!utils.hasValue(props.data)) {
    return null
  }

  const sourceValue = props.data!

  const inner = (
    <CCCharacterizationReportLOMKeyValue
      path={props.path}
      keyName="source"
      keyValue={sourceValue.source}
      valueName="value"
      value={sourceValue.value}
    />
  )

  if (utils.hasValue(props.title)) {
    return (
      <CCCharacterizationReportLOMDetail
        title={props.title!}
        value={inner}
        path={props.path}
      />
    )
  }

  return inner
}
