import React from 'react'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

import './NotificationCenter.css'
import { View, Text, Icon } from 'lib/components'
import NonTableList from 'lib/components/NonTableList'
import { NOTIFICATIONS_RECORD } from 'lib/records/modules/notifications'
import NotifcationRow from 'domains/notifications/components/NotifcationRow'
import { useSelector } from 'react-redux'
import { utils } from '@ims/1edtech-frontend-common'
import useModalState from 'lib/hooks/useModalState'
import Dialog from 'domains/application/modals/Dialog'
import { markNotificationAsRead } from 'domains/notifications/workflows/markNotificationAsRead'
import PushNotificationsToggle from 'domains/notifications/components/PushNotificationsToggle'
import { meSelector } from 'domains/authentication/selectors/me'
import IUser from 'domains/users/models/IUser'
import { deleteNotification } from 'domains/notifications/workflows/deleteNotification'

const headerHeight = 84

export let showNotificationCenter: () => any = () => null
export let hideNotificationCenter: () => any = () => null

const NotificationCenter = React.forwardRef((props: any, ref) => {
  const drawer = React.useRef<HTMLDivElement>(null)

  const me = useSelector(meSelector) as IUser

  const notifications = useSelector((state: any) =>
    records.recordsSelectors.recordSelector(NOTIFICATIONS_RECORD)(state),
  )

  const [open, setOpen] = React.useState(false)

  showNotificationCenter = () => setOpen(true)
  hideNotificationCenter = () => setOpen(false)

  const [markAllOpen, openMarkAll, closeMarkAll] = useModalState()
  const [deleteAllOpen, openDeleteAll, closeDeleteAll] = useModalState()

  const close = () => {
    setOpen(false)
  }

  const handleClick = React.useCallback(
    (e) => {
      if (drawer.current && open && !markAllOpen && !deleteAllOpen) {
        const isMenuItem = R.compose(
          R.any((path) => {
            const className = R.pathOr('', ['className'], path)
            return (
              `${className}`.includes('tether-element') ||
              `${className}`.includes('ReactModal')
            )
          }),
          R.propOr([], 'path'),
        )(e)
        if (drawer.current.contains(e.target) || isMenuItem) {
          return
        }
        setOpen(false)
      }
    },
    [deleteAllOpen, markAllOpen, open],
  )

  React.useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClick, false)

      return () => {
        document.removeEventListener('mousedown', handleClick, false)
      }
    }
  }, [open, markAllOpen, deleteAllOpen, handleClick])

  React.useImperativeHandle(
    ref,
    () => ({
      show: () => {
        if (!open) {
          setOpen(true)
        }
      },
      hide: () => {
        if (open) {
          setOpen(false)
        }
      },
      isOpen: open,
    }),
    [open],
  )

  const onMarkAllRead = async () => {
    await markNotificationAsRead()
    closeMarkAll()
  }

  const onDeleteAll = async () => {
    await deleteNotification()
    closeDeleteAll()
  }

  return (
    <View
      ref={drawer}
      id="notification-center"
      className={open ? 'open' : 'closed'}
      position="fixed"
      top={0}
      right={0}
      height="100%"
      bg="rgba(255,255,255,0.95)"
      flexible="column"
    >
      <View
        id="notification-center-content"
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
      >
        <View
          flexible="row-space-between"
          height={headerHeight}
          bg="white"
          borderBottom="solid 1px"
          borderColor="background"
          px={3}
          position="sticky"
          top={0}
        >
          <View flexible="row-v-center">
            <Icon
              className="material-icons rotate-chevron down"
              onClick={close}
              cursor="pointer"
              mr={3}
            >
              menu_open
            </Icon>

            <Text variant="title">Notifications</Text>
          </View>

          <View flexible="column-space-between">
            {utils.hasValue(notifications.items) && (
              <View flexible="row-v-center">
                <Text color="primary" cursor="pointer" onClick={openMarkAll}>
                  Mark All as Read
                </Text>
                <Text mx={2}>•</Text>
                <Text color="primary" cursor="pointer" onClick={openDeleteAll}>
                  Delete All
                </Text>
              </View>
            )}
            <View
              flexible="row-h-end"
              width="100%"
              mt={utils.hasValue(notifications.items) ? 2 : 0}
            >
              <PushNotificationsToggle
                userId={me.id}
                small={true}
                data-test="quick-notification-toggle"
              />
            </View>
          </View>
        </View>

        <View height={`calc(100% - ${headerHeight + 1}px)`} overflowX="hidden">
          <NonTableList
            record={NOTIFICATIONS_RECORD}
            RowComponent={NotifcationRow}
            blankSlateText="No Notifications"
            noHorizontalScroll={true}
            params={{ id: me.id, filter: 'archived=false' }}
            reInitialize={open}
          />
        </View>
      </View>

      <Dialog
        isOpen={markAllOpen}
        title="Mark all notifications as read?"
        icon="fas fa-check-double"
        onConfirm={onMarkAllRead}
        onDeny={closeMarkAll}
      />

      <Dialog
        isOpen={deleteAllOpen}
        title="Delete all notifications?"
        icon="fas fa-trash"
        message="All notifications will be deleted. This cannot be undone."
        onConfirm={onDeleteAll}
        onDeny={closeDeleteAll}
      />
    </View>
  )
})

export default NotificationCenter
