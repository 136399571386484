import React from 'react'

import { Text, Button } from 'lib/components'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'

interface IProps {
  isSubmitting: boolean
  handleSubmit: () => void
  height: number
}

export default function CreateCharacterizationGBPushDetails(props: IProps) {
  return (
    <DetailsTopWidget
      title="Gradebook Push"
      dataTest="gradebook-push-widget"
      height={props.height}
    >
      <div className="w-full h-full flex flex-col justify-between">
        <div
          className="flex flex-col flex-1 mt-4 text-text text-sm space-y-4"
          data-test="gb-push-details"
        >
          <p>
            Clicking on the &quot;Start Session&quot; button below will initiate
            your Gradebook Push characterization.
          </p>
          <p>
            You will be brought to a screen displaying the credentials and
            URL(s) you will enter into your system in order to send gradebook
            data to be characterized by Compatibility Check.
          </p>
          <p>
            When you are done sending data, you will click on the &quot;Stop
            Session&quot; button to complete the characterization and generate
            your report.
          </p>
        </div>

        <Button
          type="submit"
          onClick={props.handleSubmit}
          variant="start"
          width="100%"
          mb={2}
          py={3}
          data-test="submit-characterization-btn"
          pending={props.isSubmitting}
        >
          <Text color="white" fontSize={16}>
            Start Session
          </Text>
        </Button>
      </div>
    </DetailsTopWidget>
  )
}
