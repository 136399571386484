import React from 'react'
import * as R from 'ramda'
import { Formik } from 'formik'

import IProduct from 'domains/products/models/IProduct'
import {
  AnySpecFormatType,
  FORMAT_TO_DISPLAY_NAME,
} from 'domains/formats/constants/formats'
import { ModalWrapper, View, Icon, Text, Checkbox, Span } from 'lib/components'
import { changeProductStatus } from 'domains/products/workflows/changeProductStatus'
import { PUBLISHED_PRODUCT_STATUS } from 'domains/products/constants/products'
import { bactchPublishRosteredProducts } from 'domains/products/workflows/bactchPublishRosteredProducts'

interface IProps {
  isOpen: boolean
  close: () => any
  rosteredProducts: IProduct[]
  format: AnySpecFormatType
  product: IProduct
}

interface ICheckableProduct extends IProduct {
  checked: boolean
}

export default function PublishRosteringToolModal(props: IProps) {
  const onSave = async (values: ICheckableProduct[]) => {
    await changeProductStatus(
      props.product.id,
      PUBLISHED_PRODUCT_STATUS,
      props.format,
    )
    const products = values.filter((p) => p.checked)
    await bactchPublishRosteredProducts(props.format, products)
    props.close()
  }

  return (
    <Formik
      initialValues={props.rosteredProducts.map((p) => ({
        ...p,
        checked: true,
      }))}
      onSubmit={onSave}
      enableReinitialize={true}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
      }) => {
        const allChecked = R.all(R.propEq('checked', true))(values)
        const someChecked = R.any(R.propEq('checked', true))(values)
        const onChangeAll = () => {
          for (let i = 0; i < values.length; i++) {
            setFieldValue(`[${i}].checked`, allChecked ? false : true)
          }
        }

        return (
          <ModalWrapper
            isOpen={props.isOpen}
            title={'Are you sure?'}
            bodyProps={{ p: 0, px: [2, 4, 4], pb: [2, 4, 4] }}
            actions={[
              {
                text: 'Yes',
                variant: 'start',
                onClick: handleSubmit,
              },
              {
                text: 'Cancel',
                variant: 'neutral',
                onClick: props.close,
              },
            ]}
            pending={isSubmitting}
          >
            <View>
              <View
                position="sticky"
                top={0}
                left={0}
                right={0}
                bg="white"
                zIndex={1}
                pt={3}
                px={3}
              >
                <View flexible="column-h-center">
                  <Icon
                    className="fas fa-eye"
                    fontSize={54}
                    data-test="modal-icon"
                    data-icon="fas fa-eye"
                    mb={2}
                  />
                </View>

                <Text>
                  <Span fontWeight={700}>Product:</Span> {props.product.name}
                </Text>
                <Text mb={3}>
                  <Span fontWeight={700}>Format:</Span>{' '}
                  {FORMAT_TO_DISPLAY_NAME[props.format]}
                </Text>

                <View flexible="column-h-center">
                  <Text>
                    You are about to make{' '}
                    <Span fontWeight={700}>{props.product.name}</Span> PUBLIC.
                    The following products are rostered via{' '}
                    <Span fontWeight={700}>{props.product.name}</Span>. These
                    products will also be made PUBLIC for this format. Please
                    uncheck any that should remain PRIVATE.
                  </Text>
                </View>

                <View mt={3}>
                  <Checkbox
                    name="select/deselect all"
                    checked={allChecked}
                    isIndeterminate={!allChecked && someChecked}
                    onChange={onChangeAll}
                    data-test="checkbox-all"
                  >
                    <Text userSelect="none" color="complete">
                      {allChecked ? 'Deselect' : 'Select'} All
                    </Text>
                  </Checkbox>
                </View>
              </View>

              <View px={3} ml={3}>
                {values.map((product, index) => (
                  <Checkbox
                    name={`[${index}].checked`}
                    key={product.name}
                    checked={product.checked}
                    onChange={handleChange}
                    data-test={`checkbox-${product.id}`}
                  >
                    <Text userSelect="none">{product.name}</Text>
                  </Checkbox>
                ))}
              </View>
            </View>
          </ModalWrapper>
        )
      }}
    </Formik>
  )
}
