import React from 'react'

import { Text, View } from 'lib/components'

interface IProps {
  parentName?: string
  name?: string
  isConsumer: boolean
  size?: number
}

export default function PrintMetadataFlag(props: IProps) {
  const { parentName = '', name = '', size = 16 } = props
  return (
    <View
      flexible="column-center"
      bg={props.isConsumer ? 'primary' : 'info'}
      width={size}
      height={size}
      data-test={`${parentName}-${name}-flag`}
    >
      <Text color="white" fontWeight={700}>
        {props.isConsumer ? 'C' : 'P'}
      </Text>
    </View>
  )
}
