import React, { PureComponent } from 'react'
import * as R from 'ramda'

import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import { View } from 'lib/components'
import ITransformedCharacterizationCSVReport from 'domains/characterizations/models/ITransformedCharacterizationCSVReport'
import ReportTopLevelError from 'domains/reports/components/ReportTopLevelError'
import { RED_REPORT_STATUS } from 'domains/reports/models/IReportStatuses'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'

interface IProps {
  report: ITransformedCharacterizationCSVReport
}
export default class CharacterizationReportFileSetErrors extends PureComponent<IProps> {
  render() {
    const { report } = this.props
    if (!report) {
      return null
    }

    if (report.hasFileSetErrors) {
      const errors = (
        <View mt={3}>
          <ReportTopLevelError
            status={RED_REPORT_STATUS}
            title={`The following error(s) with your fileset are preventing characterization:`}
            errors={report.fileSetErrors.map(R.prop('description'))}
            dataTest="fileset-error"
          />
        </View>
      )

      if (!isOnPrintScreen()) {
        return (
          <View>
            <WidgetHeader
              title="Fileset Errors"
              dataTest="characterization-report-fileset"
            />
            {errors}
          </View>
        )
      }

      return errors
    }

    return null
  }
}
