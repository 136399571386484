import React from 'react'
import DatePickerComponent from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import './DatePicker.css'
import Input from 'lib/components/Input'

const CustomDateInput = React.forwardRef((props: any, ref: any) => (
  <Input
    value={props.value}
    onChange={props.onChange}
    {...props}
    width="100%"
    ref={ref}
    placeholder="MM/DD/YYYY"
  />
))
CustomDateInput.displayName = 'CustomDateInput'

interface IProps {
  date: Date
  handleChange: (date: Date) => any
  isClearable?: boolean
  minDate?: Date
  maxDate?: Date
  openToDate?: Date
  'data-test'?: string
}

export default function DatePicker(props: IProps) {
  return (
    <DatePickerComponent
      selected={props.date}
      onChange={props.handleChange}
      customInput={<CustomDateInput data-test={props['data-test']} />}
      isClearable={props.isClearable}
      clearButtonTitle={props.isClearable ? 'Remove' : undefined}
      minDate={props.minDate}
      showMonthDropdown={true}
      showYearDropdown={true}
      dropdownMode="select"
      openToDate={props.openToDate}
    />
  )
}
