import { useEffect, useState } from 'react'
import { utils, records } from '@ims/1edtech-frontend-common'

import { useSelector } from 'react-redux'
import { getNavigationParam } from 'lib/utils/navigation'
import { RootState } from 'lib/store/rootReducer'
import { restCharacterizationPropSelector } from 'domains/characterizations/selectors/characterizations'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import IRESTCharacterizationReport from 'domains/characterizations/models/IRESTCharacterizationReport'
import { getFullRESTPCharacterization } from 'domains/characterizations/workflows/getFullRESTPCharacterization'
import { OR_REST_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/orRESTCharacterizations'
import { REST_CHARACTERIZATION_REPORTS_RECORD } from 'lib/records/modules/restCharacterizationReports'
import ITransformedRESTCharacterizationReport from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import { transformRESTPCharacterizationReport } from 'domains/characterizations/utils/transformRESTPCharacterizationReport'
import { SPECIFICATIONS_RECORD } from 'lib/records/modules/specifications'
import {
  AnyFormatType,
  ANY_FORMAT_TO_SPEC_FORMAT_MAP,
  FORMAT_TO_INVERSE_MAP,
} from 'domains/formats/constants/formats'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'

export default function useFullRESTPCharacterization(
  props: any,
  characterizationIdParam = 'id',
  formatParam = 'format',
): [
  ICharacterization,
  IRestCSpec,
  IRESTCharacterizationReport,
  ITransformedRESTCharacterizationReport,
  boolean,
] {
  const [pending, setPending] = useState(true)
  const characterizationId = utils.convertToInt(
    getNavigationParam(props, characterizationIdParam),
  )

  useEffect(() => {
    const fetch = async () => {
      await getFullRESTPCharacterization(characterizationId)
      setPending(false)
    }
    fetch()
  }, [characterizationId])

  const format = getNavigationParam(props, formatParam) as AnyFormatType
  const specFormat =
    FORMAT_TO_INVERSE_MAP[ANY_FORMAT_TO_SPEC_FORMAT_MAP[format]]
  const specification = useSelector((state: RootState) =>
    records.entitiesSelectors.entityByIdSelector(
      SPECIFICATIONS_RECORD,
      'specFormat',
    )(state, {
      specFormat,
    }),
  ) as IRestCSpec

  const characterization = useSelector((state: RootState) =>
    records.entitiesSelectors.entityByIdSelector(
      OR_REST_CHARACTERIZATIONS_RECORD,
      characterizationId,
    )(state, props),
  ) as ICharacterization

  const characterizationReport = useSelector(
    records.entitiesSelectors.entityByIdSelector(
      REST_CHARACTERIZATION_REPORTS_RECORD,
      restCharacterizationPropSelector('content', characterizationId),
    ),
  ) as IRESTCharacterizationReport

  let transformedReport: any = null
  if (characterizationReport) {
    transformedReport = transformRESTPCharacterizationReport(
      characterizationReport,
    )
    // transformedReport = transformRESTPCharacterizationReport(
    //   // @ts-ignore
    //   report as IRESTCharacterizationReport,
    // )
  }

  return [
    characterization,
    specification,
    characterizationReport,
    transformedReport,
    pending,
  ]
}
