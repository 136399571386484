import * as R from 'ramda'
import { utils, records } from '@ims/1edtech-frontend-common'

import store from 'lib/store'
import { IApiResponse } from 'lib/api/models'

const getPagingValue = (headers: Record<string, unknown>, field: string) =>
  utils.convertToInt(
    R.propOr<number, Record<string, unknown>, number>(0, field, headers),
  )

interface IHeaderResponse {
  headers?: Record<string, unknown>
}

export const getPaging = (
  response: IHeaderResponse,
  page?: number | null,
  pageSize?: number,
  params?: Record<string, unknown>,
): records.recordReducers.IRecordPaging => {
  const p = page || 1
  const ps = pageSize || 25
  const headers = response.headers || {}
  const total = getPagingValue(headers, 'x-total-count')
  const totalPages = total > 0 ? Math.ceil(total / ps) : 0

  return {
    nextPage: p >= totalPages ? false : p + 1,
    page: p,
    pageSize: ps,
    total,
    totalPages,
    params: params || {},
  }
}

export const clearPaging = (
  record: string,
  parentRecordId?: string | number,
) => {
  const clearPagingState = {
    paging: {
      nextPage: undefined,
      page: undefined,
      pageSize: undefined,
      total: undefined,
      totalPages: undefined,
      params: undefined,
    },
  }
  if (parentRecordId) {
    store.dispatch(
      records.relatedRecordActions.setRelatedRecordProperties(
        record,
        clearPagingState,
        parentRecordId,
      ),
    )
  } else {
    store.dispatch(
      records.recordActions.setRecordProperties(record, clearPagingState),
    )
  }
}

export const setPaging = (
  record: string,
  setProperties: any,
  response: IApiResponse,
  parentRecordId?: string | number,
  page?: number | null,
  pageSize?: number,
  params?: Record<string, unknown>,
) => {
  const paging = getPaging(response, page, pageSize, params)
  store.dispatch(setProperties(record, { paging }, parentRecordId))
}
