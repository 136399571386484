import React from 'react'
import ITransformedCharacterizationCSVReport from 'domains/characterizations/models/ITransformedCharacterizationCSVReport'
import { View, Icon, Text } from 'lib/components'

interface IProps {
  report: ITransformedCharacterizationCSVReport
}

export default function OR1P1CSVCharacterizationReportUnavailable(
  props: IProps,
) {
  if (!props.report.unavailable) {
    return null
  }
  return (
    <View width="100%" flexible="column-center">
      <Icon className="fas fa-file" fontSize={48} mb={3} />
      <Text variant="subtitle" fontWeight={700}>
        Report Unavailable
      </Text>
    </View>
  )
}
