import React from 'react'

import Text, { ITextProps } from 'lib/components/Text'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'

const isPrint = isOnPrintScreen()

export default function ListPlainTextCell(props: ITextProps) {
  return (
    <Text
      userSelect="none"
      fontSize={isPrint ? 11 : 13}
      lineHeight={isPrint ? '14px' : '16px'}
      overflow="auto"
      wordBreak="break-word"
      // overflow={isPrint ? 'auto' : 'ellipsis'}
      // wordBreak={isPrint ? 'break-all' : 'normal'}
      {...props}
    />
  )
}
