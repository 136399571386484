import React from 'react'

import { Select } from 'lib/components'
import ListFilterSection from 'lib/components/ListFilter/ListFilterSection'
import {
  IListFilter,
  IFilterState,
  getSelectCurrentValue,
  IListFilterOption,
} from 'lib/components/ListFilter/listFilterUtils'

interface IProps {
  filter: IListFilter
  renderDivider: boolean
  appliedFilters: IFilterState
  dataTest: string
  onChange: (event: any) => any
}

export default function ListSelectFilter(props: IProps) {
  const { filter, renderDivider, appliedFilters, dataTest, onChange } = props
  return (
    <ListFilterSection title={filter.title} divider={renderDivider}>
      <Select
        name={filter.title}
        value={getSelectCurrentValue(filter.title, appliedFilters)}
        onChange={onChange}
        variant="bold"
        width="100%"
        data-test={`${dataTest}-select-${filter.title}`}
      >
        {filter.options.map((option: IListFilterOption) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </ListFilterSection>
  )
}
