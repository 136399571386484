import React from 'react'
import * as R from 'ramda'
import clsx from 'clsx'

import './Switch.css'
import View, { IViewProps } from 'lib/components/View'
import Icon from 'lib/components/Icon'
import { Spinner, Text } from 'lib/components'

const propTypes = {
  on: true,
  onChange: true,
  onIconName: true,
  offIconName: true,
  onText: true,
  offText: true,
  variant: true,
  dataTest: true,
  pending: true,
  small: true,
  locked: true,
}
interface IProps extends IViewProps {
  on: boolean
  onChange: () => void
  onIconName?: string
  offIconName?: string
  onText?: string
  offText?: string
  variant?: string
  dataTest?: string
  'aria-label'?: string
  pending?: boolean
  small?: boolean
  locked?: boolean
}
export default function Switch(props: IProps) {
  // static defaultProps = {
  //   variant: 'primary',
  // }

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent,
  ) => {
    event.preventDefault()
    event.stopPropagation()
    if (!props.locked) props.onChange()

    return false
  }

  const onClassName = props.on ? 'on' : 'off'

  let icon: any = null
  if (props.onIconName && props.offIconName) {
    icon = (
      <Icon
        className={clsx('switch-icon', onClassName, {
          'small text-xs': props.small,
          'text-base': !props.small,
          [props.onIconName]: props.on,
          [props.offIconName]: !props.on,
        })}
      />
    )
  }

  const text: any =
    props.onText || props.offText ? (
      <Text className={clsx('switch-text', onClassName)}>
        {props.on ? props.onText || 'On' : props.offText || 'Off'}
      </Text>
    ) : null

  let locked: any = props.locked ? (
    <Icon
      className={clsx('switch-lock fas fa-lock', onClassName, {
        small: props.small,
      })}
    />
  ) : null

  const viewProps = R.omit(R.keys(propTypes), props)
  const smallClass = props.small ? 'small' : ''
  const lockedClass = props.locked ? 'locked' : ''
  const content = props.pending ? (
    <Spinner size={20} />
  ) : (
    <View
      flexible="column-center"
      ml={props.small ? 0 : 1}
      className={clsx(
        'switch-container',
        props.variant || 'primary',
        smallClass,
        lockedClass,
      )}
      data-test={props.dataTest}
      data-on={props.on}
      data-locked={props.locked}
      cursor={props.locked ? 'not-allowed' : 'pointer'}
    >
      <input
        type="checkbox"
        name="switch"
        className={clsx('switch', smallClass)}
        checked={props.on}
        onChange={onChange}
        aria-label={props['aria-label']}
      />
      <label
        htmlFor="switch"
        className={clsx('switch-label', smallClass, lockedClass)}
        data-off="off"
        data-on="on"
      />
      {icon}
      {text}
      {locked}
    </View>
  )

  return (
    <View {...viewProps} ml={1} onClick={onChange}>
      {content}
    </View>
  )
}
