import React from 'react'
import * as R from 'ramda'
import {
  ResponsiveContainer,
  LineChart as RechartLineChart,
  Line,
  Legend,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts'

import colors from 'lib/styles/colors'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import CustomizedAxisTick from 'domains/dashboard/components/AdminAnalytics/CustomizedAxisTick'
import LargeWidget from 'domains/dashboard/components/LargeWidget'
import useLineChart from 'domains/dashboard/components/AdminAnalytics/UseLineChartData'
import { Spinner } from 'lib/components'

interface IProps {
  title: string
  chart: string
}
export default function LineChart(props: IProps) {
  const { chartData, pending } = useLineChart(props.chart)

  return (
    <LargeWidget fadeDelay={100}>
      <WidgetHeader title={props.title} />
      {pending && <Spinner centered={true} />}
      {!pending && (
        <ResponsiveContainer width="100%" height={380}>
          <RechartLineChart margin={{ top: 24, left: 8, right: 8 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              type="category"
              allowDuplicatedCategory={false}
              height={80}
              tick={CustomizedAxisTick}
            />
            <YAxis dataKey="value" />
            <Tooltip />
            <Legend />
            {chartData.map((s, index: number) => {
              const stroke =
                colors.chartColors[index % colors.chartColors.length]
              return (
                <Line
                  dataKey="value"
                  type="monotone"
                  data={R.propOr([], 'data', s)}
                  name={R.propOr('', 'name', s)}
                  key={R.propOr('', 'name', s)}
                  stroke={stroke}
                  strokeWidth={2}
                />
              )
            })}
          </RechartLineChart>
        </ResponsiveContainer>
      )}
    </LargeWidget>
  )
}
