import React from 'react'

import { ListPlainTextCell } from 'lib/components'
import { ITransformedRESTServiceEndpointAttributeCharacterizationReport } from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import IRestCModel from 'domains/modeling/models/IRestCModel'
import {
  findModelServiceEndointAttributeByName,
  findSpecServiceEndointAttributeByName,
} from 'domains/modeling/utils/restCOR1p1utils'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import { OR_1P1_REST_ROSTERING_CONSUMER_SERVICES } from 'domains/modeling/constants/restCOneRoster1p1'

interface IProps
  extends ITransformedRESTServiceEndpointAttributeCharacterizationReport {
  serviceName: string
  endpointName: string
  specification: IRestCSpec
  model?: IRestCModel
}

export default function RESTCharacterizationAttributeMultiplicityCell(
  props: IProps,
) {
  const {
    endpointName,
    serviceName,
    attributeName,
    model,
    multiplicity,
    specification,
  } = props
  let display = multiplicity
  if (!display && model) {
    const modelAttribute = findModelServiceEndointAttributeByName(
      model,
      serviceName as OR_1P1_REST_ROSTERING_CONSUMER_SERVICES,
      endpointName,
      attributeName,
    )
    display = modelAttribute ? modelAttribute.multiplicity : null
  }
  if (!display) {
    const specAttribute = findSpecServiceEndointAttributeByName(
      specification,
      serviceName as OR_1P1_REST_ROSTERING_CONSUMER_SERVICES,
      endpointName,
      attributeName,
    )
    display = specAttribute ? specAttribute.multiplicity : ''
  }
  return <ListPlainTextCell>{display}</ListPlainTextCell>
}
