import React from 'react'
import * as R from 'ramda'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { utils, records } from '@ims/1edtech-frontend-common'

import { Button, Spinner, View } from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import BaseORRosteringCharacterizationsBreadCrumbs from 'domains/characterizations/components/BaseORRosteringCharacterizationsBreadCrumbs'
import IGBPushSession from 'domains/characterizations/models/IGBPushSession'
import { getRecord } from 'lib/records/workflows/getRecord'
import { REST_PUSH_CHARACTERIZATION_REPORTS_RECORD } from 'lib/records/modules/restPushCharacterizationReports'
import { deleteRecord } from 'lib/records/workflows/deleteRecord'
import { OR_REST_PUSH_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/orRESTPushCharacterizations'
import { ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL } from 'domains/formats/constants/formats'
import { getCharacterizationRoute } from 'domains/characterizations/navigation/routes'
import CopyableUrls from 'lib/components/CopyableUrls'
import Dialog from 'domains/application/modals/Dialog'
import useModalState from 'lib/hooks/useModalState'
import { OR_REST_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/orRESTCharacterizations'
import { RootState } from 'lib/store/rootReducer'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'

export default function GradebookPushCharacterizationSessionScreen(props: any) {
  const { id } = useParams<{ id: any }>()
  const characterizationId = utils.convertToInt(id)
  const [confirmDoneOpen, openConfirmDone, closeConfirmDone] = useModalState()
  const { replace, push } = useHistory()
  const [pending, setPending] = React.useState(false)
  const [session, setSession] = React.useState<null | IGBPushSession>(null)
  const characterization = useSelector((state: RootState) =>
    records.entitiesSelectors.entityByIdSelector(
      OR_REST_CHARACTERIZATIONS_RECORD,
      characterizationId,
    )(state, props),
  ) as ICharacterization
  const sessionId = R.pathOr('', ['content'], characterization)

  const characterizationName = R.pathOr('', ['name'], characterization)

  React.useEffect(() => {
    getRecord(OR_REST_CHARACTERIZATIONS_RECORD, characterizationId)
  }, [characterizationId])

  React.useEffect(() => {
    const fetch = async () => {
      if (
        utils.hasValue(sessionId) &&
        !R.pathEq(['validationId'], sessionId, session)
      ) {
        setPending(true)
        const {
          success,
          data,
        } = await getRecord(
          REST_PUSH_CHARACTERIZATION_REPORTS_RECORD,
          sessionId,
          { isSession: true },
        )
        if (success) {
          setSession(data as IGBPushSession)
        } else {
          // No session - go to report
          replace(
            getCharacterizationRoute(
              characterizationId!,
              ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL,
            ).replace('/session', ''),
          )
        }
        setPending(false)
      }
    }
    fetch()
  }, [sessionId, characterizationId, replace, session])

  const onStopSession = async () => {
    await deleteRecord(OR_REST_PUSH_CHARACTERIZATIONS_RECORD, sessionId!)
    push(
      getCharacterizationRoute(
        characterizationId!,
        ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL,
      ).replace('/session', ''),
    )
  }

  if (pending || !session) {
    return (
      <View variant="screen">
        <DocumentTitle
          title={`Gradebook Push Session - ${characterizationName}`}
        />
        <BaseORRosteringCharacterizationsBreadCrumbs
          crumbs={[{ name: characterizationName }]}
        />
        <Spinner centered />
      </View>
    )
  }

  return (
    <View variant="screen">
      <DocumentTitle
        title={`Gradebook Push Session - ${characterizationName}`}
      />
      <BaseORRosteringCharacterizationsBreadCrumbs
        crumbs={[{ name: characterizationName }]}
      />

      <View variant="paper" width="fit-content">
        <div className="w-full h-full flex flex-col justify-between">
          <div className="flex flex-col flex-1 space-y-2 lg:space-y-4">
            <WidgetHeader title="Gradebook Push Session Details" />

            <div
              className="flex flex-col md:flex-row items-center py-6"
              data-test="session-details"
            >
              <Spinner />
              <div className="md:ml-4 flex flex-col items-center md:items-start">
                <p className="text-md text-text text-center md:text-left font-semibold">
                  Your session is active.
                </p>
                <p className="text-md text-text text-center md:text-left font-semibold">
                  Use the details below to send requests in order to fully
                  characterize your system.
                </p>
              </div>
            </div>

            <CopyableUrls
              label="Client ID"
              value={session.clientId}
              allowCopy
            />
            <CopyableUrls
              label="Client Secret"
              value={session.clientSecret}
              allowCopy
            />
            <CopyableUrls
              label="Base URL"
              value={`https://${utils.host.getHostname()}/1edtech_characterisation_gradebook_endpoint`}
              allowCopy
            />
            <CopyableUrls
              label="OAuth2 Token URL"
              value={`https://${utils.host.getHostname()}/1edtech_characterisation_gradebook_endpoint/ims/auth/token`}
              allowCopy
              optional
            />
          </div>

          <div className="w-full flex items-center justify-center">
            <div className="w-72">
              <Button
                type="button"
                onClick={openConfirmDone}
                variant="error"
                width="100%"
                mt={4}
                py={3}
                data-test="stop-session-btn"
              >
                <p className="text-white text-base font-bold">
                  Stop Session and generate report
                </p>
              </Button>
            </div>
          </div>
        </div>
      </View>

      <Dialog
        isOpen={confirmDoneOpen}
        icon="fas fa-stop-circle"
        title="Done with your Gradebook Push Characterization Session?"
        message="This will end your session and generate your characterization report."
        confirmText="OK"
        onConfirm={onStopSession}
        onDeny={closeConfirmDone}
      />
    </View>
  )
}
