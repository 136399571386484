import React, { PureComponent, ComponentType } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { utils, records } from '@ims/1edtech-frontend-common'

import { Spinner } from 'lib/components'
import { getDisplayName } from 'lib/utils/hoc'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'
import { CSV_C_MODELS_RECORD } from 'lib/records/modules/csvCModels'
import { getFullProduct } from 'domains/products/workflows/getFullProduct'
import { rosteringToolSelector } from 'domains/products/selectors/rosteringTool'
import { CSV_C_MODEL_CHARACTERIZATION_REPORTS_RECORD } from 'lib/records/modules/csvCModelCharacterizationReports'
import { transformCharacterizationCSVModelReport } from 'domains/characterizations/utils/transformCharacterizationCSVModelReport'
import { CSV_C_MODEL_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/csvCModelCharacterizations'
import { getModelFullSpecSelector } from 'domains/modeling/selectors/modelSpecification'
import IProduct from 'domains/products/models/IProduct'
import ICSVConsumerModel from 'domains/modeling/models/ICSVConsumerModel'
import ICSVModelCharacterization from 'domains/characterizations/models/ICSVModelCharacterization'
import ICSVSpecification from 'domains/specifications/models/ICSVSpecification'
import { RootState } from 'lib/store/rootReducer'
import ITransformedCharacterizationCSVReport from 'domains/characterizations/models/ITransformedCharacterizationCSVReport'
import withNavigationProps from 'lib/utils/withNavigationProps'
import ICSVModelCharacterizationReport from 'domains/characterizations/models/ICSVModelCharacterizationReport'
import {
  AnyNavFormatType,
  ANY_FORMAT_TO_SPEC_FORMAT_MAP,
} from 'domains/formats/constants/formats'
import {
  productFormatModelIdSelector,
  productFormatCharacterizationLinkSelector,
} from 'domains/modeling/selectors/productModelSelectors'
import GenericError from 'domains/application/components/GenericError'

interface IPropsFromState {
  product: IProduct
  csvConsumerModel?: ICSVConsumerModel
  csvConsumerCharacterization?: ICSVModelCharacterization
  rosteringTool?: IProduct
  csvConsumerModelReport?: ICSVModelCharacterizationReport
  specification: ICSVSpecification
}
const stateMap = createStructuredSelector<RootState, any, IPropsFromState>({
  product: records.entitiesSelectors.entityByIdSelector(PRODUCTS_RECORD, 'id'),
  csvConsumerModel: records.entitiesSelectors.entityByIdSelector(
    CSV_C_MODELS_RECORD,
    productFormatModelIdSelector('id', 'format'),
  ),
  csvConsumerCharacterization: records.entitiesSelectors.entityByIdSelector(
    CSV_C_MODEL_CHARACTERIZATIONS_RECORD,
    'id',
  ),
  rosteringTool: rosteringToolSelector('id'),
  csvConsumerModelReport: records.entitiesSelectors.entityByIdSelector(
    CSV_C_MODEL_CHARACTERIZATION_REPORTS_RECORD,
    productFormatCharacterizationLinkSelector('id', 'format'),
  ),
  specification: getModelFullSpecSelector(CSV_C_MODELS_RECORD, 'modelId'),
})

interface IProps extends IPropsFromState {
  id: string
  modelId?: string | number
  format?: AnyNavFormatType
}
export interface IWithFullProductProps extends IProps {
  characterizationReport: ITransformedCharacterizationCSVReport
}

const initialState = {
  loading: true,
}
type IState = Readonly<typeof initialState>

export default function withFullProduct(WrappedComponent: ComponentType<any>) {
  class WithFullProduct extends PureComponent<IProps> {
    private unmounted = false

    public static displayName = getDisplayName(
      'WithFullProduct',
      WrappedComponent,
    )

    state: IState = initialState

    async componentDidMount() {
      await getFullProduct(
        this.props.id,
        ANY_FORMAT_TO_SPEC_FORMAT_MAP[this.props.format!],
      )
      this.setLoadingFalse()
    }

    componentWillUnmount() {
      this.unmounted = true
    }

    setLoadingFalse = () => {
      if (!this.unmounted) {
        this.setState({ loading: false })
      }
    }

    renderLoading() {
      if (this.state.loading) {
        return <Spinner centered={true} />
      }

      return null
    }

    renderContent() {
      if (this.state.loading) {
        return null
      }

      if (
        !utils.hasValue(this.props.product) ||
        (utils.hasValue(this.props.modelId) &&
          !utils.hasValue(this.props.specification))
      ) {
        return <GenericError />
      }

      return (
        <WrappedComponent
          {...this.props}
          characterizationReport={transformCharacterizationCSVModelReport(
            this.props.csvConsumerModelReport,
          )}
        />
      )
    }

    render() {
      return (
        <>
          {this.renderLoading()}
          {this.renderContent()}
        </>
      )
    }
  }

  return withNavigationProps(connect(stateMap)(WithFullProduct))
}
