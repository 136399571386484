import React, { PureComponent } from 'react'

import { resetProduct } from 'domains/products/workflows/resetProduct'
import Dialog from 'domains/application/modals/Dialog'
import IProduct from 'domains/products/models/IProduct'
import { AnyFormatType } from 'domains/formats/constants/formats'
import { isProductCharacterized } from 'domains/products/utils/products'
import { isProductFormatPublished } from 'domains/products/utils/productFormatStatus'

interface IProps {
  isOpen: boolean
  product: IProduct
  format: AnyFormatType
  onReset: () => void
  onCancel: () => void
}

export default class ResetProductDialog extends PureComponent<IProps> {
  onConfirm = async () => {
    const { product, format } = this.props
    await resetProduct(product.id, format)
    this.props.onReset()
  }

  renderMessage() {
    const { name } = this.props.product
    const makePrivateMessage = isProductFormatPublished(
      this.props.product,
      this.props.format,
    )
      ? ` This will make ${name} PRIVATE. Any PUBLIC products that use ${name} as a rostering method will be reverted to PRIVATE.`
      : ''
    return `You are about to reset modeling${
      isProductCharacterized(this.props.product, this.props.format)
        ? ' and characterization'
        : ''
    } for ${name}.${makePrivateMessage}`
  }

  render() {
    if (!this.props.isOpen) {
      return null
    }

    return (
      <Dialog
        isOpen={this.props.isOpen}
        icon="fas fa-trash"
        message={this.renderMessage()}
        onConfirm={this.onConfirm}
        onDeny={this.props.onCancel}
      />
    )
  }
}
