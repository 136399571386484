import React from 'react'
import clsx from 'clsx'
import * as R from 'ramda'
import { useSelector } from 'react-redux'

import { records } from '@ims/1edtech-frontend-common'
import { ORG_ACTIONS_RECORD } from 'lib/records/modules/orgActions'
import IOrgAction from 'domains/orgs/models/IOrgAction'
import IOrg from 'domains/orgs/models/IOrg'
import { ExpansionPanel } from 'lib/components'
import useModalState from 'lib/hooks/useModalState'
import OrgActionOverrideForm from 'domains/orgs/components/OrgActions/OrgActionOverrideForm'
import { isActionAllowed } from 'lib/components/OrgActions'

type Props = {
  id: string
  org: IOrg
}

export default function OrgActionRow(props: Props) {
  const [isOpen, , , toggle] = useModalState()
  const action = useSelector((state: any) =>
    records.entitiesSelectors.entityByIdSelector(ORG_ACTIONS_RECORD, 'id')(
      state,
      props,
    ),
  ) as IOrgAction

  const orgActions = R.pathOr([], ['org', 'actions'], props)
  const orgOverrides = R.pathOr([], ['org', 'overrides'], props)
  const orgHasAction = isActionAllowed(orgActions, orgOverrides, action.name)
  const existingOverride = R.find(R.propEq('name', action.name), orgOverrides)
  const forceAllowed = R.pathOr(false, ['forceAllow'], existingOverride)
  const forceDenied = R.pathOr(false, ['forceDeny'], existingOverride)

  return (
    <div
      className={clsx('w-full', {
        border: isOpen,
      })}
    >
      <ExpansionPanel
        isOpen={isOpen}
        onRequestChange={toggle}
        summary={
          <div
            className={clsx(
              'flex flex-row items-center pl-4 pr-2 py-2 space-x-3',
              { 'bg-gray-100': isOpen },
            )}
          >
            <div className="flex flex-1 flex-col">
              <h3 className="text-lg font-bold text-text">{action.name}</h3>
              <p className="text-sm text-text">{action.description}</p>
              <p className="text-xs text-textLight">
                Applies to org type:{' '}
                <span className="font-bold">
                  {action.appliesTo === 'Org' ? 'Any' : action.appliesTo}
                </span>
              </p>
              <p className="text-xs text-textLight">
                Overriden:{' '}
                <span className="font-bold">
                  {forceAllowed
                    ? 'Force Allowed'
                    : forceDenied
                    ? 'Force Denied'
                    : 'NO'}
                </span>
              </p>
            </div>

            <div className="flex flex-col">
              <i
                className={clsx('text-2xl fas', {
                  'text-success-20': orgHasAction,
                  'text-error-30': !orgHasAction,
                  'fa-check': orgHasAction,
                  'fa-x': !orgHasAction,
                })}
              />
            </div>

            <i
              className={clsx(
                'text-4xl text-text fas rotate-caret fa-angle-down',
                {
                  down: isOpen,
                },
              )}
            />
          </div>
        }
        details={<OrgActionOverrideForm org={props.org} action={action} />}
      />
    </div>
  )
}
