import React from 'react'

import { View, Spinner, Paper } from 'lib/components'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'

interface IProps {
  title: string
  rightContent?: any
  dataTest?: string
  height?: number
  children: any
  pending?: boolean
  noScroll?: boolean
}

export default function DetailsTopWidget(props: IProps) {
  const {
    title,
    rightContent = null,
    children,
    pending,
    height,
    dataTest,
  } = props
  return (
    <Paper
      variant="paper"
      mb={3}
      height={height || 460}
      boxSizing="border-box"
      data-test={dataTest}
    >
      <View
        flexible="column"
        id="details-top-widget-content"
        height="100%"
        width="100%"
      >
        <View flexible="row-space-between">
          <WidgetHeader title={title} />
          {rightContent}
        </View>
        <View
          pt={3}
          overflowY={props.noScroll ? 'hidden' : 'auto'}
          flex={1}
          height="100%"
        >
          {pending && <Spinner centered={true} />}
          {!pending && children}
        </View>
      </View>
    </Paper>
  )
}
