import * as R from 'ramda'

import ITransformedCharacterizationCSVReport from 'domains/characterizations/models/ITransformedCharacterizationCSVReport'
import ITransformedCharacterizationCSVFile from 'domains/characterizations/models/ITransformedCharacterizationCSVFile'
import * as statuses from 'domains/reports/models/IReportStatuses'
import ITransformedRESTCharacterizationReport from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'

export const getCharacterizationReportFileStatus = (
  fileName: string,
  report?: ITransformedCharacterizationCSVReport,
): statuses.ReportStatus => {
  const file = R.find<ITransformedCharacterizationCSVFile>(
    R.propEq('fileLabel', fileName),
    R.propOr([], 'files', report),
  )
  if (!file) {
    return statuses.UNKOWN_REPORT_STATUS
  }

  if (file.hasFileErrors) {
    return statuses.RED_REPORT_STATUS
  }

  if (file.hasColumnErrors) {
    return statuses.AMBER_REPORT_STATUS
  }

  return statuses.GREEN_REPORT_STATUS
}

export const getCharacterizationReportStatus = (
  report:
    | ITransformedCharacterizationCSVReport
    | ITransformedRESTCharacterizationReport,
) => {
  if (
    report.unavailable ||
    R.pathOr(false, ['hasFileSetErrors'], report) ||
    R.pathOr(false, ['hasFileErrors'], report)
  ) {
    return statuses.RED_REPORT_STATUS
  }

  const filesWithColumnErrors = R.filter(R.propEq('hasColumnErrors', true))(
    R.pathOr([], ['files'], report),
  )

  if (filesWithColumnErrors.length > 0) {
    return statuses.AMBER_REPORT_STATUS
  }
  return statuses.GREEN_REPORT_STATUS
}

export const getReportFileByLabel = (
  fileLabel: string,
  report: ITransformedCharacterizationCSVReport,
): ITransformedCharacterizationCSVFile | undefined => {
  return R.find(R.propEq('fileLabel', fileLabel), report.files)
}
