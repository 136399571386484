import * as R from 'ramda'
import { createSelector } from 'reselect'
import { records } from '@ims/1edtech-frontend-common'

import ICharacterization from 'domains/characterizations/models/ICharacterization'
import { SPECIFIC_TO_FULL_FORMAT_MAP } from 'domains/formats/constants/formats'
import ICSVSpecification from 'domains/specifications/models/ICSVSpecification'
import { SPECIFICATIONS_RECORD } from 'lib/records/modules/specifications'

export const getCharacterizationFullSpecNameSelector = (
  record: string,
  getId = 'id',
) =>
  createSelector<any, any, ICharacterization, string>(
    records.entitiesSelectors.entityByIdSelector(record, getId),
    (characterization: ICharacterization) =>
      SPECIFIC_TO_FULL_FORMAT_MAP[
        R.propOr<string, ICharacterization, string>(
          '',
          'format',
          characterization,
        )
      ],
  )

export const getCharacterizationFullSpecSelector = (
  record: string,
  getId = 'id',
) =>
  createSelector<any, any, string, any, ICSVSpecification>(
    getCharacterizationFullSpecNameSelector(record, getId),
    records.entitiesSelectors.entityListSelector(SPECIFICATIONS_RECORD),
    (specName: string, specifications: any) => specifications[specName],
  )
