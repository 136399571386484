import React from 'react'
import * as R from 'ramda'
import { useSelector } from 'react-redux'
import { utils, records } from '@ims/1edtech-frontend-common'

import { Screen, View, Spinner } from 'lib/components'
import BaseCommonCartridgeCharacterizationsBreadCrumbs from 'domains/characterizations/components/BaseCommonCartridgeCharacterizationsBreadCrumbs'
import useFullCommonCartridgeCharacterization from 'domains/commonCartridge/hooks/useFullCommonCartridgeCharacterization'
import GenericError from 'domains/application/components/GenericError'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import ICommonCartridgeCharacterizationReport from 'domains/commonCartridge/models/ICommonCartridgeCharacterizationReport'
import { getCharacterizationRoute } from 'domains/characterizations/navigation/routes'
import { getNavigationParam } from 'lib/utils/navigation'
import { getRecord } from 'lib/records/workflows/getRecord'
import { COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_RESOURCES_RECORD } from 'lib/records/modules/commonCartridgeCharacterizationReportResources'
import CCCharacterizationReportLOMDropdowns from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/CCCharacterizationReportLOMDropdowns'
import { ICommonCartridgeResource } from 'domains/commonCartridge/models/ICommonCartridgeResource'
import DebugReportView from 'domains/reports/components/DebugReportView'
import CommonCartridgeCharacterizationReportStandardsList from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CommonCartridgeCharacterizationReportStandardsList'
import CCCharacterizationReportLegend from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportLegend'
import { CCReportTypeIcon } from 'domains/commonCartridge/constants/commonCartridge'
import CCCharacterizationReportDetailsHeader from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportDetailsHeader'
import CCCharacterizationReportNavigationHeader from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportNavigationHeader'
import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'
import CCCharacterizationReportLTIResource from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportLTIResource'

export default function CommonCartridgeCharacterizationReportResourceDetailsScreen(
  props: any,
) {
  const [showMissingMD, setShowMissingMD] = React.useState(false)
  const [characterizationData, reportData, pending] =
    useFullCommonCartridgeCharacterization(props)
  const [fetchPending, setFetchPending] = React.useState(true)

  const resourceId = utils.convertToInt(
    getNavigationParam(props, 'resourceId', 0),
  )

  React.useEffect(() => {
    const fetch = async () => {
      await getRecord(
        COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_RESOURCES_RECORD,
        resourceId,
      )
      await utils.wait.waitFor(250)
      setFetchPending(false)
    }

    fetch()
  }, [resourceId])

  const resource = useSelector(
    records.entitiesSelectors.entityByIdSelector(
      COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_RESOURCES_RECORD,
      resourceId,
    ),
  ) as ICommonCartridgeResource

  if (
    pending ||
    fetchPending ||
    !utils.hasValue(characterizationData) ||
    !utils.hasValue(reportData)
  ) {
    if (
      !pending &&
      !fetchPending &&
      (!utils.hasValue(characterizationData) ||
        !utils.hasValue(reportData) ||
        !utils.hasValue(resource))
    ) {
      return <GenericError />
    }
    return (
      <View variant="screen">
        <Spinner centered={true} />
      </View>
    )
  }

  const characterization = characterizationData as ICharacterization
  const characterizationName = R.pathOr('', ['name'], characterization)
  const report = reportData as ICommonCartridgeCharacterizationReport

  const lom = R.pathOr({}, ['lom'], resource)
  const identifier = R.pathOr(
    '(identifier not specified)',
    ['identifier'],
    resource,
  )
  const disclosures = R.pathOr([], ['disclosures'], resource) as string[]

  return (
    <ShowMissingMetadataContext.Provider
      value={{
        showMissingMetadata: showMissingMD,
        setShowMissingMetadata: setShowMissingMD,
        disclosures,
      }}
    >
      <Screen>
        <BaseCommonCartridgeCharacterizationsBreadCrumbs
          crumbs={[
            {
              name: utils.string.ellipsize(characterizationName, 30),
              route: getCharacterizationRoute(
                characterization.id,
                characterization.format,
              ),
            },
            {
              name: 'Resources',
              route: getCharacterizationRoute(
                characterization.id,
                characterization.format,
                ['resources'],
              ),
            },
            { name: utils.string.ellipsize(identifier, 30) },
          ]}
        />

        <View variant="paper">
          <CCCharacterizationReportLegend />

          <CCCharacterizationReportNavigationHeader
            title={identifier}
            characterization={characterization}
            tocUrl="resources"
            hideLevels={true}
          />

          <CCCharacterizationReportDetailsHeader
            icon={CCReportTypeIcon.Resource}
            titleBg="ccReportResourceTitleBG"
            title="Resource Details"
            bg="ccReportResourceBG"
            details={[
              { data: resource, label: 'type', path: ['type'] },
              { data: resource, label: 'intendedUse', path: ['intendedUse'] },
              // ...ltiCustoms.reduce((agg, custom, index) => {
              //   return [
              //     ...agg,
              //     {
              //       data: resource,
              //       isTitle: true,
              //       label: `custom [${index + 1}]`,
              //       path: [],
              //     },
              //     {
              //       data: resource,
              //       indentLevel: 1,
              //       label: 'name',
              //       path: ['lti', 'custom', index, 'name'],
              //     },
              //     {
              //       data: resource,
              //       indentLevel: 1,
              //       label: 'value',
              //       path: ['lti', 'custom', index, 'value'],
              //     },
              //   ]
              // }, [] as any[]),
              // ...ltiExtensions.reduce((agg, extension, index) => {
              //   return [
              //     ...agg,
              //     {
              //       data: resource,
              //       isTitle: true,
              //       label: `extensions [${index + 1}]`,
              //       path: [],
              //     },
              //     {
              //       data: resource,
              //       indentLevel: 1,
              //       label: 'platform',
              //       path: ['lti', 'extensions', index, 'platform'],
              //     },
              //     ...extension.properties.reduce(
              //       (propertiesAgg, property, propertyIndex) => {
              //         return [
              //           ...propertiesAgg,
              //           {
              //             data: resource,
              //             indentLevel: 1,
              //             isTitle: true,
              //             label: `property [${propertyIndex + 1}]`,
              //             path: [],
              //           },
              //           {
              //             data: resource,
              //             indentLevel: 2,
              //             label: 'name',
              //             path: [
              //               'lti',
              //               'extensions',
              //               index,
              //               'properties',
              //               propertyIndex,
              //               'name',
              //             ],
              //           },
              //           {
              //             data: resource,
              //             indentLevel: 2,
              //             label: 'value',
              //             path: [
              //               'lti',
              //               'extensions',
              //               index,
              //               'properties',
              //               propertyIndex,
              //               'value',
              //             ],
              //           },
              //         ]
              //       },
              //       [] as any[],
              //     ),
              //   ]
              // }, [] as any[]),
            ]}
          >
            <CCCharacterizationReportLTIResource resource={resource} />
          </CCCharacterizationReportDetailsHeader>

          <CCCharacterizationReportLOMDropdowns
            type="Resource"
            parentId={resourceId}
            lom={lom}
            noBlankslate={true}
          />

          <View mt={2} />
          <CommonCartridgeCharacterizationReportStandardsList
            report={report}
            characterization={characterization}
            resourceId={resourceId}
            noBlankslate={true}
          />
        </View>

        <DebugReportView rawReport={resource || {}} title="Resource JSON" />
      </Screen>
    </ShowMissingMetadataContext.Provider>
  )
}
