import { utils } from '@ims/1edtech-frontend-common'
import { TOKENS_STORAGE_KEY } from 'lib/api/constants'

export const goToTeacherDashboard = (newTab = false) => {
  const tokens = utils.localStorage.getLocalItem(TOKENS_STORAGE_KEY)
  const url = `/trustedapps?accessToken=${tokens.accessToken}&refreshToken=${tokens.refreshToken}`

  if (newTab) {
    window.open(url)
  } else {
    window.location.href = url
  }
}
