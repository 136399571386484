import React from 'react'

type CopyModelSearchRowProps = {
  productName: string
}

export default function CopyModelSearchRow(props: CopyModelSearchRowProps) {
  return (
    <div
      className="p-4 bg-white hover:bg-gray-200 cursor-pointer"
      style={{ minWidth: '300px' }}
      data-test={`copy-row-${props.productName}`}
    >
      <p className="text-text text-lg">{props.productName}</p>
    </div>
  )
}
