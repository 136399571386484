import React from 'react'

import { ModalWrapper } from 'lib/components'
import ModalTitle from 'lib/components/ModalTitle'
import IOrg from 'domains/orgs/models/IOrg'
import OrganizationPPList from 'domains/orgs/components/OrganizationPPList'

interface IProps {
  isOpen: boolean
  closeModal: () => any
  org: IOrg
}

export default function OrganizationPPListModal(props: IProps) {
  if (!props.isOpen) {
    return null
  }

  return (
    <ModalWrapper
      isOpen={props.isOpen}
      title={
        <ModalTitle
          title={`@@ uses these Preferred Partners`}
          highlight={props.org.name}
          wordBreak="break-word"
        />
      }
      actions={[
        {
          text: 'OK',
          variant: 'start',
          onClick: props.closeModal,
          extra: { type: 'button' },
        },
      ]}
    >
      <OrganizationPPList org={props.org} />
    </ModalWrapper>
  )
}
