import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { records } from '@ims/1edtech-frontend-common'

import { View } from 'lib/components'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import { RootState } from 'lib/store/rootReducer'
import IProduct from 'domains/products/models/IProduct'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import { RouteComponentProps } from 'react-router'
import SupplierProductList from 'domains/products/components/SupplierProductList'
import { productsListSelector } from 'domains/products/selectors/productsListSelector'
import BaseProductsBreadCrumbs from 'domains/products/components/BaseProductsBreadCrumbs'

interface IPropsFromState {
  orgId: number
  products: records.recordReducers.IRecordState
  search: string | null
}

const stateMap = createStructuredSelector<RootState, any, IPropsFromState>({
  orgId: myOrganizationIdSelector,
  products: productsListSelector,
  search: records.relatedRecordsSelectors.relatedRecordCustomPropertySelector(
    PRODUCTS_RECORD,
    myOrganizationIdSelector,
    'search',
  ),
})

interface IState {
  resetWarningOpen: boolean
  resetProduct: IProduct | boolean
}

export class SupplierProductCatalogScreen extends PureComponent<
  IPropsFromState & RouteComponentProps
> {
  readonly state: IState = {
    resetWarningOpen: false,
    resetProduct: false,
  }

  render() {
    const { orgId, products } = this.props
    return (
      <View variant="screen">
        <DocumentTitle title="Product Catalog" />
        <BaseProductsBreadCrumbs />
        <SupplierProductList products={products} orgId={orgId} />
      </View>
    )
  }
}

export default connect(stateMap)(SupplierProductCatalogScreen)
