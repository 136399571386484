export type CharacterizationReportYesNoUndefined = 'Yes' | 'No' | 'Undefined'

export type CharacterizationReportRequstType =
  | 'Filter'
  | 'Fields'
  | 'Limit'
  | 'Offset'
  | 'OrderBy'
  | 'Sort'

export type CharacterizationReportPopulation =
  | 'Consistent'
  | 'Inconsistent'
  | 'None'

export type CharacterizationReportMultiplicity =
  | '1'
  | '0'
  | 'Finite'
  | 'Infinite'

export type CharacterizationReportDataType =
  | 'Enumeration'
  | 'Datetime'
  | 'String'
  | 'Date'
  | 'GUID'
  | 'Guid Reference'
  | 'Year'
  | 'LIST_OF_STRINGS'
  | 'Boolean'
  | 'Float'
  | 'Integer'
  | 'User'
  | 'User Identifier'
  | 'List of Guid References'

export const FORMAT_TO_DISPLAY: { [key: string]: string } = {
  Enumeration: 'Enumeration',
  Datetime: 'Datetime',
  String: 'String',
  Date: 'Date',
  GUID: 'GUID',
  'Guid Reference': 'GUID Reference',
  Year: 'Year',
  LIST_OF_STRINGS: 'LIST_OF_STRINGS',
  Boolean: 'Boolean',
  Float: 'Float',
  Integer: 'Integer',
  'User Identifier': 'User Identifier',
  'List of Guid References': 'List of GUID References',
}
