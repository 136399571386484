import React from 'react'
import { useHistory } from 'react-router-dom'

import { View, Button } from 'lib/components'
import ICommonCartridgeCharacterizationReport from 'domains/commonCartridge/models/ICommonCartridgeCharacterizationReport'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import { getCharacterizationRoute } from 'domains/characterizations/navigation/routes'
import CommonCartridgeCharacterizationReportTOCList from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CommonCartridgeCharacterizationReportTOCList'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'

interface IProps {
  characterization: ICharacterization
  report: ICommonCartridgeCharacterizationReport
}

export default function CCCharcterizationReportToCPreview(props: IProps) {
  const history = useHistory()

  const onSeeAll = () => {
    const route = getCharacterizationRoute(
      props.characterization.id,
      props.characterization.format,
      ['items'],
    )
    history.push(route)
  }

  return (
    <View variant="paper" flexible="column" mt={2}>
      <View flexible="row-space-between">
        <WidgetHeader title="Table of Contents" dataTest="cc-toc" />
        <Button
          variant="ccReportBlue500"
          onClick={onSeeAll}
          data-test="cc-toc-see-all"
        >
          See All
        </Button>
      </View>

      <View mt={3}>
        <CommonCartridgeCharacterizationReportTOCList
          isPreview={true}
          report={props.report}
          characterization={props.characterization}
        />
      </View>
    </View>
  )
}
