import React, { PureComponent } from 'react'

import { View } from 'lib/components'
import ProductDetailsWidget from 'domains/products/components/ProductDetail/ProductDetailsWidget'
import ProductComparisonsList from 'domains/products/components/ProductDetail/ProductComparisonsList'
import BaseProductsBreadCrumbs from 'domains/products/components/BaseProductsBreadCrumbs'
import withFullProduct, {
  IWithFullProductProps,
} from 'domains/products/components/withFullProduct'
import trackProductAnalytics from 'domains/products/utils/trackProductAnalytics'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import SupplierProductList from 'domains/products/components/SupplierProductList'
import { expandProductsByFormats } from 'domains/products/utils/expandProductsByFormats'

class SupplierProductDetailScreen extends PureComponent<IWithFullProductProps> {
  componentDidMount() {
    trackProductAnalytics('viewed_product_details')
  }

  render() {
    const { product, id } = this.props
    return (
      <View variant="screen">
        <DocumentTitle title={this.props.product.name} />
        <BaseProductsBreadCrumbs crumbs={[{ name: product.name }]} />
        <ProductDetailsWidget product={product} />
        <View flexible="column">
          <SupplierProductList
            nonRecordProducts={expandProductsByFormats(product)}
          />

          <View mt={3} />
          <ProductComparisonsList id={id} />
        </View>
      </View>
    )
  }
}

export default withFullProduct(SupplierProductDetailScreen)
