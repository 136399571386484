import React from 'react'

import { View, ToolTip } from 'lib/components'
import useModalState from 'lib/hooks/useModalState'
import PreferredPartnerBadge from 'domains/products/components/PreferredPartnerBadge'
import { utils } from '@ims/1edtech-frontend-common'
import IOrg from 'domains/orgs/models/IOrg'
import OrganizationPPListModal from 'domains/orgs/modals/OrganizationPPListModal'

interface IProps extends IOrg {
  size?: number
}

export default function OrganizationPPCell(props: IProps) {
  const [isModalOpen, openModal, closeModal] = useModalState()

  if (!utils.hasValue(props.preferrers)) {
    return null
  }

  return (
    <View flexible="column-center" hover="clickable">
      <View onClick={openModal} data-test={`pp-icon-${props.id}`}>
        <ToolTip
          id={`pp-icon-tip-${props.id}`}
          dataTest={`pp-icon-tip-${props.id}`}
          tip="Uses Preferred Partner(s)"
          delay={250}
        >
          <PreferredPartnerBadge size={props.size} />
        </ToolTip>
      </View>

      <OrganizationPPListModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        org={props}
      />
    </View>
  )
}
