import * as normalizr from 'normalizr'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

export const IMS_APPLICATION_ORGANIZATIONS_RECORD = 'imsApplicationOrgs'

const imsApplicationOrgs: records.modules.IModule = {
  record: IMS_APPLICATION_ORGANIZATIONS_RECORD,
  responseDataKey: 'applications',
  getCreateResponseData: R.propOr({}, 'data'),
  schema: new normalizr.schema.Entity(
    IMS_APPLICATION_ORGANIZATIONS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(['orgName']),
  ),
  api: {
    create: () => 'trustedapps/applicationOrganizations',
    get: (id) => `trustedapps/applicationOrganizations/${id}`,
    getList: () => 'trustedapps/applicationOrganizations',
    update: (id) => `trustedapps/applicationOrganizations/${id}`,
    delete: (id) => `trustedapps/applicationOrganizations/${id}`,
  },
}

export default imsApplicationOrgs
