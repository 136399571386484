import React from 'react'
import * as R from 'ramda'
import memoize from 'fast-memoize'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { utils, records } from '@ims/1edtech-frontend-common'

import { View, List, Text, Span, ListCallToActionButton } from 'lib/components'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import { OR_CSV_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/orCSVCharacterizations'
import trackCharacterizationAnalytics from 'domains/characterizations/utils/trackCharacterizationAnalytics'
import CharacterizationNameCell from 'domains/characterizations/components/CharacterizationNameCell'
import { displaySpecVersion } from 'domains/products/utils/products'
import {
  AnySpecFormatType,
  FORMAT_TO_DISPLAY_FORMAT_BASE_NAME,
  OR_FORMAT_TO_SPEC_SUBTYPE,
} from 'domains/formats/constants/formats'
import CharacterizationStatusCell from 'domains/characterizations/components/CharacterizationStatusCell'
import CharacterizationNotesCell from 'domains/characterizations/components/CharacterizationNotesCell'
import CharacterizationDeleteCell from 'domains/characterizations/components/CharacterizationDeleteCell'
import {
  DISTRICT_OR_CHARACTERIZATIONS_ROUTE,
  SUPPLIER_OR_CHARACTERIZATIONS_ROUTE,
} from 'domains/application/navigation/routes'
import Dialog from 'domains/application/modals/Dialog'
import CharacterizationSourceCell from 'domains/characterizations/components/CharacterizationSourceCell'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import { RootState } from 'lib/store/rootReducer'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import { OpenTableRows, IOpenTableRows } from 'lib/hooks/OpenTableRows'
import { IListColumnDefinition } from 'lib/components/List'
import { deleteCharacterization } from 'domains/characterizations/workflows/deleteCharacterization'
import { isSupplierRoleSelector } from 'domains/authentication/selectors/roles'
import CharacterizationDistrictPropCell from 'domains/characterizations/components/CharacterizationDistrictPropCell'
import { ONEROSTER_BASE_NAV_ROUTE } from 'domains/specifications/constants/specNavigation'
import { oneRosterCharacterizationFormatsFilterSelector } from 'domains/application/selectors/application'
import BaseORRosteringCharacterizationsBreadCrumbs from 'domains/characterizations/components/BaseORRosteringCharacterizationsBreadCrumbs'

const keyExtractor = (char: ICharacterization, isSubRow: boolean) =>
  `${char.id}${isSubRow ? '-sub-row' : ''}`

export default function ORCharacterizationsScreen(props: RouteComponentProps) {
  const baseFilterParam = useSelector(
    oneRosterCharacterizationFormatsFilterSelector,
  )
  const isSupplier = useSelector(isSupplierRoleSelector)
  const orgId = useSelector((s: RootState) =>
    myOrganizationIdSelector(s, props),
  )
  const characterizations = useSelector((s: RootState) =>
    records.relatedRecordsSelectors.fullRelatedRecordsSelector(
      OR_CSV_CHARACTERIZATIONS_RECORD,
      myOrganizationIdSelector,
    )(s, props),
  )
  const [charToDelete, setCharToDelete] =
    React.useState<ICharacterization | null>(null)

  const onCloseDeleteDialog = () => setCharToDelete(null)

  const onOpenDeleteDialog = (char: ICharacterization) => setCharToDelete(char)

  const onDeleteConfirmed = async () => {
    if (charToDelete) {
      await deleteCharacterization(charToDelete.id, charToDelete.format)
      onCloseDeleteDialog()
    }
  }

  const onToggleNotes =
    (toggle: (id: string | number) => any) =>
    (characterization: ICharacterization) =>
      toggle(characterization.id)

  const onAddNew = () =>
    props.history.push(
      `${
        isSupplier
          ? SUPPLIER_OR_CHARACTERIZATIONS_ROUTE
          : DISTRICT_OR_CHARACTERIZATIONS_ROUTE
      }/${ONEROSTER_BASE_NAV_ROUTE}/create`,
    )

  const getColumns = memoize(
    (openTableRowsOptions: IOpenTableRows): IListColumnDefinition[] => {
      const charNameColumn = {
        title: 'Characterization',
        maxWidth: [240, 240, 300, 350, 400],
        CellComponent: CharacterizationNameCell,
        cellProps: { maxWidthArray: [188, 188, 248, 298, 448] },
        sortKey: utils.sort.addSortValue('name@'),
        noCenter: true,
      } as IListColumnDefinition
      const columns = [
        {
          title: 'Source',
          maxWidth: 80,
          noHeaderCenter: true,
          noCenter: false,
          CellComponent: CharacterizationSourceCell,
          sortKey: utils.sort.addSortValue('source@'),
        },
        {
          title: 'Run Date/Time',
          maxWidth: 132,
          noHeaderCenter: true,
          noCenter: true,
          accessor: (char: any) =>
            utils.date.formatForDisplay(R.propOr(0, 'runDate', char)),
          sortKey: utils.sort.addSortValue('runDate@'),
        },
        {
          title: 'Mode',
          maxWidth: 72,
          noHeaderCenter: true,
          noCenter: true,
          noSort: true,
          accessor: (char: any) =>
            OR_FORMAT_TO_SPEC_SUBTYPE[
              R.propOr('', 'format', char) as AnySpecFormatType
            ],
        },
        {
          title: 'Ver',
          maxWidth: 48,
          noHeaderCenter: true,
          noCenter: true,
          noSort: true,
          accessor: (char: any) =>
            displaySpecVersion(R.prop('specificationVersion', char)),
        },
        {
          title: 'Format',
          maxWidth: 42,
          noHeaderCenter: true,
          noCenter: true,
          noSort: true,
          accessor: (char: any) =>
            FORMAT_TO_DISPLAY_FORMAT_BASE_NAME[
              R.prop('format', char) as AnySpecFormatType
            ],
        },
        {
          title: 'Notes',
          maxWidth: 72,
          CellComponent: ({ ...rest }) => (
            <CharacterizationNotesCell
              onClick={onToggleNotes(openTableRowsOptions.onToggleRow)}
              isOpen={R.propOr(
                false,
                (rest as ICharacterization).id.toString(),
                openTableRowsOptions.OpenTableRows,
              )}
              {...(rest as ICharacterization)}
            />
          ),
          noSort: true,
        },
        {
          title: 'Status',
          maxWidth: 48,
          forceCenter: true,
          CellComponent: CharacterizationStatusCell,
          sortKey: utils.sort.addSortValue('status@,a.name:ascending', true),
        },
        {
          title: 'Comp',
          maxWidth: 48,
          forceCenter: true,
          accessor: 'comparisonCount',
          noSort: true,
        },
      ]

      const deleteColumn = {
        title: 'Delete',
        maxWidth: 48,
        forceCenter: true,
        noSort: true,
        CellComponent: CharacterizationDeleteCell,
        cellProps: { onClick: onOpenDeleteDialog },
      }
      if (isSupplier) {
        return [
          charNameColumn,
          {
            title: 'District',
            maxWidth: 120,
            noHeaderCenter: true,
            noCenter: true,
            CellComponent: CharacterizationDistrictPropCell,
            cellProps: {
              fallbackProp: 'organizationName',
              prop: 'districtName',
              concatProp: 'districtState',
            },
            noSort: true,
          },
          ...columns,
          deleteColumn,
        ]
      }

      return [charNameColumn, ...columns, deleteColumn]
    },
  )

  const renderDeleteMessage = () =>
    charToDelete ? (
      <Text>
        You are about to delete characterization{' '}
        <Span fontWeight={700}>{charToDelete.name}.</Span> Any comparisons based
        on this characterization will also be deleted. This action cannot be
        undone.
      </Text>
    ) : (
      ''
    )

  const renderSubRow =
    (OpenTableRows: any) => (characterization: ICharacterization) =>
      OpenTableRows[R.prop('id', characterization)] ? (
        <Text
          fontWeight={700}
          p={3}
          data-test={`${characterization.id}-notes-row`}
        >
          {characterization.notes || 'No Notes'}
        </Text>
      ) : (
        false
      )

  const createNewBtn = <ListCallToActionButton onClick={onAddNew} />
  return (
    <OpenTableRows>
      {(openTableRowsOptions: IOpenTableRows) => (
        <View variant="screen">
          <DocumentTitle title="Characterizations" />
          <BaseORRosteringCharacterizationsBreadCrumbs />
          <List
            title={'Characterizations'}
            record={OR_CSV_CHARACTERIZATIONS_RECORD}
            recordState={{
              ...characterizations,
              items: R.pathOr([], ['items'], characterizations).filter(
                utils.hasValue,
              ),
            }}
            baseFilterParam={baseFilterParam}
            parentRecordId={orgId}
            columns={getColumns(openTableRowsOptions)}
            rowHeight={68}
            dataTest="characterizations-list"
            renderSubRow={renderSubRow(openTableRowsOptions.OpenTableRows)}
            trackAnalytics={trackCharacterizationAnalytics}
            trackAnalyticsCategory="supplier_characterizations_list"
            initialSortColumnIndex={isSupplier ? 3 : 2}
            initialSortDescending={true}
            searchTemplate="name=~@@ OR filename=~@@ OR organizationName=~@@ OR districtName=~@@"
            callToAction={createNewBtn}
            keyExtractor={keyExtractor}
            resetPaging={!charToDelete}
          />
          <Dialog
            isOpen={utils.hasValue(charToDelete)}
            icon="fas fa-trash"
            message={renderDeleteMessage}
            confirmText="Yes, Delete"
            onConfirm={onDeleteConfirmed}
            onDeny={onCloseDeleteDialog}
          />
        </View>
      )}
    </OpenTableRows>
  )
}
