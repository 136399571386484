import React, { useState } from 'react'

import IRestCModelServiceEndpointParam from 'domains/modeling/models/IRestCModelServiceEndpointParam'
import { View, Switch, Text, Button } from 'lib/components'
import NoteEditorModal from 'lib/components/NoteEditorModal'
import { utils } from '@ims/1edtech-frontend-common'
import ModalTitle from 'lib/components/ModalTitle'

interface IProps {
  setFieldValue: any
  endpointIndex: number
  paramIndex: number
  param: IRestCModelServiceEndpointParam
  locked?: boolean
  noNotes?: boolean
}

export default function ModelServiceEndpointParamEditor(props: IProps) {
  const paramFormPath = `endpoints[${props.endpointIndex}].params[${props.paramIndex}]`

  const [noteOpen, setNoteOpen] = useState(false)
  const onOpenNote = () => setNoteOpen(true)
  const onCloseNote = () => setNoteOpen(false)
  const onNoteSaved = (note: string) =>
    props.setFieldValue(`${paramFormPath}.notes`, note)

  const onChange = () =>
    props.setFieldValue(`${paramFormPath}.supported`, !props.param.supported)

  return (
    <View
      flexible="row-space-between"
      py={1}
      pl="6px"
      mr="12px"
      ml="-2px"
      mb={3}
      minWidth={props.noNotes ? 'auto' : 206}
      borderWidth="0 0 0 2px"
      borderColor="neutral"
      borderStyle="solid"
      bg="activeTabColor"
    >
      <View flexible="row-v-center">
        <Switch
          small={true}
          on={props.param.supported}
          onChange={onChange}
          locked={props.locked}
          dataTest={`param-${props.param.name}`}
        />
        <Text ml={2} variant="caption">
          {props.param.name}
        </Text>
      </View>

      {!props.noNotes && (
        <>
          <Button
            ml={2}
            variant={utils.hasValue(props.param.notes) ? 'start' : 'tertiary'}
            onClick={onOpenNote}
            data-test={`param-notes-${props.param.name}`}
          >
            <Text fontSize={11} color="white">
              {utils.hasValue(props.param.notes)
                ? 'view/edit note'
                : 'add a note'}
            </Text>
          </Button>
          <NoteEditorModal
            isOpen={noteOpen}
            close={onCloseNote}
            onSave={onNoteSaved}
            title={
              <ModalTitle
                title="Edit notes for @@ parameter"
                highlight={props.param.name}
              />
            }
            notes={props.param.notes}
          />
        </>
      )}
    </View>
  )
}
