import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

import store from 'lib/store'
import ICSVSpecification from 'domains/specifications/models/ICSVSpecification'
import ICSVSpecificationFile from 'domains/specifications/models/ICSVSpecificationFile'
import { SPECIFICATIONS_RECORD } from 'lib/records/modules/specifications'
import { ONER_V1P1_CSV_ROSTERING_IMPORT_BULK } from 'domains/formats/constants/formats'
import { OR1P1Subsets } from 'domains/specifications/constants/oneRoster1p1FileSets'

export const CLASS_ROSTERS_FILES = [
  'orgs.csv',
  'users.csv',
  'courses.csv',
  'academicSessions.csv',
  'classes.csv',
  'enrollments.csv',
]
export const CLASS_ROSTERS_W_DEMOGRAPHICS_FILES = [
  ...CLASS_ROSTERS_FILES,
  'demographics.csv',
]
const FILES_TO_FILESET_MAP = {
  [OR1P1Subsets.CLASS_ROSTERS_SUBSET]: CLASS_ROSTERS_FILES,
  [OR1P1Subsets.CLASS_ROSTERS_W_DEMOGRAPHICS_SUBSET]:
    CLASS_ROSTERS_W_DEMOGRAPHICS_FILES,
}
export const getFilesForSpecFileSet = (fileSet: OR1P1Subsets) => {
  const files = R.propOr<string[], any, string[]>(
    [],
    fileSet,
    FILES_TO_FILESET_MAP,
  )
  const spec = records.entitiesSelectors.entityByIdSelector(
    SPECIFICATIONS_RECORD,
    'format',
  )(store.getState(), { format: ONER_V1P1_CSV_ROSTERING_IMPORT_BULK })
  const specFilesNames = R.compose<
    ICSVSpecification,
    ICSVSpecificationFile[],
    string[]
  >(
    R.map(R.prop('name')),
    R.propOr([], 'files'),
  )(spec)
  const intersectingFiles = R.intersection(files, specFilesNames)
  return R.compose(
    R.map(R.assoc('touched', false)),
    R.map((file) =>
      R.find(R.propEq('name', file), R.propOr([], 'files', spec)),
    ),
  )(intersectingFiles)
}

export const FILE_SET_TO_DISPLAY_NAME: { [key: string]: string } = {
  [OR1P1Subsets.CLASS_ROSTERS_SUBSET]: 'Class Rosters',
  [OR1P1Subsets.CLASS_ROSTERS_W_DEMOGRAPHICS_SUBSET]:
    'Class Rosters (with demographics)',
}
