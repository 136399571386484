import {
  ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
  ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
} from 'domains/formats/constants/formats'
import { CSV_C_MODELS_RECORD } from 'lib/records/modules/csvCModels'
import { REST_C_MODELS_RECORD } from 'lib/records/modules/restCModels'

export const FORMAT_TO_MODULE_NAME_MAP: { [key: string]: string } = {
  [ONER_V1P1_CSV_ROSTERING_IMPORT_BULK]: CSV_C_MODELS_RECORD,
  [ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL]: REST_C_MODELS_RECORD,
}
