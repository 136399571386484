import React from 'react'
import { useSelector } from 'react-redux'
import { utils } from '@ims/1edtech-frontend-common'

import { Switch } from 'lib/components'
import { NotificationPreference } from 'domains/users/models/IUserPreferences'
import { RootState } from 'lib/store/rootReducer'
import { USER_PREFERENCES_RECORD } from 'lib/records/modules/userPreferences'
import { meSelector } from 'domains/authentication/selectors/me'
import IUser from 'domains/users/models/IUser'
import { updateRecord } from 'lib/records/workflows/updateRecord'
import { listenForNotifications } from 'domains/notifications/workflows/listenForNotifications'
import { notificationPreferenceSelector } from 'domains/notifications/selectors/notificationSelectors'
import trackNotificationAnalytics from 'domains/notifications/utils/trackNotificationAnalytics'

interface IProps {
  userId: string | number
  small?: boolean
  value?: NotificationPreference
  onChange?: (key: string, value: any) => any
  'data-test'?: string
}

export default function PushNotificationsToggle(props: IProps) {
  const me = useSelector(meSelector) as IUser
  const defaultNotificationPreference = useSelector<
    RootState,
    NotificationPreference
  >((s) => notificationPreferenceSelector('userId')(s, props))
  const notificationPreference = props.value || defaultNotificationPreference
  const showNotifications = notificationPreference === 'ALERTS'

  const onToggleShowNotifications = async () => {
    const preference = showNotifications ? 'SILENT' : 'ALERTS'
    if (props.onChange) {
      await props.onChange('notifications', preference)
    } else {
      await updateRecord(USER_PREFERENCES_RECORD, props.userId, {
        notifications: { inApp: { preference } },
      })
      if (me.id === utils.convertToInt(props.userId)) {
        await listenForNotifications(preference)
      }
      trackNotificationAnalytics('user_toggled_push_notifications', {
        newPrefernce: preference,
      })
    }
  }

  return (
    <Switch
      on={showNotifications}
      onChange={onToggleShowNotifications}
      dataTest={props['data-test'] || 'show-notifications-toggle'}
      onIconName="fas fa-bell"
      offIconName="fas fa-bell-slash"
      small={props.small}
    />
  )
}
