import React from 'react'
import * as R from 'ramda'

import {
  ReportStatus,
  STATUS_TO_LIGHT_VARIANT_MAP,
} from 'domains/reports/models/IReportStatuses'
import { View, Text } from 'lib/components'

interface IProps {
  status: ReportStatus
  text: any
  'data-test'?: string
}

export default function RESTCharacterizationParamError(props: IProps) {
  return (
    <View
      flexible="row-v-center"
      my={2}
      py={2}
      px={3}
      minHeight={48}
      borderRadius={6}
      data-test={props['data-test'] || `report-param-error-${props.text}`}
      bg={STATUS_TO_LIGHT_VARIANT_MAP[props.status]}
    >
      {R.is(String, props.text) && <Text fontStyle="italic">{props.text}</Text>}
      {!R.is(String, props.text) && props.text}
    </View>
  )
}
