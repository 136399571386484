import React, { PureComponent } from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { records } from '@ims/1edtech-frontend-common'

import { Text } from 'lib/components'
import { specFileColumnSelector } from 'domains/modeling/selectors/csvCModelToSpec'
import SpecificationDescription from 'domains/modeling/components/SpecificationDescription'
import { RootState } from 'lib/store/rootReducer'
import { ICSVConsumerFileColumn } from 'domains/modeling/models/ICSVConsumerModel'
import ICSVSpecificationFileColumn from 'domains/specifications/models/ICSVSpecificationFileColumn'
import { AnySpecFormatType } from 'domains/formats/constants/formats'
import { SPECIFICATIONS_RECORD } from 'lib/records/modules/specifications'
import { getFormatByProp } from 'domains/products/selectors/formats'

interface IPropsFromState {
  specColumn?: ICSVSpecificationFileColumn | null
}
const stateMap = createStructuredSelector<RootState, any, IPropsFromState>({
  specColumn: specFileColumnSelector(
    records.entitiesSelectors.entityByIdSelector(
      SPECIFICATIONS_RECORD,
      getFormatByProp(),
    ),
    'fileName',
    'header',
  ),
})

interface IProps extends ICSVConsumerFileColumn {
  format: AnySpecFormatType
  fileName: string
}

export class CsvCColumnDescriptionCell extends PureComponent<
  IPropsFromState & IProps
> {
  render() {
    const { description, specColumn } = this.props
    return (
      <Text py={3} wordBreak="break-word" width={300}>
        <SpecificationDescription
          description={description || R.propOr('', 'notes', specColumn)}
        />
      </Text>
    )
  }
}

export default connect(stateMap)(CsvCColumnDescriptionCell)
