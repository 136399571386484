import React from 'react'
import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'

import CharacterizationsLineItemsDetails from 'domains/characterizations/components/DistrictCharacterizationDetails/CharacterizationsLineItemsDetails'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import ICSVModelCharacterization from 'domains/characterizations/models/ICSVModelCharacterization'
import IProduct from 'domains/products/models/IProduct'
import ICSVConsumerModel from 'domains/modeling/models/ICSVConsumerModel'
import ITransformedCharacterizationCSVReport from 'domains/characterizations/models/ITransformedCharacterizationCSVReport'
import ITransformedRESTCharacterizationReport from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import PrintHR from 'domains/printing/components/PrintHR'
import PrintPopulationLegend from 'domains/characterizations/components/DistrictCharacterizationDetails/print/PrintPopulationLegend'
import WidgetLineItem from 'domains/reports/components/WidgetLineItem'

interface IProps {
  characterization: ICharacterization | ICSVModelCharacterization
  report:
    | ITransformedCharacterizationCSVReport
    | ITransformedRESTCharacterizationReport
  product?: IProduct
  csvModel?: ICSVConsumerModel
}

export default function PrintCharacterizationTopDetails(props: IProps) {
  const notes = props.csvModel
    ? props.csvModel.notes
    : (props.characterization as ICharacterization).notes

  const extraItems: { label: string; value: string }[] = [
    {
      label: 'Host Name',
      value: R.pathOr('', ['hostname'], props.characterization),
    },
    {
      label: 'Security',
      value: R.pathOr('', ['securityType'], props.characterization),
    },
  ].filter((item) => utils.hasValue(item.value))

  return (
    <div>
      <div className="flex flex-row justify-between items-start mt-3">
        <div style={{ width: '38%' }}>
          <CharacterizationsLineItemsDetails
            characterization={props.characterization}
            product={props.product}
            addName={true}
          />
        </div>

        <div
          className="flex flex-col flex-1 ml-2 justify-between"
          style={{ minHeight: 120 }}
        >
          <div>
            {extraItems.map((item) => (
              <WidgetLineItem
                key={`print-${item.label}`}
                label={item.label}
                value={item.value}
              />
            ))}
            {utils.hasValue(notes) && (
              <WidgetLineItem label="Notes" value={notes} />
            )}
          </div>

          <PrintPopulationLegend />
        </div>
      </div>

      <PrintHR />
    </div>
  )
}
