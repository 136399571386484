import { createSelector } from 'reselect'
import * as R from 'ramda'
import { utils, records } from '@ims/1edtech-frontend-common'

import {
  AnyFormatType,
  ANY_FORMAT_TO_SPEC_FORMAT_MAP,
  FORMAT_TO_INVERSE_MAP,
} from 'domains/formats/constants/formats'
import { FORMATS_RECORD } from 'lib/records/modules/formats'
import { IEntityState } from 'lib/store/entities.reducer'
import { RootState } from 'lib/store/rootReducer'

export const findFormatIdByName = (
  format: AnyFormatType,
  formats: any[],
  inverse = false,
) => {
  const specFormat = ANY_FORMAT_TO_SPEC_FORMAT_MAP[format]
  const formatName = inverse ? FORMAT_TO_INVERSE_MAP[specFormat] : specFormat
  const found = R.find(
    (f: any) => R.propOr('', 'description', f) === formatName,
    formats || [],
  )
  return utils.convertToInt(found ? found.id : null, 0)
}

export const formatIdByNameSelector = (format: AnyFormatType) =>
  createSelector<RootState, any, any>(
    records.entitiesSelectors.entityListSelector(FORMATS_RECORD),
    (formats: IEntityState) => findFormatIdByName(format, R.values(formats)),
  )

export const formatIdByNamePropSelector = (
  getFormatName: records.entitiesSelectors.getIdType,
  inverse?: boolean,
) =>
  createSelector<RootState, any, any, any, any>(
    records.entitiesSelectors.idSelector(getFormatName),
    records.entitiesSelectors.entityListSelector(FORMATS_RECORD),
    (format, formats: IEntityState) =>
      findFormatIdByName(format, R.values(formats), inverse),
  )

export const formatNameByIdSelector = (formatId: string | number) =>
  createSelector(
    records.entitiesSelectors.entityListSelector(FORMATS_RECORD),
    R.compose(
      R.propOr('', 'description'),
      R.pathOr({}, [utils.convertToInt(formatId)]),
    ),
  )
