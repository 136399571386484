import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

export const FORMATS_RECORD = 'formats'

const formats: records.modules.IModule = {
  record: FORMATS_RECORD,
  schema: new normalizr.schema.Entity(
    FORMATS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(),
  ),
  responseDataKey: 'formats',
  api: {
    create: () => '/formats',
    get: (id) => `/formats/${id}`,
    getList: () => 'configuration',
    update: (id) => `/formats/${id}`,
    delete: (id) => `/formats/${id}`,
  },
}

export default formats
