import * as R from 'ramda'

import { putRequest } from 'lib/api/api'
import { updateRecord } from 'lib/records/workflows/updateRecord'
import { NOTIFICATIONS_RECORD } from 'lib/records/modules/notifications'
import { applicationPropSelector } from 'domains/application/selectors/application'
import store from 'lib/store'
import { updateEntityProperty } from 'lib/store/entities.actions'
import { getNotificationCount } from 'domains/notifications/workflows/getNotificationCount'
import trackNotificationAnalytics from 'domains/notifications/utils/trackNotificationAnalytics'

export const markNotificationAsRead = async (id?: number) => {
  if (id) {
    await updateRecord(NOTIFICATIONS_RECORD, id)
    store.dispatch(
      updateEntityProperty([NOTIFICATIONS_RECORD, id], R.assoc('read', true)),
    )
    trackNotificationAnalytics('marked_single_notification_read', { id })
  } else {
    const meId = applicationPropSelector('me')(store.getState())
    await putRequest(`users/${meId}/notifications/read`)
    store.dispatch(
      updateEntityProperty([NOTIFICATIONS_RECORD], (notifications) => {
        const newNotifications = R.keys(notifications).reduce(
          (agg, key) =>
            R.assoc(
              String(key),
              {
                ...notifications[key],
                read: true,
              },
              agg,
            ),
          {},
        )
        return newNotifications
      }),
    )
    trackNotificationAnalytics('marked_all_notifications_read')
  }

  await getNotificationCount()
}
