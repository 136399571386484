import { records } from '@ims/1edtech-frontend-common'

import { OrgCertificationLevels } from 'domains/orgs/models/IOrg'
import IProductFormat from 'domains/products/models/IProductFormat'
import { ReportStatus } from 'domains/reports/models/IReportStatuses'

export enum CharacterizationProfileTypes {
  basic = 'basic',
  sceds = 'sceds',
  unknown = 'unknown',
}
export enum CHARACTERIZATION_PROFILE_TO_DISPLAY {
  basic = 'Exclude external controlled vocabulary verification',
  sceds = 'Include external controlled vocabulary verification',
  unknown = 'Unknown',
}

export default interface ICharacterization extends IProductFormat {
  id: number
  name: string
  color: string
  notes?: string
  profile?: CharacterizationProfileTypes
  source: string
  sourceVersion?: string
  certifiedSourceLevel?: OrgCertificationLevels
  runDate: number
  zipFileURL?: string
  hostname?: string
  securityType?: string
  comparisonCount?: number
  status?: ReportStatus
  content: string
  organization: records.modules.IRef
  organizationName: string
  organizationCertificationLevel?: OrgCertificationLevels
  districtName?: string
  districtState?: string
  districtCountry?: string
}
