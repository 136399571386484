import React from 'react'
import { useSelector } from 'react-redux'
import { utils } from '@ims/1edtech-frontend-common'

import { DeleteCircle } from 'lib/components'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'

interface IProps extends ICharacterization {
  onClick: (arg: ICharacterization) => void
}

export default function CharacterizationDeleteCell(props: IProps) {
  const orgId = useSelector((s: any) => myOrganizationIdSelector(s, props))

  if (utils.convertToInt(orgId) !== utils.convertToInt(props.organization.id)) {
    return null
  }

  return (
    <DeleteCircle
      onClick={props.onClick}
      item={props}
      data-test={`delete-characterization-btn-${props.id}`}
      aria-label={`Delete ${props.name} characterization`}
    />
  )
}
