import React from 'react'
import * as R from 'ramda'

import { View, Text, Span } from 'lib/components'
import IFileSetValidationErrorReport from 'domains/characterizations/models/IFileSetValidationErrorReport'
import ICSVSpecification from 'domains/specifications/models/ICSVSpecification'
import { findSpecColumn } from 'domains/modeling/selectors/csvCModelToSpec'
import ICSVSpecificationFileColumn from 'domains/specifications/models/ICSVSpecificationFileColumn'
import CharacterizationErrorDot from 'domains/characterizations/components/CharacterizationErrorDot'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  fileLabel: string
  column: string
  errors: IFileSetValidationErrorReport[]
  specification: ICSVSpecification
}

export default function PrintOR1P1CSVCharacterizationFileColumnErrors(
  props: IProps,
) {
  const specColumn = findSpecColumn(
    props.specification,
    props.fileLabel,
    props.column,
  ) as ICSVSpecificationFileColumn

  const idKey = `${props.fileLabel}-${props.column}`

  const type = R.propOr<string, any, string>('', 'dataType', specColumn)
  const description = R.propOr<string, any, string>('', 'notes', specColumn)
  return (
    <View mt={3} mb={4} data-test={idKey}>
      <Text
        fontSize={16}
        fontWeight={700}
        minWidth={100}
        ml={4}
        fontStyle="italic"
      >
        {props.column}
      </Text>

      {(utils.hasValue(type) || utils.hasValue(description)) && (
        <View flexible="row" mt={1} ml={4}>
          <Text minWidth={100}>
            <Span fontWeight={700}>Type: </Span>
            {type}
          </Text>

          <View flexible="row" ml={4}>
            <Text fontWeight={700}>Description: </Text>
            <Text ml={1}>{description}</Text>
          </View>
        </View>
      )}

      <Text fontSize={14} fontWeight={700} mt={1} ml={4}>
        Critical Exceptions:
      </Text>

      <View mt={1}>
        {props.errors.map(
          (error: IFileSetValidationErrorReport, index: number) => (
            <View
              key={`${idKey}-error-${index}`}
              data-test={`${idKey}-error`}
              flexible="row-v-center"
              bg={index % 2 === 0 ? 'background' : 'white'}
              py={1}
              pl={4}
            >
              <CharacterizationErrorDot color="error" size={12} />
              <Text ml={3}>{error.description}</Text>
            </View>
          ),
        )}
      </View>
    </View>
  )
}
