import React from 'react'
import { utils, records } from '@ims/1edtech-frontend-common'

import IComparison from 'domains/comparisons/models/IComparison'
import DeleteButton from 'lib/components/DeleteButton'
import { useSelector } from 'react-redux'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'

interface IProps extends IComparison {
  onClick: (comparison: IComparison) => void
}

export default function ComparisonDeleteCell(props: IProps) {
  const myOrgId = useSelector((s: any) => myOrganizationIdSelector(s, props))

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    props.onClick(props)
  }

  if (
    utils.convertToInt(myOrgId) !==
    utils.convertToInt(records.modules.getRefId(props, 'ownerId'))
  ) {
    return null
  }

  return (
    <DeleteButton
      onClick={onClick}
      data-test={`delete-comparison-btn-${props.id}`}
      aria-label={`Delete comparison between product ${props.productName} and characterization ${props.characterizationName}`}
    />
  )
}
