import React from 'react'
import { utils } from '@ims/1edtech-frontend-common'

import InfoCardItem from 'lib/components/InfoCard/InfoCardItem'

export type InfoCardItemProps = {
  label: string
  value?: string | string[]
  commaDelimited?: boolean
}

export default function InfoCardItems({
  items,
  showIfNoValue = false,
  'data-test': dataTest,
}: {
  items: InfoCardItemProps[]
  showIfNoValue?: boolean
  'data-test'?: string
}) {
  const filteredItems = showIfNoValue
    ? items
    : items.filter((item) => utils.hasValue(item.value))
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
      {filteredItems.map((item) => (
        <InfoCardItem
          key={`info-item-${item.label}`}
          label={item.label}
          value={item.value}
          commaDelimited={item.commaDelimited}
          showIfNoValue={showIfNoValue}
          data-test={dataTest}
        />
      ))}
    </div>
  )
}
