import { updateCsvCModel } from 'domains/modeling/workflows/updateCsvCModel'
import { updateFileAndGetModelInState } from 'domains/modeling/workflows/csvCModelFile'

export const updateCsvCFileModel = async (
  modelId: string | number,
  fileIndex: string | number,
  file: any,
) => {
  const model = updateFileAndGetModelInState(modelId, fileIndex, file)
  await updateCsvCModel(modelId, model)
}
