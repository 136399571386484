import React from 'react'
import * as R from 'ramda'

import IProduct from 'domains/products/models/IProduct'
import { View } from 'lib/components'
import { IPreferredPartner } from 'domains/orgs/models/IOrg'
import PPListRow from 'domains/orgs/components/PPListRow'

interface IProps {
  product: IProduct
}

export default function ProductPPList(props: IProps) {
  const preferrers = R.pathOr(
    [],
    ['product', 'preferrers'],
    props,
  ) as IPreferredPartner[]

  return (
    <View data-test={`product-${props.product.id}-pp-list`}>
      {preferrers.map((pp, index) => (
        <PPListRow
          key={`product-${props.product.id}-preferred-partner-${index}`}
          data-test={`product-${props.product.id}-pp-${pp.id}`}
          preferredPartner={pp}
        />
      ))}
    </View>
  )
}
