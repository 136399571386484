import React from 'react'
import * as R from 'ramda'

import { View, ExpansionPanel, Icon } from 'lib/components'
import {
  IIMSApplicationFilter,
  IIMSApplicationParentFilter,
  PARENT_FILTER_PREFIX,
} from 'domains/trustedApps/components/AddTrustedApps/IMSApplicationsFilter/IIMSApplicationsFilter'
import IMSApplicationCheckboxFilter from 'domains/trustedApps/components/AddTrustedApps/IMSApplicationsFilter/IMSApplicationCheckboxFilter'

interface IFilterProps {
  appliedFilters: string[]
  filter: IIMSApplicationParentFilter
  onFilterChecked: (
    filter: IIMSApplicationFilter | IIMSApplicationFilter[],
  ) => any
}
export default function IMSApplicationGroupedCheckboxFilter(
  props: IFilterProps,
) {
  const [isOpen, setIsOpen] = React.useState(false)
  const toggleIsOpen = () => setIsOpen(!isOpen)

  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    event.stopPropagation()
    const name = R.pathOr('', ['target', 'name'], event)
    const isParent = name.startsWith(PARENT_FILTER_PREFIX)
    if (isParent) {
      props.onFilterChecked(props.filter.children)
    } else {
      const filter = R.find(R.propEq('label', name))(
        props.filter.children,
      ) as IIMSApplicationFilter
      props.onFilterChecked([filter])
    }
  }

  return (
    <ExpansionPanel
      isOpen={isOpen}
      onRequestChange={toggleIsOpen}
      disabled={true}
      summary={
        <View flexible="row-space-between">
          <IMSApplicationCheckboxFilter
            filter={{
              label: props.filter.parent,
              filterKey: 'ims_cert_type',
              value: props.filter.parent,
            }}
            appliedFilters={props.appliedFilters}
            parentFilter={props.filter}
            onChange={onChange}
          />
          <View
            flexible="column-center"
            minWidth={24}
            minHeight={24}
            onClick={toggleIsOpen}
            cursor="pointer"
            data-test={`${props.filter.parent}-toggle`}
          >
            <Icon
              ml={1}
              className={`fas rotate-caret fa-angle-down ${
                isOpen ? 'down' : ''
              }`}
            />
          </View>
        </View>
      }
      details={props.filter.children.map((f) => (
        <IMSApplicationCheckboxFilter
          key={`filter-child-${f.value}`}
          filter={f}
          appliedFilters={props.appliedFilters}
          onChange={onChange}
          isChild={true}
        />
      ))}
    />
  )
}
