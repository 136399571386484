import { putRequest } from 'lib/api/api'
import store from 'lib/store'
import { setApplicationProperties } from 'domains/application/ducks/application'
import {
  EVENT_HEADER_IMAGE_URL,
  EVENT_HEADER_LINK_URL,
  EVENT_BUTTON_TEXT
} from 'domains/application/models/configuration'

export const updateEventHeaderConfig = async (payload: {
  linkUrl: string
  imageUrl: string
  buttonText: string
}) => {
  const { success } = await putRequest('system/config', {
    configurations: [
      {
        attribute: EVENT_HEADER_LINK_URL,
        value: payload.linkUrl,
      },
      {
        attribute: EVENT_HEADER_IMAGE_URL,
        value: payload.imageUrl,
      },
      {
        attribute: EVENT_BUTTON_TEXT,
        value: payload.buttonText
      }
    ],
  })

  if (success) {
    store.dispatch(
      setApplicationProperties({
        [EVENT_HEADER_LINK_URL]: payload.linkUrl,
        [EVENT_HEADER_IMAGE_URL]: payload.imageUrl,
        [EVENT_BUTTON_TEXT]: payload.buttonText
      }),
    )
  }

  return success
}
