import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { Text, View } from 'lib/components'
import { myOrganizationPropSelector } from 'domains/authentication/selectors/organizations'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import ProductImageCell from 'domains/products/components/ProductImageCell'
import { RootState } from 'lib/store/rootReducer'
import IProduct from 'domains/products/models/IProduct'

interface IPropsFromState {
  orgName: string
}
const stateMap = createStructuredSelector<RootState, any, IPropsFromState>({
  orgName: myOrganizationPropSelector('name'),
})

interface IProps {
  product: IProduct
}

class ProductDetailsWidget extends PureComponent<IProps & IPropsFromState> {
  static defaultProps = {}

  render() {
    const {
      product: { url, name, logo, description },
      orgName,
    } = this.props

    return (
      <DetailsTopWidget
        title="Details"
        dataTest="product-details-widget"
        height={380}
      >
        <View flexible="row-v-center" my={4}>
          <ProductImageCell name={name} logo={logo} size={128} />
          <View ml={3}>
            <Text fontWeight={700} mt={3} data-test="product-details-name">
              <a href={url} target="_blank" rel="noopener noreferrer">
                {name}
              </a>
            </Text>
            <Text data-test="product-details-org">{orgName}</Text>
          </View>
        </View>

        <Text fontWeight={700} mt={3}>
          Description
        </Text>
        <Text data-test="product-details-description">
          {description || 'N/A'}
        </Text>
      </DetailsTopWidget>
    )
  }
}

export default connect(stateMap)(ProductDetailsWidget)
