import React from 'react'

import IFileSetCharacterizationColumn from 'domains/characterizations/models/IFileSetCharacterizationColumn'
import CharacterizationErrorDot from 'domains/characterizations/components/CharacterizationErrorDot'
import { utils } from '@ims/1edtech-frontend-common'
import { ToolTip } from 'lib/components'
import { GREEN_REPORT_STATUS } from 'domains/reports/models/IReportStatuses'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'

const dotSize = isOnPrintScreen() ? 12 : undefined
interface IExtraProps {
  fileLabel: string
}

export default function CharacterizationColumnWarningCell(
  props: IFileSetCharacterizationColumn & IExtraProps,
) {
  const { fileLabel, headerName, warning, notes, status } = props
  if (
    utils.hasValue(warning) ||
    utils.hasValue(notes) ||
    (status && status !== GREEN_REPORT_STATUS)
  ) {
    if (utils.hasValue(warning) && !isOnPrintScreen()) {
      return (
        <ToolTip
          id={`${fileLabel}-${headerName}-warning`}
          tip={warning}
          place="right"
        >
          <CharacterizationErrorDot color="secondary" size={dotSize} />
        </ToolTip>
      )
    }
    return <CharacterizationErrorDot color="secondary" size={dotSize} />
  }
  return null
}
