import React from 'react'

import SmallWidget from 'domains/dashboard/components/SmallWidget'

interface IProps {
  fadeDelay: number
  count: number
}

export default function OrganizationCountWidget(props: IProps) {
  return (
    <SmallWidget
      flexible="column-h-start"
      fadeDelay={props.fadeDelay}
      icon="fas fa-sitemap"
      iconColor="infoLight"
      title={props.count}
      subtitle="Organizations"
      dataTest="organization-count-widget"
    />
  )
}
