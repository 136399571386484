import React from 'react'

import { Text, Span, View } from 'lib/components'
import { utils } from '@ims/1edtech-frontend-common'
import { ICommonCartridgeLanguageText } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeLanguageText'
import CCCharacterizationReportLOMDetail from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMDetail'
import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'

interface IProps {
  title?: string
  data?: ICommonCartridgeLanguageText
  path: string
}
export const CC_LANGUAGE_NOT_SPECIFIED_MESSAGE = '(No language specified)'

export default function CCCharacterizationReportLOMLanguageText(props: IProps) {
  const { showMissingMetadata } = React.useContext(ShowMissingMetadataContext)
  if (!showMissingMetadata && !utils.hasValue(props.data)) {
    return null
  }

  const inner = (
    <View>
      <Text mb={1} whiteSpace="pre-line" data-test={`${props.path}.language`}>
        <Span fontWeight={700}>language: </Span>{' '}
        {props.data!.language || CC_LANGUAGE_NOT_SPECIFIED_MESSAGE}
      </Text>
      <Text ml={3} whiteSpace="pre-line" data-test={`${props.path}.text`}>
        {props.data!.text}
      </Text>
    </View>
  )

  if (utils.hasValue(props.title)) {
    return (
      <CCCharacterizationReportLOMDetail
        path={props.path}
        title={props.title!}
        value={inner}
      />
    )
  }

  return inner
}
