import React from 'react'
import * as R from 'ramda'

import { View, ExpansionPanel } from 'lib/components'
import ITransformedRESTCharacterizationReport, {
  ITransformedRESTServiceCharacterizationReport,
  ITransformedRESTServiceEndpointCharacterizationReport,
} from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import RESTServiceCharacterizationReport from 'domains/characterizations/components/CharacterizationReport/RESTServiceCharacterizationReport'
import useAccordionHeaderState from 'domains/reports/hooks/useAccordionHeaderState'
import IAccordionHeaderState from 'domains/characterizations/models/IAccordionHeaderState'
import RESTEndpointReportHeader from 'domains/reports/components/RESTEndpointReportHeader'
import { AnySpecFormatType } from 'domains/formats/constants/formats'

interface IProps {
  format: AnySpecFormatType
  report: ITransformedRESTCharacterizationReport
  service: ITransformedRESTServiceCharacterizationReport
  specification: IRestCSpec
  serviceExpanded: boolean
  serviceCollapsed: boolean
  allExpanded: boolean
  allCollapsed: boolean
  setAllExpandedState: (state: boolean) => any
  setAllCollapsedState: (state: boolean) => any
}

export default function RESTPCharacterizationReportService(props: IProps) {
  const [
    headerStates,
    getHeaderState,
    onToggleHeader,
    onExpandAll,
    onCollapseAll,
  ] = useAccordionHeaderState(
    R.pathOr([], ['endpointCharacterizations'], props.service).map(
      (e: any) => ({
        ...e,
        name: e.endpointName,
      }),
    ),
  )

  React.useEffect(() => {
    if (props.allExpanded) {
      onExpandAll()
    }
    if (props.allCollapsed || props.serviceCollapsed) {
      onCollapseAll()
    }
    // eslint-disable-next-line
  }, [props.allCollapsed, props.allExpanded, props.serviceCollapsed])

  const onRequestChange = (
    endpoint: ITransformedRESTServiceEndpointCharacterizationReport,
  ) => () => {
    const state = getHeaderState(endpoint.endpointName)
    if (state.open && props.allExpanded) {
      props.setAllExpandedState(false)
    }
    if (!state.open && props.allCollapsed) {
      props.setAllCollapsedState(false)
    }
    onToggleHeader(endpoint.endpointName)
  }

  const renderEndpoint = (item: IAccordionHeaderState) => {
    const endpoint = item.data as ITransformedRESTServiceEndpointCharacterizationReport
    const headerState = getHeaderState(endpoint.endpointName)
    if (!headerState) {
      return null
    }

    return (
      <ExpansionPanel
        key={endpoint.endpointName}
        isOpen={headerState.open}
        onRequestChange={onRequestChange(endpoint)}
        hideWhenClosed={true}
        summary={
          <RESTEndpointReportHeader
            format={props.format}
            endpoint={endpoint}
            open={headerState.open}
            data-test={`endpoint-report-header-${endpoint.endpointName}`}
          />
        }
        details={
          <RESTServiceCharacterizationReport
            isOpen={headerState.open}
            report={props.report}
            service={props.service}
            specification={props.specification}
            endpoint={endpoint}
          />
        }
      />
    )
  }

  const { report } = props
  if (report.serviceCharacterizations.length < 1) {
    return null
  }

  return (
    <View
      data-test={`characterization-report-service-${props.service.serviceName}`}
    >
      {headerStates.map(renderEndpoint)}
    </View>
  )
}
