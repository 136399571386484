import React from 'react'

import SmallWidget from 'domains/dashboard/components/SmallWidget'

interface IProps {
  count: number
  fadeDelay: number
}
export default function CommonCartridgeValidTotalWidget(props: IProps) {
  return (
    <SmallWidget
      fadeDelay={props.fadeDelay}
      icon="fas fa-chart-pie"
      iconColor="start"
      title={props.count}
      subtitle="Valid Cartridges"
      dataTest="common-cartridge-valid-count-widget"
    />
  )
}
