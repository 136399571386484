import React from 'react'

import { BreadCrumbs } from 'lib/components'
import {
  DASHBOARD_ROUTE,
  COMPARISONS_ROUTE,
  getRoute,
} from 'domains/application/navigation/routes'
import IBreadCrumb from 'domains/application/models/IBreadCrumb'
import { ONEROSTER_BASE_NAV_ROUTE } from 'domains/specifications/constants/specNavigation'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  crumbs?: IBreadCrumb[]
}

export default function BaseComparisonsBreadCrumbs(props: IProps) {
  const { crumbs = [] } = props

  return (
    <BreadCrumbs
      crumbs={[
        {
          name: 'Home',
          route: getRoute(DASHBOARD_ROUTE),
        },
        {
          name: 'OneRoster Check',
          route: getRoute(`/${ONEROSTER_BASE_NAV_ROUTE}${DASHBOARD_ROUTE}`),
        },
        {
          name: 'Comparisons',
          route: !utils.hasValue(crumbs)
            ? undefined
            : getRoute(`/${ONEROSTER_BASE_NAV_ROUTE}${COMPARISONS_ROUTE}`),
        },
        ...crumbs,
      ]}
    />
  )
}
