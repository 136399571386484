import React from 'react'
import { View, Text, Icon, Linkify } from 'lib/components'
import { utils } from '@ims/1edtech-frontend-common'
import SpecificationDescription from 'domains/modeling/components/SpecificationDescription'
import {
  AMBER_REPORT_STATUS,
  RED_REPORT_STATUS,
  ReportStatus,
} from 'domains/reports/models/IReportStatuses'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'
import { getReportMinMaxValue } from 'domains/reports/utils/getReportMinMaxValue'

const isPrint = isOnPrintScreen()

interface IProps {
  minWidth: number
  dataType: string
  status: ReportStatus
  required: string
  mdSource?: string
  parentName: string
  name: string
  description?: string
  notes?: string
  errorMessage?: string
  smallestValueLength?: number
  largestValueLength?: number
}

export default function ComparisonFileColumnBody(props: IProps) {
  const renderLine = (
    title: string,
    value: any,
    icon: React.ReactElement | null = null,
  ) => {
    const text = (
      <Text
        data-test={`${props.parentName}-${props.name}-detail-${title}`}
        mt={isPrint ? 0 : [2, 0]}
        ml={isPrint ? 2 : 0}
        fontSize={isPrint ? 10 : undefined}
        wordBreak="break-word"
      >
        <Linkify>{value}</Linkify>
      </Text>
    )
    return (
      <View
        flexDirection={isPrint ? 'row' : ['column', 'row']}
        mb={isPrint ? 0 : 2}
      >
        <View
          flexible={isPrint ? 'row' : 'row'}
          minWidth={100}
          width={isPrint ? 'auto' : 100}
        >
          <View flexible="row" minWidth={isPrint ? 64 : 100}>
            {icon}
            <Text fontWeight={700} fontSize={isPrint ? 10 : 16}>
              {title}:
            </Text>
          </View>
          {isPrint && text}
        </View>
        {!isPrint && text}
      </View>
    )
  }

  return (
    <View
      px={3}
      position="relative"
      minWidth={props.minWidth}
      flexible={isPrint ? 'row' : undefined}
    >
      {isPrint && (
        <Text
          fontWeight={400}
          fontSize={10}
          data-test={`comparison-min-max-${props.parentName}-${props.name}`}
          minWidth={48}
        >
          {getReportMinMaxValue(props)}
        </Text>
      )}
      <View flexible={isPrint ? 'column-v-center' : undefined}>
        {utils.hasValue(props.description) &&
          renderLine(
            'Description',
            <SpecificationDescription description={props.description} />,
          )}
        {utils.hasValue(props.notes) &&
          renderLine(
            'Notes',
            props.notes,
            <Icon className="fas fa-info-circle" color="info" mr={1} />,
          )}
        {props.status === AMBER_REPORT_STATUS &&
          utils.hasValue(props.errorMessage) &&
          renderLine(
            'Alert',
            props.errorMessage,
            <Icon className="fas fa-check-circle" color="warningAlt" mr={1} />,
          )}
        {props.status === RED_REPORT_STATUS &&
          renderLine(
            'Critical',
            props.errorMessage,
            <Icon className="fas fa-exclamation-circle" color="error" mr={1} />,
          )}
      </View>
    </View>
  )
}
