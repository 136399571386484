import React from 'react'

import { ListPlainTextCell } from 'lib/components'
import SpecificationDescription from 'domains/modeling/components/SpecificationDescription'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'
import { ITransformedRESTServiceEndpointAttributeCharacterizationReport } from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import IRestCModel from 'domains/modeling/models/IRestCModel'
import { OR_1P1_REST_ROSTERING_CONSUMER_SERVICES } from 'domains/modeling/constants/restCOneRoster1p1'
import {
  findSpecServiceEndointAttributeByName,
  findModelServiceEndointAttributeByName,
} from 'domains/modeling/utils/restCOR1p1utils'

const isPrint = isOnPrintScreen()

interface IProps
  extends ITransformedRESTServiceEndpointAttributeCharacterizationReport {
  serviceName: string
  endpointName: string
  specification: IRestCSpec
  model?: IRestCModel
  width?: string | (string | number)[]
}

export default function RESTCharacterizationAttributeDescriptionCell(
  props: IProps,
) {
  const {
    endpointName,
    serviceName,
    attributeName,
    width = [200, 250, 250, 'auto', 'auto'],
    description,
    specification,
    model,
  } = props
  let display = description
  if (!display && model) {
    const modelAttribute = findModelServiceEndointAttributeByName(
      model,
      serviceName as OR_1P1_REST_ROSTERING_CONSUMER_SERVICES,
      endpointName,
      attributeName,
    )
    display = modelAttribute ? modelAttribute.description : null
  }
  if (!display) {
    const specAttribute = findSpecServiceEndointAttributeByName(
      specification,
      serviceName as OR_1P1_REST_ROSTERING_CONSUMER_SERVICES,
      endpointName,
      attributeName,
    )

    if (!specAttribute) {
      return null
    }
    display = specAttribute.description
  }
  return (
    <ListPlainTextCell
      py={isPrint ? 1 : 3}
      wordBreak="break-word"
      overflow="auto"
      width={width}
    >
      <SpecificationDescription description={display} />
    </ListPlainTextCell>
  )
}
