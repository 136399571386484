import React from 'react'
import * as R from 'ramda'

import { View } from 'lib/components'
import IOrg, { IPreferredPartner } from 'domains/orgs/models/IOrg'
import PPListRow from 'domains/orgs/components/PPListRow'

interface IProps {
  org: IOrg
}

export default function OrganizationPPList(props: IProps) {
  const preferrers = R.pathOr(
    [],
    ['org', 'preferrers'],
    props,
  ) as IPreferredPartner[]

  return (
    <View data-test={`org-${props.org.id}-pp-list`}>
      {preferrers.map((pp, index) => (
        <PPListRow
          key={`org-${props.org.id}-preferred-partner-${index}`}
          preferredPartner={pp}
        />
      ))}
    </View>
  )
}
