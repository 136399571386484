import React from 'react'
import clsx from 'clsx'
import { TextArea, Input, View, Text, FormErrorMessage } from 'lib/components'

interface IProps {
  label: string
  name: string
  onChange: any
  onBlur: any
  value: string
  placeholder: string
  'aria-label': string
  isTextArea?: boolean
  customInput?: any
  noWidth100?: boolean
  labelProps?: any
  showErrorImmediately?: boolean
}

export default function ProductModelDetailInput(props: IProps) {
  const InputComponent = (props.isTextArea ? TextArea : Input) as any
  const extraProps = props.isTextArea
    ? { variant: 'bold', minHeight: '150px' }
    : {}
  return (
    <View flexible="column" width={props.noWidth100 ? 'auto' : '100%'} my={3}>
      <Text fontWeight={700} mb={3} {...(props.labelProps || {})}>
        {props.label}
      </Text>
      {props.customInput && props.customInput}
      {!props.customInput && (
        <View flexible="column-h-center" width="100%">
          <InputComponent
            type="text"
            width="100%"
            placeholder={props.placeholder}
            name={props.name}
            onChange={props.onChange}
            // onBlur={props.onBlur}
            value={props.value}
            {...extraProps}
            aria-label={props['aria-label']}
          />
        </View>
      )}

      <div className={clsx('mt-2', { 'ml-4': !!props.labelProps })}>
        <FormErrorMessage
          name={props.name}
          showImmediately={props.showErrorImmediately}
        />
      </div>
    </View>
  )
}
