import React, { PureComponent, MouseEvent } from 'react'

import { Button, View } from 'lib/components'
import IComparison from 'domains/comparisons/models/IComparison'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  comparison: IComparison
  isOpen: boolean
  onClick: (column: IComparison) => void
}
export default class ComparisonNotesCell extends PureComponent<IProps> {
  onClick = (event: MouseEvent) => {
    event.stopPropagation()
    this.props.onClick(this.props.comparison)
  }

  render() {
    const { comparison, isOpen } = this.props
    const hasNotes = utils.hasValue(comparison.notes)
    return (
      <View flexible="column-center">
        <Button
          variant={isOpen ? 'info' : hasNotes ? 'start' : 'complete'}
          onClick={this.onClick}
          data-test={`${comparison.id}-view-edit-notes`}
          width={100}
        >
          {isOpen ? 'close' : hasNotes ? 'view' : 'add a note'}
        </Button>
      </View>
    )
  }
}
