import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'

import { View, Image } from 'lib/components'
import BaseBreadCrumbs from 'domains/application/components/BaseBreadCrumbs'
import PublicProductsWidget from 'domains/dashboard/components/PublicProductsWidget'
import RecentProductsWidget from 'domains/dashboard/components/RecentProductsWidget/RecentProductsWidget'
import ModelingNeededWidget from 'domains/dashboard/components/ModelingNeededWidget'
import DashboardWrapper from 'domains/dashboard/components/DashboardWrapper'
import ComparisonCountWidget from 'domains/dashboard/components/ComparisonCountWidget'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import { getRequest } from 'lib/api/api'
import TotalProductsWidget from 'domains/dashboard/components/TotalProductsWidget'
import ORCheckDashboardRecentComparisonsWidget from 'domains/dashboard/components/ORCheckDashboardRecentComparisonsWidget'
import { ISupplierORCDashboardStatistics } from 'domains/dashboard/models/IDashboardStatistics'

export default function SupplierORCheckDashboardScreen(
  props: RouteComponentProps,
) {
  const [statistics, setStatistics] = useState<ISupplierORCDashboardStatistics>(
    {
      comparisonCount: 0,
      productCount: 0,
      publicProductCount: 0,
      ccInvestigationCount: 0,
      validCCInvestigationCount: 0,
      recentProducts: [],
      recentComparisons: [],
      productsNeedingModelling: [],
      recentCcInvestigations: [],
    },
  )

  useEffect(() => {
    const fetch = async () => {
      const response = await getRequest('statistics/supplier')
      setStatistics(response.data)
    }
    fetch()
  }, [])

  return (
    <View variant="screen">
      <DocumentTitle title="OneRoster Check" />
      <BaseBreadCrumbs crumbs={[{ name: 'OneRoster Check' }]} />
      <View flex={1} width="100%">
        <DashboardWrapper
          row1Widgets={[
            <View key="logo" flexible="column-center">
              <Image
                key="logo"
                src="/images/ORLogo.png"
                width="auto"
                height="80px"
              />
            </View>,
            <PublicProductsWidget
              key="publicProducts"
              publicProductCount={statistics.publicProductCount}
              fadeDelay={100}
            />,
            <TotalProductsWidget
              key="totalProducts"
              productCount={statistics.productCount}
              fadeDelay={200}
            />,
            <ComparisonCountWidget
              key="comparisons"
              fadeDelay={300}
              comparisonCount={statistics.comparisonCount}
            />,
          ]}
          row2Widgets={[
            <RecentProductsWidget
              key="recentProducts"
              recentProducts={statistics.recentProducts}
              history={props.history}
              fadeDelay={500}
            />,
            <ORCheckDashboardRecentComparisonsWidget
              key="recentComparisons"
              recentComparisons={statistics.recentComparisons}
              fadeDelay={600}
            />,
          ]}
          row3Widgets={[
            <ModelingNeededWidget
              key="modelingNeeded"
              productsNeedingModelling={statistics.productsNeedingModelling}
              fadeDelay={700}
            />,
          ]}
          row3IsLarge={true}
        />
      </View>
    </View>
  )
}
