import * as R from 'ramda'
import React from 'react'
import { utils } from '@ims/1edtech-frontend-common'

import IComparison from 'domains/comparisons/models/IComparison'
import { displaySpecVersion } from 'domains/products/utils/products'
import {
  AnySpecFormatType,
  FORMAT_TO_DISPLAY_FORMAT_BASE_NAME,
  OR_FORMAT_TO_SPEC_SUBTYPE,
} from 'domains/formats/constants/formats'
import ComparisonCharacterizationNameCell from 'domains/comparisons/components/ComparisonCharacterizationNameCell'
import ComparisonProductNameCell from 'domains/comparisons/components/ComparisonProductNameCell'
import ComparisonCompatibilityCell from 'domains/comparisons/components/ComparisonCompatibilityCell'
import ComparisonProductLogoNameCell from 'domains/comparisons/components/ComparisonProductLogoNameCell'
import ComparisonDistrictPropCell from 'domains/comparisons/components/ComparisonDistrictPropCell'
import ComparisonRosteringMethodCell from 'domains/comparisons/components/ComparisonRosteringMethodCell'
import ComparisonNotesCell from 'domains/comparisons/components/ComparisonNotesCell'
import { IListColumnDefinition } from 'lib/components/List'
import ComparisonStatusCell from 'domains/comparisons/components/ComparisonStatusCell'

const NAME_CELL_LENGTHS = [170, 170, 170, 290]
const NAME_CELL_TEXT_LENGTHS = [125, 125, 125, 245]
export const comparisonListCharacterizationCell: IListColumnDefinition = {
  title: 'Characterization',
  CellComponent: ComparisonCharacterizationNameCell,
  maxWidth: NAME_CELL_LENGTHS,
  cellProps: { maxWidthArray: NAME_CELL_TEXT_LENGTHS },
  sortKey: utils.sort.addSortValue('characterizationName@'),
}
export const comparisonListDistrictProductCell: IListColumnDefinition = {
  title: 'Product',
  noHeaderCenter: true,
  noCenter: true,
  CellComponent: ComparisonProductLogoNameCell,
  maxWidth: NAME_CELL_LENGTHS,
  cellProps: { maxWidthArray: NAME_CELL_TEXT_LENGTHS },
  sortKey: utils.sort.addSortValue('productName@'),
}
export const comparisonListSupplierProductCell: IListColumnDefinition = {
  title: 'Product',
  noHeaderCenter: true,
  noCenter: true,
  CellComponent: ComparisonProductNameCell,
  maxWidth: [160, 160, 160, 300],
  cellProps: { maxWidthArray: [140, 140, 140, 270] },
  sortKey: utils.sort.addSortValue('productName@'),
}
export const comparisonListDistrictNameCell = {
  title: 'District',
  noHeaderCenter: true,
  noCenter: true,
  CellComponent: ComparisonDistrictPropCell,
  maxWidth: [160, 160, 160, 300],
  cellProps: {
    fallbackProp: 'organizationName',
    prop: 'districtName',
  },
  sortKey: utils.sort.addSortValue('districtName@'),
}
export const comparisonListDistrictStateCell = {
  title: 'State',
  noHeaderCenter: true,
  noCenter: true,
  CellComponent: ComparisonDistrictPropCell,
  maxWidth: [90],
  cellProps: {
    maxWidthArray: [70],
    prop: 'districtState',
  },
  noSort: true,
}
export const comparisonListRosteringMethodCell: IListColumnDefinition = {
  title: 'Rostering Method',
  noHeaderCenter: true,
  noCenter: true,
  CellComponent: ComparisonRosteringMethodCell,
  maxWidth: 160,
  cellProps: {
    maxWidth: 150,
  },
  sortKey: utils.sort.addSortValue('rosteringTool@,productName:ascending'),
}
export const comparisonListStatusCell: IListColumnDefinition = {
  title: 'Share',
  forceCenter: true,
  CellComponent: ComparisonStatusCell,
  maxWidth: 86,
  noSort: true,
  shouldRender: (comparison: IComparison) => !comparison.zombie,
}
export const defaultComparisonListColumns: IListColumnDefinition[] = [
  {
    title: 'Mode',
    maxWidth: 64,
    noHeaderCenter: true,
    noCenter: true,
    accessor: (comparison) =>
      OR_FORMAT_TO_SPEC_SUBTYPE[
        R.propOr('', 'format', comparison) as AnySpecFormatType
      ],
    noSort: true,
  },
  {
    title: 'Ver',
    maxWidth: 36,
    noHeaderCenter: true,
    noCenter: true,
    accessor: (item) =>
      displaySpecVersion(R.prop('specificationVersion', item)),
    noSort: true,
  },
  {
    title: 'Format',
    maxWidth: 64,
    noHeaderCenter: true,
    noCenter: true,
    accessor: (item) =>
      FORMAT_TO_DISPLAY_FORMAT_BASE_NAME[
        R.prop('format', item) as AnySpecFormatType
      ],
    noSort: true,
  },
  // {
  //   title: 'Type',
  //   maxWidth: 42,
  //   noHeaderCenter: true,
  //   noCenter: true,
  //   accessor: item => FORMAT_TYPE_TO_DISPLAY_NAME[R.prop('format', item)],
  //   noSort: true,
  // },
  {
    title: 'Created',
    maxWidth: 136,
    noHeaderCenter: true,
    noCenter: true,
    accessor: (item) =>
      utils.date.formatForDisplay(R.propOr(0, 'comparisonRunDate', item)),
    sortKey: utils.sort.addSortValue('comparisonDate@'),
  },
  {
    title: 'Compatibility',
    maxWidth: 56,
    forceCenter: true,
    CellComponent: ComparisonCompatibilityCell,
    getColumnProps: (comparison: IComparison) => ({
      colSpan: comparison.zombie ? 3 : 1,
    }),
    sortKey: utils.sort.addSortValue('compatibility@'),
  },
]

export const getComparisonListNotesCell = (
  OpenTableRows: any,
  onToggleNotes: (comparison: IComparison) => void,
): IListColumnDefinition => ({
  title: 'Notes',
  maxWidth: 100,
  forceCenter: true,
  CellComponent: (props: any) => {
    const comparison = props as IComparison
    return (
      <ComparisonNotesCell
        comparison={comparison}
        onClick={onToggleNotes}
        isOpen={R.propOr(false, comparison.id.toString(), OpenTableRows)}
      />
    )
  },
  shouldRender: (comparison: IComparison) => !comparison.zombie,
  noSort: true,
})
