import React from 'react'

import { Text } from 'lib/components'
import IProduct from 'domains/products/models/IProduct'
import { FORMAT_TO_DISPLAY_SHORTEST_NAME } from 'domains/formats/constants/formats'
import { getFirstSupportedProductFormat } from 'domains/products/utils/getFirstSupportedProductFormat'
import ProductCatalogExpandedRowCell from 'domains/products/components/ProductCatalog/ProductCatalogExpandedRowCell'

interface IProps extends IProduct {
  isSubRow: boolean
  isSingleProduct: boolean
  isOpen?: boolean
}

export default function SupplierProductCatalogFormatCell(props: IProps) {
  let format = props.format
  if (!format) {
    format = getFirstSupportedProductFormat(props)
  }
  return (
    <ProductCatalogExpandedRowCell {...props}>
      <Text fontSize={13} color={props.isOpen ? 'white' : 'text'}>
        {format ? FORMAT_TO_DISPLAY_SHORTEST_NAME[format] : 'Unknown'}
      </Text>
    </ProductCatalogExpandedRowCell>
  )
}
