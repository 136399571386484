import { utils } from '@ims/1edtech-frontend-common'

import { AnySpecFormatType } from 'domains/formats/constants/formats'
import { getRequest } from 'lib/api/api'

export const getModelsForFormat = async (
  format: AnySpecFormatType,
  search?: string,
) => {
  let filter = `formatDesc=${format}`
  if (utils.hasValue(search)) {
    filter = `${filter} AND productName=~${search}`
  }
  return await getRequest(`models/summaries`, {
    filter,
    sort: 'productName:ascending',
  })
}
