import React from 'react'
import { useSelector } from 'react-redux'

import { isDistrictRoleSelector } from 'domains/authentication/selectors/roles'
import BaseORRosteringCharacterizationsBreadCrumbs from 'domains/characterizations/components/BaseORRosteringCharacterizationsBreadCrumbs'
import ORCharacterizationsScreen from 'domains/characterizations/screens/ORCharacterizationsScreen'
import OrgAction from 'lib/components/OrgActions'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'

function ORCharacterizationsNotAllowed(props: any) {
  return (
    <div className="mx-4" data-test="not-allowed-run_characterisation">
      <DocumentTitle title="Characterizations" />
      <BaseORRosteringCharacterizationsBreadCrumbs />
      <div className="bg-locked px-4 md:px-12 lg:px-40 py-12 md:py-60 rounded shadow-lg flex flex-col flex-1 w-full items-center justify-center">
        <i className="fas fa-lock text-white text-6xl lg:text-9xl text-center" />

        <p className="mt-8 text-white text-lg text-center">
          To run district characterizations you must be{' '}
          <span className="font-bold">Compatibility Check Certified</span>.
        </p>
        <p className="text-white text-base text-center">
          For more information visit{' '}
          <a
            className="text-secondary"
            href="https://www.imsglobal.org/standardsfirst/compatibilitycheckcertification"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.imsglobal.org/standardsfirst/compatibilitycheckcertification
          </a>
        </p>
      </div>
    </div>
  )
}

export default function ORCharacterizationsScreenWrapper(props: any) {
  const isDistrict = useSelector(isDistrictRoleSelector)
  if (isDistrict) {
    return <ORCharacterizationsScreen {...props} />
  }

  return (
    <OrgAction
      action="run_characterisation"
      notAllowed={ORCharacterizationsNotAllowed}
    >
      <ORCharacterizationsScreen {...props} />
    </OrgAction>
  )
}
