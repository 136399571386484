import React from 'react'

interface IProps {
  children: any
}

export default function TrustedAppListItem(props: IProps) {
  return (
    <div
      className="border flex flex-row justify-between mb-3 px-4 py-4"
      style={{ background: '#F7F7F7' }}
    >
      {props.children}
    </div>
  )
}
