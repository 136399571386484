import * as R from 'ramda'

import IRestCModelServiceEndpoint from 'domains/modeling/models/IRestCModelServiceEndpoint'
import {
  OR_1P1_REST_ROSTERING_CONSUMER_SERVICES,
  SERVICE_PARENT_ENDPOINT_MAP,
  SERVICE_ENDPOINTS_MAP,
} from 'domains/modeling/constants/restCOneRoster1p1'
import { findModelServiceEndointByName } from 'domains/modeling/utils/restCOR1p1utils'
import IRestCModel from 'domains/modeling/models/IRestCModel'

export interface IServicesChildEndpoints {
  [parentEndpointName: string]: {
    [childEndpointName: string]: IRestCModelServiceEndpoint
  }
}

export const getServicesChildEndpointsMap = (
  model: IRestCModel,
  services: OR_1P1_REST_ROSTERING_CONSUMER_SERVICES[],
): IServicesChildEndpoints => {
  return services.reduce((agg, serviceName) => {
    let serviceChildren = {}
    const parentEndpoint = SERVICE_PARENT_ENDPOINT_MAP[serviceName]
    const children = SERVICE_ENDPOINTS_MAP[serviceName].filter(
      (eName) => eName !== parentEndpoint,
    )
    for (const childEndpoint of children) {
      const modelEndpoint = findModelServiceEndointByName(
        model,
        serviceName as OR_1P1_REST_ROSTERING_CONSUMER_SERVICES,
        childEndpoint,
      )!
      serviceChildren = R.assoc(
        modelEndpoint.name,
        modelEndpoint,
        serviceChildren,
      )
    }
    return {
      ...agg,
      [parentEndpoint]: serviceChildren,
    }
  }, {})
}
