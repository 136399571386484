import React from 'react'
import * as R from 'ramda'
import { useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'

import { initialize } from 'domains/application/workflows/initialize'
import AppLoadingScreen from 'domains/application/screens/AppLoadingScreen'
import AppScreen from 'domains/application/screens/AppScreen'
import { logComponentDidCatch } from 'lib/errorTracking'
import { applicationPropSelector } from 'domains/application/selectors/application'
import GenericError from 'domains/application/components/GenericError'
import LockedOutScreen from 'domains/application/screens/LockedOutScreen'
import { utils } from '@ims/1edtech-frontend-common'
import { history } from 'lib/utils/navigation'
import {
  EMBEDDABLE_TRUSTED_APPS_ROUTE,
  FRESHDESK_JWT_LOGIN,
  LTI_LOGIN_REDIRECT_ROUTE,
} from 'domains/application/navigation/routes'
import IUser from 'domains/users/models/IUser'
import { meSelector } from 'domains/authentication/selectors/me'
import {
  TA_TEACHER_VIEWER_ROLE,
  TA_VIEWER_ROLE,
} from 'domains/users/constants/userRoles'
import { goToTeacherDashboard } from 'domains/trustedApps/workflows/goToTeacherDashboard'
import EmbeddableTrustedAppsScreen from 'domains/trustedApps/screens/EmbeddableTrustedAppsScreen'
import { Route, Router, matchPath } from 'react-router-dom'

export default function StartupScreen() {
  const initialized = useSelector(applicationPropSelector('initialized'))
  const lockedOut = useSelector(applicationPropSelector('lockedOut'))
  const me = useSelector(meSelector) as null | IUser

  const [error, setError] = React.useState<string | boolean>(false)
  const onError = (error: any, errorInfo: any) => {
    logComponentDidCatch(error, errorInfo)
    const errorInfoString =
      process.env.NODE_ENV !== 'production'
        ? errorInfo
          ? `ERRORINFO: ${R.propOr('', 'componentStack', errorInfo)}`
          : 'No error info'
        : ''
    setError(`ERROR: ${error} \n\n${errorInfoString}`)
  }

  const onLTILogin = window.location.pathname.includes(LTI_LOGIN_REDIRECT_ROUTE)
  const onFreshdeskJWTogin =
    window.location.pathname.includes(FRESHDESK_JWT_LOGIN)

  const onEmbeddableTrustedApps = !!matchPath(
    window.location.pathname,
    EMBEDDABLE_TRUSTED_APPS_ROUTE,
  )

  React.useEffect(() => {
    const fetch = async () => {
      if (!onLTILogin && !onEmbeddableTrustedApps) {
        await initialize()
      }
    }

    fetch()
  }, []) // eslint-disable-line

  React.useEffect(() => {
    if (
      me &&
      ((me.userRole?.length === 1 &&
        (me.userRole[0] === TA_VIEWER_ROLE ||
          me.userRole[0] === TA_TEACHER_VIEWER_ROLE)) ||
        me.lTIRole === 'trustedappsuser')
    ) {
      goToTeacherDashboard()
    }
  }, [me])

  if (error) {
    return <GenericError noScreenWrapper={true} />
  }

  if (onEmbeddableTrustedApps) {
    return (
      <Router history={history}>
        <Route
          path={EMBEDDABLE_TRUSTED_APPS_ROUTE}
          render={() => <EmbeddableTrustedAppsScreen />}
        />
      </Router>
    )
  }

  if (!initialized && !onLTILogin && !onFreshdeskJWTogin) {
    return (
      <Sentry.ErrorBoundary showDialog={false} onError={onError}>
        <AppLoadingScreen />
      </Sentry.ErrorBoundary>
    )
  }

  return (
    <Sentry.ErrorBoundary showDialog={false} onError={onError}>
      {utils.hasValue(lockedOut) ? <LockedOutScreen /> : <AppScreen />}
    </Sentry.ErrorBoundary>
  )
}
