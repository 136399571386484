import React from 'react'

import IFileSetCharacterizationColumn from 'domains/characterizations/models/IFileSetCharacterizationColumn'
import { ListPlainTextCell } from 'lib/components'
import { findSpecColumn } from 'domains/modeling/selectors/csvCModelToSpec'
import ICSVSpecification from 'domains/specifications/models/ICSVSpecification'
import { findModelFileColumn } from 'domains/modeling/utils/findModelDetails'
import ICSVConsumerModel from 'domains/modeling/models/ICSVConsumerModel'

interface IProps extends IFileSetCharacterizationColumn {
  fileLabel: string
  specification: ICSVSpecification
  model?: ICSVConsumerModel
}

export default function CharacterizationColumnMultiplicityCell(props: IProps) {
  const { fileLabel, model, multiplicity, specification, headerName } = props
  let display = multiplicity
  if (!display && model) {
    const modelColumn = findModelFileColumn(model, fileLabel, headerName)
    display = modelColumn ? modelColumn.multiplicity : undefined
  }
  if (!display) {
    const specColumn = findSpecColumn(specification, fileLabel, headerName)
    display = specColumn ? specColumn.multiplicity : ''
  }
  return <ListPlainTextCell>{display}</ListPlainTextCell>
}
