import React from 'react'
import * as R from 'ramda'
import { RouteComponentProps } from 'react-router'
import { utils } from '@ims/1edtech-frontend-common'

import { Spinner } from 'lib/components'
import trackCharacterizationAnalytics from 'domains/characterizations/utils/trackCharacterizationAnalytics'
import ITransformedCharacterizationCSVFile from 'domains/characterizations/models/ITransformedCharacterizationCSVFile'
import OR1P1CSVCharacterizationReportUnavailable from 'domains/characterizations/components/DistrictCharacterizationDetails/OR1P1CSVCharacterizationReportUnavailable'
import CharacterizationReportFileSetErrors from 'domains/characterizations/components/CharacterizationReportFileSetErrors'
import useFullCSVPCharacterization from 'domains/characterizations/hooks/useFullCSVPCharacterization'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import ITransformedCharacterizationCSVReport from 'domains/characterizations/models/ITransformedCharacterizationCSVReport'
import GenericError from 'domains/application/components/GenericError'
import usePrintOnMountEffect from 'lib/hooks/usePrintOnMountEffect'
import PrintPageWrapper from 'domains/printing/components/PrintPageWrapper'
import PrintPageHeader from 'domains/printing/components/PrintPageHeader'
import PrintCharacterizationTopDetails from 'domains/characterizations/components/CharacterizationReport/PrintCharacterizationTopDetails'
import PrintOR1P1CSVCharacterizationReportFile from 'domains/characterizations/components/DistrictCharacterizationDetails/print/PrintOR1P1CSVCharacterizationReportFile'

export default function PrintOR1P1CSVProviderCharacterizationReportScreen(
  props: RouteComponentProps,
) {
  const [
    characterizationData,
    reportData,
    transformedReportData,
    specification,
    pending,
  ] = useFullCSVPCharacterization(props)

  React.useEffect(() => {
    if (!pending && utils.hasValue(characterizationData)) {
      trackCharacterizationAnalytics('viewed_print_characterization_report', {
        id: R.pathOr(0, ['id'], characterizationData),
        format: R.pathOr('', ['format'], characterizationData),
        validationId: R.pathOr('', ['content'], characterizationData),
      })
    }
  }, [pending, characterizationData])

  usePrintOnMountEffect(
    pending ||
      !utils.hasValue(characterizationData) ||
      !utils.hasValue(reportData),
  )
  if (
    pending ||
    !utils.hasValue(characterizationData) ||
    !utils.hasValue(reportData)
  ) {
    if (
      !pending &&
      (!utils.hasValue(characterizationData) || !utils.hasValue(reportData))
    ) {
      return <GenericError />
    }
    return <Spinner centered={true} />
  }

  const transformedReport = transformedReportData as ITransformedCharacterizationCSVReport
  const characterization = characterizationData as ICharacterization
  const files = R.pathOr<ITransformedCharacterizationCSVFile[]>(
    [],
    ['files'],
    transformedReportData,
  )

  return (
    <PrintPageWrapper>
      <PrintPageHeader title="Characterization Details - Full Report" />
      <PrintCharacterizationTopDetails
        characterization={characterization}
        report={transformedReport}
      />
      <OR1P1CSVCharacterizationReportUnavailable report={transformedReport} />
      <CharacterizationReportFileSetErrors report={transformedReport} />

      <div className="my-3">
        {transformedReport.hasFileErrors && (
          <p className="italic text-base">
            A maximum of 20 critical errors will be shown per file. Please
            resolve and re-characterize to see any additional errors.
          </p>
        )}

        {files.map((file, index) => (
          <PrintOR1P1CSVCharacterizationReportFile
            key={`report-file-${index}`}
            file={file}
            report={transformedReport}
            specification={specification}
          />
        ))}
      </div>
    </PrintPageWrapper>
  )
}
