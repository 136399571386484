import * as normalizr from 'normalizr'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

import { getCCLomAndDisclosures } from 'lib/records/modules/commonCartridgeCharacterizationReports'

export const COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_ITEMS_RECORD =
  'commonCartridgeCharacterizationReportItems'

const commonCartridgeCharacterizationReportItems: records.modules.IModule = {
  record: COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_ITEMS_RECORD,
  schema: new normalizr.schema.Entity(
    COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_ITEMS_RECORD,
    {},
    {
      processStrategy: R.compose((item) => {
        const [lom, disclosures] = getCCLomAndDisclosures(item)
        const [resourceLom, resourceDisclosures] = getCCLomAndDisclosures(
          R.pathOr({}, ['resource'], item),
        )
        return {
          ...item,
          lom,
          disclosures,
          resource: R.has('resource', item)
            ? {
                ...item.resource,
                lom: resourceLom,
                disclosures: resourceDisclosures,
              }
            : undefined,
        }
      }, records.modules.utils.fixIdAttribute),
      idAttribute: 'id',
    },
  ),
  api: {
    create: () => '',
    get: (id) => `ccsys/items/${id}`,
    getList: (params, parentId) => {
      const isCartridge = R.propOr(false, 'isCartridge', params)
      if (isCartridge) {
        return `ccsys/cartridges/${parentId}/items`
      }
      return `ccsys/items/${parentId}/items`
    },
    update: () => '',
    delete: () => '',
  },
}

export default commonCartridgeCharacterizationReportItems
