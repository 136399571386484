import React from 'react'
import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'

import { Screen, View, Spinner } from 'lib/components'
import BaseCommonCartridgeCharacterizationsBreadCrumbs from 'domains/characterizations/components/BaseCommonCartridgeCharacterizationsBreadCrumbs'
import useFullCommonCartridgeCharacterization from 'domains/commonCartridge/hooks/useFullCommonCartridgeCharacterization'
import GenericError from 'domains/application/components/GenericError'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import CCCharcterizationReportDetailsWidget from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharcterizationReportDetailsWidget'
import CCCharcterizationReportStatusWidget from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharcterizationReportStatusWidget'
import ICommonCartridgeCharacterizationReport from 'domains/commonCartridge/models/ICommonCartridgeCharacterizationReport'
import CCCharcterizationReportToCPreview from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharcterizationReportToCPreview'
import CCCharcterizationReportStandardsPreview from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharcterizationReportStandardsPreview'
import CCCharacterizationReportLOMDropdowns from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/CCCharacterizationReportLOMDropdowns'
import ReportThreeTopSection from 'domains/reports/components/ReportThreeTopSection'
import CharacterizationsDetailsWidget from 'domains/characterizations/components/DistrictCharacterizationDetails/CharacterizationsDetailsWidget'
import DebugReportView from 'domains/reports/components/DebugReportView'
import CCCharacterizationReportValidations from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportValidations'
import CCCharcterizationReportResourcesPreview from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharcterizationReportResourcesPreview'
import {
  getValidationsWithErrorsOrWarnings,
  doesCCReportHaveErrors,
} from 'domains/commonCartridge/utils/validations'
import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'
import CharacterizationNotesPaper from 'domains/characterizations/components/CharacterizationReport/CharacterizationNotesPaper'

const TOP_WIDGETS_HEIGHT = 340

export default function CommonCartridgeCharacterizationReportScreen(
  props: any,
) {
  const [showMissingMD, setShowMissingMD] = React.useState(false)
  const [
    characterizationData,
    reportData,
    pending,
  ] = useFullCommonCartridgeCharacterization(props)

  if (
    pending ||
    !utils.hasValue(characterizationData) ||
    !utils.hasValue(reportData)
  ) {
    if (
      !pending &&
      (!utils.hasValue(characterizationData) || !utils.hasValue(reportData))
    ) {
      return <GenericError />
    }
    return (
      <View variant="screen">
        <Spinner centered={true} />
      </View>
    )
  }

  const characterization = characterizationData as ICharacterization
  const characterizationName = R.pathOr('', ['name'], characterization)
  const report = reportData as ICommonCartridgeCharacterizationReport
  const hasErrors = doesCCReportHaveErrors(report)
  const validationsWithErrorsOrWarnings = getValidationsWithErrorsOrWarnings(
    report,
  )

  return (
    <ShowMissingMetadataContext.Provider
      value={{
        showMissingMetadata: showMissingMD,
        setShowMissingMetadata: setShowMissingMD,
        disclosures: report.disclosures,
      }}
    >
      <Screen>
        <BaseCommonCartridgeCharacterizationsBreadCrumbs
          crumbs={[{ name: utils.string.ellipsize(characterizationName, 30) }]}
        />

        <ReportThreeTopSection>
          <CharacterizationsDetailsWidget
            title="Cartridge Details"
            characterization={characterization}
            hideDetails={true}
            height={TOP_WIDGETS_HEIGHT}
          />

          <CCCharcterizationReportDetailsWidget
            characterization={characterization}
            report={report}
            height={TOP_WIDGETS_HEIGHT}
          />

          <CCCharcterizationReportStatusWidget
            characterization={characterization}
            height={TOP_WIDGETS_HEIGHT}
          />
        </ReportThreeTopSection>

        <CharacterizationNotesPaper characterization={characterization} />

        {utils.hasValue(validationsWithErrorsOrWarnings) && (
          <View mt={3}>
            <CCCharacterizationReportValidations report={report} />
          </View>
        )}

        {!hasErrors && (
          <View>
            <View mt={3}>
              <CCCharcterizationReportToCPreview
                characterization={characterization}
                report={report}
              />
            </View>

            <View
              mt={3}
              display="flex"
              flexDirection={['column', 'column', 'row']}
            >
              <View flex={1} maxWidth={['100%', '100%', '50%']}>
                <CCCharcterizationReportStandardsPreview
                  report={report}
                  characterization={characterization}
                />
              </View>

              <View width={[0, 0, 16]} height={[16, 16, 0]} />

              <View flex={1} maxWidth={['100%', '100%', '50%']}>
                <CCCharcterizationReportResourcesPreview
                  report={report}
                  characterization={characterization}
                />
              </View>
            </View>

            <View mt={3}>
              <CCCharacterizationReportLOMDropdowns
                title="Manifest Metadata"
                lom={report.lom}
                type="Metadata"
                parentId={report.id}
              />
            </View>
          </View>
        )}

        <DebugReportView rawReport={reportData} title="Cartidge JSON" />
      </Screen>
    </ShowMissingMetadataContext.Provider>
  )
}
