import React from 'react'
import * as R from 'ramda'

import { View, Text } from 'lib/components'
import { ICommonCartridgeLanguageText } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeLanguageText'
import { ICommonCartridgeSourceValue } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeSourceValue'
import { ICommonCartridgeCatalogEntry } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeCatalogEntry'
import { ICommonCartridgeLOMRelation } from 'domains/commonCartridge/models/lom/ICommonCartridgeLOMRelation'
import CCCharacterizationReportLOMSourceValue from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMSourceValue'
import CCCharacterizationReportLOMDetail from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMDetail'
import CCCharacterizationReportLOMCatalogEntry from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMCatalogEntry'
import CCCharacterizationReportLOMLanguageText from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMLanguageText'
import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  path: string
  data: ICommonCartridgeLOMRelation
}

export default function CCCharacterizationReportLOMRelation(props: IProps) {
  const { showMissingMetadata } = React.useContext(ShowMissingMetadataContext)
  const kind = R.pathOr({}, ['kind'], props.data) as ICommonCartridgeSourceValue
  const resource = R.pathOr({}, ['resource'], props.data) as any
  const identifiers = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['identifier'],
    resource,
  ) as ICommonCartridgeCatalogEntry[]
  const descriptions = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['description'],
    resource,
  ) as ICommonCartridgeLanguageText[]

  return (
    <View>
      <CCCharacterizationReportLOMSourceValue
        path={`${props.path}.kind`}
        title="kind"
        data={kind}
      />

      <CCCharacterizationReportLOMDetail
        path={`${props.path}.resource`}
        title="resource"
        value={
          <View>
            {identifiers.map((identifier, i) => (
              <View mt={2} key={`${props.path}.resource.identifier.${i}`}>
                <Text fontWeight={700}>identifier [{i + 1}]:</Text>
                <View ml={3}>
                  <CCCharacterizationReportLOMCatalogEntry
                    path={
                      !utils.hasValue(identifier)
                        ? `${props.path}.resource.identifier`
                        : `${props.path}.resource.identifier.${i}`
                    }
                    data={identifier}
                  />
                </View>
              </View>
            ))}

            {descriptions.map((description, i) => (
              <View mt={2} key={`${props.path}.resource.description.${i}`}>
                <Text fontWeight={700}>description [{i + 1}]:</Text>
                <View ml={3}>
                  <CCCharacterizationReportLOMLanguageText
                    path={
                      !utils.hasValue(description)
                        ? `${props.path}.resource.description`
                        : `${props.path}.resource.description.${i}`
                    }
                    data={description}
                  />
                </View>
              </View>
            ))}
          </View>
        }
      />
    </View>
  )
}
