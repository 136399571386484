import {
  ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
  ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
} from 'domains/formats/constants/formats'
import ProductModelingRestEditScreen from 'domains/modeling/screens/ProductModelingRestEditScreen'
import ProductModelingCsvCEditScreen from 'domains/modeling/screens/ProductModelingCsvCEditScreen'
import useFormatScreen from 'lib/hooks/useFormatScreen'

export default function ProductModelingScreen(props: any) {
  return useFormatScreen({
    ...props,
    map: {
      [ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL]: ProductModelingRestEditScreen,
      [ONER_V1P1_CSV_ROSTERING_IMPORT_BULK]: ProductModelingCsvCEditScreen,
    },
  })
}
