import { records } from '@ims/1edtech-frontend-common'

import IProduct from 'domains/products/models/IProduct'
import { AnySpecFormatType } from 'domains/formats/constants/formats'
import { getProductFormatByName } from 'domains/products/utils/products'

export const getProductFormatCharacterizationlId = (
  product: IProduct,
  format: AnySpecFormatType,
) => {
  const productFormat = getProductFormatByName(format)(product)
  return records.modules.getRefId(productFormat, 'characterization')
}
