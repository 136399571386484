import React from 'react'
import * as R from 'ramda'

import IRestCModelServiceEndpointAttribute from 'domains/modeling/models/IRestCModelServiceEndpointAttribute'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import IRestCModelService from 'domains/modeling/models/IRestCModelService'
import IRestCModelServiceEndpoint from 'domains/modeling/models/IRestCModelServiceEndpoint'
import useRestCSpecServiceEndpointAttributeCell from 'domains/modeling/hooks/useRestCSpecServiceEndpointAttributeCell'
import DeleteButton from 'lib/components/DeleteButton'
import Dialog from 'domains/application/modals/Dialog'
import TextWithBoldHighlight from 'lib/components/TextWithBoldHighlight'
import { trackRestCModelingAnalytics } from 'domains/modeling/utils/trackModelingAnalytics'

interface IProps extends IRestCModelServiceEndpointAttribute {
  specification: IRestCSpec
  service: IRestCModelService
  endpoint: IRestCModelServiceEndpoint
  endpointIndex: number
  handleChange: any
  setFieldValue: any
}

export default function RestCAttributeDeleteCell(props: IProps) {
  const { name, metadata } = props

  const [open, setOpen] = React.useState(false)
  const [, attributeIndex, , endpointFormPath] =
    useRestCSpecServiceEndpointAttributeCell(props)

  if (!metadata) {
    return null
  }

  const onClick = () => setOpen(true)
  const close = () => setOpen(false)
  const onDeleteConfirmed = () => {
    trackRestCModelingAnalytics('removed_custom_metadata')
    props.setFieldValue(
      `${endpointFormPath}.attributes`,
      R.remove(attributeIndex as number, 1, props.endpoint.attributes),
    )
  }

  return (
    <>
      <DeleteButton
        onClick={onClick}
        data-test={`delete-metadata-${props.name}`}
      />
      {open && (
        <Dialog
          isOpen={open}
          title="Delete Metadata"
          message={
            <TextWithBoldHighlight
              text="This will permanently remove @@."
              highlight={name}
            />
          }
          icon="fas fa-trash"
          confirmText="Yes, Delete"
          onConfirm={onDeleteConfirmed}
          onDeny={close}
        />
      )}
    </>
  )
}
