import * as R from 'ramda'
import memoize from 'fast-memoize'
import { utils } from '@ims/1edtech-frontend-common'

import {
  OR_1P1_REST_ROSTERING_CONSUMER_SERVICES,
  SERVICE_PARENT_ENDPOINT_MAP,
  PARENT_ENDPOINT_TO_SERVICE_MAP,
  ENDPOINT_FRIENDLY_TO_SHORT_NAME,
  SERVICE_ENDPOINTS_MAP,
  OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES,
} from 'domains/modeling/constants/restCOneRoster1p1'
import IRestCModel from 'domains/modeling/models/IRestCModel'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import IRestCModelService, {
  mockRestCModelService,
} from 'domains/modeling/models/IRestCModelService'
import { mockRestCModelServiceEndpoint } from 'domains/modeling/models/IRestCModelServiceEndpoint'
import { mockRestCModelServiceEndpointAttribute } from 'domains/modeling/models/IRestCModelServiceEndpointAttribute'
import { ITransformedRESTServiceCharacterizationReport } from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import IRestCSpecServiceEndpointAttribute from 'domains/specifications/models/IRestCSpecServiceEndpointAttribute'
import IRestCSpecServiceEndpoint from 'domains/specifications/models/IRestCSpecServiceEndpoint'

export const findModelServiceByName = R.curry(
  (
    model: IRestCModel,
    name:
      | OR_1P1_REST_ROSTERING_CONSUMER_SERVICES
      | OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES,
  ) => R.find((s) => R.propOr('', 'name', s) === name, model.services),
)

export const findSpecServiceByName = R.curry(
  (
    spec: IRestCSpec,
    name:
      | OR_1P1_REST_ROSTERING_CONSUMER_SERVICES
      | OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES,
  ) => R.find((s) => R.propOr('', 'name', s) === name, spec.services),
)

export const findSpecServiceEndointByName = R.curry(
  (
    spec: IRestCSpec,
    serviceName:
      | OR_1P1_REST_ROSTERING_CONSUMER_SERVICES
      | OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES,
    endpointName: string,
  ): IRestCSpecServiceEndpoint | null => {
    const service = findSpecServiceByName(spec, serviceName)
    if (!service) {
      return null
    }

    let endpoint = R.find(R.propEq('name', endpointName), service.endpoints)
    if (!endpoint) {
      endpoint = R.find(
        R.propEq('name', ENDPOINT_FRIENDLY_TO_SHORT_NAME[endpointName]),
        service.endpoints,
      )
      if (!endpoint) {
        return null
      }
    }

    return endpoint as IRestCSpecServiceEndpoint
  },
)

export const findSpecServiceEndointAttributeByName = R.curry(
  (
    spec: IRestCSpec,
    serviceName:
      | OR_1P1_REST_ROSTERING_CONSUMER_SERVICES
      | OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES,
    endpointName: string,
    attributeName: string,
  ): IRestCSpecServiceEndpointAttribute | null => {
    const endpoint = findSpecServiceEndointByName(
      spec,
      serviceName,
      endpointName,
    )
    if (!endpoint) {
      return null
    }

    const attribute = R.find(
      R.propEq('name', attributeName),
      endpoint.attributes,
    )
    if (!attribute) {
      return null
    }

    return attribute
  },
)

export const findModelServiceEndointByName = R.curryN(
  3,
  (
    model: IRestCModel,
    serviceName:
      | OR_1P1_REST_ROSTERING_CONSUMER_SERVICES
      | OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES,
    endpointName: string,
    forceResponse = false,
  ) => {
    const service = findModelServiceByName(model, serviceName)
    if (!service) {
      return forceResponse ? mockRestCModelServiceEndpoint() : null
    }

    const endpoint = R.find(R.propEq('name', endpointName), service.endpoints)
    if (!endpoint) {
      return forceResponse ? mockRestCModelServiceEndpoint() : null
    }

    return endpoint
  },
)

export const findModelServiceEndointAttributeByName = R.curryN(
  3,
  (
    model: IRestCModel,
    serviceName:
      | OR_1P1_REST_ROSTERING_CONSUMER_SERVICES
      | OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES,
    endpointName: string,
    attributeName: string,
    forceResponse = false,
  ) => {
    const endpoint = findModelServiceEndointByName(
      model,
      serviceName,
      endpointName,
    )
    if (!endpoint) {
      return forceResponse ? mockRestCModelServiceEndpointAttribute() : null
    }

    const attribute = R.find(
      R.propEq('name', attributeName),
      endpoint.attributes,
    )
    if (!attribute) {
      return forceResponse ? mockRestCModelServiceEndpointAttribute() : null
    }

    return attribute
  },
)

export const findModelServiceByParentEndpointName = R.curryN(
  2,
  (model: IRestCModel, endpointName: string, forceResponse = false) => {
    const serviceName = PARENT_ENDPOINT_TO_SERVICE_MAP[endpointName]
    const service = findModelServiceByName(model, serviceName as any)
    if (forceResponse) {
      return service || mockRestCModelService()
    }

    return service
  },
)

export const isModelServiceEndpointTouched = R.curry(
  (
    model: IRestCModel,
    serviceName:
      | OR_1P1_REST_ROSTERING_CONSUMER_SERVICES
      | OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES,
    endpointName: string,
  ) => {
    const service = findModelServiceByName(model, serviceName)
    if (!service) {
      return false
    }

    const endpoint = R.find(R.propEq('name', endpointName), service.endpoints)
    if (!endpoint) {
      return false
    }

    return endpoint.touched
  },
)

export const isModelServiceTouched = R.curry(
  (
    model: IRestCModel,
    serviceName:
      | OR_1P1_REST_ROSTERING_CONSUMER_SERVICES
      | OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES,
  ) => {
    const service = findModelServiceByName(model, serviceName)
    if (!service) {
      return false
    }

    return R.all(R.propEq('touched', true), service.endpoints)
  },
)

export const getModelServiceParentEndpoint = R.curry(
  (model: IRestCModel, service: IRestCModelService) => {
    const parentEndpointName = SERVICE_PARENT_ENDPOINT_MAP[service.name]
    return findModelServiceEndointByName(
      model,
      service.name as OR_1P1_REST_ROSTERING_CONSUMER_SERVICES,
      parentEndpointName,
    )!
  },
)

export const getRestCModelProgress = (model: IRestCModel) => {
  const endpointProgress = model.services.reduce(
    (agg, service) => [
      ...agg,
      ...service.endpoints.reduce(
        (eAgg, endpoint) => [
          ...eAgg,
          isModelServiceEndpointTouched(
            model,
            service.name as OR_1P1_REST_ROSTERING_CONSUMER_SERVICES,
            endpoint.name,
          ),
        ],
        [] as boolean[],
      ),
    ],
    [] as boolean[],
  )
  return utils.getPercentage(
    endpointProgress.filter((p) => p === true).length / endpointProgress.length,
  )
}

export const isWholeServiceDisabled = memoize(
  (service: ITransformedRESTServiceCharacterizationReport) =>
    R.all(R.propEq('enabled', false))(service.endpointCharacterizations),
)

export const getServiceParentEndpointCharacterization = memoize(
  (service: ITransformedRESTServiceCharacterizationReport) => {
    const parentEndpoint = SERVICE_PARENT_ENDPOINT_MAP[service.serviceName]
    return R.find(
      R.propEq('endpointName', parentEndpoint),
      service.endpointCharacterizations,
    )
  },
)

export const parentServiceEndpointHasNotes = memoize(
  (service: ITransformedRESTServiceCharacterizationReport) => {
    const parentEndpoint = getServiceParentEndpointCharacterization(service)
    return parentEndpoint && utils.hasValue(parentEndpoint.notes)
  },
)

export const isServiceReportable = memoize(
  (service: ITransformedRESTServiceCharacterizationReport) => {
    const hasAllDisabled = isWholeServiceDisabled(service)
    const parentHasNotes = parentServiceEndpointHasNotes(service)
    return !hasAllDisabled || parentHasNotes
  },
)

interface IHasEndpointName {
  endpointName: string
}
export const sortEndpointsForDisplay = memoize(
  (serviceName: string, endpoints: IHasEndpointName[]) => {
    const orderedEndpoints = SERVICE_ENDPOINTS_MAP[serviceName]
    return orderedEndpoints
      .filter(
        (modelServiceEndpoint) =>
          !!R.find(R.propEq('endpointName', modelServiceEndpoint), endpoints),
      )
      .map(
        (modelServiceEndpoint) =>
          R.find(R.propEq('endpointName', modelServiceEndpoint), endpoints)!,
      )
  },
)
