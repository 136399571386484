import React from 'react'

import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import { goToHelp } from 'domains/application/workflows/goToHelp'
import { Button } from 'lib/components'

interface IProps {
  height: number
}

export default function TrustedAppsLockedConnectionWidget(props: IProps) {
  return (
    <div
      className="bg-locked flex flex-col pt-8 px-8 pb-12 rounded-md shadow-lg overflow-x-auto"
      style={{ height: props.height }}
    >
      <WidgetHeader
        title={
          <h2 className="text-white font-bold text-lg">
            Connect Your Dashboard
          </h2>
        }
      />

      <div className="flex flex-col flex-1 w-full px-6 mt-6 items-center space-y-6">
        <div className="flex flex-col items-center justify-center text-center">
          <i className="fas fa-lock text-white text-2xl lg:text-6xl" />

          <p className="mt-4 text-white text-md lg:text-lg">
            To enable the LTI launch capability for your{' '}
            <span className="font-bold">TrustEd Apps Dashboard</span>, submit a
            new support ticket via the Support portal.
          </p>
        </div>

        <div className="flex flex-col w-full lg:w-96 mx-auto space-y-2">
          <p className="text-white text-md lg:text-lg">
            Complete the ticket fields as follows:
          </p>
          <p className="ml-4 text-white text-base lg:text-md">
            <span className="font-bold italic">Subject: </span>Dashboard
            Connection
          </p>
          <p className="ml-4 text-white text-base lg:text-md">
            <span className="font-bold italic">Type: </span>Connection Request
          </p>
          <p className="ml-4 text-white text-base lg:text-md">
            <span className="font-bold italic">Function: </span>TrustEd Apps
          </p>
          <p className="ml-4 text-white text-base lg:text-md italic">
            <span className="font-bold italic">Description: </span>Include the
            name of the LTI platform and any relevant version information
          </p>
        </div>

        <div className="flex flex-col items-center">
          <p className="text-white text-md lg:text-lg text-center">
            For more information, search for{' '}
            <span className="italic">
              &ldquo;Launch Platform Requirements&rdquo;
            </span>{' '}
            in the{' '}
            <span
              className="font-bold underline cursor-pointer"
              onClick={goToHelp}
            >
              support site.
            </span>
          </p>
        </div>

        <div className="flex justify-center">
          <Button variant="complete" onClick={goToHelp}>
            <p className="text-white text-lg text-center">Create Ticket</p>
          </Button>
        </div>
      </div>
    </div>
  )
}
