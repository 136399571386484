import React from 'react'

import SmallWidget from 'domains/dashboard/components/SmallWidget'

interface IProps {
  publicProductCount: number
  fadeDelay: number
}

export default function PublicProductsWidget(props: IProps) {
  return (
    <SmallWidget
      fadeDelay={props.fadeDelay}
      icon="fas fa-eye"
      iconColor="infoLight"
      title={props.publicProductCount}
      subtitle="Public Products"
      dataTest="public-products-widget"
    />
  )
}
