import React from 'react'

import { View, Text, Icon } from 'lib/components'
import {
  STATUS_TO_BADGE_VARIANT_MAP,
  ReportStatus,
  UNSUPPORTED_REPORT_STATUS,
} from 'domains/reports/models/IReportStatuses'
import { utils } from '@ims/1edtech-frontend-common'
import { CHAR_REPORT_ROW_KEY_WIDTH } from 'domains/characterizations/constants/characterizationReport'
import { ENDPOINT_NAME_TO_FRIENDLY_NAME } from 'domains/modeling/constants/restCOneRoster1p1'
import {
  AnyFormatType,
  ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL,
} from 'domains/formats/constants/formats'

interface IProps {
  format?: AnyFormatType
  endpoint: {
    status: ReportStatus
    endpointName: string
    enabled?: boolean
    isOptional?: boolean
    enabledInCharacterisation?: boolean
    leaf: string
    collectionSize?: number
    sampleSize?: number
    notes?: string
    failedCharacterization?: boolean
  }
  open: boolean
  'data-test'?: string
  useFriendlyName?: boolean
  ignoreDisabledStatus?: boolean
}

export default function RESTEndpointReportHeader(props: IProps) {
  const { endpoint, open } = props
  const status =
    !props.ignoreDisabledStatus &&
    endpoint.enabled === false &&
    !endpoint.failedCharacterization
      ? UNSUPPORTED_REPORT_STATUS
      : endpoint.status

  const isGBPush =
    props.format === ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL
  return (
    <View
      display="flex"
      flexDirection={['column', 'row']}
      alignItems={['flex-start', 'center']}
      justifyContent={['center', 'flex-start']}
      flex={1}
      mt={2}
      p={2}
      minHeight={[48, 64]}
      data-test={
        props['data-test'] || `report-endpoint-header-${endpoint.leaf}`
      }
      data-open={open ? 'open' : 'closed'}
      bg={STATUS_TO_BADGE_VARIANT_MAP[status]}
      data-status={status}
    >
      <View
        flexible="column-v-center"
        height="80%"
        borderRight={['', 'solid 1px rgba(255, 255, 255, 0.5)']}
        pr={3}
        mb={[2, 0]}
        minWidth={CHAR_REPORT_ROW_KEY_WIDTH}
        maxWidth={CHAR_REPORT_ROW_KEY_WIDTH}
      >
        <Text
          color="white"
          fontSize={[14, 20]}
          lineHeight={['18px', '24px']}
          fontWeight={800}
          userSelect="none"
        >
          {props.useFriendlyName
            ? ENDPOINT_NAME_TO_FRIENDLY_NAME[endpoint.endpointName]
            : endpoint.endpointName}
        </Text>
        <Text
          color="white"
          fontSize={[12, 14]}
          userSelect="none"
          wordBreak="break-word"
        >
          {endpoint.leaf}
        </Text>
      </View>

      {utils.hasValue(endpoint.collectionSize) &&
        utils.hasValue(endpoint.sampleSize) && (
          <View flexible="row-v-center" mx={[0, 3]} mb={[2, 0]}>
            <View width={!isGBPush ? [132, 148] : 'auto'}>
              {!isGBPush && (
                <Text color="white" fontWeight={700} fontSize={[12, 14]}>
                  Total Collection Size:
                </Text>
              )}
              <Text color="white" fontWeight={700} fontSize={[12, 14]}>
                Sample Size:
              </Text>
            </View>

            <View ml={1}>
              {!isGBPush && (
                <Text color="white">{endpoint.collectionSize}</Text>
              )}
              <Text color="white">{endpoint.sampleSize}</Text>
            </View>
          </View>
        )}

      <View flex={1} />

      <View width={24}>
        <Icon
          fontSize={24}
          color="white"
          className={`fas rotate-caret fa-arrow-circle-down ${
            open ? 'down' : ''
          }`}
        />
      </View>
    </View>
  )
}
