import * as R from 'ramda'
import { createSelector } from 'reselect'

import { IApplicationState } from 'domains/application/ducks/application'
import { RootState } from 'lib/store/rootReducer'

export const applicationSelector = (state: RootState): IApplicationState =>
  R.propOr({}, 'application', state)

export const applicationPropSelector = (prop: string, fallback?: any): any =>
  createSelector(applicationSelector, R.propOr(fallback, prop))

export const oneRosterCharacterizationFormatsFilterSelector = createSelector(
  applicationPropSelector('oneRosterCharacterizationFormatIds', []),
  R.compose(
    R.join(' OR '),
    R.map((id) => `format=${id}`),
  ),
)

export const commonCartridgeCharacterizationFormatsFilterSelector = createSelector(
  applicationPropSelector('commonCartridgeCharacterizationFormatIds', []),
  R.compose(
    R.join(' OR '),
    R.map((id) => `format=${id}`),
  ),
)
