import { trackDomainAnalytics, getAmplitudeInstance } from 'lib/eventTracking'

export default trackDomainAnalytics('Session')

export const setUserProperties = (userProperties = {}) => {
  if (process.env.NODE_ENV !== 'production') {
    return
  }
  getAmplitudeInstance().setUserProperties(userProperties)
}
