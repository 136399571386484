import * as R from 'ramda'
import { createSelector } from 'reselect'
import { records } from '@ims/1edtech-frontend-common'

import { SPECIFIC_TO_FULL_FORMAT_MAP } from 'domains/formats/constants/formats'
import ICSVSpecification from 'domains/specifications/models/ICSVSpecification'
import { SPECIFICATIONS_RECORD } from 'lib/records/modules/specifications'
import ICSVConsumerModel from 'domains/modeling/models/ICSVConsumerModel'

export const getModelFullSpecNameSelector = (record: string, getId = 'id') =>
  createSelector<any, any, ICSVConsumerModel, string>(
    records.entitiesSelectors.entityByIdSelector(record, getId),
    (model: ICSVConsumerModel) =>
      SPECIFIC_TO_FULL_FORMAT_MAP[
        R.propOr<string, ICSVConsumerModel, string>('', 'format', model)
      ],
  )

export const getModelFullSpecSelector = (record: string, getId = 'id') =>
  createSelector<any, any, string, any, ICSVSpecification>(
    getModelFullSpecNameSelector(record, getId),
    records.entitiesSelectors.entityListSelector(SPECIFICATIONS_RECORD),
    (specName: string, specifications: any) => specifications[specName],
  )
