import { records } from '@ims/1edtech-frontend-common'

import IProduct from 'domains/products/models/IProduct'
import {
  AnyFormatType,
  ANY_FORMAT_TO_SPEC_FORMAT_MAP,
  ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
} from 'domains/formats/constants/formats'
import { getProductFormatByName } from 'domains/products/utils/products'

export const getProductFormatModelId = (
  product: IProduct,
  format: AnyFormatType,
) => {
  const specFormat = ANY_FORMAT_TO_SPEC_FORMAT_MAP[format]
  const productFormat = getProductFormatByName(specFormat)(product)
  let modelKey: string
  switch (specFormat) {
    case ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL:
      modelKey = 'restModel'
      break
    default:
      modelKey = 'csvModel'
      break
  }
  return records.modules.getRefId(productFormat, modelKey)
}
