import React from 'react'

import IOrg from 'domains/orgs/models/IOrg'
import { Icon } from 'lib/components'
import { utils } from '@ims/1edtech-frontend-common'
import OrganizationPPCell from 'domains/orgs/components/OrganizationPPCell'

interface IProps extends IOrg {
  size?: number
  hidePreferrers?: boolean
}

export default function OrganizationPPBadgeCell(props: IProps) {
  if (props.preferredPartner) {
    return (
      <div className="flex flex-row items-center">
        <div className="flex flex-col items-center justify-center">
          <Icon
            className="fas fa-handshake"
            color="complete"
            fontSize={props.size || 24}
            data-test="pp-org"
          />
        </div>
      </div>
    )
  }

  if (!props.hidePreferrers && utils.hasValue(props.preferrers)) {
    return (
      <div className="flex flex-row items-center">
        <OrganizationPPCell {...props} size={32} />
      </div>
    )
  }

  return null
}
