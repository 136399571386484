import React, { PureComponent } from 'react'
import { utils } from '@ims/1edtech-frontend-common'

import { View, Text } from 'lib/components'

export default class Footer extends PureComponent {
  render() {
    return (
      <footer>
        <View flexible="row-space-between" data-test="footer" py={3} pr={3}>
          <Text variant="caption" color="textLight">
            &copy; 2019 - {utils.date.getMoment().format('YYYY')} 1EdTech Consortium Inc. All Rights Reserved.
          </Text>

          <Text variant="caption" color="textLight" textAlign="right" ml="8px">
            <a
              href="https://www.1edtech.org/1EdTech-TOS"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
          </Text>
        </View>
      </footer>
    )
  }
}
