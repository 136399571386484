import React from 'react'

import { BreadCrumbs } from 'lib/components'
import {
  DASHBOARD_ROUTE,
  getRoute,
  CHARACTERIZATIONS_ROUTE,
} from 'domains/application/navigation/routes'
import { IBreadCrumb } from 'lib/components/BreadCrumbs'
import { ONEROSTER_BASE_NAV_ROUTE } from 'domains/specifications/constants/specNavigation'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  crumbs?: IBreadCrumb[]
}

export default function BaseORRosteringCharacterizationsBreadCrumbs(
  props: IProps,
) {
  const { crumbs = [] } = props
  return (
    <BreadCrumbs
      crumbs={[
        {
          name: 'Home',
          route: getRoute(DASHBOARD_ROUTE),
        },
        {
          name: 'OneRoster Check',
          route: getRoute(`/${ONEROSTER_BASE_NAV_ROUTE}${DASHBOARD_ROUTE}`),
        },
        {
          name: 'Characterizations',
          route: !utils.hasValue(crumbs)
            ? undefined
            : getRoute(
                `/${ONEROSTER_BASE_NAV_ROUTE}${CHARACTERIZATIONS_ROUTE}`,
              ),
        },
        ...crumbs,
      ]}
    />
  )
}
