import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'

import * as formatConstants from 'domains/formats/constants/formats'

export const isFormatOfType = (
  formats: formatConstants.AnyFormatType | formatConstants.AnyFormatType[],
  format: formatConstants.AnyFormatType,
) => {
  const specFormat = formatConstants.ANY_FORMAT_TO_SPEC_FORMAT_MAP[format]
  return R.anyPass(
    utils.array
      .ensureArray(formats)
      .map((f: string) =>
        R.equals(
          formatConstants.ANY_FORMAT_TO_SPEC_FORMAT_MAP[
            f as formatConstants.AnyFormatType
          ],
        ),
      ),
  )(specFormat)
}

export const isOneRosterCSVFormat = (format: formatConstants.AnyFormatType) =>
  isFormatOfType(
    [
      formatConstants.ONER_V1P1_CSV_ROSTERING_EXPORT_BULK,
      formatConstants.ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
      formatConstants.ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK,
      formatConstants.ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK,
      formatConstants.ONER_V1P2_CSV_ROSTERING_EXPORT_BULK,
    ],
    format,
  )

export const isOneRosterRESTFormat = (format: formatConstants.AnyFormatType) =>
  isFormatOfType(
    [
      formatConstants.ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
      formatConstants.ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL,
    ],
    format,
  )
