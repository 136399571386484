import * as R from 'ramda'
import { createSelector } from 'reselect'
import { utils, records } from '@ims/1edtech-frontend-common'

import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import { OR_CSV_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/orCSVCharacterizations'
import { ReportStatus } from 'domains/reports/models/IReportStatuses'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import { RootState } from 'lib/store/rootReducer'
import { OR_REST_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/orRESTCharacterizations'
import { COMMON_CARTRIDGE_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/commonCartridgeCharacterizations'

export const characterizationsByStatusSelector = (
  status: ReportStatus | ReportStatus[],
) =>
  createSelector<RootState, any, ICharacterization[], ICharacterization[]>(
    records.relatedRecordsSelectors.allRelatedRecordsSelector(
      OR_CSV_CHARACTERIZATIONS_RECORD,
      myOrganizationIdSelector,
    ),
    (characterizations) => {
      const filter = R.is(Array, status)
        ? R.anyPass(
            (status as any[]).map(
              (s) => (char: ICharacterization) =>
                R.propOr('', 'status', char) === s,
            ),
          )
        : (char: ICharacterization) => R.propOr('', 'status', char) === status
      return R.compose<
        ICharacterization[],
        ICharacterization[],
        ICharacterization[]
      >(
        R.sort(R.descend(R.propOr(0, 'runDate'))),
        R.filter(filter),
      )(characterizations)
    },
  )

export const characterizationPropSelector = (
  prop: string | number | (string | number)[],
  getId: records.entitiesSelectors.getIdType = 'id',
) =>
  createSelector(
    records.entitiesSelectors.entityByIdSelector(
      OR_CSV_CHARACTERIZATIONS_RECORD,
      getId,
    ),
    R.pathOr(null, utils.array.ensureArray(prop)),
  )

export const restCharacterizationPropSelector = (
  prop: string | number | (string | number)[],
  getId: records.entitiesSelectors.getIdType = 'id',
) =>
  createSelector(
    records.entitiesSelectors.entityByIdSelector(
      OR_REST_CHARACTERIZATIONS_RECORD,
      getId,
    ),
    R.pathOr(null, utils.array.ensureArray(prop)),
  )

export const ccCharacterizationPropSelector = (
  prop: string | number | (string | number)[],
  getId: records.entitiesSelectors.getIdType = 'id',
) =>
  createSelector(
    records.entitiesSelectors.entityByIdSelector(
      COMMON_CARTRIDGE_CHARACTERIZATIONS_RECORD,
      getId,
    ),
    R.pathOr(null, utils.array.ensureArray(prop)),
  )
