import React from 'react'
import { useSelector } from 'react-redux'
import { records } from '@ims/1edtech-frontend-common'

import { getProductRoute } from 'domains/products/navigation/routes'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'
import { IBreadCrumb } from 'lib/components/BreadCrumbs'
import IProduct from 'domains/products/models/IProduct'
import { RootState } from 'lib/store/rootReducer'
import { AnySpecFormatType } from 'domains/formats/constants/formats'
import BaseProductsBreadCrumbs from 'domains/products/components/BaseProductsBreadCrumbs'

interface IProps {
  id: string | number
  format: AnySpecFormatType
  crumbs?: IBreadCrumb[]
  beforeNavigate?: (event: any, route: string) => void
}

export default function BaseProductDetailsBreadCrumbs(props: IProps) {
  const product = useSelector((s: RootState) =>
    records.entitiesSelectors.entityByIdSelector(PRODUCTS_RECORD, 'id')(
      s,
      props,
    ),
  ) as IProduct
  const { id, format, crumbs = [], ...rest } = props
  return (
    <BaseProductsBreadCrumbs
      {...rest}
      crumbs={[
        {
          name: product.name,
          route: getProductRoute(id, format),
        },
        ...crumbs,
      ]}
    />
  )
}
