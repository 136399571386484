import { useState } from 'react'
import { putRequest } from '../../../../lib/api/api'
import { CustomRating } from './types'
import trustedAppsRatings from '../../../../lib/records/modules/trustedAppsRatings'
import { mergeOrReplaceEntities } from 'lib/records/workflows/entities'

export const usePutRatings = () => {
  const [loading, setLoading] = useState(false)

  const putRatings = async (ratings: CustomRating[]) => {
    setLoading(true)

    await putRequest(
      'trustedapps/organizations/applications/config/ratings',
      ratings.reduce((acc, r, i) => {
        acc[i] = {
          iconColor: r.iconColor as any,
          iconIdentifier: r.iconIdentifier,
          ratingId: r.isNew ? undefined : r.ratingId,
          ratingText: r.ratingText,
          isEmbedded: r.isEmbedded,
        }

        return acc
      }, {} as Record<number, any>),
    )
      .then(() => mergeOrReplaceEntities(true, trustedAppsRatings, { ratings }))
      .finally(() => setLoading(false))

    mergeOrReplaceEntities(true, trustedAppsRatings, { ratings })
  }

  return [putRatings, loading] as const
}
