import React from 'react'
import { Text } from 'lib/components'

export default function FormError({
    name,
    children,
}: {
    name: string
    children: any
}) {
    return (
        <Text
            variant="formError"
            id={`${name}-error`}
            data-test={`field-error-${name}`}
        >
            {children}
        </Text>
    )
}
