import React from 'react'
import clsx from 'clsx'
import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'

import ReportStatusIcon from 'domains/reports/components/ReportStatusIcon'
import {
  GREEN_REPORT_STATUS,
  AMBER_REPORT_STATUS,
  RED_REPORT_STATUS,
  ReportStatus,
} from 'domains/reports/models/IReportStatuses'

export const PRINT_STATUS_TEXT_MAP = {
  [GREEN_REPORT_STATUS]: '',
  [AMBER_REPORT_STATUS]: 'PASSED WITH ALERTS',
  [RED_REPORT_STATUS]: 'FAILED',
}

type Props = {
  name: string
  status: ReportStatus
  circle?: boolean
  'data-test'?: string
}

export default function PrintReportRestServiceHeader(props: Props) {
  const { name, status, circle } = props
  const statusText = R.pathOr('', [`${status}`], PRINT_STATUS_TEXT_MAP)
  const hasStatusText = utils.hasValue(statusText)
  return (
    <div
      className={clsx(
        'flex flex-row flex-1 items-center border-b-2 border-text',
        'p-2 mt-6 h-12 avoid-print-break',
      )}
      data-test={props['data-test'] || `rest-service-header-${name}`}
    >
      <ReportStatusIcon
        status={status}
        circle={circle}
        data-test={`${props['data-test']}-status`}
      />
      <h1 className="text-sm font-bold ml-2">
        {name}{' '}
        {hasStatusText && <span className="text-xs">- {statusText}</span>}
      </h1>
    </div>
  )
}
