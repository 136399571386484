import * as normalizr from 'normalizr'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

import * as formats from 'domains/formats/constants/formats'
import { AnyFormatType } from 'domains/formats/constants/formats'

export const COMPARISONS_RECORD = 'comparisons'

const prepareCreateData = (data: Record<string, unknown>) => ({
  request: R.dissoc('format', data),
})

interface IGetListParams {
  isSupplier?: boolean
}

const comparisons: records.modules.IModule = {
  record: COMPARISONS_RECORD,
  responseDataKey: ['comparison', 'comparisons'],
  schema: new normalizr.schema.Entity(
    COMPARISONS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(),
  ),
  api: {
    create: (newComparison: any) => getCreateComparisonLink(newComparison),
    prepareCreateData,
    get: (id) => `comparisons/${id}`,
    getList: (params, orgId) => {
      if ((params as IGetListParams).isSupplier) {
        return `suppliers/${orgId}/comparisons`
      }
      return `districts/${orgId}/comparisons`
    },
    update: (id) => `comparisons/${id}/notes`,
    delete: (id) => `comparisons/${id}`,
  },
}

export const getCreateComparisonLink = (newComparison: {
  format: AnyFormatType
}) => {
  const specFormat = formats.ANY_FORMAT_TO_SPEC_FORMAT_MAP[newComparison.format]
  switch (specFormat) {
    case formats.ONER_V1P1_CSV_ROSTERING_EXPORT_BULK:
    case formats.ONER_V1P2_CSV_ROSTERING_EXPORT_BULK:
    case formats.ONER_V1P1_CSV_ROSTERING_IMPORT_BULK:
      return 'comparisons/csv'
    case formats.ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL:
    case formats.ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL:
      return 'comparisons/rest'
    default:
      return 'comparisons/csv'
  }
}

export const getCSVComparisonDownloadLink = (id: string | number) =>
  `comparisons/csv/${id}/download`

export const getRESTComparisonDownloadLink = (id: string | number) =>
  `comparisons/rest/${id}/download`

export default comparisons
