import React from 'react'

export default function TrustedAppVettingWelcomeText() {
  return (
    <p className="text-lg my-6">
      Visit the{' '}
      <a href="https://www.1edtech.org/trusted-apps-program-overview-page">
        TrustEd Apps Program
      </a>{' '}
      page to learn more or email{' '}
      <a href="mailto:trustedapps@1edtech.org">TrustEdApps@1edtech.org</a>{' '}
      with any questions.
    </p>
  )
}
