import { SUPPORTED_1EDTECH_FORMATS } from 'domains/formats/constants/formats'
import { isProductFormatSupported } from 'domains/products/utils/isProductFormatSupported'
import IProductFormat from 'domains/products/models/IProductFormat'

export const filterProductFormatsBySupported = (
  productFormats: IProductFormat[],
  supportedFormats = SUPPORTED_1EDTECH_FORMATS,
) =>
  productFormats.filter((f) =>
    isProductFormatSupported(f.format, supportedFormats),
  )
