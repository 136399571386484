import React from 'react'

import { Text } from 'lib/components'
import IProduct from 'domains/products/models/IProduct'
import { getFirstSupportedProductFormat } from 'domains/products/utils/getFirstSupportedProductFormat'
import ProductCatalogExpandedRowCell from 'domains/products/components/ProductCatalog/ProductCatalogExpandedRowCell'
import {
  AnySpecFormatType,
  OR_FORMAT_TO_SPEC_SUBTYPE,
} from 'domains/formats/constants/formats'

interface IProps extends IProduct {
  isSubRow: boolean
  isSingleProduct: boolean
  isOpen?: boolean
}

export default function SupplierProductCatalogSpecCell(props: IProps) {
  const format = props.format || getFirstSupportedProductFormat(props)
  return (
    <ProductCatalogExpandedRowCell {...props} borderLeft={true}>
      <Text fontSize={13} color={props.isOpen ? 'white' : 'text'}>
        {OR_FORMAT_TO_SPEC_SUBTYPE[format as AnySpecFormatType]}
      </Text>
    </ProductCatalogExpandedRowCell>
  )
}
