import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

import {
  OR_1P1_REST_ROSTERING_CONSUMER_SERVICES,
  SERVICE_PARENT_ENDPOINT_MAP,
} from 'domains/modeling/constants/restCOneRoster1p1'
import { getRestModelServicesFromSpec } from 'domains/modeling/utils/getRestModelServicesFromSpec'
import { OR1P1Subsets } from 'domains/specifications/constants/oneRoster1p1FileSets'
import { REST_C_MODELS_RECORD } from 'lib/records/modules/restCModels'
import store from 'lib/store'
import IRestCModel from 'domains/modeling/models/IRestCModel'
import IRestCModelService from 'domains/modeling/models/IRestCModelService'
import { formatNameByIdSelector } from 'domains/specifications/selectors/formatSelectors'
import { deleteProductCharacterization } from 'domains/characterizations/workflows/deleteProductCharacterization'
import { AnySpecFormatType } from 'domains/formats/constants/formats'
import { trackRestCModelingAnalytics } from 'domains/modeling/utils/trackModelingAnalytics'
import { updateRestCServices } from 'domains/modeling/workflows/updateRestCServices'

export const resetRestCServices = async (
  modelId: number,
  serviceNames: OR_1P1_REST_ROSTERING_CONSUMER_SERVICES[],
) => {
  const model = records.entitiesSelectors.entityByIdSelector(
    REST_C_MODELS_RECORD,
    modelId,
  )(store.getState()) as IRestCModel

  const formatName = formatNameByIdSelector(model.formatId)(store.getState())
  await deleteProductCharacterization(
    model.productId,
    formatName as AnySpecFormatType,
  )

  const servicesDerivedFromSpec = getRestModelServicesFromSpec(
    OR1P1Subsets.CLASS_ROSTERS_W_DEMOGRAPHICS_SUBSET,
  )

  const endpoints: any[] = []
  for (const serviceName of serviceNames) {
    const serviceDerivedFromSpec = R.find(
      (s) => R.propOr('', 'name', s) === serviceName,
      servicesDerivedFromSpec,
    )! as IRestCModelService
    const parentEndpointName = SERVICE_PARENT_ENDPOINT_MAP[serviceName]
    const endpointDerivedFromSpec = R.find(
      R.propEq('name', parentEndpointName),
      serviceDerivedFromSpec.endpoints,
    )
    endpoints.push(endpointDerivedFromSpec)
  }

  await updateRestCServices(modelId, endpoints, {}, true)
  trackRestCModelingAnalytics('reset_services', { services: serviceNames })
}
