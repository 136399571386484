import { useSelector } from 'react-redux'
import { utils } from '@ims/1edtech-frontend-common'

import { navigationParamSelector } from 'lib/utils/navigation'
import useRecordEntity from 'lib/records/hooks/useRecordEntity'
import { TRUSTED_APPS_RECORD } from 'lib/records/modules/trustedApplications'
import { IMS_APPLICATIONS_RECORD } from 'lib/records/modules/imsApplications'
import IIMSApplication from 'domains/trustedApps/models/IIMSApplication'
import ITrustedApplication from 'domains/trustedApps/models/ITrustedApplication'

export default function useTrustedApp(
  props: any,
): [
  number,
  IIMSApplication | null,
  ITrustedApplication | null,
  boolean,
  number,
] {
  const parentRecordId = utils.convertToInt(
    useSelector((state: any) =>
      navigationParamSelector('orgId', 0)(state, props),
    ),
  )
  const id = utils.convertToInt(
    useSelector((state: any) => navigationParamSelector('id', 0)(state, props)),
  )
  const [selectedAppDetails, selectedPending] = useRecordEntity({
    record: TRUSTED_APPS_RECORD,
    id,
    requestParams: { parentRecordId },
  })
  const [imsAppDetails, imsPending] = useRecordEntity({
    record: IMS_APPLICATIONS_RECORD,
    id,
  })

  return [
    id,
    imsAppDetails ? (imsAppDetails as IIMSApplication) : null,
    selectedAppDetails ? (selectedAppDetails as ITrustedApplication) : null,
    !utils.hasValue(imsAppDetails) && (selectedPending || imsPending),
    parentRecordId,
  ]
}
