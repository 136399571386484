import React from 'react'
import SmallWidget from 'domains/dashboard/components/SmallWidget'

interface IProps {
  characterisationsCount: number
  fadeDelay: number
}
export default function OneRosterCharacterizationsWidget(props: IProps) {
  return (
    <SmallWidget
      fadeDelay={props.fadeDelay}
      icon="fingerprint"
      iconColor="#009de3"
      isMaterialIcon={true}
      title={props.characterisationsCount}
      subtitle="Total Characterizations"
      dataTest="oneroster-characterizations-widget"
    />
  )
}
