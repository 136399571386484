import React from 'react'
import { useSelector } from 'react-redux'
import Confetti from 'react-dom-confetti'
import { utils } from '@ims/1edtech-frontend-common'

import { Button, Icon, Image, Text, View } from 'lib/components'
import ProfileHeader from 'domains/application/components/ProfileHeader'
import { withScreenSize, IScreenSizeProps } from 'lib/utils/screenSize'
import {
  myOrganizationLogoSelector,
  myOrganizationPropSelector,
} from 'domains/authentication/selectors/organizations'
import NotificationBell from 'domains/notifications/components/NotificationBell'
import { isCCXCertified } from 'domains/orgs/models/IOrg'
import CCxCertifiedBadge from 'lib/components/CCxCertifiedBadge'
import { meSelector } from 'domains/authentication/selectors/me'
import { RootState } from 'lib/store/rootReducer'
import { login } from 'domains/authentication/workflows/login'
import { ERROR_TOAST, showToast } from 'lib/utils/toast'

const confettiDuration = 3000
const confettiConfig = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: 500,
  dragFriction: 0.12,
  duration: confettiDuration,
  stagger: 3,
  width: '10px',
  height: '10px',
  perspective: '500px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
}

interface IProps {
  toggleDrawer?: () => void
}

export function Header(props: IProps & IScreenSizeProps) {
  const [logoError, setLogoError] = React.useState(false)
  const [showConfetti, setShowConfetti] = React.useState(false)
  const me = useSelector(meSelector)
  const orgType: string = useSelector((state: RootState) =>
    myOrganizationPropSelector('type')(state, {}),
  )

  const logo = useSelector((s: any) => myOrganizationLogoSelector(s, props))
  const orgName = useSelector((s: any) =>
    myOrganizationPropSelector('name', '')(s, props),
  )
  const orgCertLevel = useSelector((s: any) =>
    myOrganizationPropSelector('certificationLevel', '')(s, props),
  )

  const onImageError = () => setLogoError(true)

  const onImageClick3 = async (e: React.MouseEvent<HTMLImageElement>) => {
    if (e.detail === 3) {
      setShowConfetti(true)
      await utils.wait.waitFor(confettiDuration)
      setShowConfetti(false)
    }
  }

  const onLogoutOrganization = async () => {
    const IMS_ID = utils.env.getEnvVariable('IMS_ID')
    const success = await login('', '', IMS_ID)

    if (!success) {
      showToast(ERROR_TOAST, `Failed to login with Org: ${IMS_ID}`)
    }
  }

  return (
    <View
      height={70}
      position="sticky"
      top={0}
      elevation="zero"
      zIndex={2}
      pl={[0, 3, 4]}
      pr={[2, 2, 3]}
      bg="white"
    >
      <View flexible="row-space-between" height="100%" width="100%">
        <header>
          <View flexible="row-v-center">
            {props.toggleDrawer && (
              <Icon
                p={16}
                id="header-hamburger"
                cursor="pointer"
                onClick={props.toggleDrawer}
                className="fas fa-bars"
                color="textLight"
                fontSize={20}
              />
            )}
            {!props.hasSmallScreen && !logoError && (
              <Image
                src={logo}
                maxWidth={36}
                maxHeight={36}
                borderRadius="50%"
                mr={[2, 3]}
                alt={`Organization Logo for ${orgName}`}
                onError={onImageError}
                onClick={onImageClick3}
              />
            )}
            {!props.hasSmallScreen && (
              <Text
                variant="title"
                overflow="ellipsis"
                data-test="header-org-name"
              >
                {orgName}
              </Text>
            )}
            {!props.hasSmallScreen && isCCXCertified(orgCertLevel) && (
              <CCxCertifiedBadge className="ml-2" showTooltip />
            )}
          </View>
        </header>

        <View flexible="row-v-center" height="100%">
          {!!me && me.ims && orgType !== 'IMS' && (
            <Button variant="secondary" onClick={onLogoutOrganization}>
              Logout of Organization
            </Button>
          )}
          <ProfileHeader />
          <NotificationBell />
        </View>
      </View>

      <Confetti active={showConfetti} config={confettiConfig} />
    </View>
  )
}

export default withScreenSize(Header) as (props: IProps) => any
