import React from 'react'

import { View, Text, Icon, Linkify } from 'lib/components'
import { utils } from '@ims/1edtech-frontend-common'
import ICSVConsumerModel from 'domains/modeling/models/ICSVConsumerModel'
import { ICSVComparisonReport } from 'domains/comparisons/models/ICSVComparisonReport'
import IRestCModel from 'domains/modeling/models/IRestCModel'
import {
  ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
  ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
  AnyFormatType,
  ANY_FORMAT_TO_SPEC_FORMAT_MAP,
  ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL,
  ONER_V1P1_CSV_ROSTERING_EXPORT_BULK,
  ONER_V1P2_CSV_ROSTERING_EXPORT_BULK,
} from 'domains/formats/constants/formats'
import ReportLinkBox from 'domains/reports/components/ReportLinkBox'
import IRESTComparisonReport from 'domains/characterizations/models/IRESTComparisonReport'

interface IProps {
  model?: ICSVConsumerModel | IRestCModel
  report?: ICSVComparisonReport | IRESTComparisonReport
  format?: AnyFormatType
}

export default function ModelReportDetails(props: IProps) {
  const getTopLevelNotesLabel = () => {
    switch (
      ANY_FORMAT_TO_SPEC_FORMAT_MAP[props.format || ('' as AnyFormatType)]
    ) {
      case ONER_V1P1_CSV_ROSTERING_IMPORT_BULK:
      case ONER_V1P1_CSV_ROSTERING_EXPORT_BULK:
      case ONER_V1P2_CSV_ROSTERING_EXPORT_BULK:
        return 'Fileset Notes'
      case ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL:
      case ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL:
        return 'Endpoint Set Notes'
      default:
        return 'Fileset Notes'
    }
  }

  const { report, model } = props
  if (!model && !report) {
    return null
  }
  const implementationUrl = report
    ? report.implementationUrl
    : model!.implementationUrl
  const helpUrl = report ? report.helpUrl : model!.onlineHelpUrl
  const supportEmail = report ? report.supportEmail : model!.productSupportEmail
  const notes = report ? report.notes : model!.notes

  return (
    <View width="100%" flexible="column">
      <View
        display="grid"
        gridTemplateColumns={['auto', 'auto', '1fr 1fr 1fr']}
        gridTemplateRows={['1fr 1fr 1fr', '1fr 1fr 1fr', 'auto']}
        gridColumnGap={2}
        gridRowGap={2}
        width="100%"
        my={3}
      >
        <ReportLinkBox
          name="Implementation Documentation URL"
          url={implementationUrl}
        />
        <ReportLinkBox name="Online Help URL" url={helpUrl} />
        <ReportLinkBox
          name="Product Support Email"
          url={supportEmail}
          isMailTo={true}
        />
      </View>
      {utils.hasValue(notes) && (
        <View my={3} ml={2} data-test="model-notes">
          <Text fontSize={18} fontWeight={700} mb={2}>
            <Icon className="fas fa-info-circle" color="info" mr={1} />
            {getTopLevelNotesLabel()}:
          </Text>
          <Text whiteSpace="pre-line">
            <Linkify>{notes}</Linkify>
          </Text>
        </View>
      )}
    </View>
  )
}
