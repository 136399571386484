import React from 'react'
import clsx from 'clsx'
import * as R from 'ramda'

import {
  ITransformedRESTServiceEndpointCharacterizationReport,
  ITransformedRESTServiceEndpointWarning,
} from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import {
  GREEN_REPORT_STATUS,
  RED_REPORT_STATUS,
  AMBER_REPORT_STATUS,
  STATUS_TO_LIGHT_VARIANT_MAP,
} from 'domains/reports/models/IReportStatuses'
import { utils } from '@ims/1edtech-frontend-common'
import RESTParameterDetails, {
  RESTParameterRow,
  RESTParameterInfoItem,
  findParamByName,
} from 'domains/reports/components/RESTParameterDetails'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'
import { View } from 'lib/components'

const isPrint = isOnPrintScreen()

interface IProps {
  endpoint: ITransformedRESTServiceEndpointCharacterizationReport
}
export default function RESTPCharacterizationEndpointParameterList(
  props: IProps,
) {
  const securityErrors =
    props.endpoint.securityErrors ||
    ([] as ITransformedRESTServiceEndpointWarning[])
  const securityWarnings =
    props.endpoint.securityWarnings ||
    ([] as ITransformedRESTServiceEndpointWarning[])
  const securityMessages = [...securityErrors, ...securityWarnings]

  const supportedErrors =
    props.endpoint.supportedErrors ||
    ([] as ITransformedRESTServiceEndpointWarning[])
  const supportedWarnings =
    props.endpoint.supportedWarnings ||
    ([] as ITransformedRESTServiceEndpointWarning[])
  const supportedMessages = [...supportedErrors, ...supportedWarnings]

  const endpointHasErrors =
    utils.hasValue(securityErrors) ||
    utils.hasValue(supportedErrors) ||
    utils.hasValue(props.endpoint.attributeErrors) ||
    utils.hasValue(props.endpoint.errors) ||
    (props.endpoint.paramCharacterizations || []).filter(
      (p) => !R.propEq('status', GREEN_REPORT_STATUS, p),
    ).length > 0

  const securityStatus =
    securityErrors.length > 0
      ? RED_REPORT_STATUS
      : securityWarnings.length > 0
      ? AMBER_REPORT_STATUS
      : GREEN_REPORT_STATUS

  const supportedStatus =
    supportedErrors.length > 0
      ? RED_REPORT_STATUS
      : supportedWarnings.length > 0
      ? AMBER_REPORT_STATUS
      : GREEN_REPORT_STATUS

  const displaySecurity = !endpointHasErrors || securityMessages.length > 0
  const displaySupported = !endpointHasErrors || supportedMessages.length > 0
  const params: any[] = []
  if (displaySecurity) {
    params.push({
      name: 'Security',
      status: securityStatus,
      dataTest: `param-status-${props.endpoint.endpointName}-Security`,
      infoItems: [
        ...securityErrors.map((error) => (
          <RESTParameterInfoItem
            key={error.description}
            status={RED_REPORT_STATUS}
            message={error.description}
          />
        )),
        ...securityWarnings.map((warning) => (
          <RESTParameterInfoItem
            key={warning.description}
            status={AMBER_REPORT_STATUS}
            message={warning.description}
          />
        )),
      ],
    })
  }
  if (displaySupported) {
    params.push({
      name: 'Supported',
      status: supportedStatus,
      dataTest: `param-status-${props.endpoint.endpointName}-Supported`,
      infoItems: [
        ...supportedErrors.map((error) => (
          <RESTParameterInfoItem
            key={error.description}
            status={RED_REPORT_STATUS}
            message={error.description}
          />
        )),
        ...supportedWarnings.map((warning) => (
          <RESTParameterInfoItem
            key={warning.description}
            status={AMBER_REPORT_STATUS}
            message={warning.description}
          />
        )),
      ],
    })
  }
  ;['Filter', 'Fields', 'Sort', 'Order By', 'Offset', 'Limit'].forEach(
    (param) => {
      const foundParam = findParamByName(
        param,
        props.endpoint.paramCharacterizations,
      )
      if (foundParam) {
        params.push({
          name: param,
        })
      }
    },
  )

  if (params.length < 1) {
    return null
  }

  return (
    <View
      className={clsx('flex flex-col p-1 avoid-print-break', {
        'md:p-3 overflow-x-auto': !isPrint,
      })}
      bg={isPrint ? '#fff' : STATUS_TO_LIGHT_VARIANT_MAP[props.endpoint.status]}
    >
      {params.map((param, index) => {
        if (param.status) {
          return (
            <RESTParameterRow
              key={`${param.name}-${index}`}
              name={param.name}
              endpointName={props.endpoint.endpointName}
              status={param.status}
              data-test={param.dataTest}
              infoItems={param.infoItems}
              index={index}
            />
          )
        } else {
          return (
            <RESTParameterDetails
              key={`${param.name}-${index}`}
              name={param.name}
              endpointName={props.endpoint.endpointName}
              params={props.endpoint.paramCharacterizations}
              index={index}
            />
          )
        }
      })}
    </View>
  )
}
