import React from 'react'
import * as R from 'ramda'

import { View, Icon } from 'lib/components'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import { AnyFormatType } from 'domains/formats/constants/formats'
import ReportStatusRow from 'domains/reports/components/ReportStatusRow'
import IComparison, {
  IComparisonFileSummary,
} from 'domains/comparisons/models/IComparison'
import { GRAY_REPORT_STATUS } from 'domains/reports/models/IReportStatuses'

function UnknownStatus() {
  return (
    <View
      flexible="column-center"
      mt={4}
      data-test="comparison-overview-widget"
    >
      <Icon
        fontSize={48}
        className="fas fa-question-circle"
        color="secondary"
        data-test="unkown-status"
      />
    </View>
  )
}

interface IProps {
  format: AnyFormatType
  comparison: IComparison
  height?: number
}

export default function ComparisonReportOverviewWidget(props: IProps) {
  let summaries: any = <UnknownStatus />

  const summary = R.pathOr(
    [],
    ['comparison', 'summary'],
    props,
  ) as IComparisonFileSummary[]
  if (summary.length > 0) {
    summaries = props.comparison.summary.map((summ: IComparisonFileSummary) => (
      <ReportStatusRow
        key={summ.filename}
        label={summ.filename}
        status={summ.compatibility}
        circle={summ.compatibility === GRAY_REPORT_STATUS}
        data-test={`overview-item-status-${summ.filename}`}
      />
    ))
  }

  return (
    <DetailsTopWidget
      title="Comparison Overview"
      dataTest="comparison-overview-widget"
      height={props.height || 472}
    >
      <View height="100%" overflowY="auto">
        {summaries}
      </View>
    </DetailsTopWidget>
  )
}
