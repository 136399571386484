import { getRequest } from 'lib/api/api'
import { ERROR_TOAST, showToast } from 'lib/utils/toast'
import { useEffect, useState } from 'react'
import { GetTrustedAppSurveyQuestionsResponse } from '../models/ITrustedAppsCustomQuestions'

const fetchQuestions = async (): Promise<
  GetTrustedAppSurveyQuestionsResponse['questions']
> => {
  const response = await getRequest(
    'trustedapps/organizations/applications/config/surveys',
  )

  return response.data.questions
}

export const useQueryCustomQuestions = () => {
  const [loading, setLoading] = useState(true)
  const [questions, setQuestions] = useState<
    GetTrustedAppSurveyQuestionsResponse['questions'] | undefined
  >(undefined)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const response = await fetchQuestions()
        setQuestions(response)
      } catch (error) {
        showToast(ERROR_TOAST, 'Failed to get Custom Fields')
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  return [questions, loading] as const
}
