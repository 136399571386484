import React, { useState, useEffect } from 'react'

import { View, Image } from 'lib/components'
import BaseBreadCrumbs from 'domains/application/components/BaseBreadCrumbs'
import DashboardWrapper from 'domains/dashboard/components/DashboardWrapper'
import RecentORCharacterizationsWidget from 'domains/dashboard/components/RecentORCharacterizationsWidget'
import CharacterizationsNeedingCorrectionsWidget from 'domains/dashboard/components/CharacterizationsNeedingCorrectionsWidget'
import ReadyToCompareCharacterizationsWidget from 'domains/dashboard/components/ReadyToCompareCharacterizationsWidget'
import ComparisonCountWidget from 'domains/dashboard/components/ComparisonCountWidget'
import ORCheckDashboardRecentComparisonsWidget from 'domains/dashboard/components/ORCheckDashboardRecentComparisonsWidget'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import { getRequest } from 'lib/api/api'
import OneRosterCharacterizationsWidget from 'domains/dashboard/components/OneRosterCharacterizationsWidget'
import { IDistrictORCDashboardStatistics } from 'domains/dashboard/models/IDashboardStatistics'
import RecentCharacterizationSummaryWidget from 'domains/dashboard/components/RecentCharacterizationSummaryWidget'

export default function DistrictORCheckDashboardScreen() {
  const [statistics, setStatistics] = useState<IDistrictORCDashboardStatistics>(
    {
      readyToCompareCount: 0,
      ccInvestigationCount: 0,
      validCCInvestigationCount: 0,
      characterisationsCount: 0,
      characterisationsWithErrorsCount: 0,
      comparisonCount: 0,
      characterisationsNeedingCorrections: [],
      recentCharacterisations: [],
      recentCcInvestigations: [],
      recentComparisons: [],
    },
  )

  useEffect(() => {
    const fetch = async () => {
      const response = await getRequest('statistics/district')
      setStatistics(response.data)
    }
    fetch()
  }, [])

  return (
    <View variant="screen">
      <DocumentTitle title="OneRoster Check" />
      <BaseBreadCrumbs crumbs={[{ name: 'OneRoster Check' }]} />
      <View flex={1} width="100%">
        <DashboardWrapper
          row1Widgets={[
            <View key="logo" flexible="column-center">
              <Image
                key="logo"
                src="/images/ORLogo.png"
                width="auto"
                height="80px"
              />
            </View>,
            <ReadyToCompareCharacterizationsWidget
              key="readyToCompare"
              readyToCompareCount={statistics.readyToCompareCount}
              fadeDelay={100}
            />,
            <OneRosterCharacterizationsWidget
              fadeDelay={200}
              key="characterizations"
              characterisationsCount={statistics.characterisationsCount}
            />,
            <ComparisonCountWidget
              key="comparisons"
              fadeDelay={300}
              comparisonCount={statistics.comparisonCount}
            />,
          ]}
          row2Widgets={[
            <RecentORCharacterizationsWidget
              key="recentCharacterizations"
              recentCharacterisations={statistics.recentCharacterisations}
              fadeDelay={500}
            />,
            <RecentCharacterizationSummaryWidget
              key="recentCharacterizationSummary"
              fadeDelay={600}
            />,
          ]}
          row3Widgets={[
            <CharacterizationsNeedingCorrectionsWidget
              key="CharacterizationsNeedingCorrections"
              characterisationsNeedingCorrections={
                statistics.characterisationsNeedingCorrections
              }
              fadeDelay={800}
            />,
            <ORCheckDashboardRecentComparisonsWidget
              key="recentComparisons"
              recentComparisons={statistics.recentComparisons}
              fadeDelay={700}
            />,
          ]}
          row3IsLarge={true}
        />
      </View>
    </View>
  )
}
