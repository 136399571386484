import { records } from '@ims/1edtech-frontend-common'

import store from 'lib/store'
import { specFileSelector } from 'domains/modeling/selectors/csvCModelToSpec'
import { updateCsvCModel } from 'domains/modeling/workflows/updateCsvCModel'
import { getFileForCreate } from 'domains/modeling/utils/csvCModelToServer'
import { trackCSVCModelingAnalytics } from 'domains/modeling/utils/trackModelingAnalytics'
import { updateFileAndGetModelInState } from 'domains/modeling/workflows/csvCModelFile'
import { AnyFormatType } from 'domains/formats/constants/formats'
import { SPECIFICATIONS_RECORD } from 'lib/records/modules/specifications'

export const resetFileModeling = async (
  modelId: string | number,
  format: AnyFormatType,
  fileName: string,
) => {
  const specFile = specFileSelector(
    records.entitiesSelectors.entityByIdSelector(
      SPECIFICATIONS_RECORD,
      'format',
    ),
    'fileName',
  )(store.getState(), { fileName, format })
  const model = updateFileAndGetModelInState(modelId, fileName, {
    ...getFileForCreate(specFile),
    touched: false,
    inProgress: false,
  })
  await updateCsvCModel(modelId, model)
  trackCSVCModelingAnalytics('reset-file', { fileName })
}
