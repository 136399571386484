import React from 'react'

import DeleteButton from 'lib/components/DeleteButton'

interface IProps {
  nameProp: string
  onClick: (item: any) => void
}

export default function GenericDeleteCell(props: IProps) {
  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    props.onClick(props)
  }

  const name = (props as any)[props.nameProp]
  return (
    <DeleteButton
      onClick={onClick}
      data-test={`delete-btn-${name}`}
      aria-label={`Delete ${name}`}
    />
  )
}
