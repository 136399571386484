import { getRequest } from 'lib/api/api'
import * as R from 'ramda'

export const isLTIISSAndClientIdAvailable = async (
  orgId: string,
  iss: string,
  clientId: string,
): Promise<boolean> => {
  const request = await getRequest(`orgs/${orgId}/lti/configurations`)
  if (request?.data?.configurations) {
    const data = request.data.configurations.filter((v: any) =>
      R.propEq('iss', iss, v) && R.propEq('clientId', clientId, v),
    )
    return !data.length
  }

  return true
}
