import React from 'react'
import LinkifyIt from 'react-linkify'

interface IProps {
  children: string
}
const openLinkInNewTabProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
}

const componentDecorator = (
  decoratedHref: any,
  decoratedText: any,
  key: any,
) => (
  <a {...openLinkInNewTabProps} href={decoratedHref} key={key}>
    {decoratedText}
  </a>
)

export default function Linkify(props: IProps) {
  return (
    <LinkifyIt componentDecorator={componentDecorator}>
      {props.children}
    </LinkifyIt>
  )
}
