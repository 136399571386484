import * as R from 'ramda'

import { utils } from '@ims/1edtech-frontend-common'
import {
  isSupplierRoleSelector,
  isIMSAdminRoleSelector,
} from 'domains/authentication/selectors/roles'
import store from 'lib/store'
import {
  ONEROSTER_BASE_NAV_ROUTE,
  COMMON_CARTRIDGE_BASE_NAV_ROUTE,
} from 'domains/specifications/constants/specNavigation'

export const LOGIN_ROUTE = '/login'
export const OAUTH_REDIRECT_ROUTE = '/login/oauth2redirect'
export const LTI_LOGIN_REDIRECT_ROUTE = '/lti/login'
export const FRESHDESK_JWT_LOGIN = '/freshdesk/jwt/login'

export const EMBEDDABLE_TRUSTED_APPS_ROUTE = '/embeddable/trustedApps/:orgId'

export const LOGIN_ROUTES = [
  LOGIN_ROUTE,
  OAUTH_REDIRECT_ROUTE,
  LTI_LOGIN_REDIRECT_ROUTE,
  FRESHDESK_JWT_LOGIN,
]

export const DASHBOARD_ROUTE = '/home'
export const CHARACTERIZATIONS_ROUTE = '/characterizations'
export const CREATE_CHARACTERIZATION_ROUTE = `${CHARACTERIZATIONS_ROUTE}/create`
export const PRODUCT_CATALOG_ROUTE = '/products'
export const TRUSTED_APPS_ROUTE = `/trustedapps`
export const COMPARISONS_ROUTE = '/comparisons'
export const USERS_ROUTE = '/users'
export const ORGANIZATIONS_ROUTE = '/organizations'
export const ORG_PREF_ROUTE = '/org'
export const HELP_ROUTE = '/help'

export const PRINT_ROOT_ROUTE = '/print'

export const SUPPLIER_ROOT_ROUTE = '/supplier'
export const SUPPLIER_DASHBOARD_ROUTE = `${SUPPLIER_ROOT_ROUTE}${DASHBOARD_ROUTE}`
export const SUPPLIER_OR_DASHBOARD_ROUTE = `${SUPPLIER_ROOT_ROUTE}/${ONEROSTER_BASE_NAV_ROUTE}${DASHBOARD_ROUTE}`
export const SUPPLIER_OR_CHARACTERIZATIONS_ROUTE = `${SUPPLIER_ROOT_ROUTE}/${ONEROSTER_BASE_NAV_ROUTE}${CHARACTERIZATIONS_ROUTE}`
export const SUPPLIER_OR_PRODUCT_CATALOG_ROUTE = `${SUPPLIER_ROOT_ROUTE}/${ONEROSTER_BASE_NAV_ROUTE}${PRODUCT_CATALOG_ROUTE}`
export const SUPPLIER_OR_COMPARISONS_ROUTE = `${SUPPLIER_ROOT_ROUTE}/${ONEROSTER_BASE_NAV_ROUTE}${COMPARISONS_ROUTE}`
export const SUPPLIER_CC_DASHBOARD_ROUTE = `${SUPPLIER_ROOT_ROUTE}/${COMMON_CARTRIDGE_BASE_NAV_ROUTE}${DASHBOARD_ROUTE}`
export const SUPPLIER_CC_CHARACTERIZATIONS_ROUTE = `${SUPPLIER_ROOT_ROUTE}/${COMMON_CARTRIDGE_BASE_NAV_ROUTE}${CHARACTERIZATIONS_ROUTE}`
export const SUPPLIER_USERS_ROUTE = `${SUPPLIER_ROOT_ROUTE}${USERS_ROUTE}`
export const SUPPLIER_ORGANIZATIONS_ROUTE = `${SUPPLIER_ROOT_ROUTE}${ORGANIZATIONS_ROUTE}`
export const SUPPLIER_ORG_PREF_ROUTE = `${SUPPLIER_ROOT_ROUTE}${ORG_PREF_ROUTE}`
export const SUPPLIER_HELP_ROUTE = `${SUPPLIER_ROOT_ROUTE}${HELP_ROUTE}`

export const DISTRICT_ROOT_ROUTE = '/district'
export const DISTRICT_DASHBOARD_ROUTE = `${DISTRICT_ROOT_ROUTE}${DASHBOARD_ROUTE}`
export const DISTRICT_OR_DASHBOARD_ROUTE = `${DISTRICT_ROOT_ROUTE}/${ONEROSTER_BASE_NAV_ROUTE}${DASHBOARD_ROUTE}`
export const DISTRICT_OR_CHARACTERIZATIONS_ROUTE = `${DISTRICT_ROOT_ROUTE}/${ONEROSTER_BASE_NAV_ROUTE}${CHARACTERIZATIONS_ROUTE}`
export const DISTRICT_OR_PRODUCT_CATALOG_ROUTE = `${DISTRICT_ROOT_ROUTE}/${ONEROSTER_BASE_NAV_ROUTE}${PRODUCT_CATALOG_ROUTE}`
export const DISTRICT_OR_COMPARISONS_ROUTE = `${DISTRICT_ROOT_ROUTE}/${ONEROSTER_BASE_NAV_ROUTE}${COMPARISONS_ROUTE}`
export const DISTRICT_TRUSTED_APPS_ROUTE = `${DISTRICT_ROOT_ROUTE}${TRUSTED_APPS_ROUTE}`
export const DISTRICT_TRUSTED_APPS_DASHBOARD_ROUTE = `${DISTRICT_TRUSTED_APPS_ROUTE}/dashboard`
export const DISTRICT_TRUSTED_APPS_ADD_ROUTE = `${DISTRICT_TRUSTED_APPS_ROUTE}/add`
export const DISTRICT_TRUSTED_APPS_ADD_CUSTOM_ROUTE = `${DISTRICT_TRUSTED_APPS_ROUTE}/add_custom`
export const DISTRICT_TRUSTED_APPS_SETUP_ROUTE = `${DISTRICT_TRUSTED_APPS_ROUTE}/setup`
export const DISTRICT_TRUSTED_APPS_SHARED_DASHBOARDS_ROUTE = `${DISTRICT_TRUSTED_APPS_ROUTE}/shared`
export const DISTRICT_TRUSTED_APPS_CHANGELOG_ROUTE = `${DISTRICT_TRUSTED_APPS_ROUTE}/changelog`
export const DISTRICT_CC_DASHBOARD_ROUTE = `${DISTRICT_ROOT_ROUTE}/${COMMON_CARTRIDGE_BASE_NAV_ROUTE}${DASHBOARD_ROUTE}`
export const DISTRICT_CC_CHARACTERIZATIONS_ROUTE = `${DISTRICT_ROOT_ROUTE}/${COMMON_CARTRIDGE_BASE_NAV_ROUTE}${CHARACTERIZATIONS_ROUTE}`
export const DISTRICT_USERS_ROUTE = `${DISTRICT_ROOT_ROUTE}${USERS_ROUTE}`
export const DISTRICT_ORGANIZATIONS_ROUTE = `${DISTRICT_ROOT_ROUTE}${ORGANIZATIONS_ROUTE}`
export const DISTRICT_ORG_PREF_ROUTE = `${DISTRICT_ROOT_ROUTE}${ORG_PREF_ROUTE}`
export const DISTRICT_HELP_ROUTE = `${DISTRICT_ROOT_ROUTE}${HELP_ROUTE}`

export const ADMIN_ROOT_ROUTE = '/admin'
export const ADMIN_CONFIGURATIONS_ROOT_ROUTE = `/admin/configurations`
export const ADMIN_DASHBOARD_ROUTE = `${ADMIN_ROOT_ROUTE}${DASHBOARD_ROUTE}`
export const ADMIN_ORGANIZATIONS_ROUTE = `${ADMIN_ROOT_ROUTE}${ORGANIZATIONS_ROUTE}`
export const ADMIN_SOURCES_ROUTE = `${ADMIN_CONFIGURATIONS_ROOT_ROUTE}/sources`
export const ADMIN_LTI_PLATFORMS_ROUTE = `${ADMIN_CONFIGURATIONS_ROOT_ROUTE}/ltiplatforms`
export const ADMIN_LANDING_PAGE_URL_ROUTE = `${ADMIN_CONFIGURATIONS_ROOT_ROUTE}/event_header_config`
export const ADMIN_USERS_ROUTE = `${ADMIN_ROOT_ROUTE}${USERS_ROUTE}`

export const getRoute = (
  baseRoute: string,
  id?: string | number,
  parts?: any[] | string,
  queryString?: any,
) => {
  const isAdmin = isIMSAdminRoleSelector(store.getState())
  const isSupplier = isSupplierRoleSelector(store.getState())
  let base = `${
    isAdmin
      ? ADMIN_ROOT_ROUTE
      : isSupplier
      ? SUPPLIER_ROOT_ROUTE
      : DISTRICT_ROOT_ROUTE
  }${baseRoute}`
  if (id && utils.hasValue(id)) {
    base = `${base}/${id}`
  }
  if (utils.hasValue(parts)) {
    base = `${base}${
      Array.isArray(parts) ? `/${parts.join('/')}` : `/${parts}`
    }`
  }
  if (utils.hasValue(queryString)) {
    const qs = R.compose<any, any, string[], string>(
      R.join('&'),
      R.values,
      R.mapObjIndexed((value, key) => `${key}=${value}`),
    )(queryString)
    if (utils.hasValue(qs)) {
      base = `${base}?${qs}`
    }
  }
  return base
}
