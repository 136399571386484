import React from 'react'
import * as R from 'ramda'

import IRESTComparisonReport from 'domains/characterizations/models/IRESTComparisonReport'
import IRESTServiceComparisonReport from 'domains/characterizations/models/IRESTServiceComparisonReport'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import useAccordionHeaderState from 'domains/reports/hooks/useAccordionHeaderState'
import IRESTServiceEndpointComparisonReport from 'domains/characterizations/models/IRESTServiceEndpointComparisonReport'
import IAccordionHeaderState from 'domains/characterizations/models/IAccordionHeaderState'
import { ExpansionPanel, View } from 'lib/components'
import RESTEndpointReportHeader from 'domains/reports/components/RESTEndpointReportHeader'
import RESTServiceEndpointComparisonReport from 'domains/comparisons/components/ComparisonReport/RESTServiceEndpointComparisonReport'
import { sortEndpointsForDisplay } from 'domains/modeling/utils/restCOR1p1utils'

interface IProps {
  report: IRESTComparisonReport
  service: IRESTServiceComparisonReport
  specification: IRestCSpec
  serviceExpanded: boolean
  serviceCollapsed: boolean
  allExpanded: boolean
  allCollapsed: boolean
  setAllExpandedState: (state: boolean) => any
  setAllCollapsedState: (state: boolean) => any
}

export default function RESTServiceComparisonReport(props: IProps) {
  const [
    headerStates,
    getHeaderState,
    onToggleHeader,
    onExpandAll,
    onCollapseAll,
  ] = useAccordionHeaderState(
    sortEndpointsForDisplay(
      props.service.serviceName,
      R.pathOr([], ['endpointComparisons'], props.service),
    ).map((e: any) => ({
      ...e,
      name: e.endpointName,
    })),
  )

  React.useEffect(() => {
    if (props.allExpanded) {
      onExpandAll()
    }
    if (props.allCollapsed || props.serviceCollapsed) {
      onCollapseAll()
    }
  }, [props.allCollapsed, props.allExpanded, props.serviceCollapsed]) // eslint-disable-line

  const onRequestChange = (
    endpoint: IRESTServiceEndpointComparisonReport,
  ) => () => {
    const state = getHeaderState(endpoint.endpointName)
    if (state.open && props.allExpanded) {
      props.setAllExpandedState(false)
    }
    if (!state.open && props.allCollapsed) {
      props.setAllCollapsedState(false)
    }
    onToggleHeader(endpoint.endpointName)
  }

  const renderEndpoint = (item: IAccordionHeaderState) => {
    const endpoint = item.data as IRESTServiceEndpointComparisonReport
    const headerState = getHeaderState(endpoint.endpointName)
    if (!headerState) {
      return null
    }

    return (
      <ExpansionPanel
        key={endpoint.endpointName}
        isOpen={headerState.open}
        onRequestChange={onRequestChange(endpoint)}
        hideWhenClosed={true}
        summary={
          <RESTEndpointReportHeader
            endpoint={endpoint}
            useFriendlyName={true}
            open={headerState.open}
            data-test={`endpoint-report-header-${endpoint.endpointName}`}
          />
        }
        details={
          <RESTServiceEndpointComparisonReport
            isOpen={headerState.open}
            report={props.report}
            service={props.service}
            endpoint={endpoint}
            specification={props.specification}
          />
        }
      />
    )
  }

  const { report } = props
  if (report.restComparisons.length < 1) {
    return null
  }

  return (
    <View data-test={`comparison-report-service-${props.service.serviceName}`}>
      {headerStates.map(renderEndpoint)}
    </View>
  )
}
