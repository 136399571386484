import { utils, records } from '@ims/1edtech-frontend-common'

import { getRecord } from 'lib/records/workflows/getRecord'
import store from 'lib/store'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import { OR_REST_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/orRESTCharacterizations'
import { REST_CHARACTERIZATION_REPORTS_RECORD } from 'lib/records/modules/restCharacterizationReports'

export const getFullRESTPCharacterization = async (id: string | number) => {
  await getRecord(OR_REST_CHARACTERIZATIONS_RECORD, id)
  const characterization: ICharacterization = records.entitiesSelectors.entityByIdSelector(
    OR_REST_CHARACTERIZATIONS_RECORD,
    utils.convertToInt(id),
  )(store.getState())
  if (!characterization) {
    return
  }
  await getRecord(
    REST_CHARACTERIZATION_REPORTS_RECORD,
    characterization.content,
  )
}
