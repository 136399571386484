import React from 'react'
import * as R from 'ramda'
import { RouteComponentProps } from 'react-router'
import { utils } from '@ims/1edtech-frontend-common'

import { View, Paper, Screen, Spinner } from 'lib/components'
import BaseORRosteringCharacterizationsBreadCrumbs from 'domains/characterizations/components/BaseORRosteringCharacterizationsBreadCrumbs'
import CharacterizationsDetailsWidget from 'domains/characterizations/components/DistrictCharacterizationDetails/CharacterizationsDetailsWidget'
import CharacterizationReportFileSetErrors from 'domains/characterizations/components/CharacterizationReportFileSetErrors'
import trackCharacterizationAnalytics from 'domains/characterizations/utils/trackCharacterizationAnalytics'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import ITransformedCharacterizationCSVFile from 'domains/characterizations/models/ITransformedCharacterizationCSVFile'
import OR1P1CSVCharacterizationReportUnavailable from 'domains/characterizations/components/DistrictCharacterizationDetails/OR1P1CSVCharacterizationReportUnavailable'
import ReportThreeTopSection from 'domains/reports/components/ReportThreeTopSection'
import CSVCharacterizationReportOverviewWidget from 'domains/characterizations/components/CharacterizationReport/CSVCharacterizationReportOverviewWidget'
import CharacterizationDetailsActionsWidget from 'domains/characterizations/components/CharacterizationReport/CharacterizationDetailsActionsWidget'
import CSVCharacterizationReportFiles from 'domains/characterizations/components/CharacterizationReport/CSVCharacterizationReportFiles'
import CharacterizationNotesPaper from 'domains/characterizations/components/CharacterizationReport/CharacterizationNotesPaper'
import DebugReportView from 'domains/reports/components/DebugReportView'
import useFullCSVPCharacterization from 'domains/characterizations/hooks/useFullCSVPCharacterization'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import ITransformedCharacterizationCSVReport from 'domains/characterizations/models/ITransformedCharacterizationCSVReport'
import GenericError from 'domains/application/components/GenericError'
import useAccordionHeaderState from 'domains/reports/hooks/useAccordionHeaderState'

const WIDGET_HEIGHT = 536

export default function OR1P1CSVProviderCharacterizationReportScreen(
  props: RouteComponentProps,
) {
  const [
    characterizationData,
    reportData,
    transformedReportData,
    specification,
    pending,
  ] = useFullCSVPCharacterization(props)
  const [
    fileStates,
    ,
    onToggleHeader,
    onExpandAll,
    onCollapseAll,
  ] = useAccordionHeaderState(
    R.pathOr<ITransformedCharacterizationCSVFile[]>(
      [],
      ['files'],
      transformedReportData,
    ).map((file) => ({
      name: file.fileLabel,
      open: false,
    })),
  )

  React.useEffect(() => {
    if (!pending && utils.hasValue(characterizationData)) {
      trackCharacterizationAnalytics('viewed_characterization_report', {
        id: R.pathOr(0, ['id'], characterizationData),
        format: R.pathOr('', ['format'], characterizationData),
        validationId: R.pathOr('', ['content'], characterizationData),
      })
    }
  }, [pending, characterizationData])

  if (
    pending ||
    !utils.hasValue(characterizationData) ||
    !utils.hasValue(reportData)
  ) {
    if (
      !pending &&
      (!utils.hasValue(characterizationData) || !utils.hasValue(reportData))
    ) {
      return <GenericError />
    }
    return (
      <View variant="screen">
        <Spinner centered={true} />
      </View>
    )
  }

  const transformedReport = transformedReportData as ITransformedCharacterizationCSVReport
  const characterization = characterizationData as ICharacterization

  const characterizationName = R.pathOr('', ['name'], characterization)

  return (
    <Screen>
      <DocumentTitle title={`Characterization: ${characterizationName}`} />
      <BaseORRosteringCharacterizationsBreadCrumbs
        crumbs={[{ name: utils.string.ellipsize(characterizationName, 30) }]}
      />
      <View flexible="column">
        <ReportThreeTopSection>
          <CharacterizationsDetailsWidget
            characterization={characterization}
            height={WIDGET_HEIGHT}
          />
          <CSVCharacterizationReportOverviewWidget
            report={transformedReport}
            height={WIDGET_HEIGHT}
          />
          <CharacterizationDetailsActionsWidget
            characterization={characterization}
            report={transformedReport}
            expandAll={onExpandAll}
            collapseAll={onCollapseAll}
            format={characterization.format}
            height={WIDGET_HEIGHT}
          />
        </ReportThreeTopSection>

        <CharacterizationNotesPaper characterization={characterization} />

        <Paper>
          <OR1P1CSVCharacterizationReportUnavailable
            report={transformedReport}
          />

          <CharacterizationReportFileSetErrors report={transformedReport} />

          <CSVCharacterizationReportFiles
            report={transformedReport}
            specification={specification}
            fileStates={fileStates}
            onFileRequestChange={onToggleHeader}
          />
        </Paper>
      </View>

      <DebugReportView rawReport={reportData} title="Charsys Report JSON" />
      <DebugReportView
        rawReport={transformedReport}
        title="Transformed Report JSON"
      />
    </Screen>
  )
}
