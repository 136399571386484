import React from 'react'
import { useSelector } from 'react-redux'
import { records } from '@ims/1edtech-frontend-common'

import { NOTIFICATIONS_RECORD } from 'lib/records/modules/notifications'
import INotification from 'domains/notifications/models/INotification'
import { hideNotificationCenter } from 'domains/notifications/components/NotificationCenter/NotificationCenter'
import { getOnClickNotificationAction } from 'domains/notifications/workflows/getOnClickNotificationAction'
import { markNotificationAsRead } from 'domains/notifications/workflows/markNotificationAsRead'
import { deleteNotification } from 'domains/notifications/workflows/deleteNotification'
import NotifcationRowComponent from 'domains/notifications/components/NotifcationRowComponent'

interface IProps {
  index: number
  id: number
}

export default function NotifcationRow(props: IProps) {
  const notification = useSelector((state: any) =>
    records.entitiesSelectors.entityByIdSelector(NOTIFICATIONS_RECORD, 'id')(
      state,
      props,
    ),
  ) as INotification

  if (!notification) {
    return null
  }

  const onClickNotification = getOnClickNotificationAction(notification, true)

  const onMarkAsRead = async (e?: any) => {
    if (e) {
      e.stopPropagation()
    }
    await markNotificationAsRead(props.id)
  }

  const onDelete = async (e?: any) => {
    if (e) {
      e.stopPropagation()
    }
    await deleteNotification(props.id)
  }

  const onClick = async () => {
    if (onClickNotification) {
      await onMarkAsRead()
      await onClickNotification()
      hideNotificationCenter()
    }
  }

  const onMenuItemClicked = async ({ name }: { name: string }) => {
    switch (name.toLowerCase()) {
      case 'mark as read':
        await onMarkAsRead()
        break
      case 'delete':
        await onDelete()
        break
    }
  }

  return (
    <NotifcationRowComponent
      index={props.index}
      notification={notification}
      onClickNotification={onClickNotification}
      onMarkAsRead={onMarkAsRead}
      onDelete={onDelete}
      onClick={onClick}
      onMenuItemClicked={onMenuItemClicked}
    />
  )
}
