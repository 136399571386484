export const CC_TYPE_ITEM = 'Item'
export const CC_TYPE_RESOURCE = 'Resource'
export const CC_TYPE_STANDARD = 'Standard'
export const CC_TYPE_METADATA = 'Metadata'

export type CC_TYPES = 'Item' | 'Resource' | 'Standard' | 'Metadata'

export const CCReportTypeIcon: { [key: string]: string } = {
  Item: 'fas fa-sitemap',
  Resource: 'fas fa-anchor',
  Standard: 'fas fa-file-alt',
  Metadata: 'fas fa-info-circle',
}
