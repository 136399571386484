import React from 'react'

import { List } from 'lib/components'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import LargeWidget from 'domains/dashboard/components/LargeWidget'
import CharacterizationButton from 'domains/characterizations/components/CharacterizationButton'
import CharacterizationNameCell from 'domains/characterizations/components/CharacterizationNameCell'
import ICharacterization from 'domains/characterizations/models/ICharacterization'

interface IProps {
  recentCharacterisations: ICharacterization[]
  fadeDelay: number
}

const columns = [
  {
    title: null,
    titleRender: <WidgetHeader title="Recent Characterizations" />,
    noCenter: true,
    CellComponent: CharacterizationNameCell,
    cellProps: { maxWidthArray: [140, 325, 500, 325], noResponsiveIcon: true },
  },
  {
    title: 'Created',
    CellComponent: (props: any) => (
      <CharacterizationButton characterization={props} />
    ),
  },
]

export default function RecentORCharacterizationsWidget(props: IProps) {
  return (
    <LargeWidget
      fadeDelay={props.fadeDelay}
      dataTest="recent-oneroster-characterizations-widget"
    >
      <List
        noWrapper={true}
        columns={columns}
        nonRecordItems={props.recentCharacterisations}
        rowHeight={90}
        dataTest="recent-oneroster-characterizations-widget-list"
        blankSlateText="No Recent OneRoster Characterizations"
        noSort={true}
      />
    </LargeWidget>
  )
}
