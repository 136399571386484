import React from 'react'

import IIMSApplication from 'domains/trustedApps/models/IIMSApplication'
import TrustedAppLogo from 'domains/trustedApps/components/TrustedAppsList/TrustedAppLogo'

interface IProps {
  app: IIMSApplication
  textColor?: string
}

export default function TrustedAppRowName(props: IProps) {
  const { textColor = 'text-text' } = props
  return (
    <div className="flex flex-row items-center">
      <TrustedAppLogo
        title={props.app.productName}
        logo={props.app.productImage}
      />
      <div className="ml-2">
        <p className={`text-sm md:text-base lg:text-lg font-bold ${textColor}`}>
          {props.app.productName}
        </p>
        <p className={`text-xs lg:text-sm font-light ${textColor}`}>
          by {props.app.orgName}
        </p>
      </div>
    </div>
  )
}
