import { useEffect, useState } from 'react'
import { utils, records } from '@ims/1edtech-frontend-common'

import { useSelector } from 'react-redux'
import { getNavigationParam } from 'lib/utils/navigation'
import { RootState } from 'lib/store/rootReducer'
import { ccCharacterizationPropSelector } from 'domains/characterizations/selectors/characterizations'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import ICommonCartridgeCharacterizationReport from 'domains/commonCartridge/models/ICommonCartridgeCharacterizationReport'
import { getFullCommonCartridgeCharacterization } from 'domains/commonCartridge/workflows/getFullCommonCartridgeCharacterization'
import { COMMON_CARTRIDGE_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/commonCartridgeCharacterizations'
import { COMMON_CARTRIDGE_CHARACTERIZATION_REPORTS_RECORD } from 'lib/records/modules/commonCartridgeCharacterizationReports'

export default function useFullCommonCartridgeCharacterization(
  props: any,
  characterizationIdParam = 'id',
): [ICharacterization, ICommonCartridgeCharacterizationReport, boolean] {
  const characterizationId = utils.convertToInt(
    getNavigationParam(props, characterizationIdParam),
  )

  useEffect(() => {
    const fetch = async () => {
      await getFullCommonCartridgeCharacterization(characterizationId)
      setPending(false)
    }
    fetch()
  }, [characterizationId])

  const [pending, setPending] = useState(true)

  const characterization = useSelector((state: RootState) =>
    records.entitiesSelectors.entityByIdSelector(
      COMMON_CARTRIDGE_CHARACTERIZATIONS_RECORD,
      characterizationId,
    )(state, props),
  ) as ICharacterization

  const characterizationReport = useSelector(
    records.entitiesSelectors.entityByIdSelector(
      COMMON_CARTRIDGE_CHARACTERIZATION_REPORTS_RECORD,
      ccCharacterizationPropSelector('content', characterizationId),
    ),
  ) as ICommonCartridgeCharacterizationReport

  return [characterization, characterizationReport, pending]
}
