import React, { useState } from 'react'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'
import { useSelector } from 'react-redux'
import memoize from 'fast-memoize'

import {
  View,
  Text,
  Switch,
  TextArea,
  List,
  Button,
  Icon,
} from 'lib/components'
import IRestCModelServiceEndpoint from 'domains/modeling/models/IRestCModelServiceEndpoint'
import IRestCModel from 'domains/modeling/models/IRestCModel'
import ModelServiceEndpointParamEditor from 'domains/modeling/components/ProductModeling/ModelServiceEndpointParamEditor'
import { IServicesChildEndpoints } from 'domains/modeling/models/IServicesChildEndpoints'
import { findModelServiceByParentEndpointName } from 'domains/modeling/utils/restCOR1p1utils'
import { IListColumnDefinition } from 'lib/components/List'
import RestCAttributeRequiredCell from 'domains/modeling/components/ProductModeling/RestCAttributeRequiredCell'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import IRestCModelService from 'domains/modeling/models/IRestCModelService'
import RestCAttributeDescriptionCell from 'domains/modeling/components/ProductModeling/RestCAttributeDescriptionCell'
import RestCAttributeNotesCell from 'domains/modeling/components/ProductModeling/RestCAttributeNotesCell'
import IRestCModelServiceEndpointAttribute, {
  mockRestCModelServiceEndpointAttribute,
} from 'domains/modeling/models/IRestCModelServiceEndpointAttribute'
import CreateEditModelMetadataModal from 'domains/modeling/modals/CreateEditModelMetadataModal'
import { utils } from '@ims/1edtech-frontend-common'
import { OR_1P1_REST_ROSTERING_CONSUMER_SERVICES } from 'domains/modeling/constants/restCOneRoster1p1'
import { myOrganizationPropSelector } from 'domains/authentication/selectors/organizations'
import { RootState } from 'lib/store/rootReducer'
import RestCAttributeEditMetadataCell from 'domains/modeling/components/ProductModeling/RestCAttributeEditMetadataCell'
import RestCAttributeNameCell from 'domains/modeling/components/ProductModeling/RestCAttributeNameCell'
import RestCAttributeDeleteCell from 'domains/modeling/components/ProductModeling/RestCAttributeDeleteCell'
import RestCAttributeFormatCell from 'domains/modeling/components/ProductModeling/RestCAttributeFormatCell'

const keyExtractor = (attribute: IRestCModelServiceEndpointAttribute) =>
  attribute._internalId ? attribute._internalId : attribute.id

const findParamIndex = memoize(
  (endpoint: IRestCModelServiceEndpoint, name: string) =>
    R.findIndex(R.propEq('name', name), endpoint.params),
)
const findParam = memoize(
  (endpoint: IRestCModelServiceEndpoint, name: string) =>
    R.find(R.propEq('name', name), endpoint.params),
)

function Subtitle(props: { color?: string; children: any }) {
  return (
    <Text fontWeight={700} ml={2} mt={3} mb={2} color={props.color}>
      {props.children}
    </Text>
  )
}
function ChildEndpointToggle(props: {
  parentEndpointName: string
  setFieldValue: any
  childEndpoints: IServicesChildEndpoints
  childEndpointName: string
}) {
  const childEndpoint =
    props.childEndpoints[props.parentEndpointName][props.childEndpointName]
  const onChange = () => {
    props.setFieldValue(
      `childEndpoints[${props.parentEndpointName}][${props.childEndpointName}].enabled`,
      !childEndpoint.enabled,
    )
  }

  return (
    <View flexible="row-v-center" mb={2} ml={1}>
      <Switch
        on={childEndpoint.enabled}
        onChange={onChange}
        small={true}
        dataTest={`child-endpoint-${childEndpoint.name}`}
      />
      <Text ml={3}>{childEndpoint.leaf}</Text>
    </View>
  )
}

const getRowProps = memoize(
  (attribute: IRestCModelServiceEndpointAttribute) => {
    const defaultProps = { 'data-test': `${attribute.name}-row` }
    if (attribute.metadata) {
      return R.assoc('bg', 'customMetaDataBG', defaultProps)
    }
    return defaultProps
  },
)

const getAttributeTableColumns = (
  specification: IRestCSpec,
  service: IRestCModelService,
  endpoint: IRestCModelServiceEndpoint,
  endpointIndex: number,
  handleChange: any,
  setFieldValue: any,
): IListColumnDefinition[] => {
  const cellProps = {
    specification,
    service,
    endpoint,
    endpointIndex,
    handleChange,
    setFieldValue,
  }

  return [
    {
      title: 'Data Element Name',
      maxWidth: [140, 140, 200, 350],
      CellComponent: RestCAttributeNameCell,
      noCenter: true,
    },
    {
      title: 'Required',
      maxWidth: 140,
      CellComponent: RestCAttributeRequiredCell,
      noCenter: true,
      noHeaderCenter: true,
      cellProps,
    },
    {
      title: 'Format',
      noCenter: true,
      CellComponent: RestCAttributeFormatCell,
      maxWidth: 100,
      noHeaderCenter: true,
      cellProps,
    },
    {
      title: 'Multiplicity',
      maxWidth: 70,
      noCenter: true,
      noHeaderCenter: true,
      accessor: 'multiplicity',
      cellProps,
    },
    {
      title: 'Description',
      noCenter: true,
      noHeaderCenter: true,
      maxWidth: 300,
      CellComponent: RestCAttributeDescriptionCell,
      cellProps,
    },
    {
      title: 'Notes',
      maxWidth: 110,
      CellComponent: RestCAttributeNotesCell,
      forceCenter: true,
      cellProps,
    },
    {
      title: null,
      maxWidth: 36,
      CellComponent: RestCAttributeEditMetadataCell,
      forceCenter: true,
      cellProps,
    },
    {
      title: null,
      maxWidth: 36,
      CellComponent: RestCAttributeDeleteCell,
      forceCenter: true,
      cellProps,
    },
  ]
}

interface IProps {
  model: IRestCModel
  specification: IRestCSpec
  endpoint: IRestCModelServiceEndpoint
  endpointIndex: number
  childEndpoints: IServicesChildEndpoints
  setFieldValue: any
  handleChange: any
}

const defaultMetaData = R.dissoc(
  'id',
  mockRestCModelServiceEndpointAttribute({
    name: '',
    description: '',
    notes: '',
    metadata: true,
  }),
) as IRestCModelServiceEndpointAttribute

export default function ProductModelingRestServiceEdit(props: IProps) {
  const orgName = useSelector((s: RootState) =>
    myOrganizationPropSelector('name', '')(s, props),
  )
  const [metadata, setMetadata] = useState<
    IRestCModelServiceEndpointAttribute | boolean
  >(false)
  const onAddMetadata = () => setMetadata(defaultMetaData)
  const onCloseMetadata = () => setMetadata(false)
  const onSaveMetadata = (
    savedMetadata: IRestCModelServiceEndpointAttribute,
  ) => {
    props.setFieldValue(`endpoints[${props.endpointIndex}].attributes`, [
      ...props.endpoint.attributes,
      { ...savedMetadata, _internalId: uuid() },
    ])
  }

  const { endpoint, endpointIndex, setFieldValue } = props

  const notesEditor = (disabled?: boolean) => (
    <View mt={4}>
      <Subtitle color={disabled ? 'white' : 'text'}>Notes</Subtitle>
      <TextArea
        width="100%"
        placeholder="Endpoint notes"
        name={`endpoints[${props.endpointIndex}].notes`}
        onChange={props.handleChange}
        value={props.endpoint.notes}
        variant="bold"
        aria-label="Endpoint notes"
        rows={4}
        mt={2}
      />
    </View>
  )

  if (!endpoint.enabled) {
    return (
      <View mt={3}>
        <Text color="white" ml={2}>
          This endpoint has been identified as not used. To model this endpoint
          please turn it on.
        </Text>

        {notesEditor(true)}
      </View>
    )
  }

  const service = findModelServiceByParentEndpointName(
    props.model,
    props.endpoint.name,
    true,
  )

  return (
    <View>
      <Subtitle>Include</Subtitle>
      <View flexible="row-wrap" overflow="hidden">
        <ModelServiceEndpointParamEditor
          endpointIndex={endpointIndex}
          paramIndex={findParamIndex(endpoint, 'filter')!}
          param={findParam(endpoint, 'filter')!}
          setFieldValue={setFieldValue}
        />
        <ModelServiceEndpointParamEditor
          endpointIndex={endpointIndex}
          paramIndex={findParamIndex(endpoint, 'fields')!}
          param={findParam(endpoint, 'fields')!}
          setFieldValue={setFieldValue}
        />
        <ModelServiceEndpointParamEditor
          endpointIndex={endpointIndex}
          paramIndex={findParamIndex(endpoint, 'sort')!}
          param={findParam(endpoint, 'sort')!}
          setFieldValue={setFieldValue}
        />
        <ModelServiceEndpointParamEditor
          endpointIndex={endpointIndex}
          paramIndex={findParamIndex(endpoint, 'orderBy')!}
          param={findParam(endpoint, 'orderBy')!}
          setFieldValue={setFieldValue}
          noNotes={true}
        />
        <ModelServiceEndpointParamEditor
          endpointIndex={endpointIndex}
          paramIndex={findParamIndex(endpoint, 'offset')!}
          param={findParam(endpoint, 'offset')!}
          setFieldValue={setFieldValue}
          locked={true}
          noNotes={true}
        />
        <ModelServiceEndpointParamEditor
          endpointIndex={endpointIndex}
          paramIndex={findParamIndex(endpoint, 'limit')!}
          param={findParam(endpoint, 'limit')!}
          setFieldValue={setFieldValue}
          locked={true}
          noNotes={true}
        />
      </View>

      <Subtitle>Additional Child Endpoints</Subtitle>
      {R.keys(props.childEndpoints[props.endpoint.name]).map(
        (childEndpointName) => (
          <ChildEndpointToggle
            key={childEndpointName}
            parentEndpointName={props.endpoint.name}
            setFieldValue={setFieldValue}
            childEndpoints={props.childEndpoints}
            childEndpointName={childEndpointName as string}
          />
        ),
      )}

      {notesEditor(false)}

      <View mt={4} />
      <Subtitle>{service.name} Data Elements</Subtitle>
      <View bg="white">
        <List
          dataTest={`attributes-${props.endpoint.name}`}
          noWrapper={true}
          nonRecordItems={props.endpoint.attributes}
          columns={getAttributeTableColumns(
            props.specification,
            service,
            props.endpoint,
            props.endpointIndex,
            props.handleChange,
            setFieldValue,
          )}
          noSort={true}
          getRowProps={getRowProps}
          keyExtractor={keyExtractor}
        />
        <Button
          onClick={onAddMetadata}
          m={2}
          variant="tertiary"
          data-test="add-metadata"
        >
          <View flexible="row-v-center">
            <Icon className="fas fa-plus-square" color="white" mr={2} />
            <Text color="white">Add Metadata</Text>
          </View>
        </Button>
      </View>

      <CreateEditModelMetadataModal
        isOpen={utils.hasValue(metadata)}
        isCreate={true}
        metadata={
          metadata
            ? (metadata as IRestCModelServiceEndpointAttribute)
            : defaultMetaData
        }
        close={onCloseMetadata}
        onSave={onSaveMetadata}
        serviceName={service.name as OR_1P1_REST_ROSTERING_CONSUMER_SERVICES}
        orgName={orgName}
      />
    </View>
  )
}
