import { mergeOrReplaceEntities } from 'lib/records/workflows/entities'
import modules from 'lib/records/modules'
import { getRequest } from 'lib/api/api'
import { removeEntity } from 'lib/store/entities.actions'
import store from 'lib/store'

export const getRecord = async (
  record: string,
  id: string | number,
  requestParams = {},
) => {
  const recordModule = modules[record]

  const response = await getRequest(recordModule.api.get(id, requestParams))

  if (response.success) {
    const { data } = response

    mergeOrReplaceEntities(true, recordModule, data)
  } else {
    store.dispatch(removeEntity(record, `${id}`))
  }

  return response
}
