import React from 'react'
import * as R from 'ramda'

import { ToolTip, ListPlainTextCell } from 'lib/components'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps extends ICharacterization {
  prop: string
  fallbackProp?: string
  concatProp?: string
  concatString?: string
}

export default function CharacterizationDistrictPropCell(props: IProps) {
  const { id, prop, fallbackProp, concatProp = '', concatString = ', ' } = props

  const tooltipId = `${id}-district-${prop}`
  const fallBackValue = R.pathOr('', [fallbackProp || ''], props)
  let value = R.pathOr(fallBackValue, [prop], props)
  const concatValue = R.pathOr('', [concatProp], props)

  if (utils.hasValue(concatValue)) {
    value = `${value}${concatString}${concatValue}`
  }
  return (
    <div>
      <ToolTip id={tooltipId} tip={value} delay={500}>
        <ListPlainTextCell>{value}</ListPlainTextCell>
      </ToolTip>
    </div>
  )
}
