import React from 'react'

import IFileSetCharacterizationColumn from 'domains/characterizations/models/IFileSetCharacterizationColumn'
import { ListPlainTextCell } from 'lib/components'

export default function CSVCharacterizationColumnNameCell(
  props: IFileSetCharacterizationColumn,
) {
  return <ListPlainTextCell mr={2}>{props.headerName}</ListPlainTextCell>
}
