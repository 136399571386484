import { records } from '@ims/1edtech-frontend-common'

import store from 'lib/store'
import { createRecord } from 'lib/records/workflows/createRecord'
import { CSV_C_MODELS_RECORD } from 'lib/records/modules/csvCModels'
import { AnySpecFormatType } from 'domains/formats/constants/formats'
import { getFullProduct } from 'domains/products/workflows/getFullProduct'
import {
  FILE_SET_TO_DISPLAY_NAME,
  getFilesForSpecFileSet,
} from 'domains/modeling/utils/csvCOneRoster1p1Utils'
import { trackCSVCModelingAnalytics } from 'domains/modeling/utils/trackModelingAnalytics'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'
import { getProductFormatModelId } from 'domains/products/utils/productByModel'
import { OR1P1Subsets } from 'domains/specifications/constants/oneRoster1p1FileSets'

export const createCsvCModel = async (
  productId: string | number,
  format: AnySpecFormatType,
  fileSet: OR1P1Subsets,
) => {
  const orgId = myOrganizationIdSelector(store.getState(), {})
  const model = {
    name: FILE_SET_TO_DISPLAY_NAME[fileSet],
    productId,
    orgId,
    format,
    files: getFilesForSpecFileSet(fileSet),
    delta: false,
  }
  const { success } = await createRecord(CSV_C_MODELS_RECORD, model)
  if (!success) {
    return success
  }
  await getFullProduct(productId, format)
  const product = records.entitiesSelectors.entityByIdSelector(
    PRODUCTS_RECORD,
    productId,
  )(store.getState())
  const modelId = getProductFormatModelId(product, format)
  const createdModel = records.entitiesSelectors.entityByIdSelector(
    CSV_C_MODELS_RECORD,
    modelId,
  )(store.getState())

  trackCSVCModelingAnalytics('started_modeling')
  return createdModel
}
