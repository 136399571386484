import React, { PureComponent } from 'react'
import * as R from 'ramda'

import { Icon, View, Image } from 'lib/components'
import CharacterizationIcon from 'domains/characterizations/components/CharacterizationIcon'
import FormatLineItems from 'domains/modeling/components/FormatLineItems'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import ProductImageCell from 'domains/products/components/ProductImageCell'
import IComparison from 'domains/comparisons/models/IComparison'
import getOrgLogo from 'domains/orgs/utils/getOrgLogo'
import { utils } from '@ims/1edtech-frontend-common'
import {
  getComparisonReportFormatLineItems,
  getComparisonReportFormatAfterLineItems,
} from 'domains/comparisons/utils/getComparisonReportFormatLineItems'
import IRESTComparisonReport from 'domains/characterizations/models/IRESTComparisonReport'
import { ICSVComparisonReport } from 'domains/comparisons/models/ICSVComparisonReport'
import IProduct from 'domains/products/models/IProduct'
import DistrictProductCatalogPPCell from 'domains/products/components/DistrictProductCatalogPPCell'

interface IProps {
  comparison: IComparison
  report: ICSVComparisonReport | IRESTComparisonReport
  product: IProduct
  isSupplier: boolean
  height?: number
}

export default class ComparisonReportDetailsWidget extends PureComponent<IProps> {
  renderCharacterizationIcon() {
    const { comparison, isSupplier } = this.props
    const charIcon = (
      <CharacterizationIcon
        color={comparison.characterizationColor}
        size={100}
        noResponsive={true}
      />
    )
    if (isSupplier) {
      return utils.hasValue(comparison.districtLogoUrl) ? (
        <Image
          alt={`The logo for district: ${comparison.districtName}`}
          src={getOrgLogo(comparison.districtLogoUrl)}
          maxWidth={100}
          maxHeight={100}
          borderRadius="50%"
        />
      ) : (
        charIcon
      )
    }

    return charIcon
  }

  render() {
    const { comparison, product } = this.props
    const hasPreferrers = utils.hasValue(
      R.pathOr(false, ['preferrers'], product),
    )
    const productImageCellSize = hasPreferrers ? 148 : 100

    return (
      <DetailsTopWidget
        title="Comparison Details"
        dataTest="comparison-details-widget"
        height={this.props.height || 472}
      >
        <View pl={[0, 2]} flexible="column-h-center">
          <View flexible="row-center" mt={3} mb={4} pr={4}>
            {this.renderCharacterizationIcon()}
            <View flexible="column-center" m={[3, 4]}>
              <View
                bg="text"
                borderRadius="4px"
                width="28px"
                height="28px"
                flexible="column-center"
              >
                <Icon className="fas fa-plus" color="white" />
              </View>
            </View>

            <View
              position="relative"
              width={productImageCellSize}
              height={productImageCellSize}
              flexible="column-center"
            >
              <ProductImageCell
                name={comparison.productName}
                logo={comparison.productLogo}
                size={100}
              />
              {hasPreferrers && (
                <View position="absolute" top={0} right={0}>
                  <DistrictProductCatalogPPCell {...product} />
                </View>
              )}
            </View>
          </View>

          <FormatLineItems
            format={comparison}
            dataTest="comparison-format-details"
            keyWidth={136}
            beforeItems={getComparisonReportFormatLineItems(
              comparison,
              this.props.isSupplier,
              this.props.product,
              this.props.report,
            )}
            afterItems={getComparisonReportFormatAfterLineItems(
              comparison,
              this.props.report,
            )}
            hideType={true}
            baseFormat={true}
            wordBreak="break-word"
          />
        </View>
      </DetailsTopWidget>
    )
  }
}
