import * as normalizr from 'normalizr'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

export const ORG_ACTIONS_RECORD = 'orgActions'

const orgActions: records.modules.IModule = {
  record: ORG_ACTIONS_RECORD,
  schema: new normalizr.schema.Entity(
    ORG_ACTIONS_RECORD,
    {},
    {
      idAttribute: 'name',
      processStrategy: (entity: any) =>
        R.assoc('id', R.prop('name', entity), entity),
    },
  ),
  api: {
    create: () => 'actions',
    get: (id) => `actions/${id}`,
    getList: () => 'actions',
    update: (id) => `actions/${id}`,
    delete: (id) => `actions/${id}`,
  },
}

export default orgActions
