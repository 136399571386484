import React from 'react'

import IRestCModelServiceEndpointAttribute from 'domains/modeling/models/IRestCModelServiceEndpointAttribute'
import { ListPlainTextCell } from 'lib/components'
import { FORMAT_TO_DISPLAY } from 'domains/characterizations/models/CharacterizationReport'

export default function RestCAttributeFormatCell(
  props: IRestCModelServiceEndpointAttribute,
) {
  const { dataType } = props
  return (
    <ListPlainTextCell>
      {dataType ? FORMAT_TO_DISPLAY[dataType] : null}
    </ListPlainTextCell>
  )
}
