import * as R from 'ramda'
import { createSelector } from 'reselect'
import { utils, records } from '@ims/1edtech-frontend-common'

import { ORGANIZATIONS_RECORD } from 'lib/records/modules/organizations'
import { meSelector } from 'domains/authentication/selectors/me'
import getOrgLogo from 'domains/orgs/utils/getOrgLogo'
import { RootState } from 'lib/store/rootReducer'
import IUser from 'domains/users/models/IUser'
import IOrg from 'domains/orgs/models/IOrg'
import { NON_MEMBER_ORG_MEMBERSHIP_DISPLAY } from 'domains/orgs/constants/organization'

export const myOrganizationIdSelector = createSelector<
  RootState,
  any,
  IUser | null,
  any
>(meSelector, R.pathOr(null, ['organization', 'id']))

export const myOrganizationPropSelector = (
  prop: string | number | (string | number)[],
  fallback: any = null,
) =>
  createSelector<RootState, any, IOrg, any>(
    records.entitiesSelectors.entityByIdSelector(
      ORGANIZATIONS_RECORD,
      myOrganizationIdSelector,
    ),
    R.pathOr(fallback, utils.array.ensureArray(prop)),
  )

export const myOrganizationLogoSelector = createSelector<
  RootState,
  any,
  string,
  string
>(myOrganizationPropSelector('logourl', ''), getOrgLogo)

export const isMyOrgFreemiumSelector = createSelector(
  myOrganizationPropSelector('referrerId', ''),
  myOrganizationPropSelector('membershipLevel', ''),
  (referrerId, membershipLevel: string) =>
    utils.hasValue(referrerId) &&
    membershipLevel === NON_MEMBER_ORG_MEMBERSHIP_DISPLAY,
)
