import React, { PureComponent } from 'react'
import { utils } from '@ims/1edtech-frontend-common'
import * as R from 'ramda'
import { withRouter, RouteComponentProps } from 'react-router'

import { getProductModelCharacterizationRoute } from 'domains/products/navigation/routes'
import Dialog from 'domains/application/modals/Dialog'
import { createCSVCModelCharacterization } from 'domains/characterizations/workflows/createCSVCModelCharacterization'
import { waitForServer } from 'lib/utils/wait'
import { CSV_C_MODEL_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/csvCModelCharacterizations'
import { showToast, ERROR_TOAST } from 'lib/utils/toast'
import {
  ANY_FORMAT_TO_SPEC_FORMAT_MAP,
  ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
  AnyFormatType,
  ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
} from 'domains/formats/constants/formats'
import { createRESTCModelCharacterization } from 'domains/characterizations/workflows/createRESTCModelCharacterization'
import { REST_C_MODEL_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/restCModelCharacterizations'

interface IProps {
  open: boolean
  closeModal: () => any
  modelId: string | number
  productId: string | number
  format: AnyFormatType
}

const initialState = {
  launchCharDialogOpen: false,
}
type State = Readonly<typeof initialState>

export class CharacterizeProductModal extends PureComponent<
  IProps & RouteComponentProps
> {
  readonly state: State = initialState

  private unmounted = false

  componentWillUnmount() {
    this.unmounted = true
  }

  onLaunchCharacterization = async () => {
    const { modelId, format, closeModal } = this.props
    const specFormat = ANY_FORMAT_TO_SPEC_FORMAT_MAP[format]
    switch (specFormat) {
      case ONER_V1P1_CSV_ROSTERING_IMPORT_BULK: {
        const characterization = await createCSVCModelCharacterization(modelId)
        if (!characterization) {
          showToast(ERROR_TOAST, 'Failed to characterize model')
          closeModal()
          return
        }
        await waitForServer(
          CSV_C_MODEL_CHARACTERIZATIONS_RECORD,
          modelId,
          (data) => utils.hasValue(R.propOr(false, 'summary', data)),
        )
        break
      }
      case ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL: {
        const characterization = await createRESTCModelCharacterization(modelId)
        if (!characterization) {
          showToast(ERROR_TOAST, 'Failed to characterize model')
          closeModal()
          return
        }
        await waitForServer(
          REST_C_MODEL_CHARACTERIZATIONS_RECORD,
          modelId,
          (data) => utils.hasValue(R.propOr(false, 'summary', data)),
        )
        break
      }
      default:
        break
    }
    if (!this.unmounted) {
      this.goToCharacterization()
    }
  }

  onLaunchCharacterizationComfirm = () =>
    this.setState({ launchCharDialogOpen: true })

  goToCharacterization = () =>
    this.props.history.push(
      getProductModelCharacterizationRoute(
        this.props.productId,
        this.props.modelId,
      ),
    )

  render() {
    return (
      <Dialog
        isOpen={this.props.open}
        icon="fas fa-check-square"
        message="You are about to characterize your model. Is your model complete?"
        onConfirm={this.onLaunchCharacterization}
        onDeny={this.props.closeModal}
      />
    )
  }
}

export default withRouter(CharacterizeProductModal)
