import React from 'react'

type Props = {
  color: string
  icon?: string
  label: string
}

export default function TrustedAppLegendKey(props: Props) {
  return (
    <div className="flex flex-row items-center">
      {!!props.icon && <i className={`text-xl ${props.icon} ${props.color}`} />}
      {!props.icon && <div className={`w-3 h-3 rounded-full ${props.color}`} />}
      <p className="ml-3 uppercase font-bold text-sm">{props.label}</p>
    </div>
  )
}
