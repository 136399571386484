import React from 'react'

import { Icon } from 'lib/components'
import {
    ReportStatus,
    STATUS_TO_BADGE_VARIANT_MAP,
    STATUS_TO_BADGE_ICON_MAP,
} from 'domains/reports/models/IReportStatuses'

interface IProps {
    status?: ReportStatus
    autoResize?: boolean
    fontSize?: number
    circle?: boolean
    'data-test'?: string
}

export default function ReportStatusIcon(props: IProps) {
    const { autoResize, status, fontSize } = props

    const defaultFontSize = fontSize || 24

    if(!status)
        return null

    return (
        <Icon
            color={STATUS_TO_BADGE_VARIANT_MAP[status]}
            className={`${STATUS_TO_BADGE_ICON_MAP[status]}${
                props.circle ? '-circle' : ''
            }`}
            fontSize={[
                fontSize ? fontSize : autoResize ? 16 : defaultFontSize,
                defaultFontSize,
            ]}
            data-icon={STATUS_TO_BADGE_ICON_MAP[status]}
            data-status={status}
            data-test={props['data-test']}
        />
    )
} 
