import { useEffect, useState } from 'react'
import * as R from 'ramda'
import { utils, records } from '@ims/1edtech-frontend-common'

import { useSelector } from 'react-redux'
import { getNavigationParam } from 'lib/utils/navigation'
import { RootState } from 'lib/store/rootReducer'
import { getFullRESTCCharacterization } from 'domains/characterizations/workflows/getFullRESTCCharacterization'
import IRestCModelCharacterization from 'domains/characterizations/models/IRestCModelCharacterization'
import { REST_C_MODEL_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/restCModelCharacterizations'
import { REST_C_MODEL_CHARACTERIZATION_REPORTS_RECORD } from 'lib/records/modules/restCModelCharacterizationReports'
import IRESTComparisonReport from 'domains/characterizations/models/IRESTComparisonReport'
import ITransformedRESTCharacterizationReport from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import { transformRESTModelCharacterizationReport } from 'domains/characterizations/utils/transformRESTModelCharacterizationReport'

export default function useFullRESTCCharacterization(
  props: any,
  productIdParam = 'id',
  modelIdParam = 'modelId',
): [
  IRestCModelCharacterization,
  IRESTComparisonReport | undefined,
  ITransformedRESTCharacterizationReport | undefined,
  boolean,
] {
  const [pending, setPending] = useState(true)

  const productId = utils.convertToInt(
    getNavigationParam(props, productIdParam),
  )
  const modelId = utils.convertToInt(getNavigationParam(props, modelIdParam))

  useEffect(() => {
    const fetch = async () => {
      await getFullRESTCCharacterization(modelId)
      setPending(false)
    }
    fetch()
  }, [modelId])

  const restModelCharacterization = useSelector((state: RootState) =>
    records.entitiesSelectors.entityByIdSelector(
      REST_C_MODEL_CHARACTERIZATIONS_RECORD,
      productId,
    )(state, props),
  ) as IRestCModelCharacterization

  const characterizationLink = R.prop(
    'characterizationLink',
    restModelCharacterization,
  )
  const restModelCharacterizationReport = useSelector((state: RootState) =>
    records.entitiesSelectors.entityByIdSelector(
      REST_C_MODEL_CHARACTERIZATION_REPORTS_RECORD,
      'characterizationLink',
    )(state, { ...props, characterizationLink }),
  ) as IRESTComparisonReport

  const transformedReport = restModelCharacterizationReport
    ? transformRESTModelCharacterizationReport(restModelCharacterizationReport)
    : undefined

  return [
    restModelCharacterization,
    restModelCharacterizationReport,
    transformedReport,
    pending,
  ]
}
