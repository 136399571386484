import React from 'react'
import IOrg from 'domains/orgs/models/IOrg'
import { utils } from '@ims/1edtech-frontend-common'

import { View, ListPlainTextCell } from 'lib/components'

interface IProps extends IOrg {
  nonList?: boolean
}

export default function OrganizationLockoutDateCell(props: IProps) {
  const lockoutDate = utils.date.getMoment(
    props.lockoutDate || 999999999999999999,
  )
  const isLockedOut = lockoutDate.isSameOrBefore(utils.date.getMoment(), 'day')
  const thirtyDaysFromNow = utils.date.getMoment().endOf('day').add(30, 'days')
  const isWithin30OfLockout = lockoutDate.diff(thirtyDaysFromNow, 'days') <= 0
  const allClear = !isLockedOut && !isWithin30OfLockout
  const bg = isLockedOut
    ? 'error'
    : isWithin30OfLockout
    ? 'warningAlt'
    : 'transparent'

  return (
    <View
      p={allClear && props.nonList ? 0 : 2}
      bg={bg}
      borderRadius={4}
      maxWidth={props.nonList ? 'none' : '85%'}
      minHeight={32}
      flexible="row-v-center"
    >
      <ListPlainTextCell
        color={isLockedOut || isWithin30OfLockout ? 'white' : 'text'}
      >
        {props.lockoutDate ? utils.date.formatDate(props.lockoutDate) : 'N/A'}
      </ListPlainTextCell>
    </View>
  )
}
