import { useEffect, useState } from 'react'
import { utils, records } from '@ims/1edtech-frontend-common'

import { useSelector } from 'react-redux'
import { getNavigationParam } from 'lib/utils/navigation'
import { getRecord } from 'lib/records/workflows/getRecord'
import {
  AnyFormatType,
  ANY_FORMAT_TO_SPEC_FORMAT_MAP,
} from 'domains/formats/constants/formats'
import { REST_C_MODELS_RECORD } from 'lib/records/modules/restCModels'
import { SPECIFICATIONS_RECORD } from 'lib/records/modules/specifications'
import { RootState } from 'lib/store/rootReducer'
import IRestCModel from 'domains/modeling/models/IRestCModel'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'

export default function useFullRESTCModel(
  props: any,
  productIdParam = 'id',
  modelIdParam = 'modelId',
  formatParam = 'format',
) {
  const [pending, setPending] = useState(true)
  const productId = utils.convertToInt(
    getNavigationParam(props, productIdParam),
  )
  const modelId = utils.convertToInt(getNavigationParam(props, modelIdParam))
  const format = getNavigationParam(props, formatParam) as AnyFormatType
  const specFormat = ANY_FORMAT_TO_SPEC_FORMAT_MAP[format]

  const model = useSelector(
    records.entitiesSelectors.entityByIdSelector(REST_C_MODELS_RECORD, modelId),
  ) as IRestCModel
  const specification = useSelector((state: RootState) =>
    records.entitiesSelectors.entityByIdSelector(
      SPECIFICATIONS_RECORD,
      'specFormat',
    )(state, {
      specFormat,
    }),
  ) as IRestCSpec

  useEffect(() => {
    const fetch = async () => {
      await getRecord(REST_C_MODELS_RECORD, modelId, { productId })
      setPending(false)
    }
    fetch()
  }, [modelId, productId])

  return [model, specification, pending]
}
