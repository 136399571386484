import { useState, useEffect } from 'react'
import * as R from 'ramda'

import { getRequest } from 'lib/api/api'

export default function useLineChart(chart: string) {
  const [pending, setPending] = useState(true)
  const [chartData, setChartData] = useState<any[]>([])
  useEffect(() => {
    const onLoad = async () => {
      const response = await getRequest(`proxy/analytics/${chart}`)
      const responseData = R.pathOr([], ['data', 'data'], response)
      let series = R.propOr<any[], any, any[]>([], 'series', responseData)
      series = series.map((segment: any[], index: number) => ({
        name: R.pathOr('', ['seriesLabels', index], responseData),
        data: segment.map((point, pointIndex: number) => ({
          date: R.pathOr('', ['xValues', pointIndex], responseData),
          value: R.propOr(0, 'value', point),
        })),
      }))
      setChartData(series)
      setPending(false)
    }
    onLoad()
  }, []) // eslint-disable-line

  return { pending, chartData }
}
