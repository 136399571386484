import * as R from 'ramda'

import {
  SUPPORTED_1EDTECH_FORMATS,
  AnyFormatType,
  ANY_FORMAT_TO_SPEC_FORMAT_MAP,
} from 'domains/formats/constants/formats'

export const isProductFormatSupported = (
  format: AnyFormatType,
  supportedFormats = SUPPORTED_1EDTECH_FORMATS,
) => {
  const specFormat = ANY_FORMAT_TO_SPEC_FORMAT_MAP[format]
  return R.anyPass(supportedFormats.map((f) => R.equals(f)))(specFormat)
}
