import { utils } from '@ims/1edtech-frontend-common'

import { isRosteredByOtherProduct } from 'domains/products/utils/products'
import IProduct from 'domains/products/models/IProduct'

export default function sortProductsByRosteringFirst(
  productA: IProduct,
  productB: IProduct,
) {
  const isAModeledByRosteringTool = isRosteredByOtherProduct(productA)
  const isBModeledByRosteringTool = isRosteredByOtherProduct(productB)
  if (isAModeledByRosteringTool && !isBModeledByRosteringTool) {
    return 1
  }
  if (isBModeledByRosteringTool && !isAModeledByRosteringTool) {
    return -1
  }
  if (isAModeledByRosteringTool && isBModeledByRosteringTool) {
    return utils.comparePropForSort(true, 'name')(productA, productB)
  }

  return false
}
