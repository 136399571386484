import React, { useRef } from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import {
  Button,
  FormErrorMessage,
  Input,
  Spinner,
  Text,
  View,
} from 'lib/components'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .min(2, 'Login or Email must be at least 2 characters')
    .required('Required'),
  password: Yup.string()
    .min(2, 'Password must be at least 2 characters')
    .required('Required'),
})

export interface ILoginFormValues {
  email: string
  password: string
}

interface IProps {
  onLogin?: (
    values: ILoginFormValues,
    bag: FormikHelpers<ILoginFormValues>,
  ) => Promise<any>
  action?: string
}

export default function LoginForm(props: IProps) {
  const formEl = useRef(null)

  const onLogin = () => {
    // @ts-ignore
    formEl.current.submit()
  }

  const formProps = props.action ? { action: props.action, method: 'POST' } : {}
  return (
    <View flexible="column" flex={1}>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={loginSchema}
        onSubmit={props.onLogin || onLogin}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
          <form
            ref={formEl}
            {...formProps}
            style={{ width: '100%' }}
            noValidate={true}
            data-test="login-form"
          >
            <Text fontWeight="bold" pt={3} pb={2}>
              Login or Email
            </Text>
            <Input
              type="email"
              name="email"
              value={values.email}
              placeholder="myemail@example.com"
              variant="underlined"
              onChange={handleChange}
              aria-label="login"
            />
            <FormErrorMessage name="email" />
            <Text fontWeight="bold" pt="16px" pb={2}>
              Password
            </Text>
            <Input
              type="password"
              name="password"
              value={values.password}
              placeholder="password"
              variant="underlined"
              onChange={handleChange}
              aria-label="password"
            />
            <FormErrorMessage name="password" />

            <View my={4} />
            {isSubmitting && <Spinner />}
            {!isSubmitting && (
              <Button
                variant={
                  !errors.email && !errors.password ? 'primary' : 'disabled'
                }
                width="100%"
                disabled={errors.email || errors.password}
                onClick={handleSubmit}
                type="submit"
              >
                Login
              </Button>
            )}
          </form>
        )}
      </Formik>
    </View>
  )
}
