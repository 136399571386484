import React from 'react'

import { View, Text, Icon } from 'lib/components'
import RESTParameterDetails, {
  RESTParameterRow,
  RESTParameterInfoItem,
  findParamByName,
} from 'domains/reports/components/RESTParameterDetails'
import IRESTServiceEndpointComparisonReport from 'domains/characterizations/models/IRESTServiceEndpointComparisonReport'
import PrintMetadataFlag from 'domains/comparisons/components/PrintMetadataFlag'
import {
  STATUS_TO_LIGHT_VARIANT_MAP,
  RED_REPORT_STATUS,
  GREEN_REPORT_STATUS,
} from 'domains/reports/models/IReportStatuses'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  endpoint: IRESTServiceEndpointComparisonReport
}

export default function RESTServiceEndpointComparisonParamList(props: IProps) {
  const displayOAuth2Mismatch =
    props.endpoint.enabledInCharacterisation &&
    props.endpoint.enabled &&
    props.endpoint.modelIsOAuth2 !== props.endpoint.actualIsOAuth2
  const params: any[] = []
  if (displayOAuth2Mismatch && utils.hasValue(props.endpoint.errorMessage)) {
    params.push({
      name: 'Security',
      status: [
        props.endpoint.actualIsOAuth2 ? GREEN_REPORT_STATUS : RED_REPORT_STATUS,
        props.endpoint.modelIsOAuth2 ? GREEN_REPORT_STATUS : RED_REPORT_STATUS,
      ],
      dataTest: `param-status-${props.endpoint.endpointName}-Security`,
      infoItems: [
        <RESTParameterInfoItem
          key="security-oauth2-mismatch"
          status={RED_REPORT_STATUS}
          message={props.endpoint.errorMessage || ''}
          data-test={`${props.endpoint.endpointName}-Security-messages-0`}
        />,
      ],
    })
  }

  ;['Filter', 'Fields', 'Sort', 'Order By', 'Offset', 'Limit'].forEach(
    (param) => {
      const foundParam = findParamByName(param, props.endpoint.paramComparisons)
      if (foundParam) {
        params.push({
          name: param,
        })
      }
    },
  )

  if (params.length < 1) {
    return null
  }

  return (
    <View
      mt={3}
      p={[1, 3]}
      bg={STATUS_TO_LIGHT_VARIANT_MAP[props.endpoint.status]}
    >
      <RESTParameterRow
        isComparison={true}
        headers={[
          {
            content: (
              <View flexible="row-v-center">
                <PrintMetadataFlag isConsumer={false} />
                <Text fontWeight={700} ml={1}>
                  Provider
                </Text>
              </View>
            ),
            centered: true,
          },
          {
            content: (
              <View flexible="row-v-center">
                <PrintMetadataFlag isConsumer={true} />
                <Text fontWeight={700} ml={1}>
                  Consumer
                </Text>
              </View>
            ),
            centered: true,
          },
          {
            content: (
              <View flexible="row-v-center">
                <Icon
                  className="fas fa-info-circle"
                  color="info"
                  fontSize={20}
                />
                <Text fontWeight={700} ml={1}>
                  Notes/Alerts
                </Text>
              </View>
            ),
            centered: false,
          },
        ]}
        endpointName={props.endpoint.endpointName}
        status="GREEN"
        index={0}
        data-test={`${props.endpoint.endpointName}-params-header-row`}
      />
      {params.map((param, index) => {
        if (param.status) {
          return (
            <RESTParameterRow
              key={`${param.name}-${index}`}
              isComparison={true}
              name={param.name}
              endpointName={props.endpoint.endpointName}
              status={param.status}
              data-test={param.dataTest}
              infoItems={param.infoItems}
              index={index}
            />
          )
        } else {
          return (
            <RESTParameterDetails
              key={`${param.name}-${index}`}
              isComparison={true}
              name={param.name}
              endpointName={props.endpoint.endpointName}
              params={props.endpoint.paramComparisons}
              index={index}
            />
          )
        }
      })}
    </View>
  )
}
