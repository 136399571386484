import IProduct from 'domains/products/models/IProduct'
import { AnySpecFormatType } from 'domains/formats/constants/formats'
import { expandProductsByFormats } from 'domains/products/utils/expandProductsByFormats'

export const getFirstSupportedProductFormat = (
  product?: IProduct,
  supportedFormats?: AnySpecFormatType[],
): AnySpecFormatType | undefined => {
  if (!product) {
    return
  }
  const productFormats = expandProductsByFormats(product, supportedFormats)
  if (productFormats.length > 0) {
    return productFormats[0].format
  }
}
