import React from 'react'

import { ModalWrapper } from 'lib/components'
import { ORG_ACTIONS_RECORD } from 'lib/records/modules/orgActions'
import NonTableList from 'lib/components/NonTableList'
import IOrg from 'domains/orgs/models/IOrg'
import OrgActionRow from 'domains/orgs/components/OrgActions/OrgActionRow'

type Props = {
  isOpen: boolean
  closeModal: () => any
  org: IOrg
}

export default function OrganizationActionsModal(props: Props) {
  if (!props.isOpen) {
    return null
  }

  return (
    <ModalWrapper
      isOpen={props.isOpen}
      title="Organization Actions"
      actions={[
        {
          text: 'Done',
          variant: 'start',
          onClick: props.closeModal,
        },
      ]}
    >
      <NonTableList
        record={ORG_ACTIONS_RECORD}
        RowComponent={OrgActionRow}
        rowData={{
          org: props.org,
        }}
        pageSize={250}
        noHorizontalScroll
      />
    </ModalWrapper>
  )
}
