import React from 'react'
import * as R from 'ramda'

import { utils } from '@ims/1edtech-frontend-common'
import { View, Text, Span } from 'lib/components'
import CCCharacterizationReportLOMDetail from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMDetail'
import { ICommonCartridgeDate } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeDate'
import { ICommonCartridgeLanguageText } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeLanguageText'
import CCCharacterizationReportLOMLanguageText from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMLanguageText'
import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'

interface IProps {
  path: string
  title?: string
  data?: ICommonCartridgeDate
}

export default function CCCharacterizationReportLOMDate(props: IProps) {
  const { showMissingMetadata } = React.useContext(ShowMissingMetadataContext)
  if (!showMissingMetadata && !utils.hasValue(props.data)) {
    return null
  }

  const descriptions = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['description'],
    props.data,
  ) as ICommonCartridgeLanguageText[]

  const inner = (
    <View>
      <Text mb={1} data-test={`${props.path}.dateTime`}>
        <Span fontWeight={700}>dateTime: </Span> {props.data!.dateTime}
      </Text>

      {descriptions.map((description, i) => {
        const descDT = `${props.path}.description.${i}`
        return (
          <View mt={i === 0 ? 0 : 1} key={descDT} data-test={descDT}>
            <Text fontWeight={700}>description [{i + 1}]: </Text>
            <View ml={3}>
              <CCCharacterizationReportLOMLanguageText
                path={
                  !utils.hasValue(description)
                    ? `${props.path}.description`
                    : `${props.path}.description.${i}`
                }
                data={description}
              />
            </View>
          </View>
        )
      })}
    </View>
  )

  if (!props.title) {
    return inner
  }

  return (
    <CCCharacterizationReportLOMDetail
      path={props.path}
      title={props.title}
      value={inner}
    />
  )
}
