import React from 'react'

import { View, Text, Span, Icon } from 'lib/components'

interface IProps {
  name: string
  icon: string
  description: string
  highlight?: string
}

function NameIconDescription(props: IProps) {
  return (
    <Text variant="caption">
      <Span fontWeight={700} mr={1}>
        {props.name}
        <Icon ml={1} className={props.icon} />:
      </Span>
      {props.description.substr(
        0,
        props.description.includes('@@')
          ? props.description.indexOf('@@')
          : undefined,
      )}
      <Span fontWeight={700}>{props.highlight || ''}</Span>
      {props.description.substr(props.description.indexOf('@@') + 2)}
    </Text>
  )
}

export default function PrintPopulationLegend() {
  return (
    <View mt={2}>
      <NameIconDescription
        name="None"
        icon="fas fa-battery-empty"
        description="This Field is optional and not populated within the characterized data set. This may cause issues if the data is required by a Consumer's system."
      />
      <NameIconDescription
        name="Inconsistent"
        icon="fas fa-battery-half"
        description="This field is optional and is inconsistently populated with data. This may cause issues if the data is required by a Consumer's system."
      />
      <NameIconDescription
        name="Consistent"
        icon="fas fa-battery-full"
        description="This @@ field is consistently populated. This field might be ignored by a Consumer's system."
        highlight="metadata"
      />
    </View>
  )
}
