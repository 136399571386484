import React from 'react'
import * as R from 'ramda'

export default function useModalState(
  defaultOpen = false,
): [boolean, () => any, () => any, () => any] {
  const [isOpen, setIsOpen] = React.useState<boolean>(defaultOpen)
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)
  const toggle = () => setIsOpen(R.not)

  return [isOpen, openModal, closeModal, toggle]
}
