import { useEffect, useState } from 'react'
import * as R from 'ramda'
import { utils, records } from '@ims/1edtech-frontend-common'

import { useSelector } from 'react-redux'
import { getNavigationParam } from 'lib/utils/navigation'
import { RootState } from 'lib/store/rootReducer'
import ICSVSpecification from 'domains/specifications/models/ICSVSpecification'
import IComparison from 'domains/comparisons/models/IComparison'
import { ICSVComparisonReport } from 'domains/comparisons/models/ICSVComparisonReport'
import getFullComparison from 'domains/comparisons/workflows/getFullComparison'
import { fullSpecificationSelector } from 'domains/specifications/selectors/fullSpecification'
import { comparisonFormatToReportRecord } from 'domains/comparisons/workflows/comparisonFormatToRecord'
import IRESTComparisonReport from 'domains/characterizations/models/IRESTComparisonReport'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import { COMPARISONS_RECORD } from 'lib/records/modules/comparisons'
import IProduct from 'domains/products/models/IProduct'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'

export default function useFullComparison(
  props: any,
  comparisonForamtParam = 'format',
  comparisonIdParam = 'id',
): [
  IComparison,
  ICSVComparisonReport | IRESTComparisonReport,
  ICSVSpecification | IRestCSpec,
  IProduct,
  boolean,
] {
  const [pending, setPending] = useState(true)
  const format = getNavigationParam(props, comparisonForamtParam)
  const comparisonId = utils.convertToInt(
    getNavigationParam(props, comparisonIdParam),
  )
  useEffect(() => {
    const fetch = async () => {
      await getFullComparison(comparisonId, format)
      setPending(false)
    }
    fetch()
  }, [format, comparisonId])

  const comparison = useSelector((state: RootState) =>
    records.entitiesSelectors.entityByIdSelector(
      COMPARISONS_RECORD,
      comparisonId,
    )(state, props),
  ) as IComparison

  const reportRecord = comparisonFormatToReportRecord(format)
  const comparisonReport = useSelector((state: RootState) =>
    records.entitiesSelectors.entityByIdSelector(
      reportRecord,
      'comparisonLink',
    )(state, {
      ...props,
      comparisonLink: R.pathOr('', ['comparisonLink'], comparison),
    }),
  ) as ICSVComparisonReport

  const specification = useSelector((state: RootState) =>
    fullSpecificationSelector(records.entitiesSelectors.idSelector('format'))(
      state,
      {
        ...props,
        format: R.pathOr('', ['format'], comparison),
      },
    ),
  ) as ICSVSpecification

  const product = useSelector((state: RootState) =>
    records.entitiesSelectors.entityByIdSelector(
      PRODUCTS_RECORD,
      records.modules.getRefId(comparison, 'productId'),
    )(state, props),
  ) as IProduct

  return [comparison, comparisonReport, specification, product, pending]
}
