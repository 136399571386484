import React from 'react'
import { Link } from 'react-router-dom'

import { List, Text, View, Icon } from 'lib/components'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import LargeWidget from 'domains/dashboard/components/LargeWidget'
import CharacterizationIcon from 'domains/characterizations/components/CharacterizationIcon'
import { getCharacterizationRoute } from 'domains/characterizations/navigation/routes'
import ICharacterization from 'domains/characterizations/models/ICharacterization'

interface IProps {
  characterisationsNeedingCorrections: ICharacterization[]
  fadeDelay: number
}

const columns = [
  {
    title: 'Name',
    titleRender: <WidgetHeader title="Characterizations Needing Corrections" />,
    noCenter: true,
    renderCell: (characterization: ICharacterization) => (
      <View flexible="row-v-center" pl={2}>
        <CharacterizationIcon {...characterization} noResponsive={true} />
        <View pr={2} />
        <Link
          to={getCharacterizationRoute(
            characterization.id,
            characterization.format,
          )}
        >
          <Text pl={2} textAlign="left" color="tertiary" whiteSpace="pre-line">
            {characterization.name}
          </Text>
        </Link>
      </View>
    ),
  },
  {
    title: 'Status',
    renderCell: () => (
      <Icon color="error" fontSize={32} className="fas fa-exclamation-circle" />
    ),
  },
]

export default function CharacterizationsNeedingCorrectionsWidget(
  props: IProps,
) {
  return (
    <LargeWidget
      fadeDelay={props.fadeDelay}
      dataTest="characterizations-needing-corrections-widget"
    >
      <List
        noWrapper={true}
        columns={columns}
        nonRecordItems={props.characterisationsNeedingCorrections}
        dataTest="characterizations-needing-corrections-widget-list"
        blankSlateText="No characterizations needing correction"
        rowHeight={64}
        noSort={true}
      />
    </LargeWidget>
  )
}
