import {
  getRoute,
  COMPARISONS_ROUTE,
} from 'domains/application/navigation/routes'
import * as formats from 'domains/formats/constants/formats'
import {
  COMMON_CARTRIDGE_BASE_NAV_ROUTE,
  ONEROSTER_BASE_NAV_ROUTE,
} from 'domains/specifications/constants/specNavigation'

export const getComparisonReportRoute = (
  id: string | number,
  format: formats.AnyFormatType,
  parts: string[] | string = '',
  queryString: any = {},
) => {
  const navFormat =
    formats.FORMAT_TO_FORMAT_NAV_ROUTE_MAP[format as formats.AnySpecFormatType]
  let baseURL = ''
  switch (formats.ANY_FORMAT_TO_SPEC_FORMAT_MAP[format]) {
    case formats.COMMON_CARTRIDGE_V1P2:
    case formats.COMMON_CARTRIDGE_V1P3:
    case formats.THIN_COMMON_CARTRIDGE_V1P2:
    case formats.THIN_COMMON_CARTRIDGE_V1P3:
      baseURL = `/${COMMON_CARTRIDGE_BASE_NAV_ROUTE}`
      break
    default:
      baseURL = `/${ONEROSTER_BASE_NAV_ROUTE}`
      break
  }
  const route = getRoute(
    `${baseURL}${COMPARISONS_ROUTE}/${navFormat}`,
    id,
    parts,
    queryString,
  )
  return route
}
