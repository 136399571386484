import React, { PureComponent } from 'react'
import * as R from 'ramda'
import { RouteComponentProps } from 'react-router-dom'
import { Formik, Form, FormikHelpers } from 'formik'
import { utils } from '@ims/1edtech-frontend-common'

import { View, WizardFooter } from 'lib/components'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import ProductModelingCsvCFileSetStatus from 'domains/modeling/components/ProductModeling/ProductModelingCsvCFileSetStatus'
import { updateCsvCModel } from 'domains/modeling/workflows/updateCsvCModel'
import {
  getProductModelCharacterizationRoute,
  getProductRoute,
  getProductStartModelingRoute,
} from 'domains/products/navigation/routes'
import { trackCSVCModelingAnalytics } from 'domains/modeling/utils/trackModelingAnalytics'
import ResetProductDialog from 'domains/products/components/ResetProductDialog'
import CharacterizeProductModal from 'domains/characterizations/components/CharacterizeProductModal'
import EditModelWarning from 'domains/modeling/components/EditModelWarning'
import { isProductCharacterized } from 'domains/products/utils/products'
import BaseProductDetailsBreadCrumbs from 'domains/products/components/BaseProductDetailsBreadCrumbs'
import memoize from 'fast-memoize'
import UnsavedModelWarning from 'domains/modeling/components/UnsavedModelWarning'
import { showToast, SUCCESS_TOAST, ERROR_TOAST } from 'lib/utils/toast'
import withFullProduct, {
  IWithFullProductProps,
} from 'domains/products/components/withFullProduct'
import {
  ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
  AnyFormatType,
} from 'domains/formats/constants/formats'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import { modelDetailValidation } from 'domains/modeling/utils/modelDetailValidation'
import ProductModelDetailEditor, {
  IProductModelValues,
} from 'domains/modeling/components/ProductModeling/ProductModelDetailEditor'

interface IEditConfirmArgs {
  values: IProductModelValues
  setSubmitting: (submitting: boolean) => void
}
interface IState {
  launchCharDialogOpen: boolean
  editConfirmDialog: boolean | IEditConfirmArgs
  resetDialogOpen: boolean
  cancelDialogOpen: boolean
  cancelRoute: string
}

export class ProductModelingCsvCEditScreen extends PureComponent<
  IWithFullProductProps & RouteComponentProps
> {
  readonly state: IState = {
    launchCharDialogOpen: false,
    editConfirmDialog: false,
    resetDialogOpen: false,
    cancelDialogOpen: false,
    cancelRoute: getProductRoute(
      this.props.id,
      ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
    ),
  }

  onSaveConfirm = (
    values: IProductModelValues,
    { setSubmitting }: FormikHelpers<IProductModelValues>,
  ) => {
    setSubmitting(true)
    const { product } = this.props
    const saveArgs = { values, setSubmitting }
    if (isProductCharacterized(product, ONER_V1P1_CSV_ROSTERING_IMPORT_BULK)) {
      this.setState({ editConfirmDialog: saveArgs })
    } else {
      this.updateModel(saveArgs)
    }
  }

  onSaveConfirmed = async (args: any) => {
    this.onCloseEditConfirm(args)
    this.updateModel(args)
  }

  getModel = () => R.propOr({}, 'csvConsumerModel', this.props)
  getModelProp = (prop: string, fallback: any = null) =>
    R.propOr(fallback, prop, this.getModel()) as any

  updateModel = async ({
    values,
    setSubmitting,
  }: {
    values: IProductModelValues
    setSubmitting: any
  }) => {
    this.setState({ pending: true })
    const model = await updateCsvCModel(this.props.modelId!, {
      ...this.props.csvConsumerModel,
      ...values,
    })
    if (model) {
      showToast(SUCCESS_TOAST, 'Model updated')
      trackCSVCModelingAnalytics('updated_model_details')
    } else {
      showToast(ERROR_TOAST, 'Failed to update model')
    }
    setSubmitting(false)
  }

  onCloseEditConfirm = ({ setSubmitting }: any) => {
    setSubmitting(false)
    this.setState({ editConfirmDialog: false })
  }

  onProductReset = () =>
    this.props.history.replace(
      getProductStartModelingRoute(
        this.props.id,
        ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
      ),
    )

  onResetAll = () => this.setState({ resetDialogOpen: true })

  onCancelConfirmed = async () => {
    this.setState({ cancelDialogOpen: false })
    if (utils.hasValue(this.state.cancelRoute)) {
      this.props.history.push(this.state.cancelRoute)
    }
  }

  onDenyCancelWarning = () => this.setState({ cancelDialogOpen: false })

  onShowCancelWarning = () => this.setState({ cancelDialogOpen: true })

  onNavigateAway = memoize(
    (isDirty) => (e: React.MouseEvent<any>, route: string) => {
      if (isDirty) {
        e.preventDefault()
        this.onShowCancelWarning()
        this.setState({ cancelDialogOpen: true, cancelRoute: route })
      }
    },
  )

  closeResetDialog = () => this.setState({ resetDialogOpen: false })

  closeLaunchCharDialog = () => this.setState({ launchCharDialogOpen: false })

  onLaunchCharacterizationComfirm = () =>
    this.setState({ launchCharDialogOpen: true })

  goToCharacterization = () =>
    this.props.history.push(
      getProductModelCharacterizationRoute(
        this.props.id,
        this.getModelProp('id') as number,
      ),
    )

  canCharacterize = () => {
    const allTouched = R.all(
      R.propEq('touched', true),
      R.propOr([], 'files', this.props.csvConsumerModel),
    )
    return allTouched
  }

  getFooterButtons = (handleSubmit: any, errors: any, dirty: any) => {
    const isCharacterized = isProductCharacterized(
      this.props.product,
      ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
    )
    const canCharacterize = this.canCharacterize()
    return [
      {
        title: 'Save',
        onClick: handleSubmit,
        variant: 'complete',
        dataTest: 'save-btn',
        type: 'submit',
      },
      {
        title: 'Reset All',
        onClick: this.onResetAll,
        variant: 'black',
        dataTest: 'reset-all-btn',
        type: 'button',
      },
      {
        title: isCharacterized
          ? 'View Characterization'
          : 'Launch Characterization',
        onClick: isCharacterized
          ? this.goToCharacterization
          : this.onLaunchCharacterizationComfirm,
        variant: canCharacterize ? 'start' : 'neutral',
        disabled: !canCharacterize || dirty,
        type: 'button',
        dataTest: 'launch-characterization-btn',
      },
    ]
  }

  render() {
    const { csvConsumerModel, product } = this.props
    if (!csvConsumerModel) {
      return null
    }

    return (
      <View variant="screen">
        <DocumentTitle
          title={`Product Modeling - ${this.props.product.name}`}
        />
        <Formik
          initialValues={
            {
              notes: R.propOr('', 'notes', csvConsumerModel),
              implementationUrl: R.propOr(
                '',
                'implementationUrl',
                csvConsumerModel,
              ),
              onlineHelpUrl: R.propOr('', 'onlineHelpUrl', csvConsumerModel),
              productSupportEmail: R.propOr(
                '',
                'productSupportEmail',
                csvConsumerModel,
              ),
            } as IProductModelValues
          }
          validationSchema={modelDetailValidation(false)}
          onSubmit={this.onSaveConfirm}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            handleBlur,
            isSubmitting,
            dirty,
          }) => (
            <View>
              <BaseProductDetailsBreadCrumbs
                id={product.id}
                format={ONER_V1P1_CSV_ROSTERING_IMPORT_BULK}
                crumbs={[{ name: 'Modeling' }]}
                beforeNavigate={this.onNavigateAway(dirty)}
              />
              <View variant="paper">
                <Form>
                  <WidgetHeader
                    title={`Bulk CSV Consumer v1.1 Modeling Setup: ${this.getModelProp(
                      'name',
                      '',
                    )} for ${product.name}`}
                    dataTest="product-modeling-edit-header"
                  />

                  <ProductModelingCsvCFileSetStatus
                    id={product.id}
                    csvModelId={this.getModelProp('id')}
                    format={this.getModelProp('format')}
                    warnBefore={dirty}
                  />

                  <ProductModelDetailEditor
                    format={ONER_V1P1_CSV_ROSTERING_IMPORT_BULK}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                  />

                  <WizardFooter
                    pending={isSubmitting}
                    buttons={this.getFooterButtons(handleSubmit, errors, dirty)}
                  />
                </Form>
              </View>
            </View>
          )}
        </Formik>
        <CharacterizeProductModal
          modelId={this.props.modelId!}
          productId={this.props.id}
          open={this.state.launchCharDialogOpen}
          closeModal={this.closeLaunchCharDialog}
          format={csvConsumerModel.format}
        />
        <ResetProductDialog
          isOpen={this.state.resetDialogOpen}
          product={this.props.product}
          format={this.getModelProp('format') as AnyFormatType}
          onReset={this.onProductReset}
          onCancel={this.closeResetDialog}
        />
        <EditModelWarning
          isOpen={!!this.state.editConfirmDialog}
          product={product}
          args={this.state.editConfirmDialog}
          onConfirm={this.onSaveConfirmed}
          closeModal={this.onCloseEditConfirm}
        />
        <UnsavedModelWarning
          isOpen={this.state.cancelDialogOpen}
          onConfirm={this.onCancelConfirmed}
          onDeny={this.onDenyCancelWarning}
        />
      </View>
    )
  }
}

export default withFullProduct(ProductModelingCsvCEditScreen)
