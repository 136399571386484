import IRestConsumerEndpoint from 'domains/modeling/models/IRestCModelServiceEndpoint'

export type RestHttpVerbs = 'GET' | 'POST' | 'PUT' | 'DELETE'
export default interface IRestCModelService {
  id: number
  name: string
  notes: string
  endpoints: IRestConsumerEndpoint[]
}

export const mockRestCModelService = (overrides = {}): IRestCModelService => ({
  id: 1,
  name: 'getAllAcademicSessions',
  notes: '',
  endpoints: [],
  ...overrides,
})
