import React from 'react'
import * as R from 'ramda'
import clsx from 'clsx'
import { records } from '@ims/1edtech-frontend-common'
import { Checkbox, Icon, Text, View } from 'lib/components'
import { useSelector } from 'react-redux'
import { IMS_APPLICATIONS_RECORD } from 'lib/records/modules/imsApplications'
import { TRUSTED_APPS_RECORD } from 'lib/records/modules/trustedApplications'
import IIMSApplication, {
  isIMSAppVetted,
  isIMSAppCertified,
} from 'domains/trustedApps/models/IIMSApplication'
import { IAddTrustedAppSelection } from 'domains/trustedApps/components/AddTrustedApps/models/IAddTrustedAppSelection'
import TrustedAppRowName from 'domains/trustedApps/components/AddTrustedApps/TrustedAppRowName'

interface ICheckProps {
  show: boolean
  label: string
  isChecked: boolean
}
function Check(props: ICheckProps) {
  return (
    <View
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      {props.show && (
        <View
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Icon
            className="fas fa-check-circle"
            color={props.isChecked ? 'white' : 'text'}
          />
          <Text textAlign="center" color={props.isChecked ? 'white' : 'text'}>
            {props.label}
          </Text>
        </View>
      )}
    </View>
  )
}

interface IProps {
  id: number
  selection: IAddTrustedAppSelection[]
  addApp: (id: number) => any
  removeApp: (id: number) => any
  showInfo: (id: number) => any
  alreadyAdded: boolean
}

export default function IMSApplicationRow(props: IProps) {
  const imsApplication = useSelector((state: any) =>
    records.entitiesSelectors.entityByIdSelector(IMS_APPLICATIONS_RECORD, 'id')(
      state,
      props,
    ),
  ) as IIMSApplication

  const trustedApp = useSelector((state: any) =>
    records.recordsSelectors.recordSelector(TRUSTED_APPS_RECORD)(
      state,
      props.id,
    ),
  )


  const isTrusted = trustedApp.items.includes(props.id)
  const index = R.findIndex(
    R.propEq('applicationId', props.id),
    props.selection,
  )
  const isChecked = index > -1
  const textColor = isChecked ? 'text-white' : 'text-text'
  const onChange = () => {
    if (isChecked) {
      props.removeApp(props.id)
    } else {
      if (!isTrusted) props.addApp(props.id)
    }
  }

  const showInfo = (e: React.MouseEvent) => {
    e.stopPropagation()
    props.showInfo(props.id)
  }

  return (
    <div
      className={clsx(
        'grid grid-cols-auto-1fr-auto gap-1 lg:gap-2 py-2 px-2 mb-2 cursor-pointer rounded-sm',
        {
          'bg-complete': isChecked,
          'bg-background': !isChecked,
        },
      )}
      onClick={onChange}
      data-test={`app-${imsApplication.id}`}
      data-selected={isChecked}
    >
      <Checkbox
        checked={isChecked}
        name={`${imsApplication.id}`}
        onChange={onChange}
        hidden={isTrusted}
      />

      <div className="flex flex-row">
        <TrustedAppRowName app={imsApplication} textColor={textColor} />
        <div className="ml-3 cursor-pointer" onClick={showInfo}>
          <i className={`fas fa-info-circle text-lg md:text-xl ${textColor}`} />
        </div>
      </div>
      <div
        className={clsx('flex flex-row items-center space-x-2 lg:space-x-4', {
          hidden: !isTrusted,
        })}
      >
        <Text>This TrustEd App is Already in Your Dashboard</Text>
      </div>
      <div className="flex flex-row flex-wrap items-center space-x-1 lg:space-x-3">
        <div className="w-12">
          <Check
            show={isIMSAppCertified(imsApplication)}
            label="Certified"
            isChecked={isChecked}
          />
        </div>

        <div className="w-12">
          <Check
            show={isIMSAppVetted(imsApplication)}
            label="Vetted"
            isChecked={isChecked}
          />
        </div>
      </div>
    </div>
  )
}
