import React from 'react'
import { View } from 'lib/components'
import { STATUS_TO_ROW_BG_MAP } from 'domains/comparisons/constants/comparisonReports'
import ComparisonFileColumnHeader from 'domains/comparisons/components/ComparisonFileColumnHeader'
import ComparisonFileColumnBody from 'domains/comparisons/components/ComparisonFileColumnBody'
import MetadataFlag from 'domains/comparisons/components/MetadataFlag'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'
import { ReportStatus } from 'domains/reports/models/IReportStatuses'
import { getCharReportRowKeyWidth } from 'domains/characterizations/constants/characterizationReport'

const isPrint = isOnPrintScreen()

interface IProps {
  index: number
  dataType: string
  status: ReportStatus
  required: string
  mdSource?: string
  parentName: string
  name: string
  description?: string
  notes?: string
  errorMessage?: string
  smallestValueLength?: number
  largestValueLength?: number
  'data-test'?: string
}

const MIN_COL_WIDTH = isPrint ? [200, 200] : getCharReportRowKeyWidth(-22)

export default function ComparisonItemRow(props: IProps) {
  return (
    <View
      position="relative"
      bg={
        isPrint
          ? props.index % 2 === 0
            ? 'white'
            : 'background'
          : STATUS_TO_ROW_BG_MAP[props.status]
      }
      py={isPrint ? 0 : 2}
      px={isPrint ? 1 : 3}
      mt={isPrint ? 0 : 2}
      flexible="row"
      display={['inline-flex', 'flex']}
      borderRadius={4}
      minWidth={isPrint ? undefined : MIN_COL_WIDTH[1] * 2 + 96}
      boxSizing="border-box"
      data-test={
        props['data-test'] || `${props.parentName}-${props.name}-subItem`
      }
      data-status={props.status}
    >
      <ComparisonFileColumnHeader
        minWidth={MIN_COL_WIDTH[1]}
        dataType={props.dataType}
        status={props.status}
        required={props.required}
        mdSource={props.mdSource}
        parentName={props.parentName}
        name={props.name}
        smallestValueLength={props.smallestValueLength}
        largestValueLength={props.largestValueLength}
      />

      <ComparisonFileColumnBody
        minWidth={MIN_COL_WIDTH[1]}
        dataType={props.dataType}
        status={props.status}
        required={props.required}
        mdSource={props.mdSource}
        parentName={props.parentName}
        name={props.name}
        description={props.description}
        notes={props.notes}
        errorMessage={props.errorMessage}
        smallestValueLength={props.smallestValueLength}
        largestValueLength={props.largestValueLength}
      />
      {!isPrint && (
        <MetadataFlag
          parentName={props.parentName}
          name={props.name}
          mdSource={props.mdSource}
        />
      )}
    </View>
  )
}
