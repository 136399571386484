import React from 'react'
import View, { IViewProps } from 'lib/components/View'

type Props = IViewProps & {
  container?: boolean
  children?: any
}

export default function Screen({
  container = false,
  children = null,
  ...rest
}: Props) {
  if (container) {
    return (
      <div className="screen px-4 flex flex-col items-center">
        <div className="container">{children}</div>
      </div>
    )
  }

  return (
    <View variant="screen" className="screen" {...rest}>
      {children}
    </View>
  )
}
