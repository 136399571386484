import React from 'react'
import * as R from 'ramda'
import { useSelector } from 'react-redux'
import { records } from '@ims/1edtech-frontend-common'
import { useHistory } from 'react-router'

import { ProgressBar, Text, View } from 'lib/components'
import ProductModelResource from 'domains/products/components/ProductModelResource'
import { CSV_C_MODELS_RECORD } from 'lib/records/modules/csvCModels'
import { getProductFileModelingRoute } from 'domains/products/navigation/routes'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'
import { isProductCharacterized } from 'domains/products/utils/products'
import { trackCSVCModelingAnalytics } from 'domains/modeling/utils/trackModelingAnalytics'
import { resetFileModeling } from 'domains/modeling/workflows/resetFileModeling'
import Dialog from 'domains/application/modals/Dialog'
import EditModelWarning from 'domains/modeling/components/EditModelWarning'
import UnsavedModelWarning from 'domains/modeling/components/UnsavedModelWarning'
import ICSVConsumerModel from 'domains/modeling/models/ICSVConsumerModel'
import IProduct from 'domains/products/models/IProduct'
import { ICSVConsumerFile } from 'domains/modeling/models/ICSVConsumerModel'
import { AnyFormatType } from 'domains/formats/constants/formats'
import { modelCompletionSelector } from 'domains/modeling/selectors/csvModeling'
import { getCharacterizedWarningMessage } from 'domains/modeling/utils/characterizedWarningMessage'
import ProductModelingWrapper from 'domains/modeling/components/ProductModeling/ProductModelingWrapper'
import ProductModelResourceGrid from 'domains/modeling/components/ProductModeling/ProductModelResourceGrid'
import { RootState } from 'lib/store/rootReducer'

interface IDialogArgs {
  fileName: string
}

type Props = {
  csvModelId: string | number
  id: string | number
  format: AnyFormatType
  warnBefore?: boolean
}

export default function ProductModelingCsvCFileSetStatus(props: Props) {
  const history = useHistory()
  const progress = useSelector((s: RootState) =>
    modelCompletionSelector('id', 'format')(s, props),
  ) as number
  const product = useSelector((s: RootState) =>
    records.entitiesSelectors.entityByIdSelector(PRODUCTS_RECORD, 'id')(
      s,
      props,
    ),
  ) as IProduct | undefined
  const model = useSelector((s: RootState) =>
    records.entitiesSelectors.entityByIdSelector(
      CSV_C_MODELS_RECORD,
      'csvModelId',
    )(s, props),
  ) as ICSVConsumerModel | undefined
  const [fileName, setFileName] = React.useState('')
  const [editConfirmDialog, setEditConfirmDialog] = React.useState<
    string | boolean
  >(false)
  const [resetFileDialog, setResetFileDialog] = React.useState<
    string | boolean
  >(false)
  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false)

  if (!model || !product) return null

  const onDenyEditFile = () => setEditConfirmDialog(false)
  const onDenyResetFile = () => setResetFileDialog(false)

  const onGoToFile =
    (fileName: string, force = false) =>
    () => {
      const { id, csvModelId, warnBefore } = props
      if (warnBefore && !force) {
        setFileName(fileName)
        setCancelDialogOpen(true)
        return
      }
      history.push(getProductFileModelingRoute(id, csvModelId, fileName))
    }

  const onEditCharacterizedFile = (fileName: string) => {
    trackCSVCModelingAnalytics('edited_characterized_file')
    onGoToFile(fileName)()
  }

  const onCancelConfirmed = () => {
    const { format } = props
    setCancelDialogOpen(false)
    if (isProductCharacterized(product, format)) setEditConfirmDialog(fileName)
    else onGoToFile(fileName, true)()
  }

  const onDenyCancelWarning = () => setCancelDialogOpen(false)

  const onEditFile = (fileName: string) => () => {
    const { format, warnBefore } = props
    if (warnBefore) {
      setFileName(fileName)
      setCancelDialogOpen(true)
      setFileName(fileName)
      setCancelDialogOpen(true)
      return
    }

    if (isProductCharacterized(product, format)) {
      setEditConfirmDialog(fileName)
      return
    }

    onGoToFile(fileName)()
  }

  const onResetFileConfirmed = async (args: any) => {
    await resetFileModeling(
      props.csvModelId,
      model!.format,
      (args as IDialogArgs).fileName,
    )
    setResetFileDialog(false)
  }

  const onResetFile = (fileName: string) => setResetFileDialog(fileName)

  const renderFileSet = ({ name }: { name: string }, index: number) => (
    <ProductModelResource
      key={`${name}-${index}`}
      name={name}
      modelId={props.csvModelId}
      format={model!.format}
      onEdit={onEditFile(name)}
      onLaunch={onGoToFile(name)}
      onReset={onResetFile}
      dataTest={name}
    />
  )

  const { format } = props
  return (
    <ProductModelingWrapper>
      <View flexible="column">
        <ProductModelResourceGrid>
          {R.propOr<ICSVConsumerFile[], ICSVConsumerModel, ICSVConsumerFile[]>(
            [],
            'files',
            model!,
          ).map(renderFileSet)}
        </ProductModelResourceGrid>
        <View my={4}>
          <Text fontWeight={700} mb={3}>
            Modeling Progress:
          </Text>
          <ProgressBar progress={progress} dataTest="model-progress" />
        </View>
        <EditModelWarning
          isOpen={!!editConfirmDialog}
          product={product!}
          args={editConfirmDialog}
          onConfirm={onEditCharacterizedFile}
          closeModal={onDenyEditFile}
        />{' '}
        <Dialog
          title={`Are you sure you want to reset ${resetFileDialog}?`}
          isOpen={!!resetFileDialog}
          icon="fas fa-x-circle"
          message={
            isProductCharacterized(product, format)
              ? getCharacterizedWarningMessage(product!.name)
              : 'This will reset the file modeling back to the specification.'
          }
          onConfirm={onResetFileConfirmed}
          onDeny={onDenyResetFile}
          args={{ fileName: resetFileDialog }}
        />
        <UnsavedModelWarning
          isOpen={cancelDialogOpen}
          onConfirm={onCancelConfirmed}
          onDeny={onDenyCancelWarning}
        />
      </View>
    </ProductModelingWrapper>
  )
}
