import * as normalizr from 'normalizr'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

export const TRUSTED_APPS_CHANGE_LOG_RECORD = 'trustedAppsChangelog'

const trustedAppsChangelog: records.modules.IModule = {
  record: TRUSTED_APPS_CHANGE_LOG_RECORD,
  responseDataKey: 'entries',
  getCreateResponseData: R.propOr({}, 'data'),
  schema: new normalizr.schema.Entity(
    TRUSTED_APPS_CHANGE_LOG_RECORD,
    {},
    records.modules.utils.getSchemaOptions(['activity', 'applicationName', 'date']),
  ),
  api: {
    create: () => 'trustedapps/organizations/audit',
    get: (id) => `trustedapps/organizations/audit/${id}`,
    getList: () => 'trustedapps/organizations/audit',
    update: (id) => `trustedapps/organizations/audit/${id}`,
    delete: () => '',
  },
}

export default trustedAppsChangelog
