import React from 'react'

import CCCharacterizationReportLOMDetail from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMDetail'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  path: string
  title: string
  data?: any[]
}

export default function CCCharacterizationReportLOMCSVList(props: IProps) {
  if (!utils.hasValue(props.data)) {
    return null
  }

  return (
    <CCCharacterizationReportLOMDetail
      path={props.path}
      title={props.title}
      value={props.data!.join(', ')}
    />
  )
}
