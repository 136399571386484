import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { utils } from '@ims/1edtech-frontend-common'

import { View, Text, ToolTip } from 'lib/components'
import ProductImageCell from 'domains/products/components/ProductImageCell'
import IComparison from 'domains/comparisons/models/IComparison'
import CharacterizationIcon from 'domains/characterizations/components/CharacterizationIcon'
import { getListRowBackground } from 'lib/components/List'
import ReportStatusIcon from 'domains/reports/components/ReportStatusIcon'
import ZombieCell from 'domains/comparisons/components/ZombieCell'
import { getComparisonReportRoute } from 'domains/comparisons/navigation/routes'
import { isSupplierRoleSelector } from 'domains/authentication/selectors/roles'

interface IProps {
  index: number
  comparison: IComparison
}

export default function ORDashboardRecentComparisonRow(props: IProps) {
  const isSupplier = useSelector(isSupplierRoleSelector)
  const history = useHistory()
  const { comparison } = props
  const tooltipId = `${comparison.id}-name`

  const onRowClick = () =>
    history.push(getComparisonReportRoute(comparison.id, comparison.format))

  const dataTest = `or-dashboard-recent-comparison-${comparison.id}`
  const primaryName = isSupplier
    ? comparison.productName
    : comparison.characterizationName
  const secondaryName = isSupplier
    ? comparison.characterizationName
    : comparison.productName
  const productImage = (
    <ProductImageCell
      name={comparison.productName}
      logo={comparison.productLogo}
      size={isSupplier ? 50 : 28}
    />
  )
  const charIcon = (
    <CharacterizationIcon
      color={comparison.characterizationColor}
      size={isSupplier ? 22 : 50}
    />
  )
  const primaryImage = isSupplier ? productImage : charIcon
  const secondaryImage = isSupplier ? charIcon : productImage
  return (
    <View
      flexible="row-v-center"
      bg={getListRowBackground(props.index)}
      px={2}
      minHeight={90}
      hover={!comparison.zombie ? 'clickable-bg' : undefined}
      onClick={comparison.zombie ? undefined : onRowClick}
      data-test={dataTest}
    >
      {primaryImage}

      <View flexible="column-v-center" flex={1} ml={3} py={[2, 2, 0]}>
        <ToolTip id={tooltipId} tip={primaryName} delay={250}>
          <Text
            textAlign="left"
            overflow={['', '', 'ellipsis']}
            fontWeight={700}
            color="tertiary"
            // maxWidth={['100%', '100%', 200]}
            data-test={`${dataTest}-${
              isSupplier ? 'product' : 'characterization'
            }-name`}
          >
            {primaryName}
          </Text>
        </ToolTip>

        <View
          display="flex"
          flexDirection={['column', 'row']}
          justifyContent={['center']}
          alignItems={['flex-start', 'center']}
          mt={1}
        >
          <View flexible="row-v-center">
            <Text variant="caption" mr={2}>
              Compared With
            </Text>
            {secondaryImage}
            <ToolTip
              id={`${tooltipId}-${isSupplier ? 'characterization' : 'product'}`}
              tip={secondaryName}
              delay={250}
            >
              <Text
                ml={1}
                textAlign="left"
                fontWeight={700}
                overflow={['', '', 'ellipsis']}
                color="tertiary"
                // maxWidth={['100%', '100%', 200]}
                data-test={`${dataTest}-${
                  isSupplier ? 'characterization' : 'product'
                }-name`}
              >
                {secondaryName}
              </Text>
            </ToolTip>
          </View>

          <Text variant="caption" mx={1} display={['none', 'block']}>
            |
          </Text>

          <Text variant="caption" mx={1}>
            {utils.date.formatForDisplay(comparison.comparisonRunDate)}
          </Text>
        </View>
      </View>

      {comparison.zombie && <ZombieCell />}
      {!comparison.zombie && (
        <ReportStatusIcon
          status={comparison.compatibility}
          fontSize={32}
          data-test={`${dataTest}-status`}
        />
      )}
    </View>
  )
}
