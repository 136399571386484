import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'

import {
  MODELLED_MODELLING_STATUS,
  CHARACTERISED_MODELLING_STATUS,
  IProductModelingStatuses,
} from 'domains/products/constants/products'
import {
  ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
  FORMAT_TO_INVERSE_MAP,
  AnySpecFormatType,
  ANY_FORMAT_TO_SPEC_FORMAT_MAP,
  AnyFormatType,
} from 'domains/formats/constants/formats'
import IProductFormat from 'domains/products/models/IProductFormat'
import IProduct from 'domains/products/models/IProduct'

export const getProductFormatByName = (
  format: AnyFormatType | undefined,
  inverse = false,
) =>
  R.compose<IProduct | undefined, IProductFormat[], IProductFormat | any>(
    (productFormats) => {
      const lookingForFormat = inverse
        ? FORMAT_TO_INVERSE_MAP[
            ANY_FORMAT_TO_SPEC_FORMAT_MAP[(format || '') as AnySpecFormatType]
          ]
        : ANY_FORMAT_TO_SPEC_FORMAT_MAP[(format || '') as AnySpecFormatType]
      const found = R.find((f) => {
        const pfFormat = R.propOr('', 'format', f)
        return pfFormat === lookingForFormat
      }, productFormats)
      return found || {}
    },
    R.propOr([], 'formats'),
  )

export const getProductFormatPropByName = (
  format: AnyFormatType | undefined,
  prop: string | number | (string | number)[],
  fallback: any = null,
) =>
  R.compose<IProduct | undefined, IProductFormat | any, any>(
    R.pathOr(fallback, [utils.array.ensureArray(prop)]),
    getProductFormatByName(
      ANY_FORMAT_TO_SPEC_FORMAT_MAP[
        format || ONER_V1P1_CSV_ROSTERING_IMPORT_BULK
      ],
    ),
  )

export const productHasModelingStatus =
  (status: IProductModelingStatuses) =>
  (product?: IProduct, format?: AnySpecFormatType) =>
    getProductFormatPropByName(format, 'modellingStatus')(product) === status

export const isProductCharacterized = (
  product?: IProduct,
  format?: AnyFormatType,
) =>
  productHasModelingStatus(CHARACTERISED_MODELLING_STATUS)(
    product,
    ANY_FORMAT_TO_SPEC_FORMAT_MAP[
      format || ONER_V1P1_CSV_ROSTERING_IMPORT_BULK
    ],
  )

export const canProductFormatBePublished = (
  product: IProduct,
  format: AnySpecFormatType,
) => productHasModelingStatus(CHARACTERISED_MODELLING_STATUS)(product, format)

export const productModeledOrCharacterized = (
  product?: IProduct,
  format?: AnySpecFormatType,
) =>
  productHasModelingStatus(MODELLED_MODELLING_STATUS)(product, format) ||
  productHasModelingStatus(CHARACTERISED_MODELLING_STATUS)(product, format)

export const productNeedsModeling = (
  product?: IProduct,
  format?: AnySpecFormatType,
) => !productHasModelingStatus(CHARACTERISED_MODELLING_STATUS)(product, format)

export const isRosteredByOtherProduct = (product: IProduct) =>
  utils.hasValue(product.rosteringTool)

export const displaySpecVersion = (version: string) =>
  (R.is(String, version) ? version : '').split('p').join('.')
