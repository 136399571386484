import React from 'react'
import clsx from 'clsx'
import * as R from 'ramda'

import {
  ReportStatus,
  CHARACTERIZATION_STATUS_TO_DISPLAY_MAP,
  RED_REPORT_STATUS,
  GRAY_REPORT_STATUS,
} from 'domains/reports/models/IReportStatuses'
import { getCharReportRowKeyWidth } from 'domains/characterizations/constants/characterizationReport'
import colors from 'lib/styles/colors'
import ReportStatusIcon from 'domains/reports/components/ReportStatusIcon'
import { utils } from '@ims/1edtech-frontend-common'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'
import View from 'lib/components/View'

interface IInfoItemProps {
  status: ReportStatus | 'INFO'
  message: string
  'data-test'?: string
}

export function RESTParameterInfoItem(props: IInfoItemProps) {
  return (
    <div className="flex flex-row pb-2" data-test={props['data-test']}>
      <p className="break-words">
        <span className="font-bold">
          {CHARACTERIZATION_STATUS_TO_DISPLAY_MAP[props.status]}
        </span>{' '}
        {props.message}
      </p>
    </div>
  )
}

interface IRowHeaderProps {
  content: any
  centered: boolean
}

interface IRowProps {
  isComparison?: boolean
  headers?: IRowHeaderProps[]
  name?: string
  endpointName: string
  status: ReportStatus | ReportStatus[]
  index: number
  infoItems?: any[]
  'data-test': string
}
const isPrint = isOnPrintScreen()

export function RESTParameterRow(props: IRowProps) {
  const { infoItems = [] } = props
  const nameWidths = isPrint ? [100, 100] : getCharReportRowKeyWidth(-12)
  const statuses = (props.isComparison
    ? props.status
    : [props.status]) as ReportStatus[]
  return (
    <View
      bg={props.index % 2 === 0 || isPrint ? 'white' : 'background'}
      display="grid"
      gridTemplateColumns={
        props.isComparison
          ? [
              `120px 86px 86px minmax(200px, 1fr)`,
              `${nameWidths[0]}px 86px 86px minmax(200px, 1fr)`,
              `${nameWidths[1]}px 140px 140px minmax(200px, 1fr)`,
            ]
          : [
              `${nameWidths[0]}px 36px minmax(200px, 1fr)`,
              `${nameWidths[0]}px 36px minmax(200px, 1fr)`,
              `${nameWidths[1]}px 36px minmax(200px, 1fr)`,
            ]
      }
      px={3}
      minHeight={isPrint ? 24 : 36}
      className="avoid-print-break"
      data-test={props['data-test']}
      data-status={props.status}
    >
      <View
        flexible="column-v-center"
        borderRight={`solid 1px ${colors.reportTableBorder}`}
        mr={isPrint ? 0 : 3}
        pl={isPrint ? 0 : '38px'}
      >
        {utils.hasValue(props.name) && (
          <h3
            className={clsx('font-bold', {
              'text-xs': isPrint,
              'text-base': !isPrint,
            })}
          >
            {props.name}
          </h3>
        )}
      </View>

      {!utils.hasValue(props.headers) &&
        statuses.map((status, index) => (
          <div
            key={`${props.endpointName}-status-${index}`}
            className={clsx('flex flex-col items-center justify-center', {
              'py-2': !isPrint,
            })}
          >
            <ReportStatusIcon
              status={status}
              circle={status === GRAY_REPORT_STATUS}
              fontSize={isPrint ? 14 : undefined}
            />
          </div>
        ))}

      {(props.headers || []).map((header, index) => (
        <View
          key={`${props.endpointName}-header-${index}`}
          flexible={header.centered ? 'column-center' : 'column-v-center'}
        >
          {header.content}
        </View>
      ))}

      {utils.hasValue(infoItems) && (
        <View flexible="column" pt={2}>
          {infoItems.map((item, index) => (
            <View key={`${props.endpointName}-info-item-${index}`}>{item}</View>
          ))}
        </View>
      )}
    </View>
  )
}

interface IGenericParam {
  paramName: string
  errors?: string[]
  status: ReportStatus
  supported?: boolean
  supportedByCharacterisation?: boolean
  errorTitle?: string
  errorMessage?: string
  notes?: string
}
interface IProps {
  isComparison?: boolean
  name: string
  endpointName: string
  params: IGenericParam[]
  index: number
}

export const findParamByName = (name: string, params: IGenericParam[]) => {
  const paramName = name.toLowerCase().replace(' ', '')
  const foundParam = R.find(
    (param) => param.paramName.replace(' ', '').toLowerCase() === paramName,
    params,
  )
  return foundParam
}

export default function RESTParameterDetails(props: IProps) {
  const foundParam = findParamByName(props.name, props.params)
  if (!foundParam) {
    return null
  }

  const status = !props.isComparison
    ? foundParam.status
    : [
        !foundParam.supportedByCharacterisation
          ? RED_REPORT_STATUS
          : foundParam.status,
        foundParam.supported === false ? GRAY_REPORT_STATUS : foundParam.status,
      ]

  const errors = !props.isComparison
    ? foundParam.errors || []
    : utils.hasValue(foundParam.errorMessage)
    ? [foundParam.errorMessage]
    : []

  const infoItems: any[] = []

  let prevMessageCount = 0
  if (props.isComparison && utils.hasValue(foundParam.notes)) {
    prevMessageCount++
    infoItems.push(
      <RESTParameterInfoItem
        key={`${props.endpointName}-param-${foundParam.paramName}-notes`}
        status="INFO"
        message={foundParam?.notes || ''}
        data-test={`${props.endpointName}-${props.name}-messages-0`}
      />,
    )
  }

  errors.forEach((error, index) =>
    infoItems.push(
      <RESTParameterInfoItem
        key={error}
        status={
          utils.hasValue(foundParam.errorMessage)
            ? foundParam.status
            : RED_REPORT_STATUS
        }
        message={error || ''}
        data-test={`${props.endpointName}-${props.name}-messages-${
          index + prevMessageCount
        }`}
      />,
    ),
  )

  return (
    <RESTParameterRow
      isComparison={props.isComparison}
      name={props.name}
      endpointName={props.endpointName}
      status={status}
      index={props.index}
      infoItems={infoItems}
      data-test={`param-status-${props.endpointName}-${props.name}`}
    />
  )
}
