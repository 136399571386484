import { putRequest } from 'lib/api/api'
import { getRecord } from 'lib/records/workflows/getRecord'
import { AnyFormatType } from 'domains/formats/constants/formats'
import comparisons, {
  COMPARISONS_RECORD,
} from 'lib/records/modules/comparisons'
import { mergeOrReplaceEntities } from 'lib/records/workflows/entities'

export const updateComparisonSharing = async (
  id: string | number,
  format: AnyFormatType,
  share: boolean,
) => {
  await putRequest(`comparisons/${id}/${share ? 'share' : 'private'}`)

  const { success, data } = await getRecord(COMPARISONS_RECORD, id)
  if (success) {
    mergeOrReplaceEntities(true, comparisons, data)
  }
}
