import * as normalizr from 'normalizr'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

export const ORGANIZATIONS_RECORD = 'organizations'

const organizations: records.modules.IModule = {
  record: ORGANIZATIONS_RECORD,
  responseDataKey: 'orgs',
  getCreateResponseData: R.propOr({}, 'data'),
  schema: new normalizr.schema.Entity(
    ORGANIZATIONS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(),
  ),
  api: {
    create: () => 'districts',
    prepareCreateData: (data) => ({
      org: {
        ...data,
        shareComparisons: false,
      },
    }),
    get: (id) => `orgs/${id}`,
    getList: () => 'orgs',
    update: (id) => `orgs/${id}`,
    prepareUpdateData: (data: any) => ({
      org: R.pick(
        [
          'id',
          'name',
          'membershipLevel',
          'certificationLevel',
          'segment',
          'shareComparisons',
          'imsurl',
          'logourl',
          'externalurl',
          'externalsitetitle',
          'lockoutDate',
          'referrerId',
          'ncesId',
          'country',
          'state',
          'preferredPartner',
          'trustedAppsLtiEnabled',
          'ltiPlatformId',
          'ltiPlatformName',
          'overrides',
        ],
        data,
      ),
    }),
    delete: () => '',
  },
}

export default organizations
