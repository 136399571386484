import React from 'react'
import { Button, View, Icon } from 'lib/components'
import { utils } from '@ims/1edtech-frontend-common'
import Text from 'lib/components/Text'

interface IProps {
  variant: string
  title: string
  onClick?: () => void
  pending?: boolean
  badgeType?: string
  badgeContent?: any
  icon?: string
  color?: string
  disabled?: boolean
  extra?: any
  'data-test'?: string
}

const LargeButton = (props: IProps) => {
  const {
    variant,
    title,
    onClick,
    badgeType,
    badgeContent,
    icon = '',
    color = 'white',
    disabled = false,
    extra = {},
  } = props
  return (
    <Button
      variant={variant}
      py={3}
      width="100%"
      onClick={onClick}
      badgeType={badgeType}
      badgeContent={badgeContent}
      data-test={props['data-test']}
      large={true}
      mb={3}
      disabled={disabled}
      pending={props.pending}
      {...extra}
    >
      <View flexible="row-center">
        {utils.hasValue(icon) && (
          <Icon className={icon || ''} color={color} mr={2} />
        )}
        <Text color={color} fontSize={18}>
          {title}
        </Text>
      </View>
    </Button>
  )
}

export default LargeButton
