import React, { PureComponent, RefObject } from 'react'
import * as R from 'ramda'
import TetherComponent from 'react-tether'

import './Dropdown.css'
import Icon from 'lib/components/Icon'
import View from 'lib/components/View'

interface IProps {
  attachment?: string
  children?: any
  constrainTo?: string
  disableToggleClose?: boolean
  targetProps?: any
  showChevron?: boolean
  target?: any
  'data-test'?: string
}
export default class Dropdown extends PureComponent<IProps> {
  private isUnmounted = false

  state = {
    isOpen: false,
  }

  componentDidUpdate(prevProps: IProps) {
    if (!prevProps.children && this.props.children && !this.state.isOpen) {
      this.setState({ isOpen: true })
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true
  }

  close = () => {
    document.removeEventListener('click', this.close)
    if (!this.isUnmounted) {
      this.setState({ isOpen: false })
    }
  }

  toggleOpen = (e: any) => {
    if (e && e.stopPropagation) {
      e.stopPropagation()
    }
    if (!this.state.isOpen) {
      document.addEventListener('click', this.close)
    }
    if (this.props.disableToggleClose && this.state.isOpen) {
      return
    }
    this.setState(R.over(R.lensProp('isOpen'), R.not))
  }

  renderTarget = (ref: RefObject<HTMLElement>) => {
    const { isOpen } = this.state
    const { showChevron, target, targetProps = {} } = this.props
    if (!target) {
      return <View ref={ref as RefObject<HTMLDivElement>} />
    }

    return (
      <View
        ref={ref as RefObject<HTMLDivElement>}
        onClick={this.toggleOpen}
        hover="clickable"
        className="dropdown"
        flexible="row-v-center"
        data-test={this.props['data-test']}
        {...targetProps}
      >
        {target}
        {showChevron && (
          <Icon
            color="textLight"
            className={`
                  fas fa-chevron-down
                  rotate-chevron
                  ${isOpen ? 'down' : ''}
                `}
          />
        )}
      </View>
    )
  }

  renderElement = (ref: RefObject<HTMLElement>) => {
    if (this.state.isOpen) {
      return (
        <View ref={ref as RefObject<HTMLDivElement>}>
          <View animation="fadeIn" animationDelay={50}>
            {this.props.children}
          </View>
        </View>
      )
    }

    return null
  }

  render() {
    const { attachment = 'top center', constrainTo = 'scrollParent' } =
      this.props

    return (
      // @ts-ignore
      <TetherComponent
        attachment={attachment}
        constraints={[
          {
            to: constrainTo,
            attachment: 'together',
            pin: true,
            outOfBoundsClass: '',
            pinnedClass: '',
          },
        ]}
        renderTarget={this.renderTarget}
        renderElement={this.renderElement}
      />
    )
  }
}
