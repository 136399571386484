import React from 'react'
import * as R from 'ramda'

import { View, ExpansionPanel } from 'lib/components'
import ReportExpansionHeader from 'domains/reports/components/ReportExpansionHeader'
import ITransformedRESTCharacterizationReport, {
  ITransformedRESTServiceCharacterizationReport,
} from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import IAccordionHeaderState from 'domains/characterizations/models/IAccordionHeaderState'
import RESTPCharacterizationReportService from 'domains/characterizations/components/CharacterizationReport/RESTPCharacterizationReportService'
import { GRAY_REPORT_STATUS } from 'domains/reports/models/IReportStatuses'
import { AnySpecFormatType } from 'domains/formats/constants/formats'

interface IProps {
  format: AnySpecFormatType
  report: ITransformedRESTCharacterizationReport
  specification: IRestCSpec
  headerStates: IAccordionHeaderState[]
  getHeaderState: (name: string) => any
  onToggleHeader: (name: string) => any
  allExpanded: boolean
  allCollapsed: boolean
  setAllExpandedState: (state: boolean) => any
  setAllCollapsedState: (state: boolean) => any
}

export default function RESTPCharacterizationReportServices(props: IProps) {
  const onRequestChange = (
    service: ITransformedRESTServiceCharacterizationReport,
  ) => () => {
    const state = props.getHeaderState(service.serviceName)
    if (state.open && props.allExpanded) {
      props.setAllExpandedState(false)
    }
    if (!state.open && props.allCollapsed) {
      props.setAllCollapsedState(false)
    }
    props.onToggleHeader(service.serviceName)
  }

  const renderService = (
    service: ITransformedRESTServiceCharacterizationReport,
  ) => {
    const headerState = props.getHeaderState(service.serviceName)
    if (!headerState) {
      return null
    }

    return (
      <ExpansionPanel
        key={service.serviceName}
        isOpen={headerState.open}
        onRequestChange={onRequestChange(service)}
        summary={
          <ReportExpansionHeader
            status={service.status}
            name={service.serviceName}
            open={headerState.open}
            circle={service.status === GRAY_REPORT_STATUS}
            data-test={`service-report-header-${service.serviceName}`}
          />
        }
        details={
          <RESTPCharacterizationReportService
            format={props.format}
            report={props.report}
            service={service}
            specification={props.specification}
            serviceExpanded={headerState.open}
            serviceCollapsed={!headerState.open}
            allExpanded={props.allExpanded}
            allCollapsed={props.allCollapsed}
            setAllExpandedState={props.setAllExpandedState}
            setAllCollapsedState={props.setAllCollapsedState}
          />
        }
      />
    )
  }

  const { report } = props
  if (report.serviceCharacterizations.length < 1) {
    return null
  }

  return (
    <View mb={3} data-test="characterization-report-services">
      {R.sort(
        R.ascend(R.prop('serviceName')),
        report.serviceCharacterizations,
      ).map(renderService)}
    </View>
  )
}
