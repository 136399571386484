import React, { useMemo } from 'react'
import axios from 'axios'
import { Formik } from 'formik'
import {
  FileInput,
  FormErrorMessage,
  FormInput,
  ModalWrapper,
  Text,
} from 'lib/components'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { meSelector } from 'domains/authentication/selectors/me'
import { ERROR_TOAST, SUCCESS_TOAST, showToast } from 'lib/utils/toast'
import { XIcon } from '@heroicons/react/solid'
import clsx from 'clsx'

interface FormValues {
  name: string
  orgname: string
  description: string
  email: string
  attachments: File[]
}

interface ZapierHookBody {
  name: string
  orgname: string
  description: string
  email: string
  attachments: File[]
  subject: string
}

interface ZapierHookResponse {
  attempt: string
  id: string
  request_id: string
  status: string
}

interface Props {
  isOpen: boolean
  onClose: () => void
}

const sendZapierRequest = (body: ZapierHookBody) => {
  const formData = new FormData()

  formData.append('subject', body.subject)
  formData.append('name', body.name)
  formData.append('orgname', body.orgname)
  formData.append('description', body.description)
  formData.append('email', body.email)

  body.attachments.forEach((file) => {
    formData.append('attachments[]', file)
  })

  return axios.post<ZapierHookResponse>(
    'https://hooks.zapier.com/hooks/catch/13143178/37wez0i/',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  orgname: Yup.string().required('Organization Name is required'),
  description: Yup.string().required('Description is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  attachments: Yup.array().of(Yup.mixed<File>()),
})

export default function SupportModal(props: Props) {
  const me = useSelector(meSelector)

  if (!me) throw new Error('Not authorized')

  const initialValues: FormValues = useMemo(
    () => ({
      name: `${me.firstName} ${me.lastName}`,
      orgname: me.organizationName,
      description: '',
      email: me.email,
      attachments: [],
    }),
    [me.email, me.firstName, me.lastName, me.organizationName],
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        return sendZapierRequest({
          name: values.name,
          orgname: values.orgname,
          description: values.description,
          email: values.email,
          attachments: values.attachments,
          subject: 'TAMS Support Inquiry',
        })
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formProps) => {
        return (
          <ModalWrapper
            title="TAMS Support Inquiry"
            isOpen={props.isOpen}
            actions={[
              {
                text: 'Submit',
                variant: 'start',
                extra: { type: 'submit' },
                onClick: async () => {
                  await formProps
                    .submitForm()
                    .then(() => {
                      showToast(
                        SUCCESS_TOAST,
                        `Support inquiry sent successfully`,
                      )
                      formProps.resetForm()
                      props.onClose()
                    })
                    .catch(() => {
                      showToast(ERROR_TOAST, `Failed to send support inquiry`)
                    })
                },
              },
              {
                text: 'Cancel',
                variant: 'neutral',
                onClick: () => {
                  props.onClose()
                  formProps.resetForm()
                },
              },
            ]}
            pending={formProps.isSubmitting}
            maxWidth={['100%', 820, 900]}
          >
            <div>
              <FormInput
                label="Full Name"
                name="name"
                placeholder="Full Name"
                value={formProps.values.name || ''}
                handleChange={formProps.handleChange}
                valueDataTest="support-name-field"
                disabled
                required
              />
              <FormInput
                label="Organization Name"
                name="orgname"
                placeholder="Organization Name"
                value={formProps.values.orgname || ''}
                handleChange={formProps.handleChange}
                valueDataTest="support-orgname-field"
                disabled
                required
              />
              <FormInput
                label="Email"
                name="email"
                placeholder="Email"
                value={formProps.values.email || ''}
                handleChange={formProps.handleChange}
                valueDataTest="support-email-field"
                disabled
                required
              />
              <FormInput
                label="Description"
                aria-label="Description"
                name="description"
                placeholder="Description"
                value={formProps.values.description || ''}
                handleChange={formProps.handleChange}
                valueDataTest="support-description-field"
                textArea
                inputProps={{
                  width: '100%',
                  rows: 7,
                }}
                required
              />

              <div className="mt-4">
                <Text fontWeight={700} variant="subtitle" mb={2}>
                  Attachments
                </Text>

                <div>
                  <div>
                    <FileInput
                      aria-label="Upload attachments"
                      text="Browse"
                      variant="complete"
                      name="attachments"
                      onChange={(event) => {
                        const files = Array.from(
                          event.currentTarget.files || [],
                        )

                        formProps.setFieldValue('attachments', [
                          ...formProps.values.attachments,
                          ...files,
                        ])
                      }}
                      multiple
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    {formProps.values.attachments &&
                      formProps.values.attachments.map((file: File) => (
                        <div className="flex items-center gap-2 mt-2 w-80">
                          <Text key={file.name} fontWeight={700}>
                            {file.name}
                          </Text>
                          <XIcon
                            className={clsx(
                              'w-5 h-5 text-gray-700 transform hover:scale-110 hover:text-red-500 cursor-pointer flex-none',
                            )}
                            onClick={() => {
                              formProps.setFieldValue(
                                'attachments',
                                formProps.values.attachments.filter(
                                  (f) => f !== file,
                                ),
                              )
                            }}
                          />
                        </div>
                      ))}
                  </div>
                </div>
                <FormErrorMessage name="attachments" showImmediately />
              </div>
            </div>
          </ModalWrapper>
        )
      }}
    </Formik>
  )
}
