import React from 'react'

import { Text, Span } from 'lib/components'
import { ITextProps } from 'lib/components/Text'

interface IProps extends ITextProps {
  text: string // update @@ notes
  highlight: string
  substitutionPattern?: string
}

export default function TextWithBoldHighlight(props: IProps) {
  const { text, highlight, substitutionPattern = '@@', ...rest } = props
  return (
    <Text
      fontSize="inherit"
      lineHeight="inherit"
      wordBreak="break-word"
      {...rest}
    >
      {text.substr(0, text.indexOf(substitutionPattern))}
      <Span fontWeight={700} wordBreak="break-word">
        {highlight}
      </Span>
      {text.substr(text.indexOf(substitutionPattern) + 2)}
    </Text>
  )
}
