import React from 'react'
import * as R from 'ramda'

import { IOrgOverrideAction } from 'domains/orgs/models/IOrgOverrideAction'

export const OrgActionsContext = React.createContext<{
  actions: string[]
  overrides: IOrgOverrideAction[]
}>({
  actions: [],
  overrides: [],
})

export const isActionAllowed = (
  orgActions: string[],
  orgOverrides: IOrgOverrideAction[],
  action: string,
) => {
  const matchingOverride = orgOverrides.find((o) => o.name === action)
  const hasAction = R.includes(action, orgActions)
  return (
    (matchingOverride && matchingOverride.forceAllow) ||
    (hasAction && (!matchingOverride || !matchingOverride.forceDeny))
  )
}

export const useIsActionAllowed = (action: string) => {
  const { actions, overrides } = React.useContext(OrgActionsContext)
  return isActionAllowed(actions, overrides, action)
}

type Props = {
  action: string
  children?: any
  allowed?: any
  notAllowed?: any
}

export default function OrgAction(props: Props) {
  const { actions, overrides } = React.useContext(OrgActionsContext)
  const {
    action,
    children,
    allowed: Allowed,
    notAllowed: NotAllowed,
    ...rest
  } = props

  const isEnabled = isActionAllowed(actions, overrides, action)

  if (isEnabled) {
    if (children) {
      return children
    }
    return <Allowed {...rest} />
  }

  if (NotAllowed) {
    return <NotAllowed {...rest} />
  }

  return null
}
