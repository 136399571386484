import React, { PureComponent } from 'react'

import { Button, View } from 'lib/components'
import { utils } from '@ims/1edtech-frontend-common'
import ICharacterization from 'domains/characterizations/models/ICharacterization'

interface IProps extends ICharacterization {
  isOpen: boolean
  onClick: (characterization: ICharacterization) => void
}
export default class CharacterizationNotesCell extends PureComponent<IProps> {
  onClick = () => this.props.onClick(this.props)

  render() {
    const { id, notes, isOpen } = this.props
    if (!utils.hasValue(notes)) {
      return null
    }
    return (
      <View flexible="column-center">
        <Button
          variant={isOpen ? 'info' : 'start'}
          onClick={this.onClick}
          data-test={`${id}-view-edit-notes`}
          width={72}
          innerMinWidth="none"
        >
          {isOpen ? 'close' : 'view'}
        </Button>
      </View>
    )
  }
}
