import React, { PureComponent } from 'react'
import * as R from 'ramda'

import { View, ExpansionPanel } from 'lib/components'
import IFileSetValidationErrorReport from 'domains/characterizations/models/IFileSetValidationErrorReport'
import ICSVSpecification from 'domains/specifications/models/ICSVSpecification'
import { findSpecColumn } from 'domains/modeling/selectors/csvCModelToSpec'
import ICSVSpecificationFileColumn from 'domains/specifications/models/ICSVSpecificationFileColumn'
import CriticalErrorHeader from 'domains/characterizations/components/CharacterizationReport/CriticalErrorHeader'
import CriticalErrorMessage from 'domains/characterizations/components/CharacterizationReport/CriticalErrorMessage'
import CriticalErrorSpecDetails from 'domains/characterizations/components/CharacterizationReport/CriticalErrorSpecDetails'

interface IProps {
  isOpen: boolean
  fileLabel: string
  column: string
  errors: IFileSetValidationErrorReport[]
  specification: ICSVSpecification
}

interface IState {
  open: boolean
}

export default class CharacterizationFileColumnReport extends PureComponent<IProps> {
  state: IState = {
    open: this.props.isOpen,
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ open: this.props.isOpen })
    }
  }

  onToggleOpen = () => this.setState(R.over(R.lensProp('open'), R.not))

  getSpecColumn = (): ICSVSpecificationFileColumn | null =>
    findSpecColumn(
      this.props.specification,
      this.props.fileLabel,
      this.props.column,
    )

  getIdKey = () => `${this.props.fileLabel}-${this.props.column}`

  renderDetails() {
    const { errors, fileLabel } = this.props
    const specColumn = this.getSpecColumn()
    const isManifest = fileLabel === 'manifest.csv'
    return (
      <View mt={3} ml={2}>
        {!isManifest && (
          <CriticalErrorSpecDetails
            attributeName={fileLabel}
            type={specColumn ? specColumn.dataType : undefined}
            description={specColumn ? specColumn.notes : undefined}
          />
        )}
        {errors.map((error: IFileSetValidationErrorReport, index: number) => (
          <CriticalErrorMessage
            key={`${this.getIdKey()}-error-${index}`}
            data-test={`${this.getIdKey()}-error`}
            message={error.description}
          />
        ))}
      </View>
    )
  }

  render() {
    const { open } = this.state
    return (
      <ExpansionPanel
        key={this.getIdKey()}
        isOpen={open}
        onRequestChange={this.onToggleOpen}
        summary={
          <CriticalErrorHeader
            name={this.props.column}
            open={this.state.open}
            data-test={this.getIdKey()}
          />
        }
        details={this.renderDetails()}
      />
    )
  }
}
