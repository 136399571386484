import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  title: string
  icon: any
  enabled: boolean
  hasValue?: boolean
  route?: string
  onClick?: () => any
}

export default function TrustedAppDetailLink(props: IProps) {
  const displayGray = !props.enabled || props.hasValue === false
  const content = (
    <div
      className={clsx('h-14 flex flex-row items-center rounded', {
        'hover:shadow-lg': props.enabled || props.hasValue === false,
      })}
      onClick={props.enabled ? props.onClick : undefined}
      data-test={`link-${props.title}`}
    >
      <div
        className={clsx(
          'flex flex-col items-center justify-center h-full w-14',
          {
            'bg-ta-gray-1': displayGray,
            'bg-ta-blue-1': !displayGray,
            'cursor-pointer': props.enabled,
            'cursor-not-allowed': !props.enabled,
          },
        )}
      >
        {props.icon}
      </div>

      <div
        className={clsx(
          'flex flex-1 flex-col items-center justify-center pl-2 h-full',
          {
            'bg-ta-gray-2': displayGray,
            'bg-ta-blue-2': !displayGray,
            'cursor-pointer': props.enabled,
            'cursor-not-allowed': !props.enabled,
          },
        )}
      >
        <p className="text-lg text-white">{props.title}</p>
      </div>

      <div
        className={clsx(
          'flex flex-col items-center justify-center h-full w-14',
          {
            'bg-ta-gray-3': displayGray,
            'bg-ta-blue-3': !displayGray,
            'cursor-pointer': props.enabled,
            'cursor-not-allowed': !props.enabled,
          },
        )}
      >
        <i className="fas fa-chevron-right text-lg text-white" />
      </div>
    </div>
  )

  if (props.enabled && utils.hasValue(props.route)) {
    return <Link to={`${props.route}`}>{content}</Link>
  }

  return content
}
