import React from 'react'

import { getNavigationParam } from 'lib/utils/navigation'
import useFullProductByFormat from 'domains/products/hooks/useFullProductByFormat'
import useFullRESTCModel from 'domains/modeling/hooks/useFullRESTCModel'
import { utils } from '@ims/1edtech-frontend-common'
import { View, Spinner, Text, Icon, WizardFooter, Span } from 'lib/components'
import IProduct from 'domains/products/models/IProduct'
import IRestCModel from 'domains/modeling/models/IRestCModel'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import BaseProductModelingDetailsBreadCrumbs from 'domains/products/components/BaseProductModelingBreadCrumbs'
import Tabs, { Tab } from 'lib/components/Tabs'
import {
  SERVICE_PARENT_ENDPOINT_MAP,
  OR_1P1_REST_ROSTERING_CONSUMER_SERVICES,
} from 'domains/modeling/constants/restCOneRoster1p1'
import { findModelServiceEndointByName } from 'domains/modeling/utils/restCOR1p1utils'
import ProductModelingRestServiceEdit from 'domains/modeling/components/ProductModeling/ProductModelingRestServiceEdit'
import { FormikHelpers, Formik } from 'formik'
import IRestCModelServiceEndpoint from 'domains/modeling/models/IRestCModelServiceEndpoint'
import { updateRestCServices } from 'domains/modeling/workflows/updateRestCServices'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import {
  IServicesChildEndpoints,
  getServicesChildEndpointsMap,
} from 'domains/modeling/models/IServicesChildEndpoints'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import { getProductModelingRoute } from 'domains/products/navigation/routes'
import useModalState from 'lib/hooks/useModalState'
import UnsavedModelWarning from 'domains/modeling/components/UnsavedModelWarning'
import { RouterContext } from 'domains/application/screens/AppScreen'
import { RouteComponentProps } from 'react-router'
import { trackRestCModelingAnalytics } from 'domains/modeling/utils/trackModelingAnalytics'

export interface IRestCModelServiceFormValues {
  endpoints: IRestCModelServiceEndpoint[]
  childEndpoints: IServicesChildEndpoints
}

export default function ProductModelingRestServicesEditScreen(
  props: RouteComponentProps,
) {
  const router = React.useContext(RouterContext) as RouteComponentProps
  const servicesParam = getNavigationParam(props, 'services') as string
  const services = servicesParam.split(',')
  const [productData, , , productPending] = useFullProductByFormat(
    props,
    'id',
    'format',
  )
  const [modelData, specData, modelPending] = useFullRESTCModel(
    props,
    'id',
    'modelId',
    'format',
  )
  const [cancelRoute, setCancelRoute] = React.useState('')

  const [cancelWarningOpen, openCancelWarning, closeCancelWarning] =
    useModalState()

  const onCancelConfirmed = () => {
    trackRestCModelingAnalytics('cancelled_model_services_changes')
    if (utils.hasValue(cancelRoute)) {
      router.history.push(cancelRoute)
    } else {
      router.history.goBack()
    }
  }

  const onNavigateAway = (e: React.MouseEvent<any>, route: string) => {
    e.preventDefault()
    setCancelRoute(route)
    openCancelWarning()
  }

  if (
    productPending ||
    modelPending ||
    !utils.hasValue(productData) ||
    !utils.hasValue(modelData)
  ) {
    return (
      <View variant="screen">
        <Spinner centered={true} />
      </View>
    )
  }

  const product = productData as IProduct
  const model = modelData as IRestCModel
  const specification = specData as IRestCSpec

  const onSave = async (
    values: IRestCModelServiceFormValues,
    bag: FormikHelpers<IRestCModelServiceFormValues>,
  ) => {
    await updateRestCServices(model.id, values.endpoints, values.childEndpoints)
    bag.setSubmitting(false)

    props.history.push(getProductModelingRoute(product.id, model.id))
  }

  const parentEndpoints = services.map((serviceName) => {
    const serviceParentEndpointName = SERVICE_PARENT_ENDPOINT_MAP[serviceName]
    return findModelServiceEndointByName(
      model,
      serviceName as OR_1P1_REST_ROSTERING_CONSUMER_SERVICES,
      serviceParentEndpointName,
    )!
  })

  const childEndpoints = getServicesChildEndpointsMap(
    model,
    services as OR_1P1_REST_ROSTERING_CONSUMER_SERVICES[],
  )

  return (
    <Formik
      initialValues={{ endpoints: parentEndpoints, childEndpoints }}
      onSubmit={onSave}
      enableReinitialize={true}
    >
      {(formikProps) => (
        <View variant="screen">
          <DocumentTitle
            title={`Product Service Model - ${product.name} - ${servicesParam}`}
          />
          <BaseProductModelingDetailsBreadCrumbs
            productId={product.id}
            modelId={model.id}
            crumbs={[{ name: services.join(', ') }]}
            beforeNavigate={onNavigateAway}
          />
          <View variant="paper">
            <WidgetHeader
              title={
                <Text fontSize="inherit" fontWeight={400}>
                  REST Consumer v1.1 Modeling Setup:{' '}
                  <Span fontWeight={700}>
                    Configure {services.join(', ')} for product {product.name}
                  </Span>
                </Text>
              }
            />
            <View mt={4} />
            <Tabs>
              {formikProps.values.endpoints.map((endpoint, index) => {
                const onToggle = () => {
                  formikProps.setFieldValue(
                    `endpoints[${index}].enabled`,
                    !endpoint.enabled,
                  )
                  const serviceChildrenEndpoints = childEndpoints[endpoint.name]
                  for (const endpointName of Object.keys(
                    serviceChildrenEndpoints,
                  )) {
                    formikProps.setFieldValue(
                      `childEndpoints[${endpoint.name}][${endpointName}].enabled`,
                      !endpoint.enabled,
                    )
                  }
                }
                const rightContent = (isActive: boolean) => {
                  const color = !endpoint.enabled
                    ? 'white'
                    : endpoint.enabled
                    ? 'start'
                    : 'text'
                  return (
                    <View
                      flexible="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      ml={3}
                      cursor="pointer"
                      onClick={isActive ? onToggle : undefined}
                      width={48}
                      data-test={`service-toggle-${endpoint.name}`}
                    >
                      <Icon className="fas fa-power-off" color={color} />
                      <Text ml={1} color={color} userSelect="none">
                        {endpoint.enabled ? 'On' : 'Off'}
                      </Text>
                    </View>
                  )
                }
                return (
                  <Tab
                    key={endpoint.name}
                    title={endpoint!.leaf}
                    disabled={!endpoint.enabled}
                    rightContent={rightContent}
                    data-test={`tab-${endpoint.name}`}
                  >
                    <ProductModelingRestServiceEdit
                      model={model}
                      specification={specification}
                      endpointIndex={index}
                      endpoint={endpoint}
                      childEndpoints={formikProps.values.childEndpoints}
                      setFieldValue={formikProps.setFieldValue}
                      handleChange={formikProps.handleChange}
                    />
                  </Tab>
                )
              })}
            </Tabs>

            <WizardFooter
              buttons={[
                {
                  title: 'Save',
                  onClick: formikProps.handleSubmit,
                  variant: 'tertiary',
                  dataTest: 'save-service-btn',
                },
                {
                  title: 'Cancel',
                  onClick: openCancelWarning,
                  variant: 'neutral',
                  dataTest: 'cancel-btn',
                },
              ]}
              pending={formikProps.isSubmitting}
            />
          </View>

          <UnsavedModelWarning
            isOpen={cancelWarningOpen}
            onConfirm={onCancelConfirmed}
            onDeny={closeCancelWarning}
          />
        </View>
      )}
    </Formik>
  )
}
