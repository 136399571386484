import * as R from 'ramda'
import ICharacterizationCSVReport from 'domains/characterizations/models/ICharacterizationCSVReport'
import IFileSetValidation from 'domains/characterizations/models/IFileSetValidation'
import IFileSetValidationErrorReport from 'domains/characterizations/models/IFileSetValidationErrorReport'
import { utils } from '@ims/1edtech-frontend-common'
import ITransformedCharacterizationCSVReport from 'domains/characterizations/models/ITransformedCharacterizationCSVReport'
import ICharacterizationReportFileSetCharacteriztion from 'domains/characterizations/models/ICharacterizationReportFileSetCharacteriztion'
import ITransformedCharacterizationCSVFile from 'domains/characterizations/models/ITransformedCharacterizationCSVFile'
import IFileSetCharacterizationColumn from 'domains/characterizations/models/IFileSetCharacterizationColumn'
import { IS_CONFORMANT_COLUMN } from 'domains/characterizations/constants/characterizationReport'

interface IFileMap {
  [s: string]: ITransformedCharacterizationCSVFile
}

export const transformCharacterizationCSVReport = (
  report?: ICharacterizationCSVReport,
): ITransformedCharacterizationCSVReport => {
  const fileSetValidations = R.propOr<
    IFileSetValidation[],
    any | undefined,
    IFileSetValidation[]
  >([], 'fileSetValidations', report)
  const fileSetValidation =
    fileSetValidations.length > 0 ? fileSetValidations[0] : []
  const errorReports = R.propOr<
    IFileSetValidationErrorReport[],
    any,
    IFileSetValidationErrorReport[]
  >([], 'errorReports', fileSetValidation)

  const emptyFile: ITransformedCharacterizationCSVFile = {
    fileLabel: '',
    hasFileErrors: false,
    errors: [],
    hasColumnErrors: false,
    columnErrors: {},
    columns: [],
  }

  const fileSetErrors: IFileSetValidationErrorReport[] = []
  let filesMap: IFileMap = {}
  let hasFileErrors = false

  // Conformance Errors
  errorReports.forEach((error: IFileSetValidationErrorReport) => {
    if (error.filename) {
      const fileMapFile = R.propOr<
        ITransformedCharacterizationCSVFile,
        any,
        ITransformedCharacterizationCSVFile
      >(emptyFile, error.filename, filesMap)
      hasFileErrors = true

      if (error.field) {
        const existingColumnErrors = R.propOr<
          IFileSetValidationErrorReport[],
          any,
          IFileSetValidationErrorReport[]
        >([], error.field, fileMapFile.columnErrors)
        filesMap = R.assoc(
          error.filename,
          {
            ...fileMapFile,
            fileLabel: error.filename,
            hasFileErrors: true,
            errors: R.pathOr([], ['errors'], fileMapFile),
          },
          filesMap,
        )
        filesMap = R.assocPath(
          [error.filename, 'columnErrors', error.field],
          existingColumnErrors.concat([error]),
          filesMap,
        )
      } else {
        filesMap = R.assoc(
          error.filename,
          {
            ...fileMapFile,
            fileLabel: error.filename,
            hasFileErrors: true,
            errors: [...R.pathOr([], ['errors'], fileMapFile), error],
          },
          filesMap,
        )
      }
    } else {
      fileSetErrors.push(error)
    }
  })

  // Characterization information
  const files = R.propOr<
    ICharacterizationReportFileSetCharacteriztion[],
    ICharacterizationCSVReport | undefined,
    ICharacterizationReportFileSetCharacteriztion[]
  >([], 'fileSetCharacterization', report)
  files.forEach((file: ICharacterizationReportFileSetCharacteriztion) => {
    const fileMapFile = R.propOr<
      ITransformedCharacterizationCSVFile,
      any,
      ITransformedCharacterizationCSVFile
    >(emptyFile, file.fileLabel, filesMap)
    const columns = R.propOr<
      IFileSetCharacterizationColumn[],
      ICharacterizationReportFileSetCharacteriztion,
      IFileSetCharacterizationColumn[]
    >([], 'columns', file)
    const columnsWithErrors = R.filter(
      (column: IFileSetCharacterizationColumn) =>
        column.isConformant !== IS_CONFORMANT_COLUMN ||
        utils.hasValue(column.warning),
      columns,
    )
    const hasColumnErrors = columnsWithErrors.length > 0
    filesMap = R.assoc(
      file.fileLabel,
      {
        ...fileMapFile,
        ...file,
        hasColumnErrors,
      },
      filesMap,
    )
  })

  const transformedReport = {
    validationId: R.propOr<string, any | undefined, string>(
      '',
      'validationId',
      report,
    ),
    unavailable: fileSetValidations.length === 0,
    hasFileSetErrors: fileSetErrors.length > 0,
    fileSetErrors,
    hasFileErrors,
    files: (R.keys(filesMap) as string[])
      .map((fileLabel) => {
        const file = filesMap[fileLabel]
        return {
          ...file,
          fileLabel,
        }
      })
      .sort(R.ascend(R.prop('fileLabel'))),
  }

  return transformedReport
}
