import React from 'react'

import { ICommonCartridgeCatalogEntry } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeCatalogEntry'
import { utils } from '@ims/1edtech-frontend-common'
import CCCharacterizationReportLOMKeyValue from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMKeyValue'
import CCCharacterizationReportLOMDetail from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMDetail'
import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'

interface IProps {
  title?: string
  data?: ICommonCartridgeCatalogEntry
  path: string
}

export default function CCCharacterizationReportLOMCatalogEntry(props: IProps) {
  const { showMissingMetadata } = React.useContext(ShowMissingMetadataContext)
  if (!showMissingMetadata && !utils.hasValue(props.data)) {
    return null
  }

  const inner = (
    <CCCharacterizationReportLOMKeyValue
      path={props.path}
      keyName="catalog"
      keyValue={props.data!.catalog}
      valueName="entry"
      value={props.data!.entry}
    />
  )

  if (utils.hasValue(props.title)) {
    return (
      <CCCharacterizationReportLOMDetail
        path={props.path}
        title={props.title!}
        value={inner}
      />
    )
  }

  return inner
}
