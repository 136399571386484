import React, { PureComponent } from 'react'

import ProductRosteringToolLink from 'domains/products/components/ProductRosteringToolLink'
import ListItalicTextOnlyCell from 'domains/products/components/ListItalicTextOnlyCell'
import { AnySpecFormatType } from 'domains/formats/constants/formats'

interface IProps {
  id: string | number
  format: AnySpecFormatType
  rosteringToolId?: number
  rosteringToolName?: string
  textProps?: any
  linkTextProps?: any
}

export default class ModeledByRosteringCell extends PureComponent<IProps> {
  render() {
    return (
      <ListItalicTextOnlyCell {...this.props.textProps}>
        Modeled via{' '}
        <ProductRosteringToolLink
          id={this.props.id}
          format={this.props.format}
          rosteringToolId={this.props.rosteringToolId}
          rosteringToolName={this.props.rosteringToolName}
          textProps={this.props.linkTextProps}
        />
      </ListItalicTextOnlyCell>
    )
  }
}
