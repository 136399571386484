import React from 'react'
import * as R from 'ramda'

import { View } from 'lib/components'
import { ICommonCartridgeLOMGeneral } from 'domains/commonCartridge/models/lom/ICommonCartridgeLOMGeneral'
import { ICommonCartridgeLanguageText } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeLanguageText'
import { ICommonCartridgeSourceValue } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeSourceValue'
import CCCharacterizationReportLOMCatalogEntry from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMCatalogEntry'
import CCCharacterizationReportLOMLanguageText from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMLanguageText'
import CCCharacterizationReportLOMCSVList from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMCSVList'
import CCCharacterizationReportLOMLanguageTextGrouped from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMLanguageTextGrouped'
import CCCharacterizationReportLOMSourceValue from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMSourceValue'
import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'
import { utils } from '@ims/1edtech-frontend-common'
import { ICommonCartridgeCatalogEntry } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeCatalogEntry'

interface IProps {
  data: ICommonCartridgeLOMGeneral
  path: string
}

export default function CCCharacterizationReportLOMGeneral(props: IProps) {
  const { showMissingMetadata } = React.useContext(ShowMissingMetadataContext)
  const identifiers = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['identifier'],
    props.data,
  )
  const titles = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['title'],
    props.data,
  ) as ICommonCartridgeLanguageText[]
  const languages = R.pathOr([], ['language'], props.data) as string[]
  const descriptions = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['description'],
    props.data,
  ) as ICommonCartridgeLanguageText[]
  const keywords = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['keyword'],
    props.data,
  ) as ICommonCartridgeLanguageText[]
  const coverages = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['coverage'],
    props.data,
  ) as ICommonCartridgeLanguageText[]
  const structure = R.pathOr(
    {},
    ['structure'],
    props.data,
  ) as ICommonCartridgeSourceValue
  const aggregationLevel = R.pathOr(
    {},
    ['aggregationLevel'],
    props.data,
  ) as ICommonCartridgeSourceValue

  return (
    <View>
      {identifiers.map((identifier, i) => (
        <CCCharacterizationReportLOMCatalogEntry
          key={`${props.path}.identifier.${i}`}
          path={
            !utils.hasValue(identifier)
              ? `${props.path}.identifier`
              : `${props.path}.identifier.${i}`
          }
          title={`identifier [${i + 1}]`}
          data={
            utils.hasValue(identifier)
              ? (identifier as ICommonCartridgeCatalogEntry)
              : undefined
          }
        />
      ))}

      {titles.map((title, i) => (
        <CCCharacterizationReportLOMLanguageText
          key={`${props.path}.title.${i}`}
          path={
            !utils.hasValue(title)
              ? `${props.path}.title`
              : `${props.path}.title.${i}`
          }
          title={`title [${i + 1}]`}
          data={title}
        />
      ))}

      <CCCharacterizationReportLOMCSVList
        path={`${props.path}.language`}
        title="language"
        data={languages}
      />

      {descriptions.map((description, i) => (
        <CCCharacterizationReportLOMLanguageText
          key={`${props.path}.description.${i}`}
          path={
            !utils.hasValue(description)
              ? `${props.path}.description`
              : `${props.path}.description.${i}`
          }
          title={`description [${i + 1}]`}
          data={description}
        />
      ))}

      <CCCharacterizationReportLOMLanguageTextGrouped
        path={`${props.path}.keyword`}
        title="keyword"
        data={keywords}
      />

      {coverages.map((coverage, i) => (
        <CCCharacterizationReportLOMLanguageText
          key={`${props.path}.coverage.${i}`}
          path={
            !utils.hasValue(coverage)
              ? `${props.path}.coverage`
              : `${props.path}.coverage.${i}`
          }
          title={`coverage [${i + 1}]`}
          data={coverage}
        />
      ))}

      <CCCharacterizationReportLOMSourceValue
        path={`${props.path}.structure`}
        title="structure"
        data={structure}
      />

      <CCCharacterizationReportLOMSourceValue
        path={`${props.path}.aggregationLevel`}
        title="aggregationLevel"
        data={aggregationLevel}
      />
    </View>
  )
}
