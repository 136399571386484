import * as normalizr from 'normalizr'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

export const MODELED_PRODUCTS_RECORD = 'modeledProducts'

const modeledProducts: records.modules.IModule = {
  record: MODELED_PRODUCTS_RECORD,
  responseDataKey: 'products',
  schema: new normalizr.schema.Entity(
    MODELED_PRODUCTS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(),
  ),
  api: {
    create: () => '',
    get: () => '',
    getList: (params) => {
      const orgId = R.propOr(0, 'orgId', params)
      return `suppliers/${orgId}/products`
    },
    update: () => '',
    delete: () => '',
  },
}

export default modeledProducts
