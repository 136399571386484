import React, { PureComponent } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { Text, View, Button, ProgressBar } from 'lib/components'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import {
  ONER_V1P1_CSV_ROSTERING_EXPORT_BULK,
  ONER_V1P2_CSV_ROSTERING_EXPORT_BULK,
  COMMON_CARTRIDGE_V1P2,
  COMMON_CARTRIDGE_V1P3,
  THIN_COMMON_CARTRIDGE_V1P2,
  THIN_COMMON_CARTRIDGE_V1P3,
  AnySpecFormatType,
  ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK,
  ONER_V1P1_CSV_RESOURCES_EXPORT_BULK,
} from 'domains/formats/constants/formats'
import CharacterizationFileProvider from 'domains/characterizations/components/CreateCharacterization/CharacterizationFileProvider'
import {
  UPLOAD_ZIP_FILE_SOURCE,
  ZipFileSources,
} from 'domains/characterizations/constants/createCharacterizationCSV'
import CharacterizationInProgress from 'domains/characterizations/components/CreateCharacterization/CharacterizationInProgress'
import { deleteCharacterization } from 'domains/characterizations/workflows/deleteCharacterization'

const FORMAT_TO_TITLE_MAP: { [key: string]: string } = {
  [ONER_V1P1_CSV_ROSTERING_EXPORT_BULK]: 'CSV Zip File Selection',
  [ONER_V1P2_CSV_ROSTERING_EXPORT_BULK]: 'CSV Zip File Selection',
  [ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK]: 'CSV Zip File Selection',
  [ONER_V1P1_CSV_RESOURCES_EXPORT_BULK]: 'CSV Zip File Selection',
  [COMMON_CARTRIDGE_V1P2]: 'Common Cartridge File Selection',
  [COMMON_CARTRIDGE_V1P3]: 'Common Cartridge File Selection',
  [THIN_COMMON_CARTRIDGE_V1P2]: 'Common Cartridge File Selection',
  [THIN_COMMON_CARTRIDGE_V1P3]: 'Common Cartridge File Selection',
}

interface IProps {
  format: string
  zipFileSource?: ZipFileSources
  zipFile: any
  zipFileURL?: string
  username: string
  password: string
  isSubmitting: boolean
  handleSubmit: () => void
  handleChange: (event: any) => void
  setFieldValue: (key: string, value: any) => void
  uploadProgress: number
  characterizationId: number
  height: number
}

type Props = RouteComponentProps & IProps

export class CreateCharacterizationFileDetails extends PureComponent<Props> {
  readonly state = {
    uploadProgress: false,
  }

  onCancel = async () => {
    if (this.props.characterizationId) {
      await deleteCharacterization(
        this.props.characterizationId,
        this.props.format as AnySpecFormatType,
      )
    }
    this.props.history.goBack()
  }

  renderButtons(renderSubmit = true) {
    const { handleSubmit } = this.props
    return (
      <View width="100%" flexible="column">
        {renderSubmit && (
          <Button
            type="submit"
            onClick={handleSubmit}
            variant="start"
            width="100%"
            mb={2}
            py={3}
            data-test="submit-characterization-btn"
          >
            <Text color="white" fontSize={16}>
              Submit Characterization
            </Text>
          </Button>
        )}
        <Button
          type="button"
          variant="complete"
          width="100%"
          py={3}
          onClick={this.onCancel}
        >
          <Text color="white" fontSize={16}>
            Cancel
          </Text>
        </Button>
      </View>
    )
  }

  renderProgress() {
    const { isSubmitting, uploadProgress, zipFileSource } = this.props
    if (!isSubmitting) {
      return null
    }

    const progress =
      zipFileSource === UPLOAD_ZIP_FILE_SOURCE && uploadProgress < 1 ? (
        <ProgressBar progress={uploadProgress} variant="secondary" />
      ) : (
        false
      )

    return (
      <View
        mt={4}
        width="100%"
        flexible="column-space-between"
        flex={1}
        height="85%"
      >
        <View flexible="column-center" flex={1} width="100%" height="90%">
          {progress && <View width="100%">{progress}</View>}
          <CharacterizationInProgress
            progress={uploadProgress < 1 ? uploadProgress : 0}
            progressText="Uploading your file..."
          />
        </View>
        {this.renderButtons(false)}
      </View>
    )
  }

  renderOptions() {
    const {
      zipFileSource,
      zipFile,
      zipFileURL,
      username,
      password,
      isSubmitting,
      handleChange,
      setFieldValue,
    } = this.props
    if (isSubmitting) {
      return null
    }

    return (
      <View
        mt={4}
        width="100%"
        height="80%"
        flexible="column-space-between"
        flex={1}
      >
        <View width="100%" height="85%" flex={1}>
          <CharacterizationFileProvider
            zipFileSource={zipFileSource}
            zipFile={zipFile}
            zipFileURL={zipFileURL}
            username={username}
            password={password}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
          />
        </View>
        {this.renderButtons()}
      </View>
    )
  }

  render() {
    const { format, height } = this.props
    return (
      <DetailsTopWidget
        title={FORMAT_TO_TITLE_MAP[format]}
        dataTest="characterization-file-selection-widget"
        height={height}
      >
        {this.renderProgress()}
        {this.renderOptions()}
      </DetailsTopWidget>
    )
  }
}

export default withRouter(CreateCharacterizationFileDetails)
