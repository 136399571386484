import React from 'react'
import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'

import { Span, Text, View } from 'lib/components'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import CharacterizationIcon from 'domains/characterizations/components/CharacterizationIcon'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import IProduct from 'domains/products/models/IProduct'
import ProductImageCell from 'domains/products/components/ProductImageCell'
import ICSVModelCharacterization from 'domains/characterizations/models/ICSVModelCharacterization'
import CharacterizationsLineItemsDetails from 'domains/characterizations/components/DistrictCharacterizationDetails/CharacterizationsLineItemsDetails'
import IRestCModelCharacterization from 'domains/characterizations/models/IRestCModelCharacterization'
import { isCCXCertified } from 'domains/orgs/models/IOrg'
import CCxCertifiedBadge from 'lib/components/CCxCertifiedBadge'

interface IProps {
  characterization:
    | ICharacterization
    | ICSVModelCharacterization
    | IRestCModelCharacterization
  product?: IProduct
  height: number
  extra?: any
  hideType?: boolean
  hideDetails?: boolean
  title?: string
}

export default function CharacterizationsDetailsWidget(props: IProps) {
  const {
    title = 'Characterization Details',
    characterization,
    product,
    height,
    hideDetails = false,
  } = props

  const districtState = R.pathOr('', ['districtState'], characterization)
  const districtCountry = R.pathOr('', ['districtCountry'], characterization)
  return (
    <DetailsTopWidget
      title={title}
      dataTest="characterization-details-widget"
      height={height}
    >
      <View flexible="column-center" my={3}>
        {product && (
          <ProductImageCell
            name={product.name}
            logo={product.logo}
            size={128}
          />
        )}
        {!product && (
          <CharacterizationIcon
            color={(characterization as ICharacterization).color}
            size={90}
            noResponsive={true}
            specificationName={characterization.specificationName}
          />
        )}
      </View>

      {product && (
        <Text
          fontWeight={700}
          mt={3}
          mb={2}
          data-test="characterization-details-name"
        >
          <a href={product.url} target="_blank" rel="noopener noreferrer">
            {product.name}
          </a>
        </Text>
      )}
      {!product && (
        <View>
          <Text fontWeight={700} mt={2}>
            Name
          </Text>
          <Text data-test="characterization-details-name" mb={2}>
            {(characterization as ICharacterization).name}
          </Text>

          {!hideDetails && (
            <View>
              <Text fontWeight={700} mt={2}>
                District
              </Text>
              <div className="mb-3 flex flex-row items-center">
                <Text data-test="characterization-org-name">
                  {R.pathOr(
                    R.pathOr('', ['organizationName'], characterization),
                    ['districtName'],
                    characterization,
                  )}
                  {(utils.hasValue(districtState) ||
                    utils.hasValue(districtCountry)) && (
                    <Span>
                      {' '}
                      -{' '}
                      {utils.string.joinString(
                        [districtState, districtCountry].filter(utils.hasValue),
                        ', ',
                      )}
                    </Span>
                  )}
                </Text>
                {isCCXCertified(
                  R.pathOr(
                    'UNCERTIFIED',
                    ['organizationCertificationLevel'],
                    characterization,
                  ),
                ) ? (
                  <CCxCertifiedBadge className="ml-2" showTooltip />
                ) : null}
              </div>
            </View>
          )}
        </View>
      )}

      {!product && <View flex={1} />}

      {!hideDetails && (
        <CharacterizationsLineItemsDetails
          characterization={characterization}
          product={product}
          hideType={props.hideType}
        />
      )}

      {props.extra}
    </DetailsTopWidget>
  )
}
