import * as R from 'ramda'
import { utils, records } from '@ims/1edtech-frontend-common'

import { getRecords } from 'lib/records/workflows/getRecords'
import commonCartridgeCharacterizationReportItems, {
  COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_ITEMS_RECORD,
} from 'lib/records/modules/commonCartridgeCharacterizationReportItems'

export const getCommonCartridgeTOCRootItemId = async (
  ccId: number,
): Promise<number> => {
  const tocItemResponse = await getRecords({
    record: COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_ITEMS_RECORD,
    params: { isCartridge: true },
    force: true,
    parentRecordId: ccId,
    pageSize: 1,
  })

  const tocItemId = utils.convertToInt(
    R.pathOr(
      0,
      [0, 'id'],
      records.modules.utils.getRecordDataFromResponse(
        commonCartridgeCharacterizationReportItems,
        tocItemResponse as any,
      ),
    ),
  )
  if (!tocItemId || tocItemId < 1) {
    return 0
  }

  const rootItemResponse = await getRecords({
    record: COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_ITEMS_RECORD,
    force: true,
    parentRecordId: tocItemId,
    pageSize: 1,
  })

  const rootItemId = utils.convertToInt(
    R.pathOr(
      0,
      [0, 'id'],
      records.modules.utils.getRecordDataFromResponse(
        commonCartridgeCharacterizationReportItems,
        rootItemResponse as any,
      ),
    ),
  )

  return rootItemId
}
