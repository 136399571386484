import { AnyFormatType } from 'domains/formats/constants/formats'
import IProduct from 'domains/products/models/IProduct'
import { getProductFormatPropByName } from 'domains/products/utils/products'
import { PUBLISHED_PRODUCT_STATUS } from 'domains/products/constants/products'

export const getProductFormatStatus = (
  product: IProduct,
  format: AnyFormatType,
) => {
  const formatStatus = getProductFormatPropByName(
    format,
    'status',
    false,
  )(product)
  if (formatStatus !== false) {
    return formatStatus
  }
  return product.status
}

export const isProductFormatPublished = (
  product: IProduct,
  format: AnyFormatType,
) => {
  const formatStatus = getProductFormatStatus(product, format)
  return formatStatus === PUBLISHED_PRODUCT_STATUS
}
