import clsx from 'clsx'
import { myOrganizationLogoSelector } from 'domains/authentication/selectors/organizations'
import { TRUSTED_APP_RATING_ORG_LOGO_IDENTIFIER } from 'domains/trustedApps/constants/trustedAppsCustomRating'
import { TrustedAppRating } from 'domains/trustedApps/models/ITrustedApplication'
import React from 'react'
import { useSelector } from 'react-redux'

interface TrustedAppsRatingChipProps {
  rating: TrustedAppRating
}

export function TrustedAppsRatingChip(props: TrustedAppsRatingChipProps) {
  const logo = useSelector((s: any) => myOrganizationLogoSelector(s, {}))

  return (
    <div className="flex items-center gap-2">
      {props.rating.iconIdentifier ===
      TRUSTED_APP_RATING_ORG_LOGO_IDENTIFIER ? (
        <img width={20} height={20} src={logo} alt="Organization Logo" />
      ) : (
        <i
          className={clsx(`fas text-xl`, props.rating.iconIdentifier)}
          style={{ color: props.rating.iconColor }}
        />
      )}

      <span>{props.rating.ratingText}</span>
    </div>
  )
}
