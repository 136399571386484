import React from 'react'
import * as R from 'ramda'

import { View, Text } from 'lib/components'
import { utils } from '@ims/1edtech-frontend-common'
import { useIsMissingMetadataPathClassname } from 'domains/commonCartridge/hooks/useIsMissingMetadataPath'

interface IProps {
  path: string
  title: string
  rightTitle?: string
  value?: any
}

export default function CCCharacterizationReportLOMDetail(props: IProps) {
  const missingClass = useIsMissingMetadataPathClassname(props.path)
  if (!utils.hasValue(props.value)) {
    return null
  }

  return (
    <View
      borderRadius={4}
      mt={3}
      data-test={props.path}
      className={missingClass}
    >
      <View
        p={3}
        bg="ccReportLOMTitle"
        borderRadius={'4px 4px 0 0'}
        flexible="row-space-between"
      >
        <Text color="#000" fontWeight={700} data-test={`${props.path}.title`}>
          {props.title}
        </Text>
        {utils.hasValue(props.rightTitle) && (
          <Text color="#000" fontWeight={700}>
            {props.rightTitle}
          </Text>
        )}
      </View>

      <View p={3} bg="ccReportLOMDetail" borderRadius={'0 0 4px 4px'}>
        {R.is(String, props.value) && <Text>{props.value}</Text>}
        {!R.is(String, props.value) && props.value}
      </View>
    </View>
  )
}
