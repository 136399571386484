import React from 'react'
import * as R from 'ramda'

import useRecord from 'lib/records/hooks/useRecord'
import { IMS_CERTIFICATIONS_RECORD } from 'lib/records/modules/imsCertifications'
import IIMSCertification, {
  groupCerts,
} from 'domains/trustedApps/models/IIMSCertification'
import {
  IIMSApplicationFilter,
  IIMSApplicationParentFilter,
} from 'domains/trustedApps/components/AddTrustedApps/IMSApplicationsFilter/IIMSApplicationsFilter'
import IMSApplicationsFilterGroup from 'domains/trustedApps/components/AddTrustedApps/IMSApplicationsFilter/IMSApplicationsFilterGroup'

interface IProps {
  filters: string[]
  onFilterChecked: (
    checked: IIMSApplicationFilter | IIMSApplicationFilter[],
  ) => any
}

export default function IMSApplicationsCertificationsFilter(props: IProps) {
  const [certificationsRecord, pending] = useRecord(
    {
      record: IMS_CERTIFICATIONS_RECORD,
      params: { limit: 250 },
    },
    true,
  )

  const certifications = R.pathOr(
    [],
    ['items'],
    certificationsRecord,
  ) as IIMSCertification[]
  const groupedCertifications = groupCerts(certifications).map((parent) => {
    const children = parent.children.map((cert) => ({
      label: cert.imsCertTitle,
      value: cert.imsCertType,
      filterKey: 'ims_cert_type',
    }))
    children.sort((c1, c2) => (c1.label < c2.label ? -1 : 1))
    return {
      ...parent,
      children,
    }
  }) as IIMSApplicationParentFilter[]
  groupedCertifications.sort((c1, c2) => (c1.parent < c2.parent ? -1 : 1))

  return (
    <IMSApplicationsFilterGroup
      title="1EdTech Standards"
      appliedFilters={props.filters}
      groupedFilters={groupedCertifications}
      onFilterChecked={props.onFilterChecked}
      pending={pending}
    />
  )
}
