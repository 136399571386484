import React from 'react'
import copy from 'copy-to-clipboard'

import { utils } from '@ims/1edtech-frontend-common'
import { showToast } from 'lib/utils/toast'
import ToolTip from 'lib/components/ToolTip'

type CopyableUrlsProps = {
  label: string

  value: string
  allowCopy?: boolean
  optional?: boolean
  'data-test'?: string
}

export default function CopyableUrls(props: CopyableUrlsProps) {
  const unknown = !utils.hasValue(props.value)
  const value = unknown ? 'N/A' : props.value

  const copyText = () => {
    copy(value)
    showToast('info', `Copied ${props.label} to clipboard`)
  }

  const dataTest = props['data-test'] || `copy-url-${props.label}`
  return (
    <div>
      <p
        className="font-bold text-text text-sm"
        data-test={`${dataTest}-label`}
      >
        {props.label}
        {props.optional ? <span className="italic"> (optional)</span> : null}
      </p>
      <div className="flex flex-row items-center justify-between">
        <p
          className="text-textLight text-sm italic"
          style={{ wordBreak: 'break-word' }}
          data-test={`${dataTest}-value`}
        >
          {value}
        </p>
        {props.allowCopy && (
          <ToolTip
            delay={100}
            tip="Copy to clipboard"
            id={`${props.label}-copy`}
          >
            <i
              className="ml-2 text-base text-text fas fa-clipboard cursor-pointer hover:text-complete"
              onClick={copyText}
            />
          </ToolTip>
        )}
      </div>
    </div>
  )
}
