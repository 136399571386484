import React from 'react'
import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'

import { View, Spinner, Text } from 'lib/components'
import useFullRESTPCharacterization from 'domains/characterizations/hooks/useFullRESTPCharacterization'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import BaseORRosteringCharacterizationsBreadCrumbs from 'domains/characterizations/components/BaseORRosteringCharacterizationsBreadCrumbs'
import ReportThreeTopSection from 'domains/reports/components/ReportThreeTopSection'
import CharacterizationsDetailsWidget from 'domains/characterizations/components/DistrictCharacterizationDetails/CharacterizationsDetailsWidget'
import RESTCharacterizationReportOverviewWidget from 'domains/characterizations/components/CharacterizationReport/RESTCharacterizationReportOverviewWidget'
import CharacterizationDetailsActionsWidget from 'domains/characterizations/components/CharacterizationReport/CharacterizationDetailsActionsWidget'
import useORRESTCharacterizationAccordionHeaderStates from 'domains/reports/hooks/useORRESTCharacterizationAccordionHeaderStates'
import ITransformedRESTCharacterizationReport from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import ReportLinkBox from 'domains/reports/components/ReportLinkBox'
import RESTPCharacterizationReportServices from 'domains/characterizations/components/CharacterizationReport/RESTPCharacterizationReportServices'
import GenericError from 'domains/application/components/GenericError'
import DebugReportView from 'domains/reports/components/DebugReportView'
import CharacterizationNotesPaper from 'domains/characterizations/components/CharacterizationReport/CharacterizationNotesPaper'
import ReportTopLevelError from 'domains/reports/components/ReportTopLevelError'
import { ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL } from 'domains/formats/constants/formats'

const TOP_WIDGETS_HEIGHT = 486

export default function OR1P1RestProviderCharacterizationReportScreen(
  props: any,
) {
  const [
    characterizationData,
    specification,
    reportData,
    transformedReportData,
    pending,
  ] = useFullRESTPCharacterization(props)

  const [
    headerStates,
    getHeaderState,
    onToggleHeader,
    onExpandAll,
    onCollapseAll,
  ] = useORRESTCharacterizationAccordionHeaderStates(
    transformedReportData,
    true,
  )

  const [allExpanded, setAllExpanded] = React.useState(false)
  const onExpandAllServices = () => {
    setAllExpanded(true)
    setAllCollapsed(false)
    onExpandAll()
  }

  const [allCollapsed, setAllCollapsed] = React.useState(true)
  const onCollapseAllServices = () => {
    setAllExpanded(false)
    setAllCollapsed(true)
    onCollapseAll()
  }

  if (
    pending ||
    !utils.hasValue(characterizationData) ||
    !utils.hasValue(reportData)
  ) {
    if (
      !pending &&
      (!utils.hasValue(characterizationData) || !utils.hasValue(reportData))
    ) {
      return <GenericError />
    }
    return (
      <View variant="screen">
        <Spinner centered={true} />
      </View>
    )
  }

  const transformedReport = transformedReportData as ITransformedRESTCharacterizationReport
  const characterization = characterizationData as ICharacterization

  const characterizationName = R.pathOr('', ['name'], characterization)
  const isGBPush =
    characterization.format === ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL

  return (
    <View variant="screen">
      <DocumentTitle title={`Characterization: ${characterizationName}`} />
      <BaseORRosteringCharacterizationsBreadCrumbs
        crumbs={[{ name: utils.string.ellipsize(characterizationName, 30) }]}
      />

      <View flexible="column">
        <ReportThreeTopSection>
          <CharacterizationsDetailsWidget
            characterization={characterization}
            hideType={true}
            height={TOP_WIDGETS_HEIGHT}
          />
          <RESTCharacterizationReportOverviewWidget
            report={transformedReport}
            height={TOP_WIDGETS_HEIGHT}
            isProvider={true}
          />
          <CharacterizationDetailsActionsWidget
            characterization={characterization}
            report={transformedReport}
            expandAll={onExpandAllServices}
            collapseAll={onCollapseAllServices}
            format={characterization.format}
            height={TOP_WIDGETS_HEIGHT}
          />
        </ReportThreeTopSection>

        <CharacterizationNotesPaper characterization={characterization} />

        <View variant="paper">
          <WidgetHeader title="Characterization Report" />

          {!pending && (
            <View width="100%" flexible="column">
              <View
                display="grid"
                gridTemplateColumns={['auto', 'auto', '1fr 1fr 1fr']}
                gridTemplateRows={['1fr 1fr 1fr', '1fr 1fr 1fr', 'auto']}
                gridColumnGap={2}
                gridRowGap={2}
                width="100%"
                my={3}
              >
                <ReportLinkBox
                  name="Host Name"
                  url={characterization.hostname || ''}
                />
                <ReportLinkBox
                  name="Source"
                  url={characterization.source || ''}
                />
                <ReportLinkBox
                  name="Security"
                  url={characterization.securityType || ''}
                />
              </View>

              {transformedReport.hasErrors && !isGBPush && (
                <Text fontStyle="italic" mt={2}>
                  A maximum of 10 critical errors will be shown per endpoint.
                  Please resolve and re-characterize to see any additional
                  errors.
                </Text>
              )}

              {utils.hasValue(transformedReport.criticalError) && (
                <View my={4}>
                  <ReportTopLevelError
                    status="RED"
                    title="Critical Error"
                    errors={[transformedReport.criticalError!]}
                    dataTest="critical-error"
                  />
                </View>
              )}

              <RESTPCharacterizationReportServices
                format={characterization.format}
                report={transformedReport}
                specification={specification}
                headerStates={headerStates}
                getHeaderState={getHeaderState}
                onToggleHeader={onToggleHeader}
                allExpanded={allExpanded}
                allCollapsed={allCollapsed}
                setAllExpandedState={setAllExpanded}
                setAllCollapsedState={setAllCollapsed}
              />
            </View>
          )}
        </View>
      </View>

      <DebugReportView rawReport={reportData} title="Charsys Report JSON" />
      <DebugReportView
        rawReport={transformedReport}
        title="Transformed Report JSON"
      />
    </View>
  )
}
