import React from 'react'
import styled from 'styled-components'
import * as SS from 'styled-system'

import colors from 'lib/styles/colors'
import { Text, View } from 'lib/components'
import { utils } from '@ims/1edtech-frontend-common'

interface IFlagProps {
  consumer: boolean
}
const Flag = styled.div<IFlagProps>(
  (props) => `
  position: absolute;
  right: 0;
  bottom: 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
  }

  &::after {
    border-radius: 4px;
    border-width: 32px;
    border-right-color: ${props.consumer ? colors.primary : colors.info};
    border-bottom-color: ${props.consumer ? colors.primary : colors.info};
  }

  ${SS.borderColor};
  ${SS.borderRadius};
  ${SS.borders};
`,
)

interface IProps {
  parentName: string
  name: string
  mdSource?: string
}

export default function MetadataFlag(props: IProps) {
  const renderText = (
    value: string,
    fontSize: number,
    paddingRight: string,
    bold = false,
  ) => (
    <Text
      textAlign="right"
      color="white"
      fontSize={fontSize}
      position="relative"
      pr={paddingRight}
      fontWeight={bold ? 700 : 500}
    >
      {value}
    </Text>
  )

  if (!utils.hasValue(props.mdSource)) {
    return null
  }

  const isConsumer = props.mdSource === 'Consumer'
  return (
    <View
      position="absolute"
      right={0}
      bottom={0}
      data-test={`${props.parentName}-${props.name}-flag`}
    >
      <Flag consumer={isConsumer} />
      {renderText(isConsumer ? 'C' : 'P', 20, '8px', true)}
      {renderText(isConsumer ? 'consumer' : 'provider', 10, '4px')}
    </View>
  )
}
