import React, { PureComponent } from 'react'
import * as R from 'ramda'

import { Text, View, List } from 'lib/components'
import BaseProductsBreadCrumbs from 'domains/products/components/BaseProductsBreadCrumbs'
import withFullProduct, {
  IWithFullProductProps,
} from 'domains/products/components/withFullProduct'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import ProductImageCell from 'domains/products/components/ProductImageCell'
import { displaySpecVersion } from 'domains/products/utils/products'
import trackProductAnalytics from 'domains/products/utils/trackProductAnalytics'
import ComparisonModal from 'domains/comparisons/modals/ComparisonModal'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import { IListColumnDefinition } from 'lib/components/List'
import { filterProductFormatsBySupported } from 'domains/products/utils/filterProductFormatsBySupported'
import {
  FORMAT_TO_DISPLAY_FORMAT_BASE_NAME,
  AnySpecFormatType,
  OR_FORMAT_TO_SPEC_SUBTYPE,
} from 'domains/formats/constants/formats'
import DistrictProductFormatCompareCell from 'domains/products/components/ProductDetail/DistrictProductFormatCompareCell'
import GenericError from 'domains/application/components/GenericError'
import memoize from 'fast-memoize'
import IProductFormat from 'domains/products/models/IProductFormat'
import { isProductFormatPublished } from 'domains/products/utils/productFormatStatus'
import { utils } from '@ims/1edtech-frontend-common'
import ProductDetailPPWidget from 'domains/products/components/ProductDetailPPWidget'
import CCxCertifiedBadge from 'lib/components/CCxCertifiedBadge'
import { isCCXCertified } from 'domains/orgs/models/IOrg'

interface IState {
  compareModalOpen: boolean
  compareFormat?: AnySpecFormatType
}

export class DistrictProductDetailScreen extends PureComponent<
  IWithFullProductProps,
  IState
> {
  state = {
    compareModalOpen: false,
    compareFormat: undefined,
  }

  componentDidMount() {
    trackProductAnalytics('viewed_product_details')
  }

  onLaunchComparison = (compareFormat: AnySpecFormatType) =>
    this.setState({ compareModalOpen: true, compareFormat })

  onCloseComparison = () =>
    this.setState({ compareModalOpen: false, compareFormat: undefined })

  formatColumns: IListColumnDefinition[] = [
    {
      title: 'Mode',
      maxWidth: 72,
      noSort: true,
      noHeaderCenter: true,
      noCenter: true,
      accessor: (product) =>
        OR_FORMAT_TO_SPEC_SUBTYPE[
          R.propOr('', 'format', product) as AnySpecFormatType
        ],
    },
    {
      title: 'Version',
      forceCenter: true,
      maxWidth: 48,
      noSort: true,
      accessor: (format) =>
        displaySpecVersion(R.prop('specificationVersion', format)),
    },
    {
      title: 'Format',
      maxWidth: 70,
      noSort: true,
      noHeaderCenter: true,
      noCenter: true,
      accessor: (format) =>
        FORMAT_TO_DISPLAY_FORMAT_BASE_NAME[
          R.prop('format', format) as AnySpecFormatType
        ],
    },
    {
      title: null,
      maxWidth: 100,
      noSort: true,
      CellComponent: DistrictProductFormatCompareCell,
      cellProps: { onClick: this.onLaunchComparison },
    },
  ]

  getProducts = memoize((productFormats: IProductFormat[]) =>
    filterProductFormatsBySupported(
      productFormats.filter((f) =>
        isProductFormatPublished(this.props.product, f.format),
      ),
    ),
  )

  render() {
    const { product } = this.props
    if (!product) {
      return <GenericError />
    }

    const hasPreferredPartners = utils.hasValue(product.preferrers)

    return (
      <View variant="screen">
        <DocumentTitle title={this.props.product.name} />
        <BaseProductsBreadCrumbs crumbs={[{ name: product.name }]} />

        <View
          display="grid"
          gridTemplateColumns={[
            'auto',
            'auto',
            hasPreferredPartners ? '2fr 1fr' : 'auto',
          ]}
          gridColumnGap={3}
          width="100%"
        >
          <View variant="paper">
            <WidgetHeader title="Product Details" />

            <View mt={3}>
              <ProductImageCell {...product} size={110} />
              <Text fontWeight={700} mt={3} data-test="product-details-name">
                <a href={product.url} target="_blank" rel="noopener noreferrer">
                  {product.name}
                </a>
              </Text>

              <div className="flex flex-row items-center">
                <Text mt={1} data-test="product-org-name">
                  {product.organizationName}
                </Text>
                {isCCXCertified(product.organizationCertificationLevel) && (
                  <CCxCertifiedBadge className="ml-2" showTooltip />
                )}
              </div>

              <Text fontWeight={700} mt={3}>
                Description
              </Text>
              <Text data-test="product-details-description">
                {product.description || 'N/A'}
              </Text>
            </View>
          </View>

          {hasPreferredPartners && <ProductDetailPPWidget product={product} />}
        </View>

        <View mt={3} />
        <List
          dataTest="product-formats-list"
          title="Format Details for this Product"
          noSearch={true}
          columns={this.formatColumns}
          nonRecordItems={this.getProducts(product.formats)}
          noSort={true}
        />

        <ComparisonModal
          isOpen={this.state.compareModalOpen}
          id={product.id}
          format={this.state.compareFormat}
          closeModal={this.onCloseComparison}
          startWithProduct={true}
        />
      </View>
    )
  }
}

export default withFullProduct(DistrictProductDetailScreen)
