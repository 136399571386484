import React, { useEffect } from 'react'
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'

import * as routes from 'domains/application/navigation/routes'
import {
  isSupplierRoleSelector,
  isIMSAdminRoleSelector,
} from 'domains/authentication/selectors/roles'
import PrintCharacterizationReportRouterScreen from 'domains/characterizations/screens/PrintCharacterizationReportRouterScreen'

const districtRoutes = [
  <Route
    key="PRINT-ORCharacterizationReport"
    exact={true}
    path={`${routes.PRINT_ROOT_ROUTE}${routes.DISTRICT_OR_CHARACTERIZATIONS_ROUTE}/:format/:id`}
    component={PrintCharacterizationReportRouterScreen}
  />,
]

const supplierRoutes = [
  <Route
    key="ORCharacterizationReports"
    exact={true}
    path={`${routes.PRINT_ROOT_ROUTE}${routes.SUPPLIER_OR_CHARACTERIZATIONS_ROUTE}/:format/:id`}
    component={PrintCharacterizationReportRouterScreen}
  />,
]

export const PrintRouter = (props: RouteComponentProps) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [props.location])

  const isSupplier = useSelector(isSupplierRoleSelector)
  const isIMSAdmin = useSelector(isIMSAdminRoleSelector)

  const renderDistrictRoutes = () => {
    if (isSupplier || isIMSAdmin) {
      return null
    }

    return [
      ...districtRoutes,
      <Redirect key="redirect" to={`${routes.DISTRICT_DASHBOARD_ROUTE}`} />,
    ]
  }

  const renderSupplierRoutes = () => {
    if (!isSupplier || isIMSAdmin) {
      return null
    }

    return [
      ...supplierRoutes,
      <Redirect key="redirect" to={`${routes.SUPPLIER_DASHBOARD_ROUTE}`} />,
    ]
  }

  return (
    <Switch>
      {renderDistrictRoutes()}
      {renderSupplierRoutes()}
    </Switch>
  )
}

export default PrintRouter
