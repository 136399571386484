import * as R from 'ramda'
import { createSelector } from 'reselect'
import { records } from '@ims/1edtech-frontend-common'

import { USERS_RECORD } from 'lib/records/modules/users'
import { RootState } from 'lib/store/rootReducer'
import IUser from 'domains/users/models/IUser'

const applicationMeIdSelector = R.pathOr(0, ['application', 'me'])

export const meSelector = createSelector<RootState, number, any, IUser | null>(
  applicationMeIdSelector,
  records.entitiesSelectors.entitiesSelector,
  (meId, entities) => R.pathOr(null, [USERS_RECORD, meId], entities),
)
