import React, { useState, useEffect } from 'react'

import { ModalWrapper, WYSIWYGEditor } from 'lib/components'
import FormInput from 'lib/components/FormInput'
import View from 'lib/components/View'

interface IProps {
  isOpen: boolean
  close: () => any
  onSave: (notes: string) => any
  title: any
  notes: string
  name?: string
  valueDataTest?: string
  richText?: boolean
}

export default function NoteEditorModal(props: IProps) {
  const [notes, setNotes] = useState(props.notes)

  useEffect(() => {
    setNotes(props.notes)
  }, [props.isOpen]) // eslint-disable-line

  const onChange = (e: React.FormEvent<HTMLInputElement>) =>
    setNotes(e.currentTarget.value)
  const onRichTextChange = (value: string) => setNotes(value)

  const onSave = () => {
    props.onSave(notes)
    props.close()
  }

  const editor = props.richText ? (
    <View mb={3}>
      <WYSIWYGEditor
        name={props.name}
        value={notes}
        onChange={onRichTextChange}
        autoFocus={true}
        placeholder="Notes"
      />
    </View>
  ) : (
    <FormInput
      name="note"
      value={notes}
      placeholder="Notes"
      handleChange={onChange}
      valueDataTest={props.valueDataTest || 'note-editor'}
      textArea={true}
      inputProps={{ width: '100%', rows: 6 }}
    />
  )

  return (
    <ModalWrapper
      isOpen={props.isOpen}
      title={props.title}
      actions={[
        {
          text: 'Save',
          variant: 'start',
          onClick: onSave,
          extra: {
            type: 'submit',
          },
        },
        {
          text: 'Cancel',
          variant: 'neutral',
          onClick: props.close,
          extra: {
            type: 'button',
          },
        },
      ]}
    >
      {editor}
    </ModalWrapper>
  )
}
