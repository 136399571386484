import React from 'react'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import * as Yup from 'yup'

import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import { Button, FormInput, InputLabel, Switch } from 'lib/components'
import CopyableUrls from 'lib/components/CopyableUrls'
import ITrustedAppsDetails from 'domains/trustedApps/models/ITrustedAppsDetails'
import { updateRecord } from 'lib/records/workflows/updateRecord'
import { TRUSTED_APPS_DETAILS_RECORD } from 'lib/records/modules/trustedAppsDetails'
import { showToast } from 'lib/utils/toast'
import { WYSIWYGEditor } from 'lib/components'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import { useSelector } from 'react-redux'

interface IFormValues {
    suggestedAppRecipientEmail: string
    welcomeText: string
    teacherShowTAView: boolean
    taHideAppDescription: boolean
}

const validationSchema = Yup.object().shape({
    suggestedAppRecipientEmail: Yup.string().email('Invalid email address'),
    welcomeText: Yup.string(),
    teacherShowTAView: Yup.bool(),
    taHideAppDescription: Yup.bool()
})

interface IProps {
    height: number
    details: ITrustedAppsDetails
}

export default function TrustedAppsAdditionalInfoWidget(props: IProps) {
    const onSave = async (
        values: IFormValues,
        bag: FormikHelpers<IFormValues>,
    ) => {
        const { success } = await updateRecord(TRUSTED_APPS_DETAILS_RECORD, 1, {
            ...props.details,
            ...values,
        })
        bag.setSubmitting(false)

        if (success) {
            showToast('start', 'Successfully updated additional information')
        } else {
            showToast('error', 'Failed to update additional information')
        }
    }

    const orgId = useSelector((s: any) => myOrganizationIdSelector(s, {}))
    const rootUrl = window.location.origin

    return (
        <DetailsTopWidget
            title="Additional Information"
            dataTest="trustedapps-additional-info-widget"
            height={props.height}
        >
            <div className="bg-gray-100 p-4 rounded-md">
                <CopyableUrls
                    label="Approved Apps Embeddable Dashboard"
                    value={`${rootUrl}/embeddable/trustedApps/${orgId}`}
                    allowCopy
                />
            </div>
            <Formik
                initialValues={{
                    suggestedAppRecipientEmail:
                        props.details.suggestedAppRecipientEmail,
                    welcomeText: props.details.welcomeText,
                    teacherShowTAView: props.details.teacherShowTAView,
                    taHideAppDescription: props.details.taHideAppDescription,
                }}
                validationSchema={validationSchema}
                onSubmit={onSave}
            >
                {(bag: FormikProps<IFormValues>) => {
                    const onChangeText = (value: string) =>
                        bag.setFieldValue('welcomeText', value)

                    const onToggleShow = (field:string) => {
                        bag.setFieldValue(
                            field,
                            !bag.values[field as keyof IFormValues],
                        )
                    }

                    return (
                        <form
                            onSubmit={bag.handleSubmit}
                            className="flex flex-1 flex-col pt-2"
                        >
                            <div className="flex flex-1 flex-col">
                                <FormInput
                                    label="App Suggestion Contact Email Address"
                                    name="suggestedAppRecipientEmail"
                                    handleChange={bag.handleChange}
                                    value={
                                        bag.values.suggestedAppRecipientEmail
                                    }
                                    valueDataTest="suggestion-email"
                                    placeholder="suggestapp@yourtrustedapps.net"
                                    aria-label="Suggest Trusted App Email"
                                />

                                <InputLabel>Welcome Notes</InputLabel>
                                <WYSIWYGEditor
                                    value={bag.values.welcomeText}
                                    onChange={onChangeText}
                                />
                            </div>
                            <div>
                                <InputLabel>
                                    TrustEd Apps Directory Visible for
                                    Instructors?
                                </InputLabel>
                                <Switch
                                    onChange={() => onToggleShow('teacherShowTAView')}
                                    on={bag.values.teacherShowTAView}
                                />
                            </div>
                            <div>
                                <InputLabel>
                                    Hide vendor descriptions for applications?
                                </InputLabel>
                                <Switch
                                    onChange={() => onToggleShow('taHideAppDescription')}
                                    on={bag.values.taHideAppDescription}
                                />
                            </div>

                            <div className="flex flex-row justify-end mt-3">
                                <Button
                                    variant="complete"
                                    onClick={bag.handleSubmit}
                                    pending={bag.isSubmitting}
                                    data-test="save-additional-info-btn"
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        </DetailsTopWidget>
    )
}
