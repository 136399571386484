import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

export const LTI_PLATFORMS_RECORD = 'ltiPlatforms'

const ltiPlatforms: records.modules.IModule = {
  record: LTI_PLATFORMS_RECORD,
  schema: new normalizr.schema.Entity(
    LTI_PLATFORMS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(),
  ),
  api: {
    create: () => 'lti/platforms',
    get: (id) => `lti/platforms/${id}`,
    getList: () => 'lti/platforms',
    update: (id) => `lti/platforms/${id}`,
    delete: (id) => `lti/platforms/${id}`,
  },
}

export default ltiPlatforms
