import React from 'react'
import clsx from 'clsx'

import { utils } from '@ims/1edtech-frontend-common'
import getOrgLogo from 'domains/orgs/utils/getOrgLogo'

type TrustedAppLogoProps = {
  title: string
  logo?: string
}

export default function TrustedAppLogo(props: TrustedAppLogoProps) {
  const [hasError, setError] = React.useState(false)
  const onError = () => setError(true)

  return (
    <div
      className={clsx('flex flex-col items-center justify-center w-12 h-12', {
        border: !utils.hasValue(props.logo),
      })}
    >
      <img
        className={clsx('w-auto max-w-12 h-auto max-h-12', {
          'lg:block': utils.hasValue(props.logo),
          hidden: hasError || !utils.hasValue(props.logo),
        })}
        src={getOrgLogo(`${props.logo}`)}
        alt={`${props.title} logo`}
        onError={onError}
      />
      <p
        className={clsx('text-xl center text-textLight font-bold', {
          hidden: !hasError || utils.hasValue(props.logo),
        })}
      >
        {`${props.title}`.trim().split('')[0].toUpperCase()}
      </p>
    </div>
  )
}
