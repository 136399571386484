import React from 'react'
import clsx from 'clsx'
import * as R from 'ramda'

import { Linkify } from 'lib/components'
import colors from 'lib/styles/colors'
import ITransformedRESTCharacterizationReport, {
  ITransformedRESTServiceCharacterizationReport,
  ITransformedRESTServiceEndpointCharacterizationReport,
  ITransformedRESTServiceEndpointWarning,
} from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import IRestCModel from 'domains/modeling/models/IRestCModel'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import { SERVICE_PARENT_ENDPOINT_MAP } from 'domains/modeling/constants/restCOneRoster1p1'
import {
  findModelServiceEndointByName,
  isWholeServiceDisabled,
  parentServiceEndpointHasNotes,
  sortEndpointsForDisplay,
} from 'domains/modeling/utils/restCOR1p1utils'
import { utils } from '@ims/1edtech-frontend-common'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import RESTOperationInformationHeader from 'domains/characterizations/components/CharacterizationReport/RESTOperationInformationHeader'
import RESTEndpointOperationInformation from 'domains/characterizations/components/CharacterizationReport/RESTEndpointOperationInformation'
import RESTEndpointCharacterizationDataElementsList from 'domains/characterizations/components/CharacterizationReport/RESTEndpointCharacterizationDataElementsList'
import RESTCharacterizationParamError from 'domains/characterizations/components/CharacterizationReport/RESTCharacterizationParamError'
import {
  AMBER_REPORT_STATUS,
  RED_REPORT_STATUS,
  STATUS_TO_BADGE_VARIANT_MAP,
  GRAY_REPORT_STATUS,
} from 'domains/reports/models/IReportStatuses'
import RESTPCharacterizationEndpointParameterList from 'domains/characterizations/components/CharacterizationReport/RESTPCharacterizationEndpointParameterList'
import RESTEndpointAttributeErrors from 'domains/characterizations/components/CharacterizationReport/RESTEndpointAttributeErrors'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'

interface IProps {
  isOpen: boolean
  report: ITransformedRESTCharacterizationReport
  service: ITransformedRESTServiceCharacterizationReport
  specification: IRestCSpec
  model?: IRestCModel
  endpoint?: ITransformedRESTServiceEndpointCharacterizationReport
}

export default function RESTServiceCharacterizationReport(props: IProps) {
  const isPrint = isOnPrintScreen()
  const parentEndpointForService =
    SERVICE_PARENT_ENDPOINT_MAP[props.service.serviceName]

  const allDisabled = isWholeServiceDisabled(props.service)
  const hasNotes = parentServiceEndpointHasNotes(props.service)
  const onlyShowNotes = allDisabled && hasNotes

  const modelSortedEndpoints = props.endpoint
    ? [props.endpoint]
    : (sortEndpointsForDisplay(
        props.service.serviceName,
        props.service.endpointCharacterizations,
      ) as ITransformedRESTServiceEndpointCharacterizationReport[])
  const paramEndpoints = modelSortedEndpoints.filter(
    (endpoint) =>
      utils.hasValue(endpoint.errors) ||
      utils.hasValue(endpoint.paramCharacterizations),
  )

  const renderNotes = () => {
    if (!props.model) {
      return null
    }

    const parentEndpoint = findModelServiceEndointByName(
      props.model,
      props.service.serviceName,
      parentEndpointForService,
      true,
    )
    if (!utils.hasValue(parentEndpoint.notes)) {
      return null
    }

    return (
      <div className={clsx({ 'mb-3': onlyShowNotes, 'mb-4': !onlyShowNotes })}>
        <WidgetHeader
          title="Endpoint Notes:"
          dataTest={`service-notes-header-${props.service.serviceName}`}
        />
        <h2
          className="mt-2 ml-3"
          data-test={`service-notes-${props.service.serviceName}`}
        >
          <Linkify>{parentEndpoint.notes}</Linkify>
        </h2>
      </div>
    )
  }

  const errors = R.pathOr([], ['endpoint', 'errors'], props)
  const warnings = R.pathOr(
    [],
    ['endpoint', 'warnings'],
    props,
  ) as ITransformedRESTServiceEndpointWarning[]

  return (
    <div
      className={clsx('bg-white flex flex-col flex-1', {
        'px-3': !!props.endpoint && !isPrint,
        'pb-3': !isPrint,
      })}
      style={{
        border:
          !isPrint && props.endpoint
            ? `4px solid ${
                (colors as any)[
                  STATUS_TO_BADGE_VARIANT_MAP[modelSortedEndpoints[0].status]
                ]
              }`
            : 'none',
      }}
    >
      <div className="px-3">
        {renderNotes()}
        {!onlyShowNotes &&
          utils.hasValue(paramEndpoints) &&
          !props.endpoint && <WidgetHeader title="Operation Information" />}
      </div>

      {!onlyShowNotes && (
        <div>
          <div className="flex flex-col items-center justify-center w-full">
            <div className="w-full">
              {!props.endpoint && utils.hasValue(paramEndpoints) && (
                <>
                  <RESTOperationInformationHeader />
                  {paramEndpoints.map((endpoint, index) => (
                    <RESTEndpointOperationInformation
                      key={endpoint.endpointName}
                      serviceName={props.service.serviceName}
                      endpoint={endpoint}
                      index={index}
                    />
                  ))}
                </>
              )}

              {(utils.hasValue(warnings) || utils.hasValue(errors)) && (
                <div className="mt-2 mb-3">
                  {errors.map((error, index) => (
                    <RESTCharacterizationParamError
                      key={`${props.service.serviceName}-${
                        props.endpoint!.endpointName
                      }-param-error-${index}`}
                      status={RED_REPORT_STATUS}
                      text={error}
                    />
                  ))}
                  {warnings.map((warning, index) => (
                    <RESTCharacterizationParamError
                      key={`${props.service.serviceName}-${
                        props.endpoint!.endpointName
                      }-param-warning-${index}`}
                      status={
                        R.pathOr(
                          false,
                          ['endpoint', 'isOptionalAndUnsupported'],
                          props,
                        )
                          ? GRAY_REPORT_STATUS
                          : AMBER_REPORT_STATUS
                      }
                      text={warning.description}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>

          {props.endpoint && !props.endpoint.isOptionalAndUnsupported && (
            <RESTPCharacterizationEndpointParameterList
              endpoint={props.endpoint}
            />
          )}

          <RESTEndpointAttributeErrors
            isOpen={props.isOpen}
            endpoint={props.endpoint}
            service={props.service}
            specification={props.specification}
          />

          <RESTEndpointCharacterizationDataElementsList
            endpoint={modelSortedEndpoints[0]}
            service={props.service}
            specification={props.specification}
            model={props.model}
            noHeader={!!props.endpoint || isPrint}
          />
        </div>
      )}
    </div>
  )
}
