import { updateRecord } from 'lib/records/workflows/updateRecord'
import { ORGANIZATIONS_RECORD } from 'lib/records/modules/organizations'
import { replaceEntityProperty } from 'lib/store/entities.actions'
import store from 'lib/store'

export const updateOrg = async (id: number, updates: any) => {
  const response = await updateRecord(ORGANIZATIONS_RECORD, id, updates)
  if (updates.lockoutDate === null) {
    store.dispatch(
      replaceEntityProperty([ORGANIZATIONS_RECORD, id, 'lockoutDate'], null),
    )
  }
  if (updates.referrerId === null) {
    store.dispatch(
      replaceEntityProperty([ORGANIZATIONS_RECORD, id, 'referrerId'], null),
    )
    store.dispatch(
      replaceEntityProperty([ORGANIZATIONS_RECORD, id, 'referrerName'], null),
    )
  }
  return response
}
