import React from 'react'

import { View } from 'lib/components'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import IProduct from 'domains/products/models/IProduct'
import PreferredPartnerBadge from 'domains/products/components/PreferredPartnerBadge'
import ProductPPList from 'domains/products/components/ProductPPList'

interface IProps {
  product: IProduct
}

export default function ProductDetailPPWidget(props: IProps) {
  return (
    <View variant="paper">
      <WidgetHeader
        title={
          <View flexible="row-v-center">
            <PreferredPartnerBadge />
            <View ml={2} />
            Available via Preferred Partner(s)
          </View>
        }
        dataTest="product-pp-widget"
      />

      <View mt={3} overflowY="auto" maxHeight={400}>
        <ProductPPList product={props.product} />
      </View>
    </View>
  )
}
