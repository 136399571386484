import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

export const PRODUCT_COMPARISONS_RECORD = 'productComparisons'

const productComparisons: records.modules.IModule = {
  record: PRODUCT_COMPARISONS_RECORD,
  responseDataKey: ['comparison', 'comparisons'],
  schema: new normalizr.schema.Entity(
    PRODUCT_COMPARISONS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(),
  ),
  api: {
    create: () => '',
    get: () => '',
    getList: (_, productId) => `products/${productId}/comparisons`,
    update: (id) => `comparisons/${id}/notes`,
    delete: () => '',
  },
}

export default productComparisons
