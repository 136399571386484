import React from 'react'

import { View, Text, Icon } from 'lib/components'
import {
  ReportStatus,
  RED_REPORT_STATUS,
  AMBER_REPORT_STATUS,
} from 'domains/reports/models/IReportStatuses'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  status: ReportStatus
  title: string
  description?: string
  errors?: string[]
  dataTest?: string
}

const STATUS_TO_COLOR_MAP: { [key: string]: string } = {
  [RED_REPORT_STATUS]: 'error',
  [AMBER_REPORT_STATUS]: 'warningAlt',
}
const STATUS_TO_ICON_MAP: { [key: string]: string } = {
  [RED_REPORT_STATUS]: 'fas fa-exclamation-circle',
  [AMBER_REPORT_STATUS]: 'fas fa-check-circle',
}
export default function PrintReportTopLevelError(props: IProps) {
  const { status, title, description, dataTest } = props
  return (
    <View data-test={dataTest}>
      <View ml={3}>
        <Text mb={1} fontWeight={700} fontSize={12}>
          {title}
        </Text>

        <View flexible="row">
          <Icon
            color={STATUS_TO_COLOR_MAP[status]}
            className={STATUS_TO_ICON_MAP[status]}
            fontSize={16}
          />
          {!!props.errors && (
            <View ml={1}>
              {props.errors.map((error, index) => (
                <Text
                  key={`error-${index}`}
                  mb={2}
                  fontStyle="italic"
                  fontSize={12}
                >
                  {error}
                </Text>
              ))}
            </View>
          )}
          {!utils.hasValue(props.errors) && (
            <Text fontStyle="italic" fontSize={12} ml={1}>
              {description}
            </Text>
          )}
        </View>
      </View>
    </View>
  )
}
