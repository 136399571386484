import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { utils } from '@ims/1edtech-frontend-common'

import { Icon, Text, View, ExpansionPanel, Image } from 'lib/components'
import colors from 'lib/styles/colors'
import { UserRole } from 'domains/users/constants/userRoles'

// @ts-ignore
const Container = styled(View)`
  min-height: 50px;
  &:hover {
    background-color: ${(props) => props.hover || colors.tertiary};
    cursor: pointer;
  }
`

export interface IBaseDrawerLinkProps {
  title: string
  icon: string
  iconColor?: string
  titleFontSize?: number
  titleFontWeight?: number
  route?: string
  isSubLink?: boolean
  parentTitle?: string
  baseRoute?: string
  onClick?: () => void
  isMaterial?: boolean
  isImg?: boolean
  external?: boolean
  manual?: boolean
  bg?: string
  hoverBg?: string
  requiredRoles: UserRole[]
  subLinks?: IBaseDrawerLinkProps[]
}
export interface IDrawerLinkProps extends IBaseDrawerLinkProps {
  open: boolean
}

export default function DrawerLink(props: IDrawerLinkProps) {
  const [subLinksOpen, setSubLinksOpen] = React.useState(
    utils.hasValue(props.subLinks) &&
      utils.host.isOnUrl(props.baseRoute || 'SHOULD_NOT_EXIST'),
  )
  const toggleSublinks = () => setSubLinksOpen(!subLinksOpen)

  const {
    icon,
    iconColor = props.isSubLink ? 'text' : 'white',
    isMaterial = false,
    isImg = false,
    title,
    titleFontSize = 16,
    titleFontWeight = 400,
    route,
    open,
    onClick,
    external,
    manual,
    bg = props.isSubLink ? 'background' : 'transparent',
    hoverBg,
  } = props

  const iconContent = isMaterial ? (
    <Icon
      className="material-icons"
      width="48px"
      height="48px"
      color={iconColor}
      py={12}
      pl={12}
      pr={11}
    >
      {icon}
    </Icon>
  ) : isImg ? (
    <Image
      src={icon}
      width="18px"
      height="18px"
      color={iconColor}
      ml={3}
      mr={2}
    />
  ) : (
    <Icon
      className={icon}
      width="48px"
      height="48px"
      color={iconColor}
      py={3}
      pl={3}
      pr={2}
    />
  )

  const content = (
    <Container
      flexible="row-v-center"
      pr={3}
      data-expanded={
        utils.hasValue(props.subLinks) && subLinksOpen
          ? 'expanded'
          : 'collapsed'
      }
      data-test={`drawer-link-${
        utils.hasValue(props.parentTitle) ? `${props.parentTitle}-` : ''
      }${title}`}
      bg={bg}
      hover={hoverBg}
      borderBottom="solid 1px rgba(255, 255, 255, 0.1)"
    >
      {iconContent}
      <Text
        variant="navLink"
        fontSize={titleFontSize}
        fontWeight={titleFontWeight}
        className={`collapsible ${open ? 'open' : 'closed'}`}
        whiteSpace="nowrap"
        userSelect="none"
        color={props.isSubLink ? 'text' : 'white'}
      >
        {title}
      </Text>

      <View flex={1} />
      {utils.hasValue(props.subLinks) && (
        <Icon
          fontSize={18}
          color="white"
          className={`fas rotate-caret fa-angle-down ${
            subLinksOpen ? 'down' : ''
          }`}
        />
      )}
    </Container>
  )

  if (external) {
    return (
      <a href={route} target="_blank" rel="noopener noreferrer">
        {content}
      </a>
    )
  }

  if (manual) {
    return <View onClick={onClick}>{content}</View>
  }

  if (props.subLinks) {
    return (
      <ExpansionPanel
        isOpen={subLinksOpen}
        onRequestChange={toggleSublinks}
        summary={content}
        details={props.subLinks.map((subLink, index) => (
          <DrawerLink
            key={`title-sublink-${subLink.title}-${index}`}
            {...subLink}
            open={props.open}
            isMaterial={subLink.isMaterial}
            isImg={subLink.isImg}
            isSubLink={true}
            hoverBg={'silver'}
            parentTitle={props.title}
          />
        ))}
      />
    )
  }

  return (
    <Link to={`${route}`} onClick={onClick}>
      {content}
    </Link>
  )
}
