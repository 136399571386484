import { HTMLAttributes } from 'react'
import styled from 'styled-components'
import * as SS from 'styled-system'

import colors from 'lib/styles/colors'
import { flexibleStyle } from 'lib/components/View'
import { inputDefaultStyles } from 'lib/components/Input'

export const variantStyle = SS.variant({ key: 'selectVariantStyles' })
export const selectVariantStyles = {
  bold: {
    fontWeight: '700',
    width: '200px',
    backgroundColor: '#ebebeb',
    border: 'solid 1px transparent',
    height: '48px',
    textIndent: '12px',
  },
  'narrow-bold': {
    fontWeight: '700',
    width: '120px',
    backgroundColor: '#ebebeb',
    border: 'solid 1px transparent',
    height: '36px',
    textIndent: '12px',
  },
}

export const whiteSpaceStyle = SS.style({
  prop: 'whiteSpace',
  cssProperty: 'whiteSpace',
})
export const textOverflowStyle = SS.variant({
  key: 'textOverflowStyles',
  prop: 'overflow',
})

export interface IHtmlSelectElementProps // eslint-disable-line
  extends HTMLAttributes<HTMLSelectElement> {}
interface IBaseProps
  extends IHtmlSelectElementProps,
    SS.AlignContentProps,
    SS.AlignItemsProps,
    SS.AlignSelfProps,
    SS.BackgroundColorProps,
    SS.BorderColorProps,
    SS.BorderProps,
    SS.BorderRadiusProps,
    SS.BordersProps,
    SS.BottomProps,
    SS.DisplayProps,
    SS.FlexBasisProps,
    SS.FlexDirectionProps,
    SS.FlexProps,
    SS.FlexWrapProps,
    SS.FontSizeProps,
    SS.FontStyleProps,
    SS.FontWeightProps,
    SS.GridAutoFlowProps,
    SS.GridColumnGapProps,
    SS.GridRowGapProps,
    SS.GridTemplateColumnsProps,
    SS.GridTemplateRowsProps,
    SS.HeightProps,
    SS.JustifyContentProps,
    SS.JustifySelfProps,
    SS.LeftProps,
    SS.LineHeightProps,
    SS.MaxHeightProps,
    SS.MaxWidthProps,
    SS.MinHeightProps,
    SS.MinWidthProps,
    SS.OrderProps,
    SS.OverflowProps,
    SS.PositionProps,
    SS.RightProps,
    SS.SpaceProps,
    SS.TextAlignProps,
    SS.TopProps,
    SS.VerticalAlignProps,
    SS.WidthProps,
    SS.ZIndexProps {
  flexible?: string
  whiteSpace?: string
  textOverflow?: string
  'data-test'?: string
}

interface IVariantProps {
  variant?: 'bold' | 'narrow-bold'
}

type ISelectProps = IBaseProps & IVariantProps

// prettier-ignore
const Select = styled.select<ISelectProps>`
  background-clip: padding-box;
  background-color: #fff;
  border-radius: .25rem;
  border: 1px solid ${colors.placeholder};
  color: ${colors.text};
  display: block;
  font-size: 14px;
  font-weight: 400;
  height: 38px
  line-height: 1.5;
  padding: 5px 8px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  width: 100%;

  ${variantStyle}
  ${flexibleStyle}
  ${inputDefaultStyles}
  ${whiteSpaceStyle}
  ${textOverflowStyle}

  ${SS.alignContent}
  ${SS.alignItems}
  ${SS.alignSelf}
  ${SS.bottom}
  ${SS.display}
  ${SS.flexBasis}
  ${SS.flexDirection}
  ${SS.flexWrap}
  ${SS.flex}
  ${SS.fontSize}
  ${SS.fontWeight}
  ${SS.height}
  ${SS.justifyContent}
  ${SS.justifySelf}
  ${SS.left}
  ${SS.lineHeight}
  ${SS.maxHeight}
  ${SS.maxWidth}
  ${SS.minHeight}
  ${SS.minWidth}
  ${SS.order}
  ${SS.position}
  ${SS.right}
  ${SS.space}
  ${SS.top}
  ${SS.width}
  ${SS.zIndex}
`

Select.displayName = 'Select'
export default Select
