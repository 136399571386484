import ITransformedCharacterizationCSVReport from 'domains/characterizations/models/ITransformedCharacterizationCSVReport'
import { GREEN_REPORT_STATUS } from 'domains/reports/models/IReportStatuses'
import ICSVModelCharacterizationReport from 'domains/characterizations/models/ICSVModelCharacterizationReport'

export const transformCharacterizationCSVModelReport = (
  report?: ICSVModelCharacterizationReport,
): ITransformedCharacterizationCSVReport => {
  if (!report) {
    return {
      validationId: 'n/a',
      unavailable: true,
      hasFileSetErrors: false,
      fileSetErrors: [],
      hasFileErrors: false,
      files: [],
    }
  }
  return {
    validationId: report.comparisonId,
    unavailable: false,
    hasFileSetErrors: false,
    fileSetErrors: [],
    hasFileErrors: false,
    files: report.fileComparisons.map((file) => ({
      fileLabel: file.filename,
      hasFileErrors: false,
      errors: [],
      hasColumnErrors: file.status !== GREEN_REPORT_STATUS,
      columnErrors: {},
      columns: file.columnComparisons.map((column) => ({
        headerName: column.name,
        isConformant: 'Yes',
        metadata: column.metadata,
        status: column.status,
        required: column.required,
        description: column.description,
        position: column.position,
        notes: column.notes,
        warningTitle: column.errorTitle,
        warning: column.errorMessage,
      })),
    })),
  }
}
