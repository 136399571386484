import * as historyLib from 'history'
import * as R from 'ramda'

export const getNavigationParam = (
  props: any,
  param: string,
  fallback: any = null,
) => R.pathOr(fallback, ['match', 'params', param], props)

export const navigationParamSelector = (
  param: string,
  fallback: any = null,
) => (_: any, props: Record<string, unknown>) =>
  getNavigationParam(props, param, fallback)

const historySingleton = historyLib.createBrowserHistory()

export const history = historySingleton
