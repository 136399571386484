import React from 'react'
import * as R from 'ramda'
import { Link } from 'react-router-dom'
import { utils } from '@ims/1edtech-frontend-common'

import IComparison from 'domains/comparisons/models/IComparison'
import { getCharacterizationRoute } from 'domains/characterizations/navigation/routes'
import { getProductRoute } from 'domains/products/navigation/routes'
import { isOneRosterRESTFormat } from 'domains/formats/utils/isFormatOfType'
import { ICSVComparisonReport } from 'domains/comparisons/models/ICSVComparisonReport'
import IRESTComparisonReport from 'domains/characterizations/models/IRESTComparisonReport'
import IProduct from 'domains/products/models/IProduct'
import { IWidgetLineItemProps } from 'domains/reports/components/WidgetLineItem'
import { CHARACTERIZATION_PROFILE_TO_DISPLAY } from 'domains/characterizations/models/ICharacterization'
import { isCCXCertified } from 'domains/orgs/models/IOrg'
import CCxCertifiedBadge from 'lib/components/CCxCertifiedBadge'

export const getComparisonReportFormatLineItems = (
  comparison: IComparison,
  isSupplier: boolean,
  product: IProduct,
  report?: ICSVComparisonReport | IRESTComparisonReport,
): IWidgetLineItemProps[] => {
  const runDate = {
    label: 'Run Date/Time',
    value: utils.date.formatForDisplay(comparison.characterizationRunDate),
  }
  let characterizationDetails = [
    {
      label: 'District',
      value: (
        <div className="flex flex-row items-center">
          <p className="text-text text-sm">{comparison.districtName}</p>
          {isCCXCertified(comparison.certifiedOwner) ? (
            <CCxCertifiedBadge className="ml-2" showTooltip />
          ) : null}
        </div>
      ),
    },
    {
      label: 'Characterization',
      value: isSupplier ? (
        comparison.characterizationName
      ) : (
        <Link
          to={getCharacterizationRoute(
            comparison.characterizationId.id,
            comparison.format,
          )}
        >
          {comparison.characterizationName}
        </Link>
      ),
    },
    {
      label: 'Source',
      value: (
        <div className="flex flex-row items-center">
          <p className="text-text text-sm">
            {comparison.characterizationSource}
          </p>
          {isCCXCertified(comparison.certifiedCharacterizationSourceLevel) ? (
            <CCxCertifiedBadge className="ml-2" showTooltip />
          ) : null}
        </div>
      ),
    },
    {
      label: 'Profile',
      value:
        CHARACTERIZATION_PROFILE_TO_DISPLAY[comparison.profile] || 'Unknown',
    },
  ]

  if (isOneRosterRESTFormat(comparison.format)) {
    const actualIsOAuth2 = R.pathOr(
      false,
      ['restComparisons', 0, 'endpointComparisons', 0, 'actualIsOAuth2'],
      report,
    )
    characterizationDetails = [
      ...characterizationDetails,
      { label: 'Security', value: actualIsOAuth2 ? 'OAuth2' : 'OAuth1a' },
      runDate,
    ]
  } else {
    characterizationDetails = [...characterizationDetails, runDate]
  }

  const productLink = {
    label: 'Product',
    value: (
      <Link
        to={getProductRoute(comparison.productId.id)}
        data-test="product-name"
      >
        {comparison.productName}
      </Link>
    ),
  }

  const productDetails = [productLink]

  const supplier = {
    label: 'Supplier',
    value: (
      <div className="flex flex-row items-center">
        <p className="text-text text-sm">{comparison.supplierName}</p>
        {isCCXCertified(comparison.certifiedSupplier) ? (
          <CCxCertifiedBadge className="ml-2" showTooltip />
        ) : null}
      </div>
    ),
  }

  const blank = { label: '', value: '' }
  if (comparison.rosteringToolName) {
    return [
      ...characterizationDetails,
      blank,
      ...productDetails,
      {
        label: 'Characterized via',
        value: (
          <Link
            to={getProductRoute(comparison.rosteringTool!.id)}
            data-test="characterized-via"
          >
            {comparison.rosteringToolName}
          </Link>
        ),
      },
      supplier,
    ]
  }

  return [...characterizationDetails, blank, ...productDetails, supplier]
}

export const getComparisonReportFormatAfterLineItems = (
  comparison: IComparison,
  report?: ICSVComparisonReport | IRESTComparisonReport,
): IWidgetLineItemProps[] => {
  if (isOneRosterRESTFormat(comparison.format)) {
    const modelIsOAuth2 = R.pathOr(
      false,
      ['restComparisons', 0, 'endpointComparisons', 0, 'modelIsOAuth2'],
      report,
    )
    return [{ label: 'Supports OAuth2', value: modelIsOAuth2 ? 'Yes' : 'No' }]
  }
  return []
}
