import React from 'react'

import View from 'lib/components/View'
import Text from 'lib/components/Text'
import Icon from 'lib/components/Icon'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  variant?: 'info' | 'error'
  message: string
  messageDataTest?: string
  extraMessage?: string
  extraMessageDataTest?: string
}

const VARIANT_TO_BG_MAP = {
  info: 'infoAlert',
  error: 'error',
}
const VARIANT_TO_COLOR_MAP = {
  info: 'text',
  error: 'white',
}
const VARIANT_TO_ICON_COLOR_MAP = {
  info: 'white',
  error: 'text',
}
const VARIANT_TO_ICON = {
  info: 'fas fa-info',
  error: 'fas fa-exclamation',
}

export default function InlineAlert(props: IProps) {
  const {
    variant = 'info',
    message,
    messageDataTest = 'inline-alert-message',
    extraMessage,
    extraMessageDataTest = 'inline-alert-extra-message',
  } = props
  const color = VARIANT_TO_COLOR_MAP[variant]
  return (
    <View
      bg={VARIANT_TO_BG_MAP[variant]}
      flexible="row"
      borderRadius="4px"
      p={3}
    >
      <View
        bg={color}
        borderRadius="50%"
        width="18px"
        minWidth="18px"
        height="18px"
        minHeight="18px"
        flexible="column-center"
        mr={2}
      >
        <Icon
          color={VARIANT_TO_ICON_COLOR_MAP[variant]}
          className={VARIANT_TO_ICON[variant]}
          fontSize="8px"
        />
      </View>

      <View>
        <Text color={color} fontSize={[10, 11, 12]} data-test={messageDataTest}>
          {message}
        </Text>
        {utils.hasValue(extraMessage) && (
          <Text
            color={color}
            mt={3}
            fontSize={[10, 11, 12]}
            data-test={extraMessageDataTest}
            whiteSpace="pre-wrap"
          >
            {extraMessage}
          </Text>
        )}
      </View>
    </View>
  )
}
