import { records } from '@ims/1edtech-frontend-common'

import { getRecord } from 'lib/records/workflows/getRecord'
import store from 'lib/store'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'
import { isProductCharacterized } from 'domains/products/utils/products'
import { deleteRecord } from 'lib/records/workflows/deleteRecord'
import { CSV_C_MODEL_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/csvCModelCharacterizations'
import {
  AnyFormatType,
  ANY_FORMAT_TO_SPEC_FORMAT_MAP,
} from 'domains/formats/constants/formats'
import { getProductFormatCharacterizationlId } from 'domains/products/utils/productByCharacterization'

export const deleteProductCharacterization = async (
  productId: string | number,
  format: AnyFormatType,
) => {
  const specFormat = ANY_FORMAT_TO_SPEC_FORMAT_MAP[format]
  const product = records.entitiesSelectors.entityByIdSelector(
    PRODUCTS_RECORD,
    productId,
  )(store.getState())
  if (isProductCharacterized(product, specFormat)) {
    const characterizationId = getProductFormatCharacterizationlId(
      product,
      specFormat,
    )
    await deleteRecord(CSV_C_MODEL_CHARACTERIZATIONS_RECORD, characterizationId)
    await getRecord(PRODUCTS_RECORD, productId)
  }
}
