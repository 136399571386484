import React, { PureComponent } from 'react'

import { View } from 'lib/components'

interface IProps {
  children: any
  fadeDelay: number
  dataTest?: string
}

const HEIGHT = 468
export default class LargeWidget extends PureComponent<IProps> {
  static HEIGHT = HEIGHT
  render() {
    return (
      <View
        animation="fadeIn"
        animationDelay={this.props.fadeDelay}
        variant="paper"
        height={HEIGHT}
        maxHeight={HEIGHT}
        boxSizing="border-box"
        mt={3}
        hover="elevation-two"
        data-test={this.props.dataTest}
        overflowY="auto"
      >
        {this.props.children}
      </View>
    )
  }
}
