import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { utils } from '@ims/1edtech-frontend-common'

import { Alert, Button, Text, View } from 'lib/components'
import LoggedOut from 'domains/authentication/components/LoggedOut'
import { setApplicationProperties } from 'domains/application/ducks/application'
import { applicationPropSelector } from 'domains/application/selectors/application'
import { APPLICATION_NAME } from 'domains/application/constants/application'
import { RootState } from 'lib/store/rootReducer'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import LoginForm, {
  ILoginFormValues,
} from 'domains/authentication/components/LoginForm'
import {
  getOauth2LoginInfo,
  VERIFIER_TOKEN,
} from 'domains/authentication/workflows/oauth2Login'
import { TOKENS_STORAGE_KEY } from 'lib/api/constants'
import { FRESHDESK_DATA_KEY } from 'domains/authentication/workflows/freshdeskJWTLogin'

interface IPropsFromState {
  loggedOut: boolean
}

const stateMap = createStructuredSelector<RootState, IPropsFromState>({
  loggedOut: applicationPropSelector('loggedOut'),
})

interface IActions {
  unSetLoggedOut: () => void
}

const actionMap = (dispatch: any) => ({
  unSetLoggedOut: () =>
    dispatch(setApplicationProperties({ loggedOut: false })),
})

const initialState = {
  failed: false,
  pending: false,
}
type IState = Readonly<typeof initialState>

export const BEARER_TOKEN = utils.env.getEnvVariable('BEARER_TOKEN')

export class LoginScreen extends PureComponent<IPropsFromState & IActions> {
  readonly state: IState = initialState

  shouldMockLogin = () => utils.env.getEnvVariable('MOCK_OAUTH') === 'true'

  componentDidMount() {
    if (!this.props.loggedOut) {
      this.onOauth2RedirectLogin()
    }
  }

  onOauth2RedirectLogin = () => {
    const { url, verifier } = getOauth2LoginInfo()
    utils.localStorage.setLocalItem(VERIFIER_TOKEN, verifier)

    const query = new URLSearchParams(window.location.search)
    const isFreshdesk = query.get('freshdesk') === 'true'
    if (isFreshdesk) {
      const state = query.get('state')
      const nonce = query.get('nonce')
      utils.localStorage.setLocalItem(FRESHDESK_DATA_KEY, {
        state,
        nonce,
      })
    }
    if (!this.shouldMockLogin()) {
      window.location.href = url
    }
  }

  onLogin = async (values: ILoginFormValues) => {
    this.setState({ failed: false })
    const localTokens = {
      tokenType: 'bearer',
      scope: 'http://www.1edtech.org/1edtech/v1p0/oauth2scope/1edtech-all',
      expiresIn: 3600,
      accessToken: BEARER_TOKEN || values.email,
      refreshToken:
        '266f810756bab6bc40cff2e923c3280f485f0bf61da851631f48f8711de95141',
      clientId: 'aaabbbccc',
      user: values.email,
    }
    utils.localStorage.setLocalItem(TOKENS_STORAGE_KEY, localTokens)
    window.location.href = '/'
  }

  switchToLogin = () => {
    this.props.unSetLoggedOut()
    this.onOauth2RedirectLogin()
  }

  renderLoggedOut() {
    return (
      <View flexible="column-center">
        <Text textAlign="center" my={3} data-test="logout-message">
          You have successfully logged out.
        </Text>
        <Button
          variant="flat"
          onClick={this.switchToLogin}
          data-test="logout-login-button"
        >
          Log In.
        </Button>
      </View>
    )
  }

  render() {
    const { loggedOut } = this.props
    if (!loggedOut && !this.shouldMockLogin()) {
      return <View variant="screen" />
    }

    return (
      <View flexible="column" flex={1} minWidth="100vw" minHeight="100vh">
        <DocumentTitle title={APPLICATION_NAME} />
        <LoggedOut>
          {this.state.failed && (
            <Alert variant="error" my={3} data-test="login-alert">
              Invalid login/password
            </Alert>
          )}
          {loggedOut && this.renderLoggedOut()}
          {!loggedOut && <LoginForm onLogin={this.onLogin} />}
        </LoggedOut>
      </View>
    )
  }
}

export default connect(stateMap, actionMap)(LoginScreen)
