import * as R from 'ramda'

import {
  ReportStatus,
  RED_REPORT_STATUS,
  AMBER_REPORT_STATUS,
  GREEN_REPORT_STATUS,
} from 'domains/reports/models/IReportStatuses'
import IComparison from 'domains/comparisons/models/IComparison'

const getComparisonReportStatus = (comparison: IComparison): ReportStatus => {
  const summary = R.pathOr([], ['summary'], comparison)
  if (R.any(R.propEq('compatibility', RED_REPORT_STATUS), summary)) {
    return RED_REPORT_STATUS
  }

  if (R.any(R.propEq('compatibility', AMBER_REPORT_STATUS), summary)) {
    return AMBER_REPORT_STATUS
  }

  return GREEN_REPORT_STATUS
}

export default getComparisonReportStatus
