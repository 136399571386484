import React from 'react'

import SmallWidget from 'domains/dashboard/components/SmallWidget'

interface IProps {
  fadeDelay: number
  count: number
}

export default function OrganizationSupplierCountWidget(props: IProps) {
  return (
    <SmallWidget
      flexible="column-h-start"
      fadeDelay={props.fadeDelay}
      icon="fas fa-building"
      iconColor="#009de3"
      title={props.count}
      subtitle="Suppliers"
      dataTest="suppliers-count-widget"
    />
  )
}
