import * as R from 'ramda'

import { createRecord } from 'lib/records/workflows/createRecord'
import { COMPARISONS_RECORD } from 'lib/records/modules/comparisons'
import { waitForServer } from 'lib/utils/wait'
import { utils } from '@ims/1edtech-frontend-common'
import { AnyFormatType } from 'domains/formats/constants/formats'

export default async function createComparison(
  productId: string | number,
  characterizationId: string | number,
  format: AnyFormatType,
  visibility: boolean,
): Promise<any> {
  const response = await createRecord(COMPARISONS_RECORD, {
    productId,
    characterizationId,
    visibility,
    format,
  })
  if (!response.success) {
    return false
  }

  const [, , comparison] = await waitForServer(
    COMPARISONS_RECORD,
    response.data.id,
    (data) => utils.hasValue(R.propOr(false, 'summary', data)),
    1000,
  )

  return comparison
}
