import * as R from 'ramda'
import { utils, records } from '@ims/1edtech-frontend-common'

import { CSV_C_MODELS_RECORD } from 'lib/records/modules/csvCModels'
import { updateRecord } from 'lib/records/workflows/updateRecord'
import { getRecord } from 'lib/records/workflows/getRecord'
import store from 'lib/store'
import { deleteProductCharacterization } from 'domains/characterizations/workflows/deleteProductCharacterization'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'
import ICSVConsumerModel from 'domains/modeling/models/ICSVConsumerModel'

export const updateCsvCModel = async (id: string | number, updates: any) => {
  const model = records.entitiesSelectors.entityByIdSelector(
    CSV_C_MODELS_RECORD,
    utils.convertToInt(id),
  )(store.getState()) as ICSVConsumerModel
  const productId = utils.convertToInt(
    R.pathOr(0, ['product', 'id'], model),
  ) as number
  await deleteProductCharacterization(productId, model.format)
  await updateRecord(CSV_C_MODELS_RECORD, id, { ...updates, productId })
  await getRecord(CSV_C_MODELS_RECORD, id, { productId })
  await getRecord(PRODUCTS_RECORD, productId)
  return records.entitiesSelectors.entityByIdSelector(
    CSV_C_MODELS_RECORD,
    utils.convertToInt(id),
  )(store.getState())
}
