import React from 'react'
import * as R from 'ramda'

import { Text, View, Span } from 'lib/components'
import { utils } from '@ims/1edtech-frontend-common'
import CCCharacterizationReportLOMDetail from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMDetail'
import { ICommonCartridgeDuration } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeDuration'
import { ICommonCartridgeLanguageText } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeLanguageText'
import CCCharacterizationReportLOMLanguageText from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMLanguageText'
import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'

interface IProps {
  path: string
  title?: string
  data?: ICommonCartridgeDuration
}

export default function CCCharacterizationReportLOMDuration(props: IProps) {
  const { showMissingMetadata } = React.useContext(ShowMissingMetadataContext)
  if (!showMissingMetadata && !utils.hasValue(props.data)) {
    return null
  }

  const descriptions = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['description'],
    props.data,
  ) as ICommonCartridgeLanguageText[]

  const inner = (
    <View>
      <Text data-test={`${props.path}.type`}>
        <Span fontWeight={700}>duration: </Span> {props.data!.duration}
      </Text>

      {descriptions.map((d, i) => (
        <View mt={i === 0 ? 0 : 1} key={`${props.path}.description.${i}`}>
          <Text fontWeight={700}>description [{i + 1}]: </Text>
          <View ml={3}>
            <CCCharacterizationReportLOMLanguageText
              path={
                !utils.hasValue(d)
                  ? `${props.path}.description`
                  : `${props.path}.description.${i}`
              }
              data={d}
            />
          </View>
        </View>
      ))}
    </View>
  )

  if (utils.hasValue(props.title)) {
    return (
      <CCCharacterizationReportLOMDetail
        path={props.path}
        title={props.title!}
        value={inner}
      />
    )
  }

  return inner
}
