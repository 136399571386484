import React from 'react'
import { useSelector } from 'react-redux'
import { utils, records } from '@ims/1edtech-frontend-common'

import { View, List, ListCallToActionButton } from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import BaseBreadCrumbs from 'domains/application/components/BaseBreadCrumbs'
import { LTI_PLATFORMS_RECORD } from 'lib/records/modules/ltiPlatforms'
import { RootState } from 'lib/store/rootReducer'
import ILTIPlatform from 'domains/configuration/models/ILTIPlatform'
import GenericDeleteCell from 'domains/configuration/components/GenericDeleteCell'
import useModalState from 'lib/hooks/useModalState'
import Dialog from 'domains/application/modals/Dialog'
import TextWithBoldHighlight from 'lib/components/TextWithBoldHighlight'
import { deleteRecord } from 'lib/records/workflows/deleteRecord'
import CreateEditLTIPlatformModal from 'domains/configuration/components/CreateEditLTIPlatformModal'
import { IListColumnDefinition } from 'lib/components/List'

export default function LTIPlatformsScreen(props: any) {
  const ltiPlatforms = useSelector((state: RootState) =>
    records.recordsSelectors.fullRecordsSelector(LTI_PLATFORMS_RECORD)(
      state,
      props,
    ),
  )

  const [deleteModalOpen, openDeleteModal, closeDeleteModal] = useModalState()
  const [
    deleteLTIPlatform,
    setDeleteLTIPlatform,
  ] = React.useState<null | ILTIPlatform>(null)
  const onDeleteSource = (platform: ILTIPlatform) => {
    setDeleteLTIPlatform(platform)
    openDeleteModal()
  }
  const onDeleteConfirmed = async () => {
    await deleteRecord(LTI_PLATFORMS_RECORD, deleteLTIPlatform!.id)
    closeDeleteModal()
  }

  const [editModalOpen, openEditModal, closeEditModal] = useModalState()
  const [
    editLTIPlatform,
    setEditLTIPlatform,
  ] = React.useState<null | ILTIPlatform>(null)
  const onEditSource = (source: ILTIPlatform) => {
    setEditLTIPlatform(source)
    openEditModal()
  }

  const onAddNew = () => {
    setEditLTIPlatform(null)
    openEditModal()
  }

  const columns: IListColumnDefinition[] = [
    {
      title: 'Name',
      noCenter: true,
      accessor: 'platformName',
      sortKey: utils.sort.addSortValue('platformName@'),
    },
    {
      title: null,
      noSort: true,
      forceCenter: true,
      maxWidth: 50,
      CellComponent: GenericDeleteCell,
      cellProps: { onClick: onDeleteSource, nameProp: 'platformName' },
    },
  ]

  return (
    <View variant="screen">
      <DocumentTitle title="LTI Platforms" />
      <BaseBreadCrumbs crumbs={[{ name: 'LTI Platforms' }]} />

      <List
        title="LTI Platforms"
        record={LTI_PLATFORMS_RECORD}
        recordState={ltiPlatforms}
        columns={columns}
        dataTest="ltiPlatforms-list"
        initialSortColumnIndex={0}
        noSort={true}
        noSearch={true}
        onRowClick={onEditSource}
        callToAction={
          <ListCallToActionButton onClick={onAddNew} text="New LTI Platform" />
        }
      />

      <Dialog
        isOpen={deleteModalOpen}
        icon="fas fa-trash"
        message={
          utils.hasValue(deleteLTIPlatform) ? (
            <TextWithBoldHighlight
              text="You are about to delete @@. Are you sure?"
              highlight={deleteLTIPlatform!.platformName}
            />
          ) : (
            ''
          )
        }
        confirmText="Yes, Delete"
        onConfirm={onDeleteConfirmed}
        onDeny={closeDeleteModal}
      />

      <CreateEditLTIPlatformModal
        editLTIPlatform={editLTIPlatform}
        isOpen={editModalOpen}
        close={closeEditModal}
      />
    </View>
  )
}
