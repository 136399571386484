import React from 'react'
import { useDispatch } from 'react-redux'
import * as R from 'ramda'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { utils, records } from '@ims/1edtech-frontend-common'

import IOrgAction from 'domains/orgs/models/IOrgAction'
import IOrg from 'domains/orgs/models/IOrg'
import { Button, FormInput, InputLabel, Switch } from 'lib/components'
import { updateRecord } from 'lib/records/workflows/updateRecord'
import { ORGANIZATIONS_RECORD } from 'lib/records/modules/organizations'
import { showToast } from 'lib/utils/toast'

type FormValues = {
  reason: string
  force: 'allow' | 'deny' | null
}

const validationSchema = Yup.object().shape({
  reason: Yup.string().when(['force'], {
    is: (force) => !force,
    then: Yup.string(),
    otherwise: Yup.string().required(
      'Please provide a reason for overriding this action.',
    ),
  }),
  force: Yup.mixed(),
})

type Props = {
  org: IOrg
  action: IOrgAction
}

export default function OrgActionOverrideForm(props: Props) {
  const dispatch = useDispatch()
  const orgOverrides = props.org.overrides || []
  const existingOverride = R.find(
    R.propEq('name', props.action.name),
    orgOverrides,
  )
  const reason = R.pathOr('', ['reason'], existingOverride)
  const timestamp = R.pathOr(0, ['timestamp'], existingOverride)
  const user = R.pathOr('', ['user'], existingOverride)
  const forceAllowed = R.pathOr(false, ['forceAllow'], existingOverride)
  const forceDenied = R.pathOr(false, ['forceDeny'], existingOverride)

  const onSave = async (values: FormValues, bag: FormikHelpers<FormValues>) => {
    const orgOverridesWithoutAction = orgOverrides.filter(
      (o) => o.name !== props.action.name,
    )
    const { success } = await updateRecord(
      ORGANIZATIONS_RECORD,
      props.org.id,
      {
        ...props.org,
        overrides: [
          ...orgOverridesWithoutAction,
          ...(!values.force
            ? []
            : [
                {
                  ...(existingOverride || {}),
                  name: props.action.name,
                  reason: values.reason,
                  forceAllow: values.force === 'allow',
                  forceDeny: values.force === 'deny',
                },
              ]),
        ],
      },
      true,
    )

    if (success) {
      if (!values.force) {
        dispatch(
          records.entitiesActions.replaceEntityProperty(
            [ORGANIZATIONS_RECORD, props.org.id, 'overrides'],
            orgOverridesWithoutAction,
          ),
        )
      }
      showToast('start', 'Successfully updated action override')
    } else {
      showToast('error', 'Failed to update action override')
    }

    bag.setSubmitting(false)
  }

  return (
    <Formik
      initialValues={{
        reason,
        force: forceAllowed ? 'allow' : forceDenied ? 'deny' : null,
      }}
      onSubmit={onSave}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {(bag) => {
        const changed =
          (bag.values.force === 'allow' && !forceAllowed) ||
          (bag.values.force === 'deny' && !forceDenied) ||
          (!bag.values.force && (forceAllowed || forceDenied)) ||
          bag.values.reason !== reason
        const onToggleForce = (type: 'allow' | 'deny') => () => {
          bag.setFieldValue('force', bag.values.force === type ? null : type)
          bag.setFieldTouched('force', true)
        }

        return (
          <div className="p-4 flex flex-col space-y-4 border-t">
            <div className="flex flex-row items-center space-x-6">
              <div className="">
                <InputLabel>Force Allow</InputLabel>
                <Switch
                  on={bag.values.force === 'allow'}
                  onChange={onToggleForce('allow')}
                  dataTest={`action-${props.action.name}-force-allow-toggle`}
                />
              </div>

              <div className="">
                <InputLabel>Force Deny</InputLabel>
                <Switch
                  on={bag.values.force === 'deny'}
                  onChange={onToggleForce('deny')}
                  dataTest={`action-${props.action.name}-force-deny-toggle`}
                />
              </div>
            </div>

            <div className="w-full">
              <FormInput
                label="Reason"
                required
                name="reason"
                value={bag.values.reason}
                placeholder="Reason for overriding"
                valueDataTest={`action-${props.action.name}-reason`}
                handleChange={bag.handleChange}
                handleBlur={bag.handleBlur}
                inputProps={{ width: '100%' }}
                textArea
              />
            </div>

            <div className="w-full flex flex-row justify-between">
              <div>
                {!!existingOverride && (
                  <>
                    <p className="text-xs text-textLight">
                      Previously overridden by{' '}
                      <span className="font-bold">{user}</span>
                    </p>
                    <p className="text-xs text-textLight">
                      {utils.date.formatForDisplay(timestamp)}
                    </p>
                  </>
                )}
              </div>

              <Button
                onClick={bag.handleSubmit}
                type="submit"
                variant={changed ? 'start' : 'neutral'}
                disabled={!changed}
                pending={bag.isSubmitting}
              >
                Save
              </Button>
            </div>
          </div>
        )
      }}
    </Formik>
  )
}
