import React from 'react'

import ICharacterization from 'domains/characterizations/models/ICharacterization'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import { View, Text } from 'lib/components'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  characterization: ICharacterization
}

export default function CharacterizationNotesPaper(props: IProps) {
  const { notes = '' } = props.characterization
  if (!utils.hasValue(notes)) {
    return null
  }

  return (
    <View variant="paper" data-test="characterization-notes-widget" mb={3}>
      <WidgetHeader
        title="Characterization Report Notes"
        dataTest="characterization-notes-header"
      />

      <Text mt={3} whiteSpace="pre-line" data-test="characterization-notes">
        {notes}
      </Text>
    </View>
  )
}
