import React, { useState } from 'react'
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  autoUpdate,
  flip,
  shift,
  offset,
  FloatingFocusManager,
} from '@floating-ui/react'
import clsx from 'clsx'
import {
  ICON_COLORS,
  ICON_IDENTIFIERS,
  getRandomIconColor,
  getRandomIconIdentifier,
} from '../../constants/trustedAppsCustomRatingSetup'
import { twMerge } from 'tailwind-merge'
import { myOrganizationLogoSelector } from 'domains/authentication/selectors/organizations'
import { useSelector } from 'react-redux'
import { TRUSTED_APP_RATING_ORG_LOGO_IDENTIFIER } from 'domains/trustedApps/constants/trustedAppsCustomRating'

interface CustomRatingIconProps {
  iconIdentifier: string
  iconColor: string
  onSelectIconIdentifier: (iconIdentifier: string) => void
  onSelectIconColor: (iconColor: string) => void
}

enum Tab {
  ICON,
  ORG_LOGO,
}

export function CustomRatingIconSelect({
  iconColor,
  iconIdentifier,
  onSelectIconIdentifier,
  onSelectIconColor,
}: CustomRatingIconProps) {
  const isLogoSelected =
    iconIdentifier === TRUSTED_APP_RATING_ORG_LOGO_IDENTIFIER
  const logo = useSelector((s: any) => myOrganizationLogoSelector(s, {}))
  const [selectedTab, setSelectedTab] = useState<Tab>(
    isLogoSelected ? Tab.ORG_LOGO : Tab.ICON,
  )

  const [isOpen, setIsOpen] = useState(false)
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
    placement: 'right',
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ])

  const tabs = [
    {
      label: 'Icon',
      value: Tab.ICON,
    },
    {
      label: 'Org Logo',
      value: Tab.ORG_LOGO,
    },
  ]

  return (
    <>
      <button className="w-14" ref={refs.setReference} {...getReferenceProps()}>
        {isLogoSelected ? (
          <img width={36} height={36} src={logo} alt="Organization Logo" />
        ) : (
          <i
            className={`fas ${iconIdentifier} text-4xl`}
            style={{ color: iconColor }}
          />
        )}
      </button>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            className="p-4"
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
              width: 300,
              backgroundColor: '#A3A3A3',
            }}
            {...getFloatingProps()}
          >
            <div className="flex items-center border-2 border-blue-500 rounded-lg overflow-hidden mb-3">
              {tabs.map((tab) => (
                <button
                  key={tab.value}
                  className={twMerge(
                    'px-2 py-1 bg-blue-500 text-white w-1/2',
                    tab.value === selectedTab && 'bg-white text-blue-500',
                  )}
                  onClick={() => {
                    setSelectedTab(tab.value)

                    if (tab.value === Tab.ICON) {
                      onSelectIconIdentifier(getRandomIconIdentifier())
                      onSelectIconColor(getRandomIconColor())
                    }

                    if (tab.value === Tab.ORG_LOGO) {
                      onSelectIconIdentifier(
                        TRUSTED_APP_RATING_ORG_LOGO_IDENTIFIER,
                      )
                      onSelectIconColor(TRUSTED_APP_RATING_ORG_LOGO_IDENTIFIER)
                    }
                  }}
                >
                  {tab.label}
                </button>
              ))}
            </div>

            {selectedTab === Tab.ICON && (
              <div>
                <div className="flex gap-2 items-center flex-wrap">
                  {ICON_IDENTIFIERS.map((icon) => (
                    <div
                      className={clsx(
                        'border-2 border-solid rounded-full h-8 w-8 box-content flex items-center justify-center cursor-pointer',
                        iconIdentifier === icon
                          ? 'border-white'
                          : 'border-transparent',
                      )}
                      onClick={() => {
                        onSelectIconIdentifier(icon)
                      }}
                    >
                      <i className={clsx(`fas text-xl`, icon, 'text-white')} />
                    </div>
                  ))}
                </div>

                <div className="mt-2 flex gap-2 items-center flex-wrap">
                  {ICON_COLORS.map((color) => (
                    <div
                      className={clsx(
                        'border-2 border-solid rounded-full h-4 w-4 box-content flex items-center justify-center cursor-pointer',
                      )}
                      style={{
                        borderColor:
                          iconColor === color ? '#FFFFFF' : '#BFBFBF',
                        backgroundColor: color,
                      }}
                      onClick={() => {
                        onSelectIconColor(color)
                      }}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </FloatingFocusManager>
      )}
    </>
  )
}
