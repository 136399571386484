import React from 'react'
import { ITransformedRESTServiceEndpointAttributeCharacterizationReport } from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import { ListPlainTextCell } from 'lib/components'
import { getReportMinMaxValue } from 'domains/reports/utils/getReportMinMaxValue'

export default function RESTCharacterizationAttributeMinMaxCell(
  props: ITransformedRESTServiceEndpointAttributeCharacterizationReport,
) {
  return <ListPlainTextCell>{getReportMinMaxValue(props)}</ListPlainTextCell>
}
