import React from 'react'
import * as R from 'ramda'

import { Text, View } from 'lib/components'
import { twMerge } from 'tailwind-merge'

interface IProps {
  title: any
  dataTest?: string
  className?: string
}

export default function WidgetHeader(props: IProps) {
  return (
    <div className={twMerge('flex items-center', props.className)}>
      <View
        bg="secondary"
        width="4px"
        height="20px"
        mr={2}
        borderRadius="2px"
        boxSizing="border-box"
      />
      {R.is(String, props.title) && (
        <Text fontSize={18} fontWeight={700} data-test={props.dataTest}>
          {props.title}
        </Text>
      )}
      {!R.is(String, props.title) && (
        <View data-test={props.dataTest} fontSize={18} fontWeight={700}>
          {props.title}
        </View>
      )}
    </div>
  )
}
