interface IComponent {
  displayName?: string
  name?: string
}

export const getComponentName = (WrappedComponent: IComponent) =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component'

export const getDisplayName = (hoc: string, WrappedComponent: any) =>
  `${hoc}(${getComponentName(WrappedComponent)})`
