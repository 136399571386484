import React from 'react'
import clsx from 'clsx'

import * as vettings from 'domains/trustedApps/models/IIMSApplicationVetting'
import { IIMSApplicationVettingSelection } from 'domains/trustedApps/models/IIMSApplicationVetting'

type CellProps = {
  checked: boolean
  icon: string
  bgColor: string
  rightBorder?: boolean
}
function Cell(props: CellProps) {
  return (
    <div
      className={clsx(
        'border-l border-gray-200 h-full flex flex-1 flex-row items-center justify-center',
        {
          'border-r': props.rightBorder,
          [props.bgColor]: props.checked,
          'bg-transparent': !props.checked,
          shadow: props.checked,
        },
      )}
    >
      {props.checked && <i className={`${props.icon} text-white text-lg`} />}
    </div>
  )
}

type TrustedAppVettingSelectionResultsProps = {
  selection: IIMSApplicationVettingSelection
  index: number
}

export default function TrustedAppVettingSelectionResults(
  props: TrustedAppVettingSelectionResultsProps,
) {
  const dataTest = `rubric-score-${props.index}`
  if (props.selection.userSelection === vettings.VETTING_NA_RUBRIC_SCORE) {
    return (
      <div
        className={clsx(
          vettings.VETTING_RUBRIC_SCORE_BG_COLOR_MAP[
            vettings.VETTING_NA_RUBRIC_SCORE
          ],
          'flex flex-row justify-center items-center h-full shadow',
        )}
        data-test={dataTest}
        data-score={props.selection.userSelection}
      >
        <p className="text-white text-center font-bold text-base">
          NOT APPLICABLE
        </p>
      </div>
    )
  }

  const scores = [
    vettings.VETTING_MEETS_RUBRIC_SCORE,
    vettings.VETTING_PARTIAL_RUBRIC_SCORE,
    vettings.VETTING_UNMET_RUBRIC_SCORE,
  ]

  return (
    <div
      className="bg-gray-100 flex flex-row items-center"
      data-test={dataTest}
      data-score={props.selection.userSelection}
    >
      {scores.map((score, index) => (
        <Cell
          key={`${score}-${index}`}
          checked={props.selection.userSelection === score}
          icon={vettings.VETTING_RUBRIC_SCORE_ICON_MAP[score]}
          bgColor={vettings.VETTING_RUBRIC_SCORE_BG_COLOR_MAP[score]}
          rightBorder={index === scores.length - 1}
        />
      ))}
    </div>
  )
}
