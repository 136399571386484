import { IconIdentifier } from '../components/TrustedAppsCustomRatingSetup/types'

export const getRandomIconColor = () => {
  return ICON_COLORS[(Math.random() * ICON_COLORS.length) | 0]
}

export const getRandomIconIdentifier = () => {
  return ICON_IDENTIFIERS[(Math.random() * ICON_IDENTIFIERS.length) | 0]
}

export const ICON_COLORS = [
  '#8459FF',
  '#CC4614',
  '#33AE62',
  '#919496',
  '#6666FF',
  '#FF941F',
  '#FFBF00',
] as const

export const ICON_IDENTIFIERS = [
  'fa-star',
  'fa-x',
  'fa-minus',
  'fa-ellipsis',
  'fa-triangle-exclamation',
  'fa-clipboard',
  'fa-clipboard-check',
  'fa-circle-check',
  'fa-circle-question',
] as const

export const ICON_IDENTIFIERS_OBJECT = ICON_IDENTIFIERS.reduce((acc, id) => {
  acc[id] = id
  return acc
}, {} as Record<IconIdentifier, IconIdentifier>)
