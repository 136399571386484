import React from 'react'
// import * as R from 'ramda'
import clsx from 'clsx'

import {  Text, Icon  } from 'lib/components'
import TrustedAppsConnectionWidget from 'domains/trustedApps/components/TrustedAppsConnectionWidget'
import ILTIConfiguration from 'domains/trustedApps/models/ILTIConfiguration'

type IProps = {
    ltiConfiguration: ILTIConfiguration
    onUpdate: () => void
}
export default function TrustedAppsConfigExpansionHeader(props: IProps) {
    const [open, setOpen] = React.useState(false)
    const config = props.ltiConfiguration
    return (
            <div className="w-full flex flex-col shadow-md rounded-md mt-2">
                <div
                    className={clsx(
                        'w-full flex justify-between cursor-pointer p-6 hover:bg-gray-100 rounded:shadow-lg rounded-md',
                        {
                            'mb-4': open,
                        },
                    )}
                    onClick={() => setOpen(!open)}
                >
                    <Text fontSize={16}>{config.name}</Text>
                    <Icon
                        color="textLight"
                        className={`
                      fas fa-chevron-up
                      rotate-chevron
                      ${!open ? 'down' : ''}
                    `}
                    />
                </div>
                {open && (
                    <TrustedAppsConnectionWidget
                        height={850}
                        ltiConfiguration={config}
                    onUpdate={() => { 
                        props.onUpdate()
                        setOpen(false)
                    }}
                    />
                )}
            </div>
    )
}
