import { normalize } from 'normalizr'
import { records, utils } from '@ims/1edtech-frontend-common'
import * as R from 'ramda'

import {
  mergeEntities,
  replaceEntities,
  updateEntityProperty,
} from 'lib/store/entities.actions'
import store from 'lib/store'

export const mergeOrReplaceEntities = (
  merge: boolean,
  module: records.modules.IModule,
  values: any = [],
  isArray = false,
) => {
  const { schema } = module

  if (!schema || !utils.hasValue(values)) {
    return values
  }

  const normalizrSchema = isArray ? [schema] : schema
  const { entities, result } = normalize(values, normalizrSchema)

  if (merge) {
    store.dispatch(mergeEntities(entities))
  } else {
    store.dispatch(replaceEntities(entities))

    if (isArray) {
      store.dispatch(
        updateEntityProperty([module.record], () =>
          R.pathOr({}, [module.record], entities),
        ),
      )
    } else {
      store.dispatch(
        updateEntityProperty([module.record, result], () =>
          R.pathOr({}, [module.record, result], entities),
        ),
      )
    }
  }

  return result
}
