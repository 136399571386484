import React from 'react'
import { View, Icon, Text } from 'lib/components'
import { REQUIRED_TO_ICON_COLOR_MAP } from 'domains/comparisons/constants/comparisonReports'
import {
  REQUIRED_REQUIRED_FIELD,
  PROHIBITED_REQUIRED_FIELD,
  OPTIONAL_REQUIRED_FIELD,
} from 'domains/modeling/constants/columns'
import PrintMetadataFlag from 'domains/comparisons/components/PrintMetadataFlag'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'
import {
  AnySpecFormatType,
  ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL,
  ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
} from 'domains/formats/constants/formats'
import ReportStatusIcon from 'domains/reports/components/ReportStatusIcon'

const isOnPrint = isOnPrintScreen()
const fontSize = isOnPrint ? 11 : undefined
const lineHeight = isOnPrint ? '11px' : undefined

function KeyContainer(props: { isFirst?: boolean; children: any }) {
  return (
    <View
      flexible="row-v-center"
      ml={props.isFirst ? undefined : [0, 2]}
      mt={props.isFirst ? undefined : [1, 0]}
    >
      {props.children}
    </View>
  )
}
function KeyText(props: any) {
  return (
    <Text ml={2} fontSize={fontSize} lineHeight={lineHeight}>
      {props.children}
    </Text>
  )
}

interface IProps {
  isPrint?: boolean
  format?: AnySpecFormatType
}

export default function ComparisonReportLegend(props: IProps) {
  const isREST = [
    ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL,
    ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
  ].includes(props.format || '')

  const providerLabel = (
    <View flexible="row-v-center">
      <PrintMetadataFlag isConsumer={false} />
      <KeyText>Provider</KeyText>
    </View>
  )

  const consumerLabel = (
    <View flexible="row-v-center" ml={[0, 2]} mt={[1, 0]}>
      <PrintMetadataFlag isConsumer={true} />
      <KeyText>Consumer</KeyText>
    </View>
  )
  return (
    <View
      px={props.isPrint ? 3 : 4}
      py={props.isPrint ? 1 : 2}
      bg="background"
      minHeight={props.isPrint ? 30 : 38}
      minWidth={['auto', 280]}
      flexible="column-v-center"
    >
      <View flexible="row-space-between-wrap" width="100%">
        <KeyContainer isFirst={true}>
          <Icon
            color={REQUIRED_TO_ICON_COLOR_MAP[REQUIRED_REQUIRED_FIELD]}
            className="fas fa-asterisk"
          />
          <KeyText>Required</KeyText>
        </KeyContainer>

        <KeyContainer>
          <Icon
            color={REQUIRED_TO_ICON_COLOR_MAP[PROHIBITED_REQUIRED_FIELD]}
            className="fas fa-ban"
          />
          <KeyText>Prohibited</KeyText>
        </KeyContainer>

        <KeyContainer>
          <Icon
            color={REQUIRED_TO_ICON_COLOR_MAP[OPTIONAL_REQUIRED_FIELD]}
            className="fas fa-circle-notch"
          />
          <KeyText>Optional</KeyText>
        </KeyContainer>

        {props.isPrint && <KeyContainer>{providerLabel}</KeyContainer>}
        {props.isPrint && consumerLabel}
      </View>
      {isREST && (
        <View flexible="row-space-between-wrap" width="100%" pt={3}>
          <KeyContainer isFirst={true}>{providerLabel}</KeyContainer>

          {consumerLabel}

          <KeyContainer>
            <ReportStatusIcon status="GRAY" circle={true} />
            <KeyText>Inactive</KeyText>
          </KeyContainer>
        </View>
      )}
    </View>
  )
}
