import {
  ONE_ROSTER_SPEC_V1P1,
  ONE_ROSTER_SPEC_V1P2,
  ONE_ROSTER_SPEC,
  ONE_ROSTER_ROSTERING_SPEC,
  ONE_ROSTER_GRADEBOOK_SPEC,
  ONE_ROSTER_GRADEBOOK_PUSH_SPEC,
  ONE_ROSTER_GRADEBOOK_PULL_SPEC,
  ONE_ROSTER_RESOURCES_SPEC,
} from 'domains/specifications/constants/oneRoster'
import {
  COMMON_CARTRIDGE_SPEC,
  COMMON_CARTRIDGE_SPEC_V1P2,
  COMMON_CARTRIDGE_SPEC_V1P3,
} from 'domains/specifications/constants/CommonCartridge'

export const ONER_1P1_BULK = 'OneRoster 1p1 Bulk'
export const ONER_1P2_BULK = 'OneRoster 1p2 Bulk'
export const ONER_1P1_DELTA = 'OneRoster 1p1 Delta'

// ROSTERING CSV
export const ONER_V1P1_CSV_ROSTERING_IMPORT_BULK =
  'OneRoster v1.1 CSV Rostering Import Bulk'
export const ONER_V1P1_CSV_ROSTERING_IMPORT_BULK_NAV_ROUTE =
  'or_1p1_csv_rostering_import_bulk'

export const ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA =
  'OneRoster v1.1 CSV Rostering Import Delta'
export const ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA_NAV_ROUTE =
  'or_1p1_csv_rostering_import_delta'

export const ONER_V1P1_CSV_ROSTERING_EXPORT_BULK =
  'OneRoster v1.1 CSV Rostering Export Bulk'
export const ONER_V1P1_CSV_ROSTERING_EXPORT_BULK_NAV_ROUTE =
  'or_1p1_csv_rostering_export_bulk'

export const ONER_V1P2_CSV_ROSTERING_EXPORT_BULK =
  'OneRoster v1.2 CSV Rostering Export Bulk'
export const ONER_V1P2_CSV_ROSTERING_EXPORT_BULK_NAV_ROUTE =
  'or_1p2_csv_rostering_export_bulk'

export const ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA =
  'OneRoster v1.1 CSV Rostering Export Delta'
export const ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA_NAV_ROUTE =
  'or_1p1_csv_rostering_export_delta'

// ROSTERING REST
export const ONER_V1P1_REST_CONSUMER_ROSTERING_CORE =
  'OneRoster v1.1 REST Data Consumer Rostering Core'
export const ONER_V1P1_REST_CONSUMER_ROSTERING_CORE_NAV_ROUTE =
  'or_1p1_rest_consumer_rostering_core'

export const ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL =
  'OneRoster v1.1 REST Data Consumer Rostering Optional'
export const ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL_NAV_ROUTE =
  'or_1p1_rest_consumer_rostering_optional'

export const ONER_V1P1_REST_PROVIDER_ROSTERING_CORE =
  'OneRoster v1.1 REST Data Provider Rostering Core'
export const ONER_V1P1_REST_PROVIDER_ROSTERING_CORE_NAV_ROUTE =
  'or_1p1_rest_provider_rostering_core'

export const ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL =
  'OneRoster v1.1 REST Data Provider Rostering Optional'
export const ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL_NAV_ROUTE =
  'or_1p1_rest_provider_rostering_optional'
export const ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL_SHORT =
  'OneRoster v1.1 REST Data Provider Rostering'

// GRADEBOOK CSV
export const ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK =
  'OneRoster v1.1 CSV Gradebook Import Bulk'
export const ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK_NAV_ROUTE =
  'or_1p1_gradebook_import_bulk'

export const ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK =
  'OneRoster v1.1 CSV Gradebook Export Bulk'
export const ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK_NAV_ROUTE =
  'or_1p1_gradebook_export_bulk'

// GRADEBOOK REST PULL
export const ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL =
  'OneRoster v1.1 REST Data Consumer Gradebook Pull Optional'
export const ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL_NAV_ROUTE =
  'or_1p1_rest_consumer_gradebook_pull_optional'

export const ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL =
  'OneRoster v1.1 REST Data Provider Gradebook Pull Optional'
export const ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL_NAV_ROUTE =
  'or_1p1_rest_provider_gradebook_pull_optional'
export const ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL_SHORT =
  'OneRoster v1.1 REST Data Provider Gradebook Pull'

// GRADEBOOK REST PUSH
export const ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL =
  'OneRoster v1.1 REST Data Consumer Gradebook Push Optional'
export const ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL_NAV_ROUTE =
  'or_1p1_rest_consumer_gradebook_push_optional'

export const ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL =
  'OneRoster v1.1 REST Data Provider Gradebook Push Optional'
export const ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL_NAV_ROUTE =
  'or_1p1_rest_provider_gradebook_push_optional'
export const ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL_SHORT =
  'OneRoster v1.1 REST Data Provider Gradebook Push'

// RESOURCES CSV
export const ONER_V1P1_CSV_RESOURCES_EXPORT_BULK =
  'OneRoster v1.1 CSV Resources Export Bulk'
export const ONER_V1P1_CSV_RESOURCES_EXPORT_BULK_NAV_ROUTE =
  'or_1p1_csv_resources_export_bulk'

export const ONER_V1P1_CSV_RESOURCES_IMPORT_BULK =
  'OneRoster v1.1 CSV Resources Import Bulk'
export const ONER_V1P1_CSV_RESOURCES_IMPORT_BULK_NAV_ROUTE =
  'or_1p1_csv_resources_import_bulk'

// RESOURCES REST
export const ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL =
  'OneRoster v1.1 REST Data Consumer Resources Optional'
export const ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL_NAV_ROUTE =
  'or_1p1_rest_consumer_resources_optional'

export const ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL =
  'OneRoster v1.1 REST Data Provider Resources Optional'
export const ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL_NAV_ROUTE =
  'or_1p1_rest_provider_resources_optional'
export const ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL_SHORT =
  'OneRoster v1.1 REST Data Provider Resources'

// COMMON CARTRIDGE
export const COMMON_CARTRIDGE_V1P2 = 'Common Cartridge v1.2'
export const COMMON_CARTRIDGE_V1P2_NAV_ROUTE = 'cc_1p2'

export const COMMON_CARTRIDGE_V1P3 = 'Common Cartridge v1.3'
export const COMMON_CARTRIDGE_V1P3_NAV_ROUTE = 'cc_1p3'

export const THIN_COMMON_CARTRIDGE_V1P2 = 'Thin Common Cartridge v1.2'
export const THIN_COMMON_CARTRIDGE_V1P2_NAV_ROUTE = 'tcc_1p2'

export const THIN_COMMON_CARTRIDGE_V1P3 = 'Thin Common Cartridge v1.3'
export const THIN_COMMON_CARTRIDGE_V1P3_NAV_ROUTE = 'tcc_1p3'

export type AnyNavFormatType =
  | 'or_1p1_csv_rostering_import_bulk'
  | 'or_1p1_csv_rostering_import_delta'
  | 'or_1p1_csv_rostering_export_bulk'
  | 'or_1p1_csv_rostering_export_delta'
  | 'or_1p1_rest_consumer_rostering_core'
  | 'or_1p1_rest_consumer_rostering_optional'
  | 'or_1p1_rest_provider_rostering_core'
  | 'or_1p1_rest_provider_rostering_optional'
  | 'cc_1p2'
  | 'cc_1p3'
  | 'tcc_1p2'
  | 'tcc_1p3'
  | 'or_1p1_rest_consumer_gradebook_pull_optional'
  | 'or_1p1_rest_provider_gradebook_pull_optional'
  | 'or_1p1_gradebook_import_bulk'
  | 'or_1p1_gradebook_export_bulk'
  | 'or_1p1_rest_consumer_gradebook_push_optional'
  | 'or_1p1_rest_provider_gradebook_push_optional'
  | 'or_1p1_csv_resources_export_bulk'
  | 'or_1p1_csv_resources_import_bulk'
  | 'or_1p1_rest_consumer_resources_optional'
  | 'or_1p1_rest_provider_resources_optional'
  | 'or_1p2_csv_rostering_export_bulk'

export type AnySpecFormatType =
  | 'OneRoster v1.1 CSV Rostering Import Bulk'
  | 'OneRoster v1.1 CSV Rostering Import Delta'
  | 'OneRoster v1.1 CSV Rostering Export Bulk'
  | 'OneRoster v1.1 CSV Rostering Export Delta'
  | 'OneRoster v1.1 REST Data Consumer Rostering Core'
  | 'OneRoster v1.1 REST Data Consumer Rostering Optional'
  | 'OneRoster v1.1 REST Data Provider Rostering Core'
  | 'OneRoster v1.1 REST Data Provider Rostering Optional'
  | 'Common Cartridge v1.2'
  | 'Common Cartridge v1.3'
  | 'Thin Common Cartridge v1.2'
  | 'Thin Common Cartridge v1.3'
  | 'OneRoster v1.1 REST Data Consumer Gradebook Pull Optional'
  | 'OneRoster v1.1 REST Data Provider Gradebook Pull Optional'
  | 'OneRoster v1.1 CSV Gradebook Import Bulk'
  | 'OneRoster v1.1 CSV Gradebook Export Bulk'
  | 'OneRoster v1.1 REST Data Consumer Gradebook Push Optional'
  | 'OneRoster v1.1 REST Data Provider Gradebook Push Optional'
  | 'OneRoster v1.1 CSV Resources Export Bulk'
  | 'OneRoster v1.1 CSV Resources Import Bulk'
  | 'OneRoster v1.1 REST Data Consumer Resources Optional'
  | 'OneRoster v1.1 REST Data Provider Resources Optional'
  | 'OneRoster v1.2 CSV Rostering Export Bulk'

export type AnyFormatType = AnyNavFormatType | AnySpecFormatType

type NavToFormatMap = { [key in AnyNavFormatType]: AnySpecFormatType }
export const FORMAT_NAV_ROUTE_TO_FORMAT_MAP: NavToFormatMap = {
  [ONER_V1P1_CSV_ROSTERING_IMPORT_BULK_NAV_ROUTE]:
    ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
  [ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA_NAV_ROUTE]:
    ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA,
  [ONER_V1P1_CSV_ROSTERING_EXPORT_BULK_NAV_ROUTE]:
    ONER_V1P1_CSV_ROSTERING_EXPORT_BULK,
  [ONER_V1P2_CSV_ROSTERING_EXPORT_BULK_NAV_ROUTE]:
    ONER_V1P2_CSV_ROSTERING_EXPORT_BULK,
  [ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA_NAV_ROUTE]:
    ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA,
  [ONER_V1P1_REST_CONSUMER_ROSTERING_CORE_NAV_ROUTE]:
    ONER_V1P1_REST_CONSUMER_ROSTERING_CORE,
  [ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL_NAV_ROUTE]:
    ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
  [ONER_V1P1_REST_PROVIDER_ROSTERING_CORE_NAV_ROUTE]:
    ONER_V1P1_REST_PROVIDER_ROSTERING_CORE,
  [ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL_NAV_ROUTE]:
    ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL,
  [COMMON_CARTRIDGE_V1P2_NAV_ROUTE]: COMMON_CARTRIDGE_V1P2,
  [COMMON_CARTRIDGE_V1P3_NAV_ROUTE]: COMMON_CARTRIDGE_V1P3,
  [THIN_COMMON_CARTRIDGE_V1P2_NAV_ROUTE]: THIN_COMMON_CARTRIDGE_V1P2,
  [THIN_COMMON_CARTRIDGE_V1P3_NAV_ROUTE]: THIN_COMMON_CARTRIDGE_V1P3,
  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL_NAV_ROUTE]:
    ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL,
  [ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK_NAV_ROUTE]:
    ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK,
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL_NAV_ROUTE]:
    ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL,
  [ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK_NAV_ROUTE]:
    ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK,
  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL_NAV_ROUTE]:
    ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL,
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL_NAV_ROUTE]:
    ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL,

  [ONER_V1P1_CSV_RESOURCES_EXPORT_BULK_NAV_ROUTE]:
    ONER_V1P1_CSV_RESOURCES_EXPORT_BULK,
  [ONER_V1P1_CSV_RESOURCES_IMPORT_BULK_NAV_ROUTE]:
    ONER_V1P1_CSV_RESOURCES_IMPORT_BULK,
  [ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL_NAV_ROUTE]:
    ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL,
  [ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL_NAV_ROUTE]:
    ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL,
}

type SpecFormatToNavMap = { [key in AnySpecFormatType]: AnyNavFormatType }
export const FORMAT_TO_FORMAT_NAV_ROUTE_MAP: SpecFormatToNavMap = {
  [ONER_V1P1_CSV_ROSTERING_IMPORT_BULK]:
    ONER_V1P1_CSV_ROSTERING_IMPORT_BULK_NAV_ROUTE,
  [ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA]:
    ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA_NAV_ROUTE,
  [ONER_V1P1_CSV_ROSTERING_EXPORT_BULK]:
    ONER_V1P1_CSV_ROSTERING_EXPORT_BULK_NAV_ROUTE,
  [ONER_V1P2_CSV_ROSTERING_EXPORT_BULK]:
    ONER_V1P2_CSV_ROSTERING_EXPORT_BULK_NAV_ROUTE,
  [ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA]:
    ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA_NAV_ROUTE,
  [ONER_V1P1_REST_CONSUMER_ROSTERING_CORE]:
    ONER_V1P1_REST_CONSUMER_ROSTERING_CORE_NAV_ROUTE,
  [ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL]:
    ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL_NAV_ROUTE,
  [ONER_V1P1_REST_PROVIDER_ROSTERING_CORE]:
    ONER_V1P1_REST_PROVIDER_ROSTERING_CORE_NAV_ROUTE,
  [ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL]:
    ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL_NAV_ROUTE,

  [COMMON_CARTRIDGE_V1P2]: COMMON_CARTRIDGE_V1P2_NAV_ROUTE,
  [COMMON_CARTRIDGE_V1P3]: COMMON_CARTRIDGE_V1P3_NAV_ROUTE,
  [THIN_COMMON_CARTRIDGE_V1P2]: THIN_COMMON_CARTRIDGE_V1P2_NAV_ROUTE,
  [THIN_COMMON_CARTRIDGE_V1P3]: THIN_COMMON_CARTRIDGE_V1P3_NAV_ROUTE,

  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL]:
    ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL_NAV_ROUTE,
  [ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK]:
    ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK_NAV_ROUTE,
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL]:
    ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL_NAV_ROUTE,
  [ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK]:
    ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK_NAV_ROUTE,

  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL]:
    ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL_NAV_ROUTE,
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL]:
    ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL_NAV_ROUTE,

  [ONER_V1P1_CSV_RESOURCES_EXPORT_BULK]:
    ONER_V1P1_CSV_RESOURCES_EXPORT_BULK_NAV_ROUTE,
  [ONER_V1P1_CSV_RESOURCES_IMPORT_BULK]:
    ONER_V1P1_CSV_RESOURCES_IMPORT_BULK_NAV_ROUTE,
  [ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL]:
    ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL_NAV_ROUTE,
  [ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL]:
    ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL_NAV_ROUTE,
}

type AnySpecTypeToSpecFormatMap = { [key in AnyFormatType]: AnySpecFormatType }
export const ANY_FORMAT_TO_SPEC_FORMAT_MAP: AnySpecTypeToSpecFormatMap = {
  [ONER_V1P1_CSV_ROSTERING_IMPORT_BULK]: ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
  [ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA]: ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA,
  [ONER_V1P1_CSV_ROSTERING_EXPORT_BULK]: ONER_V1P1_CSV_ROSTERING_EXPORT_BULK,
  [ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA]: ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA,
  [ONER_V1P2_CSV_ROSTERING_EXPORT_BULK]: ONER_V1P2_CSV_ROSTERING_EXPORT_BULK,

  [ONER_V1P1_REST_CONSUMER_ROSTERING_CORE_NAV_ROUTE]:
    ONER_V1P1_REST_CONSUMER_ROSTERING_CORE,
  [ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL_NAV_ROUTE]:
    ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
  [ONER_V1P1_REST_PROVIDER_ROSTERING_CORE_NAV_ROUTE]:
    ONER_V1P1_REST_PROVIDER_ROSTERING_CORE,
  [ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL_NAV_ROUTE]:
    ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL,

  [ONER_V1P1_REST_CONSUMER_ROSTERING_CORE]:
    ONER_V1P1_REST_CONSUMER_ROSTERING_CORE,
  [ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL]:
    ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
  [ONER_V1P1_REST_PROVIDER_ROSTERING_CORE]:
    ONER_V1P1_REST_PROVIDER_ROSTERING_CORE,
  [ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL]:
    ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL,
  [ONER_V1P1_CSV_ROSTERING_IMPORT_BULK_NAV_ROUTE]:
    ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
  [ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA_NAV_ROUTE]:
    ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA,
  [ONER_V1P1_CSV_ROSTERING_EXPORT_BULK_NAV_ROUTE]:
    ONER_V1P1_CSV_ROSTERING_EXPORT_BULK,
  [ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA_NAV_ROUTE]:
    ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA,
  [ONER_V1P2_CSV_ROSTERING_EXPORT_BULK_NAV_ROUTE]:
    ONER_V1P2_CSV_ROSTERING_EXPORT_BULK,

  [COMMON_CARTRIDGE_V1P2_NAV_ROUTE]: COMMON_CARTRIDGE_V1P2,
  [COMMON_CARTRIDGE_V1P3_NAV_ROUTE]: COMMON_CARTRIDGE_V1P3,
  [THIN_COMMON_CARTRIDGE_V1P2_NAV_ROUTE]: THIN_COMMON_CARTRIDGE_V1P2,
  [THIN_COMMON_CARTRIDGE_V1P3_NAV_ROUTE]: THIN_COMMON_CARTRIDGE_V1P3,
  [COMMON_CARTRIDGE_V1P2]: COMMON_CARTRIDGE_V1P2,
  [COMMON_CARTRIDGE_V1P3]: COMMON_CARTRIDGE_V1P3,
  [THIN_COMMON_CARTRIDGE_V1P2]: THIN_COMMON_CARTRIDGE_V1P2,
  [THIN_COMMON_CARTRIDGE_V1P3]: THIN_COMMON_CARTRIDGE_V1P3,

  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL]:
    ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL,
  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL_NAV_ROUTE]:
    ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL,
  [ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK]: ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK,
  [ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK_NAV_ROUTE]:
    ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK,
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL]:
    ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL,
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL_NAV_ROUTE]:
    ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL,
  [ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK]: ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK,
  [ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK_NAV_ROUTE]:
    ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK,
  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL]:
    ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL,
  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL_NAV_ROUTE]:
    ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL,
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL]:
    ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL,
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL_NAV_ROUTE]:
    ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL,

  [ONER_V1P1_CSV_RESOURCES_EXPORT_BULK_NAV_ROUTE]:
    ONER_V1P1_CSV_RESOURCES_EXPORT_BULK,
  [ONER_V1P1_CSV_RESOURCES_IMPORT_BULK_NAV_ROUTE]:
    ONER_V1P1_CSV_RESOURCES_IMPORT_BULK,
  [ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL_NAV_ROUTE]:
    ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL,
  [ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL_NAV_ROUTE]:
    ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL,
  [ONER_V1P1_CSV_RESOURCES_EXPORT_BULK]: ONER_V1P1_CSV_RESOURCES_EXPORT_BULK,
  [ONER_V1P1_CSV_RESOURCES_IMPORT_BULK]: ONER_V1P1_CSV_RESOURCES_IMPORT_BULK,
  [ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL]:
    ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL,
  [ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL]:
    ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL,
}

export const FULL_FORMATS = [ONER_1P1_BULK, ONER_1P1_DELTA, ONER_1P2_BULK]

export const FULL_FORMAT_TO_URL: { [key: string]: string } = {
  [ONER_1P1_BULK]: 'specs/bulk/OneRoster/1p1',
  [ONER_1P2_BULK]: 'specs/bulk/OneRoster/1p2',
  [ONER_1P1_DELTA]: 'specs/delta/OneRoster/1p1',
}

export const SPECIFIC_TO_FULL_FORMAT_MAP: { [key: string]: string } = {
  [ONER_V1P1_CSV_ROSTERING_IMPORT_BULK]: ONER_1P1_BULK,
  [ONER_V1P1_CSV_ROSTERING_EXPORT_BULK]: ONER_1P1_BULK,
  [ONER_V1P2_CSV_ROSTERING_EXPORT_BULK]: ONER_1P2_BULK,
  [ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA]: ONER_1P1_DELTA,
  [ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA]: ONER_1P1_DELTA,
  [ONER_V1P1_REST_CONSUMER_ROSTERING_CORE]:
    ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
  [ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL]:
    ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
  [ONER_V1P1_REST_PROVIDER_ROSTERING_CORE]:
    ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
  [ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL]:
    ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
  [ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK]: ONER_1P1_BULK,
  [ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK]: ONER_1P1_BULK,
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL]:
    ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL,
  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL]:
    ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL,
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL]:
    ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL,
  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL]:
    ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL,
  [ONER_V1P1_CSV_RESOURCES_IMPORT_BULK]: ONER_1P1_BULK,
  [ONER_V1P1_CSV_RESOURCES_EXPORT_BULK]: ONER_1P1_BULK,
  [ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL]:
    ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL,
  [ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL]:
    ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL,
}

type SpecFormatInverseMap = { [key in AnySpecFormatType]: AnySpecFormatType }
export const FORMAT_TO_INVERSE_MAP: SpecFormatInverseMap = {
  [ONER_V1P1_CSV_ROSTERING_IMPORT_BULK]: ONER_V1P1_CSV_ROSTERING_EXPORT_BULK,
  [ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA]: ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA,
  [ONER_V1P1_CSV_ROSTERING_EXPORT_BULK]: ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
  [ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA]: ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA,
  [ONER_V1P2_CSV_ROSTERING_EXPORT_BULK]: ONER_V1P2_CSV_ROSTERING_EXPORT_BULK,

  [ONER_V1P1_REST_CONSUMER_ROSTERING_CORE]:
    ONER_V1P1_REST_PROVIDER_ROSTERING_CORE,
  [ONER_V1P1_REST_PROVIDER_ROSTERING_CORE]:
    ONER_V1P1_REST_CONSUMER_ROSTERING_CORE,
  [ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL]:
    ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL,
  [ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL]:
    ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,

  [COMMON_CARTRIDGE_V1P2]: COMMON_CARTRIDGE_V1P2,
  [COMMON_CARTRIDGE_V1P3]: COMMON_CARTRIDGE_V1P3,
  [THIN_COMMON_CARTRIDGE_V1P2]: THIN_COMMON_CARTRIDGE_V1P2,
  [THIN_COMMON_CARTRIDGE_V1P3]: THIN_COMMON_CARTRIDGE_V1P3,

  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL]:
    ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL,
  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL]:
    ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL,
  [ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK]: ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK,
  [ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK]: ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK,
  // Intentionally pointing to pull - No specification for Push is defined in
  // the backend and they are the same anyways
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL]:
    ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL,
  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL]:
    ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL,

  [ONER_V1P1_CSV_RESOURCES_IMPORT_BULK]: ONER_V1P1_CSV_ROSTERING_EXPORT_BULK,
  [ONER_V1P1_CSV_RESOURCES_EXPORT_BULK]: ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
  [ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL]:
    ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL,
  [ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL]:
    ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL,
}

type AnySpecTypeToString = { [key in AnySpecFormatType]: string }
export const FORMAT_TO_SPECIFICATION_NAME: AnySpecTypeToString = {
  [ONER_V1P1_CSV_ROSTERING_IMPORT_BULK]: ONE_ROSTER_SPEC,
  [ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA]: ONE_ROSTER_SPEC,
  [ONER_V1P1_CSV_ROSTERING_EXPORT_BULK]: ONE_ROSTER_SPEC,
  [ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA]: ONE_ROSTER_SPEC,
  [ONER_V1P1_REST_CONSUMER_ROSTERING_CORE]: ONE_ROSTER_SPEC,
  [ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL]: ONE_ROSTER_SPEC,
  [ONER_V1P1_REST_PROVIDER_ROSTERING_CORE]: ONE_ROSTER_SPEC,
  [ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL]: ONE_ROSTER_SPEC,
  [ONER_V1P2_CSV_ROSTERING_EXPORT_BULK]: ONE_ROSTER_SPEC,

  [COMMON_CARTRIDGE_V1P2]: COMMON_CARTRIDGE_SPEC,
  [COMMON_CARTRIDGE_V1P3]: COMMON_CARTRIDGE_SPEC,
  [THIN_COMMON_CARTRIDGE_V1P2]: COMMON_CARTRIDGE_SPEC,
  [THIN_COMMON_CARTRIDGE_V1P3]: COMMON_CARTRIDGE_SPEC,

  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL]: ONE_ROSTER_SPEC,
  [ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK]: ONE_ROSTER_SPEC,
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL]: ONE_ROSTER_SPEC,
  [ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK]: ONE_ROSTER_SPEC,
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL]: ONE_ROSTER_SPEC,
  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL]: ONE_ROSTER_SPEC,

  [ONER_V1P1_CSV_RESOURCES_IMPORT_BULK]: ONE_ROSTER_SPEC,
  [ONER_V1P1_CSV_RESOURCES_EXPORT_BULK]: ONE_ROSTER_SPEC,
  [ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL]: ONE_ROSTER_SPEC,
  [ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL]: ONE_ROSTER_SPEC,
}
export const OR_FORMAT_TO_SPEC_SUBTYPE: AnySpecTypeToString = {
  [ONER_V1P1_CSV_ROSTERING_IMPORT_BULK]: ONE_ROSTER_ROSTERING_SPEC,
  [ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA]: ONE_ROSTER_ROSTERING_SPEC,
  [ONER_V1P1_CSV_ROSTERING_EXPORT_BULK]: ONE_ROSTER_ROSTERING_SPEC,
  [ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA]: ONE_ROSTER_ROSTERING_SPEC,
  [ONER_V1P1_REST_CONSUMER_ROSTERING_CORE]: ONE_ROSTER_ROSTERING_SPEC,
  [ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL]: ONE_ROSTER_ROSTERING_SPEC,
  [ONER_V1P1_REST_PROVIDER_ROSTERING_CORE]: ONE_ROSTER_ROSTERING_SPEC,
  [ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL]: ONE_ROSTER_ROSTERING_SPEC,
  [ONER_V1P2_CSV_ROSTERING_EXPORT_BULK]: ONE_ROSTER_ROSTERING_SPEC,

  [COMMON_CARTRIDGE_V1P2]: COMMON_CARTRIDGE_SPEC,
  [COMMON_CARTRIDGE_V1P3]: COMMON_CARTRIDGE_SPEC,
  [THIN_COMMON_CARTRIDGE_V1P2]: COMMON_CARTRIDGE_SPEC,
  [THIN_COMMON_CARTRIDGE_V1P3]: COMMON_CARTRIDGE_SPEC,

  [ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK]: ONE_ROSTER_GRADEBOOK_SPEC,
  [ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK]: ONE_ROSTER_GRADEBOOK_SPEC,
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL]:
    ONE_ROSTER_GRADEBOOK_PULL_SPEC,
  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL]:
    ONE_ROSTER_GRADEBOOK_PULL_SPEC,
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL]:
    ONE_ROSTER_GRADEBOOK_PUSH_SPEC,
  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL]:
    ONE_ROSTER_GRADEBOOK_PUSH_SPEC,

  [ONER_V1P1_CSV_RESOURCES_IMPORT_BULK]: ONE_ROSTER_RESOURCES_SPEC,
  [ONER_V1P1_CSV_RESOURCES_EXPORT_BULK]: ONE_ROSTER_RESOURCES_SPEC,
  [ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL]: ONE_ROSTER_RESOURCES_SPEC,
  [ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL]: ONE_ROSTER_RESOURCES_SPEC,
}
export const FORMAT_TO_SPECIFICATION_VERSION: AnySpecTypeToString = {
  [ONER_V1P1_CSV_ROSTERING_IMPORT_BULK]: ONE_ROSTER_SPEC_V1P1,
  [ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA]: ONE_ROSTER_SPEC_V1P1,
  [ONER_V1P1_CSV_ROSTERING_EXPORT_BULK]: ONE_ROSTER_SPEC_V1P1,
  [ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA]: ONE_ROSTER_SPEC_V1P1,
  [ONER_V1P1_REST_CONSUMER_ROSTERING_CORE]: ONE_ROSTER_SPEC_V1P1,
  [ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL]: ONE_ROSTER_SPEC_V1P1,
  [ONER_V1P1_REST_PROVIDER_ROSTERING_CORE]: ONE_ROSTER_SPEC_V1P1,
  [ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL]: ONE_ROSTER_SPEC_V1P1,
  [ONER_V1P2_CSV_ROSTERING_EXPORT_BULK]: ONE_ROSTER_SPEC_V1P2,

  [COMMON_CARTRIDGE_V1P2]: COMMON_CARTRIDGE_SPEC_V1P2,
  [COMMON_CARTRIDGE_V1P3]: COMMON_CARTRIDGE_SPEC_V1P3,
  [THIN_COMMON_CARTRIDGE_V1P2]: COMMON_CARTRIDGE_SPEC_V1P2,
  [THIN_COMMON_CARTRIDGE_V1P3]: COMMON_CARTRIDGE_SPEC_V1P3,

  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL]: ONE_ROSTER_SPEC_V1P1,
  [ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK]: ONE_ROSTER_SPEC_V1P1,
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL]: ONE_ROSTER_SPEC_V1P1,
  [ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK]: ONE_ROSTER_SPEC_V1P1,
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL]: ONE_ROSTER_SPEC_V1P1,
  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL]: ONE_ROSTER_SPEC_V1P1,

  [ONER_V1P1_CSV_RESOURCES_IMPORT_BULK]: ONE_ROSTER_SPEC_V1P1,
  [ONER_V1P1_CSV_RESOURCES_EXPORT_BULK]: ONE_ROSTER_SPEC_V1P1,
  [ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL]: ONE_ROSTER_SPEC_V1P1,
  [ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL]: ONE_ROSTER_SPEC_V1P1,
}
export const FORMAT_TO_DISPLAY_NAME: AnySpecTypeToString = {
  [ONER_V1P1_CSV_ROSTERING_IMPORT_BULK]: 'OneRoster v1.1 CSV Rostering',
  [ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA]: 'OneRoster v1.1 CSV Rostering',
  [ONER_V1P1_CSV_ROSTERING_EXPORT_BULK]: 'OneRoster v1.1 CSV Rostering',
  [ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA]: 'OneRoster v1.1 CSV Rostering',
  [ONER_V1P1_REST_CONSUMER_ROSTERING_CORE]: 'OneRoster v1.1 REST Rostering',
  [ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL]: 'OneRoster v1.1 REST Rostering',
  [ONER_V1P1_REST_PROVIDER_ROSTERING_CORE]: 'OneRoster v1.1 REST Rostering',
  [ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL]: 'OneRoster v1.1 REST Rostering',
  [ONER_V1P2_CSV_ROSTERING_EXPORT_BULK]: 'OneRoster v1.2 CSV Rostering',

  [COMMON_CARTRIDGE_V1P2]: 'Common Cartridge v1.2',
  [COMMON_CARTRIDGE_V1P3]: 'Common Cartridge v1.3',
  [THIN_COMMON_CARTRIDGE_V1P2]: 'Thin Common Cartridge v1.2',
  [THIN_COMMON_CARTRIDGE_V1P3]: 'Thin Common Cartridge v1.3',

  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL]:
    'OneRoster v1.1 Rest Gradebook',
  [ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK]: 'OneRoster v1.1 CSV Gradebook',
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL]:
    'OneRoster v1.1 Rest Gradebook',
  [ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK]: 'OneRoster v1.1 CSV Gradebook',
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL]:
    'OneRoster v1.1 CSV Gradebook',
  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL]:
    'OneRoster v1.1 CSV Gradebook',

  [ONER_V1P1_CSV_RESOURCES_IMPORT_BULK]: 'OneRoster v1.1 CSV Resources',
  [ONER_V1P1_CSV_RESOURCES_EXPORT_BULK]: 'OneRoster v1.1 CSV Resources',
  [ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL]: 'OneRoster v1.1 REST Resources',
  [ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL]: 'OneRoster v1.1 REST Resources',
}
export const FORMAT_TO_DISPLAY_SHORT_NAME: AnySpecTypeToString = {
  [ONER_V1P1_CSV_ROSTERING_IMPORT_BULK]: 'CSV Consumer',
  [ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA]: 'CSV Consumer',
  [ONER_V1P1_CSV_ROSTERING_EXPORT_BULK]: 'CSV Provider',
  [ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA]: 'CSV Provider',
  [ONER_V1P2_CSV_ROSTERING_EXPORT_BULK]: 'CSV Provider',
  [ONER_V1P1_REST_CONSUMER_ROSTERING_CORE]: 'REST Consumer',
  [ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL]: 'REST Consumer',
  [ONER_V1P1_REST_PROVIDER_ROSTERING_CORE]: 'REST Provider',
  [ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL]: 'REST Provider',

  [COMMON_CARTRIDGE_V1P2]: 'Common Cartridge',
  [COMMON_CARTRIDGE_V1P3]: 'Common Cartridge',
  [THIN_COMMON_CARTRIDGE_V1P2]: 'Thin Common Cartridge',
  [THIN_COMMON_CARTRIDGE_V1P3]: 'Thin Common Cartridge',

  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL]: 'REST Consumer',
  [ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK]: 'CSV Consumer',
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL]: 'REST Provider',
  [ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK]: 'CSV Provider',
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL]: 'REST Provider',
  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL]: 'REST Consumer',

  [ONER_V1P1_CSV_RESOURCES_IMPORT_BULK]: 'CSV Consumer',
  [ONER_V1P1_CSV_RESOURCES_EXPORT_BULK]: 'CSV Provider',
  [ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL]: 'REST Consumer',
  [ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL]: 'REST Provider',
}
export const FORMAT_TO_DISPLAY_SHORTEST_NAME: AnySpecTypeToString = {
  [ONER_V1P1_CSV_ROSTERING_IMPORT_BULK]: 'CSV C',
  [ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA]: 'CSV C',
  [ONER_V1P1_CSV_ROSTERING_EXPORT_BULK]: 'CSV P',
  [ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA]: 'CSV P',
  [ONER_V1P2_CSV_ROSTERING_EXPORT_BULK]: 'CSV P',
  [ONER_V1P1_REST_CONSUMER_ROSTERING_CORE]: 'REST C',
  [ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL]: 'REST C',
  [ONER_V1P1_REST_PROVIDER_ROSTERING_CORE]: 'REST P',
  [ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL]: 'REST P',

  [COMMON_CARTRIDGE_V1P2]: 'CC',
  [COMMON_CARTRIDGE_V1P3]: 'CC',
  [THIN_COMMON_CARTRIDGE_V1P2]: 'TCC',
  [THIN_COMMON_CARTRIDGE_V1P3]: 'TCC',

  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL]: 'REST C',
  [ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK]: 'CSV C',
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL]: 'REST P',
  [ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK]: 'CSV P',
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL]: 'REST P',
  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL]: 'REST C',

  [ONER_V1P1_CSV_RESOURCES_IMPORT_BULK]: 'CSV C',
  [ONER_V1P1_CSV_RESOURCES_EXPORT_BULK]: 'CSV P',
  [ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL]: 'REST C',
  [ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL]: 'REST P',
}
export const FORMAT_TO_DISPLAY_FORMAT_BASE_NAME: AnySpecTypeToString = {
  [ONER_V1P1_CSV_ROSTERING_IMPORT_BULK]: 'CSV',
  [ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA]: 'CSV',
  [ONER_V1P1_CSV_ROSTERING_EXPORT_BULK]: 'CSV',
  [ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA]: 'CSV',
  [ONER_V1P2_CSV_ROSTERING_EXPORT_BULK]: 'CSV',
  [ONER_V1P1_REST_CONSUMER_ROSTERING_CORE]: 'REST',
  [ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL]: 'REST',
  [ONER_V1P1_REST_PROVIDER_ROSTERING_CORE]: 'REST',
  [ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL]: 'REST',

  [COMMON_CARTRIDGE_V1P2]: 'CC',
  [COMMON_CARTRIDGE_V1P3]: 'CC',
  [THIN_COMMON_CARTRIDGE_V1P2]: 'TCC',
  [THIN_COMMON_CARTRIDGE_V1P3]: 'TCC',

  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL]: 'REST',
  [ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK]: 'CSV',
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL]: 'REST',
  [ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK]: 'CSV',
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL]: 'REST',
  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL]: 'REST',

  [ONER_V1P1_CSV_RESOURCES_IMPORT_BULK]: 'CSV',
  [ONER_V1P1_CSV_RESOURCES_EXPORT_BULK]: 'CSV',
  [ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL]: 'REST',
  [ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL]: 'REST',
}

export const FORMAT_TYPE_TO_DISPLAY_NAME: AnySpecTypeToString = {
  [ONER_V1P1_CSV_ROSTERING_IMPORT_BULK]: 'Bulk',
  [ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA]: 'Delta',
  [ONER_V1P1_CSV_ROSTERING_EXPORT_BULK]: 'Bulk',
  [ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA]: 'Delta',
  [ONER_V1P2_CSV_ROSTERING_EXPORT_BULK]: 'Bulk',
  [ONER_V1P1_REST_CONSUMER_ROSTERING_CORE]: 'Core',
  [ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL]: 'Optional',
  [ONER_V1P1_REST_PROVIDER_ROSTERING_CORE]: 'Core',
  [ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL]: 'Optional',

  [COMMON_CARTRIDGE_V1P2]: 'CC',
  [COMMON_CARTRIDGE_V1P3]: 'CC',
  [THIN_COMMON_CARTRIDGE_V1P2]: 'TCC',
  [THIN_COMMON_CARTRIDGE_V1P3]: 'TCC',

  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL]: 'Optional',
  [ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK]: 'Bulk',
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL]: 'Optional',
  [ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK]: 'Bulk',
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL]: 'Optional',
  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL]: 'Optional',

  [ONER_V1P1_CSV_RESOURCES_IMPORT_BULK]: 'Bulk',
  [ONER_V1P1_CSV_RESOURCES_EXPORT_BULK]: 'Bulk',
  [ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL]: 'Optional',
  [ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL]: 'Optional',
}

export const SUPPORTED_1EDTECH_FORMATS: AnySpecFormatType[] = [
  ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL,
  ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
  ONER_V1P1_CSV_ROSTERING_EXPORT_BULK,
  ONER_V1P2_CSV_ROSTERING_EXPORT_BULK,

  COMMON_CARTRIDGE_V1P2,
  COMMON_CARTRIDGE_V1P3,
  THIN_COMMON_CARTRIDGE_V1P2,
  THIN_COMMON_CARTRIDGE_V1P3,

  ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL,
  ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK,
  ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL,
  ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL,

  ONER_V1P1_CSV_RESOURCES_IMPORT_BULK,
  ONER_V1P1_CSV_RESOURCES_EXPORT_BULK,
  ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL,
]

export const FORMAT_TO_ICON_MAP: AnySpecTypeToString = {
  [ONER_V1P1_CSV_ROSTERING_IMPORT_BULK]: 'fas fa-file-csv',
  [ONER_V1P1_CSV_ROSTERING_IMPORT_DELTA]: 'fas fa-file-csv',
  [ONER_V1P1_CSV_ROSTERING_EXPORT_BULK]: 'fas fa-file-csv',
  [ONER_V1P1_CSV_ROSTERING_EXPORT_DELTA]: 'fas fa-file-csv',
  [ONER_V1P2_CSV_ROSTERING_EXPORT_BULK]: 'fas fa-file-csv',
  [ONER_V1P1_REST_CONSUMER_ROSTERING_CORE]: 'fas fa-project-diagram',
  [ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL]: 'fas fa-project-diagram',
  [ONER_V1P1_REST_PROVIDER_ROSTERING_CORE]: 'fas fa-project-diagram',
  [ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL]: 'fas fa-project-diagram',

  [COMMON_CARTRIDGE_V1P2]: 'fas fa-layer-group',
  [COMMON_CARTRIDGE_V1P3]: 'fas fa-layer-group',
  [THIN_COMMON_CARTRIDGE_V1P2]: 'fas fa-layer-group',
  [THIN_COMMON_CARTRIDGE_V1P3]: 'fas fa-layer-group',

  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL]: 'fas fa-project-diagram',
  [ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK]: 'fas fa-file-csv',
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL]: 'fas fa-project-diagram',
  [ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK]: 'fas fa-file-csv',
  [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL]: 'fas fa-project-diagram',
  [ONER_V1P1_REST_CONSUMER_GRADEBOOK_PUSH_OPTIONAL]: 'fas fa-project-diagram',

  [ONER_V1P1_CSV_RESOURCES_IMPORT_BULK]: 'fas fa-file-csv',
  [ONER_V1P1_CSV_RESOURCES_EXPORT_BULK]: 'fas fa-file-csv',
  [ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL]: 'fas fa-project-diagram',
  [ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL]: 'fas fa-project-diagram',
}
