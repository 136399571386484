import { DEFAULT_REQUEST_TIMEOUT } from './constants'
import { performRequest } from './performRequest'

export const getRequest = (
  url: string,
  params = {},
  headers = {},
  returnRawResponse = false,
  config = {},
) =>
  performRequest({
    method: 'GET',
    url,
    params,
    headers,
    returnRawResponse,
    config,
  })

export const postRequest = (
  url: string,
  data = {},
  params = {},
  headers = {},
  timeout = DEFAULT_REQUEST_TIMEOUT,
  returnRawResponse = false,
  config = {},
) =>
  performRequest({
    method: 'POST',
    url,
    data,
    params,
    headers,
    timeout,
    returnRawResponse,
    config,
  })

export const putRequest = (
  url: string,
  data = {},
  headers = {},
  returnRawResponse = false,
) =>
  performRequest({
    method: 'PUT',
    url,
    data,
    headers,
    returnRawResponse,
  })

export const deleteRequest = (
  url: string,
  data = {},
  params = {},
  headers = {},
) =>
  performRequest({
    method: 'DELETE',
    url,
    data,
    params,
    headers,
  })
