import React from 'react'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

import { View, ExpansionPanel, Spinner } from 'lib/components'
import ModelReportDetails from 'domains/modeling/components/ModelReportDetails'
import {
  ICSVFileComparisonReport,
  ICSVComparisonReport,
} from 'domains/comparisons/models/ICSVComparisonReport'
import { ComparisonFileReport } from 'domains/comparisons/components/ComparisonFileReport'
import ReportExpansionHeader from 'domains/reports/components/ReportExpansionHeader'
import ComparisonReportTopSection from 'domains/comparisons/components/ComparisonReportTopSection'
import ComparisonReportDetailsHeader from 'domains/comparisons/components/ComparisonReport/ComparisonReportDetailsHeader'
import useFullComparison from 'domains/comparisons/hooks/useFullComparison'
import { utils } from '@ims/1edtech-frontend-common'
import useAccordionHeaderState from 'domains/reports/hooks/useAccordionHeaderState'
import { useSelector } from 'react-redux'
import { RootState } from 'lib/store/rootReducer'
import {
  myOrganizationPropSelector,
  isMyOrgFreemiumSelector,
} from 'domains/authentication/selectors/organizations'
import GenericError from 'domains/application/components/GenericError'
import IComparison from 'domains/comparisons/models/IComparison'
import ICSVSpecification from 'domains/specifications/models/ICSVSpecification'
import IAccordionHeaderState from 'domains/characterizations/models/IAccordionHeaderState'
import FreemiumLockedComparisonReport from 'domains/comparisons/components/ComparisonReport/FreemiumLockedComparisonReport'

export default function OR1P1CSVComparisonReportScreen(props: any) {
  const [
    comparisonData,
    comparisonReportData,
    specificationData,
    product,
    pending,
  ] = useFullComparison(props)

  const [
    fileStates,
    ,
    onToggleHeader,
    onExpandAll,
    onCollapseAll,
  ] = useAccordionHeaderState(
    R.pathOr([], ['fileComparisons'], comparisonReportData).map((s: any) => ({
      ...s,
      name: s.filename,
    })),
  )

  const isFreemium = useSelector((state: RootState) =>
    isMyOrgFreemiumSelector(state, props),
  )
  const referrerId = useSelector((state: RootState) =>
    myOrganizationPropSelector('referrerId')(state, props),
  )

  const onFileRequestChange = (file: string) => () => {
    onToggleHeader(file)
  }

  const renderFile = (headerState: IAccordionHeaderState) => {
    const file = headerState.data as ICSVFileComparisonReport
    return (
      <ExpansionPanel
        key={file.filename}
        isOpen={headerState.open}
        onRequestChange={onFileRequestChange(file.filename)}
        summary={
          <ReportExpansionHeader
            name={file.filename}
            status={file.status}
            open={headerState.open}
          />
        }
        details={
          <ComparisonFileReport
            specification={specification}
            fileComparison={file}
          />
        }
      />
    )
  }

  if (pending) {
    return (
      <View variant="screen">
        <Spinner centered={true} />
      </View>
    )
  }

  if (
    !utils.hasValue(comparisonData) ||
    (!R.path(['fileComparisons'], comparisonReportData) && !isFreemium)
  ) {
    return <GenericError />
  }

  const comparison = comparisonData as IComparison
  const report = comparisonReportData as ICSVComparisonReport
  const specification = specificationData as ICSVSpecification

  const isNonReferral =
    isFreemium &&
    referrerId !== records.modules.getRefId(comparison, 'supplierId', -1)
  const reportDetail = isNonReferral ? (
    <FreemiumLockedComparisonReport />
  ) : (
    <View variant="paper">
      <ComparisonReportDetailsHeader
        onExpand={onExpandAll}
        onCollapse={onCollapseAll}
        data-test="comparison-report-files"
      />

      <ModelReportDetails report={report} />

      {fileStates.map(renderFile)}
    </View>
  )

  return (
    <View px={[1, 3]}>
      <ComparisonReportTopSection
        comparison={comparison}
        report={report}
        product={product}
        isNonReferralComparison={isNonReferral}
      />
      {reportDetail}
    </View>
  )
}
