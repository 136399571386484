import * as R from 'ramda'
import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

import { ICSVConsumerFile } from 'domains/modeling/models/ICSVConsumerModel'
import { ICSVConsumerFileColumn } from 'domains/modeling/models/ICSVConsumerModel'

export const CSV_C_MODEL_CHARACTERIZATION_REPORTS_RECORD =
  'csvCModelCharacterizationReports'

const csvConsumerModelCharacterizations: records.modules.IModule = {
  record: CSV_C_MODEL_CHARACTERIZATION_REPORTS_RECORD,
  noResponseDataKey: true,
  schema: new normalizr.schema.Entity(
    CSV_C_MODEL_CHARACTERIZATION_REPORTS_RECORD,
    {},
    {
      processStrategy: R.compose(
        (model) => ({
          ...model,
          files: model.fileComparisons
            .sort(R.ascend(R.prop<string>('filename')))
            .map((file: ICSVConsumerFile) => ({
              ...file,
              columns: R.sort<ICSVConsumerFileColumn>(
                R.ascend(R.prop<string>('position')),
                R.propOr([], 'columns', file),
              ),
            })),
        }),
        records.modules.utils.fixIdAttribute,
      ),
      idAttribute: 'comparisonId',
    },
  ),
  api: {
    create: () => 'comparisons/csv/report',
    get: (id) => `comparisons/csv/report/${id}`,
    getList: (page) => `comparisons/csv/report?page=${page}`,
    update: (id) => `comparisons/csv/report/${id}`,
    delete: (id) => `comparisons/csv/report/${id}`,
  },
}

export default csvConsumerModelCharacterizations
