import * as R from 'ramda'
import { createSelector } from 'reselect'
import { records } from '@ims/1edtech-frontend-common'

import { RootState } from 'lib/store/rootReducer'
import { USER_PREFERENCES_RECORD } from 'lib/records/modules/userPreferences'
import IUserPreferences, {
  NotificationPreference,
} from 'domains/users/models/IUserPreferences'
import { applicationPropSelector } from 'domains/application/selectors/application'

export const extractNotificationPreference = (prefs: IUserPreferences) =>
  R.pathOr(
    'NONE',
    ['notifications', 'inApp', 'preference'],
    prefs,
  ) as NotificationPreference

export const extractNotificationPreferenceFromAll = (id: any, prefs: any) => {
  const userPreferences = R.pathOr(false, [id], prefs)
  if (!userPreferences) {
    return 'NONE'
  }
  return extractNotificationPreference(userPreferences)
}

export const notificationPreferenceSelector = (
  getId: records.entitiesSelectors.getIdType,
) =>
  createSelector<RootState, any, any, any, NotificationPreference>(
    records.entitiesSelectors.idSelector(getId),
    records.entitiesSelectors.entityListSelector(USER_PREFERENCES_RECORD),
    extractNotificationPreferenceFromAll,
  )

export const myNotificationPreferenceSelector = createSelector(
  applicationPropSelector('me'),
  records.entitiesSelectors.entityListSelector(USER_PREFERENCES_RECORD),
  extractNotificationPreferenceFromAll,
)
