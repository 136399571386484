import { records } from '@ims/1edtech-frontend-common'

import { COMPARISONS_RECORD } from 'lib/records/modules/comparisons'
import store from 'lib/store'
import IComparison from 'domains/comparisons/models/IComparison'
import { deleteRequest } from 'lib/api/api'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import trackComparisonAnalytics from 'domains/comparisons/utils/trackComparisonAnalytics'

export default async function deleteComparison(id: string | number) {
  const comparison = records.entitiesSelectors.entityByIdSelector(
    COMPARISONS_RECORD,
    id,
  )(store.getState()) as IComparison

  let response
  if (comparison.zombie) {
    response = await deleteRequest(`zombies/${id}`)
  } else {
    response = await deleteRequest(`comparisons/${id}`)
  }

  if (response.success) {
    const orgId = myOrganizationIdSelector(store.getState(), {})
    store.dispatch(
      records.relatedRecordActions.deleteRelatedRecordItem(
        COMPARISONS_RECORD,
        id,
        orgId,
      ),
    )
    trackComparisonAnalytics(
      `${comparison.zombie ? 'zombie_' : ''}comparison_deleted`,
      { id },
    )
  }
}
