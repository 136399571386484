import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

export const SOURCES_RECORD = 'sources'

const sources: records.modules.IModule = {
  record: SOURCES_RECORD,
  schema: new normalizr.schema.Entity(
    SOURCES_RECORD,
    {},
    records.modules.utils.getSchemaOptions(),
  ),
  api: {
    create: () => 'sources',
    get: (id) => `sources/${id}`,
    getList: () => 'sources',
    update: (id) => `sources/${id}`,
    delete: (id) => `sources/${id}`,
  },
}

export default sources
