import React from 'react'
import { records } from '@ims/1edtech-frontend-common'

import { ListPlainTextCell } from 'lib/components'
import { isRosteredByOtherProduct } from 'domains/products/utils/products'
import ProductRosteringToolLink from 'domains/products/components/ProductRosteringToolLink'
import IProduct from 'domains/products/models/IProduct'
import ProductCatalogExpandedRowCell from 'domains/products/components/ProductCatalog/ProductCatalogExpandedRowCell'

const textProps = {
  pl: 2,
  textAlign: 'left',
  overflow: 'ellipsis',
  maxWidth: '174px',
}
interface IProps extends IProduct {
  isOpen?: boolean
}

export default function ProductCatalogRosteringCell(props: IProps) {
  const content = isRosteredByOtherProduct(props) ? (
    <ProductRosteringToolLink
      id={props.id}
      rosteringToolId={records.modules.getRefId(props, 'rosteringTool')}
      rosteringToolName={props.rosteringToolName!}
      format={props.format!}
      textProps={{
        ...textProps,
        color: 'tertiary',
        fontWeight: 700,
      }}
    />
  ) : (
    <ListPlainTextCell>Direct</ListPlainTextCell>
  )

  return (
    <ProductCatalogExpandedRowCell {...props} borderBottom={props.isOpen}>
      {content}
    </ProductCatalogExpandedRowCell>
  )
}
