import React, { HTMLAttributes } from 'react'
import * as SS from 'styled-system'
import * as CSS from 'csstype'
import styled from 'styled-components'
import clsx from 'clsx'

import { boxSizing, cursorStyle, flexibleStyle } from 'lib/components/View'
import colors from 'lib/styles/colors'

export interface IProps
  extends HTMLAttributes<HTMLDivElement>,
    SS.AlignContentProps,
    SS.AlignItemsProps,
    SS.AlignSelfProps,
    SS.BackgroundColorProps,
    SS.BorderColorProps,
    SS.BorderProps,
    SS.BorderRadiusProps,
    SS.BordersProps,
    SS.BottomProps,
    SS.ColorProps,
    SS.DisplayProps,
    SS.FlexBasisProps,
    SS.FlexDirectionProps,
    SS.FlexProps,
    SS.FlexWrapProps,
    SS.FontSizeProps,
    SS.FontStyleProps,
    SS.FontWeightProps,
    SS.GridAutoFlowProps,
    SS.GridColumnGapProps,
    SS.GridRowGapProps,
    SS.GridTemplateColumnsProps,
    SS.GridTemplateRowsProps,
    SS.HeightProps,
    SS.JustifyContentProps,
    SS.JustifySelfProps,
    SS.LeftProps,
    SS.LineHeightProps,
    SS.MaxHeightProps,
    SS.MaxWidthProps,
    SS.MinHeightProps,
    SS.MinWidthProps,
    SS.OpacityProps,
    SS.OrderProps,
    SS.OverflowProps,
    SS.PositionProps,
    SS.RightProps,
    SS.SpaceProps,
    SS.TextAlignProps,
    SS.TopProps,
    SS.VerticalAlignProps,
    SS.WidthProps,
    SS.ZIndexProps {
  color?: CSS.Property.Color
  flexible?: string
  variant?: string
  hover?: string
  cursor?: string
}

const IconComponent = styled.i<IProps>`
  color: ${colors.text};
  ${boxSizing}
  ${cursorStyle}
  ${flexibleStyle}

  ${SS.alignContent}
  ${SS.alignItems}
  ${SS.alignSelf}
  ${SS.borderColor}
  ${SS.borderRadius}
  ${SS.borders}
  ${SS.bottom}
  ${SS.color}
  ${SS.display}
  ${SS.flexBasis}
  ${SS.flexDirection}
  ${SS.flexWrap}
  ${SS.flex}
  ${SS.fontSize}
  ${SS.fontWeight}
  ${SS.height}
  ${SS.justifyContent}
  ${SS.justifySelf}
  ${SS.left}
  ${SS.letterSpacing}
  ${SS.lineHeight}
  ${SS.maxHeight}
  ${SS.maxWidth}
  ${SS.minHeight}
  ${SS.minWidth}
  ${SS.opacity}
  ${SS.order}
  ${SS.position}
  ${SS.right}
  ${SS.space}
  ${SS.textAlign}
  ${SS.top}
  ${SS.width}
  ${SS.zIndex}
`

IconComponent.displayName = 'IconComponent'

function Icon(props: IProps) {
  if (props.className === 'characterization-icon') {
    return (
      <IconComponent {...props} className="material-icons">
        fingerprint
      </IconComponent>
    )
  } else if (props.className && props.className.includes('.svg')) {
    return (
      <img
        alt={props.className}
        src={props.className}
        className={clsx(props.className)}
      />
    )
  }

  return <IconComponent {...props} />
}

export default Icon
