import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

export const REST_PUSH_CHARACTERIZATION_REPORTS_RECORD =
  'restPushCharacterizationReports'

const restPushCharacterizationReports: records.modules.IModule = {
  record: REST_PUSH_CHARACTERIZATION_REPORTS_RECORD,
  noResponseDataKey: true,
  schema: new normalizr.schema.Entity(
    REST_PUSH_CHARACTERIZATION_REPORTS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(['validationId']),
  ),
  api: {
    create: () => '',
    get: (id, { isSession = false }) => {
      if (isSession) {
        return `conformancereports/rest/push/session/${id}`
      }
      return `conformancereports/providerreport/${id}`
    },
    getList: () => '',
    update: () => '',
    delete: () => '',
  },
}

export default restPushCharacterizationReports
