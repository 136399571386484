import React from 'react'

export interface IShowMissingMetadataContext {
  showMissingMetadata: boolean
  setShowMissingMetadata: (show: boolean) => any
  disclosures: string[]
}

export const ShowMissingMetadataContext = React.createContext({
  showMissingMetadata: false,
  setShowMissingMetadata: () => null,
  disclosures: [],
} as IShowMissingMetadataContext)
