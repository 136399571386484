import {
  CONSISTENT_COLUMN_POPULATION,
  INCONSISTENT_COLUMN_POPULATION,
  NONE_COLUMN_POPULATION,
} from 'domains/characterizations/constants/characterizationReport'

export const REPORT_POPULATION_ICON_MAP: { [key: string]: string } = {
  [CONSISTENT_COLUMN_POPULATION]: 'fas fa-battery-full',
  [INCONSISTENT_COLUMN_POPULATION]: 'fas fa-battery-half',
  [NONE_COLUMN_POPULATION]: 'fas fa-battery-empty',
}
