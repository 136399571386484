import React from 'react'
import { utils } from '@ims/1edtech-frontend-common'

import { Button, Icon, Paper, Screen, View } from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import BaseBreadCrumbs from 'domains/application/components/BaseBreadCrumbs'
import DashboardWrapper from 'domains/dashboard/components/DashboardWrapper'
import OrganizationCountWidget from 'domains/dashboard/components/OrganizationCountWidget'
import OrganizationSupplierCountWidget from 'domains/dashboard/components/OrganizationSupplierCountWidget'
import OrganizationDistrictCountWidget from 'domains/dashboard/components/OrganizationDistrictCountWidget'
import LineChart from 'domains/dashboard/components/AdminAnalytics/LineChart'
import { getRequest } from 'lib/api/api'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import Select from 'lib/components/modern/Select/Select'
import { useReportDownloadFetcher } from 'domains/reports/components/DownloadReport'
import FormLabel from 'lib/components/modern/FormLabel'

const today = utils.date.getMoment().format('YYYY-MM-DD_HH-mm-ss')
const REPORTS = [
  {
    url: 'reports/ccx/utilization',
    name: 'CCx Utilization',
    fileName: `CCx_Utilization_${today}`,
    mimetype:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  {
    url: 'reports/trustedapps/utilization',
    name: 'TrustEd Apps Utilization',
    fileName: `Trusted_Apps_Utilization_${today}`,
    mimetype:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  {
    url: 'reports/lti',
    name: 'LTI Configuration',
    fileName: `LTI_CONFIGURATION_${today}`,
    mimetype:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  {
    url: 'reports/orgs/state/csv',
    name: 'Statewide TrustEd Apps Report',
    fileName: `TRUSTED_APPS_REPORT_${today}`,
    mimetype:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },

  {
    url: 'reports/trustedapps/utilization/HED',
    name: 'HED Trusted Apps Utilization',
    fileName: `HED_Trusted_Apps_Utilization_${today}`,
    mimetype:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },

  {
    url: 'reports/trustedapps/utilization/K12',
    name: 'K12 Trusted Apps Utilization',
    fileName: `K12_Trusted_Apps_Utilization_${today}`,
    mimetype:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
]

const STATES = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
]

export default function AdminDashboardScreen() {
  const [statistics, setStatistics] = React.useState({
    totalNumberOfOrgs: 0,
    totalNumberOfSuppliers: 0,
    totalNumberOfDistricts: 0,
  })

  React.useEffect(() => {
    const fetch = async () => {
      const response = await getRequest('statistics/orgs')
      setStatistics(response.data)
    }
    fetch()
  }, [])

  const [downloadPending, setDownloadPending] = React.useState(false)
  const [selectedReport, setSelectedReport] = React.useState<null | number>(
    null,
  )
  const [reportState, setReportState] = React.useState<null | string>(null)

  const report = REPORTS[selectedReport || 0]
  const downloadReport = useReportDownloadFetcher({
    url: `${report.url}${
      report.name.includes('Statewide') ? `/${reportState}` : ''
    }`,
    filename: `${report.fileName}${
      report.name.includes('Statewide') ? `_${reportState}.xlsx` : '.xlsx'
    }`,
    type: report.mimetype,
    raw: true,
  })

  const onDownloadReport = async () => {
    setDownloadPending(true)
    await downloadReport()
    setSelectedReport(null)
    setDownloadPending(false)
  }

  return (
    <Screen>
      <DocumentTitle title="Admin Dashboard" />
      <BaseBreadCrumbs crumbs={[{ name: 'Admin Dashboard' }]} />

      <DashboardWrapper
        row1Widget3Items={true}
        row1Widgets={[
          <OrganizationCountWidget
            key="orgCount"
            fadeDelay={0}
            count={statistics.totalNumberOfOrgs}
          />,
          <OrganizationSupplierCountWidget
            key="supplierCount"
            fadeDelay={25}
            count={statistics.totalNumberOfSuppliers}
          />,
          <OrganizationDistrictCountWidget
            key="districtCount"
            fadeDelay={50}
            count={statistics.totalNumberOfDistricts}
          />,
        ]}
        row2Widgets={[]}
        row3Widgets={[]}
        row3IsLarge={true}
      />

      <Paper className="mt-2">
        <div className="flex flex-col space-y-6">
          <WidgetHeader title="Downloadable Reports" />

          <div className="w-full flex flex-col space-y-2 md:space-y-0 md:flex-row md:items-end md:space-x-4">
            <div className="flex flex-col w-full space-y-1">
              <FormLabel htmlFor="selectedReport">Report</FormLabel>
              <Select
                name="selectedReport"
                selected={selectedReport}
                onChange={(report) => setSelectedReport(report)}
                options={[
                  { label: 'CCx Utilization', value: 0 },
                  {
                    label: 'TrustEd Apps Utilization',
                    value: 1,
                  },
                  { label: 'LTI Configuration', value: 2 },
                  {
                    label: 'Statewide TrustEd Apps Report',
                    value: 3,
                  },
                  {
                    label: 'HED Trusted Apps Utilization',
                    value: 4,
                  },
                  {
                    label: 'K12 Trusted Apps Utilization',
                    value: 5,
                  },
                ]}
                placeholder="Select One"
              />
              {selectedReport === 3 && (
                <Select
                  name="reportState"
                  selected={reportState}
                  onChange={(state) => setReportState(state)}
                  options={STATES.map((state) => ({
                    label: state,
                    value: state,
                  }))}
                />
              )}
            </div>

            <div className="flex flex-row items-center space-x-2 pb-1">
              <Button
                variant={selectedReport !== null ? 'complete' : 'neutral'}
                disabled={
                  selectedReport === null ||
                  (selectedReport === 3 && !reportState)
                }
                onClick={onDownloadReport}
                pending={downloadPending}
              >
                <div className="flex flex-row items-center space-x-2">
                  <Icon className="fas fa-download" color="white" />
                  <p>Download</p>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </Paper>

      <View
        display="grid"
        gridTemplateColumns={['auto', 'auto', '1fr 1fr', '1fr 1fr']}
        gridColumnGap={4}
        width="100%"
      >
        <LineChart title="User Sessions" chart="usersessions" />
        <LineChart title="Comparisons Ran" chart="comparisons" />
        <LineChart
          title="OneRoster Characterizations"
          chart="districtorcharacterizations"
        />
        <LineChart
          title="Common Cartridge Investigations"
          chart="ccinvestigations"
        />
        <LineChart
          title="Supplier Model Characterizations"
          chart="suppliercharacterizations"
        />
      </View>
    </Screen>
  )
}
