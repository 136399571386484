import React from 'react'

import { Text } from 'lib/components'
import ReportStatusIcon from 'domains/reports/components/ReportStatusIcon'
import { utils } from '@ims/1edtech-frontend-common'
import {
  ReportStatus,
  CHARACTERIZATION_STATUS_TO_PRINT_DISPLAY_MAP,
} from 'domains/reports/models/IReportStatuses'
import PrintHR from 'domains/printing/components/PrintHR'

interface IProps {
  name: string
  status: ReportStatus
  noStatus?: boolean
  hr?: boolean
}

export default function PrintFileReportHeader(props: IProps) {
  const { name, status, noStatus = false } = props
  const statusText = CHARACTERIZATION_STATUS_TO_PRINT_DISPLAY_MAP[status]
  const hasStatusText = !noStatus && utils.hasValue(statusText)
  return (
    <div>
      <div
        className="flex flex-row items-center h-12"
        data-test={`file-report-header-${name}`}
      >
        <ReportStatusIcon status={status} />
        <Text ml={2} fontSize={18} fontWeight={700}>
          {name}
        </Text>
        {hasStatusText && (
          <Text fontSize={12} fontWeight={700} ml={1}>
            {' '}
            - {statusText}
          </Text>
        )}
      </div>

      {props.hr && (
        <div className="ml-3">
          <PrintHR />
        </div>
      )}
    </div>
  )
}
