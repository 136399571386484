import React, { PureComponent } from 'react'
import withSizes from 'react-sizes'

import { getDisplayName } from 'lib/utils/hoc'
import {
  MEDIUM_SCREEN_SIZE,
  LARGE_SCREEN_NAME,
  MEDIUM_SCREEN_NAME,
  SMALL_SCREEN_NAME,
  SMALL_SCREEN_SIZE,
  ScreenSizeTypes,
} from 'lib/styles/screenSizes'

const mapSize = (window: any) => {
  const { width } = window
  let screenSize
  let hasSmallScreen = false
  let hasMediumScreen = false
  let hasLargeScreen = false
  let smallerThanMedium = false
  let largerThanMedium = false
  if (width > MEDIUM_SCREEN_SIZE) {
    screenSize = LARGE_SCREEN_NAME
    hasLargeScreen = true
    largerThanMedium = true
  } else if (width > SMALL_SCREEN_SIZE) {
    smallerThanMedium = true
    screenSize = MEDIUM_SCREEN_NAME
    hasMediumScreen = true
  } else {
    smallerThanMedium = true
    screenSize = SMALL_SCREEN_NAME
    hasSmallScreen = true
  }
  return {
    screenSize,
    hasSmallScreen,
    smallerThanMedium,
    hasMediumScreen,
    largerThanMedium,
    hasLargeScreen,
  }
}

export interface IScreenSizeProps {
  screenSize: ScreenSizeTypes
  hasSmallScreen: boolean
  smallerThanMedium: boolean
  hasMediumScreen: boolean
  largerThanMedium: boolean
  hasLargeScreen: boolean
}

export const withScreenSize = (WrappedComponent: any) => {
  class WithScreenSize extends PureComponent<IScreenSizeProps> {
    public static displayName = getDisplayName(
      'WithScreenSize',
      WrappedComponent,
    )

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
  WithScreenSize.displayName = getDisplayName(
    'WithScreenSize',
    WrappedComponent,
  )
  return withSizes(mapSize)(WithScreenSize as any)
}
