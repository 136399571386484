import React from 'react'
import * as R from 'ramda'

import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'

export default function useIsMissingMetadataPath(path: string) {
  const { showMissingMetadata, disclosures } = React.useContext(
    ShowMissingMetadataContext,
  )

  if (!showMissingMetadata) {
    return false
  }

  return R.contains(path, disclosures)
}

export function useIsMissingMetadataPathClassname(path: string) {
  return useIsMissingMetadataPath(path) ? 'missing-metadata' : ''
}
export function useIsMissingMetadataPathTextClassname(path: string) {
  return useIsMissingMetadataPath(path) ? 'missing-metadata-text' : ''
}
