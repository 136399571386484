import { v4 as uuid } from 'uuid'
import pkceIt from 'pkce-challenge'

export const generateStateCode = (): string => uuid()

interface ICodeChallengeResponse {
  verifier: string
  codeChallenge: string
}
export const generateCodeChallenge = (): ICodeChallengeResponse => {
  const { code_verifier: verifier, code_challenge: codeChallenge } = pkceIt()
  return { verifier, codeChallenge }
}
