import React, { PureComponent } from 'react'
import Dialog from 'domains/application/modals/Dialog'

interface IProps {
  isOpen: boolean
  onConfirm: (args?: any) => void
  onDeny: (args?: any) => void
}

export default class UnsavedModelWarning extends PureComponent<IProps> {
  render() {
    return (
      <Dialog
        isOpen={this.props.isOpen}
        message="All changes will be lost."
        icon="fas fa-x-circle"
        onConfirm={this.props.onConfirm}
        onDeny={this.props.onDeny}
      />
    )
  }
}
