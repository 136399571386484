import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'

import {
  LOM_PATHS,
  LOM_TYPE_CATALOG_ENTRY,
  LOM_TYPE_LANGUAGE_TEXT,
  LOM_TYPE_SOURCE_VALUE,
  LOM_TYPE_CONTRIBUTE,
  LOM_TYPE_DATE,
  LOM_TYPE_DURATION,
  LOM_TYPE_REQUIREMENT,
} from 'domains/commonCartridge/constants/lomPaths'

const expandPath = (path: string): string[] => {
  const tail = R.last(path.split('.'))

  switch (tail) {
    case LOM_TYPE_CATALOG_ENTRY:
      return [path.replace(tail, 'catalog'), path.replace(tail, 'entry')]
    case LOM_TYPE_LANGUAGE_TEXT:
      return [path.replace(tail, 'language'), path.replace(tail, 'text')]
    case LOM_TYPE_SOURCE_VALUE:
      return [path.replace(tail, 'source'), path.replace(tail, 'value')]
    case LOM_TYPE_CONTRIBUTE:
      return [
        path.replace(tail, 'role.source'),
        path.replace(tail, 'role.value'),
        path.replace(tail, 'entity'),
        path.replace(tail, 'date.dateTime'),
        path.replace(tail, 'date.description.[].language'),
        path.replace(tail, 'date.description.[].text'),
      ]
    case LOM_TYPE_DATE:
      return [
        path.replace(tail, 'dateTime'),
        path.replace(tail, 'description.[].language'),
        path.replace(tail, 'description.[].text'),
      ]
    case LOM_TYPE_DURATION:
      return [
        path.replace(tail, 'duration'),
        path.replace(tail, 'description.[].language'),
        path.replace(tail, 'description.[].text'),
      ]
    case LOM_TYPE_REQUIREMENT:
      return [
        path.replace(tail, 'orComposite.[].type.source'),
        path.replace(tail, 'orComposite.[].type.value'),
        path.replace(tail, 'orComposite.[].name.source'),
        path.replace(tail, 'orComposite.[].name.value'),
        path.replace(tail, 'orComposite.[].minimumVersion'),
        path.replace(tail, 'orComposite.[].maximumVersion'),
      ]
    default:
      return [path]
  }
}

const hasMD = R.pathSatisfies(utils.hasValue)

const findTopMostMissingPath = (
  lom: any,
  pathParts: any[],
  addToPaths: Set<string> = new Set(),
): Set<string> => {
  const paths: Set<string> = addToPaths
  const partialPath: any[] = []
  for (let partIndex = 0; partIndex < pathParts.length; partIndex++) {
    const part = pathParts[partIndex]
    const parent = R.pathOr({}, partialPath, lom)

    const isArraySeparatorAndArray = part === '[]' && Array.isArray(parent)
    if (isArraySeparatorAndArray) {
      for (let i = 0; i < (parent as any[]).length; i++) {
        const newParts = [...pathParts]
        newParts[partIndex] = i
        const newPaths = findTopMostMissingPath(lom, newParts, paths)
        newPaths.forEach((p) => paths.add(p))
      }

      return paths
    } else {
      partialPath.push(part)
      if (!hasMD(partialPath, lom)) {
        paths.add(partialPath.join('.'))
        return paths
      }
    }
  }

  return paths
}

export const generateDisclosures = (lom: any) => {
  let fullPaths: string[] = []
  LOM_PATHS.forEach((path) => {
    fullPaths = [...fullPaths, ...expandPath(path)]
  })

  const disclosures = new Set<string>()
  fullPaths.forEach((path) => {
    const pathParts = path.split('.')

    const topMostMissingPath = findTopMostMissingPath(lom, pathParts)
    if (utils.hasValue(topMostMissingPath)) {
      topMostMissingPath.forEach((p) => disclosures.add(p))
    }
  })

  const final = Array.from(disclosures).map((path) => `lom.${path}`)
  return final
}
