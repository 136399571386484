export const IS_CONFORMANT_COLUMN = 'Yes'
export const IS_NON_CONFORMANT_COLUMN = 'No'
export const CONSISTENT_COLUMN_POPULATION = 'Consistent'
export const INCONSISTENT_COLUMN_POPULATION = 'Inconsistent'
export const NONE_COLUMN_POPULATION = 'None'

interface IStringMap {
  [s: string]: string
}
export const WARNING_TO_COLUMN_MAP: IStringMap = {
  'Model has made optional field required': 'Required',
  'Model requires optional attribute.': 'Required',
}

export const CHAR_REPORT_ROW_KEY_WIDTH = [200, 332]
export const getCharReportRowKeyWidth = (adjustment: number) => [
  CHAR_REPORT_ROW_KEY_WIDTH[0] + adjustment,
  CHAR_REPORT_ROW_KEY_WIDTH[1] + adjustment,
]
