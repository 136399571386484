import React, { PureComponent, RefObject } from 'react'

import View from 'lib/components/View'
import Input from 'lib/components/Input'
import Button from 'lib/components/Button'

interface IProps {
  text: string
  variant?: string
  name: string
  onChange: (event: React.FormEvent<HTMLInputElement>) => void
  'aria-label'?: string
  multiple?: boolean
}

export default class FileInput extends PureComponent<IProps> {
  private readonly input: RefObject<HTMLInputElement>

  constructor(props: IProps) {
    super(props)
    this.input = React.createRef()
  }

  onBrowse = () => {
    const input = this.input.current
    if (input) {
      input.click()
    }
  }

  render() {
    const { text, variant, name, onChange } = this.props
    return (
      <View>
        <Input
          ref={this.input}
          type="file"
          name={name}
          display="none"
          onChange={onChange}
          aria-label={this.props['aria-label']}
          multiple={this.props.multiple}
        />

        <Button
          variant={variant}
          onClick={this.onBrowse}
          type="button"
          aria-label={this.props['aria-label']}
        >
          {text}
        </Button>
      </View>
    )
  }
}
