import { utils, records } from '@ims/1edtech-frontend-common'

import store from 'lib/store'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'
import {
  productHasModelingStatus,
  isProductCharacterized,
} from 'domains/products/utils/products'
import { deleteRecord } from 'lib/records/workflows/deleteRecord'
import {
  trackCSVCModelingAnalytics,
  trackRestCModelingAnalytics,
} from 'domains/modeling/utils/trackModelingAnalytics'
import { CSV_C_MODEL_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/csvCModelCharacterizations'
import {
  AnyFormatType,
  ANY_FORMAT_TO_SPEC_FORMAT_MAP,
  ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
} from 'domains/formats/constants/formats'
import { NOT_MODELLED_MODELLING_STATUS } from 'domains/products/constants/products'
import { getProductFormatCharacterizationlId } from 'domains/products/utils/productByCharacterization'
import { getProductFormatModelId } from 'domains/products/utils/productByModel'
import { FORMAT_TO_MODULE_NAME_MAP } from 'domains/modeling/constants/formatToModule'
import { getRecord } from 'lib/records/workflows/getRecord'

export const resetProduct = async (
  id: string | number,
  format: AnyFormatType,
) => {
  const specFormat = ANY_FORMAT_TO_SPEC_FORMAT_MAP[format]
  const product = records.entitiesSelectors.entityByIdSelector(
    PRODUCTS_RECORD,
    utils.convertToInt(id),
  )(store.getState())
  const wasModeled = !productHasModelingStatus(NOT_MODELLED_MODELLING_STATUS)(
    product,
    specFormat,
  )
  if (wasModeled) {
    const modelId = getProductFormatModelId(product, specFormat)
    await deleteRecord(FORMAT_TO_MODULE_NAME_MAP[specFormat], modelId, {
      productId: id,
    })
  }

  const wasCharacterized = isProductCharacterized(product, specFormat)
  if (wasCharacterized) {
    const characterizationId = getProductFormatCharacterizationlId(
      product,
      specFormat,
    )
    await deleteRecord(CSV_C_MODEL_CHARACTERIZATIONS_RECORD, characterizationId)
  }

  await getRecord(PRODUCTS_RECORD, id)

  let trackAnalytics = trackCSVCModelingAnalytics
  switch (specFormat) {
    case ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL:
      trackAnalytics = trackRestCModelingAnalytics
      break
  }
  trackAnalytics('reset_product', {
    wasModeled,
    wasCharacterized,
  })
}
