import React, { PureComponent } from 'react'

import { Text, View } from 'lib/components'

export default class ZombieCell extends PureComponent {
  render() {
    return (
      <View
        bg="black"
        flexible="column-center"
        width="100%"
        maxWidth={['10rem', '10rem', '15rem']}
        my={2}
        borderRadius="4px"
      >
        <Text color="white" textAlign="center" fontWeight={700} fontSize={12}>
          Comparison no longer available
        </Text>
      </View>
    )
  }
}
