import * as R from 'ramda'
import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

import {
  FULL_FORMATS,
  FULL_FORMAT_TO_URL,
} from 'domains/formats/constants/formats'

export const SPECIFICATIONS_RECORD = 'specifications'

const idKeys = ['format']

const specifications: records.modules.IModule = {
  record: SPECIFICATIONS_RECORD,
  schema: new normalizr.schema.Entity(
    SPECIFICATIONS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(idKeys),
  ),
  getId: records.modules.utils.getIdAttributeByKeys(idKeys),
  api: {
    create: () => 'specs',
    get: (format, params) => {
      if (R.contains(format, FULL_FORMATS)) {
        return FULL_FORMAT_TO_URL[format]
      }

      const type = R.propOr('csv', 'type', params)
      return `specs/${type}/${format}`
    },
    getList: () => 'specs',
    update: (id) => `specs/${id}`,
    delete: (id) => `specs/${id}`,
  },
}

export default specifications
