import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

export const SUPPLIERS_RECORD = 'suppliers'

const suppliers: records.modules.IModule = {
  record: SUPPLIERS_RECORD,
  responseDataKey: 'orgs',
  schema: new normalizr.schema.Entity(
    SUPPLIERS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(),
  ),
  api: {
    create: () => 'suppliers',
    get: (id) => `suppliers/${id}`,
    getList: () => 'suppliers',
    update: (id) => `suppliers/${id}`,
    delete: () => '',
  },
}

export default suppliers
