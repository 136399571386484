import { utils, records } from '@ims/1edtech-frontend-common'

import { getRecord } from 'lib/records/workflows/getRecord'
import store from 'lib/store'
import IComparison from 'domains/comparisons/models/IComparison'
import { AnyFormatType } from 'domains/formats/constants/formats'
import { comparisonFormatToReportRecord } from 'domains/comparisons/workflows/comparisonFormatToRecord'
import { getRecords } from 'lib/records/workflows/getRecords'
import {
  myOrganizationIdSelector,
  myOrganizationPropSelector,
  isMyOrgFreemiumSelector,
} from 'domains/authentication/selectors/organizations'
import { COMPARISONS_RECORD } from 'lib/records/modules/comparisons'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'

export default async function getFullComparison(
  id: string | number,
  format: AnyFormatType,
) {
  const isFreemium = isMyOrgFreemiumSelector(store.getState(), {})
  const referrerId = myOrganizationPropSelector('referrerId')(
    store.getState(),
    {},
  )
  const comparisonResponse = await getRecord(COMPARISONS_RECORD, id)
  if (!comparisonResponse.success) {
    const orgId = myOrganizationIdSelector(store.getState(), {})
    await getRecords({
      record: COMPARISONS_RECORD,
      parentRecordId: orgId,
      force: true,
    })
    return false
  }

  const comparison = records.entitiesSelectors.entityByIdSelector(
    COMPARISONS_RECORD,
    utils.convertToInt(id),
  )(store.getState()) as IComparison

  await getRecord(
    PRODUCTS_RECORD,
    records.modules.getRefId(comparison, 'productId'),
  )

  const reportRecord = comparisonFormatToReportRecord(format)

  // Do not make request to get full report for freemium orgs comparing against
  // non-referral supplier products
  if (
    isFreemium &&
    referrerId !== records.modules.getRefId(comparison, 'supplierId')
  ) {
    return true
  }

  await getRecord(reportRecord, comparison.comparisonLink)

  return true
}
