import React from 'react'

import { View, Text, Spinner } from 'lib/components'

interface IProps {
  progress?: number
  progressText?: string
}

export default function CharacterizationInProgress(props: IProps) {
  return (
    <View>
      {!props.progress && <Spinner />}
      <View
        mt={3}
        bg="secondary"
        p={3}
        borderRadius="4px"
        flexible="column-center"
      >
        <Text color="white" textAlign="center">
          {props.progress
            ? props.progressText
            : 'Characterization in progress,'}
        </Text>
        {!props.progress && (
          <Text color="white" textAlign="center">
            You may leave this screen. Processing will continue to occur in the
            background.
          </Text>
        )}
      </View>
    </View>
  )
}
