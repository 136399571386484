import { utils } from '@ims/1edtech-frontend-common'

export const TOKENS_STORAGE_KEY = 'TOKENS'

export const FREEMIUM_LOCKED_OUT_MESSAGE =
  'Non member account has passed trial period'

export const ORG_DENIED_LOGIN_MESSAGE =
  'Users from this organisation cannot login'

export const PROXY_BASE_URL =
  utils.env.getEnvVariable('PROXY_BASE_URL', process.env.PROXY_BASE_URL) ||
  'http://localhost:8081/api'

export const REMOTE_PROXY_BASE_URL =
  utils.env.getEnvVariable(
    'REMOTE_PROXY_BASE_URL',
    process.env.REMOTE_PROXY_BASE_URL,
  ) || 'https://dev.1edtech.net/api'

export const SWITCHBOARD_BASE_URL =
  utils.env.getEnvVariable(
    'SWITCHBOARD_BASE_URL',
    process.env.SWITCHBOARD_BASE_URL,
  ) || 'http://localhost:8080/swb-endpoint/swb/v1p0'

export const CHARSYS_BASE_URL =
  utils.env.getEnvVariable('CHARSYS_BASE_URL', process.env.CHARSYS_BASE_URL) ||
  'http://localhost:8082/1edtech_characterisation_inquisitor/ims/confreport/v1p0'

export const COMPSYS_BASE_URL =
  utils.env.getEnvVariable('COMPSYS_BASE_URL', process.env.COMPSYS_BASE_URL) ||
  'http://localhost:8083/1edtech_comparisons_endpoint/ims/compreport/v1p0'

export const CCSYS_BASE_URL =
  utils.env.getEnvVariable('CCSYS_BASE_URL', process.env.COMPSYS_BASE_URL) ||
  'http://localhost:8084/1edtech_cc_endpoint/ims/cc/v1p0'

export const TRUSTED_APPS_BASE_URL =
  utils.env.getEnvVariable(
    'TRUSTED_APPS_BASE_URL',
    process.env.TRUSTED_APPS_BASE_URL,
  ) || 'http://localhost:8085/ta-endpoint/ta/v1p0'

export const isTESTMode =
  utils.env.getEnvVariable('TEST', process.env.TEST) === 'true' || false

export const DEFAULT_REQUEST_TIMEOUT = 60000
