import { putRequest } from 'lib/api/api'

export const updatePassword = async (
  id: string | number,
  newPassword: string,
  currentPassword?: string,
) => {
  const response = await putRequest(`users/${id}/password`, {
    newPassword,
    currentPassword,
  })
  return response.success === true
}
