import { utils } from '@ims/1edtech-frontend-common'
import React from 'react'

type CharacterizationSummaryHeaderProps = {
  description: string
  field?: string
}

export default function CharacterizationSummaryItem({
  description,
  field = '',
}: CharacterizationSummaryHeaderProps) {
  return (
    <p className="text-xs lg:text-sm text-text">
      <span className="font-bold">{field}</span>
      {utils.hasValue(field) && ' - '}
      {description}
    </p>
  )
}
