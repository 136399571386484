import React from 'react'
import * as R from 'ramda'

import { getNavigationProp } from 'lib/utils/withNavigationProps'

export default function useSpecScreen(
  props: any,
  map: any,
  specPropName = 'spec',
) {
  const spec = getNavigationProp(props, specPropName) as string
  const Component = R.propOr<null, any, any>(null, spec, map)
  if (!Component) {
    return null
  }
  return <Component {...props} />
}
