import { SelectOptionProps } from 'lib/components/modern/Select/SelectOption'

export const RT_ACTIVITY_LEARNING = 'ACTIVITY_LEARNING'
export const RT_ACTIVITY_WORKSHEET = 'ACTIVITY_WORKSHEET'
export const RT_ACTIVITY_EXPERIMENT = 'ACTIVITY_EXPERIMENT'
export const RT_ASSESSMENT_RUBRIC = 'ASSESSMENT_RUBRIC'
export const RT_ASSESSMENT_PREPARATION = 'ASSESSMENT_PREPARATION'
export const RT_ASSESSMENT_FORMATIVE = 'ASSESSMENT_FORMATIVE'
export const RT_ASSESSMENT_INTERIM = 'ASSESSMENT_INTERIM'
export const RT_ASSESSMENT_ITEM = 'ASSESSMENT_ITEM'
export const RT_COLLECTION_UNIT = 'COLLECTION_UNIT'
export const RT_COLLECTION_LESSON = 'COLLECTION_LESSON'
export const RT_COLLECTION_CURRICULUM_GUIDE = 'COLLECTION_CURRICULUM_GUIDE'
export const RT_COLLECTION_COURSE = 'COLLECTION_COURSE'
export const RT_GAME = 'GAME'
export const RT_INTERACTIVE_WHITEBOARD = 'INTERACTIVE_WHITEBOARD'
export const RT_INTERACTIVE_SIMULATION = 'INTERACTIVE_SIMULATION'
export const RT_INTERACTIVE_ANIMATION = 'INTERACTIVE_ANIMATION'
export const RT_LECTURE = 'LECTURE'
export const RT_MEDIA_IMAGES_VISUALS = 'MEDIA_IMAGES_VISUALS'
export const RT_MEDIA_VIDEO = 'MEDIA_VIDEO'
export const RT_MEDIA_AUDIO = 'MEDIA_AUDIO'
export const RT_TEXT_WEBSITE = 'TEXT_WEBSITE'
export const RT_TEXT_TEXTBOOK = 'TEXT_TEXTBOOK'
export const RT_TEXT_REFERENCE = 'TEXT_REFERENCE'
export const RT_TEXT_PASSAGE = 'TEXT_PASSAGE'
export const RT_TEXT_DOCUMENT = 'TEXT_DOCUMENT'
export const RT_TEXT_CHAPTER = 'TEXT_CHAPTER'
export const RT_TEXT_BOOK = 'TEXT_BOOK'
export const RT_TEXT_ARTICLE = 'TEXT_ARTICLE'
export const RT_OTHER = 'OTHER'

export type ProductResourceTypes =
  | typeof RT_ACTIVITY_LEARNING
  | typeof RT_ACTIVITY_WORKSHEET
  | typeof RT_ACTIVITY_EXPERIMENT
  | typeof RT_ASSESSMENT_RUBRIC
  | typeof RT_ASSESSMENT_PREPARATION
  | typeof RT_ASSESSMENT_FORMATIVE
  | typeof RT_ASSESSMENT_INTERIM
  | typeof RT_ASSESSMENT_ITEM
  | typeof RT_COLLECTION_UNIT
  | typeof RT_COLLECTION_LESSON
  | typeof RT_COLLECTION_CURRICULUM_GUIDE
  | typeof RT_COLLECTION_COURSE
  | typeof RT_GAME
  | typeof RT_INTERACTIVE_WHITEBOARD
  | typeof RT_INTERACTIVE_SIMULATION
  | typeof RT_INTERACTIVE_ANIMATION
  | typeof RT_LECTURE
  | typeof RT_MEDIA_IMAGES_VISUALS
  | typeof RT_MEDIA_VIDEO
  | typeof RT_MEDIA_AUDIO
  | typeof RT_TEXT_WEBSITE
  | typeof RT_TEXT_TEXTBOOK
  | typeof RT_TEXT_REFERENCE
  | typeof RT_TEXT_PASSAGE
  | typeof RT_TEXT_DOCUMENT
  | typeof RT_TEXT_CHAPTER
  | typeof RT_TEXT_BOOK
  | typeof RT_TEXT_ARTICLE
  | typeof RT_OTHER

export const RESOURCE_TYPES_TO_DISPLAY_MAP: { [key: string]: string } = {
  [RT_ACTIVITY_LEARNING]: 'Learning',
  [RT_ACTIVITY_WORKSHEET]: 'Worksheet',
  [RT_ACTIVITY_EXPERIMENT]: 'Expiriment',
  [RT_ASSESSMENT_RUBRIC]: 'Rubric',
  [RT_ASSESSMENT_PREPARATION]: 'Preperation',
  [RT_ASSESSMENT_FORMATIVE]: 'Formative',
  [RT_ASSESSMENT_INTERIM]: 'Interim',
  [RT_ASSESSMENT_ITEM]: 'Item',
  [RT_COLLECTION_UNIT]: 'Unit',
  [RT_COLLECTION_LESSON]: 'Lesson',
  [RT_COLLECTION_CURRICULUM_GUIDE]: 'Curriculum Guide',
  [RT_COLLECTION_COURSE]: 'Course',
  [RT_GAME]: 'Game',
  [RT_INTERACTIVE_WHITEBOARD]: 'Whiteboard',
  [RT_INTERACTIVE_SIMULATION]: 'Simulation',
  [RT_INTERACTIVE_ANIMATION]: 'Animation',
  [RT_LECTURE]: 'Lecture',
  [RT_MEDIA_IMAGES_VISUALS]: 'Images/Visuals',
  [RT_MEDIA_VIDEO]: 'Video',
  [RT_MEDIA_AUDIO]: 'Audio',
  [RT_TEXT_WEBSITE]: 'Website',
  [RT_TEXT_TEXTBOOK]: 'Textbook',
  [RT_TEXT_REFERENCE]: 'Reference',
  [RT_TEXT_PASSAGE]: 'Passage',
  [RT_TEXT_DOCUMENT]: 'Document',
  [RT_TEXT_CHAPTER]: 'Chapter',
  [RT_TEXT_BOOK]: 'Book',
  [RT_TEXT_ARTICLE]: 'Article',
  [RT_OTHER]: 'Other',
}

export const resourceTypeOptions: SelectOptionProps[] = [
  {
    label: 'Activity',
    value: 'ACT',
    parent: true,
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_ACTIVITY_LEARNING],
    value: RT_ACTIVITY_LEARNING,
    parentId: 'ACT',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_ACTIVITY_WORKSHEET],
    value: RT_ACTIVITY_WORKSHEET,
    parentId: 'ACT',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_ACTIVITY_EXPERIMENT],
    value: RT_ACTIVITY_EXPERIMENT,
    parentId: 'ACT',
  },
  {
    label: 'Assessment',
    value: 'ASSESS',
    parent: true,
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_ASSESSMENT_RUBRIC],
    value: RT_ASSESSMENT_RUBRIC,
    parentId: 'ASSESS',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_ASSESSMENT_PREPARATION],
    value: RT_ASSESSMENT_PREPARATION,
    parentId: 'ASSESS',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_ASSESSMENT_FORMATIVE],
    value: RT_ASSESSMENT_FORMATIVE,
    parentId: 'ASSESS',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_ASSESSMENT_INTERIM],
    value: RT_ASSESSMENT_INTERIM,
    parentId: 'ASSESS',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_ASSESSMENT_ITEM],
    value: RT_ASSESSMENT_ITEM,
    parentId: 'ASSESS',
  },
  {
    label: 'Collection',
    value: 'COLLECT',
    parent: true,
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_COLLECTION_UNIT],
    value: RT_COLLECTION_UNIT,
    parentId: 'COLLECT',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_COLLECTION_LESSON],
    value: RT_COLLECTION_LESSON,
    parentId: 'COLLECT',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_COLLECTION_CURRICULUM_GUIDE],
    value: RT_COLLECTION_CURRICULUM_GUIDE,
    parentId: 'COLLECT',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_COLLECTION_COURSE],
    value: RT_COLLECTION_COURSE,
    parentId: 'COLLECT',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_GAME],
    value: RT_GAME,
  },
  {
    label: 'Interactive',
    value: 'INTER',
    parent: true,
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_INTERACTIVE_WHITEBOARD],
    value: RT_INTERACTIVE_WHITEBOARD,
    parentId: 'INTER',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_INTERACTIVE_SIMULATION],
    value: RT_INTERACTIVE_SIMULATION,
    parentId: 'INTER',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_INTERACTIVE_ANIMATION],
    value: RT_INTERACTIVE_ANIMATION,
    parentId: 'INTER',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_LECTURE],
    value: RT_LECTURE,
  },
  {
    label: 'Media',
    value: 'MEDIA',
    parent: true,
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_MEDIA_IMAGES_VISUALS],
    value: RT_MEDIA_IMAGES_VISUALS,
    parentId: 'MEDIA',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_MEDIA_VIDEO],
    value: RT_MEDIA_VIDEO,
    parentId: 'MEDIA',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_MEDIA_AUDIO],
    value: RT_MEDIA_AUDIO,
    parentId: 'MEDIA',
  },
  {
    label: 'Text',
    value: 'TEXT',
    parent: true,
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_TEXT_WEBSITE],
    value: RT_TEXT_WEBSITE,
    parentId: 'TEXT',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_TEXT_TEXTBOOK],
    value: RT_TEXT_TEXTBOOK,
    parentId: 'TEXT',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_TEXT_REFERENCE],
    value: RT_TEXT_REFERENCE,
    parentId: 'TEXT',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_TEXT_PASSAGE],
    value: RT_TEXT_PASSAGE,
    parentId: 'TEXT',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_TEXT_DOCUMENT],
    value: RT_TEXT_DOCUMENT,
    parentId: 'TEXT',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_TEXT_CHAPTER],
    value: RT_TEXT_CHAPTER,
    parentId: 'TEXT',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_TEXT_BOOK],
    value: RT_TEXT_BOOK,
    parentId: 'TEXT',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_TEXT_ARTICLE],
    value: RT_TEXT_ARTICLE,
    parentId: 'TEXT',
  },
  {
    label: RESOURCE_TYPES_TO_DISPLAY_MAP[RT_OTHER],
    value: RT_OTHER,
    parentId: '',
  },
]
