import IProduct from 'domains/products/models/IProduct'
import IProductFormat from 'domains/products/models/IProductFormat'
import { isProductFormatSupported } from 'domains/products/utils/isProductFormatSupported'

export const productHasMultipleSupportedFormats = (product: IProduct) => {
  let supportedFormats = 0
  product.formats.forEach((format: IProductFormat) => {
    if (isProductFormatSupported(format.format)) {
      supportedFormats++
    }
  })
  return supportedFormats > 1
}
