import {
  SUPPLIER_ORG_TYPE,
  DISTRICT_ORG_TYPE,
  IMS_ORG_TYPE,
} from 'domains/authentication/constants/organizations'

export const SUPPLIER_ROLE = 'SUPPLIER'
export const DISTRICT_ROLE = 'DISTRICT'
export const IMS_ROLE = 'IMS'

export type ROLE_TYPES = 'SUPPLIER' | 'DISTRICT' | 'IMS'

interface IOrgTypeToRoleMap {
  [s: string]: ROLE_TYPES
}
export const ORG_TYPE_TO_ROLE_MAP: IOrgTypeToRoleMap = {
  [SUPPLIER_ORG_TYPE]: SUPPLIER_ROLE,
  [DISTRICT_ORG_TYPE]: DISTRICT_ROLE,
  [IMS_ORG_TYPE]: IMS_ROLE,
}
