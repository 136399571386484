import React, { PureComponent } from 'react'
import * as R from 'ramda'
import memoize from 'fast-memoize'

import { Text, View } from 'lib/components'
import { getReportFileByLabel } from 'domains/characterizations/utils/characterizationReport'
import ITransformedCharacterizationCSVReport from 'domains/characterizations/models/ITransformedCharacterizationCSVReport'
import IFileSetCharacterizationColumn from 'domains/characterizations/models/IFileSetCharacterizationColumn'
import ICSVSpecification from 'domains/specifications/models/ICSVSpecification'
import { findSpecColumn } from 'domains/modeling/selectors/csvCModelToSpec'
import CharacterizationFileColumnReport from 'domains/characterizations/components/CharacterizationFileColumnReport'
import ICSVConsumerModel, {
  ICSVConsumerFile,
} from 'domains/modeling/models/ICSVConsumerModel'
import { utils } from '@ims/1edtech-frontend-common'
import ReportTopLevelError from 'domains/reports/components/ReportTopLevelError'
import { RED_REPORT_STATUS } from 'domains/reports/models/IReportStatuses'
import colors from 'lib/styles/colors'
import CharacterizationFileReportColumnsList from 'domains/characterizations/components/CharacterizationFileReportColumnsList'

interface IProps {
  isOpen: boolean
  fileLabel: string
  report: ITransformedCharacterizationCSVReport
  specification: ICSVSpecification
  model?: ICSVConsumerModel
}

export default class CharacterizationFileReport extends PureComponent<IProps> {
  getSpecColumn = (column: IFileSetCharacterizationColumn) =>
    findSpecColumn(
      this.props.specification,
      this.props.fileLabel,
      column.headerName,
    )

  getRowProps = memoize((column: IFileSetCharacterizationColumn) => {
    const defaultProps = { 'data-test': `${column.headerName}-row` }
    if (column.metadata) {
      const specColumn = this.getSpecColumn(column)
      if (specColumn) {
        return { bg: 'builtInMetaDataBG', ...defaultProps }
      }

      return { bg: 'customMetaDataBG', ...defaultProps }
    }
    return defaultProps
  })

  renderFileNotes() {
    const { fileLabel, model } = this.props
    if (!model) {
      return null
    }

    const notes = R.compose<
      ICSVConsumerModel | any,
      ICSVConsumerFile[],
      any,
      string
    >(
      R.propOr('', 'notes'),
      R.find(R.propEq('name', fileLabel)),
      R.propOr([], 'files'),
    )(model || {})
    if (!utils.hasValue(notes)) {
      return null
    }

    return (
      <View mt={3} mb={4} ml={2}>
        <Text fontSize={18} fontWeight={700} mb={3}>
          File Notes:
        </Text>
        <Text>{notes}</Text>
      </View>
    )
  }

  render() {
    const { isOpen, fileLabel, report, specification } = this.props
    const file = getReportFileByLabel(fileLabel, report)
    if (!file) {
      return null
    }

    return (
      <View
        mb={2}
        p={3}
        bg="white"
        border={`1px solid ${colors.reportTableBorder}`}
        flex={1}
      >
        {this.renderFileNotes()}
        {utils.hasValue(file.errors) && (
          <ReportTopLevelError
            status={RED_REPORT_STATUS}
            title={`The following error(s) with your ${
              file.fileLabel ? 'file' : 'fileset'
            } are preventing characterization:`}
            errors={file.errors.map(R.prop('description'))}
            dataTest={
              file.fileLabel ? `${file.fileLabel}-file-error` : 'fileset-error'
            }
          />
        )}
        {Object.keys(file.columnErrors).map((column) => (
          <CharacterizationFileColumnReport
            key={`${fileLabel}-${column}`}
            isOpen={isOpen}
            fileLabel={fileLabel}
            column={column}
            errors={file.columnErrors[column]}
            specification={specification}
          />
        ))}

        <CharacterizationFileReportColumnsList
          fileLabel={fileLabel}
          report={report}
          specification={specification}
          model={this.props.model}
        />
      </View>
    )
  }
}
