import React from 'react'

type Props = {
  title: string
  'data-test'?: string
}

export default function BlankSlate(props: Props) {
  return (
    <div
      className="flex flex-1 flex-col items-center justify-center p-4"
      data-test={props['data-test'] || 'blankslate'}
    >
      <p className="text-xl text-text font-bold text-center">{props.title}</p>
    </div>
  )
}
