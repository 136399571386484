import React from 'react'
import * as R from 'ramda'
import { Formik, FormikHelpers, Form } from 'formik'
import { utils } from '@ims/1edtech-frontend-common'
import * as Yup from 'yup'

import IRestCModelServiceEndpointAttribute, {
  IRestCModelServiceEndpointAttributeFields,
} from 'domains/modeling/models/IRestCModelServiceEndpointAttribute'
import { OR_1P1_REST_ROSTERING_CONSUMER_SERVICES } from 'domains/modeling/constants/restCOneRoster1p1'
import {
  ModalWrapper,
  View,
  Text,
  Input,
  FormErrorMessage,
  TextArea,
  Select,
} from 'lib/components'
import ModalTitle from 'lib/components/ModalTitle'
import { trackRestCModelingAnalytics } from 'domains/modeling/utils/trackModelingAnalytics'

interface IRowLabelProps {
  required?: boolean
  children: any
}
function RowLabel(props: IRowLabelProps) {
  return (
    <View width={124} flexible="row-v-center">
      <Text fontWeight={700}>{props.children}</Text>
      {props.required && <Text color="error">*</Text>}
    </View>
  )
}

interface IRowInputProps {
  children: any
  name: string
}
function RowInput(props: IRowInputProps) {
  return (
    <View>
      {props.children}
      <FormErrorMessage name={props.name} />
    </View>
  )
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  required: Yup.mixed().oneOf(
    ['Optional', 'Required'],
    'Please select a Required option',
  ),
  dataType: Yup.mixed().oneOf(
    ['Boolean', 'Date', 'String'],
    'Please select a Format option',
  ),
  multiplicity: Yup.mixed().oneOf(
    ['0', '1', 'Finite', 'Infinite'],
    'Please select a Multiplicity option',
  ),
})
const getEmptyOption = (placeholder: any) => (
  <option key={placeholder} value="empty">
    {placeholder}
  </option>
)
const getOption = (value: any) => (
  <option key={value} value={value}>
    {value}
  </option>
)
const requiredOptions = [
  getEmptyOption('Select if required'),
  getOption('Optional'),
  getOption('Required'),
]
const formatOptions = [
  getEmptyOption('Select format'),
  getOption('Boolean'),
  getOption('Date'),
  getOption('String'),
]
const multiplicityOptions = [
  getEmptyOption('Select multiplicity'),
  getOption('0'),
  getOption('1'),
  getOption('Finite'),
  getOption('Infinite'),
]

interface IProps {
  isOpen: boolean
  isCreate: boolean
  close: () => any
  onSave: (metadata: IRestCModelServiceEndpointAttribute) => any
  metadata: IRestCModelServiceEndpointAttribute
  serviceName: OR_1P1_REST_ROSTERING_CONSUMER_SERVICES
  orgName: string
}

interface IFormValues extends IRestCModelServiceEndpointAttribute {
  addNew?: boolean
}

export default function CreateEditModelMetadataModal(props: IProps) {
  const [title, setTitle] = React.useState<any>(null)

  React.useEffect(() => {
    if (props.isOpen) {
      setTitle(
        props.isCreate ? (
          <ModalTitle
            title="Add New Metadata to @@"
            highlight={props.serviceName}
            wordBreak="break-word"
          />
        ) : (
          <ModalTitle
            title="Edit @@"
            highlight={props.metadata.name}
            wordBreak="break-all"
          />
        ),
      )
    }
  }, [props.isOpen, props.isCreate, props.serviceName, props.metadata.name])

  const onSave = (values: IFormValues, bag: FormikHelpers<IFormValues>) => {
    const metadata = {
      ...R.pick(IRestCModelServiceEndpointAttributeFields, values),
      name: `metadata.${values.name}`,
    }
    props.onSave(metadata)

    if (props.isCreate) {
      trackRestCModelingAnalytics('added_custom_metadata', { metadata })
    } else {
      trackRestCModelingAnalytics('updated_custom_metadata', { metadata })
    }

    bag.resetForm()
    if (!values.addNew) {
      props.close()
    }
  }

  const onClose = (resetForm: any) => () => {
    resetForm()
    props.close()
  }

  return (
    <Formik
      initialValues={{
        ...props.metadata,
        name:
          props.metadata.name.substring('metadata.'.length) ||
          `${utils.string.removeAllWhitespace(props.orgName).toLowerCase()}.`,
        required: props.isCreate
          ? 'Select if required'
          : props.metadata.required,
        dataType: props.isCreate ? 'Select format' : props.metadata.dataType,
        multiplicity: props.isCreate
          ? 'Select multiplicity'
          : props.metadata.multiplicity,
      }}
      onSubmit={onSave}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ values, submitForm, handleChange, setFieldValue, resetForm }) => {
        const saveAction = {
          text: 'Save',
          variant: 'start',
          onClick: () => {
            setFieldValue('addNew', false, false)
            submitForm()
          },
          extra: { type: 'submit' },
        }
        const saveAndAddNewAction = {
          text: 'Save + Add New',
          variant: 'tertiary',
          onClick: () => {
            setFieldValue('addNew', true, false)
            submitForm()
          },
          extra: { type: 'submit' },
        }
        const cancelAction = {
          text: 'Cancel',
          variant: 'neutral',
          onClick: onClose(resetForm),
          extra: {
            type: 'button',
          },
        }
        const actions = props.isCreate
          ? [saveAction, saveAndAddNewAction, cancelAction]
          : [saveAction, cancelAction]
        return (
          <ModalWrapper isOpen={props.isOpen} title={title} actions={actions}>
            <Form>
              <View
                display="grid"
                gridTemplateColumns={'124px 1fr'}
                gridColumnGap={0}
                gridRowGap={3}
                width="100%"
              >
                <RowLabel required={true}>Name</RowLabel>
                <RowInput name="name">
                  <View flex={1} flexible="row-v-center">
                    <Text variant="caption">metadata.</Text>
                    <Input
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      placeholder="suppliername"
                      mx={1}
                      p={1}
                      height="36px"
                    />
                  </View>
                </RowInput>

                <RowLabel required={true}>Description</RowLabel>
                <RowInput name="description">
                  <TextArea
                    name="description"
                    onChange={handleChange}
                    value={values.description || ''}
                    placeholder="metadata description"
                    width="100%"
                    minHeight={65}
                  />
                </RowInput>

                <RowLabel required={true}>Required</RowLabel>
                <RowInput name="required">
                  <Select
                    name="required"
                    value={values.required}
                    onChange={handleChange}
                    maxWidth={200}
                    data-test={`select-required`}
                  >
                    {requiredOptions}
                  </Select>
                </RowInput>

                <RowLabel required={true}>Format</RowLabel>
                <RowInput name="dataType">
                  <Select
                    name="dataType"
                    value={values.dataType}
                    onChange={handleChange}
                    maxWidth={200}
                    data-test={`select-dataType`}
                  >
                    {formatOptions}
                  </Select>
                </RowInput>

                <RowLabel required={true}>Multiplicity</RowLabel>
                <RowInput name="multiplicity">
                  <Select
                    name="multiplicity"
                    value={values.multiplicity}
                    onChange={handleChange}
                    maxWidth={200}
                    data-test={`select-multiplicity`}
                  >
                    {multiplicityOptions}
                  </Select>
                </RowInput>

                <RowLabel>Notes</RowLabel>
                <RowInput name="notes">
                  <TextArea
                    name="notes"
                    onChange={handleChange}
                    value={values.notes || ''}
                    placeholder="metadata notes"
                    width="100%"
                    minHeight={65}
                  />
                </RowInput>
              </View>
            </Form>
          </ModalWrapper>
        )
      }}
    </Formik>
  )
}
