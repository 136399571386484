import React from 'react'
import { Link } from 'react-router-dom'

import { Text, ToolTip } from 'lib/components'
import IProduct from 'domains/products/models/IProduct'
import { getProductRoute } from 'domains/products/navigation/routes'
import ProductCatalogExpandedRowCell from 'domains/products/components/ProductCatalog/ProductCatalogExpandedRowCell'

interface IProps extends IProduct {
  isOpen?: boolean
  maxWidth?: string
  maxWidthArray?: (string | number)[]
}

export default function SupplierProductCatalogProductNameCell(props: IProps) {
  const { id, name, format, maxWidth, maxWidthArray = [100, 220] } = props

  return (
    <ProductCatalogExpandedRowCell
      {...props}
      borderBottom={props.isOpen}
      noCenter={true}
    >
      <Link to={getProductRoute(id, format)} data-test={`product-link-${id}`}>
        <ToolTip id={`${id}-product-name`} tip={name} delay={250}>
          <Text
            textAlign="left"
            color="tertiary"
            fontWeight={700}
            maxWidth={maxWidth || maxWidthArray}
          >
            {name}
          </Text>
        </ToolTip>
      </Link>
    </ProductCatalogExpandedRowCell>
  )
}
