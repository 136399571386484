import React from 'react'
import * as R from 'ramda'
import { useParams } from 'react-router'
import { utils } from '@ims/1edtech-frontend-common'
import { useSelector } from 'react-redux'

import { ORGANIZATIONS_RECORD } from 'lib/records/modules/organizations'
import { View, Spinner, LargeButton, Switch, Linkify } from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import BaseBreadCrumbs from 'domains/application/components/BaseBreadCrumbs'
import IOrg from 'domains/orgs/models/IOrg'
import {
  getRoute,
  ORGANIZATIONS_ROUTE,
} from 'domains/application/navigation/routes'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import { setOrgComparisonSharingPref } from 'domains/orgs/workflows/setOrgComparisonSharingPref'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import { DISTRICT_ORG_TYPE } from 'domains/authentication/constants/organizations'
import DistrictLogo from 'domains/orgs/components/DistrictLogo'
import { showToast, ERROR_TOAST } from 'lib/utils/toast'
import { login } from 'domains/authentication/workflows/login'
import CreateEditOrganizationModal from 'domains/orgs/modals/CreateEditOrganizationModal'
import OrgUsersList from 'domains/users/components/OrgUsersList'
import { isOrgAdminRegardlessOfIMSUserSelector } from 'domains/authentication/selectors/roles'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import GenericError from 'domains/application/components/GenericError'
import ReportThreeTopSection from 'domains/reports/components/ReportThreeTopSection'
import OrgSubscriptionWidget from 'domains/orgs/components/OrgSubscriptionWidget'
import OrgDetailItem from 'domains/orgs/components/OrgDetailItem'
import OrganizationPPBadgeCell from 'domains/orgs/components/OrganizationPPBadgeCell'
import useModalState from 'lib/hooks/useModalState'
import useRecordEntity from 'lib/records/hooks/useRecordEntity'
import { updateRecord } from 'lib/records/workflows/updateRecord'
import OrganizationLTIProviderModal from 'domains/orgs/modals/OrganizationLTIProviderModal'
import OrgTrustEdAppsLTIPlatfromSelector from 'domains/orgs/components/OrgTrustEdAppsLTIPlatfromSelector'
import OrganizationActionsModal from 'domains/orgs/modals/OrganizationActionsModal'
import { postRequest } from 'lib/api/api'

const WIDGET_HEIGHT = 620

export default function AdminOrganizationDetailsScreen(props: any) {
  const params = useParams()
  const id = utils.convertToInt(R.pathOr(0, ['id'], params))
  const [editOrgOpen, onEditOrg, onCloseEdit] = useModalState()
  const [selectPlatformOpen, openSelectPlatform, closeSelectPlatformOpen] =
    useModalState()
  const [orgActionsModalOpen, openOrgActionsModal, closeOrgActionsModal] =
    useModalState()
  const [orgData, pending] = useRecordEntity({
    record: ORGANIZATIONS_RECORD,
    id,
  })
  const [loginPending, setLoginPending] = React.useState(false)
  const myOrgId = useSelector((s: any) => myOrganizationIdSelector(s, props))
  const isOrgAdminRegardlessOfIMSUser = useSelector(
    isOrgAdminRegardlessOfIMSUserSelector,
  )

  if (pending) {
    return <Spinner centered={true} />
  }

  if (!pending && !orgData) {
    return <GenericError />
  }

  const org = orgData as IOrg

  const onToggleShareComparisons = async () => {
    await setOrgComparisonSharingPref(id, !org.shareComparisons)
  }

  const onToggleLTIConfig = async () => {
    if (!org.trustedAppsLtiEnabled) {
      openSelectPlatform()
    } else {
      await updateRecord(ORGANIZATIONS_RECORD, id, {
        ...org,
        trustedAppsLtiEnabled: false,
      })
    }
  }

  const onLTIPlatformSelected = async (ltiPlatformId: number) => {
    await updateRecord(ORGANIZATIONS_RECORD, id, {
      ...org,
      trustedAppsLtiEnabled: true,
      ltiPlatformId,
    })
    closeSelectPlatformOpen()
  }

  const onLTIPlatformChanged = async (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const id = parseInt(event.target.value)
    await onLTIPlatformSelected(id)
  }

  const onLoginAsOrg = async () => {
    setLoginPending(true)
    await postRequest('trustedapps/logout')
    const success = await login('', '', id)
    if (!success) {
      setLoginPending(false)
      showToast(ERROR_TOAST, `Failed to login with Org: ${org.name}`)
    }
  }

  const canEditOrgUsers = org.id !== myOrgId || isOrgAdminRegardlessOfIMSUser
  const isDistrict = org.type === DISTRICT_ORG_TYPE

  return (
    <View variant="screen">
      <DocumentTitle title={`Organization - ${org.name}`} />
      <BaseBreadCrumbs
        crumbs={[
          { name: 'Organizations', route: getRoute(ORGANIZATIONS_ROUTE) },
          { name: org.name },
        ]}
      />
      <ReportThreeTopSection>
        <DetailsTopWidget
          title="Organization Details"
          dataTest="org-details-widget"
          height={WIDGET_HEIGHT}
        >
          <View my={2} />
          <View width="100%" minHeight={128} flexible="column-center">
            <DistrictLogo logoUrl={org.logourl} name={org.name} size={128} />

            <OrganizationPPBadgeCell {...org} size={48} hidePreferrers={true} />
          </View>
          <OrgDetailItem
            label="Name"
            labelDataTest="org-details-name-label"
            value={org.name}
            valueDataTest="org-details-name"
          />
          <OrgDetailItem
            label="Segment"
            labelDataTest="org-details-segment-label"
            value={org.segment}
            valueDataTest="org-details-segment"
          />
          <OrgDetailItem
            label={utils.string.joinString(['Type', 'Membership'])}
            labelDataTest="org-details-type-label"
            value={utils.string.joinString([org.type, org.membershipLevel])}
            valueDataTest="org-details-type"
          />

          {isDistrict && (
            <OrgDetailItem
              label="NCESID"
              labelDataTest="org-ncesId-label"
              value={org.ncesId || 'N/A'}
              valueDataTest="org-ncesId"
            />
          )}

          {(utils.hasValue(org.country) || utils.hasValue(org.state)) &&
            isDistrict && (
              <OrgDetailItem
                label="Country · State"
                labelDataTest="org-country-state-label"
                value={[org.country || 'N/A', org.state || 'N/A'].join(' · ')}
                valueDataTest="org-country-state"
              />
            )}

          <OrgDetailItem
            label="Last Active"
            labelDataTest="org-details-last-login-label"
            value={
              org.lastActive
                ? utils.date.formatForDisplay(org.lastActive)
                : 'No Logins'
            }
            valueDataTest="org-details-last-login"
          />
          <OrgDetailItem
            label="1EdTech Page"
            labelDataTest="org-details-imsurl-label"
            value={
              utils.hasValue(org.imsurl) ? (
                <Linkify>{utils.url.getIMSUrl(org.imsurl)}</Linkify>
              ) : null
            }
            valueDataTest="org-details-imsurl"
          />
          <OrgDetailItem
            label="Website"
            labelDataTest="org-details-website-label"
            value={
              utils.hasValue(org.externalurl) ? (
                <Linkify>{org.externalurl}</Linkify>
              ) : null
            }
            valueDataTest="org-details-website"
          />
          <OrgDetailItem
            label="Created Date"
            labelDataTest="org-details-created-date-label"
            value={
              org.createdDate
                ? utils.date.formatForDisplay(org.createdDate)
                : 'Before Tracking'
            }
            valueDataTest="org-details-created-date"
          />
        </DetailsTopWidget>

        <DetailsTopWidget
          title="Organization Actions"
          dataTest="org-actions-widget"
          height={WIDGET_HEIGHT}
        >
          <View
            pt={2}
            flex={1}
            flexible="column-space-between"
            width="100%"
            height="65%"
            boxSizing="border-box"
          >
            <div className="h-full w-full flex flex-col">
              <LargeButton
                variant="start"
                title="Login"
                icon="fas fa-sign-in-alt"
                onClick={onLoginAsOrg}
                data-test="login-btn"
                pending={loginPending}
              />
              <LargeButton
                variant="complete"
                title="Edit Organization"
                icon="fas fa-edit"
                onClick={onEditOrg}
                data-test="edit-btn"
              />
              <LargeButton
                variant="complete"
                title="Edit Actions"
                icon="fas fa-key"
                onClick={openOrgActionsModal}
                data-test="edit-actions-btn"
              />
            </div>

            {isDistrict && (
              <div>
                <div className="mt-3">
                  <WidgetHeader
                    title="Comparison Sharing Default"
                    dataTest="org-comparison-sharing-header"
                  />
                  <div className="mt-3" />
                  <Switch
                    on={org.shareComparisons}
                    onChange={onToggleShareComparisons}
                    dataTest="share-comparisons-toggle"
                    onIconName="fas fa-eye"
                    offIconName="fas fa-eye-slash"
                  />
                </div>

                <div className="mt-10">
                  <WidgetHeader
                    title="Allow TrustEd Apps LTI Configuration"
                    dataTest="org-allow-ta-lti"
                  />
                  <div className="mt-3">
                    <Switch
                      on={org.trustedAppsLtiEnabled}
                      onChange={onToggleLTIConfig}
                      dataTest="allow-ta-lti-toggle"
                      onIconName="fas fa-eye"
                      offIconName="fas fa-eye-slash"
                    />
                  </div>
                  {org.trustedAppsLtiEnabled && (
                    <div className="mt-3 ml-2">
                      <OrgTrustEdAppsLTIPlatfromSelector
                        value={org.ltiPlatformId}
                        onChange={onLTIPlatformChanged}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </View>
        </DetailsTopWidget>

        <OrgSubscriptionWidget orgId={id} height={WIDGET_HEIGHT} />
      </ReportThreeTopSection>

      {canEditOrgUsers && (
        <OrgUsersList orgId={id} useOrgRoute={true} isIMSAdminView={true} />
      )}

      <CreateEditOrganizationModal
        id={id}
        isOpen={editOrgOpen}
        closeModal={onCloseEdit}
      />
      <OrganizationLTIProviderModal
        isOpen={selectPlatformOpen}
        closeModal={closeSelectPlatformOpen}
        onLTIPlatformSelected={onLTIPlatformSelected}
      />
      <OrganizationActionsModal
        isOpen={orgActionsModalOpen}
        closeModal={closeOrgActionsModal}
        org={org}
      />
    </View>
  )
}
