import React from 'react'

import { View, ToolTip, ListPlainTextCell, Icon } from 'lib/components'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'
import { ITransformedRESTServiceEndpointAttributeCharacterizationReport } from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import { REPORT_POPULATION_ICON_MAP } from 'domains/reports/constants/populationIcons'
const isPrint = isOnPrintScreen()

interface IProps
  extends ITransformedRESTServiceEndpointAttributeCharacterizationReport {
  serviceName: string
  endpointName: string
}

export default function RESTCharacterizationAttributePopulationCell(
  props: IProps,
) {
  const {
    serviceName,
    endpointName,
    attributeName,
    population,
    warning,
  } = props
  if (isPrint) {
    const color = warning ? 'secondary' : 'text'
    return (
      <ListPlainTextCell color={color} fontWeight={warning ? 700 : 400}>
        {population}
        {warning && (
          <Icon
            ml={1}
            className={REPORT_POPULATION_ICON_MAP[population!]}
            color={color}
            fontSize={11}
          />
        )}
      </ListPlainTextCell>
    )
  }

  const content = (
    <View
      bg={warning ? 'secondary' : ''}
      borderRadius={4}
      px={1}
      py="12px"
      maxWidth={100}
    >
      <ListPlainTextCell
        color={warning ? 'white' : 'text'}
        fontWeight={warning ? 700 : 400}
      >
        {population}
      </ListPlainTextCell>
    </View>
  )

  if (warning) {
    return (
      <ToolTip
        id={`${serviceName}-${endpointName}-${attributeName}-population`}
        tip={warning}
      >
        {content}
      </ToolTip>
    )
  }

  return content
}
