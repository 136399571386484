import * as normalizr from 'normalizr'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

export const TRUSTED_APPS_SHARED_WITH_ORGS_RECORD = 'trustedAppsSharedWithOrgs'

const trustedAppsSharedWithOrgs: records.modules.IModule = {
  record: TRUSTED_APPS_SHARED_WITH_ORGS_RECORD,
  responseDataKey: 'viewers',
  schema: new normalizr.schema.Entity(
    TRUSTED_APPS_SHARED_WITH_ORGS_RECORD,
    {},
    {
      idAttribute: 'viewerOrgId',
      processStrategy: (entity) =>
        R.assoc('id', R.pathOr(0, ['viewerOrgId'], entity), entity),
    },
  ),
  api: {
    create: () => 'trustedapps/organizations/trustedapps/share',
    get: (id) => `trustedapps/organizations/trustedapps/share/${id}`,
    getList: () => 'trustedapps/organizations/trustedapps/share',
    update: (id) => `trustedapps/organizations/trustedapps/share/${id}`,
    delete: (id) => `trustedapps/organizations/${id}/trustedapps/share`,
  },
}

export default trustedAppsSharedWithOrgs
