import React from 'react'

import { BreadCrumbs } from 'lib/components'
import {
  DASHBOARD_ROUTE,
  getRoute,
} from 'domains/application/navigation/routes'
import { IBreadCrumb } from 'lib/components/BreadCrumbs'
import { COMMON_CARTRIDGE_BASE_NAV_ROUTE } from 'domains/specifications/constants/specNavigation'

interface IProps {
  crumbs?: IBreadCrumb[]
}

export default function BaseCommonCartridgeCharacterizationsBreadCrumbs(
  props: IProps,
) {
  const { crumbs = [] } = props
  return (
    <BreadCrumbs
      crumbs={[
        {
          name: 'Home',
          route: getRoute(DASHBOARD_ROUTE),
        },
        {
          name: 'Common Cartridge Check',
          route: getRoute(
            `/${COMMON_CARTRIDGE_BASE_NAV_ROUTE}${DASHBOARD_ROUTE}`,
          ),
        },
        ...crumbs,
      ]}
    />
  )
}
