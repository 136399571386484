import React from 'react'

import { View } from 'lib/components'
import { IPreferredPartner } from 'domains/orgs/models/IOrg'

interface IProps {
  'data-test'?: string
  preferredPartner: IPreferredPartner
}

export default function PPListRow(props: IProps) {
  return (
    <View
      flexible="column"
      p={3}
      data-test={props['data-test'] || `pp-${props.preferredPartner.id}`}
    >
      <a
        href={props.preferredPartner.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.preferredPartner.name}
      </a>
    </View>
  )
}
