import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

export const USER_ROLES_RECORD = 'userRoles'

const userRoles: records.modules.IModule = {
  record: USER_ROLES_RECORD,
  schema: new normalizr.schema.Entity(USER_ROLES_RECORD, {}, {}),
  api: {
    create: () => 'userRoles',
    get: () => 'userRoles',
    getList: () => 'userRoles',
    update: () => 'userRoles',
    delete: () => 'userRoles',
  },
}

export default userRoles
