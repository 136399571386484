import React, { PureComponent } from 'react'
import * as R from 'ramda'
import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import {
  FormErrorMessage,
  Input,
  Select,
  Span,
  TextArea,
  Text,
  View,
} from 'lib/components'
import ModalWrapper from 'lib/components/ModalWrapper'
import { createNewColumn } from 'domains/modeling/workflows/csvCModelFileColumn'
import {
  updateCsvCFileColumnModelInState,
  removeCsvCFileColumnModelInState,
} from 'domains/modeling/workflows/csvCModelFile'
import { trackCSVCModelingAnalytics } from 'domains/modeling/utils/trackModelingAnalytics'
import { utils } from '@ims/1edtech-frontend-common'
import { ICSVConsumerFileColumn } from 'domains/modeling/models/ICSVConsumerModel'
import { showToast, SUCCESS_TOAST } from 'lib/utils/toast'
import { OPTIONAL_COLUMN_WARNING } from 'domains/modeling/constants/columns'

const validationSchema = Yup.object().shape({
  header: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  required: Yup.string().required('Required'),
  dataType: Yup.string().required('Required'),
  multiplicity: Yup.string().required('Required'),
  addNew: Yup.boolean().required('Required'),
  delete: Yup.boolean().required('Required'),
})
const getEmptyOption = (placeholder: any) => (
  <option key={placeholder} value="empty">
    {placeholder}
  </option>
)
const getOption = (value: any) => (
  <option key={value} value={value}>
    {value}
  </option>
)
const requiredOptions = [
  getEmptyOption('Select if required'),
  getOption('Optional'),
  getOption('Required'),
]
const formatOptions = [
  getEmptyOption('Select format'),
  getOption('Boolean'),
  getOption('Date'),
  // getOption('Datetime'),
  // getOption('Enumeration'),
  // getOption('Guid'),
  // getOption('Guid Reference'),
  getOption('String'),
  // getOption('Year'),
]
const multiplicityOptions = [
  getEmptyOption('Select multiplicity'),
  getOption('0'),
  getOption('1'),
  getOption('Finite'),
  getOption('Infinite'),
]

interface IProps {
  isOpen: boolean
  closeModal: (wasSaved: boolean, wasCleared: boolean) => any
  modelId: string | number
  fileName: string
  column?: ICSVConsumerFileColumn
  header?: string
  isCreate?: boolean
  supplierName?: string
}

interface IFormValues extends ICSVConsumerFileColumn {
  delete?: boolean
  addNew?: boolean
}

export default class ModelFileColumnEditorModal extends PureComponent<IProps> {
  onSave = (values: IFormValues, bag: FormikHelpers<IFormValues>) => {
    const { modelId, fileName, isCreate, header } = this.props
    const newHeader = ['metadata', values.header].join('.')
    const column = R.compose<IFormValues, any, any, any, any>(
      R.assoc('metadata', true),
      R.assoc('header', newHeader),
      R.dissoc('addNew'),
      R.dissoc('delete'),
    )(values)
    if (isCreate) {
      createNewColumn(modelId, fileName, column)
      showToast(SUCCESS_TOAST, 'Column Added')
      trackCSVCModelingAnalytics('added_custom_metadata')
    } else if (values.delete) {
      removeCsvCFileColumnModelInState(modelId, fileName, header!)
      showToast(SUCCESS_TOAST, 'Column Removed')
      trackCSVCModelingAnalytics('removed_custom_metadata')
    } else {
      updateCsvCFileColumnModelInState(
        modelId,
        fileName,
        header!,
        column as ICSVConsumerFileColumn,
      )
      showToast(SUCCESS_TOAST, 'Column Updated')
      trackCSVCModelingAnalytics('updated_custom_metadata')
    }

    bag.resetForm()
    if (!values.addNew) {
      this.props.closeModal(true, !utils.hasValue(values.notes))
    }
  }

  onSaveAndAddNew = (submitForm: any, setFieldValue: any) => () => {
    setFieldValue('addNew', true, false)
    submitForm()
  }

  onDelete = (submitForm: any, setFieldValue: any) => () => {
    setFieldValue('delete', true, false)
    submitForm()
  }

  getActions = (submitForm: any, setFieldValue: any) => {
    const { isCreate, closeModal } = this.props
    const saveAction = {
      text: 'Save',
      variant: 'start',
      onClick: submitForm,
      extra: {
        type: 'submit',
      },
    }
    const cancelAction = {
      text: 'Cancel',
      variant: 'neutral',
      onClick: closeModal,
      extra: {
        type: 'button',
      },
    }
    if (isCreate) {
      return [
        saveAction,
        {
          text: 'Save + Add New',
          variant: 'primary',
          onClick: this.onSaveAndAddNew(submitForm, setFieldValue),
          extra: {
            type: 'submit',
          },
        },
        cancelAction,
      ]
    }

    return [
      saveAction,
      {
        text: 'Delete',
        variant: 'primary',
        onClick: this.onDelete(submitForm, setFieldValue),
        extra: {
          type: 'submit',
        },
      },
      cancelAction,
    ]
  }

  renderSection = (
    title: string,
    field: string,
    content: any,
    required = true,
  ) => (
    <View flexible="row-v-center" mb={2}>
      <View width={124} flexible="row">
        <Text fontWeight={700}>{title}</Text>
        {required && <Text color="error">*</Text>}
      </View>
      <View flex={1}>
        {content}
        <FormErrorMessage name={field} />
      </View>
    </View>
  )

  renderTitle = () => {
    const { isCreate, fileName, header } = this.props
    if (isCreate) {
      return (
        <Text
          variant="subtitle"
          fontWeight={400}
          maxWidth={[250, 350]}
          wordBreak="break-word"
        >
          Add New Metadata to <Span fontWeight="bold">{fileName}</Span>
        </Text>
      )
    }

    return (
      <Text
        variant="subtitle"
        fontWeight={400}
        overflow="ellipsis"
        maxWidth={[250, 350]}
      >
        Edit Column <Span fontWeight="bold">{header}</Span>
      </Text>
    )
  }

  render() {
    const { isOpen, column, header, supplierName } = this.props
    const columnHeader = R.propOr<
      string,
      ICSVConsumerFileColumn | undefined,
      string
    >('', 'header', column)
    const initialHeader =
      columnHeader.substring('metadata.'.length) || `${supplierName}.`

    return (
      <Formik
        initialValues={
          {
            ...(column as ICSVConsumerFileColumn),
            header: initialHeader,
            addNew: false,
            delete: false,
          } as IFormValues
        }
        onSubmit={this.onSave}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({
          values,
          setFieldValue,
          handleChange,
          submitForm,
          isSubmitting,
        }) => (
          <Form>
            <ModalWrapper
              isOpen={isOpen}
              title={this.renderTitle()}
              notice={OPTIONAL_COLUMN_WARNING}
              actions={this.getActions(submitForm, setFieldValue)}
              pending={isSubmitting}
            >
              <View>
                {this.renderSection(
                  'Field Header',
                  'header',
                  <View flex={1} flexible="row-v-center">
                    <Text variant="caption">metadata.</Text>
                    <Input
                      name="header"
                      value={values.header}
                      onChange={handleChange}
                      placeholder="suppliername"
                      mx={1}
                      p={1}
                      height="36px"
                    />
                  </View>,
                )}
                {this.renderSection(
                  'Description',
                  'description',
                  <TextArea
                    name="description"
                    onChange={handleChange}
                    value={values.description || ''}
                    placeholder={`Description${
                      utils.hasValue(header) ? ` of ${header}` : ''
                    }`}
                    width="100%"
                    minHeight="65px"
                  />,
                )}
                {this.renderSection(
                  'Required',
                  'required',
                  <Select
                    value={values.required || 'empty'}
                    onChange={handleChange}
                    name="required"
                    maxWidth="200px"
                  >
                    {requiredOptions}
                  </Select>,
                )}
                {this.renderSection(
                  'Format',
                  'dataType',
                  <Select
                    value={values.dataType || 'empty'}
                    onChange={handleChange}
                    name="dataType"
                    maxWidth="200px"
                  >
                    {formatOptions}
                  </Select>,
                )}
                {this.renderSection(
                  'Multiplicity',
                  'multiplicity',
                  <Select
                    value={values.multiplicity || 'empty'}
                    onChange={handleChange}
                    name="multiplicity"
                    maxWidth="200px"
                  >
                    {multiplicityOptions}
                  </Select>,
                )}
                {this.renderSection(
                  'Notes',
                  'notes',
                  <TextArea
                    name="notes"
                    onChange={handleChange}
                    value={values.notes || ''}
                    placeholder={`Notes${
                      utils.hasValue(header) ? ` on ${header}` : ''
                    }`}
                    width="100%"
                    minHeight="65px"
                  />,
                  false,
                )}
              </View>
            </ModalWrapper>
          </Form>
        )}
      </Formik>
    )
  }
}
