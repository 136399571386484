import React from 'react'
import { Link } from 'react-router-dom'

import { View, ToolTip, Text } from 'lib/components'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import CharacterizationIcon from 'domains/characterizations/components/CharacterizationIcon'
import { getCharacterizationRoute } from 'domains/characterizations/navigation/routes'
import Dialog from 'domains/application/modals/Dialog'
import { ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL } from 'domains/formats/constants/formats'

type CharacterizationNameCellProps = {
  maxWidth?: string
  maxWidthArray?: (string | number)[]
  noResponsiveIcon?: boolean
} & ICharacterization

export default function CharacterizationNameCell(
  props: CharacterizationNameCellProps,
) {
  const {
    id,
    name,
    maxWidth = 320,
    maxWidthArray,
    noResponsiveIcon,
    format,
  } = props

  const isGBPush = format === ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL
  const [processingAlertOpen, setProcessingAlertOpen] = React.useState(false)

  const onHideProcessingAlert = () => setProcessingAlertOpen(false)

  const onShowProcessingAlert = (e: React.MouseEvent) => {
    if (!props.status && !isGBPush) {
      e.preventDefault()
      setProcessingAlertOpen(true)
    }
  }

  const tooltipId = `${id}-${name}`
  return (
    <View flexible="row-v-center" pl={2} ml={'-8px'}>
      <CharacterizationIcon {...props} noResponsive={noResponsiveIcon} />
      <View pr={12} />
      <Link
        onClick={onShowProcessingAlert}
        to={getCharacterizationRoute(id, format)}
        data-test={`characterization-link-${id}`}
      >
        <ToolTip id={tooltipId} tip={name} delay={500}>
          <Text
            textAlign="left"
            color="tertiary"
            maxWidth={maxWidthArray || maxWidth}
            fontWeight={500}
          >
            {name}
          </Text>
        </ToolTip>
      </Link>
      {processingAlertOpen && (
        <Dialog
          isOpen={processingAlertOpen}
          title="Characterization Report is currently unavailable."
          message={
            <View flexible="column-center">
              <Text variant="subtitle">Please check back later.</Text>
            </View>
          }
          confirmText="OK"
          onConfirm={onHideProcessingAlert}
        />
      )}
    </View>
  )
}
