import React from 'react'
import clsx from 'clsx'
import * as R from 'ramda'

import {
  ITransformedRESTServiceEndpointCharacterizationReport,
  ITransformedRESTServiceCharacterizationReport,
} from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import useAccordionHeaderState from 'domains/reports/hooks/useAccordionHeaderState'
import { ExpansionPanel } from 'lib/components'
import CriticalErrorHeader from 'domains/characterizations/components/CharacterizationReport/CriticalErrorHeader'
import CriticalErrorMessage from 'domains/characterizations/components/CharacterizationReport/CriticalErrorMessage'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import { findSpecServiceEndointAttributeByName } from 'domains/modeling/utils/restCOR1p1utils'
import { OR_1P1_REST_ROSTERING_CONSUMER_SERVICES } from 'domains/modeling/constants/restCOneRoster1p1'
import CriticalErrorSpecDetails from 'domains/characterizations/components/CharacterizationReport/CriticalErrorSpecDetails'

interface IProps {
  isOpen: boolean
  endpoint?: ITransformedRESTServiceEndpointCharacterizationReport
  service: ITransformedRESTServiceCharacterizationReport
  specification: IRestCSpec
}

export default function RESTEndpointAttributeErrors(props: IProps) {
  const [headerStates, , onToggleHeader] = useAccordionHeaderState(
    (
      R.keys(R.pathOr([], ['endpoint', 'attributeErrors'], props)) as string[]
    ).map((name) => ({
      name: name as string,
      errors: R.pathOr([], ['endpoint', 'attributeErrors', name], props),
    })),
    undefined,
    props.isOpen,
  )

  if (!props.endpoint || !props.endpoint.attributeErrors) {
    return null
  }

  const onRequestChange = (name: string) => () => onToggleHeader(name)

  return (
    <div className="my-3">
      {headerStates.map((state, index) => {
        const dataTest = `${props.endpoint!.endpointName}-attribute-error-${
          state.name
        }`
        const specAttribute = findSpecServiceEndointAttributeByName(
          props.specification,
          props.service.serviceName as OR_1P1_REST_ROSTERING_CONSUMER_SERVICES,
          props.endpoint!.endpointName,
          state.name,
        )
        return (
          <ExpansionPanel
            key={dataTest}
            isOpen={state.open}
            onRequestChange={onRequestChange(state.name)}
            summary={
              <CriticalErrorHeader
                name={state.name}
                open={state.open}
                data-test={dataTest}
                first={index === 0}
              />
            }
            details={
              <div className={clsx('px-2', { 'pt-3': index !== 0 })}>
                <CriticalErrorSpecDetails
                  endpoint={props.endpoint!}
                  attributeName={state.name}
                  type={specAttribute ? specAttribute.dataType : undefined}
                  description={
                    specAttribute ? specAttribute.description : undefined
                  }
                />
                {state.data.errors.map((error: any, errorIndex: number) => (
                  <CriticalErrorMessage
                    key={`${dataTest}-error-${errorIndex}`}
                    data-test={`${dataTest}-error-${errorIndex}`}
                    message={error}
                  />
                ))}
              </div>
            }
          />
        )
      })}
    </div>
  )
}
