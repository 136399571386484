import React from 'react'

import ICharacterization from 'domains/characterizations/models/ICharacterization'
import ReportStatusIcon from 'domains/reports/components/ReportStatusIcon'

export default function CharacterizationStatusCell(props: ICharacterization) {
    const { status } = props
    return (
        <ReportStatusIcon status={status} />
    )
}
