import memoize from 'fast-memoize'
import { utils } from '@ims/1edtech-frontend-common'

import IProduct from 'domains/products/models/IProduct'
import IProductFormat from 'domains/products/models/IProductFormat'
import { isProductFormatSupported } from 'domains/products/utils/isProductFormatSupported'
import { AnySpecFormatType } from 'domains/formats/constants/formats'

export const expandProductsByFormats = memoize(
  (
    products: IProduct[] | IProduct,
    supportedFormats?: AnySpecFormatType[],
  ): IProduct[] => {
    const validProductFormats: IProduct[] = []
    utils.array.ensureArray(products).forEach((product: IProduct) => {
      product.formats.forEach((format: IProductFormat) => {
        if (isProductFormatSupported(format.format, supportedFormats)) {
          validProductFormats.push({
            ...product,
            format: format.format,
          })
        }
      })
    })
    return validProductFormats
  },
)
