import React from 'react'

import ISource from 'domains/configuration/models/ISource'
import { ListPlainTextCell } from 'lib/components'
import { OrgCertificationLevelToDisplay } from 'domains/orgs/models/IOrg'

interface IProps extends ISource {
  onClick: (source: ISource) => void
}

export default function SourceCertifiedCell(props: IProps) {
  return (
    <ListPlainTextCell>
      {OrgCertificationLevelToDisplay[props.certificationLevel] ||
        'Uncertified'}
    </ListPlainTextCell>
  )
}
