import React from 'react'
import { useSelector } from 'react-redux'
import { records } from '@ims/1edtech-frontend-common'

import {
  getProductRoute,
  getProductModelingRoute,
} from 'domains/products/navigation/routes'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'
import { IBreadCrumb } from 'lib/components/BreadCrumbs'
import IProduct from 'domains/products/models/IProduct'
import { RootState } from 'lib/store/rootReducer'
import BaseProductsBreadCrumbs from 'domains/products/components/BaseProductsBreadCrumbs'

interface IProps {
  productId: string | number
  modelId: string | number
  crumbs?: IBreadCrumb[]
  beforeNavigate?: (event: any, route: string) => void
}

export default function BaseProductModelingBreadCrumbs(props: IProps) {
  const product = useSelector((s: RootState) =>
    records.entitiesSelectors.entityByIdSelector(PRODUCTS_RECORD, 'productId')(
      s,
      props,
    ),
  ) as IProduct
  const { productId, modelId, crumbs = [], ...rest } = props
  return (
    <BaseProductsBreadCrumbs
      {...rest}
      crumbs={[
        {
          name: product.name,
          route: getProductRoute(productId),
        },
        {
          name: 'Modeling',
          route: getProductModelingRoute(productId, modelId),
        },
        ...crumbs,
      ]}
    />
  )
}
