import * as notificationConstants from 'domains/notifications/constants/notifications'

export const NOTIFICATION_EVENT_TO_ICON_MAP: { [key: string]: string } = {
  [notificationConstants.GENERAL_NOTIFICATION]: 'fas fa-info-circle',
  [notificationConstants.CHARACTERIZATION_COMPLETED_NOTIFICATION]:
    'characterization-icon',
  [notificationConstants.COMPARISON_ZOMBIFIED_NOTIFICATION]: 'fas fa-poll',
  [notificationConstants.USER_CREATED_NOTIFICATION]: 'fas fa-user-tie',
  [notificationConstants.USER_DELETED_NOTIFICATION]: 'fas fa-user-slash',
  [notificationConstants.ACCOUNT_EXPIRING_SOON_NOTIFICATION]:
    'fas fa-user-clock',
  [notificationConstants.PRODUCT_EXPIRING_SOON_NOTIFICATION]: 'fas fa-book',
}

export const NOTIFICATION_EVENT_TO_ICON_COLOR_MAP: { [key: string]: string } = {
  [notificationConstants.GENERAL_NOTIFICATION]: 'info',
  [notificationConstants.CHARACTERIZATION_COMPLETED_NOTIFICATION]: 'success',
  [notificationConstants.COMPARISON_ZOMBIFIED_NOTIFICATION]: 'info',
  [notificationConstants.USER_CREATED_NOTIFICATION]: 'info',
  [notificationConstants.USER_DELETED_NOTIFICATION]: 'warningAlt',
  [notificationConstants.ACCOUNT_EXPIRING_SOON_NOTIFICATION]: 'warningAlt',
  [notificationConstants.PRODUCT_EXPIRING_SOON_NOTIFICATION]: 'warningAlt',
}
