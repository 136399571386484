import React from 'react'
import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'

import Icon from 'lib/components/Icon'
import Input, { IInputProps } from 'lib/components/Input'
import View from 'lib/components/View'
import SearchBoxItemSelected from 'lib/components/SearchBoxItemSelected'

interface IProps extends IInputProps {
  itemSelected?: boolean
  noIcon?: boolean
  onBlur?: () => void
  onChange?: (search: any) => void
  onCleared?: () => void
  onFocus?: () => void
  placeholder?: string
  trackEvent?: (action: string, eventProps?: any) => void
  trackEventAction?: string
  width?: string | number | (string | number)[]
  fontSize?: string | number | (string | number)[]
  inputProps?: any
  name?: string
  'aria-label'?: string
  value?: string
  autoFocus?: boolean
  noHover?: boolean
  listenForKeyFocus?: boolean
}

const debounceSearch = utils.debounce.makeDebounce(350)
const propTypes = {
  itemSelected: true,
  noIcon: true,
  onBlur: true,
  onChange: true,
  onCleared: true,
  onFocus: true,
  placeholder: true,
  trackEvent: true,
  trackEventAction: true,
  width: true,
  fontSize: true,
  value: true,
  autoFocus: true,
}

const SearchBox = React.forwardRef<any, IProps>((props: IProps, ref) => {
  const [value, setValue] = React.useState(props.value || '')
  React.useEffect(() => {
    if (props.value !== undefined) setValue(props.value)
  }, [props.value])

  const input = ref || (React.useRef<any>(null) as any) // eslint-disable-line
  const ifSearchKey = React.useCallback(
    (event) => {
      if (input.current) {
        if (event.key === '/') {
          setTimeout(() => {
            input.current.focus()
          }, 250)
        } else if (event.key === 'Escape') {
          event.target.blur()
        }
      }
    },
    [input],
  )

  const onFocus = () => props.onFocus && props.onFocus()

  const onBlur = () => props.onBlur && props.onBlur()

  const { onCleared } = props
  const clear = React.useCallback(() => {
    setValue('')
    if (onCleared) {
      onCleared()
    }
  }, [onCleared])

  const clearAndFocus = React.useCallback(() => {
    clear()
    if (input.current) {
      input.current.focus()
    }
  }, [clear, input])

  React.useImperativeHandle(ref, () => ({
    focus: onFocus,
    setValue: (val: string) => {
      setValue(val)
    },
  }))

  React.useEffect(() => {
    if (props.listenForKeyFocus) {
      document.addEventListener('keydown', ifSearchKey, false)
      return () => {
        document.removeEventListener('keydown', ifSearchKey, false)
      }
    }
  }, [input, props.listenForKeyFocus, ifSearchKey])

  React.useEffect(() => {
    setTimeout(() => {
      if (props.autoFocus && props.onFocus) {
        props.onFocus()
      }
    }, 500)
  }, []) // eslint-disable-line

  const onDebounced = (search: string) => () => {
    if (props.onChange) {
      props.onChange(search)
    }
    if (props.trackEvent) {
      props.trackEvent(trackEventAction || 'search')
    }
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setValue(newValue)
    if (!utils.hasValue(newValue) && props.onCleared && !props.noIcon) {
      props.onCleared()
    }
    debounceSearch.debounce(onDebounced(newValue))
  }

  const { width, noIcon, itemSelected, trackEventAction } = props
  return (
    <View position="relative" minWidth={width || ['100%', '19rem']}>
      <Input
        ref={input}
        p="12px"
        pl={noIcon ? '12px' : '48px'}
        pr={itemSelected ? '12px' : '48px'}
        width={width || '300px'}
        maxWidth="100%"
        boxSizing="border-box"
        fontSize={props.fontSize || '22px'}
        verticalAlign="middle"
        placeholder={R.is(String, props.placeholder) ? props.placeholder : ''}
        {...(R.omit(R.keys(propTypes), props) as any)}
        name={props.name}
        value={value || ''}
        onChange={onChange}
        onFocus={onFocus}
        autoFocus={props.autoFocus}
        onBlur={onBlur}
        aria-label={
          props['aria-label']
            ? props['aria-label']
            : trackEventAction || 'Search box'
        }
        role="search"
        autoComplete="off"
      />
      {!noIcon && (
        <Icon
          className="fas fa-search"
          fontSize="22px"
          color="placeholder"
          position="absolute"
          top="18px"
          left="16px"
        />
      )}
      <SearchBoxItemSelected
        selected={!!itemSelected}
        onClick={clearAndFocus}
        noHover={props.noHover}
      />
    </View>
  )
})

SearchBox.displayName = 'SearchBox'
export default SearchBox
