import React from 'react'

import { Image } from 'lib/components'

export default function VettedIcon(props: any) {
  return (
    <Image
      alt="Vetted Badge"
      src="/images/trustedApps/vettedBadge.svg"
      {...props}
    />
  )
}
