import React, { createContext } from 'react'
import { Router, Route, Switch, RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { utils } from '@ims/1edtech-frontend-common'

import { history } from 'lib/utils/navigation'
import MainRouter from 'domains/application/components/MainRouter'
import LoginScreen from 'domains/authentication/screens/LoginScreen'
import { applicationPropSelector } from 'domains/application/selectors/application'
import {
  LOGIN_ROUTE,
  LOGIN_ROUTES,
  OAUTH_REDIRECT_ROUTE,
  LTI_LOGIN_REDIRECT_ROUTE,
  PRINT_ROOT_ROUTE,
  FRESHDESK_JWT_LOGIN,
} from 'domains/application/navigation/routes'
import OAuth2RedirectScreen from 'domains/authentication/screens/OAuth2RedirectScreen'
import { myOrganizationPropSelector } from 'domains/authentication/selectors/organizations'
import { RootState } from 'lib/store/rootReducer'
import { FeatureFlagsContext } from 'lib/components/FeatureFlags'
import { OrgActionsContext } from 'lib/components/OrgActions'
import LTILoginScreen from 'domains/authentication/screens/LTILoginScreen'
import PrintRouter from 'domains/application/components/PrintRouter'
import FreshdeskJWTLoginScreen from 'domains/authentication/screens/FreshdeskJWTLoginScreen'

export const RouterContext = createContext({})

export default function AppScreen(props: any) {
  const me = utils.convertToInt(useSelector(applicationPropSelector('me')))
  const featureFlags = useSelector((state: RootState) =>
    myOrganizationPropSelector('featureFlags')(state, props),
  )
  const orgActions = useSelector((state: RootState) =>
    myOrganizationPropSelector('actions')(state, props),
  )
  const orgOverrides = useSelector((state: RootState) =>
    myOrganizationPropSelector('overrides')(state, props),
  )
  const loggedIn = me > 0

  React.useEffect(() => {
    const isNotAuthLocation = LOGIN_ROUTES.includes(history.location.pathname)

    if (!loggedIn && !isNotAuthLocation) {
      history.push('/login', {
        redirectUrl: `${history.location.pathname}${history.location.search}`,
      })
    }
  }, [loggedIn, me])

  const renderLoggedInRouters = (routeProps: RouteComponentProps) => {
    if (routeProps.location.pathname.startsWith(PRINT_ROOT_ROUTE)) {
      return <Route component={PrintRouter} />
    }
    return <Route component={MainRouter} />
  }

  const renderRoute = (routeProps: RouteComponentProps) => {
    return (
      <RouterContext.Provider value={routeProps}>
        <Switch location={routeProps.location}>
          <Route exact={true} path={LOGIN_ROUTE} component={LoginScreen} />
          <Route path={OAUTH_REDIRECT_ROUTE} component={OAuth2RedirectScreen} />
          <Route path={LTI_LOGIN_REDIRECT_ROUTE} component={LTILoginScreen} />
          <Route
            path={FRESHDESK_JWT_LOGIN}
            component={FreshdeskJWTLoginScreen}
          />
          {me > 0 && <Route render={renderLoggedInRouters} />}
        </Switch>
      </RouterContext.Provider>
    )
  }

  return (
    <main>
      <FeatureFlagsContext.Provider value={featureFlags || []}>
        <OrgActionsContext.Provider
          value={{ actions: orgActions || [], overrides: orgOverrides || [] }}
        >
          <Router history={history}>
            <Route render={renderRoute} />
          </Router>
        </OrgActionsContext.Provider>
      </FeatureFlagsContext.Provider>
    </main>
  )
}
