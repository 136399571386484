import React from 'react'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { records } from '@ims/1edtech-frontend-common'

import { View, List, Text, Span } from 'lib/components'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import { COMPARISONS_RECORD } from 'lib/records/modules/comparisons'
import { isSupplierRoleSelector } from 'domains/authentication/selectors/roles'
import { RootState } from 'lib/store/rootReducer'
import IComparison from 'domains/comparisons/models/IComparison'
import memoize from 'fast-memoize'
import trackComparisonAnalytics from 'domains/comparisons/utils/trackComparisonAnalytics'
import { utils } from '@ims/1edtech-frontend-common'
import { getComparisonReportRoute } from 'domains/comparisons/navigation/routes'
import ComparisonReportNotesModal from 'domains/comparisons/modals/ComparisonReportNotesModal'
import ComparisonDeleteCell from 'domains/comparisons/components/ComparisonDeleteCell'
import Dialog from 'domains/application/modals/Dialog'
import deleteComparisonWorkflow from 'domains/comparisons/workflows/deleteComparison'
import ComparisonListNotesSubRow from 'domains/comparisons/components/ComparisonListNotesSubRow'
import {
  comparisonListSupplierProductCell,
  comparisonListDistrictProductCell,
  comparisonListDistrictNameCell,
  comparisonListRosteringMethodCell,
  defaultComparisonListColumns,
  comparisonListCharacterizationCell,
  getComparisonListNotesCell,
  comparisonListStatusCell,
  comparisonListDistrictStateCell,
} from 'domains/products/components/ComparisonListColumnDefinitions'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import { OpenTableRows, IOpenTableRows } from 'lib/hooks/OpenTableRows'
import BaseComparisonsBreadCrumbs from 'domains/comparisons/components/BaseComparisonsBreadCrumbs'

const keyExtractor = (comparison: IComparison, isSubRow: boolean) =>
  `${comparison.id}${comparison.zombie ? '-zombie' : ''}${
    isSubRow ? 'sub-row' : ''
  }`

export default function ComparisonsScreen(props: RouteComponentProps) {
  const [comparisonNotes, setComparisonNotes] = React.useState<
    IComparison | boolean
  >(false)
  const [deleteComparison, setDeleteComparison] = React.useState<
    IComparison | boolean
  >(false)

  const orgId = useSelector((s: RootState) =>
    myOrganizationIdSelector(s, props),
  )
  const comparisons = useSelector((s: RootState) =>
    records.relatedRecordsSelectors.fullRelatedRecordsSelector(
      COMPARISONS_RECORD,
      myOrganizationIdSelector,
    )(s, props),
  )
  const isSupplier = useSelector(isSupplierRoleSelector)

  const onShowNotes = (comp: IComparison) => setComparisonNotes(comp)

  const onCloseNotes =
    (
      onCloseRow: (
        id: string | number,
        wasSaved?: boolean,
        wasCleared?: boolean,
      ) => any,
    ) =>
    (wasSaved?: boolean, wasCleared?: boolean) => {
      onCloseRow((comparisonNotes as IComparison).id, wasSaved, wasCleared)
      setComparisonNotes(false)
    }

  const onToggleNotes =
    (toggleRow: (id: string | number) => any) => (comparison: IComparison) => {
      if (utils.hasValue(comparison.notes)) {
        toggleRow(comparison.id)
      } else {
        onShowNotes(comparison)
      }
    }

  const onCloseDeleteDialog = () => setDeleteComparison(false)

  const onOpenDeleteDialog = (comp: IComparison) => setDeleteComparison(comp)

  const onDeleteConfirmed = async () => {
    if (deleteComparison) {
      await deleteComparisonWorkflow((deleteComparison as IComparison).id)
      onCloseDeleteDialog()
    }
  }

  const onRowClick = (column: IComparison) =>
    props.history.push(getComparisonReportRoute(column.id, column.format))

  const getColumns = memoize(
    (openTableRowsOptions: IOpenTableRows, supplier: boolean) => {
      const notesCell = getComparisonListNotesCell(
        openTableRowsOptions.OpenTableRows,
        onToggleNotes(openTableRowsOptions.onToggleRow),
      )

      if (supplier) {
        return [
          comparisonListSupplierProductCell,
          comparisonListDistrictNameCell,
          comparisonListDistrictStateCell,
          comparisonListRosteringMethodCell,
          ...defaultComparisonListColumns,
          notesCell,
          {
            title: 'Delete',
            noSort: true,
            forceCenter: true,
            maxWidth: 50,
            CellComponent: ComparisonDeleteCell,
            cellProps: { onClick: onOpenDeleteDialog },
          },
        ]
      }

      return [
        comparisonListCharacterizationCell,
        comparisonListDistrictProductCell,
        ...defaultComparisonListColumns,
        comparisonListStatusCell,
        notesCell,
        {
          title: 'Delete',
          noSort: true,
          forceCenter: true,
          maxWidth: 50,
          CellComponent: ComparisonDeleteCell,
          cellProps: { onClick: onOpenDeleteDialog },
        },
      ]
    },
  )

  const getListName = () =>
    `${isSupplier ? 'supplier' : 'district'}-comparisons-list`

  const canClickRow = (comparison: IComparison) => !comparison.zombie

  const renderSubRow = (OpenTableRows: any) => (comparison: IComparison) =>
    OpenTableRows[comparison.id] ? (
      <ComparisonListNotesSubRow
        comparison={comparison}
        onShowNotes={onShowNotes}
      />
    ) : (
      false
    )

  const renderComparisonNotesModal = (
    onCloseRow: (
      id: string | number,
      wasSaved?: boolean,
      wasCleared?: boolean,
    ) => any,
  ) => {
    if (!comparisonNotes) {
      return null
    }

    const comparison = comparisonNotes as IComparison
    return (
      <ComparisonReportNotesModal
        isOpen={true}
        closeModal={onCloseNotes(onCloseRow)}
        comparison={comparison}
        note={comparison.notes}
      />
    )
  }

  const renderDeleteMessage = () => {
    if (!deleteComparison) {
      return ''
    }
    return (
      <Text>
        You are about to delete the comparison report between{' '}
        <Span fontWeight={700}>
          {(deleteComparison as IComparison).characterizationName}
        </Span>{' '}
        and{' '}
        <Span fontWeight={700}>
          {(deleteComparison as IComparison).productName}
        </Span>
        . This action cannot be undone.
      </Text>
    )
  }

  return (
    <OpenTableRows>
      {(openTableRowsOptions: IOpenTableRows) => (
        <View variant="screen">
          <DocumentTitle title="Comparisons" />
          <BaseComparisonsBreadCrumbs />
          <List
            title={'All Comparisons'}
            record={COMPARISONS_RECORD}
            recordState={comparisons}
            parentRecordId={orgId}
            getRecordsParams={{ isSupplier }}
            columns={getColumns(openTableRowsOptions, isSupplier)}
            renderSubRow={renderSubRow(openTableRowsOptions.OpenTableRows)}
            rowHeight={68}
            dataTest={getListName()}
            trackAnalytics={trackComparisonAnalytics}
            trackAnalyticsCategory={getListName()}
            initialSortColumnIndex={isSupplier ? 7 : 5}
            initialSortDescending={true}
            onRowClick={onRowClick}
            canClickRow={canClickRow}
            keyExtractor={keyExtractor}
            searchTemplate={
              isSupplier
                ? 'productName=~@@ OR districtName=~@@'
                : 'characterizationName=~@@ OR productName=~@@'
            }
          />

          <Dialog
            isOpen={utils.hasValue(deleteComparison)}
            icon="fas fa-trash"
            message={renderDeleteMessage}
            confirmText="Yes, Delete"
            onConfirm={onDeleteConfirmed}
            onDeny={onCloseDeleteDialog}
          />
          {renderComparisonNotesModal(openTableRowsOptions.onCloseRow)}
        </View>
      )}
    </OpenTableRows>
  )
}
