import React from 'react'

import View from 'lib/components/View'
import InputLabel from 'lib/components/InputLabel'
import Span from 'lib/components/Span'
import Input from 'lib/components/Input'
import FormErrorMessage from 'lib/components/FormErrorMessage'
import { TextArea } from 'lib/components'
import { utils } from '@ims/1edtech-frontend-common'

export interface IFormInputProps {
  label?: string
  labelDataTest?: string
  required?: boolean
  name: string
  placeholder: string
  value: any
  valueDataTest: string
  handleChange: any
  handleBlur?: any
  showErrorImmediately?: boolean
  showRequiredOnlyOnSumbit?: boolean
  type?: 'text' | 'password'
  textArea?: boolean
  inputProps?: any
  inputLabelProps?: any
  noMargin?: boolean
  disabled?: boolean
  className?: string
}

export default function FormInput(props: IFormInputProps) {
  const TextComponent = (props.textArea ? TextArea : Input) as any
  return (
    <View mb={props.noMargin ? 0 : 3} className={props.className}>
      {utils.hasValue(props.label) && (
        <InputLabel
          data-test={props.labelDataTest}
          {...(props.inputLabelProps || {})}
        >
          {props.label} {props.required ? <Span color="error">*</Span> : null}
        </InputLabel>
      )}

      <TextComponent
        placeholder={props.placeholder}
        name={props.name}
        value={props.value}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        type={props.type || 'text'}
        autoComplete="off"
        disabled={props.disabled}
        variant={props.disabled ? 'disabled' : 'default'}
        {...(props.inputProps || {})}
      />
      <FormErrorMessage
        name={props.name}
        showImmediately={props.showErrorImmediately}
        showRequiredOnlyOnSumbit={props.showRequiredOnlyOnSumbit}
      />
    </View>
  )
}
