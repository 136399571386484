import React from 'react'
import { Formik, Form, FormikHelpers, FormikProps } from 'formik'
import * as Yup from 'yup'
import { utils } from '@ims/1edtech-frontend-common'
import * as R from 'ramda'

import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import { showToast } from 'lib/utils/toast'
import { Paper, Button, FormInput, Checkbox } from 'lib/components'
import CopyableUrls from 'lib/components/CopyableUrls'

import { postRequest } from 'lib/api/api'

import { isLTIISSAndClientIdAvailable } from 'domains/trustedApps/workflows/isLTIISSAndClientIdAvailable'
import ILTIConfiguration from 'domains/trustedApps/models/ILTIConfiguration'
import { SWITCHBOARD_BASE_URL } from 'lib/api/constants'

interface IProps {
  orgId: string
  onSubmitSuccess: () => void
}

export default function TrustedAppsAddLtiConfig(props: IProps) {
  const { orgId, onSubmitSuccess } = props
  const [agreed, setAgreed] = React.useState(false)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Connection name is required'),
    clientId: Yup.string().required('Client Id is required'),
    iss: Yup.string().required('ISS Id is required'),
    authServerUrl: Yup.string()
      .url('Auth Server URL must be a valid URL')
      .required('Auth Server URL is required'),
    jwksUrl: Yup.string()
      .url('JWKS URL must be a valid URL')
      .required('JWKS URL is required'),
  })

  const onSubmit = async (
    values: ILTIConfiguration,
    bag: FormikHelpers<ILTIConfiguration>,
  ) => {
    try {
      const { success } = await postRequest(
        `orgs/${orgId}/lti/configuration`,
        values,
      )
      if (success) {
        showToast('start', 'Successfully added LTI Config')
        onSubmitSuccess()
      } else showToast('error', 'Failed to add LTI Config')
    } catch (e) {
      showToast('error', 'Error adding LTI configuration')
    }
    bag.setSubmitting(false)
  }

  let validateTimeout: any
  const onValidate = (values: ILTIConfiguration) =>
    new Promise((resolve) => {
      let errors = {}
      if (utils.hasValue(values.clientId) && utils.hasValue(values.iss)) {
        clearTimeout(validateTimeout)
        validateTimeout = setTimeout(async () => {
          const isAvailable = await isLTIISSAndClientIdAvailable(
            orgId,
            values.iss,
            values.clientId,
          )
          if (!isAvailable) {
            errors = R.assoc(
              'clientId',
              'This combination of Client ID and Issuer ID is already taken',
              errors,
            )
          }

          resolve(errors)
        }, 350)
      } else {
        resolve(errors)
      }
    })

  return (
    <Paper className="my-4">
      <div className="pb-4">
        <WidgetHeader title="Add New Connection" />
      </div>
      <Formik
        initialValues={{
          iss: '',
          authServerUrl: '',
          jwksUrl: '',
          clientId: '',
          name: '',
        }}
        validationSchema={validationSchema}
        validate={onValidate}
        onSubmit={onSubmit}
      >
        {(bag: FormikProps<ILTIConfiguration>) => {
          return (
            <Form>
              <div className="space-y-2">
                <CopyableUrls
                  label="TrustEd Apps OIDC Login initiation URL"
                  value={`${SWITCHBOARD_BASE_URL}/lti/initialize`}
                  allowCopy
                />
                <CopyableUrls
                  label="TrustEd Apps Launch URL"
                  value={`${SWITCHBOARD_BASE_URL}/trustedapps/lti/login`}
                  allowCopy
                />
                <CopyableUrls
                  label="TrustEd Apps Public JWK URL"
                  value="https://oauth2server.imsglobal.org/jwks"
                  allowCopy
                  optional
                />
              </div>
              <FormInput
                name="name"
                label="Connection Name"
                required
                value={bag.values.name}
                valueDataTest="data-test-connection-name"
                placeholder="Connection Name"
                handleChange={bag.handleChange}
                aria-label="Connection Name"
              />
              <FormInput
                name="clientId"
                label="Client ID"
                required
                value={bag.values.clientId}
                valueDataTest="data-test-client-id"
                placeholder="Client ID"
                handleChange={bag.handleChange}
                aria-label="Client ID"
              />
              <FormInput
                name="iss"
                label="Issuer ID (iss)"
                required
                value={bag.values.iss}
                valueDataTest="data-test-issuer"
                placeholder="Issuer"
                handleChange={bag.handleChange}
                aria-label="Issuer"
              />
              <FormInput
                name="authServerUrl"
                label="OIDC URL"
                required
                value={bag.values.authServerUrl}
                valueDataTest="data-test-auth-server-url"
                placeholder="Auth Server Url"
                handleChange={bag.handleChange}
                aria-label="Auth Server Url"
              />
              <FormInput
                name="jwksUrl"
                label="JWKS URL"
                required
                value={bag.values.jwksUrl}
                valueDataTest="data-test-jwks-url"
                placeholder="JWKS URL"
                handleChange={bag.handleChange}
                aria-label="JWKS URL"
              />
              <div className="my-4 flex">
                <Checkbox
                  name="agreement"
                  checked={agreed}
                  onChange={() => setAgreed(!agreed)}
                  data-test="checkbox-agreement"
                  required
                >
                  I understand that my support for this configuration may be
                  limited if I choose to use a platform that is not LTI v1.3
                  Certified.
                </Checkbox>
              </div>

              <Button
                type="submit"
                disabled={!agreed}
                variant={agreed ? 'primary' : 'disabled'}
              >
                Add Connection
              </Button>
            </Form>
          )
        }}
      </Formik>
    </Paper>
  )
}
