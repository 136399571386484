import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Paper, Button } from 'lib/components'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import TrustedAppsConfigExpansionHeader from 'domains/trustedApps/components/TrustedAppsConfigExpansionHeader'
import TrustedAppsAddLtiConfig from 'domains/trustedApps/components/TrustedAppsAddLtiConfig'

import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import ILTIConfiguration from 'domains/trustedApps/models/ILTIConfiguration'

interface IProps {
    configs: ILTIConfiguration[]
    updateList: () => void
}
export default function TrustedAppsConnectionList(props: IProps) {
    const { configs, updateList } = props
    const orgId = useSelector((s: any) => myOrganizationIdSelector(s, {}))

    const [addNew, setAddNew] = useState(false)

    const onUpdateList = React.useCallback(async () => {
        updateList()
        setAddNew(false)
    }, [configs]) //eslint-disable-line

    React.useEffect(() => {}, [configs])

    return (
        <Paper
            flex={1}
            flexDirection={'column'}
            minHeight={'100%'}
            width={'100%'}
        >
            <WidgetHeader title="LTI Connections" />
            {configs &&
                configs.map((connection: ILTIConfiguration) => {
                    return (
                        <TrustedAppsConfigExpansionHeader
                            ltiConfiguration={connection}
                            onUpdate={onUpdateList}
                            key={connection.name}
                        />
                    )
                })}
            {addNew && (
                <TrustedAppsAddLtiConfig
                    orgId={orgId}
                    onSubmitSuccess={onUpdateList}
                />
            )}
            <Button
                variant={addNew ? 'gray-500' : 'start'}
                className="my-4"
                onClick={() => setAddNew(!addNew)}
            >
                {addNew ? 'Close' : 'Add New Connection'}
            </Button>
        </Paper>
    )
}
