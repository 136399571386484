import React, { PureComponent } from 'react'
import { SketchPicker, ColorResult } from 'react-color'

import View from 'lib/components/View'
import Button from 'lib/components/Button'

import './ColorPickerModal.css'

const styles = {
  popover: {
    position: 'absolute' as 'absolute', // eslint-disable-line
    zIndex: 3,
    right: '0px',
  },
  cover: {
    position: 'fixed' as 'fixed', // eslint-disable-line
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
    zIndex: 2,
  },
}

interface IProps {
  onChange: (color: string) => void
  onDone: (color: string) => void
  color: string
  open: boolean
}

export default class ColorPickerModal extends PureComponent<IProps> {
  readonly state = {
    color: this.props.color,
  }

  onDone = () => this.props.onDone(this.state.color)

  onChange = (result: ColorResult) => {
    this.setState({ color: result.hex })
    this.props.onChange(this.state.color)
  }

  render() {
    if (!this.props.open) {
      return null
    }
    return (
      <View position="relative">
        <View style={styles.cover} onClick={this.onDone} />
        <View
          flexible="column-center"
          position="absolute"
          zIndex={3}
          right={0}
          bg="white"
          borderRadius="4px"
          elevation="one"
          pb={2}
        >
          <SketchPicker
            color={this.state.color}
            onChangeComplete={this.onChange}
          />
          <Button mt={2} onClick={this.onDone}>
            DONE
          </Button>
        </View>
      </View>
    )
  }
}
