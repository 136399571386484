import React from 'react'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import { useHistory } from 'react-router'
import { utils } from '@ims/1edtech-frontend-common'

import { View, Button, Icon, Text } from 'lib/components'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import { getCharacterizationReportStatus } from 'domains/characterizations/utils/characterizationReport'
import ITransformedCharacterizationCSVReport from 'domains/characterizations/models/ITransformedCharacterizationCSVReport'
import ComparisonModal from 'domains/comparisons/modals/ComparisonModal'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import IProduct from 'domains/products/models/IProduct'
import ProductCatalogStatusCell from 'domains/products/components/ProductCatalog/ProductCatalogStatusCell'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import { RED_REPORT_STATUS } from 'domains/reports/models/IReportStatuses'
import ICSVModelCharacterization from 'domains/characterizations/models/ICSVModelCharacterization'
import ICSVConsumerModel from 'domains/modeling/models/ICSVConsumerModel'
import IRestCModelCharacterization from 'domains/characterizations/models/IRestCModelCharacterization'
import ITransformedRESTCharacterizationReport from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import IRestCModel from 'domains/modeling/models/IRestCModel'
import {
  AnySpecFormatType,
  ONER_V1P1_CSV_ROSTERING_EXPORT_BULK,
  ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL,
} from 'domains/formats/constants/formats'
import { isOneRosterCSVFormat } from 'domains/formats/utils/isFormatOfType'
import { getCSVCharacterizationDownloadLink } from 'lib/records/modules/orCSVCharacterizations'
import DownloadReport from 'domains/reports/components/DownloadReport'
import { getCSVCModelCharacterizationDownloadLink } from 'lib/records/modules/csvCModelCharacterizations'
import { isSupplierRoleSelector } from 'domains/authentication/selectors/roles'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import { PRINT_ROOT_ROUTE } from 'domains/application/navigation/routes'
import { isReportDebuggingEnabled } from 'domains/reports/components/DebugReportView'

interface IProps {
  characterization:
    | ICharacterization
    | ICSVModelCharacterization
    | IRestCModelCharacterization
  report:
    | ITransformedCharacterizationCSVReport
    | ITransformedRESTCharacterizationReport
  collapseAll: () => void
  expandAll: () => void
  product?: IProduct
  model?: ICSVConsumerModel | IRestCModel
  format: AnySpecFormatType
  height: number
  shouldHidePrint?: boolean
}
interface IButtonProps {
  variant: string
  title: string
  onClick?: () => void
  badgeType?: string
  badgeContent?: any
  icon?: string
  color?: string
  disabled?: boolean
  pending?: boolean
}

export default function CharacterizationDetailsActionsWidget(props: IProps) {
  const { location } = useHistory()
  const isSupplier = useSelector(isSupplierRoleSelector)
  const orgId = useSelector((s: any) => myOrganizationIdSelector(s, props))
  const [comparisonOpen, setComparisonOpen] = React.useState(false)
  const [startDownload, setStartDownload] = React.useState(false)
  const isCSV = isOneRosterCSVFormat(props.format)

  const openComparison = () => setComparisonOpen(true)
  const closeComparison = () => setComparisonOpen(false)

  const onDownload = () => {
    if (isCSV) {
      setStartDownload(true)
    }
  }
  const onPrint =
    (debug = false) =>
    () =>
      window.open(
        `${PRINT_ROOT_ROUTE}${location.pathname}${debug ? '?debug=true' : ''}`,
      )
  const onDownloadComplete = () => setStartDownload(false)

  const renderButton = (options: IButtonProps) => {
    const {
      variant,
      title,
      onClick,
      badgeType,
      badgeContent,
      icon = '',
      color = 'white',
      disabled = false,
      pending = false,
      ...extra
    } = options
    return (
      <Button
        variant={variant}
        py={3}
        width="100%"
        onClick={onClick}
        badgeType={badgeType}
        badgeContent={badgeContent}
        data-test={`characterization-action-btn-${title}`}
        large={true}
        mb={3}
        disabled={disabled}
        pending={pending}
        {...extra}
      >
        <View flexible="row-center">
          {utils.hasValue(icon) && (
            <Icon className={icon || ''} color={color} mr={2} />
          )}
          <Text color={color} fontSize={18}>
            {title}
          </Text>
        </View>
      </Button>
    )
  }

  const reportStatus = getCharacterizationReportStatus(props.report)
  const isRostering =
    props.format === ONER_V1P1_CSV_ROSTERING_EXPORT_BULK ||
    // TODO: Uncomment below once OR1p2 Comparisons are available 
    //props.format === ONER_V1P2_CSV_ROSTERING_EXPORT_BULK ||
    props.format === ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL
  const canCompare =
    !props.report.unavailable &&
    !R.propOr(false, 'hasErrors', props.report) &&
    reportStatus !== RED_REPORT_STATUS &&
    (!isSupplier ||
      (utils.convertToInt(
        R.pathOr(0, ['characterization', 'organization', 'id'], props),
      ) === orgId &&
        !props.model))
  const hasTopLevelErrors = isCSV
    ? (props.report as ITransformedCharacterizationCSVReport).hasFileSetErrors
    : false
  return (
    <DetailsTopWidget
      title="Available Actions"
      dataTest="characterization-actions-widget"
      height={props.height}
    >
      <View flexible="column-h-center">
        <View>
          {isCSV &&
            false &&
            renderButton({
              variant: 'complete',
              title: 'Download Report',
              icon: 'fas fa-download',
              color: 'white',
              onClick: onDownload,
            })}

          {!props.shouldHidePrint && 
              <div className="flex flex-row items-center">
                {renderButton({
                  variant: 'complete',
                  title: 'Print Report',
                  icon: 'fas fa-print',
                  color: 'white',
                  onClick: onPrint(),
                })}
                {isReportDebuggingEnabled() &&
                  renderButton({
                    variant: 'complete',
                    title: 'Print (debug)',
                    icon: 'fas fa-print',
                    color: 'white',
                    onClick: onPrint(true),
                  })}
              </div>
          }
          {renderButton({
            variant: hasTopLevelErrors ? 'neutral-dark-text' : 'complete',
            onClick: props.collapseAll,
            title: 'Collapse All',
            icon: 'fas fa-arrow-circle-up',
            disabled: hasTopLevelErrors,
            color: hasTopLevelErrors ? 'text' : 'white',
          })}
          {renderButton({
            variant: hasTopLevelErrors ? 'neutral-dark-text' : 'complete',
            onClick: props.expandAll,
            title: 'Expand All',
            icon: 'fas fa-arrow-circle-down',
            disabled: hasTopLevelErrors,
            color: hasTopLevelErrors ? 'text' : 'white',
          })}
          {isRostering
            ? renderButton({
                variant: canCompare ? 'complete' : 'neutral-dark-text',
                title: 'Compare',
                icon: 'fas fa-poll',
                disabled: !canCompare,
                color: canCompare ? 'white' : 'text',
                onClick: openComparison,
              })
            : null}
        </View>

        {reportStatus === RED_REPORT_STATUS && (
          <Text fontStyle="italic" textAlign="center">
            You cannot launch comparison until all critical exceptions are
            resolved.
          </Text>
        )}

        {props.product && (
          <View width="100%">
            <WidgetHeader title="Current Status" />
            <View width="76px" mt={3}>
              <ProductCatalogStatusCell
                {...props.product}
                overrideFormat={props.format}
                isSingleProduct={true}
              />
            </View>
          </View>
        )}
      </View>
      {!utils.hasValue(props.model) && (
        <ComparisonModal
          startWithProduct={false}
          isOpen={comparisonOpen}
          closeModal={closeComparison}
          format={props.characterization.format}
          id={
            props.product
              ? ''
              : (props.characterization as ICharacterization).id
          }
        />
      )}

      {isCSV && (
        <DownloadReport
          startDownload={startDownload}
          url={
            props.product
              ? getCSVCModelCharacterizationDownloadLink(props.model!.id)
              : getCSVCharacterizationDownloadLink(
                  (props.characterization as ICharacterization).id,
                )
          }
          onDownloadComplete={onDownloadComplete}
        />
      )}
    </DetailsTopWidget>
  )
}
