import * as R from 'ramda'
import { createSelector } from 'reselect'
import { utils, records } from '@ims/1edtech-frontend-common'

import { CSV_C_MODELS_RECORD } from 'lib/records/modules/csvCModels'
import { RootState } from 'lib/store/rootReducer'
import ICSVConsumerModel, {
  ICSVConsumerFileColumn,
} from 'domains/modeling/models/ICSVConsumerModel'
import { ICSVConsumerFile } from 'domains/modeling/models/ICSVConsumerModel'
import ICSVSpecification from 'domains/specifications/models/ICSVSpecification'
import ICSVSpecificationFile from 'domains/specifications/models/ICSVSpecificationFile'
import ICSVSpecificationFileColumn from 'domains/specifications/models/ICSVSpecificationFileColumn'

export const csvModelCompletionSelector = (
  getModelIdSelector: records.entitiesSelectors.getIdType,
) =>
  createSelector<RootState, any, ICSVConsumerModel, number>(
    records.entitiesSelectors.entityByIdSelector(
      CSV_C_MODELS_RECORD,
      getModelIdSelector,
    ),
    (model) => {
      const files = R.propOr<
        ICSVConsumerFile[],
        ICSVConsumerModel,
        ICSVConsumerFile[]
      >([], 'files', model)
      const touchedModelFilesCount = R.compose<
        ICSVConsumerFile[],
        ICSVConsumerFile[],
        number
      >(
        R.length,
        R.filter<ICSVConsumerFile>((file) =>
          R.propOr<boolean, ICSVConsumerFile, boolean>(false, 'touched', file),
        ),
      )(files)
      if (touchedModelFilesCount === 0) {
        return 0
      }

      return utils.getPercentage(touchedModelFilesCount / R.length(files))
    },
  )

export const specFileSelector = (
  specSelector: any,
  getFileName: records.entitiesSelectors.getIdType,
) =>
  createSelector(
    specSelector,
    records.entitiesSelectors.idSelector(getFileName),
    (specification, fileName) =>
      (R.find(
        R.propEq('name', fileName),
        R.propOr([], 'files', specification),
      ) || null) as ICSVSpecificationFile | null,
  )

// Currently their schema is essentially the same
export const modelFileSelector = (
  modelSelector: any,
  getFileName: records.entitiesSelectors.getIdType,
) =>
  createSelector(
    modelSelector,
    records.entitiesSelectors.idSelector(getFileName),
    (model, fileName) =>
      (R.find(R.propEq('name', fileName), R.propOr([], 'files', model)) ||
        null) as ICSVConsumerFile | null,
  )

export const specFileIndexSelector = (
  specSelector: any,
  getFileName: records.entitiesSelectors.getIdType,
) =>
  createSelector<RootState, any, ICSVSpecification, string, number>(
    specSelector,
    records.entitiesSelectors.idSelector(getFileName),
    (specification, fileName) =>
      R.findIndex(
        R.propEq('name', fileName),
        R.propOr([], 'files', specification),
      ),
  )

export const modelFileIndexSelector = (
  specSelector: any,
  getFileName: records.entitiesSelectors.getIdType,
) => specFileIndexSelector(specSelector, getFileName)

export const specFileColumnIndexSelector = (
  specSelector: any,
  getFileName: records.entitiesSelectors.getIdType,
  getModelName: records.entitiesSelectors.getIdType,
) =>
  createSelector<RootState, any, ICSVSpecification, number, string, number>(
    specSelector,
    specFileIndexSelector(specSelector, getFileName),
    records.entitiesSelectors.idSelector(getModelName),
    (specification, fileIndex, columnName) =>
      R.findIndex(
        R.propEq('columnName', columnName),
        R.pathOr([], ['files', fileIndex, 'columns'], specification),
      ),
  )

export const modelFileColumnIndexSelector = (
  modelSelector: any,
  getFileName: records.entitiesSelectors.getIdType,
  getModelName: records.entitiesSelectors.getIdType,
) =>
  createSelector<RootState, any, ICSVConsumerModel, number, string, number>(
    modelSelector,
    modelFileIndexSelector(modelSelector, getFileName),
    records.entitiesSelectors.idSelector(getModelName),
    (model, fileIndex, columnName) =>
      R.findIndex(
        R.propEq('header', columnName),
        R.pathOr([], ['files', fileIndex, 'columns'], model),
      ),
  )

export const findSpecFileColumn = (
  specFile: ICSVSpecificationFile,
  columnName: string,
): ICSVSpecificationFileColumn | null =>
  (R.find(
    R.propEq('columnName', columnName),
    R.propOr([], 'columns', specFile),
  ) || null) as ICSVSpecificationFileColumn | null

export const findSpecColumn = (
  specification: ICSVSpecification,
  fileName: string,
  columnName: string,
) =>
  R.compose(
    (specFile) =>
      findSpecFileColumn(specFile as ICSVSpecificationFile, columnName),
    R.find(R.propEq('name', fileName)),
    R.propOr([], 'files'),
  )(specification) || null

export const specFileColumnSelector = (
  specSelector: any,
  getFileName: records.entitiesSelectors.getIdType,
  getColumnName: records.entitiesSelectors.getIdType,
) =>
  createSelector(
    specFileSelector(specSelector, getFileName),
    records.entitiesSelectors.idSelector(getColumnName),
    (specFile, columnName) =>
      findSpecFileColumn(specFile as ICSVSpecificationFile, columnName),
  )

export const modelFileColumnSelector = (
  modelSelector: any,
  getFileName: records.entitiesSelectors.getIdType,
  getColumnName: records.entitiesSelectors.getIdType,
) =>
  createSelector(
    specFileSelector(modelSelector, getFileName),
    records.entitiesSelectors.idSelector(getColumnName),
    (file, columnName) =>
      (R.find(R.propEq('header', columnName), R.propOr([], 'columns', file)) ||
        null) as ICSVConsumerFileColumn | null,
  )
