import React from 'react'
import * as R from 'ramda'
import { RouteComponentProps } from 'react-router'
import { utils, records } from '@ims/1edtech-frontend-common'
import { useSelector } from 'react-redux'
import { postRequest } from 'lib/api/api'

import { ORGANIZATIONS_RECORD } from 'lib/records/modules/organizations'
import { RootState } from 'lib/store/rootReducer'
import {
  View,
  List,
  Button,
  ListCallToActionButton,
  Switch,
} from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import BaseBreadCrumbs from 'domains/application/components/BaseBreadCrumbs'
import trackOrganizationAnalytics from 'domains/orgs/utils/trackOrganizationAnalytics'
import { IListColumnDefinition } from 'lib/components/List'
import IOrg from 'domains/orgs/models/IOrg'
import { login } from 'domains/authentication/workflows/login'
import { showToast, ERROR_TOAST } from 'lib/utils/toast'
import OrganizationNameCell from 'domains/orgs/components/OrganizationNameCell'
import CreateEditOrganizationModal from 'domains/orgs/modals/CreateEditOrganizationModal'
import { IMS_ORG_TYPE } from 'domains/authentication/constants/organizations'
import {
  getRoute,
  ORGANIZATIONS_ROUTE,
} from 'domains/application/navigation/routes'
import OrganizationLockoutDateCell from 'domains/orgs/components/OrganizationLockoutDateCell'

export default function AdminOrganizationsScreen(props: RouteComponentProps) {
  const orgs = useSelector((state: RootState) =>
    records.recordsSelectors.fullRecordsSelector(ORGANIZATIONS_RECORD)(
      state,
      props,
    ),
  )
  const [isCreateOpen, setIsCreateOpen] = React.useState(false)
  const [filterLockedOut, setFilterLockedOut] = React.useState(false)

  const onLoginAsOrg = (org: IOrg) => async () => {
    await postRequest('trustedapps/logout')
    const success = await login('', '', org.id)
    if (!success) {
      showToast(ERROR_TOAST, `Failed to login with Org: ${org.name}`)
    }
  }

  const columns: IListColumnDefinition[] = [
    {
      title: 'Name',
      maxWidth: 'auto',
      CellComponent: OrganizationNameCell,
      cellProps: { maxWidth: ['auto'] },
      sortKey: utils.sort.addSortValue('name@'),
    },
    {
      title: 'Type',
      noHeaderCenter: true,
      maxWidth: 72,
      accessor: 'type',
      sortKey: utils.sort.addSortValue('orgType@,name:ascending'),
    },
    {
      title: 'Segment',
      noHeaderCenter: true,
      maxWidth: 72,
      accessor: 'segment',
      noSort: true,
    },
    {
      title: 'Membership Level',
      noHeaderCenter: true,
      maxWidth: 140,
      accessor: 'membershipLevel',
      sortKey: utils.sort.addSortValue('membershipLevel@,name:ascending'),
    },
    {
      title: 'Lockout Date',
      noHeaderCenter: true,
      maxWidth: 128,
      CellComponent: OrganizationLockoutDateCell,
      sortKey: utils.sort.addSortValue('lockoutDate@,name:ascending'),
    },
    {
      title: 'Referrer',
      maxWidth: 148,
      noHeaderCenter: true,
      noCenter: true,
      accessor: (org: IOrg) => (org.referrerName ? org.referrerName : 'N/A'),
      sortKey: utils.sort.addSortValue('referrer@,name:ascending'),
    },
    // {
    //   title: 'PP',
    //   noHeaderCenter: true,
    //   maxWidth: 64,
    //   CellComponent: OrganizationPPBadgeCell,
    //   sortKey: utils.sort.addSortValue(
    //     'preferredPartner@,name:ascending',
    //     true,
    //   ),
    // },
    {
      title: 'Last Active',
      noHeaderCenter: true,
      maxWidth: 136,
      accessor: (org: IOrg) =>
        org.lastActive
          ? utils.date.formatForDisplay(org.lastActive)
          : 'No Logins',
      sortKey: utils.sort.addSortValue('lastActive@,name:ascending', true),
    },
    {
      title: null,
      maxWidth: 98,
      noSort: true,
      renderCell: (org: IOrg) => (
        <Button
          variant="start"
          onClick={onLoginAsOrg(org)}
          data-test={`login-btn-${org.id}`}
        >
          Login
        </Button>
      ),
    },
  ]

  const onToggleFilterLockedOut = () => setFilterLockedOut(R.not)

  const onAddNew = () => setIsCreateOpen(true)

  const onCloseAddNew = (org?: IOrg) => {
    setIsCreateOpen(false)
    if (org) {
      const route = getRoute(ORGANIZATIONS_ROUTE, org.id)
      props.history.push(route)
    }
  }

  return (
    <View variant="screen">
      <DocumentTitle title="Organizations" />
      <BaseBreadCrumbs crumbs={[{ name: 'Organizations' }]} />

      <List
        title="All Organizations"
        record={ORGANIZATIONS_RECORD}
        recordState={{
          ...orgs,
          items: orgs.items.filter((org: IOrg) => org.type !== IMS_ORG_TYPE),
        }}
        columns={columns}
        rowHeight={68}
        dataTest="organizations-list"
        initialSortColumnIndex={0}
        trackAnalytics={trackOrganizationAnalytics}
        trackAnalyticsCategory="admin_organizations"
        searchTemplate="name=~@@"
        baseFilterParam={
          filterLockedOut
            ? `lockoutDate>${new Date().getTime()} OR lockoutDate=0`
            : undefined
        }
        callToAction={
          <div className="flex flex-row items-center space-x-3 ml-3">
            <div className="flex flex-col items-center justify-center space-y-1">
              <Switch
                on={filterLockedOut}
                onChange={onToggleFilterLockedOut}
                dataTest="show-missing-metadata-switch"
                data-test="filter-lockout-btn"
                small={true}
                onIconName="fas fa-eye-slash"
                offIconName="fas fa-eye"
              />
              <p className="text-gray-500 text-xs text-center">
                Hide Locked Out
              </p>
            </div>

            <ListCallToActionButton onClick={onAddNew} text="New District" />
          </div>
        }
      />

      <CreateEditOrganizationModal
        isCreate={true}
        isOpen={isCreateOpen}
        closeModal={onCloseAddNew}
      />
    </View>
  )
}
