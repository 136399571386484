import {
  REQUIRED_REQUIRED_FIELD,
  OPTIONAL_REQUIRED_FIELD,
  PROHIBITED_REQUIRED_FIELD,
} from 'domains/modeling/constants/columns'
import {
  GREEN_REPORT_STATUS,
  AMBER_REPORT_STATUS,
  RED_REPORT_STATUS,
} from 'domains/reports/models/IReportStatuses'
import { ICSVFileColumnComparisonReport } from 'domains/comparisons/models/ICSVComparisonReport'
import colors from 'lib/styles/colors'

export const REQUIRED_COMP_REPORT_ICON = 'fas fa-asterisk'
export const PROHIBITED_COMP_REPORT_ICON = 'fas fa-ban'
export const OPTIONAL_COMP_REPORT_ICON = 'fas fa-circle-notch'

export const STATUS_TO_ROW_BG_MAP: { [key: string]: string } = {
  [GREEN_REPORT_STATUS]: colors.reportSuccessLight,
  [AMBER_REPORT_STATUS]: colors.reportAmberLight,
  [RED_REPORT_STATUS]: colors.reportAlertLight,
}

export const getColumnRowBackground = (
  column: ICSVFileColumnComparisonReport,
) => {
  if (column.metadata && column.status === GREEN_REPORT_STATUS) {
    if (column.customMetadata) {
      return colors.customMetaDataBG
    }
    return colors.builtInMetaDataBG
  }
  return STATUS_TO_ROW_BG_MAP[column.status]
}

export const REQUIRED_TO_ICON_MAP: { [key: string]: string } = {
  [REQUIRED_REQUIRED_FIELD]: REQUIRED_COMP_REPORT_ICON,
  [OPTIONAL_REQUIRED_FIELD]: OPTIONAL_COMP_REPORT_ICON,
  [PROHIBITED_REQUIRED_FIELD]: PROHIBITED_COMP_REPORT_ICON,
}

export const REQUIRED_TO_ICON_COLOR_MAP: { [key: string]: string } = {
  [REQUIRED_REQUIRED_FIELD]: 'info',
  [PROHIBITED_REQUIRED_FIELD]: 'text',
  [OPTIONAL_REQUIRED_FIELD]: 'success',
}
