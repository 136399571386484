import * as R from 'ramda'
import { utils, records } from '@ims/1edtech-frontend-common'

import { CSV_C_MODELS_RECORD } from 'lib/records/modules/csvCModels'
import store from 'lib/store/index'
import { getFileIndexByFileName } from 'domains/modeling/workflows/csvCModelFile'
import { replaceEntityProperty } from 'lib/store/entities.actions'

export const createNewColumn = (
  modelId: string | number,
  fileName: string,
  column: any,
) => {
  const model = records.entitiesSelectors.entityByIdSelector(
    CSV_C_MODELS_RECORD,
    utils.convertToInt(modelId),
  )(store.getState())
  const fileIndex = getFileIndexByFileName(modelId, fileName)
  const fileColumnsPath = ['files', fileIndex, 'columns']
  const fileColumnsLength = R.length(R.pathOr([], fileColumnsPath, model))
  const updatedModel = R.over(
    R.lensPath(fileColumnsPath),
    R.append({
      ...column,
      position: fileColumnsLength + 1,
    }),
    model,
  )

  store.dispatch(
    replaceEntityProperty([CSV_C_MODELS_RECORD, modelId], updatedModel),
  )
}
