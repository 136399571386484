import React from 'react'
import * as Yup from 'yup'
import * as R from 'ramda'
import { Formik, Form, FormikHelpers } from 'formik'
import { useSelector } from 'react-redux'
import { records, utils } from '@ims/1edtech-frontend-common'

import IUser from 'domains/users/models/IUser'
import { USERS_RECORD } from 'lib/records/modules/users'
import { ModalWrapper, Text, View, FormInput, Switch } from 'lib/components'
import { IModalAction } from 'lib/components/ModalWrapper'
import { meSelector } from 'domains/authentication/selectors/me'
import { createRecord } from 'lib/records/workflows/createRecord'
import { updateRecord } from 'lib/records/workflows/updateRecord'
import { showToast, ERROR_TOAST } from 'lib/utils/toast'
import { isUsernameAvailable } from 'domains/users/workflows/isUsernameAvailable'
import * as userIcons from 'domains/users/constants/users'
import { validatePassword } from 'domains/users/utils/passwords'
import trackUsersAnalytics from 'domains/users/utils/trackUsersAnalytics'
import { NotificationPreference } from 'domains/users/models/IUserPreferences'
import { USER_PREFERENCES_RECORD } from 'lib/records/modules/userPreferences'
import FormSelect from 'lib/components/modern/Select/FormSelect'
import useRecordEntity from 'lib/records/hooks/useRecordEntity'
import { USER_ROLES_RECORD } from 'lib/records/modules/userRoles'
import { myOrganizationPropSelector } from 'domains/authentication/selectors/organizations'
import IUserRole from 'domains/users/models/IUserRole'
import { ORGANIZATIONS_RECORD } from 'lib/records/modules/organizations'
import { RootState } from 'lib/store/rootReducer'
import IOrg from 'domains/orgs/models/IOrg'
import { IApiResponse } from 'lib/api/models'

interface IFormValues {
  firstName: string
  lastName: string
  jobTitle: string
  email: string
  addNew?: boolean
  password: string
  confirmPassword: string
  notifications: boolean
  primaryContact: boolean
  userRole: string[]
}

const validationSchema = {
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  jobTitle: Yup.string().required('Required'),
  email: Yup.string().email('Invalid Email').required('Required'),
  userRole: Yup.array()
    .of(Yup.string())
    .test({
      message: 'At least one role is required',
      test: (arr: any) => (Array.isArray(arr) ? arr.length > 0 : false),
    }),
}
const passwordValidationSchema = {
  password: validatePassword,
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
}

let checkUsernameTimeout: any

type Props = {
  isOpen: boolean
  id?: number
  isCreate?: boolean
  closeModal: (createdUser?: IUser) => any
  orgId: number
}

export default function CreateEditUserModal({
  isOpen,
  id = 0,
  isCreate = false,
  closeModal,
  orgId,
}: Props) {
  const me = useSelector(meSelector)
  const org = useSelector((s: RootState) =>
    records.entitiesSelectors.entityByIdSelector(ORGANIZATIONS_RECORD, 'orgId')(
      s,
      { orgId },
    ),
  ) as null | IOrg
  const myOrgType = useSelector((s: any) =>
    myOrganizationPropSelector('type', 'N/A')(s, {}),
  )
  const orgType = org ? org.type : myOrgType
  const [user] = useRecordEntity({ record: USERS_RECORD, id: id })
  const [userPreferences] = useRecordEntity({
    record: USER_PREFERENCES_RECORD,
    id: id,
  })
  const rolesRecord = useSelector((s) =>
    records.recordsSelectors.fullRecordsSelector(USER_ROLES_RECORD)(s, {}),
  )
  const roles = (R.pathOr([], ['items'], rolesRecord) as IUserRole[]).filter(
    (r) => R.intersection(['Org', orgType], r.appliesTo).length > 0,
  )

  const onSave = async (
    values: IFormValues,
    bag: FormikHelpers<IFormValues>,
  ) => {
    const isMe = id === me!.id // eslint-disable-line
    let response: IApiResponse
    let editedUser = {}
    if (isCreate) {
      response = await createRecord(
        USERS_RECORD,
        {
          ...R.compose<any, any, any>(
            R.assoc('orgId', orgId),
            R.dissoc('notifications'),
          )(values),
        },
        orgId,
      )
    } else {
      editedUser = {
        ...user!, // eslint-disable-line
        ...R.compose(
          R.dissoc('password'),
          R.dissoc('confirmPassword'),
          R.dissoc('notifications'),
        )(values),
      }
      response = await updateRecord(USERS_RECORD, id || 0, editedUser, true)
    }

    bag.setSubmitting(false)
    if (!response.success) {
      showToast(ERROR_TOAST, `Failed to ${isCreate ? 'create' : 'update'} user`)
    } else {
      if (isCreate) {
        await updateRecord(USER_PREFERENCES_RECORD, response.data.id, {
          notifications: {
            inApp: {
              preference: values.notifications ? 'ALERTS' : 'SILENT',
            },
          },
        })
        await trackUsersAnalytics('user_created', { newUser: response.data })
      } else {
        await trackUsersAnalytics(isMe ? 'profile_editied' : 'user_edited', {
          edits: editedUser,
        })
      }
      bag.resetForm()
      if (!values.addNew) {
        closeModal(isCreate ? response.data : undefined)
      }
    }
  }

  const onValidate = (values: IFormValues) =>
    new Promise((resolve) => {
      if (
        utils.hasValue(values.email) &&
        (isCreate || values.email !== user!.email) // eslint-disable-line
      ) {
        clearTimeout(checkUsernameTimeout)
        checkUsernameTimeout = setTimeout(async () => {
          const usernameAvailable = await isUsernameAvailable(values.email)
          if (!usernameAvailable) {
            resolve({ email: 'Email/Username already taken' })
          } else {
            resolve({})
          }
        }, 350)
      } else {
        resolve({})
      }
    })

  const onToggle =
    (
      field: string,
      currentValue: boolean,
      setFieldValue: (field: string, value: any) => any,
    ) =>
    () =>
      setFieldValue(field, !currentValue)

  const onSaveAndAddNew = (submitForm: any, setFieldValue: any) => () => {
    setFieldValue('addNew', true, false)
    submitForm()
  }

  const getActions = (
    submitForm: () => any,
    setFieldValue: any,
    resetForm: any,
  ): IModalAction[] => {
    const saveAction: IModalAction = {
      text: 'Save',
      onClick: submitForm,
      variant: 'start',
      extra: { type: 'submit' },
    }
    const cancelAction: IModalAction = {
      text: 'Cancel',
      onClick: () => {
        resetForm()
        closeModal()
      },
      variant: 'neutral',
      extra: {
        type: 'button',
      },
    }
    if (isCreate) {
      return [
        saveAction,
        {
          text: 'Save + Add New User',
          onClick: onSaveAndAddNew(submitForm, setFieldValue),
          extra: { type: 'submit' },
          variant: 'start',
        },
        cancelAction,
      ]
    }

    return [saveAction, cancelAction]
  }

  const getUserDetails = () => {
    const values = {
      firstName: R.propOr<string, IUser | undefined, string>(
        '',
        'firstName',
        user,
      ),
      lastName: R.propOr<string, IUser | undefined, string>(
        '',
        'lastName',
        user,
      ),
      jobTitle: R.propOr<string, IUser | undefined, string>(
        '',
        'jobTitle',
        user,
      ),
      email: R.propOr<string, IUser | undefined, string>('', 'email', user),
      password: '',
      confirmPassword: '',
      notifications:
        R.pathOr(
          'ALERTS' as NotificationPreference,
          ['notifications', 'inApp', 'preference'],
          userPreferences,
        ) === 'ALERTS',
      primaryContact: R.propOr<boolean, IUser | undefined, boolean>(
        false,
        'primaryContact',
        user,
      ),
      userRole: R.pathOr([], ['userRole'], user),
    }

    if (!isCreate) {
      return R.assoc('id', id, values)
    }

    return values
  }

  const renderRow = (children: any[]) => (
    <View
      display="grid"
      gridTemplateColumns={['auto', children.length > 1 ? '1fr 1fr' : 'auto']}
      gridColumnGap="28px"
      width="100%"
    >
      {children}
    </View>
  )

  const meId = me ? me.id : 0
  const isMe = id === meId
  return (
    <Formik
      initialValues={getUserDetails()}
      onSubmit={onSave}
      validate={onValidate}
      validationSchema={Yup.object().shape({
        ...validationSchema,
        ...(isCreate ? passwordValidationSchema : {}),
      })}
      enableReinitialize={true}
    >
      {({
        values,
        handleChange,
        submitForm,
        isSubmitting,
        setFieldValue,
        resetForm,
      }) => (
        <ModalWrapper
          isOpen={isOpen}
          title={`${isCreate ? 'Add' : 'Edit'} ${isMe ? 'Profile' : 'User'}`}
          actions={getActions(submitForm, setFieldValue, resetForm)}
          pending={isSubmitting}
          overflowInitial
        >
          <Form autoComplete="off">
            <div className="">
              {renderRow([
                <FormInput
                  key="firstName"
                  label="First Name"
                  labelDataTest="first-name-label"
                  required={true}
                  name="firstName"
                  placeholder="Jane"
                  value={values.firstName}
                  valueDataTest="first-name-input"
                  handleChange={handleChange}
                />,
                <FormInput
                  key="lastName"
                  label="Last Name"
                  labelDataTest="last-name-label"
                  required={true}
                  name="lastName"
                  placeholder="Doe"
                  value={values.lastName}
                  valueDataTest="last-name-input"
                  handleChange={handleChange}
                />,
              ])}
              {renderRow([
                <FormInput
                  key="jobTitle"
                  label="Job Title"
                  labelDataTest="job-title-label"
                  required={true}
                  name="jobTitle"
                  placeholder="IT Director"
                  value={values.jobTitle}
                  valueDataTest="job-title-input"
                  handleChange={handleChange}
                />,
                ...(isMe
                  ? []
                  : [
                      <FormInput
                        key="email"
                        label="Email"
                        labelDataTest="email-label"
                        required={true}
                        name="email"
                        placeholder="example@example.com"
                        value={values.email}
                        valueDataTest="email-input"
                        handleChange={handleChange}
                        showRequiredOnlyOnSumbit={true}
                      />,
                    ]),
              ])}
              {isCreate &&
                renderRow([
                  <FormInput
                    key="password"
                    label="Password"
                    labelDataTest="password-label"
                    required={true}
                    name="password"
                    placeholder="Password"
                    value={values.password}
                    valueDataTest="password-input"
                    type="password"
                    handleChange={handleChange}
                    showRequiredOnlyOnSumbit={true}
                  />,
                  <FormInput
                    key="confirmPassword"
                    label="Confirm Password"
                    labelDataTest="confirm-password-label"
                    required={true}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={values.confirmPassword}
                    valueDataTest="confirm-password-input"
                    type="password"
                    handleChange={handleChange}
                  />,
                ])}

              <View
                display="grid"
                gridTemplateColumns={['auto', '1fr 1fr']}
                gridColumnGap="28px"
                width="100%"
              >
                {(isCreate || meId !== id || me!.ims) && ( // eslint-disable-line
                  <>
                    <FormSelect
                      label="Roles"
                      name="userRole"
                      selected={values.userRole}
                      onChange={(value: any) =>
                        setFieldValue('userRole', value)
                      }
                      placeholder="Select role(s)"
                      options={roles.map((role) => ({
                        label: role.label,
                        description: role.description,
                        value: role.id,
                      }))}
                      multiple
                      required
                    />
                    {me!.ims && ( // eslint-disable-line
                      <div className="flex flex-row items-center mt-2">
                        <Switch
                          on={values.primaryContact}
                          onChange={onToggle(
                            'primaryContact',
                            values.primaryContact,
                            setFieldValue,
                          )}
                          dataTest="primary-contact-toggle"
                          onIconName={userIcons.PRIMARY_CONTACT_ICON}
                          offIconName={userIcons.NON_PRIMARY_CONTACT_ICON}
                        />
                        <Text ml={2}>Primary Contact</Text>
                      </div>
                    )}
                  </>
                )}

                {isCreate && (
                  <View mt={3}>
                    <Text variant="title" ml={2} mb={1}>
                      Preferences
                    </Text>
                    <View flexible="row-v-center">
                      <Switch
                        on={values.notifications}
                        onChange={onToggle(
                          'notifications',
                          values.notifications,
                          setFieldValue,
                        )}
                        dataTest="notifications-toggle"
                        onIconName="fas fa-bell"
                        offIconName="fas fa-bell-slash"
                      />
                      <Text ml={2}>Push Notifications</Text>
                    </View>
                  </View>
                )}
              </View>
            </div>
          </Form>
        </ModalWrapper>
      )}
    </Formik>
  )
}
