import React from 'react'

import { View } from 'lib/components'
import { IViewProps } from 'lib/components/View'

interface IProps extends IViewProps {
  children: any
}

export default function ProductModelingWrapper(props: IProps) {
  const { children, ...rest } = props
  return (
    <View m={4} flexible="column-center" {...rest}>
      <View flexible="column-center" width="100%" maxWidth="840px">
        {children}
      </View>
    </View>
  )
}
