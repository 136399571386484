import React from 'react'

import { utils } from '@ims/1edtech-frontend-common'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import NonTableList from 'lib/components/NonTableList'
import ICommonCartridgeCharacterizationReport from 'domains/commonCartridge/models/ICommonCartridgeCharacterizationReport'
import { COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_STANDARDS_RECORD } from 'lib/records/modules/commonCartridgeCharacterizationReportStandards'
import { Spinner, View } from 'lib/components'
import CCCharacterizationReportTableStandardRow from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportTableStandardRow'

interface IProps {
  isPreview?: boolean
  report?: ICommonCartridgeCharacterizationReport
  characterization?: ICharacterization
  query?: string
  searchTemplate?: string
  resourceId?: number
  noBlankslate?: boolean
}

export default function CommonCartridgeCharacterizationReportStandardsList(
  props: IProps,
) {
  const pending =
    !utils.hasValue(props.report) || !utils.hasValue(props.characterization)

  if (pending) {
    return <Spinner centered={true} />
  }

  const isForResource = utils.hasValue(props.resourceId)
  return (
    <View height="100%">
      <NonTableList
        data-test="cc-standard-list"
        record={COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_STANDARDS_RECORD}
        parentRecordId={isForResource ? props.resourceId : props.report!.id}
        RowComponent={CCCharacterizationReportTableStandardRow}
        params={{ isCartridge: !isForResource }}
        rowData={{ characterization: props.characterization }}
        blankSlateText="No Standards"
        noHorizontalScroll={true}
        pageSize={props.isPreview ? 5 : undefined}
        noPagination={props.isPreview}
        noBlankslate={props.noBlankslate}
        query={props.query || ''}
        searchTemplate={props.searchTemplate}
        usePageBottomListener={true}
      />
    </View>
  )
}
