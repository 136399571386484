import React from 'react'
import { View } from 'lib/components'

interface IProps {
  children: any
}

export default function ProductModelResourceGrid(props: IProps) {
  return (
    <View
      display="grid"
      gridTemplateColumns={['auto', '1fr 1fr 1fr', '1fr 1fr 1fr 1fr']}
      gridColumnGap="28px"
      gridRowGap="28px"
      py={3}
    >
      {props.children}
    </View>
  )
}
