import React from 'react'
import clsx from 'clsx'
import memoize from 'fast-memoize'
import * as R from 'ramda'

import { List } from 'lib/components'
import CharacterizationReportNotesCell from 'domains/characterizations/components/CharacterizationReport/CharacterizationReportNotesCell'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'
import { IListColumnDefinition } from 'lib/components/List'
import ICSVModelCharacterizationFileColumn from 'domains/characterizations/models/ICSVModelCharacterizationFileColumn'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import {
  ITransformedRESTServiceEndpointCharacterizationReport,
  ITransformedRESTServiceCharacterizationReport,
  ITransformedRESTServiceEndpointAttributeCharacterizationReport,
} from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import IRestCModel from 'domains/modeling/models/IRestCModel'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import RESTCharacterizationAttributeWarningCell from 'domains/characterizations/components/CharacterizationReport/RESTCharacterizationAttributeWarningCell'
import {
  findModelServiceEndointByName,
  findSpecServiceEndointAttributeByName,
} from 'domains/modeling/utils/restCOR1p1utils'
import RESTCharacterizationAttributeFormatCell from 'domains/characterizations/components/CharacterizationReport/RESTCharacterizationAttributeFormatCell'
import RESTCharacterizationAttributeMultiplicityCell from 'domains/characterizations/components/CharacterizationReport/RESTCharacterizationAttributeMultiplicityCell'
import RESTCharacterizationAttributeDescriptionCell from 'domains/characterizations/components/CharacterizationReport/RESTCharacterizationAttributeDescriptionCell'
import { OR_1P1_REST_ROSTERING_CONSUMER_SERVICES } from 'domains/modeling/constants/restCOneRoster1p1'
import RESTCharacterizationAttributeRequiredCell from 'domains/characterizations/components/CharacterizationReport/RESTCharacterizationAttributeRequiredCell'
import RESTCharacterizationAttributePopulationCell from 'domains/characterizations/components/CharacterizationReport/RESTCharacterizationAttributePopulationCell'
import RESTCharacterizationAttributeMinMaxCell from 'domains/characterizations/components/CharacterizationReport/RESTCharacterizationAttributeMinMaxCell'

const isPrint = isOnPrintScreen()

const keyExtractor = (
  column: ICSVModelCharacterizationFileColumn,
  isSubRow: boolean,
) => `column-${column.name}${isSubRow ? '-notes' : ''}`

const defaultColProps = {
  titleProps: { fontWeight: 700, fontSize: isPrint ? 11 : undefined },
  noHeaderCenter: true,
  noCenter: true,
}
interface IProps {
  endpoint: ITransformedRESTServiceEndpointCharacterizationReport
  service: ITransformedRESTServiceCharacterizationReport
  specification: IRestCSpec
  model?: IRestCModel
  noHeader?: boolean
}

const getSortedAttributes = memoize(
  (
    endpoint: ITransformedRESTServiceEndpointCharacterizationReport,
    service: ITransformedRESTServiceCharacterizationReport,
    model?: IRestCModel,
  ) => {
    if (!model) {
      return endpoint.attributeCharacterizations
    }

    const modelEndpoint = findModelServiceEndointByName(
      model,
      service.serviceName,
      endpoint.endpointName,
      true,
    )

    let indexed: { [key: string]: any } = {}
    for (const attribute of endpoint.attributeCharacterizations) {
      const index = R.findIndex(
        R.propEq('name', attribute.attributeName),
        modelEndpoint.attributes || [],
      )
      indexed = R.assoc(`${index}`, attribute, indexed)
    }
    return Object.keys(indexed).map((index) => indexed[index])
  },
)

export default function RESTEndpointCharacterizationDataElementsList(
  props: IProps,
) {
  const getColumns = memoize(
    (
      endpoint: ITransformedRESTServiceEndpointCharacterizationReport,
      specification: IRestCSpec,
    ) => {
      const descriptionCell = {
        title: 'Description',
        maxWidth: isPrint ? 'auto' : [200, 250, 250, 'auto', 'auto'],
        cellProps: {
          serviceName: props.service.serviceName,
          endpointName: endpoint.endpointName,
          specification,
          model: props.model,
          width: isPrint ? 'auto' : [200, 250, 250, 'auto', 'auto'],
        },
        CellComponent: RESTCharacterizationAttributeDescriptionCell,
        ...defaultColProps,
      }
      const columns: IListColumnDefinition[] = [
        {
          title: null,
          maxWidth: isPrint ? 14 : 48,
          CellComponent: RESTCharacterizationAttributeWarningCell,
          cellProps: { endpointName: endpoint.endpointName },
          ...defaultColProps,
        },
        {
          title: 'Name',
          maxWidth: isPrint ? 100 : [200, 250, 200, 250, 380],
          accessor: 'attributeName',
          ...defaultColProps,
        },
        {
          title: 'Required',
          maxWidth: isPrint ? 78 : 100,
          CellComponent: RESTCharacterizationAttributeRequiredCell,
          cellProps: {
            specification,
            serviceName: props.service.serviceName,
            endpointName: endpoint.endpointName,
          },
          ...defaultColProps,
        },
        {
          title: 'Format',
          maxWidth: isPrint ? 78 : 100,
          CellComponent: RESTCharacterizationAttributeFormatCell,
          cellProps: {
            serviceName: props.service.serviceName,
            endpointName: endpoint.endpointName,
            specification,
            model: props.model,
          },
          ...defaultColProps,
        },
        {
          title: 'Multiplicity',
          maxWidth: isPrint ? 78 : 100,
          CellComponent: RESTCharacterizationAttributeMultiplicityCell,
          cellProps: {
            serviceName: props.service.serviceName,
            endpointName: endpoint.endpointName,
            specification,
            model: props.model,
          },
          ...defaultColProps,
          noHeaderCenter: false,
          noCenter: false,
          forceCenter: true,
        },
      ]
      if (props.model) {
        if (isPrint) {
          return columns
        }
        return [
          ...columns,
          descriptionCell,
          {
            title: 'Notes',
            maxWidth: [200, 250, 250, 'auto', 'auto'],
            CellComponent: CharacterizationReportNotesCell,
            ...defaultColProps,
          },
        ]
      }
      return [
        ...columns,
        {
          title: 'Min/Max',
          maxWidth: isPrint ? 54 : 78,
          CellComponent: RESTCharacterizationAttributeMinMaxCell,
          ...defaultColProps,
          noHeaderCenter: isPrint,
          forceCenter: !isPrint,
        },
        descriptionCell,
        {
          title: 'Population',
          maxWidth: isPrint ? 100 : 120,
          CellComponent: RESTCharacterizationAttributePopulationCell,
          cellProps: {
            serviceName: props.service.serviceName,
            endpointName: endpoint.endpointName,
          },
          ...defaultColProps,
        },
      ]
    },
  )

  const getRowProps = memoize(
    (
      attribute: ITransformedRESTServiceEndpointAttributeCharacterizationReport,
    ) => {
      const defaultProps = { 'data-test': `${attribute.attributeName}-row` }
      if (attribute.metadata) {
        const specAttribute = findSpecServiceEndointAttributeByName(
          props.specification,
          props.service.serviceName as OR_1P1_REST_ROSTERING_CONSUMER_SERVICES,
          props.endpoint.endpointName,
          attribute.attributeName,
        )
        if (specAttribute) {
          return { bg: 'builtInMetaDataBG', ...defaultProps }
        }

        return { bg: 'customMetaDataBG', ...defaultProps }
      }
      return defaultProps
    },
  )

  if (
    !props.endpoint ||
    !props.endpoint.attributeCharacterizations ||
    props.endpoint.attributeCharacterizations.length < 1
  ) {
    return null
  }

  return (
    <div
      className={clsx({
        'mt-4': !isPrint,
        'mt-2': isPrint,
      })}
    >
      {!props.noHeader && (
        <div className="px-3">
          <WidgetHeader
            title="Data Elements"
            dataTest={`${props.service.serviceName}-${props.endpoint.endpointName}-data-elements-title`}
          />
        </div>
      )}
      <List
        noWrapper={true}
        nonRecordItems={getSortedAttributes(
          props.endpoint,
          props.service,
          props.model,
        )}
        columns={getColumns(props.endpoint, props.specification)}
        dataTest={`${props.service.serviceName}-${props.endpoint.endpointName}-data-elements-list`}
        noSort={true}
        getRowProps={getRowProps}
        keyExtractor={keyExtractor}
      />
    </div>
  )
}
