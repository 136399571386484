import React from 'react'
import * as R from 'ramda'
import { useHistory } from 'react-router-dom'

import { ICommonCartridgeItem } from 'domains/commonCartridge/models/ICommonCartridgeItem'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import useCCCharacterizationReportCrumbs, {
  ICCCrumb,
} from 'domains/commonCartridge/hooks/useCCCharacterizationReportCrumbs'
import { getCharacterizationRoute } from 'domains/characterizations/navigation/routes'
import CCCharacterizationReportRow from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportRow'
import {
  CCReportTypeIcon,
  CC_TYPE_ITEM,
} from 'domains/commonCartridge/constants/commonCartridge'

export const CC_ITEM_TITLE_NOT_SPECIFIED = '(No title specified)'

interface IProps {
  characterization: ICharacterization
  item: ICommonCartridgeItem
}

export default function CCCharacterizationReportItemRow(props: IProps) {
  const [crumbs] = useCCCharacterizationReportCrumbs()
  const history = useHistory()

  const onGoToItem = () => {
    const route = getCharacterizationRoute(
      props.characterization.id,
      props.characterization.format,
      ['items', `${props.item.id}`],
    )
    history.push(route, {
      crumbs: [
        ...crumbs,
        {
          id: props.item.id,
          type: CC_TYPE_ITEM,
          title: props.item.title || CC_ITEM_TITLE_NOT_SPECIFIED,
        } as ICCCrumb,
      ],
    })
  }

  const id = R.pathOr(0, ['id'], props.item)
  return (
    <CCCharacterizationReportRow
      type="Item"
      id={id}
      bg="ccReportItemBG"
      icon={CCReportTypeIcon.Item}
      title={props.item.title || CC_ITEM_TITLE_NOT_SPECIFIED}
      subtitle={props.item.identifier || '(identifier not specified)'}
      subtitleLabel="identifier"
      onClick={onGoToItem}
    />
  )
}
