import React, { PureComponent } from 'react'

import { View, Icon, ToolTip } from 'lib/components'
import IProduct from 'domains/products/models/IProduct'
import { FORMAT_TO_ICON_MAP } from 'domains/formats/constants/formats'
import { isProductFormatSupported } from 'domains/products/utils/isProductFormatSupported'
import { isProductFormatPublished } from 'domains/products/utils/productFormatStatus'

interface IProps extends IProduct {
  maxWidth?: string
  maxWidthArray?: (string | number)[]
}

export default class DistrictProductCatalogFormatsCell extends PureComponent<IProps> {
  render() {
    const { id, formats } = this.props

    return (
      <View flexible="row-v-center">
        {(formats || [])
          .filter(
            (f) =>
              isProductFormatSupported(f.format) &&
              isProductFormatPublished(this.props, f.format),
          )
          .map((f) => (
            <ToolTip
              id={`${id}-${f.format}`}
              tip={f.format}
              delay={250}
              key={f.format}
            >
              <Icon
                mr={2}
                className={FORMAT_TO_ICON_MAP[f.format]}
                fontSize={24}
              />
            </ToolTip>
          ))}
      </View>
    )
  }
}
