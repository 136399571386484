import React, { PureComponent } from 'react'

import {
  ReportStatus,
  STATUS_TO_BADGE_ICON_MAP,
  STATUS_TO_BADGE_VARIANT_MAP,
} from 'domains/reports/models/IReportStatuses'
import { Icon } from 'lib/components'

interface IProps {
  status: ReportStatus
  size?: number
}

export default class ComparisonStatusIcon extends PureComponent<IProps> {
  render() {
    const { status, size = 100 } = this.props
    return (
      <Icon
        color={STATUS_TO_BADGE_VARIANT_MAP[status]}
        fontSize={size}
        className={STATUS_TO_BADGE_ICON_MAP[status]}
        data-icon={STATUS_TO_BADGE_ICON_MAP[status]}
      />
    )
  }
}
