import React from 'react'
import { View, Image, Text } from 'lib/components'
import { APPLICATION_NAME } from 'domains/application/constants/application'

interface IProps {
  title: string
}

export default function PrintPageHeader(props: IProps) {
  return (
    <View flexible="row-v-center">
      <Image
        src="/images/1EdTechLogo.svg"
        width={36}
        height={36}
        pr={2}
        alt="1EdTech Logo"
      />
      <Text fontSize={14} lineHeight="18px" fontWeight={700} mx={2}>
        {APPLICATION_NAME}
      </Text>

      <View height={20} borderRight="solid 2px #000" />

      <Text fontSize={14} lineHeight="18px" fontWeight={300} ml={2}>
        {props.title}
      </Text>
    </View>
  )
}
