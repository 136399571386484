import { records, utils } from '@ims/1edtech-frontend-common'
import { UserRole } from 'domains/users/constants/userRoles'
import * as R from 'ramda'

export default interface IUser {
  id: number
  firstName: string
  lastName: string
  organizationName: string
  organization: records.modules.IRef
  jobTitle: string
  email: string
  phone: string
  ims: boolean
  primaryContact: boolean
  userRole?: UserRole[]
  lastActive?: number
  lTIRole?: string
}

export const mockGenericUser: IUser = {
  id: 1,
  firstName: 'Donald',
  lastName: 'Draper',
  organizationName: 'Sterling Cooper advertising agency',
  organization: {
    id: 1,
  },
  jobTitle: 'Ad Man',
  email: 'adsforyou@madmen.net',
  phone: '5555555555',
  ims: false,
  primaryContact: false,
  userRole: ['ADMIN'],
}

export const userHasRole = (
  user?: IUser | null,
  roles?: string | string[],
): boolean => {
  const rolesArr = utils.array.ensureArray(roles)
  if (!user) return false // Assume no
  if (!roles || rolesArr.length < 1) return true // no roles required

  return (
    R.intersection(utils.array.ensureArray(user.userRole), rolesArr).length > 0
  )
}

export const mockTestUser = (overrides = {}): IUser => ({
  ...mockGenericUser,
  ...overrides,
})
