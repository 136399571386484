import store from 'lib/store'
import { createRecord } from 'lib/records/workflows/createRecord'
import { AnySpecFormatType } from 'domains/formats/constants/formats'
import trackModelingAnalytics from 'domains/modeling/utils/trackModelingAnalytics'
import { REST_C_MODELS_RECORD } from 'lib/records/modules/restCModels'
import { getRestModelServicesFromSpec } from 'domains/modeling/utils/getRestModelServicesFromSpec'
import { OR1P1Subsets } from 'domains/specifications/constants/oneRoster1p1FileSets'
import { formatIdByNameSelector } from 'domains/specifications/selectors/formatSelectors'
import { getFullProduct } from 'domains/products/workflows/getFullProduct'

export const createRestModel = async (
  productId: string | number,
  format: AnySpecFormatType,
  endpointSet: OR1P1Subsets,
) => {
  const formatId = formatIdByNameSelector(format)(store.getState())
  const model = {
    productId,
    formatId,
    services: getRestModelServicesFromSpec(endpointSet),
  }
  const { success, data } = await createRecord(REST_C_MODELS_RECORD, model)
  if (!success) {
    return false
  }

  await getFullProduct(productId, format)
  trackModelingAnalytics('started_modeling', { productId, format, endpointSet })
  return data
}
