import React, { PureComponent } from 'react'

import { View, Text, ToolTip } from 'lib/components'
import IComparison from 'domains/comparisons/models/IComparison'

interface IProps extends IComparison {
  maxWidth?: string
  maxWidthArray?: (string | number)[]
}

export default class ComparisonProductNameCell extends PureComponent<IProps> {
  render() {
    const {
      id,
      productId,
      productName,
      maxWidth,
      maxWidthArray = [100, 220],
    } = this.props

    return (
      <View flexible="row-v-center">
        <ToolTip id={`${id}-${productId}`} tip={productName} delay={250}>
          <Text
            textAlign="left"
            overflow="ellipsis"
            maxWidth={maxWidth || maxWidthArray}
            fontSize={13}
            data-test={`${id}-product-name`}
          >
            {productName}
          </Text>
        </ToolTip>
      </View>
    )
  }
}
