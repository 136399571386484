import modules from 'lib/records/modules'
import { deleteRequest } from 'lib/api/api'
import store from 'lib/store'
import { records } from '@ims/1edtech-frontend-common'
import { removeEntity } from 'lib/store/entities.actions'

export const deleteRecord = async (
  record: string,
  id: string | number,
  requestParams?: Record<string, unknown> | null,
  parentRecordId?: string | number,
) => {
  const recordModule = modules[record]
  const { success } = await deleteRequest(
    recordModule.api.delete(id, requestParams),
  )
  if (!success) {
    return false
  }

  if (parentRecordId) {
    store.dispatch(
      records.relatedRecordActions.deleteRelatedRecordItem(
        record,
        id,
        parentRecordId,
      ),
    )
  } else {
    store.dispatch(records.recordActions.deleteRecordItem(record, id))
  }

  store.dispatch(removeEntity(record, `${id}`))

  return true
}
