import React, { PureComponent } from 'react'
import * as R from 'ramda'

import colors from 'lib/styles/colors'
import { Icon, View } from 'lib/components'
import { ONE_ROSTER_SPEC } from 'domains/specifications/constants/oneRoster'
import { COMMON_CARTRIDGE_SPEC } from 'domains/specifications/constants/CommonCartridge'

interface IProps {
  color?: string
  size?: number
  noResponsive?: boolean
  responsiveIndex?: number
  specificationName?: string
}

interface IIconProps {
  fontSizeStyle: number | any[]
}

const OneRosterIcon = (props: IIconProps) => (
  <Icon className="material-icons" color="white" fontSize={props.fontSizeStyle}>
    fingerprint
  </Icon>
)

const CommonCartridgeIcon = (props: IIconProps) => (
  <Icon
    className="fas fa-layer-group"
    color="white"
    fontSize={props.fontSizeStyle}
  />
)

export default class CharacterizationIcon extends PureComponent<IProps> {
  render() {
    const {
      color = colors.infoLight,
      size = 56,
      noResponsive = false,
      responsiveIndex = 3,
      specificationName = 'OneRoster',
    } = this.props

    const sizeStyle = noResponsive
      ? size
      : [...R.repeat(25, responsiveIndex - 1), size]
    const fontSizeStyle = noResponsive
      ? size / 1.5
      : [...R.repeat(18, responsiveIndex - 1), size / 1.5]

    return (
      <View
        width={sizeStyle}
        minWidth={sizeStyle}
        height={sizeStyle}
        minHeight={sizeStyle}
        borderRadius="50%"
        bg={color}
        flexible="column-center"
      >
        {specificationName === ONE_ROSTER_SPEC && (
          <OneRosterIcon fontSizeStyle={fontSizeStyle} />
        )}
        {specificationName === COMMON_CARTRIDGE_SPEC && (
          <CommonCartridgeIcon fontSizeStyle={fontSizeStyle} />
        )}
      </View>
    )
  }
}
