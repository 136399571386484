import React from 'react'
import Collapse from '@kunukn/react-collapse'

import View from 'lib/components/View'

interface IProps {
  onRequestChange?: () => void
  isOpen?: boolean
  summary: any
  details: any
  hideWhenClosed?: boolean
  disabled?: boolean
}

export default function ExpansionPanel(props: IProps) {
  const [isOpen, setIsOpen] = React.useState(!!props.isOpen)

  const onRequestChange = () => {
    if (props.disabled) {
      return
    }

    if (props.onRequestChange) {
      props.onRequestChange()
    } else {
      setIsOpen(!isOpen)
    }
  }

  const open =
    props.isOpen === true || props.isOpen === false ? props.isOpen : isOpen
  return (
    <View>
      <View
        hover={props.disabled ? 'default' : 'clickable'}
        onClick={props.disabled ? undefined : onRequestChange}
      >
        {props.summary}
      </View>
      <Collapse isOpen={open}>
        {!open && props.hideWhenClosed ? null : props.details}
      </Collapse>
    </View>
  )
}
