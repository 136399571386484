import React from 'react'

import { View, Icon } from 'lib/components'
import IProduct from 'domains/products/models/IProduct'
import { RouteComponentProps } from 'react-router'
import { productHasMultipleSupportedFormats } from 'domains/products/utils/productHasMultipleSupportedFormats'
import { IOpenTableRows } from 'lib/hooks/OpenTableRows'

interface IProps extends IProduct, RouteComponentProps {
  isSubRow: boolean
  isSingleProduct: boolean
  isOpen?: boolean
  openTableRowsOptions: IOpenTableRows
}

export default function ProductCatalogToggleOpenCell(props: IProps) {
  const onToggleRowOpen = () => props.openTableRowsOptions.onToggleRow(props.id)
  if (!props.isSingleProduct && productHasMultipleSupportedFormats(props)) {
    const isOpen = props.openTableRowsOptions.OpenTableRows[props.id]
    const openClass = isOpen ? 'open' : 'closed'
    const sharedProps = {
      cursor: 'pointer',
      style: {
        position: 'absolute',
        top: -12,
        left: 0,
      } as React.CSSProperties,
      color: 'complete',
      fontSize: 24,
      onClick: onToggleRowOpen,
    }
    return (
      <View flexible="column-center" position="relative">
        <Icon
          {...sharedProps}
          className={`fas fa-plus plus-minus plus ${openClass}`}
          data-test={`toggle-row-${props.id}-open`}
        />
        <Icon
          {...sharedProps}
          className={`fas fa-minus plus-minus minus ${openClass}`}
          data-test={`toggle-row-${props.id}-close`}
        />
      </View>
    )
  }

  return null
}
