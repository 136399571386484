import React, { useEffect, useState } from 'react'
import { utils, records } from '@ims/1edtech-frontend-common'
import { useSelector } from 'react-redux'

import { USERS_RECORD } from 'lib/records/modules/users'
import { View, BreadCrumbs, Spinner } from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import { RootState } from 'lib/store/rootReducer'
import IUser from 'domains/users/models/IUser'
import {
  getRoute,
  DASHBOARD_ROUTE,
  USERS_ROUTE,
  ORGANIZATIONS_ROUTE,
} from 'domains/application/navigation/routes'
import { getRecord } from 'lib/records/workflows/getRecord'
import { RouteComponentProps } from 'react-router'
import { getNavigationParam } from 'lib/utils/navigation'
import { getFullName } from 'domains/users/utils/user'
import UserDetailsWidget from 'domains/users/components/UserDetailsWidget'
import UserActionWidget from 'domains/users/components/UserActionWidget'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import { meSelector } from 'domains/authentication/selectors/me'
import GenericError from 'domains/application/components/GenericError'
import { USER_PREFERENCES_RECORD } from 'lib/records/modules/userPreferences'
import UserPreferencesWidget from 'domains/users/components/UserPreferencesWidget'
import ReportThreeTopSection from 'domains/reports/components/ReportThreeTopSection'

const WIDGET_HEIGHT = 490

export default function UserDetailsScreen(props: RouteComponentProps) {
  const id = utils.convertToInt(getNavigationParam(props, 'id'))
  const [pending, setPending] = useState(true)
  useEffect(() => {
    const getUser = async () => {
      const { success } = await getRecord(USERS_RECORD, id)
      if (success) {
        await getRecord(USER_PREFERENCES_RECORD, id)
      }
      setPending(false)
    }
    getUser()
  }, []) // eslint-disable-line

  const me = useSelector(meSelector)

  const user = useSelector<RootState, IUser>(
    records.entitiesSelectors.entityByIdSelector(USERS_RECORD, id),
  )
  const currentOrgId = useSelector<RootState, number>((state) =>
    myOrganizationIdSelector(state, {}),
  )

  const orgId = getNavigationParam(props, 'orgId', currentOrgId)

  const goToUsers = () => {
    const adminOrgId = utils.convertToInt(getNavigationParam(props, 'orgId'))
    if (
      props.history.location.pathname.includes(ORGANIZATIONS_ROUTE) &&
      utils.hasValue(adminOrgId)
    ) {
      props.history.replace(getRoute(ORGANIZATIONS_ROUTE, adminOrgId))
    } else {
      props.history.replace(getRoute(USERS_ROUTE))
    }
  }

  if (pending) {
    return (
      <View variant="screen">
        <Spinner centered={true} />
      </View>
    )
  }

  if (!user) {
    return <GenericError />
  }

  const fullName = getFullName(user)
  const homeCrumb = {
    name: 'Home',
    route: getRoute(DASHBOARD_ROUTE),
  }
  const usersCrumb = {
    name: 'Users',
    route: getRoute(USERS_ROUTE),
  }

  const userCrumb = { name: fullName }

  return (
    <View variant="screen">
      <DocumentTitle title={fullName} />
      <BreadCrumbs
        crumbs={
          me
            ? (me.id || 0) == getNavigationParam(props, 'id') // eslint-disable-line
              ? [homeCrumb, { name: 'Profile' }] // Looking at my profile
              : props.match.url.includes('organizations') // If admin
              ? [
                  homeCrumb,
                  {
                    name: 'Organizations',
                    route: getRoute(ORGANIZATIONS_ROUTE),
                  },
                  {
                    name: user.organizationName,
                    route: getRoute(
                      ORGANIZATIONS_ROUTE,
                      getNavigationParam(props, 'orgId'),
                    ),
                  },
                  {
                    name: 'Users',
                    route: getRoute(
                      ORGANIZATIONS_ROUTE,
                      getNavigationParam(props, 'orgId'),
                    ),
                  },
                  userCrumb,
                ]
              : [homeCrumb, usersCrumb, userCrumb]
            : [homeCrumb, usersCrumb, userCrumb]
        }
      />
      <View flexible="column">
        <ReportThreeTopSection>
          <UserDetailsWidget user={user} height={WIDGET_HEIGHT} />
          <UserActionWidget
            user={user}
            orgId={orgId}
            me={me!} // eslint-disable-line
            goToUsers={goToUsers}
            height={WIDGET_HEIGHT}
          />
          <UserPreferencesWidget userId={user.id} height={WIDGET_HEIGHT} />
        </ReportThreeTopSection>
      </View>
    </View>
  )
}
