import React from 'react'
import * as R from 'ramda'

import { View } from 'lib/components'
import { ICommonCartridgeLanguageText } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeLanguageText'
import { ICommonCartridgeLOMAnnotation } from 'domains/commonCartridge/models/lom/ICommonCartridgeLOMAnnotation'
import CCCharacterizationReportLOMLanguageText from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMLanguageText'
import { ICommonCartridgeSourceValue } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeSourceValue'
import CCCharacterizationReportLOMSourceValue from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMSourceValue'
import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  path: string
  data: ICommonCartridgeLOMAnnotation
}

export default function CCCharacterizationReportLOMRights(props: IProps) {
  const { showMissingMetadata } = React.useContext(ShowMissingMetadataContext)
  const cost = R.pathOr({}, ['cost'], props.data) as ICommonCartridgeSourceValue
  const copyrightAndOtherRestrictions = R.pathOr(
    {},
    ['copyrightAndOtherRestrictions'],
    props.data,
  ) as ICommonCartridgeSourceValue
  const descriptions = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['description'],
    props.data,
  ) as ICommonCartridgeLanguageText[]

  return (
    <View>
      <CCCharacterizationReportLOMSourceValue
        path={`${props.path}.cost`}
        title="cost"
        data={cost}
      />

      <CCCharacterizationReportLOMSourceValue
        path={`${props.path}.copyrightAndOtherRestrictions`}
        title="copyrightAndOtherRestrictions"
        data={copyrightAndOtherRestrictions}
      />

      {descriptions.map((description, i) => (
        <CCCharacterizationReportLOMLanguageText
          key={`${props.path}.description.${i}`}
          path={
            !utils.hasValue(description)
              ? `${props.path}.description`
              : `${props.path}.description.${i}`
          }
          title={`description [${i + 1}]`}
          data={description}
        />
      ))}
    </View>
  )
}
