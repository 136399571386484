import React, { PureComponent } from 'react'

import { View } from 'lib/components'

const gridTemplateColumns2 = ['auto', 'auto', '1fr 1fr', '1fr 1fr']
const gridTemplateColumns3 = ['auto', 'auto', '1fr 1fr 1fr', '1fr 1fr 1fr']
const gridTemplateColumns3Small = ['auto', 'auto', '1fr 1fr 1fr']
const gridTemplateColumns4 = [
  'auto',
  '1fr 1fr',
  '1fr 1fr 1fr 1fr',
  '1fr 1fr 1fr 1fr',
]

interface IProps {
  row1Widget3Items?: boolean
  row1Widgets: any[]
  row2Widgets: any[]
  row3Widgets: any[]
  row3IsLarge?: boolean
}

export default class DashboardWrapper extends PureComponent<IProps> {
  render() {
    const {
      row1Widget3Items = false,
      row1Widgets,
      row2Widgets,
      row3Widgets,
      row3IsLarge,
    } = this.props

    return (
      <View flexible="column-h-center">
        <View
          display="grid"
          gridTemplateColumns={
            row1Widget3Items ? gridTemplateColumns3Small : gridTemplateColumns4
          }
          gridColumnGap="28px"
          width="100%"
        >
          {row1Widgets}
        </View>
        <View
          display="grid"
          gridTemplateColumns={gridTemplateColumns2}
          gridColumnGap="28px"
          width="100%"
        >
          {row2Widgets}
        </View>
        <View
          display="grid"
          gridTemplateColumns={
            row3IsLarge ? gridTemplateColumns2 : gridTemplateColumns3
          }
          gridColumnGap="28px"
          flexible="row-wrap"
          width="100%"
        >
          {row3Widgets}
        </View>
      </View>
    )
  }
}
