import { records, utils } from '@ims/1edtech-frontend-common'

import store from 'lib/store'
import { postRequest } from 'lib/api/api'
import { mergeOrReplaceEntities } from 'lib/records/workflows/entities'
import modules from 'lib/records/modules'

const prepareCreateData = (
  { responseDataKey }: records.modules.IModule,
  data: Record<string, unknown>,
) => {
  if (!responseDataKey) {
    return data
  }

  if (Array.isArray(responseDataKey)) {
    return { [responseDataKey[0]]: data }
  }

  return { [responseDataKey]: data }
}

export const createRecord = async (
  record: string,
  data = {},
  parentRecordId = 0,
) => {
  const recordModule = modules[record]
  const preparedData = recordModule.api.prepareCreateData
    ? recordModule.api.prepareCreateData(data)
    : prepareCreateData(recordModule, data)
  const response = await postRequest(
    recordModule.api.create(data),
    preparedData,
  )
  if (response.success && utils.hasValue(response.data)) {
    const id = mergeOrReplaceEntities(true, recordModule, response.data)
    if (parentRecordId) {
      store.dispatch(
        records.relatedRecordActions.mergeRelatedRecordItems(
          recordModule.record,
          id,
          parentRecordId,
        ),
      )
    } else {
      store.dispatch(
        records.recordActions.mergeRecordItems(recordModule.record, id),
      )
    }
  }

  return {
    ...response,
    data: records.modules.utils.getRecordDataFromResponse(
      recordModule,
      response as any,
      'CREATE',
    ),
  }
}
