import React from 'react'
import { utils } from '@ims/1edtech-frontend-common'

import { Spinner, Text } from 'lib/components'
import useFullRESTPCharacterization from 'domains/characterizations/hooks/useFullRESTPCharacterization'
import ITransformedRESTCharacterizationReport from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import GenericError from 'domains/application/components/GenericError'
import ReportTopLevelError from 'domains/reports/components/ReportTopLevelError'
import { ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL } from 'domains/formats/constants/formats'
import usePrintOnMountEffect from 'lib/hooks/usePrintOnMountEffect'
import PrintPageWrapper from 'domains/printing/components/PrintPageWrapper'
import PrintPageHeader from 'domains/printing/components/PrintPageHeader'
import PrintCharacterizationTopDetails from 'domains/characterizations/components/CharacterizationReport/PrintCharacterizationTopDetails'
import PrintRESTPCharacterizationReportServices from 'domains/characterizations/components/CharacterizationReport/PrintRESTPCharacterizationReportServices'

export default function PrintOR1P1RestProviderCharacterizationReportScreen(
  props: any,
) {
  const [
    characterizationData,
    specification,
    reportData,
    transformedReportData,
    pending,
  ] = useFullRESTPCharacterization(props)

  usePrintOnMountEffect(
    pending ||
      !utils.hasValue(characterizationData) ||
      !utils.hasValue(reportData),
  )
  if (
    pending ||
    !utils.hasValue(characterizationData) ||
    !utils.hasValue(reportData)
  ) {
    if (
      !pending &&
      (!utils.hasValue(characterizationData) || !utils.hasValue(reportData))
    ) {
      return <GenericError />
    }
    return <Spinner centered={true} />
  }

  const transformedReport = transformedReportData as ITransformedRESTCharacterizationReport
  const characterization = characterizationData as ICharacterization

  const isGBPush =
    characterization.format === ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL

  return (
    <PrintPageWrapper>
      <PrintPageHeader title="Characterization Details - Full Report" />
      <PrintCharacterizationTopDetails
        characterization={characterization}
        report={transformedReport}
      />

      <div className="my-3">
        {transformedReport.hasErrors && !isGBPush && (
          <Text fontStyle="italic" mt={2}>
            A maximum of 10 critical errors will be shown per endpoint. Please
            resolve and re-characterize to see any additional errors.
          </Text>
        )}
        {utils.hasValue(transformedReport.criticalError) && (
          <div className="my-3">
            <ReportTopLevelError
              status="RED"
              title="Critical Error"
              errors={[transformedReport.criticalError!]}
              dataTest="critical-error"
            />
          </div>
        )}

        <PrintRESTPCharacterizationReportServices
          format={characterization.format}
          report={transformedReport}
          specification={specification}
        />
      </div>
    </PrintPageWrapper>
  )
}
