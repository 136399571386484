import React, { useState } from 'react'

import { View } from 'lib/components'
import Text from 'lib/components/Text'

interface ITabProps {
  title: string
  disabled?: boolean
  rightContent?: (isActive: boolean) => any
  children: any
}

export function Tab(props: ITabProps) {
  return props.children
}

interface ITabBarProps {
  selectedIndex: number
  index: number
  title: string
  onSelectTab: (index: number) => any
  rightContent?: (isActive: boolean) => any
  disabled?: boolean
  'data-test'?: string
}

function TabBar(props: ITabBarProps) {
  const onSelected = () => props.onSelectTab(props.index)

  const isActive = props.selectedIndex === props.index

  return (
    <View
      height={isActive ? 40 : 36}
      flexible="row-space-between"
      bg={
        props.disabled
          ? 'neutralTabColor'
          : isActive
          ? 'activeTabColor'
          : 'tertiary'
      }
      borderRadius={isActive ? '4px 4px 0 0' : '4px'}
      minWidth={175}
      px={3}
      mr={1}
      mb={isActive ? 0 : 1}
      cursor="pointer"
      onClick={onSelected}
      data-test={props['data-test']}
      data-active={isActive}
      data-disabled={props.disabled}
    >
      <Text
        userSelect="none"
        color={isActive && !props.disabled ? 'inactiveTabColor' : 'white'}
        fontWeight={700}
      >
        {props.title}
      </Text>

      {props.rightContent ? props.rightContent(isActive) : null}
    </View>
  )
}

interface IProps {
  children: any
}

export default function Tabs(props: IProps) {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const onTabSelected = (index: number) => setSelectedIndex(index)

  const activeTab = React.Children.toArray(props.children)[selectedIndex] as any

  return (
    <View width="100%">
      <View flexible="row">
        {React.Children.map(props.children, (tab, index) => {
          return (
            <TabBar
              key={`tab-${index}`}
              selectedIndex={selectedIndex}
              index={index}
              onSelectTab={onTabSelected}
              data-test={tab.props['data-test'] || `tab-${index}`}
              {...tab.props}
            />
          )
        })}
      </View>

      <View
        bg={activeTab.props.disabled ? 'neutralTabColor' : 'activeTabColor'}
        borderRadius="0 0 4px 4px"
        minHeight={200}
        p={3}
      >
        {activeTab}
      </View>
    </View>
  )
}
