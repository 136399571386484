import React from 'react'
import clsx from 'clsx'

import {
  MEMBERSHIP_LEVEL_TO_BG_COLOR_MAP,
  NON_MEMBER_ORG_MEMBERSHIP_DISPLAY,
  OrgMembershipTypesDisplay,
} from 'domains/orgs/constants/organization'

interface IProps {
  membershipType?: OrgMembershipTypesDisplay
  'data-test'?: string
}

export default function OrgMembershipLevelChip(props: IProps) {
  const level = props.membershipType || NON_MEMBER_ORG_MEMBERSHIP_DISPLAY
  return (
    <div
      className={clsx(
        'flex flex-col items-center justify-center px-3 py-2',
        MEMBERSHIP_LEVEL_TO_BG_COLOR_MAP[level],
      )}
      data-test={props['data-test'] || 'org-membership-chip'}
    >
      <p className="text-white text-base">{level.toUpperCase()}</p>
    </div>
  )
}
