import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { utils } from '@ims/1edtech-frontend-common'

import ICharacterization from 'domains/characterizations/models/ICharacterization'
import { FORMAT_TO_DISPLAY_FORMAT_BASE_NAME } from 'domains/formats/constants/formats'
import { getCharacterizationRoute } from 'domains/characterizations/navigation/routes'

function Title({ children }: { children: string }) {
  return <p className="text-sm md:text-md 2xl:text-lg font-bold">{children}</p>
}

type CharacterizationSummaryTitleProps = {
  characterization: ICharacterization
}

export default function CharacterizationSummaryTitle({
  characterization,
}: CharacterizationSummaryTitleProps) {
  return (
    <Link
      to={getCharacterizationRoute(
        characterization.id,
        characterization.format,
      )}
    >
      <div
        className={clsx(
          'flex flex-row flex-wrap items-center space-x-2 lg:space-x-4',
          'hover:text-complete cursor-pointer',
        )}
      >
        <Title>{characterization.name}</Title>
        <Title> | </Title>
        <Title>{utils.date.formatForDisplay(characterization.runDate)}</Title>
        <Title> | </Title>
        <Title>
          {FORMAT_TO_DISPLAY_FORMAT_BASE_NAME[characterization.format]}
        </Title>
      </div>
    </Link>
  )
}
