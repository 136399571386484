import React from 'react'

import { ListPlainTextCell } from 'lib/components'
import IFileSetCharacterizationColumn from 'domains/characterizations/models/IFileSetCharacterizationColumn'
import ICSVSpecification from 'domains/specifications/models/ICSVSpecification'
import SpecificationDescription from 'domains/modeling/components/SpecificationDescription'
import { findSpecColumn } from 'domains/modeling/selectors/csvCModelToSpec'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'

const isPrint = isOnPrintScreen()

interface IProps extends IFileSetCharacterizationColumn {
  fileLabel: string
  specification: ICSVSpecification
  width?: string | (string | number)[]
}

export default function CharacterizationColumnDescriptionCell(props: IProps) {
  const {
    fileLabel,
    headerName,
    description,
    specification,
    width = [200, 250, 250, 'auto', 'auto'],
  } = props
  let display = description
  if (!display) {
    const specColumn = findSpecColumn(specification, fileLabel, headerName)

    if (!specColumn) {
      return null
    }
    display = specColumn.notes
  }
  return (
    <ListPlainTextCell
      py={isPrint ? 1 : 3}
      wordBreak="break-word"
      overflow="auto"
      width={width}
    >
      <SpecificationDescription description={display} />
    </ListPlainTextCell>
  )
}
