import { putRequest } from 'lib/api/api'
import { getRecord } from 'lib/records/workflows/getRecord'
import { ORGANIZATIONS_RECORD } from 'lib/records/modules/organizations'

export const setOrgComparisonSharingPref = async (
  orgId: number,
  on: boolean,
) => {
  await putRequest(`orgs/${on ? 'share' : 'private'}/${orgId}`)
  await getRecord(ORGANIZATIONS_RECORD, orgId)
}
