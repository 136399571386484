import React, { PureComponent } from 'react'
import * as R from 'ramda'

import { Text, View, Icon, Linkify, ToolTip } from 'lib/components'
import {
  ICSVFileComparisonReport,
  ICSVFileColumnComparisonReport,
} from 'domains/comparisons/models/ICSVComparisonReport'
import { utils } from '@ims/1edtech-frontend-common'
import ICSVSpecification from 'domains/specifications/models/ICSVSpecification'
import ReportTopLevelError from 'domains/reports/components/ReportTopLevelError'
import colors from 'lib/styles/colors'
import ComparisonReportLegend from 'domains/comparisons/components/ComparisonReport/ComparisonReportLegend'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'
import ReportStatusIcon from 'domains/reports/components/ReportStatusIcon'
import PrintHR from 'domains/printing/components/PrintHR'
import { COMPARISON_STATUS_TO_PRINT_DISPLAY_MAP } from 'domains/reports/models/IReportStatuses'
import CSVComparisonFileColumnRow from 'domains/comparisons/components/CSVComparisonFileColumnRow'
import CharacterizationErrorDot from 'domains/characterizations/components/CharacterizationErrorDot'

const isPrint = isOnPrintScreen()

interface IProps {
  fileComparison: ICSVFileComparisonReport
  specification: ICSVSpecification
}

export class ComparisonFileReport extends PureComponent<IProps> {
  hasNotes = () =>
    this.props.fileComparison.notes &&
    utils.hasValue(this.props.fileComparison.notes)

  renderTitle() {
    if (!isPrint) {
      return null
    }

    const statusText =
      COMPARISON_STATUS_TO_PRINT_DISPLAY_MAP[this.props.fileComparison.status]
    const hasStatusText = utils.hasValue(statusText)
    return (
      <View flexible="row" mb={2}>
        <ReportStatusIcon
          status={this.props.fileComparison.status}
          fontSize={20}
        />
        <Text fontSize={18} fontWeight={700} ml={2}>
          {this.props.fileComparison.filename}
        </Text>
        {hasStatusText && (
          <Text fontSize={12} fontWeight={700} ml={1}>
            {' '}
            - {statusText}
          </Text>
        )}
      </View>
    )
  }

  renderFileNotes() {
    const { fileComparison } = this.props
    if (this.hasNotes()) {
      return (
        <View
          ml={isPrint ? 4 : 2}
          data-test={`file-notes-${fileComparison.filename}`}
          flexible={isPrint ? 'row' : 'column'}
        >
          <Text
            fontSize={isPrint ? 14 : 18}
            fontWeight={700}
            mb={2}
            minWidth={72}
          >
            {!isPrint && (
              <Icon className="fas fa-info-circle" color="info" mr={1} />
            )}
            File Notes:
          </Text>

          <View flexible="row-v-center">
            <View mr={1}>
              <ToolTip
                id={`file-notes-${fileComparison.filename}`}
                tip="This file contains consumer notes"
                place="right"
              >
                <CharacterizationErrorDot color="secondary" size={18} />
              </ToolTip>
            </View>
            <Text wordBreak="break-word" whiteSpace="pre-line">
              <Linkify>{fileComparison.notes!}</Linkify>
            </Text>
          </View>
        </View>
      )
    }

    return <View />
  }

  renderColumn = (column: ICSVFileColumnComparisonReport, index: number) => (
    <CSVComparisonFileColumnRow
      key={`file-column-${this.props.fileComparison.filename}-${column.name}`}
      specification={this.props.specification}
      fileComparison={this.props.fileComparison}
      columnComparison={column}
      index={index}
    />
  )

  renderColumns() {
    const { fileComparison } = this.props
    const columns = R.propOr<
      ICSVFileColumnComparisonReport[],
      ICSVFileComparisonReport,
      ICSVFileColumnComparisonReport[]
    >([], 'columnComparisons', fileComparison)
    if (columns.length < 1) {
      return null
    }

    const content = columns.map(this.renderColumn)

    return isPrint ? (
      <View flexible="column">{content}</View>
    ) : (
      <View overflowX="auto">{content}</View>
    )
  }

  render() {
    const { fileComparison } = this.props
    return (
      <View
        p={[1, 3]}
        bg="white"
        border={isPrint ? undefined : `1px solid ${colors.reportTableBorder}`}
        flex={1}
      >
        <View
          width="100%"
          display="grid"
          gridTemplateColumns={['auto', 'auto', isPrint ? 'auto' : '3fr 1fr']}
          gridColumnGap={3}
          mb={3}
        >
          {this.renderTitle()}
          {this.renderFileNotes()}
          {!isPrint && (
            <View mt={this.hasNotes() ? 3 : 0}>
              <ComparisonReportLegend />
            </View>
          )}
        </View>

        {utils.hasValue(fileComparison.errorTitle) && (
          <ReportTopLevelError
            status={fileComparison.status}
            title={fileComparison.errorTitle!}
            description={fileComparison.errorMessage!}
            dataTest={`${fileComparison.filename}-file-error`}
          />
        )}
        {this.renderColumns()}

        {isPrint && (
          <View mt={2}>
            <PrintHR />
          </View>
        )}
      </View>
    )
  }
}
