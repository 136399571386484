import React, { useContext } from 'react'
import * as R from 'ramda'

import ProductModelResourceGrid from 'domains/modeling/components/ProductModeling/ProductModelResourceGrid'
import { ProductModelResource } from 'domains/products/components/ProductModelResource'
import {
  ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
  AnySpecFormatType,
} from 'domains/formats/constants/formats'
import ProductModelingWrapper from 'domains/modeling/components/ProductModeling/ProductModelingWrapper'
import IRestCModel from 'domains/modeling/models/IRestCModel'
import {
  ROSTERING_SERVICE_GROUPINGS_MAP,
  OR_1P1_REST_ROSTERING_CONSUMER_SERVICES,
} from 'domains/modeling/constants/restCOneRoster1p1'
import {
  findModelServiceByName,
  isModelServiceTouched,
  getRestCModelProgress,
} from 'domains/modeling/utils/restCOR1p1utils'
import * as Statuses from 'domains/modeling/constants/files'
import { RouteComponentProps } from 'react-router'
import { RouterContext } from 'domains/application/screens/AppScreen'
import { View, Text, ProgressBar, Span } from 'lib/components'
import { getProductModelingServicesRoute } from 'domains/products/navigation/routes'
import UnsavedModelWarning from 'domains/modeling/components/UnsavedModelWarning'
import useModalState from 'lib/hooks/useModalState'
import EditModelWarning from 'domains/modeling/components/EditModelWarning'
import IProduct from 'domains/products/models/IProduct'
import { isProductCharacterized } from 'domains/products/utils/products'
import Dialog from 'domains/application/modals/Dialog'
import ModalTitle from 'lib/components/ModalTitle'
import { getCharacterizedWarningMessage } from 'domains/modeling/utils/characterizedWarningMessage'
import { resetRestCServices } from 'domains/modeling/workflows/resetRestCServices'
import { trackRestCModelingAnalytics } from 'domains/modeling/utils/trackModelingAnalytics'

interface IProps {
  product: IProduct
  format: AnySpecFormatType
  model: IRestCModel
  modelHasUnsavedChanges: boolean
}

export default function ProductModelingRestCEndpontSetStatus(props: IProps) {
  const router = useContext(RouterContext) as RouteComponentProps

  const [servicesToEdit, setServicesToEdit] = React.useState<
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES[]
  >([])

  const [
    unsavedChangesOpen,
    openUnsavedChangesModal,
    closeUnsavedChangesModal,
  ] = useModalState()

  const [editModelWarningOpen, openEditModelWarning, closeEditModelWarning] =
    useModalState()

  const onEditConfirmed = () => {
    closeUnsavedChangesModal()
    if (isProductCharacterized(props.product, props.format)) {
      trackRestCModelingAnalytics('edited_characterized_services', {
        services: servicesToEdit,
      })
    }
    editServices(servicesToEdit)
  }

  const onEditDenied = () => {
    setServicesToEdit([])
    closeUnsavedChangesModal()
  }

  const editServices = (
    services: OR_1P1_REST_ROSTERING_CONSUMER_SERVICES[],
  ) => {
    setServicesToEdit([])
    router.history.push(
      getProductModelingServicesRoute(
        props.model.productId,
        props.model.id,
        services,
      ),
      { noReload: true },
    )
  }

  const onEdit =
    (services: OR_1P1_REST_ROSTERING_CONSUMER_SERVICES[]) => () => {
      setServicesToEdit(services)
      if (props.modelHasUnsavedChanges) {
        openUnsavedChangesModal()
      } else if (isProductCharacterized(props.product, props.format)) {
        openEditModelWarning()
      } else {
        editServices(services)
      }
    }

  const onLaunch =
    (services: OR_1P1_REST_ROSTERING_CONSUMER_SERVICES[]) => () => {
      if (props.modelHasUnsavedChanges) {
        setServicesToEdit(services)
        openUnsavedChangesModal()
      } else {
        router.history.push(
          getProductModelingServicesRoute(
            props.model.productId,
            props.model.id,
            services,
          ),
          { noReload: true },
        )
      }
    }

  const [servicesToReset, setServicesToReset] = React.useState<
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES[]
  >([])

  const [resetWarningOpen, openResetWarningModal, closeResetWarningModal] =
    useModalState()

  const onResetConfirmed = async () => {
    await resetRestCServices(props.model.id, servicesToReset)
    closeResetWarningModal()
  }

  const onReset =
    (services: OR_1P1_REST_ROSTERING_CONSUMER_SERVICES[]) => () => {
      setServicesToReset(services)
      openResetWarningModal()
    }

  const overallProgress = getRestCModelProgress(props.model)

  return (
    <ProductModelingWrapper>
      <ProductModelResourceGrid>
        {ROSTERING_SERVICE_GROUPINGS_MAP.map(
          (grouping: OR_1P1_REST_ROSTERING_CONSUMER_SERVICES[]) => {
            const serviceFound = findModelServiceByName(
              props.model,
              grouping[0],
            )
            if (serviceFound) {
              const name = grouping.join(',\n')
              const complete = R.all<OR_1P1_REST_ROSTERING_CONSUMER_SERVICES>(
                isModelServiceTouched(props.model),
                grouping,
              )
              return (
                <ProductModelResource
                  key={name}
                  name={grouping}
                  modelId={props.model.id}
                  format={ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL}
                  onEdit={onEdit(grouping)}
                  onLaunch={onLaunch(grouping)}
                  onReset={onReset(grouping)}
                  dataTest={grouping.join(', ')}
                  status={
                    complete
                      ? Statuses.COMPLETE_STATUS
                      : Statuses.NOT_STARTED_STATUS
                  }
                />
              )
            }

            return null
          },
        )}
      </ProductModelResourceGrid>

      <View mt={3} width="100%">
        <Text fontWeight={700} mb={3}>
          Modeling Progress:
        </Text>
        <ProgressBar progress={overallProgress} dataTest="model-progress" />

        <Text
          variant="caption"
          fontStyle="italic"
          mt={3}
          data-test="model-progress-description"
        >
          You have completed <Span fontWeight={700}>{overallProgress}%</Span> of
          the needed modeling to begin Characterization. Characterization
          becomes available at 100%.
        </Text>
      </View>

      <EditModelWarning
        isOpen={editModelWarningOpen}
        product={props.product}
        onConfirm={onEditConfirmed}
        closeModal={closeEditModelWarning}
      />
      <UnsavedModelWarning
        isOpen={unsavedChangesOpen}
        onConfirm={onEditConfirmed}
        onDeny={onEditDenied}
      />
      <Dialog
        title={
          <ModalTitle
            title="Are you sure you want to reset @@"
            highlight={servicesToReset.join(', ')}
          />
        }
        isOpen={resetWarningOpen}
        icon="fas fa-x-circle"
        message={
          isProductCharacterized(props.product, props.format)
            ? getCharacterizedWarningMessage(props.product.name)
            : 'This will reset the modeling back to the specification.'
        }
        onConfirm={onResetConfirmed}
        onDeny={closeResetWarningModal}
      />
    </ProductModelingWrapper>
  )
}
