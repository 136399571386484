import React from 'react'
import { Formik, FormikHelpers } from 'formik'
import { records } from '@ims/1edtech-frontend-common'

import IUserPreferences, {
  NotificationPreference,
} from 'domains/users/models/IUserPreferences'
import { ModalWrapper, View, InputLabel } from 'lib/components'
import { updateRecord } from 'lib/records/workflows/updateRecord'
import { USER_PREFERENCES_RECORD } from 'lib/records/modules/userPreferences'
import { listenForNotifications } from 'domains/notifications/workflows/listenForNotifications'
import { meSelector } from 'domains/authentication/selectors/me'
import { useSelector } from 'react-redux'
import IUser from 'domains/users/models/IUser'
import PushNotificationsToggle from 'domains/notifications/components/PushNotificationsToggle'
import { RootState } from 'lib/store/rootReducer'

interface IFormValues {
  notifications: NotificationPreference
}

interface IProps {
  id: number
  isOpen: boolean
  close: () => any
}

export default function EditUserPreferencesModal(props: IProps) {
  const me = useSelector(meSelector) as IUser
  const preferences = useSelector<RootState, IUserPreferences>(
    records.entitiesSelectors.entityByIdSelector(
      USER_PREFERENCES_RECORD,
      props.id,
    ),
  )

  const onSave = async (
    values: IFormValues,
    bag: FormikHelpers<IFormValues>,
  ) => {
    await updateRecord(USER_PREFERENCES_RECORD, props.id, {
      notifications: { inApp: { preference: values.notifications } },
    })
    if (me.id === props.id) {
      await listenForNotifications(values.notifications)
    }
    bag.resetForm()
    bag.setSubmitting(false)
    props.close()
  }

  if (!preferences) {
    return null
  }

  return (
    <Formik
      initialValues={{
        notifications: preferences.notifications.inApp.preference,
      }}
      onSubmit={onSave}
      enableReinitialize={true}
    >
      {(bag) => (
        <ModalWrapper
          isOpen={props.isOpen}
          title="Edit Preferences"
          pending={bag.isSubmitting}
          actions={[
            {
              text: 'Save',
              variant: 'start',
              onClick: bag.handleSubmit,
              extra: { type: 'submit' },
            },
            {
              text: 'Cancel',
              variant: 'neutral',
              onClick: () => {
                bag.resetForm()
                props.close()
              },
              extra: {
                type: 'button',
              },
            },
          ]}
        >
          <View width={['100%', 600]} maxWidth={['100%', 600]}>
            <InputLabel>Push Notifications</InputLabel>
            <PushNotificationsToggle
              userId={props.id}
              value={bag.values.notifications}
              onChange={bag.setFieldValue}
            />
          </View>
        </ModalWrapper>
      )}
    </Formik>
  )
}
