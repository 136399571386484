const primary = '#2a2867'
const secondary = '#ff941f'
const tertiary = '#57afe2'
const complete = '#3333FF'
const background = '#F6F7FA'
const locked = '#063771'

const text = '#2d2f2f'
const textLight = '#6c757d6c757d'
const neutral = '#c2c2c2'
const textHighlight = tertiary

const colors = {
  primary,
  secondary,
  complete,
  tertiary,
  background,
  locked,
  errorLight: '#fed7d9',
  errorAlt: '#E57800',
  start: '#6666FF',
  paused: secondary,
  warning: '#FFCA29',
  warningAlt: '#E9821E',
  info: tertiary,
  infoLight: '#6DCFF6',
  infoAlert: '#fffcda',
  focusShadow: 'rgba(0,123,255,.25)',
  white: '#fff',
  neutral,
  builtInMetaDataBG: '#dde7f5',
  customMetaDataBG: '#fffcda',
  grey: '#2F2F2F',

  reportSuccessLight: '#daf2e4',
  reportAmberLight: '#fbe6d3',
  reportAlertLight: '#fdcbcd',
  reportTableBorder: '#ebebec',
  reportRowDivider: '#676767',
  reportDisabled: '#6F7071',

  // Font colors
  text,
  textLight,
  textHighlight,
  placeholder: '#a3a3a3',

  chartColors: [
    primary,
    secondary,
    '#F564A9',
    '#242038',
    complete,
    '#5CC8FF',
    '#FAA4BD',
    '#FAE3C6',
    '#725AC1',
    '#8D86C9',
    '#EE9480',
    '#7D70BA',
  ],

  activeTabColor: '#e1e1e1',
  inactiveTabColor: tertiary,
  neutralTabColor: '#707070',

  ccReportBlue900: '#E3E7F3',
  ccReportBlue700: '#CDD3EB',
  ccReportBlue500: '#5280A4',
  ccReportPeach900: '#BCAEAB',
  ccReportPeach700: '#FEECE8',
  ccReportItemTitleBG: '#bce6f8',
  ccReportItemBG: '#E6F7FE',
  ccReportStandardTitleBG: '#e1d8d1',
  ccReportStandardBG: '#F6F1ED',
  ccReportLOMTitle: '#B7B7B7',
  ccReportLOMDetail: '#E7E7E7',
  ccReportMetadata: '#F0F0F0',
  ccReportResourceTitleBG: '#D3DDD2',
  ccReportResourceBG: '#EFF6EE',
  ccReportResourceTitle: '#0D5C2E',

  'ta-gray-1': '#c6c6c6',
  'ta-gray-2': '#aaaaaa',
  'ta-gray-3': '#747474',
  'ta-gray-4': '#636060',
  'ta-blue-1': '#98bbff',
  'ta-blue-2': '#6699ff',
  'ta-blue-3': '#4769ad',
  'ta-blue-4': '#1B7CDD',
  'ta-blue-5': '#509BD8',

  'ta-titlebar': '#E9EBED',
  'ta-approved-limited-purple': '#4C236C',
  'ta-under-review-orange': '#EAAD5D',

  silver: '#EBEBEB',
  gray: '#919496',
  charcoal: '#2F2F2F',
  navy: {
    0: '#000000',
    10: '#00001A',
    20: '#000038',
    30: '#000057',
    40: '#000072',
    50: '#00009E',
    60: '#3333FF',
    70: '#6666FF',
    80: '#9999FF',
    90: '#CCCCFF',
  },
  error: {
    10: '#511600',
    20: '#7A2100',
    30: '#A32C00',
    40: '#CC4614',
    50: '#F56530',
    60: '#FF8457',
    70: '#FF9F7C',
    80: '#FFBAA0',
    90: '#FFD5C5',
    95: '#FFF0EA',
  },
  amber: {
    0: '#000000',
    10: '#4D2800',
    20: '#995000',
    30: '#E57800',
    40: '#FF941F',
    50: '#FFB86B',
    60: '#FFC07A',
    70: '#FFCE99',
    80: '#FFDAB3',
    90: '#FFE2C2',
  },
  success: {
    10: '#04481E',
    20: '#0E6A31',
    30: '#1E8C48',
    40: '#33AE62',
    50: '#4ED080',
    60: '#6FF2A1',
    70: '#94FFBD',
    80: '#B3FFD0',
    90: '#D2FFE3',
    95: '#F1FFF6',
  },
  plum: {
    0: '#000000',
    10: '#160A1F',
    20: '#2C143E',
    30: '#4C236C',
    40: '#662F90',
    50: '#833CB9',
    60: '#9656C8',
    70: '#B384D7',
    80: '#C6A3E1',
    90: '#E2D1F0',
  },
  sky: {
    10: '#124360',
    20: '#1E5D82',
    30: '#2E78A4',
    40: '#4094C6',
    50: '#55B1E8',
    60: '#69C7FF',
    70: '#8AD3FF',
    80: '#ACE0FF',
    90: '#CEECFF',
    95: '#F0F9FF',
  },
  gold: {
    10: '#5C4500',
    20: '#856300',
    30: '#AD8200',
    40: '#D6A000',
    50: '#FFBF00',
    60: '#FFCA2E',
    70: '#FFD65C',
    80: '#FFE18A',
    90: '#FFEDB7',
    95: '#FFF8E5',
  },
  violet: {
    10: '#160055',
    20: '#1F0077',
    30: '#280099',
    40: '#3809BB',
    50: '#4B17DD',
    60: '#6129FF',
    70: '#8459FF',
    80: '#A889FF',
    90: '#CBB9FF',
    95: '#EFE9FF',
  },
}

module.exports = colors
