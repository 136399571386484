import React, { HTMLAttributes, PureComponent } from 'react'
import styled from 'styled-components'
import * as SS from 'styled-system'
import * as CSS from 'csstype'
import * as R from 'ramda'

import colors from 'lib/styles/colors'
import ButtonBadge, { ButtonBadgeVariants } from 'lib/components/ButtonBadge'
import View, { flexibleStyle } from 'lib/components/View'
import Spinner from 'lib/components/Spinner'

export const variantStyle = SS.variant({
  key: 'buttonStyles',
})

export type ButtonVariants =
  | 'primary'
  | 'complete'
  | 'flat'
  | 'secondary'
  | 'tertiary'
  | 'start'
  | 'success'
  | 'error'
  | 'paused'
  | 'warning'
  | 'warningAlt'
  | 'neutral'
  | 'neutral-dark-text'
  | 'gray-400'
  | 'gray-500'
  | 'black'
  | 'info'
  | 'disabled'
  | 'ccReportBlue500'
  | undefined
  | string

export const buttonStyles = {
  primary: {
    color: '#fff',
    backgroundColor: colors.primary,
  },
  complete: {
    color: '#fff',
    backgroundColor: colors.complete,
  },
  flat: {
    color: colors.primary,
    backgroundColor: 'transparent',
    borderRadius: '2px',
    fontSize: '14px',
  },
  secondary: {
    color: '#fff',
    backgroundColor: colors.secondary,
  },
  tertiary: {
    color: '#fff',
    backgroundColor: colors.tertiary,
  },
  start: {
    color: '#fff',
    backgroundColor: colors.start,
  },
  success: {
    color: '#fff',
    backgroundColor: colors.success,
  },
  error: {
    color: '#fff',
    backgroundColor: colors.error,
  },
  paused: {
    color: '#fff',
    backgroundColor: colors.paused,
  },
  warning: {
    color: '#fff',
    backgroundColor: colors.warning,
  },
  warningAlt: {
    color: '#fff',
    backgroundColor: colors.warningAlt,
  },
  neutral: {
    color: '#fff',
    backgroundColor: colors.neutral,
  },
  'neutral-dark-text': {
    color: colors.text,
    backgroundColor: colors.neutral,
  },
  'gray-400': {
    color: '#fff',
    backgroundColor: '#9CA3AF',
  },
  'gray-500': {
    color: '#fff',
    backgroundColor: '#6B7280',
  },
  black: {
    color: '#fff',
    backgroundColor: '#000',
  },
  info: {
    color: '#fff',
    backgroundColor: colors.infoLight,
  },
  disabled: {
    color: '#fff',
    backgroundColor: colors.neutral,
    '&:hover': {
      cursor: 'not-allowed',
      backgroundColor: colors.neutral,
    },
  },
  ccReportBlue500: {
    color: '#fff',
    backgroundColor: colors.ccReportBlue500,
  },
}
// prettier-ignore
export const focusShadow = `
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem ${colors.focusShadow};
    text-decoration: none;
  }
`

export interface IHtmlButtonElementProps // eslint-disable-line
  extends HTMLAttributes<HTMLButtonElement> {}
interface IProps
  extends IHtmlButtonElementProps,
    SS.AlignContentProps,
    SS.AlignItemsProps,
    SS.AlignSelfProps,
    SS.BackgroundColorProps,
    SS.BorderColorProps,
    SS.BorderProps,
    SS.BorderRadiusProps,
    SS.BordersProps,
    SS.BottomProps,
    SS.ColorProps,
    SS.DisplayProps,
    SS.FlexBasisProps,
    SS.FlexDirectionProps,
    SS.FlexProps,
    SS.FlexWrapProps,
    SS.FontSizeProps,
    SS.FontStyleProps,
    SS.FontWeightProps,
    SS.GridAutoFlowProps,
    SS.GridColumnGapProps,
    SS.GridRowGapProps,
    SS.GridTemplateColumnsProps,
    SS.GridTemplateRowsProps,
    SS.HeightProps,
    SS.JustifyContentProps,
    SS.JustifySelfProps,
    SS.LeftProps,
    SS.LineHeightProps,
    SS.MaxHeightProps,
    SS.MaxWidthProps,
    SS.MinHeightProps,
    SS.MinWidthProps,
    SS.OrderProps,
    SS.OverflowProps,
    SS.PositionProps,
    SS.RightProps,
    SS.SpaceProps,
    SS.TextAlignProps,
    SS.TopProps,
    SS.VerticalAlignProps,
    SS.WidthProps,
    SS.ZIndexProps {
  disabled?: any
  type?: 'button' | 'reset' | 'submit' | undefined
  color?: CSS.Property.Color
  flexible?: string
  variant?: ButtonVariants
  badgeType?: string
  hover?: string
  onClick?: (e?: any) => any
}

// prettier-ignore
const ButtonComponent = styled.button<IProps>`
  box-sizing: border-box;
  color: #fff;
  border-radius: 75px;
  border: 1px solid transparent;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5;
  padding: 6px 16px;
  text-align: center;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out, filter .15s ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
    filter: brightness(88%);
  }
  &:disabled {
    cursor: not-allowed;
  }
  ${focusShadow}
  ${flexibleStyle}
  ${variantStyle}

  ${SS.alignContent}
  ${SS.alignItems}
  ${SS.alignSelf}
  ${SS.bottom}
  ${SS.color}
  ${SS.display}
  ${SS.flexBasis}
  ${SS.flexDirection}
  ${SS.flexWrap}
  ${SS.flex}
  ${SS.fontSize}
  ${SS.fontWeight}
  ${SS.height}
  ${SS.justifyContent}
  ${SS.justifySelf}
  ${SS.left}
  ${SS.lineHeight}
  ${SS.maxHeight}
  ${SS.maxWidth}
  ${SS.minHeight}
  ${SS.minWidth}
  ${SS.order}
  ${SS.position}
  ${SS.right}
  ${SS.space}
  ${SS.top}
  ${SS.width}
  ${SS.zIndex}
`

ButtonComponent.displayName = 'Button'
ButtonComponent.defaultProps = { variant: 'primary' }
const largeButtonStyle = { fontSize: '18px' }
const largeButtonBadgeStyle = { fontSize: '18px', top: '4px' }

interface IButtonProps extends IProps {
  badgeType?: ButtonBadgeVariants
  badgeContent?: any
  large?: boolean
  pending?: boolean
  children: any
  innerMinWidth?: any
}

export default class Button extends PureComponent<IButtonProps> {
  render() {
    const {
      badgeType,
      badgeContent,
      children,
      large = false,
      pending = false,
      innerMinWidth = 64,
    } = this.props

    const button = (
      <ButtonComponent style={large ? largeButtonStyle : {}} {...this.props}>
        <View flexible="column-center" minWidth={innerMinWidth}>
          {pending ? <Spinner size={12} /> : children}
        </View>
      </ButtonComponent>
    )
    if (badgeType) {
      return (
        <View
          position="relative"
          {...(large ? largeButtonStyle : {})}
          {...R.pick(['p', 'py', 'px'], this.props)}
        >
          {button}
          {!!badgeType && (
            <ButtonBadge
              variant={badgeType}
              style={large ? largeButtonBadgeStyle : {}}
            >
              {badgeContent}
            </ButtonBadge>
          )}
        </View>
      )
    }

    return button
  }
}
