import React, { useState } from 'react'
import * as R from 'ramda'
import { Formik, Form, FormikHelpers } from 'formik'
import { RouteComponentProps } from 'react-router'
import memoize from 'fast-memoize'
import { utils } from '@ims/1edtech-frontend-common'

import useFullRESTCModel from 'domains/modeling/hooks/useFullRESTCModel'
import useFullProductByFormat from 'domains/products/hooks/useFullProductByFormat'
import {
  Spinner,
  View,
  Text,
  Span,
  WizardFooter,
  Switch,
  InputLabel,
  FormErrorMessage,
} from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import IProduct from 'domains/products/models/IProduct'
import BaseProductDetailsBreadCrumbs from 'domains/products/components/BaseProductDetailsBreadCrumbs'
import {
  ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
  AnySpecFormatType,
} from 'domains/formats/constants/formats'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import { modelDetailValidation } from 'domains/modeling/utils/modelDetailValidation'
import ProductModelDetailEditor, {
  IProductModelValues,
} from 'domains/modeling/components/ProductModeling/ProductModelDetailEditor'
import ProductModelingRestCEndpontSetStatus from 'domains/modeling/components/ProductModeling/ProductModelingRestCEndpontSetStatus'
import IRestCModel from 'domains/modeling/models/IRestCModel'
import { updateRestCModel } from 'domains/modeling/workflows/updateRestCModel'
import { showToast, SUCCESS_TOAST, ERROR_TOAST } from 'lib/utils/toast'
import UnsavedModelWarning from 'domains/modeling/components/UnsavedModelWarning'
import ResetProductDialog from 'domains/products/components/ResetProductDialog'
import {
  getProductStartModelingRoute,
  getProductModelCharacterizationRoute,
  getProductModelingRoute,
} from 'domains/products/navigation/routes'
import CharacterizeProductModal from 'domains/characterizations/components/CharacterizeProductModal'
import { getRestCModelProgress } from 'domains/modeling/utils/restCOR1p1utils'
import { isProductCharacterized } from 'domains/products/utils/products'
import useModalState from 'lib/hooks/useModalState'
import EditModelWarning from 'domains/modeling/components/EditModelWarning'
import ProductModelingWrapper from 'domains/modeling/components/ProductModeling/ProductModelingWrapper'
import { getModelsForFormat } from 'domains/modeling/workflows/getModelsForFormat'
import { createRestModel } from 'domains/modeling/workflows/createRestModel'
import { OR1P1Subsets } from 'domains/specifications/constants/oneRoster1p1FileSets'

export default function ProductModelingRestEditScreen(
  props: RouteComponentProps,
) {
  const [productData, formatData, , productPending] = useFullProductByFormat(
    props,
    'id',
    'format',
  )
  const [modelData, , modelPending] = useFullRESTCModel(
    props,
    'id',
    'modelId',
    'format',
  )

  const [
    characterizeWarningOpen,
    openCharacterizeWarning,
    closeCharacterizeWarning,
  ] = useModalState()

  const [cancelOpen, openCancelModel, closeCancelModel] = useModalState()
  const [cancelRoute, setCancelRoute] = useState('')

  const [editModelWarningOpen, openEditModelWarning, closeEditModelWarning] =
    useModalState()

  const [resetOpen, openResetModal, closeResetModal] = useModalState()

  if (
    productPending ||
    modelPending ||
    !utils.hasValue(productData) ||
    !utils.hasValue(modelData)
  ) {
    return (
      <View variant="screen">
        <Spinner centered={true} />
      </View>
    )
  }

  const product = productData as IProduct
  const model = modelData as IRestCModel
  const specFormat = formatData as AnySpecFormatType

  const onNavigateAway = memoize(
    (isDirty) => (e: React.MouseEvent<any>, route: string) => {
      if (isDirty) {
        e.preventDefault()
        openCancelModel()
        setCancelRoute(route)
      }
    },
  )

  const onCancelConfirmed = async () => {
    closeCancelModel()
    if (utils.hasValue(cancelRoute)) {
      props.history.push(cancelRoute)
    }
  }

  const afterReset = async () => {
    const format = ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL
    const startModelingRoute = getProductStartModelingRoute(product.id, format)
    const { success, data } = await getModelsForFormat(format)

    // Create model and go to model screen
    if (!success || data.length < 1) {
      const model = await createRestModel(
        product.id,
        format,
        OR1P1Subsets.CLASS_ROSTERS_W_DEMOGRAPHICS_SUBSET,
      )
      if (model) {
        closeResetModal()
        props.history.push(getProductModelingRoute(product.id, model.id))
        return
      }
    }

    props.history.replace(startModelingRoute)
  }

  const goToCharacterization = () =>
    props.history.push(
      getProductModelCharacterizationRoute(product.id, model.id),
    )
  const onSave = async (
    values: IProductModelValues,
    bag: FormikHelpers<IProductModelValues>,
  ) => {
    // Since this can be invoked by edit warning modal
    closeEditModelWarning()
    await updateModel(values, bag)
  }

  const updateModel = async (
    values: IProductModelValues,
    bag: FormikHelpers<IProductModelValues>,
  ) => {
    const oauthSupport = values.oauthSupport || 0
    const updates = await updateRestCModel(model.id, {
      ...model,
      ...R.dissoc('oauthSupport', values),
      oauth1Supported: oauthSupport === 1 || oauthSupport === 3,
      oauth2Supported: oauthSupport >= 2,
      format: specFormat,
    })
    if (updates) {
      showToast(SUCCESS_TOAST, 'Model updated')
    } else {
      showToast(ERROR_TOAST, 'Failed to update model')
    }
    bag.setSubmitting(false)
  }

  const modelProgress = getRestCModelProgress(model)
  const canCharacterize = modelProgress >= 100
  const isCharacterized = isProductCharacterized(product, specFormat)

  return (
    <View variant="screen">
      <DocumentTitle title={`Product Modeling - ${product.name}`} />
      <Formik
        initialValues={
          {
            oauthSupport:
              model.oauth1Supported && model.oauth2Supported
                ? 3
                : model.oauth2Supported
                ? 2
                : model.oauth1Supported
                ? 1
                : 0,
            notes: model.notes || '',
            implementationUrl: model.implementationUrl || '',
            onlineHelpUrl: model.onlineHelpUrl || '',
            productSupportEmail: model.productSupportEmail || '',
          } as IProductModelValues
        }
        validateOnMount
        validationSchema={modelDetailValidation(true)}
        onSubmit={onSave}
        enableReinitialize={true}
      >
        {({
          values,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          dirty,
          errors,
        }) => {
          const onToggleOauthSupport = (type: number) => {
            const current = values.oauthSupport || 0
            if (current === 0) {
              setFieldValue('oauthSupport', type)
            } else if (current === type || current === 3) {
              setFieldValue('oauthSupport', current - type)
            } else {
              setFieldValue('oauthSupport', Math.min(current + type))
            }
          }
          const onToggleOauth1Support = () => onToggleOauthSupport(1)
          const onToggleOauth2Support = () => onToggleOauthSupport(2)

          const onConfirmDeleteCharAndUpdate = () => {
            closeEditModelWarning()
            handleSubmit()
          }

          return (
            <Form>
              <BaseProductDetailsBreadCrumbs
                id={product.id}
                format={specFormat}
                crumbs={[{ name: 'Modeling' }]}
                beforeNavigate={onNavigateAway(dirty)}
              />
              <View variant="paper">
                <WidgetHeader
                  title={
                    <Text fontSize={18}>
                      REST Consumer v1.1 Modeling Setup:{' '}
                      <Span fontWeight={700}>{model.name}</Span>
                    </Text>
                  }
                />

                <ProductModelingRestCEndpontSetStatus
                  product={product}
                  format={specFormat}
                  model={model}
                  modelHasUnsavedChanges={dirty}
                />

                <ProductModelingWrapper>
                  <div className="w-full flex flex-row items-center space-x-8 mt-3">
                    <div>
                      <InputLabel className="text-sm ml-3" overrideTextSize>
                        OAuth1a/SHA256
                      </InputLabel>
                      <div className="w-20">
                        <Switch
                          on={
                            values.oauthSupport === 1 ||
                            values.oauthSupport === 3
                          }
                          onChange={onToggleOauth1Support}
                          onText="Yes"
                          offText="No"
                          dataTest="oauth1Supported-switch"
                        />
                      </div>
                    </div>

                    <div>
                      <InputLabel className="text-sm ml-3" overrideTextSize>
                        OAuth2
                      </InputLabel>
                      <div className="w-20">
                        <Switch
                          on={
                            values.oauthSupport === 2 ||
                            values.oauthSupport === 3
                          }
                          onChange={onToggleOauth2Support}
                          onText="Yes"
                          offText="No"
                          dataTest="oauth2Supported-switch"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 ml-4 w-full flex flex-col items-start">
                    <FormErrorMessage name="oauthSupport" showImmediately />
                  </div>
                </ProductModelingWrapper>

                <ProductModelDetailEditor
                  mt={0}
                  format={specFormat}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                />

                <WizardFooter
                  pending={isSubmitting}
                  buttons={[
                    {
                      title: 'Save',
                      onClick: isCharacterized
                        ? openEditModelWarning
                        : handleSubmit,
                      variant: 'complete',
                      dataTest: 'save-btn',
                      type: 'submit',
                    },
                    {
                      title: 'Reset All',
                      onClick: openResetModal,
                      variant: 'black',
                      dataTest: 'reset-all-btn',
                      type: 'button',
                    },
                    {
                      title: isCharacterized
                        ? 'View Characterization'
                        : 'Launch Characterization',
                      onClick: isCharacterized
                        ? goToCharacterization
                        : openCharacterizeWarning,
                      variant:
                        canCharacterize && !dirty && !utils.hasValue(errors)
                          ? 'start'
                          : 'neutral',
                      disabled:
                        !canCharacterize || dirty || utils.hasValue(errors),
                      type: 'button',
                      dataTest: 'launch-characterization-btn',
                    },
                  ]}
                />
              </View>

              <CharacterizeProductModal
                modelId={model.id}
                productId={product.id}
                open={characterizeWarningOpen}
                closeModal={closeCharacterizeWarning}
                format={specFormat}
              />
              <EditModelWarning
                isOpen={editModelWarningOpen}
                product={product}
                onConfirm={onConfirmDeleteCharAndUpdate}
                closeModal={closeEditModelWarning}
              />
              <UnsavedModelWarning
                isOpen={cancelOpen}
                onConfirm={onCancelConfirmed}
                onDeny={closeCancelModel}
              />
              <ResetProductDialog
                isOpen={resetOpen}
                product={product}
                format={specFormat}
                onReset={afterReset}
                onCancel={closeResetModal}
              />
            </Form>
          )
        }}
      </Formik>
    </View>
  )
}
