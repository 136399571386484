import React, { PureComponent, HTMLAttributes } from 'react'
import styled from 'styled-components'

import colors from 'lib/styles/colors'
import { View } from 'lib/components/index'
import ToolTip from 'lib/components/ToolTip'

interface IMeterProps extends HTMLAttributes<HTMLDivElement> {} // eslint-disable-line
// prettier-ignore
const Meter = styled.div<IMeterProps>`
  height: 28px;
  position: relative;
  background: ${colors.background};
  border-radius: 2px;
  box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
`

interface IBarProps extends HTMLAttributes<HTMLSpanElement> {} // eslint-disable-line
// prettier-ignore
const Bar = styled.span<IBarProps>`
  display: block;
  height: 100%;
  border-radius: 2px;
  &.success {
    background-color: ${colors.start};
  }
  &.secondary {
    background-color: ${colors.secondary};
  }
  position: relative;
  overflow: hidden;
`

interface IProps {
  id?: string
  progress: number
  variant?: string
  dataTest?: string
}
export default class ProgressBar extends PureComponent<IProps> {
  getWidth() {
    const { progress } = this.props
    if (progress <= 1) {
      return `${Math.round(progress * 100)}%`
    }
    return `${progress}%`
  }

  render() {
    const {
      id = 'progress-bar-tooltip',
      variant = 'secondary',
      dataTest,
      ...rest
    } = this.props
    const progressTip = this.getWidth()
    const dataTestValue = dataTest || 'progress-bar'

    const barClass = progressTip === '100%' ? 'success' : variant

    return (
      <View {...rest} data-test={dataTestValue}>
        <ToolTip
          id={id}
          tip={`${progressTip} Complete`}
          dataTest={`${dataTestValue}-tooltip`}
        >
          <Meter>
            <Bar
              style={{ width: progressTip }}
              className={barClass}
              data-test={`${dataTestValue}-bar`}
              data-progress={progressTip}
            />
          </Meter>
        </ToolTip>
      </View>
    )
  }
}
