import React from 'react'
import * as R from 'ramda'

import { View, ExpansionPanel } from 'lib/components'
import ReportExpansionHeader from 'domains/reports/components/ReportExpansionHeader'
import ITransformedRESTCharacterizationReport, {
  ITransformedRESTServiceCharacterizationReport,
} from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import IRestCModel from 'domains/modeling/models/IRestCModel'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import IAccordionHeaderState from 'domains/characterizations/models/IAccordionHeaderState'
import RESTServiceCharacterizationReport from 'domains/characterizations/components/CharacterizationReport/RESTServiceCharacterizationReport'
import {
  isWholeServiceDisabled,
  isServiceReportable,
} from 'domains/modeling/utils/restCOR1p1utils'

interface IProps {
  report: ITransformedRESTCharacterizationReport
  specification: IRestCSpec
  headerStates: IAccordionHeaderState[]
  getHeaderState: (name: string) => any
  onToggleHeader: (name: string) => any
  model?: IRestCModel
}

export default function RESTCharacterizationReportServices(props: IProps) {
  const onRequestChange = (
    service: ITransformedRESTServiceCharacterizationReport,
  ) => () => {
    if (isServiceReportable(service)) {
      props.onToggleHeader(service.serviceName)
    }
  }

  const renderService = (
    service: ITransformedRESTServiceCharacterizationReport,
  ) => {
    const headerState = props.getHeaderState(service.serviceName)
    if (!headerState) {
      return null
    }

    const allDisabled = isWholeServiceDisabled(service)
    const isReportable = isServiceReportable(service)
    return (
      <ExpansionPanel
        key={service.serviceName}
        isOpen={headerState.open}
        onRequestChange={onRequestChange(service)}
        summary={
          <ReportExpansionHeader
            status={allDisabled ? 'GRAY' : service.status}
            name={service.serviceName}
            open={headerState.open}
            noIcon={!isReportable}
            circle={allDisabled}
            data-test={`service-report-header-${service.serviceName}`}
          />
        }
        details={
          <RESTServiceCharacterizationReport
            isOpen={headerState.open}
            report={props.report}
            service={service}
            specification={props.specification}
            model={props.model}
          />
        }
      />
    )
  }

  const { report } = props
  if (report.serviceCharacterizations.length < 1) {
    return null
  }

  return (
    <View my={3} data-test="characterization-report-services">
      {R.sort(
        R.ascend(R.prop('serviceName')),
        report.serviceCharacterizations,
      ).map(renderService)}
    </View>
  )
}
