import React from 'react'
import clsx from 'clsx'

interface IProps {
  selected: boolean
  onClick: () => void
  noHover?: boolean
}

export default function SearchBoxItemSelected(props: IProps) {
  const [hovered, setHovered] = React.useState(false)

  const onMouseOver = () => setHovered(true)
  const onMouseOut = () => setHovered(false)

  if (!props.selected && !hovered) {
    return null
  }

  const icon = props.noHover || hovered ? 'fas fa-x' : 'fas fa-check'

  return (
    <div
      className={clsx(
        'w-7 h-7 flex flex-col items-center justify-center absolute rounded-full',
        {
          'cursour-pointer': !props.noHover,
          'bg-success-20': props.noHover || !hovered,
          'bg-neutral': !props.noHover && hovered,
        },
      )}
      style={{
        top: '14px',
        right: '12px',
      }}
      onClick={props.onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      data-test="searchbox-selected-badge"
    >
      <i className={`${icon} text-white`} />
    </div>
  )
}
