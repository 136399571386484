import { SelectOptionProps } from 'lib/components/modern/Select/SelectOption'

export const SC_ENTERPRISE_RESOURCE_PLANNING = 'ENTERPRISE_RESOURCE_PLANNING'
export const SC_CUSTOMER_MANAGEMENT = 'CUSTOMER_MANAGEMENT'
export const SC_IDENTITY_AND_ACCESS_MANAGEMENT =
  'IDENTITY_AND_ACCESS_MANAGEMENT'
export const SC_STUDENT_INFORMATION_SYSTEM = 'STUDENT_INFORMATION_SYSTEM'
export const SC_LEARNING_MANAGEMENT_SYSTEM = 'LEARNING_MANAGEMENT_SYSTEM'
export const SC_LEARNING_PLATFORM = 'LEARNING_PLATFORM'
export const SC_COURSEWARE = 'COURSEWARE'
export const SC_COURSEWARE_LEARNING_CONTENT = 'COURSEWARE_LEARNING_CONTENT'
export const SC_COURSEWARE_LEARNING_CONTENT_TOPICS =
  'COURSEWARE_LEARNING_CONTENT_TOPICS'
export const SC_COURSEWARE_LEARNING_CONTENT_FORMAT =
  'COURSEWARE_LEARNING_CONTENT_FORMAT'
export const SC_COURSEWARE_LEARNING_TOOLS = 'COURSEWARE_LEARNING_TOOLS'
export const SC_COURSEWARE_LEARNING_TOOLS_ACCESSIBILITY =
  'COURSEWARE_LEARNING_TOOLS_ACCESSIBILITY'
export const SC_COURSEWARE_LEARNING_TOOLS_PERSONALISED_LEARNING =
  'COURSEWARE_LEARNING_TOOLS_PERSONALISED_LEARNING'
export const SC_COURSEWARE_LEARNING_TOOLS_ANALYTICS =
  'COURSEWARE_LEARNING_TOOLS_ANALYTICS'
export const SC_COURSEWARE_LEARNING_TOOLS_ASSESSMENT =
  'COURSEWARE_LEARNING_TOOLS_ASSESSMENT'
export const SC_COURSEWARE_LEARNING_TOOLS_ATTENDANCE =
  'COURSEWARE_LEARNING_TOOLS_ATTENDANCE'
export const SC_COURSEWARE_LEARNING_TOOLS_BADGES =
  'COURSEWARE_LEARNING_TOOLS_BADGES'
export const SC_COURSEWARE_LEARNING_TOOLS_COLLABORATION =
  'COURSEWARE_LEARNING_TOOLS_COLLABORATION'
export const SC_COURSEWARE_LEARNING_TOOLS_COMPETENCY_BASED_PLATFORM =
  'COURSEWARE_LEARNING_TOOLS_COMPETENCY_BASED_PLATFORM'
export const SC_COURSEWARE_LEARNING_TOOLS_EPORTFOLIO =
  'COURSEWARE_LEARNING_TOOLS_EPORTFOLIO'
export const SC_COURSEWARE_LEARNING_TOOLS_EBOOKS =
  'COURSEWARE_LEARNING_TOOLS_EBOOKS'
export const SC_COURSEWARE_LEARNING_TOOLS_LAB_MANAGEMENT =
  'COURSEWARE_LEARNING_TOOLS_LAB_MANAGEMENT'
export const SC_COURSEWARE_LEARNING_TOOLS_LECTURE_CAPTURE =
  'COURSEWARE_LEARNING_TOOLS_LECTURE_CAPTURE'
export const SC_COURSEWARE_LEARNING_TOOLS_LEARNING_OBJECT_REPOSITORIES =
  'COURSEWARE_LEARNING_TOOLS_LEARNING_OBJECT_REPOSITORIES'
export const SC_COURSEWARE_LEARNING_TOOLS_PLAGIARISM =
  'COURSEWARE_LEARNING_TOOLS_PLAGIARISM'
export const SC_COURSEWARE_LEARNING_TOOLS_PROCOTORING =
  'COURSEWARE_LEARNING_TOOLS_PROCOTORING'
export const SC_COURSEWARE_LEARNING_TOOLS_ROSTERING =
  'COURSEWARE_LEARNING_TOOLS_ROSTERING'
export const SC_COURSEWARE_LEARNING_TOOLS_TUTORING =
  'COURSEWARE_LEARNING_TOOLS_TUTORING'
export const SC_COURSEWARE_LEARNING_TOOLS_VIDEO =
  'COURSEWARE_LEARNING_TOOLS_VIDEO'
export const SC_SOLUTIONS_PROVIDER = 'SOLUTIONS_PROVIDER'

export type ProductSolutionCategories =
  | typeof SC_ENTERPRISE_RESOURCE_PLANNING
  | typeof SC_CUSTOMER_MANAGEMENT
  | typeof SC_IDENTITY_AND_ACCESS_MANAGEMENT
  | typeof SC_STUDENT_INFORMATION_SYSTEM
  | typeof SC_LEARNING_MANAGEMENT_SYSTEM
  | typeof SC_LEARNING_PLATFORM
  | typeof SC_COURSEWARE
  | typeof SC_COURSEWARE_LEARNING_CONTENT
  | typeof SC_COURSEWARE_LEARNING_CONTENT_TOPICS
  | typeof SC_COURSEWARE_LEARNING_CONTENT_FORMAT
  | typeof SC_COURSEWARE_LEARNING_TOOLS
  | typeof SC_COURSEWARE_LEARNING_TOOLS_ACCESSIBILITY
  | typeof SC_COURSEWARE_LEARNING_TOOLS_PERSONALISED_LEARNING
  | typeof SC_COURSEWARE_LEARNING_TOOLS_ANALYTICS
  | typeof SC_COURSEWARE_LEARNING_TOOLS_ASSESSMENT
  | typeof SC_COURSEWARE_LEARNING_TOOLS_ATTENDANCE
  | typeof SC_COURSEWARE_LEARNING_TOOLS_BADGES
  | typeof SC_COURSEWARE_LEARNING_TOOLS_COLLABORATION
  | typeof SC_COURSEWARE_LEARNING_TOOLS_COMPETENCY_BASED_PLATFORM
  | typeof SC_COURSEWARE_LEARNING_TOOLS_EPORTFOLIO
  | typeof SC_COURSEWARE_LEARNING_TOOLS_EBOOKS
  | typeof SC_COURSEWARE_LEARNING_TOOLS_LAB_MANAGEMENT
  | typeof SC_COURSEWARE_LEARNING_TOOLS_LECTURE_CAPTURE
  | typeof SC_COURSEWARE_LEARNING_TOOLS_LEARNING_OBJECT_REPOSITORIES
  | typeof SC_COURSEWARE_LEARNING_TOOLS_PLAGIARISM
  | typeof SC_COURSEWARE_LEARNING_TOOLS_PROCOTORING
  | typeof SC_COURSEWARE_LEARNING_TOOLS_ROSTERING
  | typeof SC_COURSEWARE_LEARNING_TOOLS_TUTORING
  | typeof SC_COURSEWARE_LEARNING_TOOLS_VIDEO
  | typeof SC_SOLUTIONS_PROVIDER

export const SOLUTION_CATEGORY_TO_DISPLAY_MAP: { [key: string]: string } = {
  [SC_ENTERPRISE_RESOURCE_PLANNING]: 'Enterprise Resource Planning (ERP)',
  [SC_CUSTOMER_MANAGEMENT]: 'Customer/Constitutent Management (CRM)',
  [SC_IDENTITY_AND_ACCESS_MANAGEMENT]: 'Identity and Access Management (IAM)',
  [SC_STUDENT_INFORMATION_SYSTEM]: 'Student Information System (SIS)',
  [SC_LEARNING_MANAGEMENT_SYSTEM]: 'Learning Management System (LMS)',
  [SC_LEARNING_PLATFORM]: 'Learning Platform',
  [SC_COURSEWARE]: 'Courseware',
  [SC_COURSEWARE_LEARNING_CONTENT]: 'Content',
  [SC_COURSEWARE_LEARNING_CONTENT_TOPICS]: 'Topics',
  [SC_COURSEWARE_LEARNING_CONTENT_FORMAT]: 'Format',
  [SC_COURSEWARE_LEARNING_TOOLS]: 'Tools',
  [SC_COURSEWARE_LEARNING_TOOLS_ACCESSIBILITY]: 'Accessibility',
  [SC_COURSEWARE_LEARNING_TOOLS_PERSONALISED_LEARNING]:
    'Adaptive/Personalized Learning',
  [SC_COURSEWARE_LEARNING_TOOLS_ANALYTICS]: 'Analytics',
  [SC_COURSEWARE_LEARNING_TOOLS_ASSESSMENT]: 'Assessment',
  [SC_COURSEWARE_LEARNING_TOOLS_ATTENDANCE]: 'Attendance',
  [SC_COURSEWARE_LEARNING_TOOLS_BADGES]: 'Digital Credentials &amp; Badges',
  [SC_COURSEWARE_LEARNING_TOOLS_COLLABORATION]: 'Collaboration',
  [SC_COURSEWARE_LEARNING_TOOLS_COMPETENCY_BASED_PLATFORM]: 'Competency-Based',
  [SC_COURSEWARE_LEARNING_TOOLS_EPORTFOLIO]: 'ePortfolio',
  [SC_COURSEWARE_LEARNING_TOOLS_EBOOKS]: 'eBooks',
  [SC_COURSEWARE_LEARNING_TOOLS_LAB_MANAGEMENT]: 'Lab Management',
  [SC_COURSEWARE_LEARNING_TOOLS_LECTURE_CAPTURE]: 'Lecture Capture',
  [SC_COURSEWARE_LEARNING_TOOLS_LEARNING_OBJECT_REPOSITORIES]:
    'Learning Object Repositories',
  [SC_COURSEWARE_LEARNING_TOOLS_PLAGIARISM]: 'Plagiarism',
  [SC_COURSEWARE_LEARNING_TOOLS_PROCOTORING]: 'Proctoring',
  [SC_COURSEWARE_LEARNING_TOOLS_ROSTERING]: 'Rostering',
  [SC_COURSEWARE_LEARNING_TOOLS_TUTORING]: 'Tutoring',
  [SC_COURSEWARE_LEARNING_TOOLS_VIDEO]: 'Video',
  [SC_SOLUTIONS_PROVIDER]: 'Solutions Provider',
}

export const solutionCategoryOptions: SelectOptionProps[] = [
  {
    label: 'Enterprise Software',
    value: 'ENT_SOFT',
    parent: true,
  },
  {
    label: SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_ENTERPRISE_RESOURCE_PLANNING],
    value: SC_ENTERPRISE_RESOURCE_PLANNING,
    parentId: 'ENT_SOFT',
  },
  {
    label: SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_CUSTOMER_MANAGEMENT],
    value: SC_CUSTOMER_MANAGEMENT,
    parentId: 'ENT_SOFT',
  },
  {
    label: SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_IDENTITY_AND_ACCESS_MANAGEMENT],
    value: SC_IDENTITY_AND_ACCESS_MANAGEMENT,
    parentId: 'ENT_SOFT',
  },
  {
    label: 'Educational Software',
    value: 'ED_SOFT',
    parent: true,
  },
  {
    label: SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_STUDENT_INFORMATION_SYSTEM],
    value: SC_STUDENT_INFORMATION_SYSTEM,
    parentId: 'ED_SOFT',
  },
  {
    label: SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_LEARNING_MANAGEMENT_SYSTEM],
    value: SC_LEARNING_MANAGEMENT_SYSTEM,
    parentId: 'ED_SOFT',
  },
  {
    label: SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_LEARNING_PLATFORM],
    value: SC_LEARNING_PLATFORM,
    parentId: 'ED_SOFT',
  },
  {
    label: SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_COURSEWARE],
    value: SC_COURSEWARE,
    parentId: 'ED_SOFT',
  },
  {
    label: SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_COURSEWARE_LEARNING_CONTENT],
    value: SC_COURSEWARE_LEARNING_CONTENT,
    parentId: 'ED_SOFT',
  },
  {
    label:
      SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_COURSEWARE_LEARNING_CONTENT_TOPICS],
    value: SC_COURSEWARE_LEARNING_CONTENT_TOPICS,
    parentId: 'ED_SOFT',
  },
  {
    label:
      SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_COURSEWARE_LEARNING_CONTENT_FORMAT],
    value: SC_COURSEWARE_LEARNING_CONTENT_FORMAT,
    parentId: 'ED_SOFT',
  },
  {
    label: SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_COURSEWARE_LEARNING_TOOLS],
    value: SC_COURSEWARE_LEARNING_TOOLS,
    parentId: 'ED_SOFT',
  },
  {
    label:
      SOLUTION_CATEGORY_TO_DISPLAY_MAP[
        SC_COURSEWARE_LEARNING_TOOLS_ACCESSIBILITY
      ],
    value: SC_COURSEWARE_LEARNING_TOOLS_ACCESSIBILITY,
    parentId: 'ED_SOFT',
  },
  {
    label:
      SOLUTION_CATEGORY_TO_DISPLAY_MAP[
        SC_COURSEWARE_LEARNING_TOOLS_PERSONALISED_LEARNING
      ],
    value: SC_COURSEWARE_LEARNING_TOOLS_PERSONALISED_LEARNING,
    parentId: 'ED_SOFT',
  },
  {
    label:
      SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_COURSEWARE_LEARNING_TOOLS_ANALYTICS],
    value: SC_COURSEWARE_LEARNING_TOOLS_ANALYTICS,
    parentId: 'ED_SOFT',
  },
  {
    label:
      SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_COURSEWARE_LEARNING_TOOLS_ASSESSMENT],
    value: SC_COURSEWARE_LEARNING_TOOLS_ASSESSMENT,
    parentId: 'ED_SOFT',
  },
  {
    label:
      SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_COURSEWARE_LEARNING_TOOLS_ATTENDANCE],
    value: SC_COURSEWARE_LEARNING_TOOLS_ATTENDANCE,
    parentId: 'ED_SOFT',
  },
  {
    label:
      SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_COURSEWARE_LEARNING_TOOLS_BADGES],
    value: SC_COURSEWARE_LEARNING_TOOLS_BADGES,
    parentId: 'ED_SOFT',
  },
  {
    label:
      SOLUTION_CATEGORY_TO_DISPLAY_MAP[
        SC_COURSEWARE_LEARNING_TOOLS_COLLABORATION
      ],
    value: SC_COURSEWARE_LEARNING_TOOLS_COLLABORATION,
    parentId: 'ED_SOFT',
  },
  {
    label:
      SOLUTION_CATEGORY_TO_DISPLAY_MAP[
        SC_COURSEWARE_LEARNING_TOOLS_COMPETENCY_BASED_PLATFORM
      ],
    value: SC_COURSEWARE_LEARNING_TOOLS_COMPETENCY_BASED_PLATFORM,
    parentId: 'ED_SOFT',
  },
  {
    label:
      SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_COURSEWARE_LEARNING_TOOLS_EPORTFOLIO],
    value: SC_COURSEWARE_LEARNING_TOOLS_EPORTFOLIO,
    parentId: 'ED_SOFT',
  },
  {
    label:
      SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_COURSEWARE_LEARNING_TOOLS_EBOOKS],
    value: SC_COURSEWARE_LEARNING_TOOLS_EBOOKS,
    parentId: 'ED_SOFT',
  },
  {
    label:
      SOLUTION_CATEGORY_TO_DISPLAY_MAP[
        SC_COURSEWARE_LEARNING_TOOLS_LAB_MANAGEMENT
      ],
    value: SC_COURSEWARE_LEARNING_TOOLS_LAB_MANAGEMENT,
    parentId: 'ED_SOFT',
  },
  {
    label:
      SOLUTION_CATEGORY_TO_DISPLAY_MAP[
        SC_COURSEWARE_LEARNING_TOOLS_LECTURE_CAPTURE
      ],
    value: SC_COURSEWARE_LEARNING_TOOLS_LECTURE_CAPTURE,
    parentId: 'ED_SOFT',
  },
  {
    label:
      SOLUTION_CATEGORY_TO_DISPLAY_MAP[
        SC_COURSEWARE_LEARNING_TOOLS_LEARNING_OBJECT_REPOSITORIES
      ],
    value: SC_COURSEWARE_LEARNING_TOOLS_LEARNING_OBJECT_REPOSITORIES,
    parentId: 'ED_SOFT',
  },
  {
    label:
      SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_COURSEWARE_LEARNING_TOOLS_PLAGIARISM],
    value: SC_COURSEWARE_LEARNING_TOOLS_PLAGIARISM,
    parentId: 'ED_SOFT',
  },
  {
    label:
      SOLUTION_CATEGORY_TO_DISPLAY_MAP[
        SC_COURSEWARE_LEARNING_TOOLS_PROCOTORING
      ],
    value: SC_COURSEWARE_LEARNING_TOOLS_PROCOTORING,
    parentId: 'ED_SOFT',
  },
  {
    label:
      SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_COURSEWARE_LEARNING_TOOLS_ROSTERING],
    value: SC_COURSEWARE_LEARNING_TOOLS_ROSTERING,
    parentId: 'ED_SOFT',
  },
  {
    label:
      SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_COURSEWARE_LEARNING_TOOLS_TUTORING],
    value: SC_COURSEWARE_LEARNING_TOOLS_TUTORING,
    parentId: 'ED_SOFT',
  },
  {
    label: SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_COURSEWARE_LEARNING_TOOLS_VIDEO],
    value: SC_COURSEWARE_LEARNING_TOOLS_VIDEO,
    parentId: 'ED_SOFT',
  },
  {
    label: SOLUTION_CATEGORY_TO_DISPLAY_MAP[SC_SOLUTIONS_PROVIDER],
    value: SC_SOLUTIONS_PROVIDER,
    parentId: 'ED_SOFT',
  },
]
