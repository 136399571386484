import { getRecord } from 'lib/records/workflows/getRecord'
import { REST_C_MODEL_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/restCModelCharacterizations'
import IRestCModelCharacterization from 'domains/characterizations/models/IRestCModelCharacterization'
import { REST_C_MODEL_CHARACTERIZATION_REPORTS_RECORD } from 'lib/records/modules/restCModelCharacterizationReports'

export const getFullRESTCCharacterization = async (modelId: number) => {
  const { success, data } = await getRecord(
    REST_C_MODEL_CHARACTERIZATIONS_RECORD,
    modelId,
  )

  if (!success) {
    return
  }
  const restCModelCharacterization: IRestCModelCharacterization = data

  await getRecord(
    REST_C_MODEL_CHARACTERIZATION_REPORTS_RECORD,
    restCModelCharacterization.characterizationLink,
  )
}
