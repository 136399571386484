import * as R from 'ramda'
import { createSelector } from 'reselect'
import { records } from '@ims/1edtech-frontend-common'

import {
  csvModelCompletionSelector,
  specFileSelector,
  modelFileSelector,
} from 'domains/modeling/selectors/csvCModelToSpec'
import { CSV_C_MODELS_RECORD } from 'lib/records/modules/csvCModels'
import * as fileConstants from 'domains/modeling/constants/files'
import { RootState } from 'lib/store/rootReducer'
import ICSVSpecificationFile from 'domains/specifications/models/ICSVSpecificationFile'
import { ICSVConsumerFile } from 'domains/modeling/models/ICSVConsumerModel'
import { productFormatModelIdSelector } from 'domains/modeling/selectors/productModelSelectors'
import { SPECIFICATIONS_RECORD } from 'lib/records/modules/specifications'

export const modelCompletionSelector = (
  getProductId: records.entitiesSelectors.getIdType = 'id',
  getFormat: records.entitiesSelectors.getIdType = 'format',
) =>
  csvModelCompletionSelector(
    productFormatModelIdSelector(getProductId, getFormat),
  )

export interface IFileSpecDetails {
  fileName: string
  specFile: ICSVSpecificationFile
  modelFile: ICSVConsumerFile
}
export const fileSpecDetailsSelector = (
  getModelId: records.entitiesSelectors.getIdType = 'modelId',
  getFormat: records.entitiesSelectors.getIdType = 'format',
  getFileName: records.entitiesSelectors.getIdType = 'filename',
) =>
  createSelector<
    RootState,
    any,
    string,
    ICSVSpecificationFile | null,
    any,
    IFileSpecDetails
  >(
    records.entitiesSelectors.idSelector(getFileName),
    specFileSelector(
      records.entitiesSelectors.entityByIdSelector(
        SPECIFICATIONS_RECORD,
        getFormat,
      ),
      getFileName,
    ),
    modelFileSelector(
      records.entitiesSelectors.entityByIdSelector(
        CSV_C_MODELS_RECORD,
        getModelId,
      ),
      getFileName,
    ),
    (fileName, specFile, modelFile) => ({
      fileName,
      specFile: specFile as ICSVSpecificationFile,
      modelFile,
    }),
  )

export const getFileStatus = (file: ICSVConsumerFile) => {
  const touched = R.propOr(false, 'touched', file)
  const inProgress = R.propOr(false, 'inProgress', file)

  if (!touched && !inProgress) {
    return fileConstants.NOT_STARTED_STATUS
  }

  if (!touched && inProgress) {
    return fileConstants.IN_PROGRESS_STATUS
  }

  if (inProgress && touched) {
    return fileConstants.UPDATED_STATUS
  }

  return fileConstants.COMPLETE_STATUS
}

export const isFileInStatus = R.curry(
  (status: string, file: ICSVConsumerFile) => getFileStatus(file) === status,
)
export const isFileInStatuses = R.curry(
  (statuses: string[], file: ICSVConsumerFile) =>
    R.contains(getFileStatus(file), statuses),
)

export const fileModelingStatusSelector = (
  getModelId: records.entitiesSelectors.getIdType = 'modelId',
  getFormat: records.entitiesSelectors.getIdType = 'format',
  getFileName: records.entitiesSelectors.getIdType = 'filename',
) =>
  createSelector<RootState, any, IFileSpecDetails, fileConstants.FileStatuses>(
    fileSpecDetailsSelector(getModelId, getFormat, getFileName),
    (fileDetails) => getFileStatus(R.propOr({}, 'modelFile', fileDetails)),
  )
