import React from 'react'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'

import { Button, ModalWrapper } from 'lib/components'
import NonTableList from 'lib/components/NonTableList'
import { TRUSTED_APPS_SHARED_WITH_ORGS_RECORD } from 'lib/records/modules/trustedAppsSharedWithOrgs'
import TrustedAppsSharedWithOrgRow from 'domains/trustedApps/components/TrustedAppsSharedWithOrgRow'
import { SelectOptionProps } from 'lib/components/modern/Select/SelectOption'
import { getRecords } from 'lib/records/workflows/getRecords'
import IOrg from 'domains/orgs/models/IOrg'
import Select from 'lib/components/modern/Select/Select'
import FormLabel from 'lib/components/modern/FormLabel'
import { postRequest } from 'lib/api/api'
import { showToast } from 'lib/utils/toast'
import { TRUSTED_APPS_SHAREABLE_ORGS_RECORD } from 'lib/records/modules/trustedAppsShareableOrgs'
import { deleteRecord } from 'lib/records/workflows/deleteRecord'

type Props = {
  isOpen: boolean
  onRequestClose: () => void
}

export default function ShareTrustedAppsDashboardModal(props: Props) {
  const [selectedDistrictId, setSelectedDistrictId] = React.useState<any>(null)
  const [refreshList, setRefreshList] = React.useState('')
  const [pending, setPending] = React.useState(false)
  const [initialDistrictsOptions, setInitialDistrictsOptions] = React.useState<
    SelectOptionProps[]
  >([])
  const [districtsOptions, setDistrictsOptions] = React.useState<
    SelectOptionProps[]
  >([])

  const getDistrictOptions = React.useCallback(
    async (search?: string) => {
      if (search && search.length < 1) return initialDistrictsOptions

      const response = await getRecords({
        record: TRUSTED_APPS_SHAREABLE_ORGS_RECORD,
        params: !!search
          ? {
              filter: `name=~${search} AND orgType=district`,
              sort: 'name:ascending',
            }
          : {
              filter: `orgType=district`,
              sort: 'name:ascending',
            },
        pageSize: 500,
        force: true,
      })
      if (response?.success) {
        const options = R.pathOr([], ['data', 'orgs'], response).map(
          (o: IOrg) => ({
            label: o.name,
            value: o.id,
            org: o,
          }),
        ) as SelectOptionProps[]
        setDistrictsOptions(options)
        return options
      }
      return []
    },
    [initialDistrictsOptions],
  )

  React.useEffect(() => {
    const getInitialOrgList = async () => {
      const opts = await getDistrictOptions()
      setInitialDistrictsOptions(opts)
    }
    if (props.isOpen) getInitialOrgList()
  }, [props.isOpen]) // eslint-disable-line

  const onShareWithDistrict = async () => {
    setPending(true)
    const org = districtsOptions.find(
      (d) => d.value === selectedDistrictId,
    ) as any
    const { success } = await postRequest(
      'trustedapps/organizations/trustedapps/share',
      {
        viewerOrgId: selectedDistrictId,
        viewerOrgName: org.org.name,
      },
    )
    if (success) {
      const newInitial = await getDistrictOptions()
      setInitialDistrictsOptions(newInitial)
      setSelectedDistrictId(null)
      showToast(
        'start',
        `Successfully shared your TrustEd Apps Dashboard with ${org.org.name}`,
      )
      setRefreshList(uuid())
    } else
      showToast(
        'error',
        'Failed to share your TrustEd Apps Dashboard. Please try again.',
      )
    setPending(false)
  }

  const onDeleteShare = async (district: any) => {
    const deleted = await deleteRecord(
      TRUSTED_APPS_SHARED_WITH_ORGS_RECORD,
      district.id,
    )
    if (deleted) {
      const newInitial = await getDistrictOptions()
      setInitialDistrictsOptions(newInitial)
      showToast(
        'info',
        `Stopped sharing your TrustEd Apps Dashboard with ${district.viewerOrgName}`,
      )
    } else {
      showToast(
        'error',
        `Failed to stop sharing your TrustEd Apps Dashboard with ${district.viewerOrgName}. Please try again.`,
      )
    }
  }

  return (
    <ModalWrapper
      isOpen={props.isOpen}
      title="Your Dashboard Sharing"
      actions={[
        {
          text: 'Done',
          variant: 'start',
          onClick: props.onRequestClose,
        },
      ]}
    >
      <div className="space-y-4 w-full" style={{ minHeight: '26rem' }}>
        <div className="flex flex-row items-end space-x-2 w-full">
          <div className="space-y-1 flex flex-col flex-1">
            <FormLabel htmlFor="district">Share My Dashboard with</FormLabel>
            <Select
              name="district"
              selected={selectedDistrictId}
              onChange={setSelectedDistrictId}
              placeholder="Institution name"
              options={districtsOptions}
              initialOptions={initialDistrictsOptions}
              onSearchChange={getDistrictOptions}
            />
          </div>

          <Button
            variant={selectedDistrictId ? 'primary' : 'neutral'}
            onClick={onShareWithDistrict}
            pending={pending}
            disabled={!selectedDistrictId}
            mb="0.375rem"
            data-test="submit-share-btn"
          >
            Share
          </Button>
        </div>

        <div className="flex flex-col flex-1">
          <NonTableList
            data-test="share-dashboard-list"
            record={TRUSTED_APPS_SHARED_WITH_ORGS_RECORD}
            RowComponent={TrustedAppsSharedWithOrgRow}
            rowData={{ onDeleteShare }}
            blankSlateText="You have not shared your TrustEd Apps Dashboard with anyone"
            refreshList={refreshList}
            params={{ sort: 'viewerOrgName:ascending' }}
            noHorizontalScroll
            noDivider
            pageSize={50}
            usePageBottomListener
          />
        </div>
      </div>
    </ModalWrapper>
  )
}
