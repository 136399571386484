import * as R from 'ramda'

import ICSVConsumerModel from 'domains/modeling/models/ICSVConsumerModel'
import { ICSVConsumerFile } from 'domains/modeling/models/ICSVConsumerModel'
import { ICSVConsumerFileColumn } from 'domains/modeling/models/ICSVConsumerModel'

export const findModelFile = (
  model: ICSVConsumerModel,
  fileLabel: string,
): ICSVConsumerFile | undefined => {
  return R.find(R.propEq('name', fileLabel), model.files)
}

export const findModelFileColumn = (
  model: ICSVConsumerModel,
  fileLabel: string,
  headerName: string,
): ICSVConsumerFileColumn | undefined => {
  const file = findModelFile(model, fileLabel)
  if (file) {
    return R.find(R.propEq('header', headerName), file.columns)
  }
}
