import React from 'react'
import * as R from 'ramda'

import ITransformedRESTCharacterizationReport from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import { GRAY_REPORT_STATUS } from 'domains/reports/models/IReportStatuses'
import { AnySpecFormatType } from 'domains/formats/constants/formats'
import PrintReportRestServiceHeader from 'domains/reports/components/PrintReportRestServiceHeader'
import PrintRESTPCharacterizationReportService from 'domains/characterizations/components/CharacterizationReport/PrintRESTPCharacterizationReportService'

interface IProps {
  format: AnySpecFormatType
  report: ITransformedRESTCharacterizationReport
  specification: IRestCSpec
}

export default function PrintRESTPCharacterizationReportServices(
  props: IProps,
) {
  if (props.report.serviceCharacterizations.length < 1) {
    return null
  }

  return (
    <div className="mb-3" data-test="characterization-report-services">
      {R.sort(
        R.ascend(R.prop('serviceName')),
        props.report.serviceCharacterizations,
      ).map((service) => (
        <div key={`service-${service.serviceName}`}>
          <PrintReportRestServiceHeader
            status={service.status}
            name={service.serviceName}
            circle={service.status === GRAY_REPORT_STATUS}
            data-test={`service-report-header-${service.serviceName}`}
          />
          <PrintRESTPCharacterizationReportService
            format={props.format}
            report={props.report}
            service={service}
            specification={props.specification}
          />
        </div>
      ))}
    </div>
  )
}
