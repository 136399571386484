import React from 'react'
import { useHistory } from 'react-router-dom'

import { View, Button } from 'lib/components'
import ICommonCartridgeCharacterizationReport from 'domains/commonCartridge/models/ICommonCartridgeCharacterizationReport'
import CommonCartridgeCharacterizationReportStandardsList from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CommonCartridgeCharacterizationReportStandardsList'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import { getCharacterizationRoute } from 'domains/characterizations/navigation/routes'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'

interface IProps {
  report: ICommonCartridgeCharacterizationReport
  characterization: ICharacterization
}

export default function CCCharcterizationReportStandardsPreview(props: IProps) {
  const history = useHistory()

  const onSeeAll = () => {
    const route = getCharacterizationRoute(
      props.characterization.id,
      props.characterization.format,
      ['standards'],
    )
    history.push(route)
  }

  return (
    <View variant="paper" flexible="column" mt={2}>
      <View flexible="row-space-between">
        <WidgetHeader title="Available Standards" />
        <Button variant="ccReportBlue500" onClick={onSeeAll}>
          See All
        </Button>
      </View>

      <View mt={3}>
        <CommonCartridgeCharacterizationReportStandardsList
          isPreview={true}
          report={props.report}
          characterization={props.characterization}
        />
      </View>
    </View>
  )
}
