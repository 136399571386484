import React, { PureComponent } from 'react'
import Dialog from 'domains/application/modals/Dialog'
import IProduct from 'domains/products/models/IProduct'
import { getCharacterizedWarningMessage } from 'domains/modeling/utils/characterizedWarningMessage'

interface IProps {
  isOpen: boolean
  product: IProduct
  args?: any
  onConfirm: (args?: any) => void
  closeModal: (args?: any) => void
}

export default class EditModelWarning extends PureComponent<IProps> {
  onConfirm = () => {
    const { args, onConfirm } = this.props
    onConfirm(args)
  }

  onDeny = () => {
    const { args, closeModal } = this.props
    closeModal(args)
  }

  render() {
    return (
      <Dialog
        isOpen={this.props.isOpen}
        icon="fas fa-pen-square"
        message={getCharacterizedWarningMessage(this.props.product.name)}
        confirmText="Yes, Edit"
        onConfirm={this.onConfirm}
        onDeny={this.onDeny}
      />
    )
  }
}
