import React from 'react'
import clsx from 'clsx'

type Props = {
  'data-test'?: string
  className?: any
  overrideTextSize?: boolean
  children: any
}

export default function InputLabel(props: Props) {
  return (
    <p
      className={clsx(
        {
          'text-lg': !props.overrideTextSize,
        },
        'font-bold text-text mb-2',
        props.className,
      )}
      data-test={props['data-test']}
    >
      {props.children}
    </p>
  )
}
