import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

export const REST_C_MODELS_RECORD = 'restCModels'

const restCModels: records.modules.IModule = {
  record: REST_C_MODELS_RECORD,
  schema: new normalizr.schema.Entity(
    REST_C_MODELS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(),
  ),
  api: {
    create: ({ productId }) => `products/${productId}/restmodels`,
    get: (id, { productId }) => `products/${productId}/restmodels/${id}`,
    getList: () => '',
    update: (id, { productId }) => `products/${productId}/restmodels/${id}`,
    delete: (id, { productId }) => `products/${productId}/restmodels/${id}`,
  },
}

export default restCModels
