import { records } from '@ims/1edtech-frontend-common'
import { createSelector } from 'reselect'
import { RootState } from 'lib/store/rootReducer'
import {
  ANY_FORMAT_TO_SPEC_FORMAT_MAP,
  AnySpecFormatType,
  AnyFormatType,
} from 'domains/formats/constants/formats'

export const getFormatByProp = (
  getFormat: records.entitiesSelectors.getIdType = 'format',
) =>
  createSelector<RootState, any, any, AnySpecFormatType>(
    records.entitiesSelectors.idSelector(getFormat),
    (format) => ANY_FORMAT_TO_SPEC_FORMAT_MAP[(format || '') as AnyFormatType],
  )
