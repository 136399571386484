import React from 'react'
import { useSelector } from 'react-redux'

import { myNotificationPreferenceSelector } from 'domains/notifications/selectors/notificationSelectors'
import { Icon, View, Text } from 'lib/components'
import NotificationCenter from 'domains/notifications/components/NotificationCenter/NotificationCenter'
import { applicationPropSelector } from 'domains/application/selectors/application'
import trackNotificationAnalytics from 'domains/notifications/utils/trackNotificationAnalytics'

const size = 32

export default function NotificationBell() {
  const bell = React.useRef<HTMLDivElement>(null)
  const notificationCenter = React.useRef<any>()
  const lockedOut = useSelector(applicationPropSelector('lockedOut'))
  const myNotificationPref = useSelector(myNotificationPreferenceSelector)
  const notificationCount = (useSelector(
    applicationPropSelector('unreadNotificationCount'),
  ) || 0) as number

  const [count, setCount] = React.useState(0)
  const [showAnimation, setShowAnimation] = React.useState(false)

  React.useEffect(() => {
    if (notificationCount !== count) {
      setShowAnimation(false)
      setTimeout(() => {
        setCount(notificationCount)
        setShowAnimation(true)
      }, 250)
    }
  }, [notificationCount]) // eslint-disable-line

  if (lockedOut || myNotificationPref === 'NONE') {
    return null
  }

  const onOpen = (e: any) => {
    if (notificationCenter.current && bell.current) {
      if (
        bell.current.contains(e.target) &&
        !notificationCenter.current!.isOpen
      ) {
        trackNotificationAnalytics('user_opened_notification_center')
        notificationCenter.current!.show()
      }
    }
  }

  let content = (
    <View width={size} height={32} flexible="column-center" cursor="pointer">
      <Icon className="fas fa-bell" fontSize={20} />
    </View>
  )

  if (count > 0) {
    content = (
      <View
        width={size}
        height={size}
        borderRadius="50%"
        bg="success"
        flexible="column-center"
        animation={showAnimation ? 'bounceIn' : undefined}
        cursor="pointer"
        data-test="notification-bell"
      >
        <Text color="white" fontWeight={700} fontSize={16}>
          {count < 10 ? count : '9+'}
        </Text>
      </View>
    )
  }

  return (
    <View ml={3} flexible="column-center" onClick={onOpen} ref={bell}>
      {content}

      <NotificationCenter ref={notificationCenter} />
    </View>
  )
}
