import React from 'react'
import clsx from 'clsx'

import { Image } from 'lib/components'

interface IProps {
  name: string
  logo?: string
  size?: number
  circle?: boolean
}

export default function ProductImageCell(props: IProps) {
  const { name, logo, size = 50, circle = false } = props
  if (!logo) {
    return null
  }

  return (
    <div
      className={clsx('bg-white flex flex-col items-center justify-center', {
        'rounded-full border border-white': circle,
      })}
      style={{ width: size, maxWidth: size, height: size, maxHeight: size }}
    >
      <Image
        minWidth={size}
        width="auto"
        height="auto"
        maxWidth={size}
        maxHeight={size}
        src={logo}
        alt={`Product logo for ${name}`}
        borderRadius={circle ? size / 2 : 0}
      />
    </div>
  )
}
