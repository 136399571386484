import { HTMLAttributes } from 'react'
import styled from 'styled-components'
import * as SS from 'styled-system'
import * as CSS from 'csstype'

import colors from 'lib/styles/colors'
import { flexibleStyle } from 'lib/components/View'
import { inputDefaultStyles } from 'lib/components/Input'

export const variantStyle = SS.variant({ key: 'textAreaVariantStyles' })
export const textAreaVariantStyles = {
  transparent: {
    fontWeight: '700',
    backgroundColor: 'transparent',
    border: 'solid 0px transparent',
  },
  bold: {
    fontWeight: '700',
    backgroundColor: '#f1f0ec',
    border: 'solid 0px transparent',
    padding: '16px',
  },
}

export interface IHtmlTextAreaElementProps // eslint-disable-line
  extends HTMLAttributes<HTMLTextAreaElement> {}
interface ITextAreaProps
  extends IHtmlTextAreaElementProps,
    SS.AlignContentProps,
    SS.AlignItemsProps,
    SS.AlignSelfProps,
    SS.BackgroundColorProps,
    SS.BorderColorProps,
    SS.BorderProps,
    SS.BorderRadiusProps,
    SS.BordersProps,
    SS.BottomProps,
    SS.ColorProps,
    SS.DisplayProps,
    SS.FlexBasisProps,
    SS.FlexDirectionProps,
    SS.FlexProps,
    SS.FlexWrapProps,
    SS.FontSizeProps,
    SS.FontStyleProps,
    SS.FontWeightProps,
    SS.GridAutoFlowProps,
    SS.GridColumnGapProps,
    SS.GridRowGapProps,
    SS.GridTemplateColumnsProps,
    SS.GridTemplateRowsProps,
    SS.HeightProps,
    SS.JustifyContentProps,
    SS.JustifySelfProps,
    SS.LeftProps,
    SS.LineHeightProps,
    SS.MaxHeightProps,
    SS.MaxWidthProps,
    SS.MinHeightProps,
    SS.MinWidthProps,
    SS.OrderProps,
    SS.OverflowProps,
    SS.PositionProps,
    SS.RightProps,
    SS.SpaceProps,
    SS.TextAlignProps,
    SS.TopProps,
    SS.VerticalAlignProps,
    SS.WidthProps,
    SS.ZIndexProps {
  color?: CSS.Property.Color
  flexible?: string
  variant?: string
  wordBreak?: string
  userSelect?: string
  hover?: string
}

// prettier-disabled
const TextArea = styled.textarea<ITextAreaProps>`
  padding: 5px 8px;
  color: ${colors.text};
  border: 1px solid ${colors.placeholder};
  background-color: #fff;
  font-size: 14px;
  border-radius: 3px;
  box-sizing: border-box;

  ${variantStyle}
  ${inputDefaultStyles}
  ${flexibleStyle}

  ${SS.alignContent}
  ${SS.alignItems}
  ${SS.alignSelf}
  ${SS.bottom}
  ${SS.color}
  ${SS.display}
  ${SS.flexBasis}
  ${SS.flexDirection}
  ${SS.flexWrap}
  ${SS.flex}
  ${SS.fontSize}
  ${SS.fontWeight}
  ${SS.height}
  ${SS.justifyContent}
  ${SS.justifySelf}
  ${SS.left}
  ${SS.lineHeight}
  ${SS.maxHeight}
  ${SS.maxWidth}
  ${SS.minHeight}
  ${SS.minWidth}
  ${SS.order}
  ${SS.position}
  ${SS.right}
  ${SS.space}
  ${SS.top}
  ${SS.width}
  ${SS.zIndex}
`

TextArea.displayName = 'TextArea'
export default TextArea
