import store from 'lib/store'
import { deleteRecord } from 'lib/records/workflows/deleteRecord'
import {
  AnyFormatType,
  ANY_FORMAT_TO_SPEC_FORMAT_MAP,
  ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK,
  ONER_V1P1_CSV_RESOURCES_EXPORT_BULK,
  ONER_V1P1_CSV_ROSTERING_EXPORT_BULK,
  ONER_V1P2_CSV_ROSTERING_EXPORT_BULK,
  ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL,
  ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL,
} from 'domains/formats/constants/formats'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import { OR_CSV_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/orCSVCharacterizations'
import { getRecords } from 'lib/records/workflows/getRecords'
import { OR_REST_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/orRESTCharacterizations'
import { COMMON_CARTRIDGE_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/commonCartridgeCharacterizations'
import {
  oneRosterCharacterizationFormatsFilterSelector,
  commonCartridgeCharacterizationFormatsFilterSelector,
} from 'domains/application/selectors/application'

export const deleteCharacterization = async (
  id: string | number,
  format: AnyFormatType,
) => {
  const state = store.getState()
  const specFormat = ANY_FORMAT_TO_SPEC_FORMAT_MAP[format]
  const orgId = myOrganizationIdSelector(state, {})
  let record = ''
  let filter = ''
  switch (specFormat) {
    case ONER_V1P1_CSV_ROSTERING_EXPORT_BULK:
    case ONER_V1P2_CSV_ROSTERING_EXPORT_BULK:
    case ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK:
    case ONER_V1P1_CSV_RESOURCES_EXPORT_BULK:
      record = OR_CSV_CHARACTERIZATIONS_RECORD
      filter = oneRosterCharacterizationFormatsFilterSelector(state)
      break
    case ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL:
    case ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL:
    case ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL:
    case ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL:
    case ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL:
    case ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL:
      record = OR_REST_CHARACTERIZATIONS_RECORD
      filter = oneRosterCharacterizationFormatsFilterSelector(state)
      break
    default:
      record = COMMON_CARTRIDGE_CHARACTERIZATIONS_RECORD
      filter = commonCartridgeCharacterizationFormatsFilterSelector(state)
      break
  }

  await deleteRecord(record, id, {}, orgId)

  // List for oneroster is always ONEROSTER_CHARACTERIZATIONS_RECORD...
  let listRecord = record
  if (
    [
      OR_CSV_CHARACTERIZATIONS_RECORD,
      OR_REST_CHARACTERIZATIONS_RECORD,
    ].includes(record)
  ) {
    listRecord = OR_CSV_CHARACTERIZATIONS_RECORD
  }

  await getRecords({
    params: { filter },
    record: listRecord,
    parentRecordId: orgId,
    force: true,
    replaceItems: true,
  })
}
