import React from 'react'
import * as R from 'ramda'
import { useSelector } from 'react-redux'
import { utils, records } from '@ims/1edtech-frontend-common'

import { Screen, View, Spinner } from 'lib/components'
import BaseCommonCartridgeCharacterizationsBreadCrumbs from 'domains/characterizations/components/BaseCommonCartridgeCharacterizationsBreadCrumbs'
import useFullCommonCartridgeCharacterization from 'domains/commonCartridge/hooks/useFullCommonCartridgeCharacterization'
import GenericError from 'domains/application/components/GenericError'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import ICommonCartridgeCharacterizationReport from 'domains/commonCartridge/models/ICommonCartridgeCharacterizationReport'
import { getCharacterizationRoute } from 'domains/characterizations/navigation/routes'
import { COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_ITEMS_RECORD } from 'lib/records/modules/commonCartridgeCharacterizationReportItems'
import { getNavigationParam } from 'lib/utils/navigation'
import CCCharacterizationReportLOMDropdowns from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/CCCharacterizationReportLOMDropdowns'
import { getRecord } from 'lib/records/workflows/getRecord'
import { ICommonCartridgeItem } from 'domains/commonCartridge/models/ICommonCartridgeItem'
import CommonCartridgeCharacterizationReportTOCList from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CommonCartridgeCharacterizationReportTOCList'
import CCCharacterizationReportNavigationHeader from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportNavigationHeader'
import DebugReportView from 'domains/reports/components/DebugReportView'
import { CC_ITEM_TITLE_NOT_SPECIFIED } from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportItemRow'
import CCCharacterizationReportResourceRow from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportResourceRow'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import CCCharacterizationReportLegend from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportLegend'
import CCCharacterizationReportDetailsHeader from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportDetailsHeader'
import { CCReportTypeIcon } from 'domains/commonCartridge/constants/commonCartridge'
import { ICommonCartridgeResource } from 'domains/commonCartridge/models/ICommonCartridgeResource'
import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'

export default function CommonCartridgeCharacterizationReportItemDetailsScreen(
  props: any,
) {
  const [fetchPending, setFetchPending] = React.useState(true)
  const [showMissingMD, setShowMissingMD] = React.useState(false)
  const [
    characterizationData,
    reportData,
    pending,
  ] = useFullCommonCartridgeCharacterization(props)

  const itemId = utils.convertToInt(getNavigationParam(props, 'itemId', 0))

  React.useEffect(() => {
    const fetch = async () => {
      await getRecord(
        COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_ITEMS_RECORD,
        itemId,
      )
      await utils.wait.waitFor(250)
      setFetchPending(false)
    }

    fetch()
  }, [itemId])

  const item = useSelector(
    records.entitiesSelectors.entityByIdSelector(
      COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_ITEMS_RECORD,
      itemId,
    ),
  ) as ICommonCartridgeItem

  const oneOrMoreMissing =
    !utils.hasValue(characterizationData) || !utils.hasValue(reportData)
  if (pending || fetchPending || oneOrMoreMissing) {
    if (!pending && !fetchPending && oneOrMoreMissing) {
      return <GenericError />
    }

    return (
      <View variant="screen">
        <Spinner centered={true} />
      </View>
    )
  }

  const characterization = characterizationData as ICharacterization
  const characterizationName = R.pathOr('', ['name'], characterization)
  const report = reportData as ICommonCartridgeCharacterizationReport

  const itemName = R.pathOr(CC_ITEM_TITLE_NOT_SPECIFIED, ['title'], item)
  const lom = R.pathOr({}, ['lom'], item)
  const resource = R.pathOr({}, ['resource'], item) as ICommonCartridgeResource
  const disclosures = R.pathOr([], ['disclosures'], item) as string[]

  return (
    <ShowMissingMetadataContext.Provider
      value={{
        showMissingMetadata: showMissingMD,
        setShowMissingMetadata: setShowMissingMD,
        disclosures,
      }}
    >
      <Screen>
        <DocumentTitle title={itemName} />
        <BaseCommonCartridgeCharacterizationsBreadCrumbs
          crumbs={[
            {
              name: utils.string.ellipsize(characterizationName, 30),
              route: getCharacterizationRoute(
                characterization.id,
                characterization.format,
              ),
            },
            {
              name: 'Table of Contents',
              route: getCharacterizationRoute(
                characterization.id,
                characterization.format,
                ['items'],
              ),
            },
            { name: utils.string.ellipsize(itemName, 30) },
          ]}
        />

        <View variant="paper">
          <CCCharacterizationReportLegend />

          <CCCharacterizationReportNavigationHeader
            title={itemName}
            characterization={characterization}
            tocUrl="items"
          />

          <CCCharacterizationReportDetailsHeader
            icon={CCReportTypeIcon.Item}
            titleBg="ccReportItemTitleBG"
            title="Item Details"
            bg="ccReportItemBG"
            details={[
              { data: item, label: 'identifier', path: ['identifier'] },
            ]}
          />

          <View mt={2} />
          <CommonCartridgeCharacterizationReportTOCList
            parentItemId={itemId}
            report={report}
            characterization={characterization}
            noBlankslate={true}
          />

          <CCCharacterizationReportLOMDropdowns
            type="Item"
            parentId={itemId}
            lom={lom}
            noBlankslate={true}
          />

          {utils.hasValue(resource) && (
            <View mt={2}>
              <CCCharacterizationReportResourceRow
                characterization={characterization}
                resource={resource!}
              />
            </View>
          )}
        </View>

        <DebugReportView rawReport={item || {}} title="Item JSON" />
      </Screen>
    </ShowMissingMetadataContext.Provider>
  )
}
