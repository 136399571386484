import React from 'react'
import clsx from 'clsx'
import * as R from 'ramda'

import { utils } from '@ims/1edtech-frontend-common'

export interface ICCCharacterizationReportDetailsHeaderDetailsProps {
  data: any
  indentLevel?: number
  isTitle?: boolean
  label: string
  path: any[]
}

export default function CCCharacterizationReportDetailsHeaderDetails(
  props: ICCCharacterizationReportDetailsHeaderDetailsProps,
) {
  const value = R.pathOr('', props.path, props.data)
  if (!utils.hasValue(value)) {
    return null
  }

  return (
    <div
      className={clsx('my-2', {
        'ml-4': props.indentLevel === 1,
        'ml-8': props.indentLevel === 2,
      })}
    >
      <p
        className={clsx('whitespace-pre-line text-text text-base', {
          'break-all': value.includes('//'),
        })}
      >
        <span className="font-bold">{props.label}: </span>
        {!props.isTitle && value}
      </p>
    </div>
  )
}
