import React, { PureComponent, ChangeEvent } from 'react'
import memoize from 'fast-memoize'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { utils, records } from '@ims/1edtech-frontend-common'

import {
  Button,
  Icon,
  List,
  Text,
  TextArea,
  View,
  WizardFooter,
} from 'lib/components'
import withFullProduct, {
  IWithFullProductProps,
} from 'domains/products/components/withFullProduct'
import BaseProductModelingBreadCrumbs from 'domains/products/components/BaseProductModelingBreadCrumbs'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import CsvCColumnDescriptionCell from 'domains/modeling/components/ProductModeling/CsvCColumnDescriptionCell'
import CsvCColumnNotesCell from 'domains/modeling/components/ProductModeling/CsvCColumnNotesCell'
import {
  modelFileSelector,
  specFileSelector,
  findSpecFileColumn,
} from 'domains/modeling/selectors/csvCModelToSpec'
import { CSV_C_MODELS_RECORD } from 'lib/records/modules/csvCModels'
import CsvCColumnRequiredCell from 'domains/modeling/components/ProductModeling/CsvCColumnRequiredCell'
import { getProductModelingRoute } from 'domains/products/navigation/routes'
import { trackCSVCModelingAnalytics } from 'domains/modeling/utils/trackModelingAnalytics'
import { updateCsvCFileModel } from 'domains/modeling/workflows/updateCsvCFileModel'
import EditModelFileColumnModal from 'domains/modeling/modals/AddEditModelFileColumnModal/EditModelFileColumnModal'
import AddModelFileColumnModal from 'domains/modeling/modals/AddEditModelFileColumnModal/AddModelFileColumnModal'
import EditModelFileColumnNoteModal from 'domains/modeling/modals/EditModelFileColumnNoteModal'
import UnsavedModelWarning from 'domains/modeling/components/UnsavedModelWarning'
import {
  ICSVConsumerFile,
  ICSVConsumerFileColumn,
} from 'domains/modeling/models/ICSVConsumerModel'
import ICSVSpecificationFile from 'domains/specifications/models/ICSVSpecificationFile'
import { RootState } from 'lib/store/rootReducer'
import { RouteComponentProps } from 'react-router'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import { SPECIFICATIONS_RECORD } from 'lib/records/modules/specifications'
import { getFormatByProp } from 'domains/products/selectors/formats'
import GenericError from 'domains/application/components/GenericError'
import CsvCColumnFormatCell from 'domains/modeling/components/ProductModeling/CsvCColumnFormatCell'

const defaultColumnProps = {
  noHeaderCenter: true,
  noCenter: true,
}

interface IPropsFromState {
  file: ICSVConsumerFile | null
  specFile: ICSVSpecificationFile | null
}

const modelSelector = records.entitiesSelectors.entityByIdSelector(
  CSV_C_MODELS_RECORD,
  'modelId',
)

const stateMap = createStructuredSelector<RootState, any, IPropsFromState>({
  file: modelFileSelector(modelSelector, 'fileName'),
  specFile: specFileSelector(
    records.entitiesSelectors.entityByIdSelector(
      SPECIFICATIONS_RECORD,
      getFormatByProp(),
    ),
    'fileName',
  ),
})

interface IActions {
  saveModel: (
    modelId: any,
    fileName: string,
    updatedFile: ICSVConsumerFile,
  ) => void
}
const actionMap = (): IActions => ({
  saveModel: (modelId, fileName, updatedFile) =>
    updateCsvCFileModel(modelId, fileName, updatedFile),
})

interface IProps
  extends IPropsFromState,
    IActions,
    IWithFullProductProps,
    RouteComponentProps {
  fileName: string
}

interface IState {
  pending: boolean
  open: any
  originalFile: ICSVConsumerFile
  file: ICSVConsumerFile
  fileNotes: string
  cancelDialogOpen: boolean
  cancelRoute: string
  addModelFileColumnOpen: boolean
  editModelFileColumnOpen: boolean
  editModelFileColumnProps: any
  editModelFileColumnNoteOpen: boolean
  editModelFileColumnNoteProps: any
}

export class CsvCFileModelingScreen extends PureComponent<IProps> {
  state: IState = {
    pending: false,
    open: {},
    originalFile: this.props.file!,
    file: this.props.file!,
    fileNotes: R.pathOr('', ['file', 'notes'], this.props),
    cancelDialogOpen: false,
    cancelRoute: '',
    addModelFileColumnOpen: false,
    editModelFileColumnOpen: false,
    editModelFileColumnProps: {},
    editModelFileColumnNoteOpen: false,
    editModelFileColumnNoteProps: {},
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.file !== this.props.file) {
      this.setState({ file: this.props.file })
    }
  }

  onGoToModeling = () =>
    this.props.history.push(
      getProductModelingRoute(this.props.id, this.props.csvConsumerModel!.id),
    )

  onSave = async () => {
    this.setState({ pending: true })
    const { fileName, modelId } = this.props
    const { file, fileNotes } = this.state
    await this.props.saveModel(modelId, fileName, {
      ...file,
      notes: fileNotes,
      touched: true,
      inProgress: true,
    })
    trackCSVCModelingAnalytics('saved_model_file', { fileName })
    this.onGoToModeling()
  }

  onCancelConfirmed = async () => {
    const { fileName, modelId, saveModel } = this.props
    await saveModel(modelId, fileName, this.state.originalFile)
    trackCSVCModelingAnalytics('cancelled_model_file_changes', { fileName })
    if (utils.hasValue(this.state.cancelRoute)) {
      this.props.history.push(this.state.cancelRoute)
    } else {
      this.props.history.goBack()
    }
  }

  onDenyCancelWarning = () => this.setState({ cancelDialogOpen: false })

  onShowCancelWarning = () => this.setState({ cancelDialogOpen: true })

  onCloseAddModelFileNoteColumn = (wasSaved = false, wasCleared = false) =>
    this.setState((state: IState) => ({
      addModelFileColumnOpen: false,
      open: {
        ...state.open,
        [state.editModelFileColumnProps.header]: wasSaved && !wasCleared,
      },
    }))

  onCloseEditModelFileColumn = (wasSaved = false, wasCleared = false) =>
    this.setState((state: IState) => ({
      editModelFileColumnOpen: false,
      open: {
        ...state.open,
        [state.editModelFileColumnProps.header]: wasSaved && !wasCleared,
      },
    }))

  onCloseEditModelFileNoteColumn = (wasSaved = false, wasCleared = false) =>
    this.setState((state: IState) => ({
      editModelFileColumnNoteOpen: false,
      open: {
        ...state.open,
        [state.editModelFileColumnNoteProps.header]: wasSaved && !wasCleared,
      },
    }))

  onNavigateAway = (e: React.MouseEvent<any>, route: string) => {
    e.preventDefault()
    this.setState({ cancelRoute: route })
    this.onShowCancelWarning()
  }

  onUpdateFileNotes = (event: ChangeEvent<HTMLTextAreaElement>) =>
    this.setState({ fileNotes: event.target.value })

  onToggleNotes = (header: string) =>
    this.setState(R.over(R.lensPath(['open', header]), R.not))

  onAddNotes = (column: ICSVConsumerFileColumn) => {
    if (utils.hasValue(column.notes)) {
      this.onToggleNotes(column.header)
    } else {
      const specColumn = this.getSpecColumn(column)
      if (specColumn) {
        this.setState({
          editModelFileColumnNoteOpen: true,
          editModelFileColumnNoteProps: {
            header: column.header,
            metadata: specColumn.metadata,
          },
        })
      } else {
        this.setState({
          editModelFileColumnOpen: true,
          editModelFileColumnProps: { header: column.header },
        })
      }
    }
  }

  onOpenNotes = (column: ICSVConsumerFileColumn) => () => {
    const specColumn = this.getSpecColumn(column)
    if (specColumn) {
      this.setState({
        editModelFileColumnNoteOpen: true,
        editModelFileColumnNoteProps: {
          header: column.header,
          metadata: specColumn.metadata,
        },
      })
    } else {
      this.setState({
        editModelFileColumnOpen: true,
        editModelFileColumnProps: { header: column.header },
      })
    }
  }

  onAddMetaData = () =>
    this.setState({
      addModelFileColumnOpen: true,
    })

  getSpecColumn = (column: ICSVConsumerFileColumn) =>
    findSpecFileColumn(this.props.specFile!, R.prop('header', column))

  getColumnsAsListRecord = memoize((file) => R.propOr([], 'columns', file))

  getRowProps = memoize((column) => {
    const defaultProps = { 'data-test': `${column.header}-row` }
    if (R.prop('metadata', column)) {
      const specColumn = this.getSpecColumn(column)
      if (specColumn) {
        return { bg: 'builtInMetaDataBG', ...defaultProps }
      }

      return { bg: 'customMetaDataBG', ...defaultProps }
    }
    return defaultProps
  })

  getColumns = memoize((open) => [
    {
      title: 'Column',
      maxWidth: [150, 150, 200, 350],
      accessor: 'header',
      cellProps: {
        mr: 3,
        wordBreak: 'break-all',
        overflow: 'auto',
        minWidth: 150,
      },
      ...defaultColumnProps,
    },
    {
      title: 'Required',
      maxWidth: 155,
      renderCell: (props: any) => (
        <CsvCColumnRequiredCell
          {...props}
          format={this.props.csvConsumerModel!.format}
          modelId={this.props.modelId}
          fileName={this.props.fileName}
        />
      ),
      ...defaultColumnProps,
    },
    {
      title: 'Format',
      CellComponent: CsvCColumnFormatCell,
      ...defaultColumnProps,
    },
    {
      title: 'Multiplicity',
      accessor: 'multiplicity',
      ...defaultColumnProps,
    },
    {
      title: 'Description',
      maxWidth: 300,
      padding: '16px 8px 16px 0',
      CellComponent: CsvCColumnDescriptionCell,
      cellProps: {
        maxWidth: 300,
        fileName: this.props.fileName,
        format: this.props.csvConsumerModel!.format,
      },
      ...defaultColumnProps,
    },
    {
      title: 'Notes',
      CellComponent: ({ ...rest }) => (
        <CsvCColumnNotesCell
          format={this.props.csvConsumerModel!.format}
          onClick={this.onAddNotes}
          isOpen={R.propOr(false, rest.header, open)}
          modelId={this.props.modelId}
          fileName={this.props.fileName}
          {...rest}
        />
      ),
    },
    {
      title: null,
      renderCell: (item: ICSVConsumerFileColumn) => {
        if (
          R.propOr(false, 'metadata', item) ||
          utils.hasValue(R.prop('notes', item))
        ) {
          return (
            <Icon
              cursor="pointer"
              className="fas fa-edit"
              onClick={this.onOpenNotes(item)}
              data-test={`${item.header}-edit-column`}
            />
          )
        }
        return null
      },
    },
  ])

  keyExtractor = (column: ICSVConsumerFileColumn, isSubrow: boolean) =>
    `${column.header}${isSubrow ? '-notes' : ''}`

  renderSubRow = (item: ICSVConsumerFileColumn) =>
    this.state.open[item.header] ? (
      <Text fontWeight={700} p={3} data-test={`${item.header}-notes-row`}>
        {item.notes || 'No Notes'}
      </Text>
    ) : (
      false
    )

  render() {
    const { id, fileName, product } = this.props
    const { fileNotes, file, open, pending } = this.state

    if (!file) {
      return <GenericError />
    }

    return (
      <View variant="screen">
        <DocumentTitle
          title={`Product File Modeling - ${this.props.product.name} - ${this.props.fileName}`}
        />
        <BaseProductModelingBreadCrumbs
          productId={id}
          modelId={this.props.csvConsumerModel!.id}
          crumbs={[{ name: fileName }]}
          beforeNavigate={this.onNavigateAway}
        />
        <View variant="paper" overflowY="hidden">
          <WidgetHeader
            title={`Bulk CSV Consumer v1.1 Modeling Setup: Configure ${fileName} for ${product.name}`}
            dataTest="product-file-modeling-header"
          />
          <View mt={4} />
          <List
            noWrapper={true}
            nonRecordItems={this.getColumnsAsListRecord(file)}
            columns={this.getColumns(open)}
            dataTest="product-file-modeling-list"
            getRowProps={this.getRowProps}
            renderSubRow={this.renderSubRow}
            noSort={true}
            keyExtractor={this.keyExtractor}
          />

          <Button
            onClick={this.onAddMetaData}
            my={4}
            variant="tertiary"
            data-test="add-metadata"
          >
            <View flexible="row-v-center">
              <Icon className="fas fa-plus-square" color="white" mr={2} />
              <Text color="white">Add Metadata</Text>
            </View>
          </Button>

          <WidgetHeader
            title={`Notes on your ${fileName}`}
            dataTest="product-file-modeling-notes-header"
          />
          <TextArea
            mt={3}
            width="100%"
            placeholder={`Notes on ${fileName}`}
            name="notes"
            onChange={this.onUpdateFileNotes}
            value={fileNotes}
            variant="bold"
            aria-label={`Notes for ${fileName}`}
          />
        </View>
        <WizardFooter
          buttons={[
            {
              title: 'Save',
              onClick: this.onSave,
              variant: 'tertiary',
              dataTest: 'save-file-btn',
            },
            {
              title: 'Cancel',
              onClick: this.onShowCancelWarning,
              variant: 'neutral',
              dataTest: 'cancel-btn',
            },
          ]}
          pending={pending}
        />
        <UnsavedModelWarning
          isOpen={this.state.cancelDialogOpen}
          onConfirm={this.onCancelConfirmed}
          onDeny={this.onDenyCancelWarning}
        />
        <AddModelFileColumnModal
          isOpen={this.state.addModelFileColumnOpen}
          modelId={this.props.modelId!}
          fileName={this.props.fileName}
          closeModal={this.onCloseAddModelFileNoteColumn}
        />
        <EditModelFileColumnModal
          isOpen={this.state.editModelFileColumnOpen}
          modelId={this.props.modelId}
          fileName={this.props.fileName}
          {...this.state.editModelFileColumnProps}
          closeModal={this.onCloseEditModelFileColumn}
        />
        <EditModelFileColumnNoteModal
          isOpen={this.state.editModelFileColumnNoteOpen}
          modelId={this.props.modelId}
          fileName={this.props.fileName}
          {...this.state.editModelFileColumnNoteProps}
          closeModal={this.onCloseEditModelFileNoteColumn}
        />
      </View>
    )
  }
}

export default withFullProduct(
  connect(stateMap, actionMap)(CsvCFileModelingScreen),
)
