import React from 'react'

import SmallWidget from 'domains/dashboard/components/SmallWidget'

interface IProps {
  commonCartridgeCharacterizationsCount: number
  fadeDelay: number
}
export default function CommonCartridgeCharacterizationsWidget(props: IProps) {
  return (
    <SmallWidget
      fadeDelay={props.fadeDelay}
      icon="fas fa-layer-group"
      iconColor="#009de3"
      title={props.commonCartridgeCharacterizationsCount}
      subtitle="Total Cartridges"
      dataTest="common-cartridge-characterizations-count-widget"
    />
  )
}
