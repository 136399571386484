import * as R from 'ramda'
import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

export const REST_C_MODEL_CHARACTERIZATIONS_RECORD =
  'restCModelCharacterizations'

const csvConsumerModelCharacterizations: records.modules.IModule = {
  record: REST_C_MODEL_CHARACTERIZATIONS_RECORD,
  noResponseDataKey: true,
  schema: new normalizr.schema.Entity(
    REST_C_MODEL_CHARACTERIZATIONS_RECORD,
    {},
    { idAttribute: (item) => R.pathOr<string>('0', ['product', 'id'], item) },
  ),
  api: {
    create: () => 'characterizations/rest/models',
    get: (id) => `characterizations/rest/models/${id}`,
    getList: (page) => `characterizations/rest/models?page=${page}`,
    update: (id) => `characterizations/rest/models/${id}`,
    delete: (id) => `speccharacterizations/${id}`,
  },
}

export default csvConsumerModelCharacterizations
