import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { utils, records } from '@ims/1edtech-frontend-common'

import { Button } from 'lib/components'
import { specFileColumnSelector } from 'domains/modeling/selectors/csvCModelToSpec'
import ICSVSpecificationFileColumn from 'domains/specifications/models/ICSVSpecificationFileColumn'
import { RootState } from 'lib/store/rootReducer'
import { RouteComponentProps } from 'react-router'
import { ICSVConsumerFileColumn } from 'domains/modeling/models/ICSVConsumerModel'
import { AnySpecFormatType } from 'domains/formats/constants/formats'
import { SPECIFICATIONS_RECORD } from 'lib/records/modules/specifications'
import { getFormatByProp } from 'domains/products/selectors/formats'

interface IPropsFromState {
  specColumn: ICSVSpecificationFileColumn | null
}
const stateMap = createStructuredSelector<RootState, any, IPropsFromState>({
  specColumn: specFileColumnSelector(
    records.entitiesSelectors.entityByIdSelector(
      SPECIFICATIONS_RECORD,
      getFormatByProp(),
    ),
    'fileName',
    'header',
  ),
})

interface IProps extends ICSVConsumerFileColumn, RouteComponentProps {
  format: AnySpecFormatType
  onClick: (column: ICSVConsumerFileColumn) => void
  isOpen: boolean
}

export class CsvCColumnNotesCell extends PureComponent<
  IProps & IPropsFromState
> {
  onViewNote = () => this.props.onClick(this.props)

  getButtonVariant = () => {
    const { isOpen, notes } = this.props
    const hasNotes = utils.hasValue(notes)
    if (isOpen) {
      return hasNotes ? 'info' : 'tertiary'
    }
    if (hasNotes) {
      return 'start'
    }
    return 'tertiary'
  }

  getButtonText = () => {
    const { isOpen, notes } = this.props
    const hasNotes = utils.hasValue(notes)
    if (isOpen) {
      return hasNotes ? 'close note' : 'add a note'
    }
    if (hasNotes) {
      return 'view note'
    }
    return 'add a note'
  }

  render() {
    const { header } = this.props
    return (
      <div>
        <Button
          variant={this.getButtonVariant()}
          onClick={this.onViewNote}
          data-test={`${header}-view-edit-notes`}
        >
          {this.getButtonText()}
        </Button>
      </div>
    )
  }
}

export default connect(stateMap)(CsvCColumnNotesCell)
