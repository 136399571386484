import React from 'react'
import { useSelector } from 'react-redux'

import { View } from 'lib/components'
import BaseBreadCrumbs from 'domains/application/components/BaseBreadCrumbs'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import OrgUsersList from 'domains/users/components/OrgUsersList'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import { RootState } from 'lib/store/rootReducer'

export default function UsersScreen() {
  const orgId = useSelector((s: RootState) => myOrganizationIdSelector(s, {}))
  return (
    <View variant="screen">
      <DocumentTitle title="Users" />
      <BaseBreadCrumbs crumbs={[{ name: 'Users' }]} />
      <OrgUsersList orgId={orgId} />
    </View>
  )
}
