import React from 'react'
import { useSelector } from 'react-redux'
import { utils, records } from '@ims/1edtech-frontend-common'

import { View, Text, Span, LargeButton, Switch } from 'lib/components'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import getComparisonReportStatus from 'domains/comparisons/utils/getComparisonReportStatus'
import ComparisonStatusIcon from 'domains/comparisons/components/ComparisonStatusIcon'
import { STATUS_TO_DISPLAY_MAP } from 'domains/reports/models/IReportStatuses'
import IComparison from 'domains/comparisons/models/IComparison'
import { updateComparisonSharing } from 'domains/comparisons/workflows/updateComparisonSharing'
import { isOneRosterCSVFormat } from 'domains/formats/utils/isFormatOfType'
import { getCSVComparisonDownloadLink } from 'lib/records/modules/comparisons'
import DownloadReport from 'domains/reports/components/DownloadReport'
import {
  isMyOrgFreemiumSelector,
  myOrganizationPropSelector,
} from 'domains/authentication/selectors/organizations'
import { RootState } from 'lib/store/rootReducer'
import useModalState from 'lib/hooks/useModalState'
import Dialog from 'domains/application/modals/Dialog'

interface IProps {
  comparison: IComparison
  isSupplier: boolean
  height?: number
}

export default function ComparisonReportActionsWidget(props: IProps) {
  const isFreemium = useSelector((state: RootState) =>
    isMyOrgFreemiumSelector(state, props),
  )
  const referrerId = useSelector((state: RootState) =>
    myOrganizationPropSelector('referrerId')(state, props),
  )
  const isNonReferral =
    isFreemium &&
    referrerId !== records.modules.getRefId(props.comparison, 'supplierId', -1)

  const [startDownload, setStartDownload] = React.useState(false)

  const [downloadIMSOnlyOpen, openDownloadIMSOnly, closeDownloadIMSOnly] =
    useModalState()

  const isThisOneRosterCSVFormat = isOneRosterCSVFormat(props.comparison.format)

  const onDownload = () => {
    if (isNonReferral) {
      openDownloadIMSOnly()
      return
    }

    if (isThisOneRosterCSVFormat) {
      setStartDownload(true)
    }
  }

  const onDownloadComplete = () => {
    setStartDownload(false)
  }

  const onToggleShareComparison = () =>
    updateComparisonSharing(
      props.comparison.id,
      props.comparison.format,
      !props.comparison.shared,
    )

  const { comparison, isSupplier } = props
  const reportStatus = getComparisonReportStatus(comparison)
  return (
    <DetailsTopWidget
      title="Comparison Status"
      dataTest="comparison-actions-widget"
      height={props.height || 472}
      rightContent={
        isSupplier ? null : (
          <View flexible="column-center">
            <Switch
              on={comparison.shared}
              onChange={onToggleShareComparison}
              onIconName="fas fa-eye"
              offIconName="fas fa-eye-slash"
              dataTest="share-comparison-switch"
            />
            <Text fontWeight={700} pt={1}>
              Share
            </Text>
          </View>
        )
      }
    >
      <View
        display="grid"
        gridTemplateRows={isThisOneRosterCSVFormat ? '1fr 1fr' : '1fr'}
        height="100%"
      >
        <View flexible="column-center" width="100%">
          <ComparisonStatusIcon status={reportStatus} size={100} />
          <Text
            textAlign="center"
            variant="subtitle"
            fontWeight={700}
            mt={2}
            data-test="comparison-report-status"
          >
            {STATUS_TO_DISPLAY_MAP[reportStatus]}
          </Text>
          <Text textAlign="center">
            <Span fontWeight={700}>Report Date:</Span>{' '}
            {utils.date.formatForDisplay(comparison.comparisonRunDate)}
          </Text>
        </View>

        {isThisOneRosterCSVFormat && false && (
          <View flexible="column-v-end">
            <LargeButton
              variant={
                isThisOneRosterCSVFormat && !isNonReferral
                  ? 'complete'
                  : 'neutral-dark-text'
              }
              color={
                isThisOneRosterCSVFormat && !isNonReferral ? 'white' : 'text'
              }
              title="Download Report"
              icon="fas fa-download"
              data-test="comparison-action-btn-Download Report"
              onClick={onDownload}
              pending={startDownload}
              disabled={!isThisOneRosterCSVFormat}
            />
          </View>
        )}
      </View>

      <DownloadReport
        startDownload={startDownload}
        url={getCSVComparisonDownloadLink(props.comparison.id)}
        onDownloadComplete={onDownloadComplete}
      />

      <Dialog
        isOpen={downloadIMSOnlyOpen}
        icon="fas fa-ban"
        title="Download only available to IMS members"
        message={
          <Text textAlign="center">
            To download the report and take full advantage of IMS member
            benefits{' '}
            <a
              href="https://www.imsglobal.org/imsmembership.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Span color="tertiary" textAlign="center">
                join IMS
              </Span>
            </a>
          </Text>
        }
        confirmText="OK"
        onConfirm={closeDownloadIMSOnly}
      />
    </DetailsTopWidget>
  )
}
