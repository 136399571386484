import React from 'react'
import * as R from 'ramda'
import { useSelector } from 'react-redux'

import IUser from 'domains/users/models/IUser'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import { View, Text, Icon } from 'lib/components'
import * as userIcons from 'domains/users/constants/users'
import { meSelector } from 'domains/authentication/selectors/me'
import UserRolesCell from 'domains/users/components/UserRolesCell'

interface IUserDetailRowProps {
  label: string
  value: any
}

function UserDetailRow(props: IUserDetailRowProps) {
  return (
    <View flexible="row-space-between" mb={3}>
      <View flex={1} data-test={`${props.label}-label`}>
        <Text fontWeight={700} fontSize={16}>
          {props.label}
        </Text>
      </View>
      <View flex={1} data-test={`${props.label}-value`}>
        {R.is(String, props.value) && <Text>{props.value}</Text>}
        {!R.is(String, props.value) && props.value}
      </View>
    </View>
  )
}

interface IProps {
  user: IUser
  height: number
}

export default function UserDetailsWidget(props: IProps) {
  const me = useSelector(meSelector)
  return (
    <DetailsTopWidget
      title="Details"
      dataTest="details-widget"
      height={props.height}
    >
      <View flexible="column-center" my={4}>
        <Icon className="fas fa-user-circle" color="complete" fontSize={100} />
      </View>
      <UserDetailRow label="First Name" value={props.user.firstName} />
      <UserDetailRow label="Last Name" value={props.user.lastName} />
      <UserDetailRow label="Job Title" value={props.user.jobTitle} />
      <UserDetailRow label="Email" value={props.user.email} />
      <UserDetailRow label="Roles" value={<UserRolesCell {...props.user} />} />
      {me!.ims && ( // eslint-disable-line
        <UserDetailRow
          label="Primary Contact"
          value={
            props.user.primaryContact ? (
              <Icon
                className={userIcons.PRIMARY_CONTACT_ICON}
                color={userIcons.PRIMARY_CONTACT_ICON_COLOR}
                fontSize={20}
                data-primary-contact="1"
              />
            ) : (
              <Icon
                className={userIcons.NON_PRIMARY_CONTACT_ICON}
                color={userIcons.NON_PRIMARY_CONTACT_ICON_COLOR}
                fontSize={20}
                data-primary-contact="0"
              />
            )
          }
        />
      )}
    </DetailsTopWidget>
  )
}
