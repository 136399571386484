import React from 'react'
import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'

import { Screen, View, Spinner, SearchBox } from 'lib/components'
import BaseCommonCartridgeCharacterizationsBreadCrumbs from 'domains/characterizations/components/BaseCommonCartridgeCharacterizationsBreadCrumbs'
import useFullCommonCartridgeCharacterization from 'domains/commonCartridge/hooks/useFullCommonCartridgeCharacterization'
import GenericError from 'domains/application/components/GenericError'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import ICommonCartridgeCharacterizationReport from 'domains/commonCartridge/models/ICommonCartridgeCharacterizationReport'
import { getCharacterizationRoute } from 'domains/characterizations/navigation/routes'
import CommonCartridgeCharacterizationReportResourcesList from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CommonCartridgeCharacterizationReportResourcesList'
import trackCommonCartridgeAnalytics from 'domains/commonCartridge/utils/trackCommonCartridgeAnalytics'

export default function CommonCartridgeCharacterizationReportResourcesScreen(
  props: any,
) {
  const [
    characterizationData,
    reportData,
    pending,
  ] = useFullCommonCartridgeCharacterization(props)
  const [query, setQuery] = React.useState('')

  if (
    pending ||
    !utils.hasValue(characterizationData) ||
    !utils.hasValue(reportData)
  ) {
    if (
      !pending &&
      (!utils.hasValue(characterizationData) || !utils.hasValue(reportData))
    ) {
      return <GenericError />
    }
    return (
      <Screen>
        <BaseCommonCartridgeCharacterizationsBreadCrumbs
          crumbs={[{ name: 'Resources' }]}
        />

        <View variant="paper">
          <WidgetHeader title="Resources" />
          <Spinner centered={true} />
        </View>
      </Screen>
    )
  }

  const characterization = characterizationData as ICharacterization
  const characterizationName = R.pathOr('', ['name'], characterization)
  const report = reportData as ICommonCartridgeCharacterizationReport

  return (
    <Screen>
      <BaseCommonCartridgeCharacterizationsBreadCrumbs
        crumbs={[
          {
            name: utils.string.ellipsize(characterizationName, 30),
            route: getCharacterizationRoute(
              characterization.id,
              characterization.format,
            ),
          },
          { name: 'Resources' },
        ]}
      />

      <View variant="paper">
        <View flexible="row-space-between-wrap">
          <WidgetHeader title="Resources" />

          <SearchBox
            name={`cc-resources-search`}
            trackEvent={trackCommonCartridgeAnalytics}
            trackEventAction={'cc-resources-search'}
            onChange={setQuery}
            placeholder="Search..."
          />
        </View>

        <View mt={3} />
        <CommonCartridgeCharacterizationReportResourcesList
          report={report}
          characterization={characterization}
          query={query}
          searchTemplate={'index=~@@'}
        />
      </View>
    </Screen>
  )
}
