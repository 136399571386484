import React from 'react'
import * as R from 'ramda'

import { utils } from '@ims/1edtech-frontend-common'
import { View, Text, Span } from 'lib/components'
import CCCharacterizationReportLOMDetail from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMDetail'
import {
  ICommonCartridgeLOMClassificationTaxonPath,
  ICommonCartridgeLOMClassificationTaxon,
} from 'domains/commonCartridge/models/lom/ICommonCartridgeLOMClassification'
import { ICommonCartridgeLanguageText } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeLanguageText'
import CCCharacterizationReportLOMLanguageText from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMLanguageText'
import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'

interface ITaxonProps {
  path: string
  title: string
  data: ICommonCartridgeLOMClassificationTaxon
}

function CCCharacterizationReportLOMTaxon(props: ITaxonProps) {
  const { showMissingMetadata } = React.useContext(ShowMissingMetadataContext)
  const taxonId = R.pathOr('', ['id'], props.data) as string
  const taxonEntries = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['entry'],
    props.data,
  ) as ICommonCartridgeLanguageText[]

  return (
    <View mt={1}>
      <Text fontWeight={700}>{props.title}: </Text>
      <View ml={3}>
        {utils.hasValue(taxonId) && (
          <Text data-test={`${props.path}.id`}>
            <Span fontWeight={700}>id: </Span>
            {taxonId}
          </Text>
        )}

        <View>
          {taxonEntries.map((entry, i) => (
            <View key={`${props.path}.entry.${i}`} mt={1}>
              <Text fontWeight={700}>entry [{i + 1}]: </Text>
              <View ml={3}>
                <CCCharacterizationReportLOMLanguageText
                  path={
                    !utils.hasValue(entry)
                      ? `${props.path}.entry`
                      : `${props.path}.entry.${i}`
                  }
                  data={entry}
                />
              </View>
            </View>
          ))}
        </View>
      </View>
    </View>
  )
}

interface IProps {
  path: string
  title?: string
  data?: ICommonCartridgeLOMClassificationTaxonPath
}

export default function CCCharacterizationReportLOMTaxonPath(props: IProps) {
  const { showMissingMetadata } = React.useContext(ShowMissingMetadataContext)
  if (!showMissingMetadata && !utils.hasValue(props.data)) {
    return null
  }

  const sources = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['source'],
    props.data,
  ) as ICommonCartridgeLanguageText[]

  const taxons = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['taxon'],
    props.data,
  ) as ICommonCartridgeLOMClassificationTaxon[]

  const inner = (
    <View>
      {sources.map((source, i) => (
        <View key={`${props.path}.source.${i}`}>
          <Text fontWeight={700}>source [{i + 1}]: </Text>
          <View ml={3}>
            <CCCharacterizationReportLOMLanguageText
              path={
                !utils.hasValue(source)
                  ? `${props.path}.source`
                  : `${props.path}.source.${i}`
              }
              data={source}
            />
          </View>
        </View>
      ))}
      {utils.hasValue(taxons) &&
        taxons.map((taxon, i) => (
          <CCCharacterizationReportLOMTaxon
            key={`${props.path}.taxon.${i}`}
            title={`taxon [${i + 1}]`}
            path={`${props.path}.taxon.${i}`}
            data={taxon}
          />
        ))}
    </View>
  )

  if (utils.hasValue(props.title)) {
    return (
      <CCCharacterizationReportLOMDetail
        path={props.path}
        title={props.title!}
        value={inner}
      />
    )
  }

  return inner
}
