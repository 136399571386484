import React, { PureComponent } from 'react'

import { View } from 'lib/components'
import {
  displaySpecVersion,
  getProductFormatByName,
} from 'domains/products/utils/products'
import {
  FORMAT_TO_DISPLAY_FORMAT_BASE_NAME,
  AnySpecFormatType,
  OR_FORMAT_TO_SPEC_SUBTYPE,
} from 'domains/formats/constants/formats'
import IProduct from 'domains/products/models/IProduct'
import IProductFormat from 'domains/products/models/IProductFormat'
import SearchMenuLineItem from 'lib/components/SearchMenuLineItem'

interface IProps extends IProduct {
  format: AnySpecFormatType
  noInverse?: boolean
}

export default class CopyModelSearchRow extends PureComponent<IProps> {
  render() {
    const format = getProductFormatByName(
      this.props.format,
      !this.props.noInverse,
    )(this.props) as IProductFormat
    return (
      <View
        flexible="row-space-between"
        p={3}
        hover="clickable-bg"
        data-test={`searched-product-${this.props.id}`}
      >
        <SearchMenuLineItem
          options={{ value: this.props.name, width: [172, 300] }}
        />
        <SearchMenuLineItem
          options={{
            value:
              OR_FORMAT_TO_SPEC_SUBTYPE[format.format as AnySpecFormatType],
            width: 80,
          }}
        />
        <SearchMenuLineItem
          options={{
            value: displaySpecVersion(format.specificationVersion),
            width: 32,
          }}
        />
        <SearchMenuLineItem
          options={{
            value: FORMAT_TO_DISPLAY_FORMAT_BASE_NAME[format.format],
            width: 48,
          }}
        />
      </View>
    )
  }
}
