import trackCharacterizationAnalytics from 'domains/characterizations/utils/trackCharacterizationAnalytics'
import { updateRecord } from 'lib/records/workflows/updateRecord'
import { ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL } from 'domains/formats/constants/formats'
import { REST_C_MODEL_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/restCModelCharacterizations'

export const createRESTCModelCharacterization = async (
  modelId: number | string,
) => {
  trackCharacterizationAnalytics('submitted_model_characterization', {
    format: ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
  })
  const { data, success } = await updateRecord(
    REST_C_MODEL_CHARACTERIZATIONS_RECORD,
    modelId,
  )
  return success ? data : success
}
