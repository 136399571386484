import { useHistory } from 'react-router-dom'
import * as R from 'ramda'

export interface ICCCrumb {
  id: number
  type: 'Item' | 'Resource' | 'Standard' | 'Metadata'
  title: string
}

export default function useCCCharacterizationReportCrumbs(): [ICCCrumb[]] {
  const history = useHistory()
  const crumbs = R.pathOr(
    [],
    ['location', 'state', 'crumbs'],
    history,
  ) as ICCCrumb[]
  return [crumbs]
}
