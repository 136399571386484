import React from 'react'
import { utils } from '@ims/1edtech-frontend-common'

import { View, Text, Icon } from 'lib/components'
import {
  ReportStatus,
  RED_REPORT_STATUS,
  AMBER_REPORT_STATUS,
} from 'domains/reports/models/IReportStatuses'
import { ICommonCartridgeCharacterizationReportValidationError } from 'domains/commonCartridge/models/ICommonCartridgeCharacterizationReport'

interface IProps {
  status: ReportStatus
  item: ICommonCartridgeCharacterizationReportValidationError
  'data-test': string
}

const STATUS_TO_TITLE_MAP: { [key: string]: string } = {
  [RED_REPORT_STATUS]: 'ERROR',
  [AMBER_REPORT_STATUS]: 'WARNING',
}
const STATUS_TO_BG_MAP: { [key: string]: string } = {
  [RED_REPORT_STATUS]: 'errorLight',
  [AMBER_REPORT_STATUS]: 'reportAmberLight',
}
const STATUS_TO_COLOR_MAP: { [key: string]: string } = {
  [RED_REPORT_STATUS]: 'error',
  [AMBER_REPORT_STATUS]: 'warningAlt',
}
const STATUS_TO_ICON_MAP: { [key: string]: string } = {
  [RED_REPORT_STATUS]: 'fas fa-exclamation-circle',
  [AMBER_REPORT_STATUS]: 'fas fa-check-circle',
}

export default function CCCharacterizationReportValidationErrorRow(
  props: IProps,
) {
  const dataTest = props['data-test']
  const fileDetails: string[] = []
  if (utils.hasValue(props.item.file)) {
    fileDetails.push(props.item.file)
  }
  if (utils.hasValue(props.item.line)) {
    fileDetails.push(`Line: ${props.item.line}`)
  }
  if (utils.hasValue(props.item.column)) {
    fileDetails.push(`Column: ${props.item.column}`)
  }
  return (
    <View
      p={4}
      bg={STATUS_TO_BG_MAP[props.status]}
      flexible="row"
      mb={3}
      data-test={dataTest}
    >
      <Icon
        color={STATUS_TO_COLOR_MAP[props.status]}
        className={STATUS_TO_ICON_MAP[props.status]}
        fontSize={32}
        mr={3}
        data-test={`${dataTest}-icon`}
        data-icon={STATUS_TO_ICON_MAP[props.status]}
      />

      <View>
        <Text
          mt={1}
          color={STATUS_TO_COLOR_MAP[props.status]}
          fontWeight={700}
          fontSize={18}
        >
          {props.item.message || STATUS_TO_TITLE_MAP[props.status]}
        </Text>

        {utils.hasValue(fileDetails) && (
          <View mt={1}>
            <Text variant="caption">
              {utils.string.joinString(fileDetails, ' - ')}
            </Text>
          </View>
        )}

        {utils.hasValue(props.item.description) && (
          <Text fontStyle="italic" fontSize={16} mt={3}>
            {props.item.description || ''}
          </Text>
        )}
      </View>
    </View>
  )
}
