import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'

export interface IListFilterOption {
  label: any
  value: any
  checked?: boolean
}
export interface IListFilter {
  title: string
  type: 'SELECT' | 'CHECKBOX'
  options: IListFilterOption[]
  filterKey: string
  filterOperator: '=' | '=~'
  defaultValue?: any
}

export interface ISelectFilterState {
  type: 'SELECT'
  title: string
  filterString: string
  filterKey: string
  filterOperator: '=' | '=~'
  currentValue?: any
}

interface ICheckedOptions {
  [key: string]: boolean
}
export interface ICheckboxFilterState {
  type: 'CHECKBOX'
  title: string
  filterString: string
  filterKey: string
  filterOperator: '=' | '=~'
  checked: ICheckedOptions
}
export interface IFilterState {
  [title: string]: ICheckboxFilterState | ISelectFilterState
}

export const getFilterState = (
  title: string,
  state: IFilterState,
): ICheckboxFilterState | ISelectFilterState | null =>
  R.propOr(null, title, state)

export const getSelectCurrentValue = (
  title: string,
  state: IFilterState,
): any => R.path([title, 'currentValue'], state)

export const getSelectFilterString = (
  title: string,
  state: IFilterState,
): string => {
  const currentValue = getSelectCurrentValue(title, state)
  const selectState = getFilterState(title, state)
  if (currentValue === '-1' || !selectState) {
    return ''
  }

  return `${selectState.filterKey}${selectState.filterOperator}${currentValue}`
}

export const onSelectValueChange = (
  title: string,
  value: any,
  state: IFilterState,
) =>
  R.compose(
    (tempState: IFilterState) =>
      R.assocPath(
        [title, 'filterString'],
        getSelectFilterString(title, tempState),
        tempState,
      ),
    R.assocPath([title, 'currentValue'], value),
  )(state)

export const isCheckboxChecked = (
  title: string,
  key: string,
  state: IFilterState,
): boolean => R.pathOr(false, [title, 'checked', key], state)

export const getCheckboxFilterString = (
  title: string,
  state: IFilterState,
): string => {
  const checkboxState = getFilterState(title, state) as ICheckboxFilterState
  if (!checkboxState) {
    return ''
  }

  const { checked, filterKey: filterkey, filterOperator } = checkboxState
  const allChecked = R.all(R.equals(true))(R.values(checked))
  if (allChecked) {
    return ''
  }

  return R.reduce<string, string>((agg: string, key: string) => {
    if (R.propOr(false, key, checked)) {
      return `${agg}${
        utils.hasValue(agg) ? ' OR ' : ''
      }${filterkey}${filterOperator}${key}`
    }
    return agg
  }, '')(Object.keys(checked))
}

export const onCheckboxValueChange = (
  title: string,
  key: string,
  value: any,
  state: IFilterState,
) =>
  R.compose(
    (tempState: IFilterState) =>
      R.assocPath(
        [title, 'filterString'],
        getCheckboxFilterString(title, tempState),
        tempState,
      ),
    R.assocPath([title, 'checked', key], value),
  )(state)

export const buildFilterState = (filters: IListFilter[]): IFilterState => {
  const state = filters.reduce((agg: any, filter: IListFilter) => {
    switch (filter.type) {
      case 'SELECT':
        const selectState: ISelectFilterState = {
          type: 'SELECT',
          title: filter.title,
          filterString: '',
          filterKey: filter.filterKey,
          filterOperator: filter.filterOperator,
          currentValue: filter.defaultValue || '-1',
        }
        return R.assoc(filter.title, selectState, agg)
      case 'CHECKBOX':
        const checkboxState: ICheckboxFilterState = {
          type: 'CHECKBOX',
          title: filter.title,
          filterString: '',
          filterKey: filter.filterKey,
          filterOperator: filter.filterOperator,
          checked: filter.options.reduce(
            (checkedAgg: any, option: IListFilterOption) =>
              R.assoc(option.value, option.checked || false, checkedAgg),
            {},
          ),
        }
        return R.assoc(filter.title, checkboxState, agg)
      default:
        return agg
    }
  }, {})

  // Build filter keys
  return R.reduce(
    (agg: IFilterState, filter: ISelectFilterState | ICheckboxFilterState) => {
      switch (filter.type) {
        case 'SELECT':
          return R.assocPath(
            [filter.title, 'filterString'],
            getSelectFilterString(filter.title, agg),
            agg,
          )
        case 'CHECKBOX':
          return R.assocPath(
            [filter.title, 'filterString'],
            getCheckboxFilterString(filter.title, agg),
            agg,
          )
        default:
          return agg
      }
    },
    state,
  )(R.values(state))
}
