import React from 'react'
import * as R from 'ramda'

import { Spinner } from 'lib/components'
import { utils } from '@ims/1edtech-frontend-common'
import {
  IIMSApplicationFilter,
  IIMSApplicationParentFilter,
  PARENT_FILTER_PREFIX,
} from 'domains/trustedApps/components/AddTrustedApps/IMSApplicationsFilter/IIMSApplicationsFilter'
import IMSApplicationCheckboxFilter from 'domains/trustedApps/components/AddTrustedApps/IMSApplicationsFilter/IMSApplicationCheckboxFilter'
import IMSApplicationGroupedCheckboxFilter from 'domains/trustedApps/components/AddTrustedApps/IMSApplicationsFilter/IMSApplicationGroupedCheckboxFilter'
import IMSApplicationsFilterGroupTitle from 'domains/trustedApps/components/AddTrustedApps/IMSApplicationsFilter/IMSApplicationsFilterGroupTitle'

interface IFilterProps {
  title: string
  appliedFilters: string[]
  filters?: IIMSApplicationFilter[]
  groupedFilters?: IIMSApplicationParentFilter[]
  onFilterChecked: (
    filter: IIMSApplicationFilter | IIMSApplicationFilter[],
  ) => any
  pending?: boolean
  topContent?: any
}
export default function IMSApplicationsFilterGroup(props: IFilterProps) {
  const { filters = [], groupedFilters = [] } = props
  const isGrouped = utils.hasValue(props.groupedFilters)

  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    const name = R.pathOr('', ['target', 'name'], event)
    const isParent = name.startsWith(PARENT_FILTER_PREFIX)
    if (isGrouped) {
      if (isParent) {
        const filter = R.find(
          R.propEq('parent', name.substring(PARENT_FILTER_PREFIX.length)),
        )(groupedFilters) as IIMSApplicationParentFilter
        props.onFilterChecked(filter.children)
      } else {
        let filter: IIMSApplicationFilter
        for (const parent of groupedFilters) {
          let found = false
          for (const f of parent.children) {
            if (f.label === name) {
              found = true
              filter = f
              break
            }
          }
          if (found) {
            break
          }
        }
        props.onFilterChecked([filter!]) // eslint-disable-line
      }
    } else {
      const filter = R.find(R.propEq('label', name))(
        filters,
      ) as IIMSApplicationFilter
      props.onFilterChecked([filter])
    }
  }

  return (
    <div className="mb-3">
      <IMSApplicationsFilterGroupTitle>
        {props.title}
      </IMSApplicationsFilterGroupTitle>
      {props.topContent}
      {props.pending && <Spinner centered={true} />}
      {!props.pending &&
        isGrouped &&
        groupedFilters.map((filter) => {
          if (filter.children.length === 1) {
            const child = filter.children[0]
            return (
              <IMSApplicationCheckboxFilter
                key={`parent-filter-${filter.parent}`}
                filter={child}
                appliedFilters={props.appliedFilters}
                onChange={onChange}
              />
            )
          }
          return (
            <IMSApplicationGroupedCheckboxFilter
              key={`parent-filter-${filter.parent}`}
              appliedFilters={props.appliedFilters}
              filter={filter}
              onFilterChecked={props.onFilterChecked}
            />
          )
        })}
      {!props.pending &&
        !isGrouped &&
        filters.map((filter, index) => {
          return (
            <IMSApplicationCheckboxFilter
              key={`filter-${filter.label}-${index}`}
              filter={filter}
              appliedFilters={props.appliedFilters}
              onChange={onChange}
            />
          )
        })}
    </div>
  )
}
