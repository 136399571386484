import { utils } from '@ims/1edtech-frontend-common'

import { INewCharacterization } from 'domains/characterizations/models/INewCharacterization'
import { createRecord } from 'lib/records/workflows/createRecord'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import store from 'lib/store'
import { postRequest } from 'lib/api/api'
import { UPLOAD_ZIP_FILE_SOURCE } from 'domains/characterizations/constants/createCharacterizationCSV'
import trackCharacterizationAnalytics from 'domains/characterizations/utils/trackCharacterizationAnalytics'

export const createFileCharacterization = async (
  record: string,
  characterization: INewCharacterization,
  onProgress?: (percentComplete: number) => void,
) => {
  let finalCharacterization = characterization
  if (
    characterization.zipFileSource === UPLOAD_ZIP_FILE_SOURCE &&
    characterization.zipFile
  ) {
    const formData = new FormData()
    formData.append('file', characterization.zipFile)
    const shouldUseProxy = utils.host.isInTestMode || utils.host.isInDevMode
    const responseURLKey = shouldUseProxy ? 'fileUrl' : 'url'
    const url = shouldUseProxy ? 'characterizations/files/upload' : 'files'
    const encoded = encodeURI(characterization.zipFile.name)
    const response = await postRequest(
      url,
      formData,
      {},
      {
        'Content-Disposition': `attachment; filename="${encoded}"; filename*=UTF-8''${encoded}`,
      },
      1800000,
      false,
      {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          if (onProgress) {
            onProgress(progressEvent.loaded / progressEvent.total)
          }
        },
      },
    )

    if (!response.success) {
      return false
    }

    if (shouldUseProxy) {
      const proxyInternalURL = utils.env.getEnvVariable(
        'PROXY_INTERNAL_URL',
        'http://proxy/api',
      )
      finalCharacterization = {
        ...finalCharacterization,
        zipFileURL: `${proxyInternalURL}/${response.data.fileUrl}`,
        authstring: response.data.authstring,
      }
    } else {
      finalCharacterization = {
        ...finalCharacterization,
        zipFileURL: response.data[responseURLKey],
      }
    }
  }

  trackCharacterizationAnalytics('submitted_characterization', {
    specification: characterization.format,
    fileSource: characterization.zipFileSource,
    source: characterization.source,
    otherSource: characterization.otherSource,
    zipSize: characterization.zipFile ? characterization.zipFile.size : 'N/A',
  })

  const orgId = myOrganizationIdSelector(store.getState(), {})
  const { data, success } = await createRecord(record, {
    ...finalCharacterization,
    orgId,
  })
  return success ? data : success
}
