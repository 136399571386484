import React, { PureComponent } from 'react'
import * as R from 'ramda'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { Span } from 'lib/components'
import { rosteringToolSelector } from 'domains/products/selectors/rosteringTool'
import { getProductRoute } from 'domains/products/navigation/routes'
import IProduct from 'domains/products/models/IProduct'
import { RootState } from 'lib/store/rootReducer'
import { AnySpecFormatType } from 'domains/formats/constants/formats'

interface IPropsFromState {
  rosteringTool?: IProduct
}
const stateMap = createStructuredSelector<RootState, any, IPropsFromState>({
  rosteringTool: rosteringToolSelector('id'),
})

interface IProps {
  id: string | number
  rosteringToolId?: number
  rosteringToolName?: string
  format: AnySpecFormatType
  textProps?: any
}

export class ProductRosteringToolLink extends PureComponent<
  IProps & IPropsFromState
> {
  render() {
    const {
      id,
      format,
      rosteringTool,
      rosteringToolId = false,
      rosteringToolName = false,
      textProps = {},
    } = this.props
    const toolId = rosteringToolId || (rosteringTool ? rosteringTool.id : 0)
    const toolName =
      rosteringToolName || R.propOr('Unnamed product', 'name', rosteringTool)
    if (!rosteringTool && !rosteringToolName) {
      return 'Rostering Tool Not Found'
    }

    return (
      <Link
        to={getProductRoute(toolId, format)}
        data-test={`rostering-tool-link-${id}`}
      >
        <Span fontSize={13} color="primary" {...textProps}>
          {toolName}
        </Span>
      </Link>
    )
  }
}

export default connect(stateMap)(ProductRosteringToolLink)
