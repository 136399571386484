import * as R from 'ramda'

import { AnySpecFormatType } from 'domains/formats/constants/formats'
import { formatIdByNameSelector } from 'domains/specifications/selectors/formatSelectors'
import store from 'lib/store'
import { getRequest } from 'lib/api/api'
import IProduct from 'domains/products/models/IProduct'

export const getRosteredProducts = async (
  productId: string | number,
  format: AnySpecFormatType,
): Promise<IProduct[]> => {
  const formatId = formatIdByNameSelector(format)(store.getState())
  const response = await getRequest(
    `products/${productId}/formats/${formatId}/rostered`,
  )

  return R.pathOr([], ['data', 'products'], response) as IProduct[]
}
