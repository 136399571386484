import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { utils } from '@ims/1edtech-frontend-common'

import { Button, Icon, Text, View } from 'lib/components'
import colors from 'lib/styles/colors'
import * as fileConstants from 'domains/modeling/constants/files'
import { RootState } from 'lib/store/rootReducer'
import { AnyFormatType } from 'domains/formats/constants/formats'
import { fileModelingStatusSelector } from 'domains/modeling/selectors/csvModeling'

interface INameTextProps {
  textColor: string
  text: string
}
const NameText = (props: INameTextProps) => (
  <Text
    variant="subtitle"
    fontWeight={700}
    textAlign="center"
    color={props.textColor}
    wordBreak="break-word"
  >
    {props.text}
  </Text>
)

const size = 180
const borderColors = {
  [fileConstants.NOT_STARTED_STATUS]: '#d8d8d8',
  [fileConstants.IN_PROGRESS_STATUS]: colors.warningAlt,
  [fileConstants.COMPLETE_STATUS]: colors.start,
  [fileConstants.UPDATED_STATUS]: colors.complete,
}
const bgColors = {
  [fileConstants.NOT_STARTED_STATUS]: colors.white,
  [fileConstants.IN_PROGRESS_STATUS]: colors.warningAlt,
  [fileConstants.COMPLETE_STATUS]: colors.start,
  [fileConstants.UPDATED_STATUS]: colors.complete,
}
const friendlyStatusName = {
  [fileConstants.NOT_STARTED_STATUS]: 'not started',
  [fileConstants.IN_PROGRESS_STATUS]: 'in progress',
  [fileConstants.COMPLETE_STATUS]: 'complete',
  [fileConstants.UPDATED_STATUS]: 'updated',
}

interface IPropsFromState {
  status: fileConstants.FileStatuses
}

const stateMap = createStructuredSelector<RootState, any, IPropsFromState>({
  status: fileModelingStatusSelector('modelId', 'format', 'name'),
})

interface IProps {
  name: string | string[]
  modelId: string | number
  format: AnyFormatType
  onLaunch?: () => void
  onEdit?: () => void
  onReset?: (name: string | string[]) => void
  dataTest?: string
}

export class ProductModelResource extends PureComponent<
  IProps & IPropsFromState
> {
  static NOT_STARTED_STATUS = fileConstants.NOT_STARTED_STATUS
  static IN_PROGRESS_STATUS = fileConstants.IN_PROGRESS_STATUS
  static COMPLETE_STATUS = fileConstants.COMPLETE_STATUS
  static UPDATED_STATUS = fileConstants.UPDATED_STATUS

  onReset = () => this.props.onReset && this.props.onReset(this.props.name)

  renderTopBar() {
    const { status, onEdit, dataTest } = this.props

    if (status === fileConstants.NOT_STARTED_STATUS) {
      return <View display="flex" flex={1} />
    }

    return (
      <View flexible="row-h-end" width="92%" p={2}>
        <Icon
          className="fas fa-pencil-alt"
          color="white"
          pl={2}
          cursor="pointer"
          onClick={onEdit}
          data-test={`${dataTest}-edit`}
        />
        {status === fileConstants.COMPLETE_STATUS && (
          <Icon
            className="fas fa-minus-circle"
            color="white"
            pl={2}
            cursor="pointer"
            onClick={this.onReset}
            data-test={`${dataTest}-reset`}
          />
        )}
      </View>
    )
  }

  render() {
    const { name, status, onLaunch, dataTest } = this.props
    const started = status !== fileConstants.NOT_STARTED_STATUS
    const textColor = started ? colors.white : colors.text
    const nameFormatted = Array.isArray(name) ? (
      name.map((n, index) => (
        <NameText
          key={n}
          textColor={textColor}
          text={`${n}${index !== name.length - 1 ? ',' : ''}`}
        />
      ))
    ) : (
      <NameText
        textColor={textColor}
        text={utils.string.camelWrap(name as string)}
      />
    )

    return (
      <View
        flexible="column-h-center"
        bg={bgColors[status]}
        border={`3px solid ${borderColors[status]}`}
        borderRadius={4}
        pb={3}
        width={size}
        minWidth={size}
        maxWidth={size}
        height={size}
        minHeight={size}
        maxHeight={size}
        boxSizing="border-box"
        data-test={dataTest}
        data-status={status}
      >
        {this.renderTopBar()}

        <View flexible="column-center" flex={2}>
          {nameFormatted}
          {started && (
            <Text color={textColor} fontWeight={700} pt={2}>
              [{friendlyStatusName[status]}]
            </Text>
          )}
        </View>

        {!started && (
          <Button
            type="button"
            variant="start"
            onClick={onLaunch}
            data-test={`${dataTest}-edit`}
          >
            launch
          </Button>
        )}
      </View>
    )
  }
}

export default connect(stateMap)(ProductModelResource)
