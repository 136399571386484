import * as normalizr from 'normalizr'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

export const LTI_CONFIGURATIONS_RECORD = 'ltiConfigurations'


const pickData = R.pick(['name', 'iss', 'authServerUrl', 'jwksUrl', 'clientId'])
const ltiConfigurations: records.modules.IModule = {
  record: LTI_CONFIGURATIONS_RECORD,
  responseDataKey: 'ltiConfigurations',
  getCreateResponseData: R.propOr({}, 'data'),
  schema: new normalizr.schema.Entity(
    LTI_CONFIGURATIONS_RECORD,
    {},
    {
      idAttribute: 'iss',
      processStrategy: (entity) => {
        const data = R.assoc('id', entity.name, entity)
        return data
      }
    },
  ),
  api: {
    create: ({ orgId }) => `orgs/${orgId}/lti/configuration`,
    prepareCreateData: pickData,
    get: (id, name) => `orgs/${id}/lti/configuration/${name}`,
    getList: (_, orgId) => `orgs/${orgId}/lti/configurations`,
    update: (id, { orgId }) => `orgs/${orgId}/lti/configuration/${id}`,
    prepareUpdateData: pickData,
    delete: (id, { orgId }) => `orgs/${orgId}/lti/configuration/${id}`,
  },
}

export default ltiConfigurations
