import * as R from 'ramda'
import Cookies from 'universal-cookie'
import { utils } from '@ims/1edtech-frontend-common'

import { history } from 'lib/utils/navigation'
import {
  OAUTH_REDIRECT_ROUTE,
  DASHBOARD_ROUTE,
} from 'domains/application/navigation/routes'
import {
  generateCodeChallenge,
  generateStateCode,
} from 'domains/authentication/utils/authorizeCodes'
import { postRequest } from 'lib/api/api'
import trackAuthenticationAnalytics from 'domains/authentication/utils/trackAuthenticationAnalytics'
import { ROLE_TYPES } from 'domains/authentication/constants/roles'
import { whoAmI } from 'domains/authentication/workflows/whoAmI'
import { initialize } from 'domains/application/workflows/initialize'
import {
  freshdeskJWTLogin,
  FRESHDESK_DATA_KEY,
} from 'domains/authentication/workflows/freshdeskJWTLogin'
import { setTokens } from 'lib/api/utils'

export const VERIFIER_TOKEN = 'OAUTH2_VERIFIER'
export const FALLBACK_OAUTH2_BASE_URL = 'http://localhost:8084/oauth2server'
export const FALLBACK_OAUTH2_CLIENT_ID = 'aaabbbccc'
export const OAUTH2_SCOPE =
  'http://www.1edtech.org/1edtech/v1p0/oauth2scope/1edtech-all'

export const OAUTH2_REDIRECT_URI = `${window.location.origin}${OAUTH_REDIRECT_ROUTE}`
export const OAUTH2_BASE_URL = utils.env.getEnvVariable(
  'OAUTH2_BASE_URL',
  FALLBACK_OAUTH2_BASE_URL,
)
export const OAUTH2_CLIENT_ID = utils.env.getEnvVariable(
  'OAUTH2_CLIENT_ID',
  FALLBACK_OAUTH2_CLIENT_ID,
)

export const getOAuth2Verifier = async () => {
  let verifier = await utils.localStorage.getLocalItem(VERIFIER_TOKEN)

  if (verifier) {
    return verifier
  }

  const cookies = new Cookies()

  verifier = cookies.get(VERIFIER_TOKEN)
  return utils.hasValue(verifier) ? verifier : false
}

export const getOauth2LoginInfo = () => {
  const { verifier, codeChallenge } = generateCodeChallenge()
  const state = generateStateCode()

  utils.localStorage.setLocalItem(VERIFIER_TOKEN, verifier)
  const cookies = new Cookies()
  cookies.set(VERIFIER_TOKEN, verifier)

  const url = `${OAUTH2_BASE_URL}/authorize?client_id=${OAUTH2_CLIENT_ID}&redirect_uri=${OAUTH2_REDIRECT_URI}&response_type=code&scope=${OAUTH2_SCOPE}&code_challenge=${codeChallenge}&code_challenge_method=S256&state=${state}`
  return { url, state, verifier, codeChallenge }
}

export const oauth2CodeExchange = async (code: string, verifier: string) => {
  let me: boolean | ROLE_TYPES = false
  const response = await postRequest('oauth2server/authtoken', {
    code,
    code_verifier: verifier,
    redirect_uri: OAUTH2_REDIRECT_URI,
  })

  if (response.success) {
    trackAuthenticationAnalytics('successful_login')
    setTokens(response)

    const freshdeskData = utils.localStorage.getLocalItem(
      FRESHDESK_DATA_KEY,
      null,
    )
    if (freshdeskData) {
      await freshdeskJWTLogin(freshdeskData.nonce, freshdeskData.state)
      return
    }

    me = await whoAmI()
    await initialize()
    const redirectUrl = R.pathOr(
      DASHBOARD_ROUTE,
      ['location', 'state', 'redirectUrl'],
      history,
    )
    history.push(redirectUrl)
  } else {
    trackAuthenticationAnalytics('failed_login')
  }

  return me
}
