import React, { PureComponent } from 'react'
import * as R from 'ramda'

import { View, Text, Icon } from 'lib/components'
import ReportStatusIcon from 'domains/reports/components/ReportStatusIcon'
import { utils } from '@ims/1edtech-frontend-common'
import {
  GREEN_REPORT_STATUS,
  AMBER_REPORT_STATUS,
  RED_REPORT_STATUS,
  ReportStatus,
} from 'domains/reports/models/IReportStatuses'
import colors from 'lib/styles/colors'

interface IProps {
  showStatusText?: boolean
  name: string
  status?: ReportStatus
  open: boolean
  noIcon?: boolean
  circle?: boolean
  'data-test'?: string
  rightContent?: any
}

const STATUS_MAP = {
  [GREEN_REPORT_STATUS]: '',
  [AMBER_REPORT_STATUS]: 'ALERTS',
  [RED_REPORT_STATUS]: 'CRITICAL',
}

export default class ReportExpansionHeader extends PureComponent<IProps> {
  render() {
    const {
      name,
      status,
      open,
      showStatusText,
      rightContent = null,
    } = this.props
    const statusText = R.pathOr('', [`${status}`], STATUS_MAP)
    const hasStatusText = utils.hasValue(statusText)
    return (
      <View
        borderBottom={`2px solid ${colors.text}`}
        flexible="row-v-end"
        flex={1}
        px={[0, 2]}
        pb={2}
        mt={2}
        height={48}
        data-test={this.props['data-test'] || `file-report-header-${name}`}
        data-open={open ? 'open' : 'closed'}
      >
        <Text fontSize={[14, 28]} fontWeight={800} userSelect="none">
          {name}
        </Text>
        <View flex={1} />

        {rightContent}

        <div className="flex flex-row items-center justify-end">
          {utils.hasValue(status) && (
            <div className="flex flex-col items-center w-16">
              <>
                <ReportStatusIcon
                  status={status}
                  circle={this.props.circle}
                  data-test={`${this.props['data-test']}-status`}
                />
                {showStatusText && hasStatusText && (
                  <Text textAlign="center" mt={1} variant="caption">
                    {statusText}
                  </Text>
                )}
              </>
            </div>
          )}

          {!this.props.noIcon && (
            <div className="w-6">
              <Icon
                mb={showStatusText && hasStatusText ? '20px' : '0'}
                fontSize={24}
                color="tertiary"
                className={`fas rotate-caret fa-arrow-circle-down ${
                  open ? 'down' : ''
                }`}
              />
            </div>
          )}
        </div>
      </View>
    )
  }
}
