import React from 'react'
import { useSelector } from 'react-redux'
import { utils } from '@ims/1edtech-frontend-common'

import IComparison from 'domains/comparisons/models/IComparison'
import { ICSVComparisonReport } from 'domains/comparisons/models/ICSVComparisonReport'
import IRESTComparisonReport from 'domains/characterizations/models/IRESTComparisonReport'
import { isSupplierRoleSelector } from 'domains/authentication/selectors/roles'
import { View, Button, Icon, Text, Linkify } from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import BaseComparisonsBreadCrumbs from 'domains/comparisons/components/BaseComparisonsBreadCrumbs'
import ReportThreeTopSection from 'domains/reports/components/ReportThreeTopSection'
import ComparisonReportDetailsWidget from 'domains/comparisons/components/ComparisonReportDetailsWidget'
import ComparisonReportActionsWidget from 'domains/comparisons/components/ComparisonReportActionsWidget'
import ComparisonReportOverviewWidget from 'domains/comparisons/components/ComparisonReportOverviewWidget'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import colors from 'lib/styles/colors'
import useModalState from 'lib/hooks/useModalState'
import ComparisonReportNotesModal from 'domains/comparisons/modals/ComparisonReportNotesModal'
import IProduct from 'domains/products/models/IProduct'

interface IProps {
  comparison: IComparison
  report: ICSVComparisonReport | IRESTComparisonReport
  product: IProduct
  isNonReferralComparison: boolean
}

export default function ComparisonReportTopSection(props: IProps) {
  const isSupplier = useSelector(isSupplierRoleSelector)
  const hasNotes = utils.hasValue(props.comparison.notes)
  const [notesOpen, openNotesModal, closeNotesModal] = useModalState()

  const widgetHeight = 550
  return (
    <View>
      <DocumentTitle
        title={`Comparison: ${props.comparison.characterizationName} -
          ${props.comparison.productName}`}
      />
      <BaseComparisonsBreadCrumbs
        crumbs={[
          {
            name: `${utils.string.ellipsize(
              props.comparison.characterizationName,
              30,
            )}  +  ${utils.string.ellipsize(props.comparison.productName, 30)}`,
          },
        ]}
      />

      <View flexible="column">
        <ReportThreeTopSection>
          <ComparisonReportDetailsWidget
            comparison={props.comparison}
            product={props.product}
            isSupplier={isSupplier}
            report={props.report}
            height={widgetHeight}
          />
          <ComparisonReportOverviewWidget
            format={props.comparison.format}
            comparison={props.comparison}
            height={widgetHeight}
          />
          <ComparisonReportActionsWidget
            comparison={props.comparison}
            isSupplier={isSupplier}
            height={widgetHeight}
          />
        </ReportThreeTopSection>
      </View>

      {!props.isNonReferralComparison && (
        <View variant="paper" mb={3}>
          <View flexible="row-wrap">
            <WidgetHeader
              title="Comparison Report Notes"
              dataTest="comparison-report-notes-widget"
            />
            <Button
              variant={hasNotes ? 'start' : 'complete'}
              py={2}
              px="28px"
              ml={[2, 4]}
              mt={[2, 0]}
              height="42px"
              onClick={openNotesModal}
              data-test="comparison-report-notes-btn"
            >
              <View flexible="row-center">
                <Icon
                  className={`fas fa-${hasNotes ? 'edit' : 'plus'}`}
                  color="white"
                  fontSize={20}
                />
                <Text color="white" ml={2}>
                  {hasNotes ? 'Edit Notes' : 'Add Note'}
                </Text>
              </View>
            </Button>
          </View>

          {hasNotes && (
            <View mt={3}>
              <View
                bg="background"
                border={`1px solid ${colors.reportTableBorder}`}
                p={3}
                px={4}
                overflowX="hidden"
                width="100%"
                boxSizing="border-box"
              >
                <Text
                  wordBreak="break-word"
                  whiteSpace="pre-line"
                  data-test="comparison-report-notes"
                >
                  <Linkify>{props.comparison.notes}</Linkify>
                </Text>
              </View>
            </View>
          )}
        </View>
      )}

      <ComparisonReportNotesModal
        isOpen={notesOpen}
        closeModal={closeNotesModal}
        comparison={props.comparison}
        note={props.comparison.notes}
      />
    </View>
  )
}
