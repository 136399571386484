import { useState, useEffect } from 'react'
import * as R from 'ramda'
import memoize from 'fast-memoize'

import IAccordionHeaderState from 'domains/characterizations/models/IAccordionHeaderState'
import { utils } from '@ims/1edtech-frontend-common'

export interface IAccordionHeaderStateItem {
  name: string
}

const getHeaderStates = memoize(
  (items: IAccordionHeaderStateItem[], open?: boolean) =>
    items.map((item) => {
      return {
        data: item,
        name: item.name,
        open: !!open,
      }
    }),
)
export default function useAccordionHeaderState(
  items: IAccordionHeaderStateItem[],
  canOpen?: (data: any) => boolean,
  defaultOpen?: boolean,
  uniqueId?: any,
): [
  IAccordionHeaderState[],
  (name: string) => any,
  (name: string) => any,
  () => any,
  () => any,
] {
  const [uId, setUniqueId] = useState(uniqueId)
  const [headerStates, setHeaderStates] = useState<IAccordionHeaderState[]>([])

  const getHeaderState = (name: string) =>
    R.find(R.propEq('name', name), headerStates)

  const onToggleHeader = (name: string) =>
    setHeaderStates(
      headerStates.map((state) => ({
        ...state,
        open: state.name === name ? !state.open : state.open,
      })),
    )

  const onExpandAll = () => {
    setHeaderStates(
      headerStates.map((state) => ({
        ...state,
        open: canOpen ? canOpen(state.data) : true,
      })),
    )
  }

  const onCollapseAll = () => {
    setHeaderStates(headerStates.map((state) => ({ ...state, open: false })))
  }

  useEffect(() => {
    if (!utils.hasValue(items)) {
      return
    }

    if (
      (items.length > 0 && !utils.hasValue(headerStates)) ||
      items.length !== headerStates.length ||
      uId !== uniqueId
    ) {
      setUniqueId(uniqueId)
      setHeaderStates(getHeaderStates(items, defaultOpen))
    }
  }, [items, uId, uniqueId, defaultOpen, headerStates])

  return [
    headerStates,
    getHeaderState,
    onToggleHeader,
    onExpandAll,
    onCollapseAll,
  ]
}
