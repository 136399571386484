import React from 'react'
import * as R from 'ramda'

import { View } from 'lib/components'
import { ICommonCartridgeLanguageText } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeLanguageText'
import { ICommonCartridgeSourceValue } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeSourceValue'
import { ICommonCartridgeContribute } from 'domains/commonCartridge/models/lom/common/ICommonCartridgeContribute'
import { ICommonCartridgeLOMLifecycle } from 'domains/commonCartridge/models/lom/ICommonCartridgeLOMLifecycle'
import CCCharacterizationReportLOMSourceValue from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMSourceValue'
import CCCharacterizationReportLOMContribute from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMContribute'
import CCCharacterizationReportLOMLanguageText from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMLanguageText'
import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  data: ICommonCartridgeLOMLifecycle
  path: string
}

export default function CCCharacterizationReportLOMLifecycle(props: IProps) {
  const { showMissingMetadata } = React.useContext(ShowMissingMetadataContext)
  const versions = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['version'],
    props.data,
  ) as ICommonCartridgeLanguageText[]
  const status = R.pathOr(
    {},
    ['status'],
    props.data,
  ) as ICommonCartridgeSourceValue
  const contributes = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['contribute'],
    props.data,
  ) as ICommonCartridgeContribute[]

  return (
    <View>
      {versions.map((version, i) => (
        <CCCharacterizationReportLOMLanguageText
          key={`${props.path}.version.${i}`}
          path={
            !utils.hasValue(version)
              ? `${props.path}.version`
              : `${props.path}.version.${i}`
          }
          title={`version [${i + 1}]`}
          data={version}
        />
      ))}

      <CCCharacterizationReportLOMSourceValue
        path={`${props.path}.status`}
        title="status"
        data={status}
      />

      {contributes.map((contribute, i) => (
        <CCCharacterizationReportLOMContribute
          key={`${props.path}.contribute.${i}`}
          path={
            !utils.hasValue(contribute)
              ? `${props.path}.contribute`
              : `${props.path}.contribute.${i}`
          }
          title={`contribute [${i + 1}]`}
          data={contribute}
        />
      ))}
    </View>
  )
}
