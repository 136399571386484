import base64 from 'base-64'
import * as R from 'ramda'

import { INewCharacterization } from 'domains/characterizations/models/INewCharacterization'
import {
  AnySpecFormatType,
  FORMAT_TO_SPECIFICATION_NAME,
  FORMAT_TO_SPECIFICATION_VERSION,
} from 'domains/formats/constants/formats'
import { URL_ZIP_FILE_SOURCE } from 'domains/characterizations/constants/createCharacterizationCSV'
import { utils } from '@ims/1edtech-frontend-common'

export const prepareFileCharacterizationForServer = (
  data: INewCharacterization,
) => {
  let final: any = R.compose<INewCharacterization, any, any, any, any>(
    R.dissoc('orgId'),
    R.assoc('organization', {
      id: R.prop('orgId', data),
    }),
    R.assoc(
      'specificationName',
      FORMAT_TO_SPECIFICATION_NAME[data.format! as AnySpecFormatType],
    ),
    R.assoc(
      'specificationVersion',
      FORMAT_TO_SPECIFICATION_VERSION[data.format! as AnySpecFormatType],
    ),
  )(data)

  final = R.dissoc('zipFile', final)
  final = R.dissoc('zipFile', final)
  final = R.dissoc('zipFileSource', final)
  final = R.dissoc('username', final)
  final = R.dissoc('password', final)
  final = R.dissoc('uploadProgress', final)
  final = R.dissoc('isReferringSupplier', final)
  final = R.dissoc('otherSource', final)
  const optionals = [
    'sourceVersion',
    'certifiedSourceLevel',
    'districtName',
    'districtState',
    'districtCountry',
  ]
  optionals.forEach((key) => {
    if (!utils.hasValue(R.pathOr(false, [key], final))) {
      final = R.dissoc(key, final)
    }
  })

  if (utils.hasValue(data.otherSource)) {
    final = R.assoc('source', data.otherSource, final)
  }

  if (data.zipFileSource === URL_ZIP_FILE_SOURCE && !data.authstring) {
    final = R.assoc(
      'authstring',
      utils.hasValue(data.username) && utils.hasValue(data.password)
        ? base64.encode(`${data.username}:${data.password}`)
        : '',
      final,
    )
  }

  return final
}
