import * as R from 'ramda'
import { utils, records } from '@ims/1edtech-frontend-common'

import { getRecord } from 'lib/records/workflows/getRecord'
import store from 'lib/store'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import { COMMON_CARTRIDGE_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/commonCartridgeCharacterizations'
import commonCartridgeCharacterizationReports, {
  COMMON_CARTRIDGE_CHARACTERIZATION_REPORTS_RECORD,
} from 'lib/records/modules/commonCartridgeCharacterizationReports'
import { getCommonCartridgeTOCRootItemId } from 'domains/commonCartridge/workflows/getCommonCartridgeTOCRootItemId'
import { updateEntityProperty } from 'lib/store/entities.actions'

export const getFullCommonCartridgeCharacterization = async (
  id: string | number,
) => {
  await getRecord(COMMON_CARTRIDGE_CHARACTERIZATIONS_RECORD, id)
  const characterization: ICharacterization =
    records.entitiesSelectors.entityByIdSelector(
      COMMON_CARTRIDGE_CHARACTERIZATIONS_RECORD,
      utils.convertToInt(id),
    )(store.getState())
  if (!characterization) {
    return
  }
  const response = await getRecord(
    COMMON_CARTRIDGE_CHARACTERIZATION_REPORTS_RECORD,
    characterization.content,
  )
  const data = records.modules.utils.getRecordDataFromResponse(
    commonCartridgeCharacterizationReports,
    response as any,
  )
  const reportId = R.pathOr(0, ['id'], data)
  const rootItemId = await getCommonCartridgeTOCRootItemId(reportId)
  store.dispatch(
    updateEntityProperty(
      [
        COMMON_CARTRIDGE_CHARACTERIZATION_REPORTS_RECORD,
        characterization.content,
      ],
      R.assoc('rootItemId', rootItemId),
    ),
  )
}
