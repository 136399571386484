export const SMALL_SCREEN_SIZE = 760
export const MEDIUM_SCREEN_SIZE = 1439
export const LARGE_SCREEN_SIZE = 1750
export const EXTRA_LARGE_SCREEN_SIZE = 1900

export const SMALL_SCREEN_NAME = 'SMALL_SCREEN'
export const MEDIUM_SCREEN_NAME = 'MEDIUM_SCREEN'
export const LARGE_SCREEN_NAME = 'LARGE_SCREEN'
export const EXTRA_LARGE_SCREEN_NAME = 'EXTRA_LARGE_SCREEN'

export type ScreenSizeTypes =
  | 'SMALL_SCREEN'
  | 'MEDIUM_SCREEN'
  | 'LARGE_SCREEN'
  | 'EXTRA_LARGE_SCREEN'
