import * as R from 'ramda'
import * as normalizr from 'normalizr'
import * as Sentry from '@sentry/browser'
import { records } from '@ims/1edtech-frontend-common'

import { PUBLISHED_PRODUCT_STATUS } from 'domains/products/constants/products'

export const PRODUCTS_RECORD = 'products'

const products: records.modules.IModule = {
  record: PRODUCTS_RECORD,
  schema: new normalizr.schema.Entity(
    PRODUCTS_RECORD,
    {},
    {
      processStrategy: R.compose((product: any) => {
        const formats = R.propOr(false, 'formats', product)
        if (!formats) {
          Sentry.captureMessage(`Product missing formats. ID: ${product.id}`)
        }
        return {
          ...product,
          formats: formats || [],
        }
      }, records.modules.utils.fixIdAttribute),
    },
  ),
  api: {
    create: () => 'products',
    get: (id) => `products/${id}`,
    getList: (params, supplierId) => {
      if (supplierId) {
        return `suppliers/${supplierId}/products`
      }

      const formatId = R.propOr(false, 'formatId', params)
      if (formatId) {
        return `formats/${formatId}/products`
      }

      return 'products'
    },
    update: (id, params) =>
      `products/${
        params.status === PUBLISHED_PRODUCT_STATUS ? 'private' : 'published'
      }/${id}`,
    delete: (id) => `products/${id}`,
  },
}

export default products
