export const GENERAL_NOTIFICATION = 'GENERAL'
export const CHARACTERIZATION_COMPLETED_NOTIFICATION =
  'CHARACTERIZATION_COMPLETED'
export const COMPARISON_ZOMBIFIED_NOTIFICATION = 'COMPARISON_ZOMBIFIED'
export const USER_DELETED_NOTIFICATION = 'USER_DELETED'
export const USER_CREATED_NOTIFICATION = 'USER_CREATED'
export const ACCOUNT_EXPIRING_SOON_NOTIFICATION = 'ACCOUNT_EXPIRING_SOON'
export const PRODUCT_EXPIRING_SOON_NOTIFICATION = 'PRODUCT_EXPIRING_SOON'

export const ALL_NOTIFICATION_EVENTS = [
  GENERAL_NOTIFICATION,
  CHARACTERIZATION_COMPLETED_NOTIFICATION,
  COMPARISON_ZOMBIFIED_NOTIFICATION,
  USER_DELETED_NOTIFICATION,
  USER_CREATED_NOTIFICATION,
  ACCOUNT_EXPIRING_SOON_NOTIFICATION,
  PRODUCT_EXPIRING_SOON_NOTIFICATION,
]
