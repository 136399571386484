import * as Yup from 'yup'

const maxLength255Message = 'Must be 255 characters or less'

export const modelDetailValidation = (isRest = false) => {
  const shared = {
    implementationUrl: Yup.string()
      .max(255, maxLength255Message)
      .url('Must be a valid URL'),
    onlineHelpUrl: Yup.string()
      .max(255, maxLength255Message)
      .url('Must be a valid URL'),
    productSupportEmail: Yup.string()
      .max(255, maxLength255Message)
      .email('Must be a valid email'),
  }

  if (isRest) {
    return Yup.object().shape({
      ...shared,
      oauthSupport: Yup.number().min(
        1,
        'At least one form of OAuth support is required',
      ),
    })
  }

  return Yup.object().shape(shared)
}
