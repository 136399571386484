import { utils } from '@ims/1edtech-frontend-common'
import * as R from 'ramda'

export default function getOrgLogo(logo: string) {
    if (R.isNil(logo)) {
        return ''
    }

    return utils.url.getIMSUrl(logo)
}
