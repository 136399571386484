import React, { PureComponent } from 'react'

import { ToolTip, ListPlainTextCell } from 'lib/components'
import ICharacterization from 'domains/characterizations/models/ICharacterization'

export default class CharacterizationSourceCell extends PureComponent<ICharacterization> {
  render() {
    const { id, source } = this.props

    const tooltipId = `${id}-source`
    return (
      <div>
        <ToolTip id={tooltipId} tip={source} delay={500}>
          <ListPlainTextCell>{source}</ListPlainTextCell>
        </ToolTip>
      </div>
    )
  }
}
