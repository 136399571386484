import React, { PureComponent } from 'react'

import IComparison from 'domains/comparisons/models/IComparison'
import ReportStatusIcon from 'domains/reports/components/ReportStatusIcon'
import ZombieCell from 'domains/comparisons/components/ZombieCell'

interface IProps extends IComparison {
  hideIfZombie?: boolean
}

export default class ComparisonCompatibilityCell extends PureComponent<IProps> {
  render() {
    if (this.props.zombie) {
      if (this.props.hideIfZombie) {
        return null
      }
      return <ZombieCell />
    }
    return <ReportStatusIcon status={this.props.compatibility} />
  }
}
