import React, { useState, useEffect } from 'react'
import * as R from 'ramda'

import Menu, { IMenuItem } from 'lib/components/Menu'
import View, { IViewProps } from 'lib/components/View'
import Icon from 'lib/components/Icon'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  target: any
  menuItems?: IMenuItem[]
  menuItemComponent?: any
  menuItemData?: any
  onMenuItemClick?: (item: any) => any
  yolo?: any
  parentProps?: IViewProps
  showChevron?: boolean
}

const DropdownMenu = (props: IProps) => {
  const [open, setOpen] = useState(false)

  const close = () => setOpen(false)

  useEffect(() => {
    if (open) {
      document.addEventListener('click', close)
    }
    return () => document.removeEventListener('click', close)
  }, [open])

  const toggleOpen = () => setOpen(!open)

  const renderTarget = () => {
    const content = R.is(Function, props.target)
      ? props.target(props, toggleOpen)
      : props.target
    return (
      <View
        onClick={toggleOpen}
        flexible="row-v-center"
        cursor="pointer"
        {...props.parentProps}
      >
        {content}
        {props.showChevron && (
          <Icon
            color="textLight"
            className={`
                  fas fa-chevron-down
                  rotate-chevron
                  ${open ? 'down' : ''}
                `}
          />
        )}
      </View>
    )
  }

  const renderMenu = () => {
    const { menuItems = [], onMenuItemClick = () => null } = props
    if (!open || !utils.hasValue(menuItems)) {
      return null
    }

    return (
      <View animation="fadeIn" position="absolute" top="100%">
        <Menu
          items={menuItems}
          component={props.menuItemComponent}
          itemData={props.menuItemData}
          onClick={onMenuItemClick}
        />
      </View>
    )
  }

  return (
    <View position="relative" flexible="column-center" {...props.parentProps}>
      {renderTarget()}
      {renderMenu()}
    </View>
  )
}

export default DropdownMenu
