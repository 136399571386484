import { utils, records } from '@ims/1edtech-frontend-common'

import { updateRecord } from 'lib/records/workflows/updateRecord'
import store from 'lib/store'
import IRestCModel from 'domains/modeling/models/IRestCModel'
import { REST_C_MODELS_RECORD } from 'lib/records/modules/restCModels'
import { trackRestCModelingAnalytics } from 'domains/modeling/utils/trackModelingAnalytics'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'
import { getRecord } from 'lib/records/workflows/getRecord'

export const updateRestCModel = async (
  id: string | number,
  model: IRestCModel,
) => {
  await updateRecord(REST_C_MODELS_RECORD, id, model)
  await getRecord(PRODUCTS_RECORD, model.productId)
  trackRestCModelingAnalytics('updated_model_details')
  return records.entitiesSelectors.entityByIdSelector(
    REST_C_MODELS_RECORD,
    utils.convertToInt(id),
  )(store.getState())
}
