import { useEffect } from 'react'
import { APPLICATION_NAME } from 'domains/application/constants/application'

export function useDocumentTitle(title: string, noAppTitle = false) {
  useEffect(() => {
    if (noAppTitle) {
      document.title = title
    } else {
      document.title = `${APPLICATION_NAME}: ${title}`
    }
  }, [title, noAppTitle])
}

interface IProps {
  title: string
  noAppTitle?: boolean
}
export function DocumentTitle(props: IProps) {
  useDocumentTitle(props.title, props.noAppTitle)
  return null
}
