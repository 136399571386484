import { utils } from '@ims/1edtech-frontend-common'

const getWidget = () => document.getElementById('freshwidget-button')

const tryWhile = async (
  attempt: () => boolean,
  maxAttempts = 100,
  wait = 1,
) => {
  let success = attempt()
  let attempts = 0
  while (!success && attempts < maxAttempts) {
    await utils.wait.waitFor(wait)
    success = attempt()
    attempts += 1
  }
}

export const tryHideFreshDeskWidget = () => {
  const widget = getWidget()

  if (widget) {
    widget.style.setProperty('visibility', 'hidden')
    return true
  }

  return false
}

export const hideFreshDeskWidget = () => tryWhile(tryHideFreshDeskWidget)

export const hideFreshDeskWidgetSync = () => {
  const widget = getWidget()
  if (widget) {
    widget.style.setProperty('visibility', 'hidden')
  }
}

const tryShowFreshDeskWidget = () => {
  const widget = getWidget()
  if (widget) {
    widget.style.setProperty('visibility', 'visible')
    return true
  }
  return false
}

export const showFreshDeskWidget = () => tryWhile(tryShowFreshDeskWidget)
