import React from 'react'

import { BreadCrumbs } from 'lib/components'
import {
  DASHBOARD_ROUTE,
  getRoute,
} from 'domains/application/navigation/routes'
import IBreadCrumb from 'domains/application/models/IBreadCrumb'

interface IProps {
  crumbs: IBreadCrumb[]
}

export default function BaseBreadCrumbs(props: IProps) {
  return (
    <BreadCrumbs
      crumbs={[
        {
          name: 'Home',
          route: getRoute(DASHBOARD_ROUTE),
        },
        ...props.crumbs,
      ]}
    />
  )
}
