import React, { PureComponent } from 'react'
import * as R from 'ramda'
import { RouteComponentProps } from 'react-router'
import { utils } from '@ims/1edtech-frontend-common'

import AppLoadingScreen from 'domains/application/screens/AppLoadingScreen'
import withNavigationProps from 'lib/utils/withNavigationProps'
import { Button, Text, View } from 'lib/components'
import { LOGIN_ROUTE } from 'domains/application/navigation/routes'
import { getRequest } from 'lib/api/api'
import { whoAmI } from 'domains/authentication/workflows/whoAmI'
import IUser from 'domains/users/models/IUser'
import { LTI_USERS_STORAGE_KEY } from 'domains/authentication/constants/lti'
import { postWhoAmIInitialize } from 'domains/application/workflows/initialize'
import { setTokens, dataSelector } from 'lib/api/utils'
import { isResponseIndicatingLockedOut } from 'lib/api/utils'

interface IState {
  invalid: boolean
}

class LTILoginScreen extends PureComponent<RouteComponentProps, IState> {
  state = {
    invalid: false,
  }

  async componentDidMount() {
    const accessToken = R.propOr(false, 'access_token', this.props)
    const refreshToken = R.propOr(false, 'refresh_token', this.props)

    if (!utils.hasValue(accessToken) || !utils.hasValue(refreshToken)) {
      this.setState({ invalid: true })
      return
    }

    utils.localStorage.clearAllLocalItems()
    setTokens({ data: { accessToken, refreshToken } })
    await whoAmI()
    const loginResponse = await getRequest('login')
    if (!loginResponse.success) {
      if (isResponseIndicatingLockedOut(loginResponse)) {
        this.props.history.replace('/')
        return
      }

      this.setState({ invalid: true })
      return
    }
    await postWhoAmIInitialize()

    const me = dataSelector(loginResponse) as IUser
    const currentLTIUsers = utils.localStorage.getLocalItem(
      LTI_USERS_STORAGE_KEY,
      {},
    ) as any
    utils.localStorage.setLocalItem(
      LTI_USERS_STORAGE_KEY,
      R.assoc(`${me.id}`, true, currentLTIUsers),
    )
    this.props.history.replace('/')
  }

  goToLogin = () => this.props.history.replace(LOGIN_ROUTE)

  render() {
    const { invalid } = this.state
    return (
      <AppLoadingScreen
        message={
          invalid ? (
            <View my={3} flexible="column-center">
              <Text textAlign="center" color="white">
                Could not log you in. Please try again later.
              </Text>
            </View>
          ) : null
        }
        action={
          invalid && (
            <Button variant="start" color="white" onClick={this.goToLogin}>
              Go to login
            </Button>
          )
        }
      />
    )
  }
}

export default withNavigationProps(LTILoginScreen)
