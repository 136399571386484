import React from 'react'

import { View, Text } from 'lib/components'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  notes?: string
}
export default function CharacterizationReportNotesCell(props: IProps) {
  const { notes } = props
  if (!utils.hasValue(notes)) {
    return null
  }
  return (
    <View bg="secondary" borderRadius={4} px={2} py="12px">
      <Text
        color="white"
        fontSize={13}
        fontWeight={700}
        width={[200, 250, 250, 'auto', 'auto']}
        wordBreak="break-all"
      >
        {notes}
      </Text>
    </View>
  )
}
