import React from 'react'
import { useSelector } from 'react-redux'
import { records } from '@ims/1edtech-frontend-common'

import {
  getRecords,
  IGetRecordsOptions,
} from 'lib/records/workflows/getRecords'

export default function useRecord(
  options: IGetRecordsOptions,
  full?: boolean,
): [records.recordReducers.IRecordState, boolean] {
  const [pending, setPending] = React.useState(true)
  const relatedRecordSel = full
    ? records.relatedRecordsSelectors.fullRelatedRecordsSelector
    : records.relatedRecordsSelectors.relatedRecordSelector
  const recordSel = full
    ? records.recordsSelectors.fullRecordsSelector
    : records.recordsSelectors.recordSelector

  const selector = options.parentRecordId
    ? relatedRecordSel(options.record, options.parentRecordId)
    : recordSel(options.record)
  const recordState = useSelector((state: any) => selector(state, {}))

  React.useEffect(() => {
    const fetch = async () => {
      await getRecords(options)
      setPending(false)
    }
    fetch()
  }, []) // eslint-disable-line

  return [recordState, pending]
}
