import React, { PureComponent } from 'react'
import ITransformedCharacterizationCSVReport from 'domains/characterizations/models/ITransformedCharacterizationCSVReport'
import { getCharacterizationReportFileStatus } from 'domains/characterizations/utils/characterizationReport'
import { ICSVFileComparisonReport } from 'domains/comparisons/models/ICSVComparisonReport'
import ReportStatusRow from 'domains/reports/components/ReportStatusRow'

interface IProps {
  report?: ITransformedCharacterizationCSVReport
  fileLabel?: string
  file?: ICSVFileComparisonReport
}
export default class FileReportRow extends PureComponent<IProps> {
  render() {
    const { report, fileLabel = '', file } = this.props
    const status = file
      ? file.status
      : getCharacterizationReportFileStatus(fileLabel, report)
    const fileName = file ? file.filename : fileLabel

    return (
      <ReportStatusRow
        label={fileName as string}
        status={status}
        data-test={`overview-item-status-${fileName}`}
      />
    )
  }
}
