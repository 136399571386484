import React from 'react'

import useQuery from 'lib/hooks/useQuery'
import { freshdeskJWTLogin } from 'domains/authentication/workflows/freshdeskJWTLogin'
import AppLoadingScreen from 'domains/application/screens/AppLoadingScreen'

export default function FreshdeskJWTLoginScreen() {
  const query = useQuery()
  const nonce = query.get('nonce') || ''
  const state = query.get('state') || ''

  React.useEffect(() => {
    freshdeskJWTLogin(nonce, state)
  }, []) // eslint-disable-line

  return <AppLoadingScreen />
}
