import { AnySpecFormatType } from 'domains/formats/constants/formats'
import { formatIdByNameSelector } from 'domains/specifications/selectors/formatSelectors'
import store from 'lib/store'
import { putRequest } from 'lib/api/api'
import IProduct from 'domains/products/models/IProduct'
import { PUBLISHED_PRODUCT_STATUS } from 'domains/products/constants/products'

export const bactchPublishRosteredProducts = async (
  format: AnySpecFormatType,
  products: IProduct[],
) => {
  const formatId = formatIdByNameSelector(format)(store.getState())
  const body = products.map((p) => ({
    formatId,
    productId: p.id,
    status: PUBLISHED_PRODUCT_STATUS,
  }))
  const response = await putRequest('products/status', body)

  return response.success
}
