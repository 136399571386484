import { SelectOptionProps } from 'lib/components/modern/Select/SelectOption'

export const EA_ALL_GRADE_LEVELS = 'ALL_GRADE_LEVELS'
export const EA_K_6TH_GRADE = 'K_6TH_GRADE'
export const EA_K7_12TH_GRADE = 'K7_12TH_GRADE'
export const EA_POST_SECONDARY = 'POST_SECONDARY'
export const EA_CORPORATE_EDUCATION = 'CORPORATE_EDUCATION'

export type ProductEducationalAudiences =
  | typeof EA_ALL_GRADE_LEVELS
  | typeof EA_K_6TH_GRADE
  | typeof EA_K7_12TH_GRADE
  | typeof EA_POST_SECONDARY
  | typeof EA_CORPORATE_EDUCATION

export const EDUCATIONAL_AUDIENCES_TO_DISPLAY_MAP: { [key: string]: string } = {
  [EA_ALL_GRADE_LEVELS]: 'All Grade Levels',
  [EA_K_6TH_GRADE]: 'K-6th Grade',
  [EA_K7_12TH_GRADE]: '7th-12th Grade',
  [EA_POST_SECONDARY]: 'Postsecondary',
  [EA_CORPORATE_EDUCATION]: 'Corporate Education',
}

export const educationAudienceOptions: SelectOptionProps[] = [
  {
    label: EDUCATIONAL_AUDIENCES_TO_DISPLAY_MAP[EA_ALL_GRADE_LEVELS],
    value: EA_ALL_GRADE_LEVELS,
  },
  {
    label: EDUCATIONAL_AUDIENCES_TO_DISPLAY_MAP[EA_K_6TH_GRADE],
    value: EA_K_6TH_GRADE,
  },
  {
    label: EDUCATIONAL_AUDIENCES_TO_DISPLAY_MAP[EA_K7_12TH_GRADE],
    value: EA_K7_12TH_GRADE,
  },
  {
    label: EDUCATIONAL_AUDIENCES_TO_DISPLAY_MAP[EA_POST_SECONDARY],
    value: EA_POST_SECONDARY,
  },
  {
    label: EDUCATIONAL_AUDIENCES_TO_DISPLAY_MAP[EA_CORPORATE_EDUCATION],
    value: EA_CORPORATE_EDUCATION,
  },
]
