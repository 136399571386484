import React from 'react'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import IUser from 'domains/users/models/IUser'
import { USER_ROLES_RECORD } from 'lib/records/modules/userRoles'
import IUserRole from 'domains/users/models/IUserRole'
import UserRoleWithDescription from 'domains/users/components/UserRoleWithDescription'

export default function UserRolesCell(user: IUser) {
  const rolesRecord = useSelector((s) =>
    records.recordsSelectors.fullRecordsSelector(USER_ROLES_RECORD)(s, {}),
  )
  const roles = R.pathOr([], ['items'], rolesRecord) as IUserRole[]
  const userRoles = R.intersection(roles.map(R.prop('id')), user.userRole || [])
  return (
    <div className="flex flex-row flex-wrap items-center">
      {userRoles.map((roleId, index) => {
        const isLast = index === userRoles.length - 1
        return (
          <div
            className={clsx('flex flex-row items-center', {
              'mr-2': !isLast,
            })}
            key={roleId}
          >
            <UserRoleWithDescription roleId={roleId} />
            <span>{!isLast ? ', ' : ''}</span>
          </div>
        )
      })}
    </div>
  )
}
