import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { records } from '@ims/1edtech-frontend-common'

import { List } from 'lib/components'
import IComparison from 'domains/comparisons/models/IComparison'
import { RootState } from 'lib/store/rootReducer'
import { PRODUCT_COMPARISONS_RECORD } from 'lib/records/modules/productComparisons'
import memoize from 'fast-memoize'
import { utils } from '@ims/1edtech-frontend-common'
import ComparisonListNotesSubRow from 'domains/comparisons/components/ComparisonListNotesSubRow'
import trackComparisonAnalytics from 'domains/comparisons/utils/trackComparisonAnalytics'
import { RouteComponentProps, withRouter } from 'react-router'
import { getComparisonReportRoute } from 'domains/comparisons/navigation/routes'
import {
  comparisonListDistrictNameCell,
  comparisonListRosteringMethodCell,
  defaultComparisonListColumns,
  getComparisonListNotesCell,
} from 'domains/products/components/ComparisonListColumnDefinitions'
import ComparisonReportNotesModal from 'domains/comparisons/modals/ComparisonReportNotesModal'
import { OpenTableRows, IOpenTableRows } from 'lib/hooks/OpenTableRows'

const keyExtractor = memoize(
  (comparison: IComparison, isSubRow: boolean) =>
    `comparison-${comparison.id}${isSubRow ? '-note' : ''}`,
)

interface IPropsFromState {
  comparisons: records.recordReducers.IRecordState
}
const stateMap = createStructuredSelector<RootState, any, IPropsFromState>({
  comparisons: records.relatedRecordsSelectors.fullRelatedRecordsSelector(
    PRODUCT_COMPARISONS_RECORD,
    'id',
  ),
})

interface IProps {
  id: string | number
}

interface IState {
  comparisonNotes?: IComparison | boolean
}

export class ProductComparisonsList extends PureComponent<
  IProps & IPropsFromState & RouteComponentProps
> {
  readonly state: IState = {}

  onShowNotes = (comparisonNotes: IComparison) =>
    this.setState({ comparisonNotes })

  onCloseNotes =
    (
      onCloseRow: (
        id: string | number,
        wasSaved?: boolean,
        wasCleared?: boolean,
      ) => any,
    ) =>
    (wasSaved?: boolean, wasCleared?: boolean) => {
      onCloseRow(
        (this.state.comparisonNotes as IComparison).id,
        wasSaved,
        wasCleared,
      )
      this.setState({ comparisonNotes: false })
    }

  onToggleNotes =
    (toggleRow: (id: string | number) => any) => (comparison: IComparison) => {
      if (utils.hasValue(comparison.notes)) {
        toggleRow(comparison.id)
      } else {
        this.onShowNotes(comparison)
      }
    }

  onRowClick = (comparison: IComparison) =>
    this.props.history.push(
      getComparisonReportRoute(comparison.id, comparison.format),
    )

  getColumns = memoize((openTableRowsOptions: IOpenTableRows) => {
    const notesCell = getComparisonListNotesCell(
      openTableRowsOptions.OpenTableRows,
      this.onToggleNotes(openTableRowsOptions.onToggleRow),
    )
    return [
      comparisonListDistrictNameCell,
      comparisonListRosteringMethodCell,
      ...defaultComparisonListColumns,
      notesCell,
    ]
  })

  renderSubRow = (OpenTableRows: any) => (comparison: IComparison) =>
    OpenTableRows[comparison.id] ? (
      <ComparisonListNotesSubRow
        comparison={comparison}
        onShowNotes={this.onShowNotes}
      />
    ) : (
      false
    )

  renderComparisonNotesModal = (
    onCloseRow: (
      id: string | number,
      wasSaved?: boolean,
      wasCleared?: boolean,
    ) => any,
  ) => {
    const { comparisonNotes } = this.state
    if (!comparisonNotes) {
      return null
    }

    const comparison = comparisonNotes as IComparison
    return (
      <ComparisonReportNotesModal
        isOpen={true}
        closeModal={this.onCloseNotes(onCloseRow)}
        comparison={comparison}
        note={comparison.notes}
        isProductComparison={true}
      />
    )
  }

  render() {
    const { id, comparisons } = this.props
    return (
      <OpenTableRows>
        {(openTableRowsOptions: IOpenTableRows) => (
          <>
            <List
              title="Comparisons for this Product"
              record={PRODUCT_COMPARISONS_RECORD}
              recordState={comparisons}
              parentRecordId={id}
              columns={this.getColumns(openTableRowsOptions)}
              renderSubRow={this.renderSubRow(
                openTableRowsOptions.OpenTableRows,
              )}
              searchTemplate="districtName=~@@"
              rowHeight={68}
              dataTest={'product-comparisons-list'}
              blankSlateText="No Comparisons for this Product"
              trackAnalytics={trackComparisonAnalytics}
              trackAnalyticsCategory={'product-comparisons-list'}
              initialSortColumnIndex={7}
              initialSortDescending={true}
              onRowClick={this.onRowClick}
              keyExtractor={keyExtractor}
            />
            {this.renderComparisonNotesModal(openTableRowsOptions.onCloseRow)}
          </>
        )}
      </OpenTableRows>
    )
  }
}

export default connect(stateMap)(withRouter(ProductComparisonsList))
