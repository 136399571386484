import React from 'react'
import { useHistory } from 'react-router-dom'
import * as R from 'ramda'

import ICharacterization from 'domains/characterizations/models/ICharacterization'
import { getCharacterizationRoute } from 'domains/characterizations/navigation/routes'
import { ICommonCartridgeResource } from 'domains/commonCartridge/models/ICommonCartridgeResource'
import CCCharacterizationReportRow from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportRow'
import {
  CCReportTypeIcon,
  CC_TYPE_RESOURCE,
} from 'domains/commonCartridge/constants/commonCartridge'
import useCCCharacterizationReportCrumbs, {
  ICCCrumb,
} from 'domains/commonCartridge/hooks/useCCCharacterizationReportCrumbs'

export const CC_RESOURCE_TITLE_NOT_SPECIFIED = '(No title specified)'

interface IProps {
  characterization: ICharacterization
  resource: ICommonCartridgeResource
}

export default function CCCharacterizationReportResourceRow(props: IProps) {
  const history = useHistory()
  const [crumbs] = useCCCharacterizationReportCrumbs()

  const onGoToResource = () => {
    const route = getCharacterizationRoute(
      props.characterization.id,
      props.characterization.format,
      ['resources', `${props.resource.id}`],
    )
    history.push(route, {
      crumbs: [
        ...crumbs,
        {
          id: props.resource.id,
          type: CC_TYPE_RESOURCE,
          title: props.resource.identifier || CC_RESOURCE_TITLE_NOT_SPECIFIED,
        } as ICCCrumb,
      ],
    })
  }

  const identifier = R.pathOr(
    '(identifier not specified)',
    ['identifier'],
    props.resource,
  )
  const id = R.pathOr(0, ['id'], props.resource)
  const type = R.pathOr('(type not specified)', ['type'], props.resource)

  return (
    <CCCharacterizationReportRow
      type="Resource"
      id={id}
      bg="ccReportResourceBG"
      icon={CCReportTypeIcon.Resource}
      title={identifier}
      subtitle={type}
      subtitleLabel="type"
      onClick={onGoToResource}
    />
  )
}
