import React from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import TurndownService from 'turndown'

import './WYSIWYGEditor.css'

interface IProps {
  name?: string
  value: string
  placeholder?: string
  onChange: (e: string) => any
  autoFocus?: boolean
}

export const htmlToMarkdown = (html: string) => {
  const turndown = new TurndownService()
  return turndown.turndown(html)
}

// Define a new clipboard module to handle pasting
const Clipboard = Quill.import('modules/clipboard')
const Delta = Quill.import('delta')

const Link = Quill.import('formats/link')

Link.sanitize = function (url: string) {
  // quill by default creates relative links if scheme is missing.
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `http://${url}`
  }
  return url
}

class PlainClipboard extends Clipboard {
  onPaste(e: any) {
    e.preventDefault()
    let html = e.clipboardData.getData('text/html')

    if (!html) {
      html = e.clipboardData.getData('text/plain')
    }

    let parser = new DOMParser()
    let doc = parser.parseFromString(html, 'text/html')

    let elements = doc.getElementsByTagName('*')
    for (let i = 0; i < elements.length; i++) {
      elements[i].removeAttribute('style')
    }
    let cleanedHtml = doc.body.innerHTML

    let range = this.quill.getSelection()
    let delta = new Delta().retain(range.index).delete(range.length)
    let insertDelta = this.convert(cleanedHtml)

    delta = delta.concat(insertDelta)
    this.quill.updateContents(delta, 'silent')
    this.quill.setSelection(range.index + insertDelta.length(), 'silent')
  }
}

// Register the new clipboard module
Quill.register('modules/clipboard', PlainClipboard, true)

export default function WYSIWYGEditor(props: IProps) {
  const ref = React.useRef<ReactQuill>(null)

  React.useEffect(() => {
    if (props.autoFocus) {
      setTimeout(() => {
        ref.current?.focus()
      }, 500)
    }
  }, [props.autoFocus, ref])

  return (
    <ReactQuill
      id={props.name}
      ref={ref as any}
      theme="snow"
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      modules={{
        clipboard: {
          matchVisual: true,
        },
      }}
    />
  )
}
