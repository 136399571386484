import React from 'react'

interface IProps {
  x: number
  y: number
  payload: any
}

export default function CustomizedAxisTick(props: IProps) {
  const { x, y, payload } = props
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {payload.value}
      </text>
    </g>
  )
}
