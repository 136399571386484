import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

import { createSelector } from 'reselect'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'
import { getProductFormatModelId } from 'domains/products/utils/productByModel'
import { RootState } from 'lib/store/rootReducer'
import IProduct from 'domains/products/models/IProduct'
import {
  AnyFormatType,
  ANY_FORMAT_TO_SPEC_FORMAT_MAP,
} from 'domains/formats/constants/formats'

export const productFormatModelIdSelector = (
  getProductId: records.entitiesSelectors.getIdType,
  getFormat: records.entitiesSelectors.getIdType,
) =>
  createSelector<RootState, any, IProduct, AnyFormatType, string | number>(
    records.entitiesSelectors.entityByIdSelector(PRODUCTS_RECORD, getProductId),
    records.entitiesSelectors.idSelector(getFormat),
    (product, format) => getProductFormatModelId(product, format),
  )

export const productFormatCharacterizationLinkSelector = (
  getProductId: records.entitiesSelectors.getIdType,
  getFormat: records.entitiesSelectors.getIdType,
) =>
  createSelector<RootState, any, IProduct, AnyFormatType, string | number>(
    records.entitiesSelectors.entityByIdSelector(PRODUCTS_RECORD, getProductId),
    records.entitiesSelectors.idSelector(getFormat),
    (product, format) =>
      R.propOr(
        '',
        `${ANY_FORMAT_TO_SPEC_FORMAT_MAP[format]}-characterizationLink`,
        product,
      ),
  )
