import React from 'react'
import clsx from 'clsx'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { utils } from '@ims/1edtech-frontend-common'

const debouncer = utils.debounce.makeDebounce(350)

export type InputProps = {
  name: string
  value: string
  placeholder?: string
  type?: string
  onChange: (event: React.ChangeEvent<any>) => any
  onFocus?: (event: React.ChangeEvent<any>) => any
  onBlur?: (event: React.ChangeEvent<any>) => any
  hasError?: boolean
  textArea?: boolean
  rows?: number
  rightContent?: any
  leftContent?: any
  className?: any
  debounce?: boolean
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref) => {
    const [value, setValue] = React.useState(props.value)
    React.useEffect(() => {
      setValue(props.value)
    }, [props.value])

    const onChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setValue(event.target.value)
      if (!props.debounce) {
        props.onChange(event)
        return
      }

      event.persist()
      debouncer.debounce(() => props.onChange(event))
    }
    const className = clsx(
      'py-2 pl-3 border focus:outline-none text-md rounded-sm',
      {
        'shadow-sm focus:ring-primary-300 focus:border-primary ring-offset-primary-300 block w-full border-placeholder pr-3':
          !props.hasError,
        'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500':
          props.hasError,
        'pl-10': !!props.leftContent,
        'pr-10':
          (props.hasError && !props.rightContent) ||
          (!!props.rightContent && !props.hasError),
        'pr-16': props.hasError && !!props.rightContent,
      },
      props.className,
    )
    const rightContentClassName = clsx('absolute inset-y-0 flex items-center', {
      'right-0': !props.hasError,
      'right-2': props.hasError,
    })
    return (
      <div
        className={clsx('flex flex-1', {
          relative:
            props.hasError || !!props.rightContent || !!props.leftContent,
          'rounded-sm shadow-sm': props.hasError,
        })}
      >
        {!props.textArea && (
          <input
            type={props.type || 'text'}
            name={props.name}
            value={value}
            onChange={onChange}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            id={props.name}
            className={className}
            placeholder={props.placeholder}
            aria-invalid={props.hasError}
            aria-describedby={`${props.name}-error`}
            ref={ref}
          />
        )}
        {props.textArea && (
          <textarea
            name={props.name}
            value={value}
            onChange={onChange}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            id={props.name}
            className={className}
            placeholder={props.placeholder}
            rows={props.rows || 4}
            aria-invalid={props.hasError}
            aria-describedby={`${props.name}-error`}
          />
        )}

        {!!props.leftContent && (
          <div className={clsx('absolute inset-y-0 flex items-center pl-3')}>
            {props.leftContent}
          </div>
        )}

        {props.hasError && (
          <div className={clsx(rightContentClassName, 'pointer-events-none')}>
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}

        {!!props.rightContent && (
          <div
            className={clsx(rightContentClassName, {
              'pr-2': !props.hasError,
              'pr-5': props.hasError,
            })}
          >
            {props.rightContent}
          </div>
        )}
      </div>
    )
  },
)

export default Input
