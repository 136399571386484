import React from 'react'
import IOrg from 'domains/orgs/models/IOrg'
import { Link } from 'react-router-dom'

import { Text } from 'lib/components'
import {
  getRoute,
  ORGANIZATIONS_ROUTE,
} from 'domains/application/navigation/routes'
import DistrictLogo from 'domains/orgs/components/DistrictLogo'

interface IProps extends IOrg {
  maxWidth: number | string | (string | number)[]
}

export default function OrganizationNameCell(props: IProps) {
  return (
    <div className="flex flex-row items-center">
      <div className="p-1">
        <DistrictLogo logoUrl={props.logourl} name={props.name} size={48} />
      </div>
      <div>
        <Link to={getRoute(ORGANIZATIONS_ROUTE, props.id)}>
          <Text
            textAlign="left"
            color="tertiary"
            // overflow="ellipsis"
            maxWidth={props.maxWidth}
            fontWeight={500}
            data-test={`org-link-${props.name}`}
          >
            {props.name}
          </Text>
        </Link>
      </div>
    </div>
  )
}
