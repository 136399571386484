import React from 'react'
import clsx from 'clsx'

import View, { IViewProps } from 'lib/components/View'

export default function Paper(props: IViewProps & { className?: any }) {
  return (
    <View
      variant="paper"
      className={clsx('paper', props.className)}
      {...props}
    />
  )
}
