import React from 'react'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import CircleSpinner from 'react-loader-spinner'

import colors from 'lib/styles/colors'

import View from './View'

const centeredProps = {
  width: '100%',
  height: '100%',
  minHeight: '150px',
}

type SpinnerProps = {
  color?: string
  size?: number
  centered?: boolean
}

export default function Spinner(props: SpinnerProps) {
  const { size = 42, color = 'primary', centered = false, ...rest } = props
  return (
    <View
      {...rest}
      {...(centered ? centeredProps : {})}
      flexible="column-center"
    >
      <CircleSpinner
        type="TailSpin"
        color={(colors as any)[color]}
        height={size}
        width={size}
      />
    </View>
  )
}
