import useSpecScreen from 'lib/hooks/useSpecScreen'
import {
  COMMON_CARTRIDGE_BASE_NAV_ROUTE,
  ONEROSTER_BASE_NAV_ROUTE,
} from 'domains/specifications/constants/specNavigation'
import CreateORCharacterizationScreen from 'domains/characterizations/screens/CreateORCharacterizationScreen'
import CreateCommonCartridgeCharacterizationScreen from 'domains/characterizations/screens/CreateCommonCartridgeCharacterizationScreen'

export default function CreateCharacterizationScreen(props: any) {
  return useSpecScreen(props, {
    [ONEROSTER_BASE_NAV_ROUTE]: CreateORCharacterizationScreen,
    [COMMON_CARTRIDGE_BASE_NAV_ROUTE]: CreateCommonCartridgeCharacterizationScreen,
  })
}
