import React from 'react'
import { useSelector } from 'react-redux'
import { utils, records } from '@ims/1edtech-frontend-common'

import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import OrgDetailItem from 'domains/orgs/components/OrgDetailItem'
import { ORGANIZATIONS_RECORD } from 'lib/records/modules/organizations'
import IOrg, { OrgCertificationLevelToDisplay } from 'domains/orgs/models/IOrg'
import OrganizationLockoutDateCell from 'domains/orgs/components/OrganizationLockoutDateCell'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import OrganizationPPList from 'domains/orgs/components/OrganizationPPList'
import { Switch } from 'lib/components'
import { setOrgLockoutStatus } from 'domains/orgs/workflows/setOrgLockoutStatus'
import { showToast } from 'lib/utils/toast'

interface IProps {
  orgId: number
  height: number
}

export default function OrgSubscriptionWidget(props: IProps) {
  const orgData = useSelector(
    records.entitiesSelectors.entityByIdSelector(
      ORGANIZATIONS_RECORD,
      props.orgId,
    ),
  )

  if (!orgData) {
    return null
  }

  const org = orgData as IOrg
  const isDistrict = org.type === 'District'

  const onToggleLockout = async () => {
    const lockout = org.manuallyLockedOut ? false : true
    const success = await setOrgLockoutStatus(org.id, lockout)
    if (!success) {
      showToast(
        'error',
        `Failed to ${
          lockout ? 'lockout' : 'unlock'
        } organization. Please try again.`,
      )
    } else {
      showToast(
        'start',
        `Successfully ${lockout ? 'locked out' : 'unlocked'} organization.`,
      )
    }
  }

  return (
    <DetailsTopWidget
      title="Subscription Information"
      dataTest="org-subscription-widget"
      height={props.height}
      noScroll={true}
    >
      <div className="mt-4 w-full flex flex-col flex-1">
        <div className="w-full flex flex-row justify-between items-start">
          <OrgDetailItem
            label="Certification Level"
            labelDataTest="org-certification-level-label"
            value={OrgCertificationLevelToDisplay[org.certificationLevel]}
            valueDataTest="org-certification-level"
          />
        </div>

        <OrgDetailItem
          label="Lockout Date"
          labelDataTest="org-lockout-date-label"
          value={<OrganizationLockoutDateCell {...org} nonList={true} />}
          valueDataTest="org-lockout-date"
        />

        <OrgDetailItem
          label="Referring Supplier"
          labelDataTest="org-referrer-label"
          value={isDistrict ? org.referrerName || 'N/A' : null}
          valueDataTest="org-referrer"
        />
        {isDistrict && (
          <div className="mt-3">
            <WidgetHeader title="Lockout Org" dataTest="org-lockout-label" />
            <div className="mt-3" />
            <p className="text-caption text-xs mb-2">
              {org.manuallyLockedOut
                ? 'Currently the organization is locked out. This means users cannot login or LTI launch into TrustEd Apps.'
                : 'This organization is not currently locked out.'}
            </p>
            <Switch
              onChange={onToggleLockout}
              on={!!org.manuallyLockedOut}
              onIconName="fas fa-lock"
              offIconName="fas fa-lock-open"
            />
          </div>
        )}
      </div>

      {utils.hasValue(org.preferrers) && (
        <div className="mt-6 w-full flex flex-col flex-1">
          <WidgetHeader
            title="Uses Preferred Partner(s)"
            dataTest="org-pp-widget"
          />
          <div
            className="overflow-y-auto"
            style={{ height: props.height * 0.58 }}
          >
            <OrganizationPPList org={org} />
          </div>
        </div>
      )}
    </DetailsTopWidget>
  )
}
