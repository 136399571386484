import React from 'react'
import * as R from 'ramda'
import * as Yup from 'yup'
import { Formik, FormikHelpers, FormikProps } from 'formik'

import { utils } from '@ims/1edtech-frontend-common'
import ISource from 'domains/configuration/models/ISource'
import {
  ModalWrapper,
  FormInput,
  Switch,
  InputLabel,
  Select,
} from 'lib/components'
import { updateRecord } from 'lib/records/workflows/updateRecord'
import { SOURCES_RECORD } from 'lib/records/modules/sources'
import { createRecord } from 'lib/records/workflows/createRecord'
import { getRecords } from 'lib/records/workflows/getRecords'
import { showToast } from 'lib/utils/toast'
import { OrgCertificationLevelToDisplay } from 'domains/orgs/models/IOrg'

const validationSchema = Yup.object().shape({
  sourceName: Yup.string().required('Required'),
  supplierName: Yup.string().required('Required'),
  isMember: Yup.boolean().required('Required'),
  certificationLevel: Yup.string().required('Required'),
})

interface IProps {
  editSource?: ISource | null
  isOpen: boolean
  close: () => any
}

export default function CreateEditSourceModal(props: IProps) {
  const onSave = async (values: ISource, bag: FormikHelpers<ISource>) => {
    const isEdit = utils.hasValue(props.editSource)
    try {
      if (isEdit) {
        await updateRecord(SOURCES_RECORD, props.editSource!.id, values)
      } else {
        await createRecord(SOURCES_RECORD, R.dissoc('id', values))
      }

      await getRecords({ record: SOURCES_RECORD, force: true })
      bag.setSubmitting(false)
      bag.resetForm()
      props.close()
    } catch (error) {
      bag.setSubmitting(false)
      showToast('error', `Failed to ${isEdit ? 'edit' : 'add'} source`)
    }
  }

  return (
    <Formik
      initialValues={
        utils.hasValue(props.editSource)
          ? props.editSource!
          : {
              id: 0,
              sourceName: '',
              supplierName: '',
              isMember: false,
              certificationLevel: 'UNCERTIFIED',
            }
      }
      onSubmit={onSave}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {(bag: FormikProps<ISource>) => {
        const onToggleIsMember = () => {
          bag.setFieldValue('isMember', !bag.values.isMember)
        }
        return (
          <ModalWrapper
            isOpen={props.isOpen}
            title={
              utils.hasValue(props.editSource) ? 'Edit Source' : 'New Source'
            }
            actions={[
              {
                text: 'Save',
                variant: 'start',
                onClick: bag.submitForm,
                extra: {
                  type: 'submit',
                },
              },
              {
                text: 'Cancel',
                variant: 'neutral',
                onClick: () => {
                  bag.resetForm()
                  props.close()
                },
                extra: {
                  type: 'button',
                },
              },
            ]}
            pending={bag.isSubmitting}
          >
            <FormInput
              label="Name"
              name="sourceName"
              value={bag.values.sourceName}
              valueDataTest="name-input"
              placeholder="Source Name"
              handleChange={bag.handleChange}
            />
            <FormInput
              label="Supplier"
              name="supplierName"
              value={bag.values.supplierName}
              valueDataTest="supplier-name-input"
              placeholder="Supplier Name"
              handleChange={bag.handleChange}
            />
            <div className="flex flex-row items-center space-x-8">
              <div className="flex flex-col">
                <div className="mb-7">
                  <InputLabel>Is Member</InputLabel>
                </div>
                <Switch
                  on={bag.values.isMember}
                  onChange={onToggleIsMember}
                  dataTest="is-member-toggle"
                  onIconName="fas fa-user"
                  offIconName="fas fa-user-slash"
                />
              </div>

              <div className="flex flex-col flex-1">
                <InputLabel>Certification Level</InputLabel>
                <Select
                  name="certificationLevel"
                  variant="bold"
                  value={bag.values.certificationLevel}
                  onChange={bag.handleChange}
                  width="100%"
                  mt={3}
                  data-test="source-certificationLevel-selector"
                >
                  <option value="UNCERTIFIED">
                    {OrgCertificationLevelToDisplay.UNCERTIFIED}
                  </option>
                  <option value="LEVEL_1">
                    {OrgCertificationLevelToDisplay.LEVEL_1}
                  </option>
                  <option value="LEVEL_2">
                    {OrgCertificationLevelToDisplay.LEVEL_2}
                  </option>
                  <option value="LEVEL_3">
                    {OrgCertificationLevelToDisplay.LEVEL_3}
                  </option>
                </Select>
              </div>
            </div>
          </ModalWrapper>
        )
      }}
    </Formik>
  )
}
