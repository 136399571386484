import React from 'react'
import * as R from 'ramda'

import { View, Text } from 'lib/components'
import { utils } from '@ims/1edtech-frontend-common'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'

const isPrint = isOnPrintScreen()

export interface IWidgetLineItemProps {
  label: string
  value: string | any
  width?: number
  maxWidth?: string | number | (string | number)[]
  'data-test'?: string
  wordBreak?: string
  vCenter?: boolean
}

export default function WidgetLineItem(props: IWidgetLineItemProps) {
  const { width = isPrint ? 96 : 128, maxWidth = '50%' } = props
  const fontSize = isPrint ? 10 : undefined

  return (
    <View
      my={isPrint ? 0 : 1}
      flexible={props.vCenter ? 'row-v-center' : 'row'}
      minHeight={16}
    >
      <View minWidth={width} width={width}>
        <Text fontWeight={700} fontSize={fontSize}>
          {utils.hasValue(props.label) ? `${props.label}:` : ''}
        </Text>
      </View>
      {R.is(String, props.value) && (
        <Text
          wordBreak={props.wordBreak || 'break-all'}
          maxWidth={maxWidth}
          data-test={props['data-test']}
          fontSize={fontSize}
        >
          {props.value}
        </Text>
      )}
      {!R.is(String, props.value) && (
        <View
          maxWidth={maxWidth}
          data-test={props['data-test']}
          fontSize={fontSize}
        >
          {props.value}
        </View>
      )}
    </View>
  )
}
