import React from 'react'
import { utils } from '@ims/1edtech-frontend-common'

import ICharacterization from 'domains/characterizations/models/ICharacterization'
import { View } from 'lib/components'
import { displaySpecVersion } from 'domains/products/utils/products'
import {
  AnySpecFormatType,
  FORMAT_TO_DISPLAY_FORMAT_BASE_NAME,
  OR_FORMAT_TO_SPEC_SUBTYPE,
} from 'domains/formats/constants/formats'
import SearchMenuLineItem from 'lib/components/SearchMenuLineItem'

export default function CharacterizationMenuItem(props: ICharacterization) {
  return (
    <View
      flexible="row-space-between"
      p={3}
      hover="clickable-bg"
      data-test={`searched-characterization-${props.id}`}
    >
      <SearchMenuLineItem options={{ value: props.name, width: [172, 200] }} />
      <SearchMenuLineItem
        options={{
          value: OR_FORMAT_TO_SPEC_SUBTYPE[props.format as AnySpecFormatType],
          width: 80,
        }}
      />
      <SearchMenuLineItem
        options={{
          value: displaySpecVersion(props.specificationVersion),
          width: 32,
        }}
      />
      <SearchMenuLineItem
        options={{
          value: FORMAT_TO_DISPLAY_FORMAT_BASE_NAME[props.format],
          width: 48,
        }}
      />
      <SearchMenuLineItem
        options={{
          value: utils.date.formatForDisplay(props.runDate),
          width: 140,
        }}
      />
    </View>
  )
}
