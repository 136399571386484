import {
  EVENT_BUTTON_TEXT,
  EVENT_HEADER_IMAGE_URL,
  EVENT_HEADER_LINK_URL,
} from 'domains/application/models/configuration'

export const SET_APPLICATION_PROPERTIES =
  '@@application/SET_APPLICATION_PROPERTIES'

interface ISetApplicationProperties {
  type: typeof SET_APPLICATION_PROPERTIES
  payload: Record<string, unknown>
}
export const setApplicationProperties = (
  properties: Record<string, unknown>,
): ISetApplicationProperties => ({
  type: SET_APPLICATION_PROPERTIES,
  payload: properties,
})

export type ApplicationActions = ISetApplicationProperties

export interface IApplicationState {
  initialized: boolean
  sendToDashboard: boolean
  me: number
  role: 'SUPPLIER' | 'DISTRICT' | ''
  loggedOut: false
  lockedOut?: 'freemiumLoginDenied' | 'orgLoginDenied' | 'membershipExpired'
  oneRosterCharacterizationFormatIds: number[]
  commonCartridgeCharacterizationFormatIds: number[]
  [EVENT_HEADER_IMAGE_URL]: string
  [EVENT_HEADER_LINK_URL]: string
  [EVENT_BUTTON_TEXT]: string
}

const initialState: IApplicationState = {
  initialized: false,
  sendToDashboard: false,
  me: 0,
  role: '',
  loggedOut: false,
  oneRosterCharacterizationFormatIds: [],
  commonCartridgeCharacterizationFormatIds: [],
  [EVENT_HEADER_IMAGE_URL]: '',
  [EVENT_HEADER_LINK_URL]: '',
  [EVENT_BUTTON_TEXT]: '',
}

export default function application(
  state: IApplicationState = initialState,
  action: ApplicationActions,
) {
  switch (action.type) {
    case SET_APPLICATION_PROPERTIES:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
