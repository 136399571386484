import React from 'react'
import * as R from 'ramda'

import VettingSectionHeader from 'domains/trustedApps/components/TrustedAppVettings/VettingSectionHeader'
import IIMSApplicationVetting from 'domains/trustedApps/models/IIMSApplicationVetting'
import { utils } from '@ims/1edtech-frontend-common'
import BlankSlate from 'lib/components/BlankSlate'

type PolicyProps = {
  label: string
  url: string
}

function Policy(props: PolicyProps) {
  return (
    <div>
      <p className="font-bold text-lg">{props.label}</p>
      <a
        className="text-base text-complete break-all"
        href={props.url}
        target="_blank"
        rel="noopener noreferrer"
        data-test={`policy-${props.label}-url`}
      >
        {props.url}
      </a>
    </div>
  )
}

type TrustedAppVettingPoliciesCitedProps = {
  vetting: IIMSApplicationVetting
}

export default function TrustedAppVettingPoliciesCited(
  props: TrustedAppVettingPoliciesCitedProps,
) {
  const urls = [
    {
      label: 'Accessibility Statement',
      url: R.pathOr('', ['accessibilityUrl'], props.vetting),
    },
    {
      label: 'Terms of Service/Terms of Use',
      url: R.pathOr('', ['tosUrl'], props.vetting),
    },
    {
      label: 'Privacy Policy',
      url: R.pathOr('', ['privacyUrl'], props.vetting),
    },
    {
      label: 'Cookie Policy',
      url: R.pathOr('', ['cookieUrl'], props.vetting),
    },
    {
      label: 'Security Practices',
      url: R.pathOr('', ['securityUrl'], props.vetting),
    },
  ].filter((u) => utils.hasValue(u.url))

  return (
    <div className="w-full">
      <VettingSectionHeader
        title="Policies Cited"
        subtitle="The following urls were cited as a basis for this information"
      />

      <div className="space-y-1">
        {!utils.hasValue(urls) && <BlankSlate title="No Policies" />}
        {urls.map((url) => (
          <Policy key={`url-${url.label}`} label={url.label} url={url.url} />
        ))}
      </div>
    </div>
  )
}
