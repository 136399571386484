import * as R from 'ramda'
import ICommonCartridgeCharacterizationReport, {
  ICommonCartridgeCharacterizationReportValidation,
} from 'domains/commonCartridge/models/ICommonCartridgeCharacterizationReport'
import { utils } from '@ims/1edtech-frontend-common'

const getValidations = (report: ICommonCartridgeCharacterizationReport) =>
  R.pathOr(
    [],
    ['validations'],
    report,
  ) as ICommonCartridgeCharacterizationReportValidation[]

export const doesCCReportHaveErrors = (
  report: ICommonCartridgeCharacterizationReport,
): boolean => {
  const validations = getValidations(report)
  const validationsWithErrors = validations.filter((val) =>
    utils.hasValue(val.errors),
  )
  return validationsWithErrors.length > 0
}

export const getValidationsWithErrorsOrWarnings = (
  report: ICommonCartridgeCharacterizationReport,
): ICommonCartridgeCharacterizationReportValidation[] => {
  const validations = getValidations(report)
  return validations.filter(
    (val) => utils.hasValue(val.errors) || utils.hasValue(val.warnings),
  )
}
