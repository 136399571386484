import React, { PureComponent } from 'react'
import { utils } from '@ims/1edtech-frontend-common'

import { View, Text, Icon } from 'lib/components'
import IProduct from 'domains/products/models/IProduct'

export default class DashboardRecentProductModifiedCell extends PureComponent<IProduct> {
  render() {
    const { dateLastModified } = this.props

    return (
      <View flexible="row-v-center">
        <Icon className="fas fa-clock" />
        <Text pl={2} textAlign="left" fontSize={13}>
          {utils.date.formatForDisplay(dateLastModified)}
        </Text>
      </View>
    )
  }
}
