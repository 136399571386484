import React from 'react'
import { utils } from '@ims/1edtech-frontend-common'

import ICharacterization from 'domains/characterizations/models/ICharacterization'
import { View } from 'lib/components'
import WidgetLineItem from 'domains/reports/components/WidgetLineItem'
import { getCharacterizationZipFileName } from 'domains/characterizations/utils/characterization'
import ICommonCartridgeCharacterizationReport from 'domains/commonCartridge/models/ICommonCartridgeCharacterizationReport'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'

interface IProps {
  characterization: ICharacterization
  report: ICommonCartridgeCharacterizationReport
  height: number
}

export default function CCCharcterizationReportDetailsWidget(props: IProps) {
  const renderLineItem = (label: string, value: any) => (
    <WidgetLineItem
      label={label}
      value={value}
      maxWidth={['60%', '60%', '80%']}
      data-test={`report-details-line-${label}`}
    />
  )

  return (
    <DetailsTopWidget
      title="Report Details"
      dataTest="characterization-other-widget"
      height={props.height}
    >
      <View flex={1} flexible="column-v-center">
        {renderLineItem(
          'File Name',
          getCharacterizationZipFileName(props.report.zipFile!),
        )}
        {renderLineItem('Source', props.characterization.source)}
        {renderLineItem(
          'Run Date/Time',
          utils.date.formatForDisplay(props.characterization.runDate),
        )}
        {renderLineItem('Specification', props.characterization.format)}
      </View>
    </DetailsTopWidget>
  )
}
