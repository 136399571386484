import { putRequest } from 'lib/api/api'
import {
  IProductStatuses,
  PUBLISHED_PRODUCT_STATUS,
} from 'domains/products/constants/products'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'
import trackProductAnalytics from 'domains/products/utils/trackProductAnalytics'
import { getRecords } from 'lib/records/workflows/getRecords'
import store from 'lib/store'
import { PRODUCT_COMPARISONS_RECORD } from 'lib/records/modules/productComparisons'
import {
  AnyFormatType,
  ANY_FORMAT_TO_SPEC_FORMAT_MAP,
} from 'domains/formats/constants/formats'
import { formatIdByNameSelector } from 'domains/specifications/selectors/formatSelectors'
import { getRecord } from 'lib/records/workflows/getRecord'

export const changeProductStatus = async (
  id: string | number,
  status: IProductStatuses,
  format: AnyFormatType,
) => {
  const specFormat = ANY_FORMAT_TO_SPEC_FORMAT_MAP[format]
  const formatId = formatIdByNameSelector(specFormat)(store.getState())
  await putRequest(
    `products/${id}/${
      status === PUBLISHED_PRODUCT_STATUS ? 'publish' : 'private'
    }/${formatId}`,
  )
  await getRecord(PRODUCTS_RECORD, id)
  await getRecords({
    record: PRODUCT_COMPARISONS_RECORD,
    parentRecordId: id,
    getCurrentPage: true,
    replaceItems: true,
  })
  trackProductAnalytics('changed_status', { status })
}
