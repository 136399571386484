import React from 'react'
import * as R from 'ramda'
import { useSelector } from 'react-redux'
import { utils, records } from '@ims/1edtech-frontend-common'

import {
  Span,
  Input,
  Select,
  View,
  FormInput,
  FormErrorMessage,
  InputLabel,
} from 'lib/components'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import { OTHER_SOURCE } from 'domains/characterizations/constants/createCharacterizationCSV'
import { getRecords } from 'lib/records/workflows/getRecords'
import { SOURCES_RECORD } from 'lib/records/modules/sources'
import { RootState } from 'lib/store/rootReducer'
import { isSupplierRoleSelector } from 'domains/authentication/selectors/roles'
import countries from 'domains/application/constants/countries.json'
import IProduct from 'domains/products/models/IProduct'
// import { formatIdByNameSelector } from 'domains/specifications/selectors/formatSelectors'
import {
  AnySpecFormatType,
  ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK,
  ONER_V1P1_CSV_RESOURCES_EXPORT_BULK,
  ONER_V1P1_CSV_ROSTERING_EXPORT_BULK,
  ONER_V1P2_CSV_ROSTERING_EXPORT_BULK,
  ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL,
} from 'domains/formats/constants/formats'
// import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
// import { getRequest } from 'lib/api/api'

interface IProps {
  format?: AnySpecFormatType | 'Select One'
  source: string
  otherSource: string
  sourceVersion?: string
  districtName?: string
  districtState?: string
  districtCountry?: string
  notes?: string
  handleChange: (event: any) => void
  setFieldValue: any
  height: number
}

export default function CreateORCharacterizationSourceDetails(props: IProps) {
  const isSupplier = useSelector(isSupplierRoleSelector)
  const sources = useSelector((state: RootState) =>
    records.recordsSelectors.fullRecordsSelector(SOURCES_RECORD)(state, props),
  )
  // const orgId = useSelector((s: RootState) =>
  //   myOrganizationIdSelector(s, props),
  // )
  // const ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK_FORMAT_ID = useSelector(
  //   formatIdByNameSelector(ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK),
  // )
  // const ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL_FORMAT_ID = useSelector(
  //   formatIdByNameSelector(ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL),
  // )
  // const [gbCSVProducts, setGBCSVProducts] = React.useState<IProduct[]>([])
  // const [gbRESTProducts, setGBRESTProducts] = React.useState<IProduct[]>([])
  const [gbCSVProducts] = React.useState<IProduct[]>([])
  const [gbRESTProducts] = React.useState<IProduct[]>([])

  React.useEffect(() => {
    const fetch = async () => {
      await getRecords({ record: SOURCES_RECORD, force: true })
      // const filter = `orgId=${orgId}`
      // const csvGBProductsResponse = await getRequest(
      //   `formats/${ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK_FORMAT_ID}/products`,
      //   { filter, sort: 'name:ascending', limit: 250 },
      // )
      // setGBCSVProducts(
      //   R.pathOr<IProduct[]>([], ['data', 'products'], csvGBProductsResponse),
      // )
      // const restGBProductsResponse = await getRequest(
      //   `formats/${ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL_FORMAT_ID}/products`,
      //   { filter, sort: 'name:ascending', limit: 250 },
      // )
      // setGBRESTProducts(
      //   R.pathOr<IProduct[]>([], ['data', 'products'], restGBProductsResponse),
      // )
    }
    // TODO: Not currently importing GB products.
    // This will fetch them if we ever start importing them
    fetch()
  }, [])

  const { source, otherSource, handleChange, height } = props
  const defaultSources = [
    { sourceName: 'Infinite Campus', supplierName: '' },
    { sourceName: 'Skyward', supplierName: '' },
    { sourceName: 'Uchida', supplierName: '' },
    { sourceName: 'Classlink', supplierName: '' },
    { sourceName: 'Clever', supplierName: '' },
    { sourceName: 'Gwinnet', supplierName: '' },
    { sourceName: 'Houston', supplierName: '' },
  ]
  const rosteringSources = utils.hasValue(R.prop('items', sources))
    ? R.pathOr([], ['items'], sources)
    : defaultSources
  let sourceItems: any[] = []
  switch (props.format) {
    case ONER_V1P1_CSV_ROSTERING_EXPORT_BULK:
    case ONER_V1P2_CSV_ROSTERING_EXPORT_BULK:
    case ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL:
    case ONER_V1P1_CSV_RESOURCES_EXPORT_BULK:
    case ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL:
      sourceItems = rosteringSources
      break
    case ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK:
      sourceItems = gbCSVProducts.map((product) => ({
        sourceName: product.name,
        supplierName: '',
      }))
      break
    case ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL:
      sourceItems = gbRESTProducts.map((product) => ({
        sourceName: product.name,
        supplierName: '',
      }))
      break
    default:
      break
  }
  sourceItems.splice(
    0,
    0,

    { sourceName: '', supplierName: '' },
  )
  sourceItems.push({
    value: OTHER_SOURCE,
    sourceName: 'Other',
    supplierName: '',
  })
  const isGradebook =
    props.format === ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK ||
    props.format === ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL ||
    props.format === ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL

  const onSourceSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selection = e.target.value
    if (selection === OTHER_SOURCE) {
      props.setFieldValue('certifiedSourceLevel', 'UNCERTIFIED')
    } else {
      const item = sourceItems.find(R.propEq('sourceName', selection))
      props.setFieldValue(
        'certifiedSourceLevel',
        R.pathOr('UNCERTIFIED', ['certificationLevel'], item),
      )
    }
    props.setFieldValue('source', selection)
  }
  return (
    <DetailsTopWidget
      title="Information"
      dataTest="characterization-information-widget"
      height={height}
    >
      <View
        pt={3}
        width="100%"
        height="94%"
        flexible="column-space-between"
        flex={1}
      >
        <View width="100%">
          {isSupplier && (
            <View>
              <FormInput
                label="District Name"
                required={true}
                name="districtName"
                placeholder="Distrct"
                value={props.districtName || ''}
                valueDataTest="district-name-input"
                handleChange={handleChange}
              />

              <View
                width="100%"
                mt={2}
                display="grid"
                gridTemplateColumns={['auto', '1fr 1fr']}
                gridColumnGap={[0, 3]}
              >
                <FormInput
                  label="State"
                  required={false}
                  name="districtState"
                  placeholder="State"
                  value={props.districtState || ''}
                  valueDataTest="district-state-input"
                  handleChange={handleChange}
                />
                <View>
                  <InputLabel data-test="country-label">Country</InputLabel>
                  <Select
                    name="districtCountry"
                    value={props.districtCountry || ''}
                    onChange={handleChange}
                    variant="bold"
                    mb={2}
                    width="100%"
                    data-test="country-select"
                    aria-label="Country"
                  >
                    {Object.keys(countries).map((countryCode, index) => (
                      <option key={`country-${index}`} value={countryCode}>
                        {utils.string.joinString([
                          countryCode,
                          R.pathOr('', [countryCode], countries),
                        ])}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage name="country" />
                </View>
              </View>
            </View>
          )}

          <View
            width="100%"
            mt={2}
            display="grid"
            gridTemplateColumns={isSupplier ? ['auto', '2fr 1fr'] : 'auto'}
            gridColumnGap={isSupplier ? [0, 3] : 0}
          >
            {!isGradebook && (
              <View>
                <InputLabel data-test="source-label">
                  Source<Span color="error">*</Span>
                </InputLabel>
                <Select
                  name="source"
                  value={source}
                  onChange={onSourceSelected}
                  variant="bold"
                  mb={2}
                  width="100%"
                  data-test="source-select"
                  aria-label="Characterization Source"
                >
                  {sourceItems.map((sourceItem, index) => (
                    <option
                      key={`sourceItem-${index}`}
                      value={R.pathOr(
                        sourceItem.sourceName,
                        ['value'],
                        sourceItem,
                      )}
                    >
                      {utils.hasValue(sourceItem.sourceName)
                        ? utils.string.joinString([
                            sourceItem.sourceName,
                            sourceItem.supplierName,
                          ])
                        : 'Select One'}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage name="source" />
              </View>
            )}
            {isGradebook && (
              <FormInput
                label="Source"
                required={true}
                name="source"
                placeholder="Source"
                value={source}
                valueDataTest="source-input"
                handleChange={handleChange}
              />
            )}

            {isSupplier && (
              <FormInput
                label="Version"
                required={true}
                name="sourceVersion"
                placeholder="1.1"
                value={props.sourceVersion || ''}
                valueDataTest="source-version-input"
                handleChange={handleChange}
              />
            )}
          </View>

          {!isGradebook && source === OTHER_SOURCE && (
            <View>
              <Input
                type="text"
                name="otherSource"
                onChange={handleChange}
                value={otherSource}
                placeholder="Other*"
                aria-label="Characterization Other Source"
              />
              <FormErrorMessage name="otherSource" />
            </View>
          )}
        </View>

        <View mt={2} width="100%">
          <FormInput
            label="Characterization Notes"
            name="notes"
            handleChange={props.handleChange}
            value={props.notes || ''}
            valueDataTest="characterization-notes"
            placeholder="Notes..."
            aria-label="Characterization Notes"
            textArea={true}
            inputProps={{
              width: '100%',
              rows: 6,
            }}
          />
        </View>
        <View flex={1} />
      </View>
    </DetailsTopWidget>
  )
}
