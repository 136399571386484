import { HTMLAttributes } from 'react'
import styled from 'styled-components'
import * as SS from 'styled-system'
import * as CSS from 'csstype'

import colors from 'lib/styles/colors'
import { flexibleStyle } from 'lib/components/View'
import { focusShadow } from 'lib/components/Button'

export const variantStyle = SS.variant({
  key: 'inputStyles',
})

export const inputStyles = {
  bordered: {
    border: `1px solid ${colors.placeholder}`,
  },
  disabled: {
    backgroundColor: colors['ta-gray-1'],
    color: colors['ta-gray-3'],
    cursor: 'not-allowed',
  },
  underlined: {
    border: 'none',
    borderRadius: 0,
    borderBottom: `2px solid ${colors.placeholder}`,
    padding: '5px 0px',
    '&:focus': {
      outline: 0,
      boxShadow: 'none',
      borderBottom: `2px solid ${colors.primary}`,
    },
  },
}
export const inputDefaultStyles = `
  ::placeholder {
    color: ${colors.placeholder};
    opacity: 1;
  }
`

export interface IHtmlInputElementProps // eslint-disable-line
  extends HTMLAttributes<HTMLInputElement> {}

export interface IInputProps
  extends IHtmlInputElementProps,
    SS.AlignContentProps,
    SS.AlignItemsProps,
    SS.AlignSelfProps,
    SS.BorderColorProps,
    SS.BottomProps,
    SS.ColorProps,
    SS.DisplayProps,
    SS.FlexBasisProps,
    SS.FlexDirectionProps,
    SS.FlexProps,
    SS.FlexWrapProps,
    SS.FontSizeProps,
    SS.FontStyleProps,
    SS.FontWeightProps,
    SS.GridAutoFlowProps,
    SS.GridColumnGapProps,
    SS.GridRowGapProps,
    SS.GridTemplateColumnsProps,
    SS.GridTemplateRowsProps,
    SS.HeightProps,
    SS.JustifyContentProps,
    SS.JustifySelfProps,
    SS.LeftProps,
    SS.LineHeightProps,
    SS.MaxHeightProps,
    SS.MaxWidthProps,
    SS.MinHeightProps,
    SS.MinWidthProps,
    SS.OpacityProps,
    SS.OrderProps,
    SS.OverflowProps,
    SS.PositionProps,
    SS.RightProps,
    SS.SpaceProps,
    SS.TextAlignProps,
    SS.TopProps,
    SS.VerticalAlignProps,
    SS.WidthProps,
    SS.ZIndexProps {
  color?: CSS.Property.Color
  variant?: string
  boxSizing?: string
  'data-test'?: string
}

// prettier-ignore
const Input = styled.input<IInputProps>`
  background-color: #fff;
  border-radius: 3px;
  color: ${colors.text};
  font-size: 14px;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  ${variantStyle}
  ${flexibleStyle}
  ${focusShadow}
  ${inputDefaultStyles}

  ${SS.alignContent}
  ${SS.alignItems}
  ${SS.alignSelf}
  ${SS.bottom}
  ${SS.color}
  ${SS.display}
  ${SS.flexBasis}
  ${SS.flexDirection}
  ${SS.flexWrap}
  ${SS.flex}
  ${SS.fontSize}
  ${SS.fontWeight}
  ${SS.height}
  ${SS.justifyContent}
  ${SS.justifySelf}
  ${SS.left}
  ${SS.lineHeight}
  ${SS.maxHeight}
  ${SS.maxWidth}
  ${SS.minHeight}
  ${SS.minWidth}
  ${SS.order}
  ${SS.position}
  ${SS.right}
  ${SS.space}
  ${SS.top}
  ${SS.width}
  ${SS.zIndex}
`

Input.displayName = 'Input'
Input.defaultProps = { variant: 'bordered' }
export default Input
