import React from 'react'

import { Image, Text, View } from 'lib/components'
import { APPLICATION_NAME } from 'domains/application/constants/application'
import Footer from 'domains/application/components/Footer'

type LoggedOutProps = {
  children: any
}

export default function LoggedOut(props: LoggedOutProps) {
  return (
    <View flexible="column-center" flex={1} bg="background">
      <View
        variant="paper"
        minWidth={['100vw', '450px']}
        maxWidth={['100vw', '450px']}
        minHeight={['100vh', '620px']}
        boxSizing="border-box"
        flexible="column"
        pt="mg"
        px={[4, 5, '72px']}
        pb={4}
      >
        <View flexible="column-h-center">
          <Image
            src="/images/1EdTechLogo.svg"
            alt="1EdTech Logo"
            width={128}
            height={128}
          />
          <Text
            textAlign="center"
            fontSize={32}
            fontWeight={700}
            lineHeight="44px"
          >
            {APPLICATION_NAME}
          </Text>
        </View>

        {props.children}
      </View>
      <Footer />
    </View>
  )
}
