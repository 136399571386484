import React from 'react'
import * as R from 'ramda'
import { Link } from 'react-router-dom'
import { utils } from '@ims/1edtech-frontend-common'

import { Button, Icon } from 'lib/components'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import { getCharacterizationRoute } from 'domains/characterizations/navigation/routes'
import { getProductModelCharacterizationRoute } from 'domains/products/navigation/routes'
import {
  RED_REPORT_STATUS,
  AMBER_REPORT_STATUS,
} from 'domains/reports/models/IReportStatuses'
import IProduct from 'domains/products/models/IProduct'
import { AnySpecFormatType } from 'domains/formats/constants/formats'
import { getProductFormatModelId } from 'domains/products/utils/productByModel'

type CharacterizationButtonProps = {
  characterization?: ICharacterization
  date?: number
  product?: IProduct
  format?: AnySpecFormatType
  modelHasAlerts?: boolean
  modelHasCriticals?: boolean
  modelHasMetadata?: boolean
  disabled?: boolean
}

export default function CharacterizationButton(
  props: CharacterizationButtonProps,
) {
  const { date, characterization, product, format, disabled } = props
  const status = R.path(['status'], characterization)
  if ((!status && !product) || (!date && !characterization)) {
    return null
  }

  function getBadgeType() {
    const { modelHasCriticals, modelHasAlerts, modelHasMetadata } = props
    if (
      (characterization && characterization.status === RED_REPORT_STATUS) ||
      modelHasCriticals
    ) {
      return 'error'
    }
    if (
      (characterization && characterization.status === AMBER_REPORT_STATUS) ||
      modelHasAlerts ||
      modelHasMetadata
    ) {
      return 'warning'
    }

    return 'success'
  }

  function getBadgeIcon() {
    if (characterization && characterization.status === RED_REPORT_STATUS) {
      return <Icon color="white" className={'fas fa-exclamation'} />
    }

    return <Icon color="white" className={'fas fa-check'} />
  }

  const route = product
    ? getProductModelCharacterizationRoute(
        product.id,
        getProductFormatModelId(product, format!),
      )
    : getCharacterizationRoute(
        characterization!.id,
        format || characterization!.format,
      )

  const displayDate = utils.date.formatForDisplay(
    date || characterization!.runDate,
  )
  const button = (
    <Button
      variant={disabled ? 'disabled' : 'complete'}
      disabled={disabled}
      badgeType={getBadgeType()}
      badgeContent={getBadgeIcon()}
      aria-label={`Go To Characterization. Run date: ${displayDate}`}
    >
      {displayDate}
    </Button>
  )
  if (disabled) {
    return button
  }
  return (
    <Link
      to={route}
      data-test={`characterization-btn-link-${
        characterization ? characterization!.id : ''
      }`}
    >
      {button}
    </Link>
  )
}
