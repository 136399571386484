import React, { PureComponent } from 'react'

import Icon from 'lib/components/Icon'
import Text from 'lib/components/Text'
import View from 'lib/components/View'
import Spinner from 'lib/components/Spinner'

export interface IMenuItem {
  name: string
  icon?: string
  dataTest?: string
}
interface IProps {
  component?: any
  onClick: (item: any) => void
  items: any[]
  itemData?: any
  loadingMore?: boolean
}

export default class Menu extends PureComponent<IProps> {
  static defaultProps = {
    component: null,
    itemData: {},
  }

  onItemClicked = (item: any) => (e: React.MouseEvent<HTMLDivElement>) => {
    if (e && e.stopPropagation) {
      e.stopPropagation()
    }
    this.props.onClick(item)
    if (item.onClick) {
      item.onClick()
    }
  }

  renderMenuItem = (item: any, index: number) => {
    const { component: Component, itemData } = this.props
    const key = `item-${index}`
    if (Component) {
      return (
        <View onClick={this.onItemClicked(item)} key={key}>
          <Component {...item} {...itemData} />
        </View>
      )
    }

    const { name, icon, dataTest } = item as IMenuItem
    return (
      <View
        key={key}
        flexible="row"
        py={2}
        px={3}
        hover="clickable-bg"
        minWidth="100px"
        data-test={dataTest}
        onClick={this.onItemClicked(item)}
      >
        {icon && <Icon className={icon} width={32} mr={2} />}
        {!icon && <View width={32} mr={2} />}
        <Text flex={1}>{name}</Text>
      </View>
    )
  }

  render() {
    return (
      <View
        flexible="column"
        bg="white"
        elevation="three"
        borderRadius="2px"
        maxHeight={300}
        overflowY="auto"
      >
        {this.props.items.map(this.renderMenuItem)}
        {this.props.loadingMore && (
          <View p={3} flexible="column-center" minWidth={100} width="100%">
            <Spinner />
          </View>
        )}
      </View>
    )
  }
}
