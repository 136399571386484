import React from 'react'
import { Link } from 'react-router-dom'

import { ToolTip } from 'lib/components'
import IProduct from 'domains/products/models/IProduct'
import ProductImageCell from 'domains/products/components/ProductImageCell'
import { getProductRoute } from 'domains/products/navigation/routes'

export default function ProductNameCell(props: IProduct) {
  const { id, name, format } = props

  return (
    <ToolTip id={id} tip={name} delay={500}>
      <div className="flex flex-row items-center pl-2">
        <div className="mr-2">
          <ProductImageCell {...props} />
        </div>
        <Link to={getProductRoute(id, format)}>
          <p className="text-tertiary font-medium">{name}</p>
        </Link>
      </div>
    </ToolTip>
  )
}
