import * as R from 'ramda'
import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

export const CSV_C_MODEL_CHARACTERIZATIONS_RECORD = 'csvModelCharacterizations'

const csvConsumerModelCharacterizations: records.modules.IModule = {
  record: CSV_C_MODEL_CHARACTERIZATIONS_RECORD,
  noResponseDataKey: true,
  schema: new normalizr.schema.Entity(
    CSV_C_MODEL_CHARACTERIZATIONS_RECORD,
    {},
    { idAttribute: (item) => R.pathOr<string>('0', ['product', 'id'], item) },
  ),
  api: {
    create: () => 'characterizations/models',
    get: (id) => `characterizations/models/${id}`,
    getList: (page) => `characterizations/models?page=${page}`,
    update: (id) => `characterizations/models/${id}`,
    delete: (id) => `speccharacterizations/${id}`,
  },
}

export const getCSVCModelCharacterizationDownloadLink = (id: string | number) =>
  `characterizations/models/${id}/download`

export default csvConsumerModelCharacterizations
