import React, { PureComponent } from 'react'
import * as R from 'ramda'
import ReactTooltip from 'react-tooltip'
import { v4 as uuid } from 'uuid'

import Span from 'lib/components/Span'
import './ToolTip.css'

interface IProps {
  children: any
  id?: any
  tip: any
  place?: 'top' | 'right' | 'bottom' | 'left'
  delay?: number
  multiline?: boolean
  dataTest?: string
}

export default class ToolTip extends PureComponent<IProps> {
  render() {
    const { children, id = uuid(), tip, place, delay, dataTest } = this.props
    const content = R.is(String, tip) ? <Span>{tip}</Span> : tip

    return (
      <div data-for={id} data-tip data-test={dataTest}>
        <ReactTooltip
          id={id}
          place={place}
          delayShow={delay || 0}
          className="tooltip"
          multiline={this.props.multiline}
          html={this.props.multiline}
        >
          {content}
        </ReactTooltip>
        {children}
      </div>
    )
  }
}
