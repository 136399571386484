import React from 'react'

import IFileSetCharacterizationColumn from 'domains/characterizations/models/IFileSetCharacterizationColumn'
import { ListPlainTextCell } from 'lib/components'
import { findSpecColumn } from 'domains/modeling/selectors/csvCModelToSpec'
import ICSVSpecification from 'domains/specifications/models/ICSVSpecification'
import ICSVConsumerModel from 'domains/modeling/models/ICSVConsumerModel'
import { findModelFileColumn } from 'domains/modeling/utils/findModelDetails'
import { FORMAT_TO_DISPLAY } from 'domains/characterizations/models/CharacterizationReport'

interface IProps extends IFileSetCharacterizationColumn {
  fileLabel: string
  specification: ICSVSpecification
  model?: ICSVConsumerModel
}

export default function CharacterizationColumnFormatCell(props: IProps) {
  const { fileLabel, model, dataType, specification, headerName } = props
  let display = dataType ? dataType.type : null
  if (!display && model) {
    const modelColumn = findModelFileColumn(model, fileLabel, headerName)
    display = modelColumn ? modelColumn.dataType : null
  }
  if (!display) {
    const specColumn = findSpecColumn(specification, fileLabel, headerName)
    display = specColumn ? specColumn.dataType : ''
  }

  return (
    <ListPlainTextCell>
      {display ? FORMAT_TO_DISPLAY[display] : null}
    </ListPlainTextCell>
  )
}
