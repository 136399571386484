import React from 'react'
import * as R from 'ramda'
import { utils, records } from '@ims/1edtech-frontend-common'

import * as formats from 'domains/formats/constants/formats'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import LargeWidget from 'domains/dashboard/components/LargeWidget'
import { useSelector } from 'react-redux'
import { oneRosterCharacterizationFormatsFilterSelector } from 'domains/application/selectors/application'
import { getRecords } from 'lib/records/workflows/getRecords'
import orCSVCharacterizations, {
  OR_CSV_CHARACTERIZATIONS_RECORD,
} from 'lib/records/modules/orCSVCharacterizations'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import { getRecord } from 'lib/records/workflows/getRecord'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import { ONEROSTER_CSV_CHARACTERIZATION_REPORTS_RECORD } from 'lib/records/modules/oneRosterCSVCharacterizationReports'
import { REST_CHARACTERIZATION_REPORTS_RECORD } from 'lib/records/modules/restCharacterizationReports'
import { Spinner } from 'lib/components'
import CharacterizationSummaryTitle from 'domains/dashboard/components/RecentCharacterizationSummaryWidget/CharacterizationSummaryTitle'
import RESTPCharacterizationSummary from 'domains/dashboard/components/RecentCharacterizationSummaryWidget/RESTPCharacterizationSummary'
import { GREEN_REPORT_STATUS } from 'domains/reports/models/IReportStatuses'
import BlankSlate from 'lib/components/BlankSlate'
import CSVPCharacterizationSummary from 'domains/dashboard/components/RecentCharacterizationSummaryWidget/CSVPCharacterizationSummary'

interface IProps {
  fadeDelay: number
}

export default function RecentCharacterizationSummaryWidget(props: IProps) {
  const [pending, setPending] = React.useState(true)

  const [characterizationId, setCharacterizationId] = React.useState(0)
  const onChangeCharId = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPending(true)
    setCharacterizationId(utils.convertToInt(e.target.value))
  }

  const orgId = useSelector((s: any) => myOrganizationIdSelector(s, props))
  const formatFilters = useSelector(
    oneRosterCharacterizationFormatsFilterSelector,
  )
  const characterizations = useSelector((s: any) =>
    records.relatedRecordsSelectors.fullRelatedRecordsSelector(
      OR_CSV_CHARACTERIZATIONS_RECORD,
      myOrganizationIdSelector,
    )(s, props),
  )

  const mostRecentChar = useSelector((s: any) =>
    records.entitiesSelectors.entityByIdSelector(
      OR_CSV_CHARACTERIZATIONS_RECORD,
      characterizationId,
    )(s, props),
  )
  const mostRecentCharValidationId = R.pathOr('', ['content'], mostRecentChar)
  const selectorProps = { validationId: mostRecentCharValidationId }

  const csvReport = useSelector((s: any) =>
    records.entitiesSelectors.entityByIdSelector(
      ONEROSTER_CSV_CHARACTERIZATION_REPORTS_RECORD,
      'validationId',
    )(s, selectorProps),
  )
  const restReport = useSelector((s: any) =>
    records.entitiesSelectors.entityByIdSelector(
      REST_CHARACTERIZATION_REPORTS_RECORD,
      'validationId',
    )(s, selectorProps),
  )

  React.useEffect(() => {
    const fetch = async () => {
      const response = await getRecords({
        record: OR_CSV_CHARACTERIZATIONS_RECORD,
        parentRecordId: orgId,
        params: { filter: formatFilters, sort: 'runDate:descending' },
        page: 1,
        pageSize: 100,
      })
      const chars = records.modules.utils.getRecordDataFromResponse(
        orCSVCharacterizations,
        response as any,
      )
      const firstId = utils.convertToInt(R.pathOr(0, [0, 'id'], chars))
      setCharacterizationId(firstId)
    }
    fetch()
  }, [formatFilters, orgId])

  React.useEffect(() => {
    const fetch = async () => {
      const report = csvReport || restReport
      if (
        mostRecentChar &&
        (!report || report.validationId !== mostRecentChar.content) &&
        utils.hasValue(mostRecentChar.status)
      ) {
        const char = mostRecentChar as ICharacterization
        let record = ONEROSTER_CSV_CHARACTERIZATION_REPORTS_RECORD
        switch (char.format) {
          case formats.ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL:
          case formats.ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL:
          case formats.ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL:
          case formats.ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL:
          case formats.ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL:
          case formats.ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL:
          case formats.ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL:
            record = REST_CHARACTERIZATION_REPORTS_RECORD
            break
        }
        await getRecord(record, char.content)
      } else if (utils.hasValue(characterizations)) {
        setPending(false)
      }
    }
    fetch()
  }, [mostRecentChar, characterizations, csvReport, restReport])

  React.useEffect(() => {
    if (csvReport || restReport) {
      setPending(false)
    }
  }, [csvReport, restReport])

  const chars = R.pathOr(
    [],
    ['items'],
    characterizations,
  ) as ICharacterization[]
  const status = R.pathOr(false, ['status'], mostRecentChar)
  const isGreen = R.pathEq(['status'], GREEN_REPORT_STATUS, mostRecentChar)

  return (
    <LargeWidget
      fadeDelay={props.fadeDelay}
      dataTest="recent-characterization-summary-widget"
    >
      <div className="h-full w-full overflow-y-auto relative">
        <div className="sticky top-0 bg-white w-full pb-4 flex flex-row items-center justify-between">
          <WidgetHeader title="Recent Characterization Summary" />
          {chars.length > 0 && (
            <div className="ml-2 flex flex-col flex-1 items-end">
              <select
                value={characterizationId}
                onChange={onChangeCharId}
                className="max-w-xs text-sm px-2 py-1"
                style={{ width: 320 }}
              >
                {chars.map((char, index) => (
                  <option key={`char-${char.name}-${index}`} value={char.id}>
                    {char.name} -{' '}
                    {formats.FORMAT_TO_DISPLAY_SHORT_NAME[char.format]} -{' '}
                    {char.status}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        {pending && <Spinner centered />}

        {!pending && !mostRecentChar && (
          <BlankSlate title="No recent characterization" />
        )}

        {!pending && !!mostRecentChar && !status && (
          <BlankSlate title="Characterization Report is currently unavailable." />
        )}

        {!pending && !!mostRecentChar && utils.hasValue(status) && (
          <div className="mt-4">
            <CharacterizationSummaryTitle characterization={mostRecentChar} />

            {isGreen && (
              <div className="flex flex-col items-center justify-center m-8">
                <img
                  src="/images/unicat.png"
                  alt="funny cat"
                  className="h-48 w-auto"
                />
                <p className="font-bold text-xl text-center text-black mt-4">
                  Everything&apos;s perfect!
                </p>
              </div>
            )}

            {!!csvReport && !isGreen && (
              <CSVPCharacterizationSummary
                report={csvReport}
                characterization={mostRecentChar}
              />
            )}

            {!!restReport && !isGreen && (
              <RESTPCharacterizationSummary
                report={restReport}
                characterization={mostRecentChar}
              />
            )}
          </div>
        )}
      </div>
    </LargeWidget>
  )
}
