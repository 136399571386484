import React from 'react'

import { Text, Span, View, Icon } from 'lib/components'
import { utils } from '@ims/1edtech-frontend-common'
import { CC_TYPES } from 'domains/commonCartridge/constants/commonCartridge'

interface IProps {
  type: CC_TYPES
  id: number | string
  bg: string
  icon: string
  title: string
  subtitle?: string
  subtitleLabel?: string
  onClick?: () => any
  isExpanded?: boolean
  rightContent?: any
  isMissing?: boolean
}

export default function CCCharacterizationReportRow(props: IProps) {
  const isExpandable = props.isExpanded !== undefined
  const dataTestPrefix = `${props.type}-${props.id}`
  return (
    <View
      bg={props.bg}
      flexible="row-space-between"
      py={2}
      px={3}
      hover="elevation-two"
      cursor="pointer"
      onClick={props.onClick}
      borderRadius={6}
      elevation={props.isExpanded ? 'two' : undefined}
      minHeight={48}
      data-test={dataTestPrefix}
      data-open={isExpandable && props.isExpanded ? 'open' : 'closed'}
      className={props.isMissing ? 'missing-metadata' : ''}
      flex={1}
    >
      <View
        flexible="row-v-center"
        width={`calc(100% - ${
          utils.hasValue(props.rightContent) ? '7.5rem' : '1.85rem'
        })`}
      >
        <Icon
          className={props.icon}
          fontSize={24}
          mr={3}
          data-test={`${dataTestPrefix}-icon`}
        />
        <View maxWidth="90%">
          <Text
            variant="subtitle"
            fontWeight={700}
            data-test={`${dataTestPrefix}-title`}
          >
            {props.title}
          </Text>

          {utils.hasValue(props.subtitle) && (
            <Text
              mt={1}
              overflow="ellipsis"
              data-test={`${dataTestPrefix}-subtitle`}
            >
              {utils.hasValue(props.subtitleLabel) && (
                <Span fontWeight={700}>{props.subtitleLabel}: </Span>
              )}
              {props.subtitle}
            </Text>
          )}
        </View>
      </View>

      {utils.hasValue(props.rightContent) && (
        <View data-test={`${dataTestPrefix}-right-content`}>
          {props.rightContent}
        </View>
      )}
      {!isExpandable && (
        <Icon className="fas fa-long-arrow-alt-right" fontSize={24} />
      )}
      {isExpandable && (
        <Icon
          fontSize={24}
          className={`fas rotate-caret fa-long-arrow-alt-down ${
            props.isExpanded ? 'down' : ''
          }`}
        />
      )}
    </View>
  )
}
