import React from 'react'

import { ITextProps } from 'lib/components/Text'
import TextWithBoldHighlight from 'lib/components/TextWithBoldHighlight'

interface IProps extends ITextProps {
  title: string // update @@ notes
  highlight: string
}

export default function ModalTitle(props: IProps) {
  const { title, highlight, ...rest } = props
  return (
    <TextWithBoldHighlight
      text={title}
      highlight={highlight}
      fontSize="inherit"
      lineHeight="inherit"
      wordBreak="break-all"
      {...rest}
    />
  )
}
