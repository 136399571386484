import {
  ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL,
  ONER_V1P1_CSV_ROSTERING_EXPORT_BULK,
  ONER_V1P2_CSV_ROSTERING_EXPORT_BULK,
  COMMON_CARTRIDGE_V1P2,
  COMMON_CARTRIDGE_V1P3,
  THIN_COMMON_CARTRIDGE_V1P2,
  THIN_COMMON_CARTRIDGE_V1P3,
  ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK,
  ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL,
  ONER_V1P1_CSV_RESOURCES_EXPORT_BULK,
} from 'domains/formats/constants/formats'
import useFormatScreen from 'lib/hooks/useFormatScreen'
import CommonCartridgeCharacterizationReportScreen from 'domains/commonCartridge/screens/CommonCartridgeCharacterizationReportScreen'
import OR1P1CSVProviderCharacterizationReportScreen from 'domains/characterizations/screens/OR1P1CSVProviderCharacterizationReportScreen'
import OR1P1RestProviderCharacterizationReportScreen from 'domains/characterizations/screens/OR1P1RestProviderCharacterizationReportScreen'

export default function CharacterizationReportRouterScreen(props: any) {
  return useFormatScreen({
    ...props,
    map: {
      [ONER_V1P1_CSV_ROSTERING_EXPORT_BULK]: OR1P1CSVProviderCharacterizationReportScreen,
      [ONER_V1P2_CSV_ROSTERING_EXPORT_BULK]: OR1P1CSVProviderCharacterizationReportScreen,
      [ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK]: OR1P1CSVProviderCharacterizationReportScreen,
      [ONER_V1P1_CSV_RESOURCES_EXPORT_BULK]: OR1P1CSVProviderCharacterizationReportScreen,
      [ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL]: OR1P1RestProviderCharacterizationReportScreen,
      [ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL]: OR1P1RestProviderCharacterizationReportScreen,
      [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL]: OR1P1RestProviderCharacterizationReportScreen,
      [ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL]: OR1P1RestProviderCharacterizationReportScreen,
      [COMMON_CARTRIDGE_V1P2]: CommonCartridgeCharacterizationReportScreen,
      [COMMON_CARTRIDGE_V1P3]: CommonCartridgeCharacterizationReportScreen,
      [THIN_COMMON_CARTRIDGE_V1P2]: CommonCartridgeCharacterizationReportScreen,
      [THIN_COMMON_CARTRIDGE_V1P3]: CommonCartridgeCharacterizationReportScreen,
    },
  })
}
