import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

import store from 'lib/store'
import { getRequest, postRequest } from 'lib/api/api'
import { whoAmI } from 'domains/authentication/workflows/whoAmI'
import {
  ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
  ONER_1P1_BULK,
  ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
  ONER_V1P1_CSV_ROSTERING_EXPORT_BULK,
  ONER_V1P2_CSV_ROSTERING_EXPORT_BULK,
  ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL,
  COMMON_CARTRIDGE_V1P2,
  COMMON_CARTRIDGE_V1P3,
  THIN_COMMON_CARTRIDGE_V1P2,
  THIN_COMMON_CARTRIDGE_V1P3,
  ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL,
  ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL,
  ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK,
  ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK,
  ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL,
  ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL,
  ONER_V1P1_CSV_RESOURCES_IMPORT_BULK,
  ONER_V1P1_CSV_RESOURCES_EXPORT_BULK,
  ONER_1P2_BULK,
} from 'domains/formats/constants/formats'
import { setApplicationProperties } from 'domains/application/ducks/application'
import { getRecord } from 'lib/records/workflows/getRecord'
import { SPECIFICATIONS_RECORD } from 'lib/records/modules/specifications'
import { getRecords } from 'lib/records/workflows/getRecords'
import { FORMATS_RECORD } from 'lib/records/modules/formats'
import { findFormatIdByName } from 'domains/specifications/selectors/formatSelectors'
import { USER_ROLES_RECORD } from 'lib/records/modules/userRoles'
import { meSelector } from 'domains/authentication/selectors/me'
import {
  EVENT_BUTTON_TEXT,
  EVENT_HEADER_IMAGE_URL,
  EVENT_HEADER_LINK_URL,
  EventHeaderConfig,
} from 'domains/application/models/configuration'
import IUser from 'domains/users/models/IUser'

export const postWhoAmIInitialize = async (me?: IUser) => {
  await getRecords({
    record: USER_ROLES_RECORD,
    pageSize: 100,
  })

  const formatResponse = await getRecords({
    record: FORMATS_RECORD,
    pageSize: 100,
  })
  await postRequest('loginHistory')
  const systemConfigResponse = await getRequest('system/config')

  if (systemConfigResponse.success) {
    const data = systemConfigResponse.data.configurations as {
      attribute: string
      value: string
    }[]

    const configurations = data.reduce(
      (acc, item) => {
        acc[item.attribute as keyof EventHeaderConfig] = item.value

        return acc
      },
      {
        [EVENT_HEADER_IMAGE_URL]: '',
        [EVENT_HEADER_LINK_URL]: '',
        [EVENT_BUTTON_TEXT]: '',
      } as EventHeaderConfig,
    )

    store.dispatch(
      setApplicationProperties({
        [EVENT_HEADER_IMAGE_URL]: configurations[EVENT_HEADER_IMAGE_URL],
        [EVENT_HEADER_LINK_URL]: configurations[EVENT_HEADER_LINK_URL],
        [EVENT_BUTTON_TEXT]: configurations[EVENT_BUTTON_TEXT],
      }),
    )
    return
  }

  const formatsRecord = records.recordsSelectors.fullRecordsSelector(
    FORMATS_RECORD,
  )(store.getState(), {})
  const formatsData = R.pathOr([], ['items'], formatsRecord)

  // Rostering
  const orV1p1RestRosteringCOptionalId = findFormatIdByName(
    ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
    formatsData,
  )
  const orV1p1CsvRosteringCBulkId = findFormatIdByName(
    ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
    formatsData,
  )

  // Gradebook
  const orV1p1RestGradebookCOptionalId = findFormatIdByName(
    ONER_V1P1_REST_CONSUMER_GRADEBOOK_PULL_OPTIONAL,
    formatsData,
  )
  const orV1p1CsvGradebookCBulkId = findFormatIdByName(
    ONER_V1P1_CSV_GRADEBOOK_IMPORT_BULK,
    formatsData,
  )

  // Resources
  const orV1p1RestResourcesCOptionalId = findFormatIdByName(
    ONER_V1P1_REST_CONSUMER_RESOURCES_OPTIONAL,
    formatsData,
  )
  const orV1p1CsvResourcesCBulkId = findFormatIdByName(
    ONER_V1P1_CSV_RESOURCES_IMPORT_BULK,
    formatsData,
  )
  await Promise.allSettled([
    getRecord(SPECIFICATIONS_RECORD, orV1p1RestRosteringCOptionalId, {
      type: 'rest',
    }),
    getRecord(SPECIFICATIONS_RECORD, orV1p1CsvRosteringCBulkId, {
      type: 'csv',
    }),

    getRecord(SPECIFICATIONS_RECORD, orV1p1RestGradebookCOptionalId, {
      type: 'rest',
    }),
    await getRecord(SPECIFICATIONS_RECORD, orV1p1CsvGradebookCBulkId, {
      type: 'csv',
    }),

    getRecord(SPECIFICATIONS_RECORD, orV1p1RestResourcesCOptionalId, {
      type: 'rest',
    }),
    await getRecord(SPECIFICATIONS_RECORD, orV1p1CsvResourcesCBulkId, {
      type: 'csv',
    }),

    await getRecord(SPECIFICATIONS_RECORD, ONER_1P1_BULK),
    await getRecord(SPECIFICATIONS_RECORD, ONER_1P2_BULK),
  ])

  const oneRosterCharacterizationFormatIds = [
    findFormatIdByName(ONER_V1P1_CSV_ROSTERING_EXPORT_BULK, formatsData),
    findFormatIdByName(ONER_V1P2_CSV_ROSTERING_EXPORT_BULK, formatsData),
    findFormatIdByName(ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL, formatsData),
    findFormatIdByName(
      ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL,
      formatsData,
    ),
    findFormatIdByName(
      ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL,
      formatsData,
    ),
    findFormatIdByName(ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK, formatsData),
    findFormatIdByName(ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL, formatsData),
    findFormatIdByName(ONER_V1P1_CSV_RESOURCES_EXPORT_BULK, formatsData),
  ]
  const commonCartridgeCharacterizationFormatIds = [
    findFormatIdByName(COMMON_CARTRIDGE_V1P2, formatsData),
    findFormatIdByName(COMMON_CARTRIDGE_V1P3, formatsData),
    findFormatIdByName(THIN_COMMON_CARTRIDGE_V1P2, formatsData),
    findFormatIdByName(THIN_COMMON_CARTRIDGE_V1P3, formatsData),
  ]
  store.dispatch(
    setApplicationProperties({
      oneRosterCharacterizationFormatIds,
      commonCartridgeCharacterizationFormatIds,
      landingPageUrl: R.pathOr('', ['data', 'url'], formatResponse),
    }),
  )
}

export const initialize = async () => {
  const orgType = await whoAmI()
  const me = meSelector(store.getState())
  if (orgType && me) {
    await postWhoAmIInitialize(me)
  }
  store.dispatch(setApplicationProperties({ initialized: true }))
}
