import React from 'react'

import { ExpansionPanel, View } from 'lib/components'
import ICommonCartridgeCharacterizationReport from 'domains/commonCartridge/models/ICommonCartridgeCharacterizationReport'
import useAccordionHeaderState from 'domains/reports/hooks/useAccordionHeaderState'
import CCCharacterizationReportValidationErrors from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportValidationErrors'
import { getValidationsWithErrorsOrWarnings } from 'domains/commonCartridge/utils/validations'
import ReportExpansionHeader from 'domains/reports/components/ReportExpansionHeader'
import { 
  RED_REPORT_STATUS,
  AMBER_REPORT_STATUS 
} from 'domains/reports/models/IReportStatuses'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  report: ICommonCartridgeCharacterizationReport
}

export default function CCCharacterizationReportValidations(props: IProps) {
  const validationsWithErrorsOrWarnings = getValidationsWithErrorsOrWarnings(
    props.report,
  )

  const [headerStates, ,onToggleHeader] = useAccordionHeaderState(
    validationsWithErrorsOrWarnings,
  )
  
  const onToggleKey = (key: string) => () => onToggleHeader(key)

  return (
    <View variant="paper">
      <View mt={3} />
      {headerStates.map((headerState, index) => {
        const validation = validationsWithErrorsOrWarnings[index]
        const status = utils.hasValue(validation.errors)
          ? RED_REPORT_STATUS
          : AMBER_REPORT_STATUS
        return (
          <ExpansionPanel
            isOpen={headerState.open}
            onRequestChange={onToggleKey(headerState.name)}
            summary={
              <ReportExpansionHeader
                name={"Common Cartridge Validation Results"}
                open={headerState.open}
                status={status}
            />
            }
            details ={
              <CCCharacterizationReportValidationErrors validation={validation} key={index} />
            }
            />
        )
      })}
    </View>
  )
}
