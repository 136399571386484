import React, { PureComponent } from 'react'
import * as R from 'ramda'

import { Icon, Text, View } from 'lib/components'
import ModalWrapper from 'lib/components/ModalWrapper'

interface IProps {
  isOpen: boolean
  title?: any
  icon?: string
  message?: any
  confirmText?: string
  denyText?: string
  onConfirm: (args?: any) => void
  onDeny?: () => void
  args?: any
  hideContentWhenPending?: boolean
}

export default class Dialog extends PureComponent<IProps> {
  state = {
    pending: false,
    message: this.props.message,
    icon: this.props.icon,
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.isOpen && !this.props.isOpen) {
      this.unSetPending()
    }
    if (
      prevProps.message !== this.props.message &&
      !this.props.hideContentWhenPending
    ) {
      this.setState({ message: this.props.message })
    }
    if (
      prevProps.icon !== this.props.icon &&
      !this.props.hideContentWhenPending
    ) {
      this.setState({ icon: this.props.icon })
    }
  }

  onConfirm = async () => {
    this.setPending()
    await this.props.onConfirm(this.props.args)
  }

  onDeny = async () => {
    this.setPending()
    if (this.props.onDeny) {
      await this.props.onDeny()
    }
  }

  getActions() {
    const { confirmText, denyText, onDeny } = this.props
    const actions = [
      {
        text: confirmText || 'Yes',
        variant: 'start',
        onClick: this.onConfirm,
      },
    ]
    if (!onDeny) {
      return actions
    }

    return [
      ...actions,
      {
        text: denyText || 'Cancel',
        variant: 'neutral',
        onClick: this.onDeny,
      },
    ]
  }

  setPending = () => this.setState({ pending: true })

  unSetPending = () => this.setState({ pending: false })

  renderMessage() {
    const { message } = this.state
    if (!message) {
      return null
    }
    if (R.is(String, message)) {
      return <Text textAlign="center">{message}</Text>
    }
    if (R.is(Function, message)) {
      return message()
    }

    return message
  }

  render() {
    const { title, isOpen } = this.props
    const { message, icon } = this.state

    return (
      <ModalWrapper
        isOpen={isOpen}
        title={title || 'Are you sure?'}
        actions={this.getActions()}
        pending={this.state.pending}
      >
        {(message || icon) && (
          <View flexible="column-h-center">
            <Icon
              className={icon}
              fontSize="86px"
              pb={4}
              data-test="modal-icon"
              data-icon={icon}
            />
            {this.renderMessage()}
          </View>
        )}
      </ModalWrapper>
    )
  }
}
