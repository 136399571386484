import { utils } from '@ims/1edtech-frontend-common'
import { records } from '@ims/1edtech-frontend-common'
import * as R from 'ramda'

import { meSelector } from 'domains/authentication/selectors/me'
import store from 'lib/store'
import { ORGANIZATIONS_RECORD } from 'lib/records/modules/organizations'
import {
  myOrganizationIdSelector,
  myOrganizationPropSelector,
} from 'domains/authentication/selectors/organizations'
import { postRequest } from 'lib/api/api'
import { showToast, ERROR_TOAST } from 'lib/utils/toast'
import { isFlagEnabled } from 'lib/components/FeatureFlags'

const FRESHDESK_LOGIN_URL = utils.env.getEnvVariable(
  'FRESHDESK_LOGIN_URL',
  'https://support.1edtech.org/customer/login',
)

// Hardcoded prod for testing
// const FRESHDESK_LOGIN_URL = 'https://support.1edtech.org/customer/login'

export const goToHelpNew = async () => {
  window.open(FRESHDESK_LOGIN_URL)
}

export const goToHelpDeprecated = async () => {
  const me = meSelector(store.getState())

  const org = records.entitiesSelectors.entityByIdSelector(
    ORGANIZATIONS_RECORD,
    myOrganizationIdSelector,
  )(store.getState())

  const response = await postRequest('freshdesk', {
    name: `${me!.firstName} ${me!.lastName}`,
    org: R.pathOr('Unknown Org', ['name'], org),
    email: me!.email,
  })

  if (response.success) {
    window.open(response.data)
  } else {
    showToast(ERROR_TOAST, 'Failed to login to FreshDesk.')
  }
}

export const goToHelp = async () => {
  const featureFlags =
    myOrganizationPropSelector('featureFlags')(store.getState(), {}) || []

  const isEnabled = isFlagEnabled(featureFlags, 'new_freshdesk_sso')

  if (isEnabled) {
    await goToHelpNew()
  } else {
    await goToHelpDeprecated()
  }
}
