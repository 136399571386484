import React from 'react'
import * as R from 'ramda'
import * as Yup from 'yup'
import { Formik, FormikHelpers, FormikProps } from 'formik'

import { utils } from '@ims/1edtech-frontend-common'
import { ModalWrapper, FormInput } from 'lib/components'
import { updateRecord } from 'lib/records/workflows/updateRecord'
import { createRecord } from 'lib/records/workflows/createRecord'
import { getRecords } from 'lib/records/workflows/getRecords'
import { showToast } from 'lib/utils/toast'
import ILTIPlatform from 'domains/configuration/models/ILTIPlatform'
import { LTI_PLATFORMS_RECORD } from 'lib/records/modules/ltiPlatforms'

const validationSchema = Yup.object().shape({
  platformName: Yup.string().required('Required'),
  matchPattern: Yup.string().max(64, 'Must be 64 characters or less'),
})

interface IProps {
  editLTIPlatform?: ILTIPlatform | null
  isOpen: boolean
  close: () => any
}

export default function CreateEditLTIPlatformModal(props: IProps) {
  const onSave = async (
    values: ILTIPlatform,
    bag: FormikHelpers<ILTIPlatform>,
  ) => {
    const isEdit = utils.hasValue(props.editLTIPlatform)
    try {
      if (isEdit) {
        await updateRecord(
          LTI_PLATFORMS_RECORD,
          props.editLTIPlatform!.id,
          values,
        )
      } else {
        await createRecord(LTI_PLATFORMS_RECORD, R.dissoc('id', values))
      }

      await getRecords({ record: LTI_PLATFORMS_RECORD, force: true })
      bag.setSubmitting(false)
      bag.resetForm()
      props.close()
    } catch (error) {
      bag.setSubmitting(false)
      showToast('error', `Failed to ${isEdit ? 'edit' : 'add'} LTI Platform`)
    }
  }

  return (
    <Formik
      initialValues={
        utils.hasValue(props.editLTIPlatform)
          ? props.editLTIPlatform!
          : {
              id: 0,
              platformName: '',
              matchPattern: ''
            }
      }
      onSubmit={onSave}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {(bag: FormikProps<ILTIPlatform>) => {
        return (
          <ModalWrapper
            isOpen={props.isOpen}
            title={
              utils.hasValue(props.editLTIPlatform)
                ? 'Edit LTI Platform'
                : 'New LTI Platform'
            }
            actions={[
              {
                text: 'Save',
                variant: 'start',
                onClick: bag.submitForm,
                extra: {
                  type: 'submit',
                },
              },
              {
                text: 'Cancel',
                variant: 'neutral',
                onClick: () => {
                  bag.resetForm()
                  props.close()
                },
                extra: {
                  type: 'button',
                },
              },
            ]}
            pending={bag.isSubmitting}
          >
            <FormInput
              label="Name"
              name="platformName"
              value={bag.values.platformName}
              valueDataTest="name-input"
              placeholder="LTI Platform Name"
              handleChange={bag.handleChange}
            />
            <FormInput
              label="Match Pattern"
              name="matchPattern"
              value={bag.values.matchPattern}
              valueDataTest="pattern-input"
              placeholder="LTI Platform Match Pattern"
              handleChange={bag.handleChange}
            />
          </ModalWrapper>
        )
      }}
    </Formik>
  )
}
