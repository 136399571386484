import React from 'react'
import { Link } from 'react-router-dom'

import { View, ToolTip, Text } from 'lib/components'
import ITrustedApplication from 'domains/trustedApps/models/ITrustedApplication'
import {
  getRoute,
  TRUSTED_APPS_ROUTE,
} from 'domains/application/navigation/routes'
import TrustedAppLogo from 'domains/trustedApps/components/TrustedAppsList/TrustedAppLogo'

export default function TrustedApplicationNameCell(
  props: ITrustedApplication & {
    parentOrgId?: number
  },
) {
  const tooltipId = `${props.id}-${props.title}`
  return (
    <Link
      to={getRoute(
        `${TRUSTED_APPS_ROUTE}${
          props.parentOrgId ? `/shared/${props.parentOrgId}` : ''
        }`,
        props.id,
      )}
      data-test={`trusted-app-${props.id}`}
    >
      <ToolTip id={tooltipId} tip={props.title} delay={500}>
        <View flexible="row-v-center">
          <TrustedAppLogo title={props.title} logo={props.logo} />
          <Text ml={2} textAlign="left" color="#2F2F2F" fontWeight={500}>
            {props.title}
          </Text>
        </View>
      </ToolTip>
    </Link>
  )
}
