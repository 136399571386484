import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'

export const getModelName = (model: any) =>
  utils.string.ellipsize(model.name || '', 50)

export const getColumnsForCreate = (file: any) =>
  R.propOr<any[], any, any[]>([], 'columns', file).map((column) =>
    R.compose<any, any, any, any, any, any, any>(
      R.dissoc('notes'),
      R.dissoc('enumTerms'),
      R.assoc('enumValues', R.propOr([], 'enumTerms', column)),
      R.dissoc('columnName'),
      R.assoc('header', R.propOr('', 'columnName', column)),
      R.assoc('dataType', R.propOr('String', 'dataType', column)),
    )(column),
  )

export const getFileForCreate = (file: any) =>
  R.compose<any, any, any, any>(
    R.assoc('columns', getColumnsForCreate(file)),
    R.assoc('touched', R.propOr(false, 'touched', file)),
    R.assoc('required', 'Required'),
  )(file)
export const getFilesForCreate = (model: any) =>
  R.propOr<any[], any, any[]>([], 'files', model).map(getFileForCreate)

export const getFilesForUpdate = (model: any) =>
  R.propOr<any[], any, any[]>([], 'files', model).map((file) =>
    R.compose(R.dissoc('inProgress'))(file),
  )

export const prepareForCreate = (data: any) => ({
  csvDataModel: R.compose<any, any, any, any, any, any, any>(
    R.dissoc('orgId'),
    R.dissoc('productId'),
    R.assoc('organization', {
      id: R.prop('orgId', data),
    }),
    R.assoc('product', {
      id: R.prop('productId', data),
    }),
    R.assoc('files', getFilesForCreate(data)),
    R.assoc('name', getModelName(data)),
  )(data),
})

export const prepareForUpdate = (data: any) => ({
  csvDataModel: R.compose<any, any, any, any>(
    R.dissoc('productId'),
    R.assoc('files', getFilesForUpdate(data)),
    R.assoc('name', getModelName(data)),
  )(data),
})
