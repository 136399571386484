import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

import store from 'lib/store'
import { SPECIFICATIONS_RECORD } from 'lib/records/modules/specifications'
import { ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL } from 'domains/formats/constants/formats'
import { OR1P1Subsets } from 'domains/specifications/constants/oneRoster1p1FileSets'
import { SUBSET_TO_SERVICE_NAMES_MAP } from 'domains/modeling/constants/restCOneRoster1p1'
import IRestCModelServiceEndpoint from 'domains/modeling/models/IRestCModelServiceEndpoint'
import IRestCModelService from 'domains/modeling/models/IRestCModelService'
import IRestCSpecService from 'domains/specifications/models/IRestCSpecService'
import IRestCSpecServiceEndpoint from 'domains/specifications/models/IRestCSpecServiceEndpoint'
import IRestCSpecServiceEndpointParam from 'domains/specifications/models/IRestCSpecServiceEndpointParam'
import IRestCSpecServiceEndpointAttribute from 'domains/specifications/models/IRestCSpecServiceEndpointAttribute'

export const getRestModelServicesFromSpec = (
  endpointSet: OR1P1Subsets,
): IRestCModelService[] => {
  const requestedServices = SUBSET_TO_SERVICE_NAMES_MAP[endpointSet] as string[]
  const spec = records.entitiesSelectors.entityByIdSelector(
    SPECIFICATIONS_RECORD,
    'format',
  )(store.getState(), { format: ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL })

  const specServices = R.propOr([], 'services', spec) as IRestCSpecService[]

  const specServiceNames = R.map(R.prop('name'), specServices) as string[]
  const intersectingServices = R.intersection(
    requestedServices,
    specServiceNames,
  )

  return intersectingServices.map((serviceName) => {
    const specService = R.find(
      R.propEq('name', serviceName),
      specServices,
    )! as IRestCSpecService
    return {
      ...R.dissoc('id', specService),
      notes: '',
      endpoints: specService.endpoints.map(
        (endpoint: IRestCSpecServiceEndpoint) => {
          return {
            ...R.compose(R.dissoc('id'), R.dissoc('httpVerb'))(endpoint),
            enabled: true,
            notes: '',
            touched: false,
            params: endpoint.params.map(
              (param: IRestCSpecServiceEndpointParam) => ({
                ...R.dissoc('id', param),
                notes: '',
                supported: true,
              }),
            ),
            attributes: endpoint.attributes.map(
              (attribute: IRestCSpecServiceEndpointAttribute) => ({
                ...R.dissoc('id', attribute),
                notes: '',
                metadata: false,
              }),
            ),
          } as IRestCModelServiceEndpoint
        },
      ),
    }
  }) as IRestCModelService[]
}
