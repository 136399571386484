import React, { PureComponent } from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Formik, Form } from 'formik'
import { utils, records } from '@ims/1edtech-frontend-common'

import { Span, TextArea, Text, View } from 'lib/components'
import ModalWrapper from 'lib/components/ModalWrapper'
import { modelFileColumnSelector } from 'domains/modeling/selectors/csvCModelToSpec'
import { CSV_C_MODELS_RECORD } from 'lib/records/modules/csvCModels'
import {
  updateCsvCFileColumnModelInState,
  removeCsvCFileColumnModelInState,
} from 'domains/modeling/workflows/csvCModelFile'
import { trackCSVCModelingAnalytics } from 'domains/modeling/utils/trackModelingAnalytics'
import { ICSVConsumerFileColumn } from 'domains/modeling/models/ICSVConsumerModel'
import { RootState } from 'lib/store/rootReducer'
import { OPTIONAL_COLUMN_WARNING } from 'domains/modeling/constants/columns'

export const EDIT_MODEL_FILE_COLUMN_NOTE_MODAL = 'editModelFileColumnNoteModal'

interface IPropsFromState {
  column: ICSVConsumerFileColumn | null
}
const stateMap = createStructuredSelector<RootState, any, IPropsFromState>({
  column: modelFileColumnSelector(
    records.entitiesSelectors.entityByIdSelector(
      CSV_C_MODELS_RECORD,
      'modelId',
    ),
    'fileName',
    'header',
  ),
})

interface IProps extends IPropsFromState {
  isOpen: boolean
  closeModal: (wasSaved: boolean, wasCleared?: boolean) => any
  fileName: string
  header?: string
  metadata?: boolean
  modelId: string | number
}

const initialState = {
  pending: false,
}
type IState = Readonly<typeof initialState>

interface IFormValues {
  notes: string
}

export class EditModelFileColumnNoteModal extends PureComponent<IProps> {
  readonly state: IState = initialState

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.isOpen && !this.props.isOpen && this.state.pending) {
      this.setState({ pending: false })
    }
  }

  onSave = (values: IFormValues) => {
    this.setState({ pending: true })
    const { closeModal, modelId, fileName, header, column } = this.props
    updateCsvCFileColumnModelInState(modelId, fileName, header!, {
      ...column!,
      ...values,
    })
    trackCSVCModelingAnalytics('added_note_on_file_header', {
      fileName,
      header,
    })
    closeModal(true, !utils.hasValue(values.notes))
  }

  onDelete = async () => {
    this.setState({ pending: true })
    const { closeModal, modelId, fileName, header } = this.props
    removeCsvCFileColumnModelInState(modelId, fileName, header!)
    trackCSVCModelingAnalytics('removed_suggested_metadata', {
      fileName,
      header,
    })
    closeModal(false)
  }

  onCancel = () => this.props.closeModal(false, false)

  getActions = (handleSubmit: any) => {
    const { column } = this.props
    const saveAction = {
      text: 'Save',
      variant: 'start',
      onClick: handleSubmit,
      extra: {
        type: 'submit',
      },
    }
    const cancelAction = {
      text: 'Cancel',
      variant: 'neutral',
      onClick: this.onCancel,
      extra: {
        type: 'button',
      },
    }

    if (R.propOr(false, 'metadata', column)) {
      return [
        saveAction,
        {
          text: 'Delete',
          variant: 'primary',
          onClick: this.onDelete,
          extra: {
            type: 'button',
          },
        },
        cancelAction,
      ]
    }
    return [saveAction, cancelAction]
  }

  renderTitle = () => {
    const { header, column } = this.props
    const notes = R.propOr('', 'notes', column)
    const columnTitle = <Span fontWeight="bold">{header}</Span>
    if (utils.hasValue(notes)) {
      return (
        <Text variant="subtitle" fontWeight={400}>
          Edit notes for {columnTitle}
        </Text>
      )
    }

    return (
      <Text variant="subtitle" fontWeight={400}>
        Add notes for {columnTitle}
      </Text>
    )
  }

  render() {
    const { isOpen, header, metadata, column } = this.props
    const notes = R.propOr<string, ICSVConsumerFileColumn, string>(
      '',
      'notes',
      column!,
    )

    return (
      <Formik
        initialValues={{ notes }}
        onSubmit={this.onSave}
        enableReinitialize={true}
      >
        {({ values, handleChange, handleSubmit }) => (
          <ModalWrapper
            isOpen={isOpen}
            title={this.renderTitle()}
            notice={metadata ? OPTIONAL_COLUMN_WARNING : undefined}
            actions={this.getActions(handleSubmit)}
            pending={this.state.pending}
          >
            <Form>
              <View flexible="row" py={3} pl={3}>
                <View width="100px">
                  <Text fontWeight={700}>Notes</Text>
                </View>
                <View flex={1}>
                  <TextArea
                    width="100%"
                    minHeight="80px"
                    placeholder={`Notes on ${header}`}
                    name="notes"
                    onChange={handleChange}
                    value={values.notes}
                    autoFocus={true}
                  />
                </View>
              </View>
            </Form>
          </ModalWrapper>
        )}
      </Formik>
    )
  }
}

export default connect(stateMap)(EditModelFileColumnNoteModal)
