import React from 'react'

import { View, Text, Icon } from 'lib/components'
import {
  FORMAT_TO_ICON_MAP,
  AnySpecFormatType,
} from 'domains/formats/constants/formats'

interface IProps {
  format: AnySpecFormatType
  label: string
}

export default function DistrictProductCatalogFilterFormat(props: IProps) {
  return (
    <View flexible="row-v-center">
      <Icon mr={2} className={FORMAT_TO_ICON_MAP[props.format]} fontSize={18} />
      <Text>{props.label}</Text>
    </View>
  )
}
