import React from 'react'

import { Button, View, Icon, Text } from 'lib/components'

interface IProps {
  expand: boolean
  onClick: () => any
}

export default function ComparisonExpansionButton(props: IProps) {
  return (
    <Button
      variant="complete"
      py={2}
      px="28px"
      mr={2}
      height="42px"
      onClick={props.onClick}
    >
      <View flexible="row-center">
        <Icon
          className={
            props.expand ? 'fas fa-arrow-circle-down' : 'fas fa-arrow-circle-up'
          }
          color="white"
          fontSize={20}
        />
        <Text color="white" ml={2}>
          {props.expand ? 'Expand' : 'Collapse'} All
        </Text>
      </View>
    </Button>
  )
}
