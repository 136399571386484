import React from 'react'
import clsx from 'clsx'
import { utils } from '@ims/1edtech-frontend-common'
import Linkify from 'lib/components/Linkify'
import HTML from 'lib/components/HTML'

function Value({
  children,
  'data-test': dataTest,
  html,
}: {
  children: string
  'data-test': string
  html?: boolean
}) {
  if (html)
    return (
      <HTML fontSize={14} color="text" className="ql-editor">
        {children}
      </HTML>
    )

  return (
    <p className="text-gray-900" data-test={dataTest}>
      <Linkify>{children}</Linkify>
    </p>
  )
}

type Props = {
  label: string
  value?: string | string[]
  'data-test'?: string
  className?: any
  scrollable?: boolean
  html?: boolean
  commaDelimited?: boolean
  showIfNoValue?: boolean
}

export default function InfoCardItem(props: Props) {
  if (!props.showIfNoValue && !utils.hasValue(props.value)) {
    return null
  }

  const dataTest = props['data-test'] || props.label
  return (
    <div
      className={clsx('text-sm leading-6 mt-4', props.className)}
      data-test={dataTest}
    >
      <label className="text-text text-lg font-bold" data-test="label">
        {props.label}
      </label>
      <div
        className={clsx('mt-1', {
          'max-h-48 overflow-auto': props.scrollable,
        })}
      >
        {Array.isArray(props.value) &&
          !props.commaDelimited &&
          props.value.map((value, index) => (
            <Value
              key={`${dataTest}-value-${index}`}
              data-test={`value-${index}`}
              html={props.html}
            >
              {value}
            </Value>
          ))}
        {(!Array.isArray(props.value) || props.commaDelimited) && (
          <Value data-test="value" html={props.html}>
            {Array.isArray(props.value)
              ? props.value.join(', ')
              : props.value || ''}
          </Value>
        )}
      </div>
    </div>
  )
}
