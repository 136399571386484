import React from 'react'
import * as R from 'ramda'

import { View, Text } from 'lib/components'

export interface IItemOptions {
  value: any
  width?: number | number[] | string
}
interface IProps {
  options: IItemOptions
}

export default function SearchMenuLineItem(props: IProps) {
  return (
    <View
      width={props.options.width || 96}
      maxWidth={props.options.width || 96}
      mx={[1, 3]}
    >
      {R.is(String, props.options.value) && (
        <Text overflow="ellipsis">{props.options.value}</Text>
      )}
      {!R.is(String, props.options.value) && props.options.value}
    </View>
  )
}
