import * as normalizr from 'normalizr'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

export const IMS_APPLICATIONS_RECORD = 'imsApplications'


const imsApplications: records.modules.IModule = {
  record: IMS_APPLICATIONS_RECORD,
  responseDataKey: 'applications',
  getCreateResponseData: R.propOr({}, 'data'),
  schema: new normalizr.schema.Entity(
    IMS_APPLICATIONS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(),
  ),
  api: {
    create: () => 'trustedapps/applications',
    get: (id) => `trustedapps/applications/${id}`,
    getList: () => 'trustedapps/applications',
    update: (id) => `trustedapps/applications/${id}`,
    delete: (id) => `trustedapps/applications/${id}`,
  },
}

export default imsApplications
