import * as normalizr from 'normalizr'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

import { prepareFileCharacterizationForServer } from 'domains/characterizations/utils/prepareFileCharacterizationForServer'

export const OR_CSV_CHARACTERIZATIONS_RECORD = 'orCSVCharacterizations'

const orCSVCharacterizations: records.modules.IModule = {
  record: OR_CSV_CHARACTERIZATIONS_RECORD,
  responseDataKey: 'characterizationReport',
  schema: new normalizr.schema.Entity(
    OR_CSV_CHARACTERIZATIONS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(),
  ),
  api: {
    create: () => 'characterizations/csvs',
    prepareCreateData: prepareFileCharacterizationForServer,
    get: (id) => `characterizations/csvs/${id}`,
    getList: (params, orgId) => {
      const formatId = R.propOr(false, 'formatId', params)
      if (formatId) {
        return `formats/${formatId}/characterizations`
      }

      // Get supported ONEROSTER formats
      return `organizations/${orgId}/characterizations`
    },
    update: (id) => `characterizations/csvs/${id}`,
    delete: (id) => `characterizations/${id}`,
  },
}

export const getCSVCharacterizationDownloadLink = (id: string | number) =>
  `characterizations/csvs/${id}/download`

export default orCSVCharacterizations
