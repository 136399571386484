import React from 'react'
import * as R from 'ramda'

import { utils } from '@ims/1edtech-frontend-common'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import IRESTCharacterizationReport from 'domains/characterizations/models/IRESTCharacterizationReport'
import { IRESTCharacterizationReportEndpointSummary } from 'domains/characterizations/models/IRESTCharacterizationReportEndpointCharacterization'
import CharacterizationSummaryHeader from 'domains/dashboard/components/RecentCharacterizationSummaryWidget/CharacterizationSummaryHeader'
import CharacterizationSummaryItem from 'domains/dashboard/components/RecentCharacterizationSummaryWidget/CharacterizationSummaryItem'

type Summary = {
  field?: string
  description: string
}
type Summaries = {
  [service: string]: {
    [endpoint: string]: Summary[]
  }
}

type CSVPCharacterizationSummaryProps = {
  report: IRESTCharacterizationReport
  characterization: ICharacterization
}

export default function RESTPCharacterizationSummary(
  props: CSVPCharacterizationSummaryProps,
) {
  let summaries: Summaries = {}
  const validations = R.pathOr(false, ['serviceValidations', 0], props.report)
  const characterizations = R.pathOr(
    false,
    ['serviceCharacterisations', 0],
    props.report,
  )

  const endpoints =
    !!validations && !characterizations
      ? R.pathOr([], ['endpoints'], validations)
      : !!characterizations
      ? R.pathOr([], ['endpoints'], characterizations)
      : []

  endpoints.forEach((endpoint) => {
    const endpointSummary = R.pathOr(
      [],
      ['summary'],
      endpoint,
    ) as IRESTCharacterizationReportEndpointSummary[]
    if (utils.hasValue(endpointSummary)) {
      const label = R.pathOr('Critical Error', ['label'], endpoint)
      const urlLeaf = R.pathOr('', ['urlLeaf'], endpoint)
      const existing = R.pathOr([], [label, urlLeaf], summaries)
      summaries = R.assocPath(
        [label, urlLeaf],
        [
          ...existing,
          ...endpointSummary.map((s) => ({
            field: s.attribute,
            description: s.description,
          })),
        ],
        summaries,
      )
    }
  })

  return (
    <div className="space-y-4 mt-4">
      {R.keys(summaries)
        .sort((a, b) => (a === b ? 0 : a < b ? -1 : 1))
        .map((service) => {
          const endpoints = R.keys(summaries[service])
          return (
            <div key={`summary-${service}`}>
              <CharacterizationSummaryHeader
                title={`${service}`}
                status={props.characterization.status || 'RED'}
              />
              {endpoints.map((endpoint) => {
                const items = summaries[service][endpoint]
                return items.map((item, itemIndex) => (
                  <CharacterizationSummaryItem
                    key={`summary-${service}-${endpoint}-item-${itemIndex}`}
                    {...item}
                  />
                ))
              })}
            </div>
          )
        })}
    </div>
  )
}
