import React from 'react'
import * as R from 'ramda'

import { utils } from '@ims/1edtech-frontend-common'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import NonTableList from 'lib/components/NonTableList'
import CCCharacterizationReportTableItemRow from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportTableItemRow'
import { COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_ITEMS_RECORD } from 'lib/records/modules/commonCartridgeCharacterizationReportItems'
import ICommonCartridgeCharacterizationReport from 'domains/commonCartridge/models/ICommonCartridgeCharacterizationReport'
import { View } from 'lib/components'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'

interface IProps {
  isPreview?: boolean
  parentItemId?: number
  report: ICommonCartridgeCharacterizationReport
  characterization: ICharacterization
  title?: string
  noBlankslate?: boolean
  query?: string
  searchTemplate?: string
  params?: any
}

export default function CommonCartridgeCharacterizationReportTOCList(
  props: IProps,
) {
  const reportRootItemId = R.pathOr(0, ['rootItemId'], props.report)
  const rootItemId = props.parentItemId || reportRootItemId

  const list = (
    <NonTableList
      data-test="cc-item-list"
      record={COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_ITEMS_RECORD}
      parentRecordId={rootItemId}
      RowComponent={CCCharacterizationReportTableItemRow}
      rowData={{ characterization: props.characterization }}
      blankSlateText="No Items"
      noBlankslate={props.noBlankslate}
      noHorizontalScroll={true}
      pageSize={props.isPreview ? 5 : undefined}
      noPagination={props.isPreview}
      query={props.query}
      searchTemplate={props.searchTemplate}
      params={props.params}
      usePageBottomListener={true}
    />
  )

  if (utils.hasValue(props.title)) {
    return (
      <View variant="paper">
        <WidgetHeader title={props.title} />
        {list}
      </View>
    )
  }

  return list
}
