import React from 'react'
import * as R from 'ramda'

import IRESTComparisonReport from 'domains/characterizations/models/IRESTComparisonReport'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import IAccordionHeaderState from 'domains/characterizations/models/IAccordionHeaderState'
import IRESTServiceComparisonReport from 'domains/characterizations/models/IRESTServiceComparisonReport'
import { ExpansionPanel, View } from 'lib/components'
import ReportExpansionHeader from 'domains/reports/components/ReportExpansionHeader'
import RESTServiceComparisonReport from 'domains/comparisons/components/ComparisonReport/RESTServiceComparisonReport'
import { GRAY_REPORT_STATUS } from 'domains/reports/models/IReportStatuses'

interface IProps {
  report: IRESTComparisonReport
  specification: IRestCSpec
  headerStates: IAccordionHeaderState[]
  getHeaderState: (name: string) => any
  onToggleHeader: (name: string) => any
  allExpanded: boolean
  allCollapsed: boolean
  setAllExpandedState: (state: boolean) => any
  setAllCollapsedState: (state: boolean) => any
}

export default function RESTServicesComparisonReport(props: IProps) {
  const onRequestChange = (service: IRESTServiceComparisonReport) => () => {
    const state = props.getHeaderState(service.serviceName)
    if (state.open && props.allExpanded) {
      props.setAllExpandedState(false)
    }
    if (!state.open && props.allCollapsed) {
      props.setAllCollapsedState(false)
    }
    props.onToggleHeader(service.serviceName)
  }

  const renderService = (service: IRESTServiceComparisonReport) => {
    const headerState = props.getHeaderState(service.serviceName)
    if (!headerState) {
      return null
    }

    const allServiceEndpointsDisabled = R.all(R.propEq('enabled', false))(
      service.endpointComparisons,
    )

    return (
      <ExpansionPanel
        key={service.serviceName}
        isOpen={headerState.open}
        onRequestChange={onRequestChange(service)}
        summary={
          <ReportExpansionHeader
            status={
              allServiceEndpointsDisabled ? GRAY_REPORT_STATUS : service.status
            }
            circle={allServiceEndpointsDisabled}
            name={service.serviceName}
            open={headerState.open}
            data-test={`service-report-header-${service.serviceName}`}
          />
        }
        details={
          <RESTServiceComparisonReport
            report={props.report}
            service={service}
            specification={props.specification}
            serviceExpanded={headerState.open}
            serviceCollapsed={!headerState.open}
            allExpanded={props.allExpanded}
            allCollapsed={props.allCollapsed}
            setAllExpandedState={props.setAllExpandedState}
            setAllCollapsedState={props.setAllCollapsedState}
          />
        }
      />
    )
  }

  const { report } = props
  if (report.restComparisons.length < 1) {
    return null
  }

  return (
    <View mb={3} data-test="comparison-report-services">
      {R.sort(R.ascend(R.prop('serviceName')), report.restComparisons).map(
        renderService,
      )}
    </View>
  )
}
