import React, { PureComponent } from 'react'
import { Formik, Form, FormikHelpers } from 'formik'

import { TextArea } from 'lib/components'
import ModalWrapper from 'lib/components/ModalWrapper'
import { updateRecord } from 'lib/records/workflows/updateRecord'
import { utils } from '@ims/1edtech-frontend-common'
import { showToast, ERROR_TOAST } from 'lib/utils/toast'
import IComparison from 'domains/comparisons/models/IComparison'
import { COMPARISONS_RECORD } from 'lib/records/modules/comparisons'
import { PRODUCT_COMPARISONS_RECORD } from 'lib/records/modules/productComparisons'

interface IProps {
  comparison: IComparison
  isOpen: boolean
  closeModal: (wasSaved: boolean, wasCleared: boolean) => void
  note: string
  isProductComparison?: boolean
}

const initialState = { pending: false }
type State = Readonly<typeof initialState>

interface IFormValues {
  note: string
}

export default class ComparisonReportNotesModal extends PureComponent<IProps> {
  readonly state: State = initialState

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.isOpen && !this.props.isOpen && this.state.pending) {
      this.setState({ pending: false })
    }
  }

  onCancel = () => this.props.closeModal(false, false)

  onSave = async (values: IFormValues, bag: FormikHelpers<IFormValues>) => {
    const { comparison, closeModal } = this.props
    const response = await updateRecord(
      this.props.isProductComparison
        ? PRODUCT_COMPARISONS_RECORD
        : COMPARISONS_RECORD,
      comparison.id,
      values,
    )
    if (response.success) {
      return closeModal(true, !utils.hasValue(values.note))
    }
    showToast(ERROR_TOAST, 'Failed to update notes')
    return bag.setSubmitting(false)
  }

  render() {
    const { isOpen, note } = this.props

    return (
      <Formik
        initialValues={{ note }}
        onSubmit={this.onSave}
        enableReinitialize={true}
      >
        {({ values, handleChange, handleSubmit, isSubmitting }) => (
          <ModalWrapper
            isOpen={isOpen}
            title="Report Notes"
            actions={[
              {
                text: 'Save',
                variant: 'start',
                onClick: handleSubmit,
                extra: {
                  type: 'submit',
                },
              },
              {
                text: 'Cancel',
                variant: 'neutral',
                onClick: this.onCancel,
                extra: {
                  type: 'button',
                },
              },
            ]}
            pending={isSubmitting}
          >
            <Form>
              <TextArea
                width="100%"
                minHeight="80px"
                placeholder="Report notes..."
                name="note"
                onChange={handleChange}
                value={values.note}
                autoFocus={true}
              />
            </Form>
          </ModalWrapper>
        )}
      </Formik>
    )
  }
}
