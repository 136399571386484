import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

export const ONEROSTER_CSV_CHARACTERIZATION_REPORTS_RECORD =
  'oneRosterCSVCharacterizationReports'

const oneRosterCSVharacterizations: records.modules.IModule = {
  record: ONEROSTER_CSV_CHARACTERIZATION_REPORTS_RECORD,
  noResponseDataKey: true,
  schema: new normalizr.schema.Entity(
    ONEROSTER_CSV_CHARACTERIZATION_REPORTS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(['validationId']),
  ),
  api: {
    create: () => '',
    get: (id) => `conformancereports/csvfilesreport/${id}`,
    getList: () => '',
    update: () => '',
    delete: () => '',
  },
}

export default oneRosterCSVharacterizations
