import React from 'react'

import IRestCModelServiceEndpointAttribute from 'domains/modeling/models/IRestCModelServiceEndpointAttribute'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import IRestCModelService from 'domains/modeling/models/IRestCModelService'
import IRestCModelServiceEndpoint from 'domains/modeling/models/IRestCModelServiceEndpoint'
import { Button, Text } from 'lib/components'
import { utils } from '@ims/1edtech-frontend-common'
import NoteEditorModal from 'lib/components/NoteEditorModal'
import ModalTitle from 'lib/components/ModalTitle'
import useRestCSpecServiceEndpointAttributeCell from 'domains/modeling/hooks/useRestCSpecServiceEndpointAttributeCell'
import { trackRestCModelingAnalytics } from 'domains/modeling/utils/trackModelingAnalytics'

interface IProps extends IRestCModelServiceEndpointAttribute {
  specification: IRestCSpec
  service: IRestCModelService
  endpoint: IRestCModelServiceEndpoint
  endpointIndex: number
  setFieldValue: any
}

export default function RestCAttributeNotesCell(props: IProps) {
  const [, , attributeFormPath] = useRestCSpecServiceEndpointAttributeCell(
    props,
  )
  const [open, setOpen] = React.useState(false)

  const onViewNote = () => setOpen(true)
  const onCloseNote = () => setOpen(false)
  const onNotesSaved = (updatedNotes: string) => {
    trackRestCModelingAnalytics('added_note_on_attribute')
    props.setFieldValue(`${attributeFormPath}.notes`, updatedNotes)
  }

  const { name, notes } = props
  const hasNotes = utils.hasValue(notes)
  const variant = hasNotes ? 'start' : 'tertiary'
  const text = hasNotes ? 'view/edit note' : 'add a note'
  return (
    <>
      <Button
        variant={variant}
        onClick={onViewNote}
        data-test={`${name}-view-edit-notes`}
        innerMinWidth={40}
      >
        <Text fontSize={11} color="white">
          {text}
        </Text>
      </Button>
      {open && (
        <NoteEditorModal
          isOpen={open}
          close={onCloseNote}
          onSave={onNotesSaved}
          title={
            <ModalTitle
              title="Edit notes for @@ data element"
              highlight={name}
            />
          }
          notes={notes}
        />
      )}
    </>
  )
}
