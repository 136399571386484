import React from 'react'
import * as R from 'ramda'
import { Formik, Form, FormikHelpers, FormikProps } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { utils } from '@ims/1edtech-frontend-common'

import { View, Text, InlineAlert } from 'lib/components'
import BaseORRosteringCharacterizationsBreadCrumbs from 'domains/characterizations/components/BaseORRosteringCharacterizationsBreadCrumbs'
import colors from 'lib/styles/colors'
import {
  AnySpecFormatType,
  ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK,
  ONER_V1P1_CSV_RESOURCES_EXPORT_BULK,
  ONER_V1P1_CSV_ROSTERING_EXPORT_BULK,
  ONER_V1P2_CSV_ROSTERING_EXPORT_BULK,
  ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL,
  ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL,
} from 'domains/formats/constants/formats'
import CreateORCharacterizationSourceDetails from 'domains/characterizations/components/CreateCharacterization/CreateORCharacterizationSourceDetails'
import CreateCharacterizationBasicDetails from 'domains/characterizations/components/CreateCharacterization/CreateCharacterizationBasicDetails'
import CreateCharacterizationFileDetails from 'domains/characterizations/components/CreateCharacterization/CreateCharacterizationFileDetails'
import { INewCharacterization } from 'domains/characterizations/models/INewCharacterization'
import {
  getCharacterizationRoute,
  getCharacterizationsListRoute,
} from 'domains/characterizations/navigation/routes'
import { RouteComponentProps } from 'react-router'
import trackCharacterizationAnalytics from 'domains/characterizations/utils/trackCharacterizationAnalytics'
import { OR_CSV_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/orCSVCharacterizations'
import { waitForServer } from 'lib/utils/wait'
import { showToast, ERROR_TOAST } from 'lib/utils/toast'
import {
  OTHER_SOURCE,
  UPLOAD_ZIP_FILE_SOURCE,
  URL_ZIP_FILE_SOURCE,
} from 'domains/characterizations/constants/createCharacterizationCSV'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import CreateCharacterizationRestAuthDetails from 'domains/characterizations/components/CreateCharacterization/CreateCharacterizationRestAuthDetails'
import { OAUTH_2_REST_AUTH } from 'domains/formats/constants/OneRosterREST'
import { createCharacterizationREST } from 'domains/characterizations/workflows/createCharacterizationREST'
import { OR_REST_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/orRESTCharacterizations'
import ReportThreeTopSection from 'domains/reports/components/ReportThreeTopSection'
import { createFileCharacterization } from 'domains/characterizations/workflows/createFileCharacterization'
import { ONE_ROSTER_SPEC } from 'domains/specifications/constants/oneRoster'
import { isSupplierRoleSelector } from 'domains/authentication/selectors/roles'
import { CharacterizationProfileTypes } from 'domains/characterizations/models/ICharacterization'
import { createCharacterizationRESTPush } from 'domains/characterizations/workflows/createCharacterizationRESTPush'
import CreateCharacterizationGBPushDetails from 'domains/characterizations/components/CreateCharacterization/CreateCharacterizationGBPushDetails'

const credsValidation = Yup.string().when(['zipFileSource', 'restAuthMethod'], {
  is: (zipFileSource) => zipFileSource === URL_ZIP_FILE_SOURCE,
  then: Yup.string().required('Required'),
  otherwise: Yup.string(),
})
const shouldDoRESTValidation = (format: string) =>
  format === ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL ||
  format === ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL ||
  format === ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL
const restValidation = Yup.string().when(['format'], {
  is: shouldDoRESTValidation,
  then: Yup.string().required('Required'),
  otherwise: Yup.string(),
})
const getValidationSchema = (isSupplier: boolean) =>
  Yup.object().shape({
    label: Yup.string().required('Required'),
    source: Yup.string().required('Required'),
    otherSource: Yup.string().when(['source'], {
      is: (source) => source === OTHER_SOURCE,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    sourceVersion: Yup.string().when(['source'], {
      is: () => isSupplier,
      then: Yup.string()
        .max(50, 'Must be 50 characters or less')
        .required('Required'),
      otherwise: Yup.string(),
    }),
    certifiedSourceLevel: Yup.string(),
    districtName: Yup.string().when(['source'], {
      is: () => isSupplier,
      then: Yup.string()
        .max(255, 'Must be 255 characters or less')
        .required('Required'),
      otherwise: Yup.string(),
    }),
    districtState: Yup.string().max(255, 'Must be 255 characters or less'),
    districtCountry: Yup.string().max(2, 'Must be 2 characters or less'),
    format: Yup.mixed()
      .oneOf(
        [
          ONER_V1P1_CSV_ROSTERING_EXPORT_BULK,
          ONER_V1P2_CSV_ROSTERING_EXPORT_BULK,
          ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK,
          ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL,
          ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL,
          ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL,
          ONER_V1P1_CSV_RESOURCES_EXPORT_BULK,
          ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL,
        ],
        'Please select a specification',
      )
      .required('Required'),
    profile: Yup.mixed()
      .oneOf(['basic', 'sceds'], 'Please select a profile')
      .required('Required'),
    zipFileURL: Yup.string().when(['zipFileSource'], {
      is: (zipFileSource) => zipFileSource === URL_ZIP_FILE_SOURCE,
      then: Yup.string().url('Invalid URL').required('Required'),
      otherwise: Yup.string(),
    }),
    zipFile: Yup.mixed().when(['format', 'zipFileSource'], {
      is: (format, zipFileSource) =>
        (format === ONER_V1P1_CSV_ROSTERING_EXPORT_BULK ||
          format === ONER_V1P2_CSV_ROSTERING_EXPORT_BULK ||
          format === ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK ||
          format === ONER_V1P1_CSV_RESOURCES_EXPORT_BULK) &&
        zipFileSource === UPLOAD_ZIP_FILE_SOURCE,
      then: Yup.mixed()
        .required('Required')
        .test(
          'isZipFile',
          'File is too big. Max 1GB.',
          (zipFile) =>
            utils.hasValue(zipFile) &&
            !utils.files.isFileLargerThanOneGB(zipFile.size),
        )
        .test(
          'isZipFile',
          'Only zip files may be uploaded',
          (zipFile) =>
            utils.hasValue(zipFile) &&
            utils.files.hasFileExtension(
              zipFile.name,
              utils.files.ZIP_EXTENSION,
            ),
        ),
      otherwise: Yup.mixed(),
    }),
    hostName: Yup.string().when(['format'], {
      is: shouldDoRESTValidation,
      then: utils.host.isInTestMode
        ? Yup.string().required('Required')
        : Yup.string().url('Invalid URL').required('Required'),
      otherwise: Yup.string(),
    }),
    consumerKey: restValidation,
    consumerSecret: restValidation,
    oauth2server: Yup.string().when(['restAuthMethod'], {
      is: (restAuthMethod) => restAuthMethod === OAUTH_2_REST_AUTH,
      then: utils.host.isInTestMode
        ? Yup.string().required('Required')
        : Yup.string().url('Invalid URL').required('Required'),
      otherwise: Yup.string(),
    }),
    username: credsValidation,
    password: credsValidation,
  })

interface ICreateCharacterization extends INewCharacterization {
  uploadProgress: number
}
const defaultCharacterization: ICreateCharacterization = {
  format: 'Select One',
  profile: CharacterizationProfileTypes.basic,
  color: colors.primary,
  label: '',
  notes: '',

  source: '',
  otherSource: '',
  sourceVersion: '',

  districtName: '',
  districtState: '',
  districtCountry: 'US',

  zipFileSource: 'upload',
  zipFile: null,
  zipFileURL: '',

  username: '',
  password: '',

  uploadProgress: 0,
}

export default function CreateORCharacterizationScreen(
  props: RouteComponentProps,
) {
  const WIDGET_HEIGHT = 720
  const isMounted = React.useRef(false)
  const isSupplier = useSelector(isSupplierRoleSelector)
  const [characterizationId, setCharacterizationId] = React.useState(0)

  React.useEffect(() => {
    isMounted.current = true
    trackCharacterizationAnalytics('started_create_characterization', {
      type: 'OneRoster',
    })
    return () => {
      isMounted.current = false
    }
  }, [])

  const onORV1P2CSVExportBulk = async (
    values: INewCharacterization,
    bag: FormikHelpers<ICreateCharacterization>,
  ) => {
    const format = values.format! as AnySpecFormatType
    const onProgress = (progress: number) =>
      bag.setFieldValue('uploadProgress', progress)
    const characterization = await createFileCharacterization(
      OR_CSV_CHARACTERIZATIONS_RECORD,
      values,
      onProgress,
    )
    if (!characterization) {
      return false
    }

    setCharacterizationId(characterization.id)
    const [timedOut, isComplete] = await waitForServer(
      OR_CSV_CHARACTERIZATIONS_RECORD,
      characterization.id,
      (data) => utils.hasValue(R.propOr(false, 'status', data)),
    )

    if (isMounted.current) {
      bag.setSubmitting(false)
      if (timedOut) {
        showToast(
          'info',
          'Your report is still processing. Please checkb back later.',
        )
        props.history.push(getCharacterizationsListRoute(format))
      } else if (isComplete) {
        const route = getCharacterizationRoute(characterization.id, format)
        props.history.push(route)
      } else {
        showToast(ERROR_TOAST, 'Failed to create characterization')
        bag.setSubmitting(false)
      }
    }
  }

  const onORV1P1CSVExportBulk = async (
      values: INewCharacterization,
      bag: FormikHelpers<ICreateCharacterization>,
    ) => {
      const format = values.format! as AnySpecFormatType
      const onProgress = (progress: number) =>
        bag.setFieldValue('uploadProgress', progress)
      const characterization = await createFileCharacterization(
        OR_CSV_CHARACTERIZATIONS_RECORD,
        values,
        onProgress,
      )
      if (!characterization) {
        return false
      }

      setCharacterizationId(characterization.id)
      const [timedOut, isComplete] = await waitForServer(
        OR_CSV_CHARACTERIZATIONS_RECORD,
        characterization.id,
        (data) => utils.hasValue(R.propOr(false, 'status', data)),
      )

      if (isMounted.current) {
        bag.setSubmitting(false)
        if (timedOut) {
          showToast(
            'info',
            'Your report is still processing. Please check back later.',
          )
          props.history.push(getCharacterizationsListRoute(format))
        } else if (isComplete) {
          const route = getCharacterizationRoute(characterization.id, format)
          props.history.push(route)
        } else {
          showToast(ERROR_TOAST, 'Failed to create characterization')
          bag.setSubmitting(false)
        }
      }
    }
  const onORV1P1RESTOptional = async (
    values: INewCharacterization,
    bag: FormikHelpers<ICreateCharacterization>,
  ) => {
    const format = values.format! as AnySpecFormatType
    const characterization = await createCharacterizationREST(values)
    if (!characterization) {
      return false
    }
    setCharacterizationId(characterization.id)
    const [timedOut, isComplete] = await waitForServer(
      OR_REST_CHARACTERIZATIONS_RECORD,
      characterization.id,
      (data) => utils.hasValue(R.propOr(false, 'status', data)),
    )

    if (isMounted.current) {
      bag.setSubmitting(false)
      if (timedOut) {
        showToast(
          'info',
          'Your report is still processing. Please checkb back later.',
        )
        props.history.push(getCharacterizationsListRoute(format))
      } else if (isComplete) {
        const route = getCharacterizationRoute(characterization.id, format)
        props.history.push(route)
      } else {
        showToast(ERROR_TOAST, 'Failed to create characterization')
        bag.setSubmitting(false)
      }
    }
  }

  const onGBPush = async (
    values: INewCharacterization,
    bag: FormikHelpers<ICreateCharacterization>,
  ) => {
    const characterization = await createCharacterizationRESTPush(values)
    if (!characterization) {
      return false
    }

    bag.setSubmitting(false)
    const route = getCharacterizationRoute(
      characterization.id,
      ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL,
    )
    props.history.push(route)
  }

  const onSave = async (
    values: INewCharacterization,
    bag: FormikHelpers<ICreateCharacterization>,
  ) => {
    try {
      let result
      switch (values.format) {
        case ONER_V1P1_CSV_ROSTERING_EXPORT_BULK:
        case ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK:
        case ONER_V1P1_CSV_RESOURCES_EXPORT_BULK:
          result = await onORV1P1CSVExportBulk(values, bag)
          break
        case ONER_V1P2_CSV_ROSTERING_EXPORT_BULK:
            result = await onORV1P2CSVExportBulk(values, bag)
            break
        case ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL:
        case ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL:
        case ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL:
          result = await onORV1P1RESTOptional(values, bag)
          break
        case ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL:
          result = await onGBPush(values, bag)
          break
      }

      bag.setSubmitting(false)
      if (result === false) {
        showToast(ERROR_TOAST, 'Failed to create characterization')
      }
    } catch (e) {
      bag.setSubmitting(false)
    }
  }

  const renderFinalWidget = (bag: FormikProps<ICreateCharacterization>) => {
    if (
      !utils.hasValue(bag.values.format) ||
      bag.values.format === 'Select One'
    ) {
      return (
        <DetailsTopWidget
          title="Characterization..."
          dataTest="characterization-placeholder-widget"
          height={WIDGET_HEIGHT}
        >
          <Text mt={4} fontSize={[16, 16, 16, 18]} lineHeight="26px" ml={2}>
            Select a specification...
          </Text>
        </DetailsTopWidget>
      )
    }

    switch (bag.values.format) {
      case ONER_V1P1_CSV_ROSTERING_EXPORT_BULK:
      case ONER_V1P2_CSV_ROSTERING_EXPORT_BULK:
      case ONER_V1P1_CSV_GRADEBOOK_EXPORT_BULK:
      case ONER_V1P1_CSV_RESOURCES_EXPORT_BULK:
        return (
          <CreateCharacterizationFileDetails
            format={bag.values.format}
            zipFileSource={bag.values.zipFileSource}
            zipFile={bag.values.zipFile}
            zipFileURL={bag.values.zipFileURL}
            username={bag.values.username || ''}
            password={bag.values.password || ''}
            isSubmitting={bag.isSubmitting}
            handleSubmit={bag.handleSubmit}
            handleChange={bag.handleChange}
            setFieldValue={bag.setFieldValue}
            uploadProgress={bag.values.uploadProgress}
            characterizationId={characterizationId}
            height={WIDGET_HEIGHT}
          />
        )
      case ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL:
      case ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL:
      case ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL:
        return (
          <CreateCharacterizationRestAuthDetails
            format={bag.values.format}
            isSubmitting={bag.isSubmitting}
            handleSubmit={bag.handleSubmit}
            handleChange={bag.handleChange}
            setFieldValue={bag.setFieldValue}
            height={WIDGET_HEIGHT}
            consumerKey={bag.values.consumerKey}
            hostName={bag.values.hostName}
            restAuthMethod={bag.values.restAuthMethod}
            consumerSecret={bag.values.consumerSecret}
            oauth2server={bag.values.oauth2server}
            username={bag.values.username}
            password={bag.values.password}
          />
        )
      case ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL:
        return (
          <CreateCharacterizationGBPushDetails
            isSubmitting={bag.isSubmitting}
            handleSubmit={bag.handleSubmit}
            height={WIDGET_HEIGHT}
          />
        )
      default:
        return null
    }
  }

  return (
    <View variant="screen">
      <BaseORRosteringCharacterizationsBreadCrumbs
        crumbs={[{ name: 'Add New Characterization' }]}
      />

      <Formik
        initialValues={defaultCharacterization}
        validationSchema={getValidationSchema(isSupplier)}
        onSubmit={onSave}
        enableReinitialize={true}
      >
        {(bag) => {
          return (
            <Form encType="multipart/form-data" autoComplete="off">
              <ReportThreeTopSection>
                <CreateCharacterizationBasicDetails
                  format={bag.values.format}
                  color={bag.values.color}
                  label={bag.values.label}
                  handleChange={bag.handleChange}
                  setFieldValue={bag.setFieldValue}
                  height={WIDGET_HEIGHT}
                  specificationName={ONE_ROSTER_SPEC}
                />

                <CreateORCharacterizationSourceDetails
                  format={bag.values.format}
                  source={bag.values.source}
                  otherSource={bag.values.otherSource}
                  sourceVersion={bag.values.sourceVersion}
                  districtName={bag.values.districtName}
                  districtState={bag.values.districtState}
                  districtCountry={bag.values.districtCountry}
                  notes={bag.values.notes}
                  handleChange={bag.handleChange}
                  setFieldValue={bag.setFieldValue}
                  height={WIDGET_HEIGHT}
                />

                {renderFinalWidget(bag)}
              </ReportThreeTopSection>

              {utils.hasValue(bag.values.format) &&
                bag.values.format !== 'Select One' && (
                  <View mt={2}>
                    <InlineAlert
                      variant="info"
                      message={`Uploaded or accessed data is not retained by Compatibility Check and all files are deleted at the end of characterization.`}
                      extraMessage={
                        bag.values.format ===
                          ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL ||
                        bag.values.format ===
                          ONER_V1P1_REST_PROVIDER_GRADEBOOK_PULL_OPTIONAL ||
                        bag.values.format ===
                          ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL ||
                        bag.values.format ===
                          ONER_V1P1_REST_PROVIDER_RESOURCES_OPTIONAL
                          ? 'REST implementations MUST at least support EITHER the OAuth 1.0a message signing (with SHA-256) OR the OAuth 2 Bearer Token (Client Credentials with Scopes) security mechanism.\nSupport for OAuth 1.0a/SHA1 is deprecated and is ONLY available for certification until 31st December 2019.'
                          : ''
                      }
                    />
                  </View>
                )}
            </Form>
          )
        }}
      </Formik>
    </View>
  )
}
