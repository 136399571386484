import React from 'react'

import {
  Text,
  View,
  Button,
  FormInput,
  InputLabel,
  Select,
} from 'lib/components'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import {
  RestAuthTypes,
  OAUTH_2_REST_AUTH,
  OAUTH_1A_REST_AUTH,
} from 'domains/formats/constants/OneRosterREST'
import CharacterizationInProgress from 'domains/characterizations/components/CreateCharacterization/CharacterizationInProgress'
import { AnySpecFormatType } from 'domains/formats/constants/formats'

interface IProps {
  isSubmitting: boolean
  handleSubmit: () => void
  handleChange: (event: any) => void
  setFieldValue: (key: string, value: any) => void
  format?: AnySpecFormatType | 'Select One'
  hostName?: string
  restAuthMethod?: RestAuthTypes
  consumerKey?: string
  consumerSecret?: string
  oauth2server?: string
  username?: string
  password?: string
  height: number
}

export default function CreateCharacterizationRestAuthDetails(props: IProps) {
  return (
    <DetailsTopWidget
      title={`REST ${props.restAuthMethod} Configuration`}
      dataTest="characterization-rest-auth-widget"
      height={props.height}
    >
      <View
        mt={3}
        width="100%"
        height="95%"
        flexible="column-space-between"
        flex={1}
      >
        {props.isSubmitting && (
          <View width="100%" flex={1} flexible="column-center">
            <CharacterizationInProgress />
          </View>
        )}
        {!props.isSubmitting && (
          <View width="100%" flexible="column">
            <FormInput
              label="Hostname"
              required={true}
              name="hostName"
              placeholder="https://your-oneroster-rest-provider.com"
              value={props.hostName || ''}
              valueDataTest="hostName-input"
              handleChange={props.handleChange}
            />

            <View width="100%" mb={2}>
              <InputLabel data-test="security-config-label">
                Security Configuration
              </InputLabel>
              <Select
                name="restAuthMethod"
                value={props.restAuthMethod}
                onChange={props.handleChange}
                variant="bold"
                width="100%"
                data-test="rest-auth-select"
                aria-label="REST Authentication Method"
              >
                <option value={OAUTH_1A_REST_AUTH}>{OAUTH_1A_REST_AUTH}</option>
                <option value={OAUTH_2_REST_AUTH}>{OAUTH_2_REST_AUTH}</option>
              </Select>
            </View>

            <FormInput
              label="Consumer Key"
              required={true}
              name="consumerKey"
              placeholder="1234abcd"
              value={props.consumerKey || ''}
              valueDataTest="consumerKey-input"
              handleChange={props.handleChange}
            />
            <FormInput
              label="Consumer Secret"
              required={true}
              name="consumerSecret"
              placeholder="**************"
              value={props.consumerSecret || ''}
              valueDataTest="consumerSecret-input"
              handleChange={props.handleChange}
              type="password"
            />
            {props.restAuthMethod === OAUTH_2_REST_AUTH && (
              <View mt={2}>
                <FormInput
                  label="Access Token URL"
                  required={true}
                  name="oauth2server"
                  placeholder="https://youraccesstokenurl.net"
                  value={props.oauth2server || ''}
                  valueDataTest="oauth2server-input"
                  handleChange={props.handleChange}
                />
              </View>
            )}

            <Button
              type="submit"
              onClick={props.handleSubmit}
              variant="start"
              width="100%"
              mb={2}
              py={3}
              data-test="submit-characterization-btn"
              pending={props.isSubmitting}
            >
              <Text color="white" fontSize={16}>
                Submit Characterization
              </Text>
            </Button>
          </View>
        )}
      </View>
    </DetailsTopWidget>
  )
}
