import * as R from 'ramda'

import ITransformedRESTCharacterizationReport, {
  ITransformedRESTServiceCharacterizationReport,
} from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import { isServiceReportable } from 'domains/modeling/utils/restCOR1p1utils'
import useAccordionHeaderState from 'domains/reports/hooks/useAccordionHeaderState'

export default function useORRESTCharacterizationAccordionHeaderStates(
  report?: ITransformedRESTCharacterizationReport,
  ignoreReportable?: boolean,
) {
  const canOpenService = (
    service: ITransformedRESTServiceCharacterizationReport,
  ) => !!isServiceReportable(service)

  return useAccordionHeaderState(
    R.pathOr([], ['serviceCharacterizations'], report).map((s: any) => ({
      ...s,
      name: s.serviceName,
    })),
    ignoreReportable ? undefined : canOpenService,
  )
}
