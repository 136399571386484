import React, { PureComponent } from 'react'
import * as R from 'ramda'

import { View, Icon } from 'lib/components'
import ITransformedRESTCharacterizationReport, {
  ITransformedRESTServiceCharacterizationReport,
} from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import ReportStatusRow from 'domains/reports/components/ReportStatusRow'
import { isWholeServiceDisabled } from 'domains/modeling/utils/restCOR1p1utils'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import { GRAY_REPORT_STATUS } from 'domains/reports/models/IReportStatuses'

interface IProps {
  report: ITransformedRESTCharacterizationReport
  height: number
  isProvider?: boolean
}

export default class RESTCharacterizationReportOverviewWidget extends PureComponent<IProps> {
  renderServiceRow = (
    service: ITransformedRESTServiceCharacterizationReport,
  ) => {
    const allDisabled =
      (this.props.isProvider && service.status === GRAY_REPORT_STATUS) ||
      (!this.props.isProvider &&
        !this.props.report.failedCharacterization &&
        isWholeServiceDisabled(service))
    return (
      <ReportStatusRow
        key={service.serviceName}
        label={service.serviceName}
        status={allDisabled ? 'GRAY' : service.status}
        circle={allDisabled}
        data-test={`overview-item-status-${service.serviceName}`}
      />
    )
  }

  renderServices() {
    const { serviceCharacterizations } = this.props.report

    if (serviceCharacterizations.length < 1) {
      return (
        <View
          flexible="column-center"
          mt={4}
          data-test="characterization-overview-widget"
        >
          <View
            flexible="column-center"
            borderRadius="50%"
            bg="secondary"
            width={90}
            height={90}
          >
            <Icon
              fontSize={48}
              className="fas fa-question"
              color="white"
              data-test="unkown-status"
            />
          </View>
        </View>
      )
    }

    return R.sort(
      R.ascend(R.prop('serviceName')),
      serviceCharacterizations,
    ).map(this.renderServiceRow)
  }

  render() {
    return (
      <DetailsTopWidget
        title="Report Overview"
        dataTest="characterization-overview-widget"
        height={this.props.height}
      >
        <View height="100%" overflowY="auto">
          {this.renderServices()}
        </View>
      </DetailsTopWidget>
    )
  }
}
