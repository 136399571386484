import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { utils } from '@ims/1edtech-frontend-common'

import ModelFileColumnEditorModal from 'domains/modeling/modals/AddEditModelFileColumnModal/ModelFileColumnEditorModal'
import { myOrganizationPropSelector } from 'domains/authentication/selectors/organizations'
import { RootState } from 'lib/store/rootReducer'

interface IPropsFromState {
  supplierName: string
}
const stateMap = createStructuredSelector<RootState, any, IPropsFromState>({
  supplierName: myOrganizationPropSelector('name'),
})

interface IProps {
  isOpen: boolean
  closeModal: (wasSaved: boolean, wasCleared: boolean) => any
  modelId: string | number
  fileName: string
}

export class AddModelFileColumnModal extends PureComponent<
  IProps & IPropsFromState
> {
  getSupplierName = () =>
    utils.string.removeAllWhitespace(this.props.supplierName).toLowerCase()

  render() {
    return (
      <ModelFileColumnEditorModal
        {...this.props}
        supplierName={this.getSupplierName()}
        isCreate={true}
      />
    )
  }
}

export default connect(stateMap)(AddModelFileColumnModal)
