import Amplitude from 'amplitude-js'
import * as R from 'ramda'

const PRODUCTION_HOST = 'app.1edtech.org'
const STAGING_HOST = 'staging.1edtech.org'

export const getAmplitudeInstance = () => Amplitude.getInstance()

function gtag(...args: any) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(args)
}

export const setupEventTracking = () => {
  const production = process.env.NODE_ENV === 'production'

  if (!production) {
    return
  }

  getAmplitudeInstance().init('6274810ee6c55eb033b4b4e3177e3952')

  // Google Analytics
  const hostname = R.pathOr<string>('N/A', ['location', 'hostname'], window)
  if (R.contains(hostname, [PRODUCTION_HOST, STAGING_HOST])) {
    // @ts-ignore
    gtag('js', new Date())
    const tagId =
      hostname === PRODUCTION_HOST ? 'UA-25297781-9' : 'UA-25297781-12'
    // @ts-ignore
    gtag('config', tagId)
  }
}

export const logEventWithProperties = (
  eventName: string,
  eventAction: string,
  properties: any,
) => {
  const production = process.env.NODE_ENV === 'production'
  if (!production) {
    return
  }

  let eventProperties = properties || {}

  if (eventAction) {
    eventProperties = R.assoc('Action', eventAction, eventProperties)
  }

  getAmplitudeInstance().logEvent(eventName, eventProperties)
}

export const trackDomainAnalytics = R.curryN(2, (domain, action, properties) =>
  logEventWithProperties(domain, action, properties),
)

export default setupEventTracking
