import React, { PureComponent } from 'react'

import { Text, View } from 'lib/components'
import { ITextProps } from 'lib/components/Text'

interface IProps extends ITextProps {
  children: any
}

export default class ListItalicTextOnlyCell extends PureComponent<IProps> {
  render() {
    const { children, ...rest } = this.props
    return (
      <View flexible="row-center">
        <Text
          variant="caption"
          color="textLight"
          textAlign="center"
          maxWidth="120px"
          pr="12px"
          fontStyle="italic"
          {...rest}
        >
          {children}
        </Text>
      </View>
    )
  }
}
