import React from 'react'

import IComparison from 'domains/comparisons/models/IComparison'
import { Switch, View } from 'lib/components'
import { updateComparisonSharing } from 'domains/comparisons/workflows/updateComparisonSharing'

export default function ComparisonStatusCell(props: IComparison) {
  const onChangeStatus = () =>
    updateComparisonSharing(props.id, props.format, !props.shared)

  return (
    <View flexible="column-center" maxWidth={86}>
      <Switch
        on={props.shared}
        onChange={onChangeStatus}
        onIconName="fas fa-eye"
        offIconName="fas fa-eye-slash"
        dataTest={`share-comparison-switch-${props.id}`}
      />
    </View>
  )
}
