import React, { PureComponent } from 'react'
import * as R from 'ramda'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import memoize from 'fast-memoize'
import BottomScrollListener from 'react-bottom-scroll-listener'
import { utils, records } from '@ims/1edtech-frontend-common'

import Icon from 'lib/components/Icon'
import Spinner from 'lib/components/Spinner'
import Text from 'lib/components/Text'
import View from 'lib/components/View'
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableHeader,
  TableDefinition,
} from 'lib/components/Table'
import BlankSlate from 'lib/components/BlankSlate'
import { History } from 'history'
import ToolTip from 'lib/components/ToolTip'
import { getRecords, DEFAULT_PAGE_SIZE } from 'lib/records/workflows/getRecords'
import { clearPaging } from 'lib/records/workflows/paging'
import ListPlainTextCell from 'lib/components/ListPlainTextCell'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import { SearchBox } from 'lib/components'
import { withScreenSize, IScreenSizeProps } from 'lib/utils/screenSize'
import ListFilter from 'lib/components/ListFilter'
import { IListFilter } from 'lib/components/ListFilter/listFilterUtils'
import ListLegend, { IListLegend } from 'lib/components/ListFilter/ListLegend'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'
import { twMerge } from 'tailwind-merge'
import styles from './List.module.css'

const isPrint = isOnPrintScreen()

export const getListRowBackground = memoize((index) =>
  index % 2 === 0 ? 'white' : 'background',
)

type IAccessorFunction<T> = (item: any) => T

export interface IListColumnDefinition {
  accessor?: string | IAccessorFunction<string>
  autoToolTip?: boolean
  CellComponent?: any
  renderCell?: (
    item: any,
    rowIndex: number,
    history: History,
    isSubRow: boolean,
  ) => any
  cellProps?: any
  maxWidth?: string | number | (string | number)[]
  forceCenter?: boolean
  getColumnProps?: (item: any, rowIndex: number) => any
  noCenter?: boolean
  noHeaderCenter?: boolean
  noSort?: boolean
  shouldRender?: (item: any, rowIndex: number, isSubRow: boolean) => boolean
  shouldRenderEmpty?: (
    item: any,
    rowIndex: number,
    isSubRow: boolean,
  ) => boolean
  sortKey?: ((sortAscending: boolean) => string) | string
  sort?: (items: any[], sortAscending: boolean) => any[]
  sortReverse?: boolean
  title: string | null
  titleColSpan?: number
  titleProps?: any
  titleRender?: any
  noAutomaticPadding?: boolean
}

export interface IListFilterOptions {
  title: string
  filters: IListFilter[]
}

interface IProps {
  blankSlateText?: string
  callToAction?: any
  canClickRow?: (item: any, rowIndex: number) => boolean
  columns?: IListColumnDefinition[]
  customList?: any
  dataTest?: string
  defaultPageSize?: number
  defaultSort?: string
  getRecordsParams?: any
  baseFilterParam?: string
  filterOptions?: IListFilterOptions
  query?: string
  onQueryChange?: (query: string) => any
  getRowProps?: (item: any, rowIndex: number, isSubRow: boolean) => any
  getSubItems?: (item: any, rowIndex: number) => any[]
  initialSortColumnIndex?: number
  initialSortDescending?: boolean
  keyExtractor?: (item: any, isSubRow: boolean) => string | number
  legends?: IListLegend[]
  noHeader?: boolean
  noSearch?: boolean
  noSort?: boolean
  nonRecordItems?: any[]
  noWrapper?: boolean
  onRowClick?: (item: any, isSubRow: boolean) => void
  parentRecordId?: string | number
  record?: string
  recordState?:
    | records.recordReducers.IRecordState
    | records.recordReducers.ITypedRecordState<any>
  renderSubRow?: (item: any, rowIndex: number) => any
  resetPaging?: boolean
  resetListToggle?: boolean
  subRowLeftPad?: number
  rowHeight?: number
  searchTemplate?: string // "name=@ OR filename=@" -> The query will replace all instances of `@`
  searchShouldClearSort?: boolean
  showBlankSlate?: boolean
  shouldRenderRow?: (item: any, rowIndex: number, isSubRow: boolean) => boolean
  spacer?: boolean
  title?: string
  trackAnalytics?: (action: string, eventProperties?: any) => any
  trackAnalyticsCategory?: string

  headerClassName?: string
}

interface IState {
  loading: false
  page: number
  pageSize: number
  sortAscending: boolean
  sortColumn: string
  sortColumnIndex: number
  filter: string
  clearSort: boolean
  filtersOpen: boolean
  advancedFilter: string
}

const getSortColumn = (
  columns: IListColumnDefinition[],
  sortColumnIndex: number,
  sortAscending: boolean,
  index: number,
  initial = false,
) => {
  const sortColumn = R.pathOr<(asc: boolean) => any>(
    () => null,
    [index, 'sortKey'],
    columns,
  )
  const asc = initial
    ? sortAscending
    : index === sortColumnIndex
    ? !sortAscending
    : true
  if (sortColumn) {
    return R.is(Function, sortColumn) ? sortColumn(asc) : sortColumn
  }
  return ''
}

let infiniteScrollTimeout: any

type ListProps = IScreenSizeProps & RouteComponentProps & IProps
export class List extends PureComponent<ListProps> {
  readonly state: IState = {
    page: 1,
    pageSize: this.props.defaultPageSize || DEFAULT_PAGE_SIZE,
    sortColumnIndex: this.props.noSort
      ? -1
      : this.props.initialSortColumnIndex || 0,
    sortColumn: getSortColumn(
      this.props.columns || [],
      this.props.initialSortColumnIndex || 0,
      !this.props.initialSortDescending,
      this.props.initialSortColumnIndex || 0,
      true,
    ),
    sortAscending: !this.props.initialSortDescending,
    loading: false,
    filter: this.props.query || '',
    clearSort: false,
    filtersOpen:
      utils.hasValue(this.props.filterOptions) && !this.props.smallerThanMedium,
    advancedFilter: '',
  }

  componentDidMount() {
    // Filter will run callback once it has calculated default filters
    if (!utils.hasValue(this.props.filterOptions)) {
      this.getRecords()
    }
  }

  getRecordsOnPropsChangedTimeout: any = null
  componentDidUpdate(prevProps: ListProps) {
    if (!prevProps.smallerThanMedium && this.props.smallerThanMedium) {
      this.setState({ filtersOpen: false })
    } else if (prevProps.smallerThanMedium && !this.props.smallerThanMedium) {
      this.setState({ filtersOpen: true })
    }
    if (!prevProps.resetPaging && this.props.resetPaging)
      this.setState({ page: 1 }, this.getRecords)

    if (prevProps.resetListToggle !== this.props.resetListToggle)
      this.getRecords()

    if (
      prevProps.baseFilterParam !== this.props.baseFilterParam ||
      prevProps.query !== this.props.query ||
      prevProps.searchTemplate !== this.props.searchTemplate
    ) {
      if (this.getRecordsOnPropsChangedTimeout)
        clearTimeout(this.getRecordsOnPropsChangedTimeout)
      this.getRecordsOnPropsChangedTimeout = setTimeout(() => {
        this.setState({ page: 1, filter: this.props.query }, this.getRecords)
      }, 250)
    }
    if (prevProps.parentRecordId !== this.props.parentRecordId) {
      this.getRecords()
    }
  }

  componentWillUnmount() {
    const { record, parentRecordId } = this.props
    if (record) {
      clearPaging(record, parentRecordId)
    }
  }

  onToggleFilterOpen = () =>
    this.setState((s: IState) => ({ filtersOpen: !s.filtersOpen }))

  onFilterChanged = (advancedFilter: string) =>
    this.setState({ advancedFilter, page: 1 }, this.getRecords)

  onSearchChange = (filter: string) => {
    const callback =
      utils.hasValue(this.props.filterOptions) &&
      this.props.smallerThanMedium &&
      this.state.filtersOpen
        ? undefined
        : this.getRecords
    this.setState(
      (state: IState) => ({
        filter,
        page: 1,
        clearSort: this.props.searchShouldClearSort || false,
        sortColumnIndex: this.props.searchShouldClearSort
          ? -1
          : state.sortColumnIndex,
      }),
      callback,
    )
    if (this.props.onQueryChange) this.props.onQueryChange(filter)
  }

  getRecords = async () => {
    const {
      record,
      parentRecordId,
      getRecordsParams = {},
      baseFilterParam = '',
      defaultSort,
      searchTemplate = '@@',
    } = this.props
    const { page, pageSize, sortColumn, filter, advancedFilter, clearSort } =
      this.state
    if (record) {
      const sortColumnParams =
        clearSort && utils.hasValue(filter)
          ? {}
          : utils.hasValue(defaultSort)
          ? { sort: defaultSort }
          : utils.hasValue(sortColumn)
          ? { sort: sortColumn }
          : {}
      let search =
        utils.hasValue(filter) && filter.length > 1
          ? searchTemplate.replace(new RegExp('@@', 'g'), filter)
          : ''
      if (utils.hasValue(advancedFilter)) {
        search = utils.hasValue(search)
          ? `${search} AND ${advancedFilter}`
          : advancedFilter
      }
      if (utils.hasValue(baseFilterParam)) {
        search = utils.hasValue(search)
          ? `${baseFilterParam} AND ${search}`
          : baseFilterParam
      }
      const searchParams = utils.hasValue(search) ? { filter: search } : {}
      await getRecords({
        record,
        parentRecordId,
        params: { ...sortColumnParams, ...getRecordsParams, ...searchParams },
        page,
        pageSize,
      })
      this.setState({ loading: false })
    }
  }

  onGetNextPage = () => {
    this.setState((s: IState) => ({ page: s.page + 1 }), this.getRecords)
  }

  getNextPage = () => {
    const { recordState } = this.props
    if (
      utils.hasValue(recordState) &&
      R.pathOr(false, ['paging', 'nextPage'], recordState) !== false &&
      !this.state.loading
    ) {
      this.setState({ loading: true })
      clearTimeout(infiniteScrollTimeout)
      infiniteScrollTimeout = setTimeout(this.onGetNextPage, 150)
    }
  }

  onRowClick = (row: any, rowIndex: number, isSubRow: boolean) => () => {
    const { onRowClick, canClickRow } = this.props
    if (!onRowClick || (canClickRow && !canClickRow(row, rowIndex))) {
      return
    }

    onRowClick(row, isSubRow)
  }

  onSortBy = (index: number) => () => {
    const { columns, trackAnalytics, trackAnalyticsCategory } = this.props
    const sortAscending =
      index === this.state.sortColumnIndex ? !this.state.sortAscending : true
    this.setState(
      (state: IState) => ({
        sortColumnIndex: index,
        sortColumn: getSortColumn(
          columns || [],
          state.sortColumnIndex,
          state.sortAscending,
          index,
        ),
        sortAscending,
        page: 1,
        clearSort: false,
      }),
      this.getRecords,
    )
    if (trackAnalytics) {
      const title = R.pathOr('N/A', [index, 'title'], columns)
      const trackSortName = `sorted_${trackAnalyticsCategory}`
      const trackSortProperties = {
        title,
        ascending: sortAscending,
        descending: !sortAscending,
      }
      trackAnalytics(trackSortName, trackSortProperties)
    }
  }

  sort = memoize((items, sortColumnIndex, sortAscending) => {
    const { columns } = this.props
    const sort = R.pathOr<(items: any[], sort: boolean) => any[]>(
      R.identity,
      [sortColumnIndex, 'sort'],
      columns,
    )
    if (sort) {
      const sorted = sort(items, sortAscending)
      return sorted
    }
    const sortKey = R.pathOr(false, [sortColumnIndex, 'sortKey'], columns)
    const sortReverse = R.pathOr(
      false,
      [sortColumnIndex, 'sortReverse'],
      columns,
    )
    if (sortKey) {
      return utils.sortAlphabetically(
        sortReverse ? !sortAscending : sortAscending,
        sortKey,
      )(items)
    }

    return items
  })

  isSortedHeader = (index: number) =>
    R.equals(this.state.sortColumnIndex, index)

  renderHeader = (
    {
      title,
      titleRender = null,
      titleColSpan = 1,
      titleProps = {},
      noHeaderCenter = false,
      noSort = false,
      noAutomaticPadding = false,
      forceCenter = false,
    }: any,
    index: number,
  ) => (
    <TableHeader
      key={`table-header-${title || index}`}
      variant={
        this.props.noSort
          ? 'sortable'
          : this.isSortedHeader(index)
          ? 'sortable'
          : ''
      }
      colSpan={titleColSpan}
      noAutomaticPadding={noAutomaticPadding}
    >
      <View
        flexible={
          (index === 0 || noHeaderCenter) && !forceCenter ? 'row' : 'row-center'
        }
        hover={noSort || this.props.noSort ? '' : 'clickable'}
        onClick={noSort ? () => null : this.onSortBy(index)}
      >
        {titleRender || (
          <Text userSelect="none" {...titleProps}>
            {title}
          </Text>
        )}
        {(title || titleRender) && !this.props.noSort && !noSort && (
          <Icon
            ml={1}
            width="16px"
            className={
              this.isSortedHeader(index) && !this.state.sortAscending
                ? 'fas fa-caret-up rotate-caret'
                : 'fas fa-caret-up rotate-caret down'
            }
          />
        )}
      </View>
    </TableHeader>
  )

  renderHeaders() {
    const { noHeader, columns = [] } = this.props
    if (noHeader) {
      return null
    }

    return (
      <TableHead>
        <TableRow>{columns.map(this.renderHeader)}</TableRow>
      </TableHead>
    )
  }

  renderCell = (
    row: any,
    column: IListColumnDefinition,
    rowIndex: number,
    colIndex: number,
    isSubRow = false,
    rowProps = {},
  ) => {
    const {
      CellComponent = null,
      renderCell = null,
      autoToolTip = false,
      cellProps = {},
      accessor,
      maxWidth,
      shouldRenderEmpty,
      ...rest
    } = column
    if (shouldRenderEmpty && shouldRenderEmpty(row, rowIndex, isSubRow)) {
      return null
    }
    const { history } = this.props
    if (CellComponent) {
      return (
        <CellComponent
          rowIndex={rowIndex}
          colIndex={colIndex}
          isSubRow={isSubRow}
          {...row}
          {...cellProps}
          {...R.dissoc('title', rest)}
          {...rowProps}
          history={history}
        />
      )
    }
    if (renderCell) {
      return renderCell(row, rowIndex, history, isSubRow)
    }

    const content = R.is(Function, accessor)
      ? (accessor as (row: any) => string)(row)
      : R.prop(accessor as string, row)
    const text = (
      <ListPlainTextCell maxWidth={maxWidth || 'none'} {...cellProps}>
        {content}
      </ListPlainTextCell>
    )

    if (autoToolTip) {
      return (
        <ToolTip id={`list-${rowIndex}-${colIndex}`} tip={content} delay={500}>
          {text}
        </ToolTip>
      )
    }
    return text
  }

  renderRow = (
    row: any,
    rowIndex: number,
    isSubRow = false,
    isLast = false,
  ) => {
    const {
      getRowProps,
      renderSubRow,
      subRowLeftPad = 0,
      getSubItems,
      columns = [],
      canClickRow,
      onRowClick,
      rowHeight = isPrint ? 28 : 48,
      dataTest,
      keyExtractor,
      spacer,
      shouldRenderRow,
    } = this.props
    if (shouldRenderRow && !shouldRenderRow(row, rowIndex, isSubRow)) {
      return null
    }
    const rowKeyId = keyExtractor ? keyExtractor(row, isSubRow) : rowIndex
    const subRowKeyId = keyExtractor ? keyExtractor(row, true) : rowIndex
    const rowKey = `${dataTest}-row-${rowKeyId}`
    const subRowKey = `${dataTest}-row-${subRowKeyId}`

    const rowProps = {
      ...(getRowProps ? getRowProps(row, rowIndex, isSubRow) : {}),
      isLast,
      isSubRow,
    }
    const renderedSubRow =
      renderSubRow && !isSubRow ? renderSubRow(row, rowIndex) : false
    const columnsLength = columns.length
    const renderedRowColspan = columnsLength - subRowLeftPad
    const leftTDs = new Array(subRowLeftPad).fill(null)
    const subRow = renderedSubRow ? (
      <TableRow
        key={subRowKey}
        data-test={subRowKey}
        bg={getListRowBackground(rowIndex)}
        {...rowProps}
      >
        {leftTDs.map((v, tdIndex) => (
          <td key={`left-pad-${tdIndex}`} />
        ))}
        <td colSpan={renderedRowColspan}>{renderedSubRow}</td>
      </TableRow>
    ) : null
    const subRowItems =
      getSubItems && !isSubRow
        ? this.sort(
            getSubItems(row, rowIndex),
            this.state.sortColumnIndex,
            this.state.sortAscending,
          )
        : []
    const extraRows = subRowItems.map((item: any, index: number) =>
      this.renderRow(item, index, true, index === subRowItems.length - 1),
    )
    const canClick = canClickRow ? canClickRow(row, rowIndex) : true
    const clickableRow = onRowClick && canClick
    return [
      spacer ? (
        <TableRow key={`${rowKeyId}-spacer`}>
          <TableDefinition colSpan={columns.length} p={1} />
        </TableRow>
      ) : null,
      <TableRow
        minHeight={rowHeight}
        height={rowHeight}
        key={rowKey}
        data-test={rowKey}
        bg={getListRowBackground(rowIndex)}
        hover={clickableRow ? 'clickable-bg' : ''}
        onClick={this.onRowClick(row, rowIndex, isSubRow)}
        {...rowProps}
      >
        {columns.map((column, colIndex) => {
          const {
            noCenter = false,
            maxWidth = false,
            forceCenter = false,
            getColumnProps = false,
            shouldRender = false,
            noAutomaticPadding = false,
          } = column
          if (shouldRender && !shouldRender(row, rowIndex, isSubRow)) {
            return null
          }
          const renderedRow = this.renderCell(
            row,
            column,
            rowIndex,
            colIndex,
            isSubRow,
            rowProps,
          )
          const content =
            !utils.hasValue(renderedRow) ||
            ((noCenter || maxWidth) && !forceCenter) ? (
              renderedRow
            ) : (
              <View flexible="column-center">{renderedRow}</View>
            )
          const columnProps = getColumnProps
            ? getColumnProps(row, rowIndex)
            : {}
          return (
            <TableDefinition
              key={`row-${rowIndex}-${colIndex}`}
              noAutomaticPadding={noAutomaticPadding}
              {...R.dissoc('data-test', rowProps)}
              {...columnProps}
              {...(maxWidth
                ? {
                    width: maxWidth,
                    maxWidth,
                    minWidth: maxWidth,
                  }
                : {})}
            >
              {content}
            </TableDefinition>
          )
        })}
      </TableRow>,
      subRow,
      extraRows,
    ]
  }

  mapRenderRow = (row: any, rowIndex: number) => this.renderRow(row, rowIndex)

  getListItems = () => {
    const { recordState, nonRecordItems } = this.props
    return (
      nonRecordItems || R.propOr<any[], any, any[]>([], 'items', recordState)
    )
  }

  renderBlankSlate = () => {
    const { noSearch = false, blankSlateText } = this.props
    const { filter } = this.state
    return (
      <BlankSlate
        title={
          blankSlateText
            ? blankSlateText
            : noSearch || !utils.hasValue(filter)
            ? 'No results'
            : 'No results found for your search'
        }
      />
    )
  }

  renderCells() {
    const { columns = [] } = this.props
    const listItems = this.getListItems()
    if (!utils.hasValue(listItems)) {
      const content = this.renderBlankSlate()
      return (
        <TableRow>
          <TableDefinition colSpan={columns.length}>{content}</TableDefinition>
        </TableRow>
      )
    }

    return listItems.map(this.mapRenderRow)
  }

  renderFilter(isSmallScreen?: boolean) {
    const {
      filterOptions,
      dataTest,
      trackAnalytics,
      trackAnalyticsCategory,
      smallerThanMedium,
    } = this.props
    if (
      filterOptions &&
      utils.hasValue(filterOptions) &&
      ((smallerThanMedium && isSmallScreen) || !smallerThanMedium)
    ) {
      return (
        <ListFilter
          isOpen={this.state.filtersOpen}
          title={filterOptions.title}
          toggleOpen={this.onToggleFilterOpen}
          searchName={`${dataTest}-search`}
          trackAnalytics={trackAnalytics}
          trackAnalyticsCategory={`${trackAnalyticsCategory}-search`}
          onSearchChange={this.onSearchChange}
          filters={filterOptions.filters}
          onFilterChanged={this.onFilterChanged}
          isSmall={smallerThanMedium}
          searchValue={this.state.filter}
        />
      )
    }

    return null
  }

  renderTableList() {
    const { customList, dataTest, showBlankSlate = false } = this.props

    return showBlankSlate
      ? this.renderBlankSlate()
      : customList || (
          <View className="horizontal-scrollbox">
            <Table cellspacing="0" cellpadding="0" data-test={dataTest}>
              {this.renderHeaders()}
              <TableBody>{this.renderCells()}</TableBody>
            </Table>
          </View>
        )
  }

  render() {
    const {
      callToAction = null,
      dataTest,
      noSearch = false,
      noWrapper = false,
      recordState,
      title = '',
      trackAnalytics,
      trackAnalyticsCategory,
      filterOptions,
      legends,
      smallerThanMedium,
      headerClassName,
    } = this.props

    const showLoadingNext = R.propOr(false, 'loadingNext', recordState)
    const showLoading = R.propOr(false, 'loading', recordState)

    const list = this.renderTableList()
    const content = (
      <View flexible="row" width="100%">
        {this.renderFilter()}
        <BottomScrollListener
          triggerOnNoScroll={false}
          onBottom={this.getNextPage}
          offset={300}
          debounce={0}
        >
          <View width="100%" flexible="column">
            {!showLoading && list}
            {(showLoadingNext || showLoading) && <Spinner />}
          </View>
        </BottomScrollListener>
      </View>
    )

    if (noWrapper) {
      return content
    }

    const shouldRenderFilter =
      smallerThanMedium && utils.hasValue(filterOptions)

    return (
      <View variant="paper">
        <div className="flex w-full items-center gap-2">
          <div
            className={twMerge(
              'flex-row items-center flex flex-shrink-0',
              styles.header,
            )}
          >
            {shouldRenderFilter && this.renderFilter(true)}
            <WidgetHeader
              title={title}
              dataTest={`${dataTest}-title`}
              className={headerClassName}
            />
          </div>

          {!noSearch && !utils.hasValue(filterOptions) && (
            <div
              className={twMerge(
                'flex items-center flex-wrap gap-x-2 gap-y-2 ml-auto w-full',
                styles.searchAndActions,
              )}
            >
              <SearchBox
                name={`${dataTest}-search`}
                trackEvent={trackAnalytics}
                trackEventAction={`${trackAnalyticsCategory}-search`}
                onChange={this.onSearchChange}
                value={this.state.filter}
                placeholder="Search..."
                width={['100%', '19rem']}
              />
              {callToAction}
            </div>
          )}
          {noSearch && utils.hasValue(callToAction) && callToAction}
          {legends && utils.hasValue(legends) && (
            <ListLegend legends={legends} />
          )}
        </div>

        <div className="mt-4">{content}</div>
      </View>
    )
  }
}

export default withScreenSize(withRouter(List)) as (props: IProps) => any
