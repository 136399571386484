import * as normalizr from 'normalizr'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

export const TRUSTED_APPS_DASHBOARDS_SHARED_WITH_US_RECORD =
  'trustedAppsDashboardsSharedWithUs'

const trustedAppsDashboardsSharedWithUs: records.modules.IModule = {
  record: TRUSTED_APPS_DASHBOARDS_SHARED_WITH_US_RECORD,
  responseDataKey: 'owners',
  schema: new normalizr.schema.Entity(
    TRUSTED_APPS_DASHBOARDS_SHARED_WITH_US_RECORD,
    {},
    {
      idAttribute: 'ownerOrgId',
      processStrategy: (entity) =>
        R.assoc('id', R.pathOr(0, ['ownerOrgId'], entity), entity),
    },
  ),
  api: {
    create: () => 'trustedapps/organizations/trustedapps/sharedwithme',
    get: (id) => `trustedapps/organizations/${id}/trustedapps/sharedwithme`,
    getList: () => 'trustedapps/organizations/trustedapps/sharedwithme',
    update: (id) => `trustedapps/organizations/trustedapps/sharedwithme/${id}`,
    delete: (id) => `trustedapps/organizations/${id}/trustedapps/sharedwithme`,
  },
}

export default trustedAppsDashboardsSharedWithUs
