import React from 'react'

import IRestCModelServiceEndpointAttribute from 'domains/modeling/models/IRestCModelServiceEndpointAttribute'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import IRestCModelService from 'domains/modeling/models/IRestCModelService'
import IRestCModelServiceEndpoint from 'domains/modeling/models/IRestCModelServiceEndpoint'
import { ListPlainTextCell } from 'lib/components'

interface IProps extends IRestCModelServiceEndpointAttribute {
  specification: IRestCSpec
  service: IRestCModelService
  endpoint: IRestCModelServiceEndpoint
  endpointIndex: number
  handleChange: any
}

export default function RestCAttributeNameCell(props: IProps) {
  const { name } = props

  return (
    <ListPlainTextCell
      py={13}
      whiteSpace="pre-wrap"
      wordBreak="break-all"
      mr={20}
    >
      {name}
    </ListPlainTextCell>
  )
}
