import React, { PureComponent } from 'react'
import IComparison from 'domains/comparisons/models/IComparison'
import { View, Text, Icon, Linkify } from 'lib/components'

interface IProps {
  comparison: IComparison
  onShowNotes: (comparison: IComparison) => any
}

export default class ComparisonListNotesSubRow extends PureComponent<IProps> {
  onShowNotes = () => this.props.onShowNotes(this.props.comparison)

  render() {
    const { comparison } = this.props
    return (
      <View flexible="row-v-center" width="100%" p={3} boxSizing="border-box">
        <View flex={1}>
          <Text
            fontWeight={700}
            data-test={`${comparison.id}-notes-row`}
            wordBreak="break-word"
            whiteSpace="pre-line"
          >
            <Linkify>{comparison.notes || ''}</Linkify>
          </Text>
        </View>
        <Icon
          ml={3}
          fontSize={24}
          className="fas fa-edit"
          onClick={this.onShowNotes}
          cursor="pointer"
          data-test={`${comparison.id}-edit-notes`}
        />
      </View>
    )
  }
}
