import React from 'react'
import * as Yup from 'yup'
import { Formik, FormikHelpers } from 'formik'
import { useSelector } from 'react-redux'

import { meSelector } from 'domains/authentication/selectors/me'
import { ModalWrapper, View, FormInput } from 'lib/components'
import { validatePassword } from 'domains/users/utils/passwords'
import { updatePassword } from 'domains/users/workflows/updatePassword'
import { showToast, ERROR_TOAST, SUCCESS_TOAST } from 'lib/utils/toast'
import trackUsersAnalytics from 'domains/users/utils/trackUsersAnalytics'

const passwordValidationSchema = {
  password: validatePassword,
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
}

interface IFormValues {
  current: string
  password: string
  confirmPassword: string
}

interface IProps {
  id: string | number
  isOpen: boolean
  closeModal: () => any
}

export default function ChangePasswordModal(props: IProps) {
  const me = useSelector(meSelector)
  const isMe = props.id === me?.id

  const onSave = async (
    values: IFormValues,
    bag: FormikHelpers<IFormValues>,
  ) => {
    trackUsersAnalytics('user_password_changed', { id: props.id })
    const success = await updatePassword(
      props.id,
      values.password,
      isMe ? values.current : undefined,
    )
    bag.setSubmitting(false)
    bag.resetForm()
    if (!success) {
      showToast(ERROR_TOAST, 'Failed to change password')
    } else {
      showToast(SUCCESS_TOAST, 'Password changed')
      props.closeModal()
    }
  }

  return (
    <Formik
      initialValues={{
        current: '',
        password: '',
        confirmPassword: '',
      }}
      onSubmit={onSave}
      validationSchema={Yup.object().shape({
        ...passwordValidationSchema,
        ...(isMe ? { current: Yup.string().required('Required') } : {}),
      })}
      enableReinitialize={true}
    >
      {({ isSubmitting, values, handleChange, handleSubmit, resetForm }) => (
        <ModalWrapper
          isOpen={props.isOpen}
          title="Change Password"
          pending={isSubmitting}
          actions={[
            {
              text: 'Change',
              variant: 'start',
              onClick: handleSubmit,
              extra: { type: 'submit' },
            },
            {
              text: 'Cancel',
              variant: 'neutral',
              onClick: () => {
                resetForm()
                props.closeModal()
              },
              extra: {
                type: 'button',
              },
            },
          ]}
        >
          <View width={['100%', 600]} maxWidth={['100%', 600]}>
            {isMe && (
              <FormInput
                label="Current Password"
                labelDataTest="current-password-label"
                required={true}
                name="current"
                placeholder="Current Password"
                type="password"
                value={values.current}
                valueDataTest="current-password-input"
                handleChange={handleChange}
              />
            )}
            <View
              display="grid"
              gridTemplateColumns={['auto', '1fr 1fr']}
              gridColumnGap="28px"
              width="100%"
            >
              <FormInput
                label="New Password"
                labelDataTest="new-password-label"
                required={true}
                name="password"
                placeholder="New Password"
                type="password"
                value={values.password}
                valueDataTest="new-password-input"
                handleChange={handleChange}
                showRequiredOnlyOnSumbit={true}
              />
              <FormInput
                label="Confirm Password"
                labelDataTest="confirm-password-label"
                required={true}
                name="confirmPassword"
                placeholder="Confirm Password"
                type="password"
                value={values.confirmPassword}
                valueDataTest="confirm-password-input"
                handleChange={handleChange}
              />
            </View>
          </View>
        </ModalWrapper>
      )}
    </Formik>
  )
}
