import React from 'react'
import { useSelector } from 'react-redux'

import { myOrganizationPropSelector } from 'domains/authentication/selectors/organizations'
import { RootState } from 'lib/store/rootReducer'
import { NON_MEMBER_ORG_MEMBERSHIP_DISPLAY } from 'domains/orgs/constants/organization'
import DrawerLink from 'domains/application/components/DrawerLink'

interface IProps {
  open: boolean
}

export default function JoinIMSDrawerLink(props: IProps) {
  const membershipLevel = useSelector((state: RootState) =>
    myOrganizationPropSelector('membershipLevel', '')(state, {}),
  )

  const isNonMember = membershipLevel === NON_MEMBER_ORG_MEMBERSHIP_DISPLAY

  if (!isNonMember) {
    return null
  }

  return (
    <DrawerLink
      title="JOIN 1EdTech"
      titleFontWeight={700}
      icon={'fas fa-star'}
      iconColor="#FFF799"
      bg="#866882"
      hoverBg="#775272"
      route="https://www.imsglobal.org/imsmembership.html"
      open={props.open}
      external={true}
      requiredRoles={[]}
    />
  )
}
