import React from 'react'
import clsx from 'clsx'

type SelectProps = {
  name: string
  value: any
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => any
  options: {
    label: string
    value: any
  }[]
  small?: boolean
}

export default function StartModelSelect(props: SelectProps) {
  return (
    <select
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      className={clsx(
        'text-black text-2xl font-bold',
        'border-t-0 border-r-0 border-l-0 border-b-4 border-black',
        'mx-4 block w-full px-2',
        'outline-none focus:outline-none',
      )}
      style={{ maxWidth: props.small ? '10rem' : '21rem' }}
    >
      {props.options.map((opt, index) => (
        <option
          key={`select-${props.name}-option-${opt.label}-${index}`}
          value={opt.value}
        >
          {opt.label}
        </option>
      ))}
    </select>
  )
}
