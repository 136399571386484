import React from 'react'

import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import { View } from 'lib/components'
import { useSelector } from 'react-redux'
import { RootState } from 'lib/store/rootReducer'
import { NotificationPreference } from 'domains/users/models/IUserPreferences'
import { notificationPreferenceSelector } from 'domains/notifications/selectors/notificationSelectors'
import WidgetLineItem from 'domains/reports/components/WidgetLineItem'

interface IProps {
  userId: string | number
  height: number
}

export default function UserPreferencesWidget(props: IProps) {
  const notificationPreference = useSelector<RootState, NotificationPreference>(
    (s) => notificationPreferenceSelector('userId')(s, props),
  )
  const showNotifications = notificationPreference === 'ALERTS'

  return (
    <DetailsTopWidget
      title="User Preferences"
      dataTest="user-preferences-widget"
      height={props.height}
    >
      <View my={3}>
        <WidgetLineItem
          label="Push Notifications"
          value={showNotifications ? 'On' : 'Off'}
          data-test="notification-preference"
        />
      </View>
    </DetailsTopWidget>
  )
}
