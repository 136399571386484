import { HTMLAttributes } from 'react'
import styled from 'styled-components'
import * as SS from 'styled-system'
import * as CSS from 'csstype'

import colors from 'lib/styles/colors'
import { animations } from 'lib/styles/animations'

export const variantStyle = SS.variant({ key: 'viewVariantStyles' })
export const viewVariantStyles = {
  screen: `
    padding-left: 16px;
    padding-right: 16px;
  `,
  paper: `
    padding: 32px;
    border-radius: 6px;
    background-color: ${colors.white};
    box-shadow: 0 0 24px rgba(0,0,0,0.12);
    max-width: 100%;
    overflow: 'hidden';
  `,
}

export const animationDelay = SS.style({
  prop: 'animationDelay',
  cssProperty: 'animationDelay',
  transformValue: (n: number) => `${n}ms`,
})

export const animationStyle = SS.variant({
  key: 'animationStyles',
  prop: 'animation',
})

export type AnimationType =
  | 'fadeIn'
  | 'fadeInUp'
  | 'fadeInDown'
  | 'fadeOut'
  | 'popIn'
  | 'popOut'
  | 'bounceIn'
  | 'bounceOut'
  | 'slideIn'
  | 'slideOut'
export const animationStyles = {
  fadeIn: animations.fadeIn,
  fadeInUp: animations.fadeInUp,
  fadeOut: animations.fadeIn,
  fadeInDown: animations.fadeInDown,
  popIn: animations.popIn,
  popOut: animations.popOut,
  bounceIn: animations.bounceIn,
  bounceOut: animations.bounceOut,
  slideIn: animations.slideIn,
  slideOut: animations.slideOut,
}

export const hoverStyle = SS.variant({
  key: 'viewHoverStyles',
  prop: 'hover',
})
export const viewHoverStyles = {
  clickable: {
    '&:active': {
      opacity: 0.7,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  'clickable-bg': {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.neutral,
    },
  },
  'elevation-one': {
    transition: 'box-shadow 200ms ease-in-out',
    '&:hover': {
      boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    },
  },
  'elevation-two': {
    transition: 'box-shadow 200ms ease-in-out',
    '&:hover': {
      boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    },
  },
}
export const elevationVariantStyle = SS.variant({
  key: 'elevationStyles',
  prop: 'elevation',
})

export const elevationStyles = {
  zero: {
    boxShadow: '0 0 24px rgba(0,0,0,0.12)',
  },
  one: {
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  },
  two: {
    boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
  },
  three: {
    boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
  },
  four: {
    boxShadow: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
  },
}

const overflowY = SS.style({
  prop: 'overflowY',
  cssProperty: 'overflowY',
})
const overflowX = SS.style({
  prop: 'overflowX',
  cssProperty: 'overflowX',
})

export const flexibleStyle = SS.variant({
  key: 'flexibleStyles',
  prop: 'flexible',
})
export type FlexibleOption =
  | 'column'
  | 'column-center'
  | 'column-h-start'
  | 'column-h-end'
  | 'column-h-center'
  | 'column-v-end'
  | 'column-v-center'
  | 'column-space-between'
  | 'row'
  | 'row-wrap'
  | 'row-center'
  | 'row-v-center'
  | 'row-v-end'
  | 'row-h-center'
  | 'row-h-end'
  | 'row-h-end-wrap'
  | 'row-space-between'
  | 'row-space-between-wrap'
  | 'row-space-around'
  | 'row-space-around-wrap'

export const cursorStyle = SS.style({
  prop: 'cursor',
  cssProperty: 'cursor',
})
export const boxSizing = SS.style({
  prop: 'boxSizing',
  cssProperty: 'boxSizing',
})

export interface IHtmlDivElementProps extends HTMLAttributes<HTMLDivElement> {} // eslint-disable-line
interface IBaseProps
  extends IHtmlDivElementProps,
    SS.AlignContentProps,
    SS.AlignItemsProps,
    SS.AlignSelfProps,
    SS.BackgroundColorProps,
    SS.BorderColorProps,
    SS.BorderProps,
    SS.BorderRadiusProps,
    SS.BordersProps,
    SS.BottomProps,
    SS.ColorProps,
    SS.DisplayProps,
    SS.FlexBasisProps,
    SS.FlexDirectionProps,
    SS.FlexProps,
    SS.FlexWrapProps,
    SS.FontSizeProps,
    SS.FontStyleProps,
    SS.FontWeightProps,
    SS.GridAutoFlowProps,
    SS.GridColumnGapProps,
    SS.GridRowGapProps,
    SS.GridTemplateColumnsProps,
    SS.GridTemplateRowsProps,
    SS.HeightProps,
    SS.JustifyContentProps,
    SS.JustifySelfProps,
    SS.LeftProps,
    SS.LineHeightProps,
    SS.MaxHeightProps,
    SS.MaxWidthProps,
    SS.MinHeightProps,
    SS.MinWidthProps,
    SS.OrderProps,
    SS.OverflowProps,
    SS.PositionProps,
    SS.RightProps,
    SS.SpaceProps,
    SS.TextAlignProps,
    SS.TopProps,
    SS.VerticalAlignProps,
    SS.WidthProps,
    SS.ZIndexProps {
  animation?: AnimationType
  animationDelay?: number | string
  color?: CSS.Property.Color
  variant?: string
  wordBreak?: string
  userSelect?: string
  hover?: string
  boxSizing?: string
  overflowY?: any
  overflowX?: any
  elevation?: string
  cursor?: string
  flexible?: FlexibleOption | FlexibleOption[]
  'data-test'?: string
}

export interface IElevationProps {
  elevation?: 'zero' | 'one' | 'two' | 'three' | 'four'
}

export type IViewProps = IBaseProps & IElevationProps

const View = styled.div<IViewProps>`
  ${flexibleStyle};
  ${variantStyle};
  ${hoverStyle};
  ${animationStyle};
  ${animationDelay};
  ${boxSizing};
  ${cursorStyle};
  ${elevationVariantStyle};
  ${overflowY};
  ${overflowX};

  ${SS.alignContent};
  ${SS.alignItems};
  ${SS.alignSelf};
  ${SS.borderColor};
  ${SS.borderRadius};
  ${SS.borders};
  ${SS.bottom};
  ${SS.color};
  ${SS.display};
  ${SS.flexBasis};
  ${SS.flexDirection};
  ${SS.flexWrap};
  ${SS.flex};
  ${SS.fontSize};
  ${SS.fontWeight};
  ${SS.gridAutoFlow};
  ${SS.gridColumnGap};
  ${SS.gridRowGap};
  ${SS.gridTemplateColumns};
  ${SS.gridTemplateRows}
  ${SS.height};
  ${SS.justifyContent};
  ${SS.justifySelf};
  ${SS.left};
  ${SS.lineHeight};
  ${SS.maxHeight};
  ${SS.maxWidth};
  ${SS.minHeight};
  ${SS.minWidth};
  ${SS.order};
  ${SS.overflow};
  ${SS.position};
  ${SS.right};
  ${SS.space};
  ${SS.top};
  ${SS.verticalAlign};
  ${SS.width};
  ${SS.zIndex};
`

View.displayName = 'View'

export default View
