import React from 'react'

import {
  Text,
  Span,
  Input,
  Select,
  View,
  FormErrorMessage,
  FormInput,
} from 'lib/components'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import { OTHER_SOURCE } from 'domains/characterizations/constants/createCharacterizationCSV'

interface IProps {
  source: string
  otherSource: string
  notes?: string
  handleChange: (event: any) => void
  setFieldValue: any
  height: number
}

export default function CreateCCCharacterizationSourceDetails(props: IProps) {
  const sources = [
    'Select One',
    'Houghton Mifflin Harcourt',
    'Savvas Learning Company',
    'Goodheart-Willcox Publisher',
    'TCI',
    'Wayside Publishing',
    'CompuScholar',
    'BrainPOP',
    'Carnegie Learning, Inc.',
    'Curriculum Associates',
    'D2L Corporation',
    'Genius Plaza',
    'Grade Results',
    'Instructure',
    'Jenzabar, Inc.',
    "It's Learning",
    OTHER_SOURCE,
  ]

  const { source, otherSource, handleChange, height } = props

  return (
    <DetailsTopWidget
      title="Information"
      dataTest="characterization-information-widget"
      height={height}
    >
      <View
        pt={3}
        width="100%"
        height="94%"
        flexible="column-space-between"
        flex={1}
      >
        <View width="100%">
          <Text fontWeight={700} variant="subtitle" mb={2}>
            Source<Span color="error">*</Span>
          </Text>
          <Select
            name="source"
            value={source}
            onChange={handleChange}
            variant="bold"
            mb={2}
            width="100%"
            data-test="source-select"
            aria-label="Characterization Source"
          >
            {sources.map((s, index) => (
              <option key={`sourceItem-${index}`} value={s}>
                {s}
              </option>
            ))}
          </Select>
          <FormErrorMessage name="source" />

          {source === OTHER_SOURCE && (
            <View>
              <Input
                type="text"
                name="otherSource"
                onChange={handleChange}
                value={otherSource}
                placeholder="Other*"
                aria-label="Characterization Other Source"
              />
              <FormErrorMessage name="otherSource" />
            </View>
          )}
        </View>

        <View mt={2} width="100%">
          <FormInput
            label="Characterization Notes"
            name="notes"
            handleChange={props.handleChange}
            value={props.notes || ''}
            valueDataTest="characterization-notes"
            placeholder="Notes..."
            aria-label="Characterization Notes"
            textArea={true}
            inputProps={{
              width: '100%',
              rows: 6,
            }}
          />
        </View>
        <View flex={1} />
      </View>
    </DetailsTopWidget>
  )
}
