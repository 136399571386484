import React from 'react'

import { ListPlainTextCell } from 'lib/components'
import { ICSVConsumerFileColumn } from 'domains/modeling/models/ICSVConsumerModel'
import { FORMAT_TO_DISPLAY } from 'domains/characterizations/models/CharacterizationReport'

export default function CsvCColumnFormatCell(props: ICSVConsumerFileColumn) {
  const { dataType } = props
  return (
    <ListPlainTextCell>
      {dataType ? FORMAT_TO_DISPLAY[dataType] : null}
    </ListPlainTextCell>
  )
}
