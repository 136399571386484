import React from 'react'
import {
  ICSVFileColumnComparisonReport,
  ICSVFileComparisonReport,
} from 'domains/comparisons/models/ICSVComparisonReport'
import ICSVSpecification from 'domains/specifications/models/ICSVSpecification'
import ComparisonItemRow from 'domains/comparisons/components/ComparisonReport/ComparisonItemRow'
import { findSpecColumn } from 'domains/modeling/selectors/csvCModelToSpec'

interface IProps {
  specification: ICSVSpecification
  fileComparison: ICSVFileComparisonReport
  columnComparison: ICSVFileColumnComparisonReport
  index: number
}

export default function CSVComparisonFileColumnRow(props: IProps) {
  const specColumn = findSpecColumn(
    props.specification,
    props.fileComparison.filename,
    props.columnComparison.name,
  )
  return (
    <ComparisonItemRow
      index={props.index}
      status={props.columnComparison.status}
      dataType={specColumn ? specColumn.dataType : 'String'}
      required={props.columnComparison.required}
      mdSource={props.columnComparison.mdSource}
      parentName={props.fileComparison.filename}
      name={props.columnComparison.name}
      description={props.columnComparison.description}
      notes={props.columnComparison.notes}
      errorMessage={props.columnComparison.errorMessage}
      smallestValueLength={props.columnComparison.smallestValueLength}
      largestValueLength={props.columnComparison.largestValueLength}
    />
  )
}
