import React, { PureComponent, ChangeEvent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

import { Select } from 'lib/components'
import { OPTIONAL_REQUIRED_FIELD } from 'domains/modeling/constants/columns'
import { updateCsvCFileColumnModelInState } from 'domains/modeling/workflows/csvCModelFile'
import {
  modelFileColumnSelector,
  specFileColumnSelector,
} from 'domains/modeling/selectors/csvCModelToSpec'
import { CSV_C_MODELS_RECORD } from 'lib/records/modules/csvCModels'
import ICSVSpecificationFileColumn from 'domains/specifications/models/ICSVSpecificationFileColumn'
import { RootState } from 'lib/store/rootReducer'
import { AnySpecFormatType } from 'domains/formats/constants/formats'
import { SPECIFICATIONS_RECORD } from 'lib/records/modules/specifications'
import { getFormatByProp } from 'domains/products/selectors/formats'

interface IPropsFromState {
  column: any
  specColumn?: ICSVSpecificationFileColumn | null
}
const stateMap = createStructuredSelector<RootState, any, IPropsFromState>({
  column: modelFileColumnSelector(
    records.entitiesSelectors.entityByIdSelector(
      CSV_C_MODELS_RECORD,
      'modelId',
    ),
    'fileName',
    'header',
  ),
  specColumn: specFileColumnSelector(
    records.entitiesSelectors.entityByIdSelector(
      SPECIFICATIONS_RECORD,
      getFormatByProp(),
    ),
    'fileName',
    'header',
  ),
})

interface IProps {
  format: AnySpecFormatType
  modelId: string | number
  fileName: string
  header: string
}

export class CsvCColumnRequiredCell extends PureComponent<
  IProps & IPropsFromState
> {
  onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { modelId, fileName, header, column } = this.props
    updateCsvCFileColumnModelInState(modelId, fileName, header, {
      ...column!,
      required: event.target.value,
    })
  }

  render() {
    const { specColumn, column, header } = this.props
    const required = R.prop('required', column!)
    if (
      !specColumn ||
      R.prop('required', specColumn) === OPTIONAL_REQUIRED_FIELD
    ) {
      return (
        <Select
          onChange={this.onChange}
          variant="narrow-bold"
          ml="-18px"
          width={120}
          value={required}
          data-test={`${header}-required-select`}
          aria-label={`Required selector for ${header}`}
        >
          <option value="Optional">Optional</option>
          <option value="Required">Required</option>
        </Select>
      )
    }

    return required
  }
}

export default connect(stateMap)(CsvCColumnRequiredCell)
