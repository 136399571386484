import React, { PureComponent } from 'react'
import memoize from 'fast-memoize'

import { List, ListPlainTextCell, Icon, Span } from 'lib/components'
import { getReportFileByLabel } from 'domains/characterizations/utils/characterizationReport'
import ITransformedCharacterizationCSVReport from 'domains/characterizations/models/ITransformedCharacterizationCSVReport'
import IFileSetCharacterizationColumn from 'domains/characterizations/models/IFileSetCharacterizationColumn'
import ICSVSpecification from 'domains/specifications/models/ICSVSpecification'
import { findSpecColumn } from 'domains/modeling/selectors/csvCModelToSpec'
import CharacterizationColumnWarningCell from 'domains/characterizations/components/CharacterizationReport/CharacterizationColumnWarningCell'
import CharacterizationColumnPopulationCell from 'domains/characterizations/components/CharacterizationReport/CharacterizationColumnPopulationCell'
import CharacterizationColumnDescriptionCell from 'domains/characterizations/components/CharacterizationReport/CharacterizationColumnDescriptionCell'
import ICSVConsumerModel from 'domains/modeling/models/ICSVConsumerModel'
import CharacterizationReportNotesCell from 'domains/characterizations/components/CharacterizationReport/CharacterizationReportNotesCell'
import CharacterizationColumnRequiredCell from 'domains/characterizations/components/CharacterizationReport/CharacterizationColumnRequiredCell'
import CharacterizationColumnFormatCell from 'domains/characterizations/components/CharacterizationReport/CharacterizationColumnFormatCell'
import CharacterizationColumnMultiplicityCell from 'domains/characterizations/components/CharacterizationReport/CharacterizationColumnMultiplicityCell'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'
import { IListColumnDefinition } from 'lib/components/List'
import { utils } from '@ims/1edtech-frontend-common'
import ICSVModelCharacterizationFileColumn from 'domains/characterizations/models/ICSVModelCharacterizationFileColumn'
import CSVCharacterizationColumnNameCell from 'domains/characterizations/components/CharacterizationReport/CSVCharacterizationColumnNameCell'
import CharacterizationColumnMinMaxCell from 'domains/characterizations/components/CharacterizationReport/CharacterizationColumnMinMaxCell'

const isPrint = isOnPrintScreen()

const keyExtractor = (
  column: ICSVModelCharacterizationFileColumn,
  isSubRow: boolean,
) => `column-${column.name}${isSubRow ? '-notes' : ''}`

const defaultColProps = {
  titleProps: { fontWeight: 700, fontSize: isPrint ? 11 : undefined },
  noHeaderCenter: true,
  noCenter: true,
}
interface IProps {
  fileLabel: string
  report: ITransformedCharacterizationCSVReport
  specification: ICSVSpecification
  model?: ICSVConsumerModel
}

export default class CharacterizationFileReportColumnsList extends PureComponent<IProps> {
  getColumns = memoize((fileLabel: string) => {
    const descriptionCell = {
      title: 'Description',
      maxWidth: isPrint ? 'auto' : [200, 210, 210, 'auto', 'auto'],
      cellProps: {
        fileLabel,
        specification: this.props.specification,
        width: isPrint ? 'auto' : [200, 210, 210, 'auto', 'auto'],
      },
      CellComponent: CharacterizationColumnDescriptionCell,
      ...defaultColProps,
    }
    const columns: IListColumnDefinition[] = [
      {
        title: null,
        maxWidth: isPrint ? 18 : 36,
        CellComponent: CharacterizationColumnWarningCell,
        cellProps: { fileLabel },
        ...defaultColProps,
      },
      {
        title: 'Position',
        maxWidth: isPrint ? 48 : 64,
        accessor: 'position',
        ...defaultColProps,
      },
      {
        title: 'Field Name',
        maxWidth: isPrint ? 100 : [200, 210, 210, 250, 380],
        CellComponent: CSVCharacterizationColumnNameCell,
        ...defaultColProps,
      },
      {
        title: 'Required',
        maxWidth: isPrint ? 78 : 100,
        CellComponent: CharacterizationColumnRequiredCell,
        cellProps: {
          fileLabel,
          specification: this.props.specification,
        },
        ...defaultColProps,
      },
      {
        title: 'Format',
        maxWidth: isPrint ? 86 : 100,
        CellComponent: CharacterizationColumnFormatCell,
        cellProps: {
          fileLabel,
          specification: this.props.specification,
          model: this.props.model,
        },
        ...defaultColProps,
      },
      {
        title: 'Multiplicity',
        maxWidth: isPrint ? 62 : 100,
        CellComponent: CharacterizationColumnMultiplicityCell,
        cellProps: {
          fileLabel,
          specification: this.props.specification,
          model: this.props.model,
        },
        ...defaultColProps,
        noHeaderCenter: isPrint,
        forceCenter: !isPrint,
      },
    ]
    if (this.props.model) {
      if (isPrint) {
        return [...columns, descriptionCell]
      }
      return [
        ...columns,
        descriptionCell,
        {
          title: 'Notes',
          maxWidth: [200, 250, 250, 'auto', 'auto'],
          CellComponent: CharacterizationReportNotesCell,
          ...defaultColProps,
        },
      ]
    }

    return [
      ...columns,
      {
        title: 'Min/Max',
        maxWidth: isPrint ? 54 : 78,
        CellComponent: CharacterizationColumnMinMaxCell,
        ...defaultColProps,
        noHeaderCenter: isPrint,
        forceCenter: !isPrint,
      },
      descriptionCell,
      {
        title: 'Population',
        maxWidth: isPrint ? 100 : 120,
        CellComponent: CharacterizationColumnPopulationCell,
        cellProps: { fileLabel },
        ...defaultColProps,
      },
    ]
  })

  getSpecColumn = (column: IFileSetCharacterizationColumn) =>
    findSpecColumn(
      this.props.specification,
      this.props.fileLabel,
      column.headerName,
    )

  getRowProps = memoize((column: IFileSetCharacterizationColumn) => {
    const defaultProps = { 'data-test': `${column.headerName}-row` }
    if (column.metadata) {
      const specColumn = this.getSpecColumn(column)
      if (specColumn) {
        return { bg: 'builtInMetaDataBG', ...defaultProps }
      }

      return { bg: 'customMetaDataBG', ...defaultProps }
    }
    return defaultProps
  })

  renderSubRow = (column: IFileSetCharacterizationColumn) => {
    if (!utils.hasValue(column.notes) || !isPrint) {
      return false
    }
    return (
      <ListPlainTextCell my={1}>
        <Icon className="fas fa-info-circle" color="secondary" mr={1} />
        <Span color="secondary" fontWeight={700}>
          Note:{' '}
        </Span>
        {column.notes}
      </ListPlainTextCell>
    )
  }

  render() {
    const { fileLabel, report } = this.props
    const file = getReportFileByLabel(fileLabel, report)
    if (!file || file.columns.length < 1) {
      return null
    }

    return (
      <List
        noWrapper={true}
        nonRecordItems={file.columns}
        columns={this.getColumns(fileLabel)}
        dataTest={`${file.fileLabel}-columns-list`}
        noSort={true}
        getRowProps={this.getRowProps}
        renderSubRow={this.renderSubRow}
        subRowLeftPad={2}
        keyExtractor={keyExtractor}
      />
    )
  }
}
