import React from 'react'
import * as R from 'ramda'

import IRESTComparisonReport from 'domains/characterizations/models/IRESTComparisonReport'
import IRESTServiceComparisonReport from 'domains/characterizations/models/IRESTServiceComparisonReport'
import IRESTServiceEndpointComparisonReport from 'domains/characterizations/models/IRESTServiceEndpointComparisonReport'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import IRESTServiceEndpointAttributeComparisonReport from 'domains/characterizations/models/IRESTServiceEndpointAttributeComparisonReport'
import ComparisonItemRow from 'domains/comparisons/components/ComparisonReport/ComparisonItemRow'
import { findSpecServiceEndointAttributeByName } from 'domains/modeling/utils/restCOR1p1utils'
import { OR_1P1_REST_ROSTERING_CONSUMER_SERVICES } from 'domains/modeling/constants/restCOneRoster1p1'

interface IProps {
  report: IRESTComparisonReport
  service: IRESTServiceComparisonReport
  endpoint: IRESTServiceEndpointComparisonReport
  attribute: IRESTServiceEndpointAttributeComparisonReport
  specification: IRestCSpec
  index: number
}

export default function RESTServiceEndpointAttributeComparisonReport(
  props: IProps,
) {
  const specAttribute = findSpecServiceEndointAttributeByName(
    props.specification,
    props.service.serviceName as OR_1P1_REST_ROSTERING_CONSUMER_SERVICES,
    props.endpoint.endpointName,
    props.attribute.attributeName,
  )
  const dataType = R.pathOr(
    props.attribute.dataType || 'String',
    ['dataType'],
    specAttribute,
  ) as string
  return (
    <ComparisonItemRow
      index={props.index}
      status={props.attribute.status}
      dataType={dataType}
      required={props.attribute.required}
      mdSource={props.attribute.mdSource}
      parentName={props.endpoint.endpointName}
      name={props.attribute.attributeName}
      description={props.attribute.description}
      notes={props.attribute.notes}
      errorMessage={props.attribute.errorMessage}
      smallestValueLength={props.attribute.smallestValueLength}
      largestValueLength={props.attribute.largestValueLength}
      key={`${props.endpoint.endpointName}-${props.attribute.attributeName}-attribute-comparison`}
    />
  )
}
