import React from 'react'

import ListFilterSection from 'lib/components/ListFilter/ListFilterSection'
import {
  IListFilter,
  IFilterState,
  IListFilterOption,
  isCheckboxChecked,
} from 'lib/components/ListFilter/listFilterUtils'
import Checkbox from 'lib/components/Checkbox'

interface IProps {
  filter: IListFilter
  renderDivider: boolean
  appliedFilters: IFilterState
  dataTest: string
  onChange: (option: IListFilterOption, event: any) => any
}

export default function ListCheckboxFilter(props: IProps) {
  const { filter, renderDivider, appliedFilters, dataTest } = props
  const onChange =
    (option: IListFilterOption) => (event: React.ChangeEvent<any>) =>
      props.onChange(option, event)

  return (
    <ListFilterSection title={filter.title} divider={renderDivider}>
      {filter.options.map((option: IListFilterOption, cIndex: number) => (
        <Checkbox
          key={`filter-${filter.title}-${option.value}-${cIndex}`}
          name={option.value}
          onChange={onChange(option)}
          checked={isCheckboxChecked(
            filter.title,
            option.value,
            appliedFilters,
          )}
          data-test={`${dataTest}-checkbox-${filter.title}-${cIndex}`}
        >
          {option.label}
        </Checkbox>
      ))}
    </ListFilterSection>
  )
}
