import React from 'react'
import * as R from 'ramda'
import { useHistory } from 'react-router-dom'

import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import { View, Paper, ListCallToActionButton } from 'lib/components'
import { IAddTrustedAppSelection } from 'domains/trustedApps/components/AddTrustedApps/models/IAddTrustedAppSelection'
import NonTableList from 'lib/components/NonTableList'
import SelectedIMSApplicationRow from 'domains/trustedApps/components/AddTrustedApps/SelectedIMSApplicationRow'
import { createRecord } from 'lib/records/workflows/createRecord'
import { TRUSTED_APPS_RECORD } from 'lib/records/modules/trustedApplications'
import { DISTRICT_TRUSTED_APPS_DASHBOARD_ROUTE } from 'domains/application/navigation/routes'
import { showToast } from 'lib/utils/toast'
import { getImsxDescription } from 'lib/api/utils'

interface IProps {
  onNext: () => any
  onPrevious: () => any
  selection: IAddTrustedAppSelection[]
  addApp: (id: number) => any
  removeApp: (id: number) => any
  updateApp: (id: number, updates: any) => any
  setSelection: (newSelection: IAddTrustedAppSelection[]) => void
}

export default function AddTrustedAppsStepTwo(props: IProps) {
  const [pending, setPending] = React.useState(false)
  const [duplicateApps, setDuplicateApps] = React.useState<
    IAddTrustedAppSelection[]
  >([])
  const history = useHistory()

  const onGoBackToDashboard = () =>
    history.replace(DISTRICT_TRUSTED_APPS_DASHBOARD_ROUTE)

  const onRemoveApp = (id: number) => {
    if (duplicateApps.length > 0) {
      const index = R.findIndex(R.propEq('applicationId', id), duplicateApps)
      if (index >= 0) setDuplicateApps(R.remove(index, 1, duplicateApps))
    }

    props.removeApp(id)
  }

  const onImport = async () => {
    setPending(true)
    const response = await createRecord(TRUSTED_APPS_RECORD, props.selection)
    setPending(false)

    if (response.success) {
      history.push(DISTRICT_TRUSTED_APPS_DASHBOARD_ROUTE)
    } else {
      try {
        const errorDescription = getImsxDescription(response)
        if (Array.isArray(errorDescription) && errorDescription.length > 0) {
          const duplicateIds = errorDescription.map((id) => parseInt(id))
          const duplicates = props.selection.filter((s) =>
            duplicateIds.includes(s.applicationId),
          )
          const newSelection = props.selection.filter(
            (s) => !duplicateIds.includes(s.applicationId),
          )
          props.setSelection(newSelection)
          setDuplicateApps(duplicates)
          showToast(
            'error',
            'The following applications already exist in your TrustEd Apps Dashboard. Please save your entered details and update these applications on the dashboard.',
            { autoClose: false },
          )
        } else {
          showToast('error', 'Failed to add TrustEd Apps. Please try again.')
        }
      } catch (error) {
        setDuplicateApps([])
        showToast('error', 'Failed to add TrustEd Apps. Please try again.')
      }
    }
  }

  const showApps = duplicateApps.length > 0 ? duplicateApps : props.selection
  const items = showApps.map(R.prop('applicationId'))

  return (
    <Paper>
      <View flexible="row-space-between-wrap">
        <WidgetHeader title="Step 2: Define Product Rating and Notes" />

        <div className="flex flex-row items-center mt-1 md:mt-0 space-x-1">
          <ListCallToActionButton
            variant="neutral"
            onClick={props.onPrevious}
            text="Go back"
            noIcon={true}
            pending={pending}
          />

          {duplicateApps.length < 1 && (
            <ListCallToActionButton
              onClick={onImport}
              text="Import into dashboard"
              noIcon={true}
              disabled={props.selection.length < 1}
              pending={pending}
              data-test="import-btn"
            />
          )}
          {duplicateApps.length > 0 && (
            <ListCallToActionButton
              variant="neutral"
              onClick={onGoBackToDashboard}
              text="See Dashboard"
              noIcon={true}
            />
          )}
        </div>
      </View>

      <View mt={3} />
      <NonTableList
        blankSlateText="No apps selected"
        nonRecordItems={items}
        RowComponent={SelectedIMSApplicationRow}
        rowData={{
          updateApp: props.updateApp,
          selection: showApps,
          removeApp: onRemoveApp,
          isDuplicate: duplicateApps.length > 0,
        }}
        usePageBottomListener={true}
      />
    </Paper>
  )
}
