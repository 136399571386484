import * as R from 'ramda'

import useRecordEntity from 'lib/records/hooks/useRecordEntity'
import { TRUSTED_APPS_REFERENCE_DATA_RECORD } from 'lib/records/modules/trustedAppsReferenceData'
import {
  IAdditionalInfoOption,
  ITrustedAppsAdditionalInfo,
} from 'domains/trustedApps/models/ITrustedAppsAdditionalInfo'
import { amIHed } from 'lib/constants/vocab'

export const transformRefData = (
  key: string,
  refData: any,
): IAdditionalInfoOption => {
  const option = R.pathOr([], ['applications'], refData).find(
    R.propEq('name', key),
  )
  return {
    multiple: R.pathOr(false, ['multipleAllowed'], option),
    extensible: R.pathOr(false, ['extensible'], option),
    adminValues: R.pathOr([], ['terms'], option)
      .map((t) => R.pathOr('', ['text'], t))
      .filter((t) => t.length > 0),
    teacherValues: R.pathOr([], ['terms'], option)
      .map((t) => R.pathOr('', ['simpletext'], t))
      .filter((t) => t.length > 0),
    orgValues: [],
  } as IAdditionalInfoOption
}

export default function useTrustedAppsAdditionalInfo(): [
  ITrustedAppsAdditionalInfo,
  boolean,
] {
  const [refData, pending] = useRecordEntity({
    record: TRUSTED_APPS_REFERENCE_DATA_RECORD,
    id: 1,
    noReFetch: true,
  })

  const additionalInfo: ITrustedAppsAdditionalInfo = {
    coreOrSupplemental: transformRefData('coreOrSupplemental', refData),
    subjects: transformRefData('subjects', refData),
    grades: amIHed()
      ? transformRefData('colleges', refData)
      : transformRefData('grades', refData),
    coverage: amIHed()
      ? transformRefData('colleges', refData)
      : transformRefData('grades', refData),
    rosteringMethod: transformRefData('rosteringMethod', refData),
    gradePassbackMethod: transformRefData('gradePassbackMethod', refData),
    accessMethods: transformRefData('accessMethods', refData),
    securityReview: transformRefData('securityReview', refData),
    learningStandardsAlignment: transformRefData(
      'learningStandardsAlignment',
      refData,
    ),
    downstreamAnalytics: transformRefData('downstreamAnalytics', refData),
    dataSharing: transformRefData('dataSharing', refData),
  }

  return [additionalInfo, !additionalInfo || pending]
}
