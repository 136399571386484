import React from 'react'
import { useSelector } from 'react-redux'
import { records } from '@ims/1edtech-frontend-common'
import * as R from 'ramda'

import { InputLabel, Select } from 'lib/components'
import { RootState } from 'lib/store/rootReducer'
import { LTI_PLATFORMS_RECORD } from 'lib/records/modules/ltiPlatforms'
import { getRecords } from 'lib/records/workflows/getRecords'
import ILTIPlatform from 'domains/configuration/models/ILTIPlatform'

type Props = {
  value: any
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => any
  addDefaultOption?: boolean
}

export default function OrgTrustEdAppsLTIPlatfromSelector(props: Props) {
  const ltiPlatforms = useSelector((state: RootState) =>
    records.recordsSelectors.fullRecordsSelector(LTI_PLATFORMS_RECORD)(
      state,
      props,
    ),
  )

  React.useEffect(() => {
    getRecords({
      record: LTI_PLATFORMS_RECORD,
      params: { sort: 'platformName:ascending' },
      force: true,
    })
  }, [])
  const platforms = R.pathOr([], ['items'], ltiPlatforms) as ILTIPlatform[]
  return (
    <div>
      <InputLabel>LTI Platform</InputLabel>
      <Select
        name="id"
        variant="bold"
        value={props.value}
        onChange={props.onChange}
        width="100%"
        data-test="lti-platform-selector"
      >
        {props.addDefaultOption && (
          <option value="">Select an LTI Platform</option>
        )}
        {platforms.map((platform, index) => (
          <option key={`platform-${index}`} value={platform.id}>
            {platform.platformName}
          </option>
        ))}
      </Select>
    </div>
  )
}
