import * as normalizr from 'normalizr'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

export const IMS_CERTIFICATIONS_RECORD = 'imsCertifications'

const imsCertifications: records.modules.IModule = {
  record: IMS_CERTIFICATIONS_RECORD,
  responseDataKey: 'descriptions',
  getCreateResponseData: R.propOr({}, 'data'),
  schema: new normalizr.schema.Entity(
    IMS_CERTIFICATIONS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(['imsCertType', 'imsCertTitle']),
  ),
  api: {
    create: () => 'integrations',
    get: (id) => `trustedapps/integrations/certifications/${id}`,
    getList: () => 'trustedapps/integrations/certifications',
    update: (id) => `trustedapps/integrations/certifications/${id}`,
    delete: (id) => `trustedapps/integrations/certifications/${id}`,
  },
}

export default imsCertifications
