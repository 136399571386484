import React from 'react'
import { utils } from '@ims/1edtech-frontend-common'

import { View, Text } from 'lib/components'
import colors from 'lib/styles/colors'

interface IProps {
  name: string
  url: string
  isMailTo?: boolean
}

export default function ReportLinkBox(props: IProps) {
  const { name, url, isMailTo } = props
  if (!utils.hasValue(url)) {
    return null
  }

  const content =
    utils.url.isValidUrl(url) || utils.url.isValidEmail(url) ? (
      <a
        href={isMailTo && utils.hasValue(url) ? `mailto:${url}` : url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: colors.text }}
      >
        {url}
      </a>
    ) : (
      url
    )
  return (
    <View
      bg="background"
      border={`1px solid ${colors.reportTableBorder}`}
      py={3}
      px={4}
      flex={1}
      overflowX="hidden"
      minWidth={164}
      mt={[2, 0]}
      data-test={`model-url-${name}`}
    >
      <Text fontSize={[12, 14, 16, 18]} fontWeight={700} mb={2}>
        {name}:
      </Text>
      <Text overflow="ellipsis">{content}</Text>
    </View>
  )
}
