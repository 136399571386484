import {
  FREEMIUM_LOCKED_OUT_MESSAGE,
  ORG_DENIED_LOGIN_MESSAGE,
} from '../constants'
import { getImsxDescription } from './getImsxDescription'

export const isResponseIndicatingLockedOut = (response: any) => {
  const message = getImsxDescription(response)
  return (
    message === FREEMIUM_LOCKED_OUT_MESSAGE ||
    message === ORG_DENIED_LOGIN_MESSAGE
  )
}
