import React from 'react'
// @ts-ignore
import renderHTML from 'react-render-html'

import { View } from 'lib/components'
import Linkify from 'lib/components/Linkify'
import clsx from 'clsx'

interface IProps {
  color?: string
  fontSize?: number
  lineHeight?: number
  children: any
  className?: string
}

export default function HTML(props: IProps) {
  const { color = 'text', fontSize = 14, lineHeight = 26, className } = props
  return (
    <View
      color={color}
      fontSize={fontSize}
      lineHeight={`${lineHeight}px`}
      className={clsx('html', className)}
    >
      <Linkify>{renderHTML(props.children)}</Linkify>
    </View>
  )
}
