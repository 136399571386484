import React from 'react'

import { View, Text, Span } from 'lib/components'

interface IProps {
  path: string
  keyName: string
  keyValue: any
  valueName: string
  value: any
}

export default function CCCharacterizationReportLOMKeyValue(props: IProps) {
  return (
    <View data-test={props.path}>
      <Text mb={1} whiteSpace="pre-line" data-test={`${props.path}.key`}>
        <Span fontWeight={700}>{props.keyName}: </Span> {props.keyValue}
      </Text>
      <Text mb={1} whiteSpace="pre-line" data-test={`${props.path}.value`}>
        <Span fontWeight={700}>{props.valueName}: </Span> {props.value}
      </Text>
    </View>
  )
}
