import React, { useEffect } from 'react'
import {
  Redirect,
  Route,
  Switch,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { View } from 'lib/components'
import NavigationDrawer from 'domains/application/components/NavigationDrawer'
import * as routes from 'domains/application/navigation/routes'
import SupplierORCheckDashboardScreen from 'domains/dashboard/screens/SupplierORCheckDashboardScreen'
import ComparisonsScreen from 'domains/comparisons/screens/ComparisonsScreen'
import UsersScreen from 'domains/users/screens/UsersScreen'
import SupplierProductCatalogScreen from 'domains/products/screens/SupplierProductCatalogScreen'
import ProductModelingScreen from 'domains/modeling/screens/ProductModelingScreen'
import CsvCFileModelingScreen from 'domains/modeling/screens/CsvCFileModelingScreen'
import DistrictORCheckDashboardScreen from 'domains/dashboard/screens/DistrictORCheckDashboardScreen'
import DistrictProductCatalogScreen from 'domains/products/screens/DistrictProductCatalogScreen'
import SupplierProductDetailScreen from 'domains/products/screens/SupplierProductDetailScreen'
import DistrictProductDetailScreen from 'domains/products/screens/DistrictProductDetailScreen'
import CreateCharacterizationScreen from 'domains/characterizations/screens/CreateCharacterizationScreen'
import {
  isSupplierRoleSelector,
  isIMSAdminRoleSelector,
} from 'domains/authentication/selectors/roles'
import OrganizationPreferencesScreen from 'domains/orgs/screens/OrganizationPreferencesScreen'
import AdminDashboardScreen from 'domains/dashboard/screens/AdminDashboardScreen'
import AdminOrganizationsScreen from 'domains/orgs/screens/AdminOrganizationsScreen'
import AdminOrganizationDetailsScreen from 'domains/orgs/screens/AdminOrganizationDetailsScreen'
import UserDetailsScreen from 'domains/users/screens/UserDetailsScreen'
import StartProductModelingScreen from 'domains/modeling/screens/StartProductModelingScreen'
import ProductModelingRestServicesEditScreen from 'domains/modeling/screens/ProductModelingRestServicesEditScreen'
import SupplierModelCharacterizationReportScreen from 'domains/characterizations/screens/SupplierModelCharacterizationReportScreen'
import CharacterizationReportRouterScreen from 'domains/characterizations/screens/CharacterizationReportRouterScreen'
import ComparisonReportScreen from 'domains/comparisons/screens/ComparisonReportScreen'
import SourcesScreen from 'domains/configuration/screens/SourcesScreen'
import CommonCartridgeCharacterizationReportTOCScreen from 'domains/commonCartridge/screens/CommonCartridgeCharacterizationReportTOCScreen'
import CommonCartridgeCharacterizationReportItemDetailsScreen from 'domains/commonCartridge/screens/CommonCartridgeCharacterizationReportItemDetailsScreen'
import CommonCartridgeCharacterizationReportStandardsScreen from 'domains/commonCartridge/screens/CommonCartridgeCharacterizationReportStandardsScreen'
import CommonCartridgeCharacterizationReportStandardDetailsScreen from 'domains/commonCartridge/screens/CommonCartridgeCharacterizationReportStandardDetailsScreen'
import CommonCartridgeCharacterizationReportResourceDetailsScreen from 'domains/commonCartridge/screens/CommonCartridgeCharacterizationReportResourceDetailsScreen'
import CommonCartridgeCharacterizationReportResourcesScreen from 'domains/commonCartridge/screens/CommonCartridgeCharacterizationReportResourcesScreen'
import ApplicationDashboardScreen from 'domains/application/screens/ApplicationDashboardScreen'
import ORCharacterizationsScreenWrapper from 'domains/characterizations/screens/ORCharacterizationsScreenWrapper'
import EventHeaderConfigScreen from 'domains/configuration/screens/EventHeaderConfigScreen'
import CommonCartridgeCheckDashboardScreen from 'domains/dashboard/screens/CommonCartridgeCheckDashboardScreen'
import TrustedAppsDashboardScreen from 'domains/trustedApps/screens/TrustedAppsDashboardScreen'
import TrustedAppDetailsScreen from 'domains/trustedApps/screens/TrustedAppDetailsScreen'
import AddTrustedAppsScreen from 'domains/trustedApps/screens/AddTrustedAppsScreen'
import AddCustomTrustedAppsScreen from 'domains/trustedApps/screens/AddCustomTrustedAppsScreen'
import TrustedAppCertsScreen from 'domains/trustedApps/screens/TrustedAppCertsScreen'
import TrustedAppVettingsScreen from 'domains/trustedApps/screens/TrustedAppVettingsScreen'
import TrustedAppVettingReportScreen from 'domains/trustedApps/screens/TrustedAppVettingReportScreen'
import GradebookPushCharacterizationSessionScreen from '../../characterizations/screens/GradebookPushCharacterizationSessionScreen'
import LTIPlatformsScreen from 'domains/configuration/screens/LTIPlatformsScreen'
import TrustedAppsSetupScreen from 'domains/trustedApps/screens/TrustedAppsSetupScreen'
import TrustedAppsSharedDashboardsScreen from 'domains/trustedApps/screens/TrustedAppsSharedDashboardsScreen'
import TrustedAppsChangelogScreen from 'domains/trustedApps/screens/TrustedAppsChangelogScreen'
import { UserRole } from 'domains/users/constants/userRoles'
import { meSelector } from 'domains/authentication/selectors/me'
import { userHasRole } from 'domains/users/models/IUser'
import * as roles from 'domains/users/constants/userRoles'

function RoleBasedRoute({
  requiredRoles,
  ...rest
}: RouteProps & { requiredRoles: UserRole[] }) {
  const me = useSelector(meSelector)
  const canViewRoute = userHasRole(me, requiredRoles)
  if (!canViewRoute) return null
  return <Route {...rest} />
}

const getCCScreens = (rootCharRoute: string) => [
  <RoleBasedRoute
    key="CCCharacterizationReport"
    exact={true}
    path={`${rootCharRoute}/:format/:id`}
    component={CharacterizationReportRouterScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="ccReportTableOfContents"
    exact={true}
    path={`${rootCharRoute}/:format/:id/items`}
    component={CommonCartridgeCharacterizationReportTOCScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="ccReportItemDetails"
    exact={true}
    path={`${rootCharRoute}/:format/:id/items/:itemId`}
    component={CommonCartridgeCharacterizationReportItemDetailsScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="ccReportStandards"
    exact={true}
    path={`${rootCharRoute}/:format/:id/standards`}
    component={CommonCartridgeCharacterizationReportStandardsScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="ccReportStandardDetails"
    exact={true}
    path={`${rootCharRoute}/:format/:id/standards/:standardId`}
    component={CommonCartridgeCharacterizationReportStandardDetailsScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="ccReportResources"
    exact={true}
    path={`${rootCharRoute}/:format/:id/resources`}
    component={CommonCartridgeCharacterizationReportResourcesScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="ccReportResourceDetails"
    exact={true}
    path={`${rootCharRoute}/:format/:id/resources/:resourceId`}
    component={CommonCartridgeCharacterizationReportResourceDetailsScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
]

const districtRoutes = [
  <RoleBasedRoute
    key="home"
    path={routes.DISTRICT_DASHBOARD_ROUTE}
    component={ApplicationDashboardScreen}
    requiredRoles={[]}
  />,
  <RoleBasedRoute
    key="oneRosterDashboard"
    path={routes.DISTRICT_OR_DASHBOARD_ROUTE}
    component={DistrictORCheckDashboardScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="ORCharacterizations"
    exact={true}
    path={`${routes.DISTRICT_OR_CHARACTERIZATIONS_ROUTE}`}
    component={ORCharacterizationsScreenWrapper}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="commonCartridgeDashboard"
    path={routes.DISTRICT_CC_DASHBOARD_ROUTE}
    component={CommonCartridgeCheckDashboardScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="createORCharacterization"
    exact={true}
    path={`${routes.DISTRICT_OR_CHARACTERIZATIONS_ROUTE}/:spec/create`}
    component={CreateCharacterizationScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="createCCCharacterization"
    exact={true}
    path={`${routes.DISTRICT_CC_CHARACTERIZATIONS_ROUTE}/:spec/create`}
    component={CreateCharacterizationScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="ORCharacterizationReport"
    exact={true}
    path={`${routes.DISTRICT_OR_CHARACTERIZATIONS_ROUTE}/:format/:id`}
    component={CharacterizationReportRouterScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  ...getCCScreens(routes.DISTRICT_CC_CHARACTERIZATIONS_ROUTE),
  <RoleBasedRoute
    key="products"
    exact={true}
    path={routes.DISTRICT_OR_PRODUCT_CATALOG_ROUTE}
    component={DistrictProductCatalogScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="productDetail"
    exact={true}
    path={`${routes.DISTRICT_OR_PRODUCT_CATALOG_ROUTE}/:id`}
    component={DistrictProductDetailScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="comparisons"
    exact={true}
    path={routes.DISTRICT_OR_COMPARISONS_ROUTE}
    component={ComparisonsScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="comparisonDetail"
    path={`${routes.DISTRICT_OR_COMPARISONS_ROUTE}/:format/:id`}
    component={ComparisonReportScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="users"
    exact={true}
    path={routes.DISTRICT_USERS_ROUTE}
    component={UsersScreen}
    requiredRoles={[roles.ADMIN_ROLE]}
  />,
  <RoleBasedRoute
    key="organization"
    exact={true}
    path={routes.DISTRICT_ORG_PREF_ROUTE}
    component={OrganizationPreferencesScreen}
    requiredRoles={[roles.ADMIN_ROLE]}
  />,
  <RoleBasedRoute
    key="userDetails"
    path={`${routes.DISTRICT_USERS_ROUTE}/:id`}
    component={UserDetailsScreen}
    requiredRoles={[]}
  />,
  <RoleBasedRoute
    key="trustedAppsDashboard"
    exact={true}
    path={routes.DISTRICT_TRUSTED_APPS_DASHBOARD_ROUTE}
    component={TrustedAppsDashboardScreen}
    requiredRoles={[
      roles.ADMIN_ROLE,
      roles.CCX_USER_ROLE,
      roles.TA_ADMIN_ROLE,
      roles.TA_CONTRIBUTOR_ROLE,
    ]}
  />,
  <RoleBasedRoute
    key="trustedAppsSetup"
    exact={true}
    path={routes.DISTRICT_TRUSTED_APPS_SETUP_ROUTE}
    component={TrustedAppsSetupScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.TA_ADMIN_ROLE]}
  />,
  <RoleBasedRoute
    key="trustedAppsShared"
    exact={true}
    path={routes.DISTRICT_TRUSTED_APPS_SHARED_DASHBOARDS_ROUTE}
    component={TrustedAppsSharedDashboardsScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.TA_ADMIN_ROLE]}
  />,
  <RoleBasedRoute
    key="trustedAppsShared"
    exact={true}
    path={`${routes.DISTRICT_TRUSTED_APPS_SHARED_DASHBOARDS_ROUTE}?orgId=:orgId`}
    component={TrustedAppsSharedDashboardsScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.TA_ADMIN_ROLE]}
  />,
  <RoleBasedRoute
    key="trustedAppsChangelog"
    exact={true}
    path={routes.DISTRICT_TRUSTED_APPS_CHANGELOG_ROUTE}
    component={TrustedAppsChangelogScreen}
    requiredRoles={[
      roles.ADMIN_ROLE,
      roles.CCX_USER_ROLE,
      roles.TA_ADMIN_ROLE,
      roles.TA_CONTRIBUTOR_ROLE,
    ]}
  />,
  <RoleBasedRoute
    key="addTrustedApps"
    exact={true}
    path={routes.DISTRICT_TRUSTED_APPS_ADD_ROUTE}
    component={AddTrustedAppsScreen}
    requiredRoles={[
      roles.ADMIN_ROLE,
      roles.CCX_USER_ROLE,
      roles.TA_ADMIN_ROLE,
      roles.TA_CONTRIBUTOR_ROLE,
    ]}
  />,
  <RoleBasedRoute
    key="addCustomTrustedApps"
    exact={true}
    path={routes.DISTRICT_TRUSTED_APPS_ADD_CUSTOM_ROUTE}
    component={AddCustomTrustedAppsScreen}
    requiredRoles={[
      roles.ADMIN_ROLE,
      roles.CCX_USER_ROLE,
      roles.TA_ADMIN_ROLE,
      roles.TA_CONTRIBUTOR_ROLE,
    ]}
  />,
  <RoleBasedRoute
    key="trustedAppDetails"
    exact={true}
    path={`${routes.DISTRICT_TRUSTED_APPS_ROUTE}/:id`}
    component={TrustedAppDetailsScreen}
    requiredRoles={[
      roles.ADMIN_ROLE,
      roles.CCX_USER_ROLE,
      roles.TA_ADMIN_ROLE,
      roles.TA_CONTRIBUTOR_ROLE,
    ]}
  />,
  <RoleBasedRoute
    key="sharedTrustedAppDetails"
    exact={true}
    path={`${routes.DISTRICT_TRUSTED_APPS_ROUTE}/shared/:orgId/:id`}
    component={TrustedAppDetailsScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.TA_ADMIN_ROLE]}
  />,
  <RoleBasedRoute
    key="trustedAppCertificationDetails"
    exact={true}
    path={`${routes.DISTRICT_TRUSTED_APPS_ROUTE}/:id/certifications`}
    component={TrustedAppCertsScreen}
    requiredRoles={[
      roles.ADMIN_ROLE,
      roles.CCX_USER_ROLE,
      roles.TA_ADMIN_ROLE,
      roles.TA_CONTRIBUTOR_ROLE,
    ]}
  />,
  <RoleBasedRoute
    key="sharedTrustedAppCertificationDetails"
    exact={true}
    path={`${routes.DISTRICT_TRUSTED_APPS_ROUTE}/shared/:orgId/:id/certifications`}
    component={TrustedAppCertsScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.TA_ADMIN_ROLE]}
  />,
  <RoleBasedRoute
    key="trustedAppVettings"
    exact={true}
    path={`${routes.DISTRICT_TRUSTED_APPS_ROUTE}/:id/vettings`}
    component={TrustedAppVettingsScreen}
    requiredRoles={[
      roles.ADMIN_ROLE,
      roles.CCX_USER_ROLE,
      roles.TA_ADMIN_ROLE,
      roles.TA_CONTRIBUTOR_ROLE,
    ]}
  />,
  <RoleBasedRoute
    key="sharedTrustedAppVettings"
    exact={true}
    path={`${routes.DISTRICT_TRUSTED_APPS_ROUTE}/shared/:orgId/:id/vettings`}
    component={TrustedAppVettingsScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.TA_ADMIN_ROLE]}
  />,
  <RoleBasedRoute
    key="trustedAppVettingDetails"
    exact={true}
    path={`${routes.DISTRICT_TRUSTED_APPS_ROUTE}/:id/vettings/:vettingId`}
    component={TrustedAppVettingReportScreen}
    requiredRoles={[
      roles.ADMIN_ROLE,
      roles.TA_ADMIN_ROLE,
      roles.TA_CONTRIBUTOR_ROLE,
      roles.CCX_USER_ROLE,
    ]}
  />,
  <RoleBasedRoute
    key="sharedTrustedAppVettingDetails"
    exact={true}
    path={`${routes.DISTRICT_TRUSTED_APPS_ROUTE}/shared/:orgId/:id/vettings/:vettingId`}
    component={TrustedAppVettingReportScreen}
    requiredRoles={[
      roles.ADMIN_ROLE,
      roles.CCX_USER_ROLE,
      roles.TA_ADMIN_ROLE,
      roles.TA_CONTRIBUTOR_ROLE,
    ]}
  />,
]
const supplierRoutes = [
  <RoleBasedRoute
    key="home"
    path={routes.SUPPLIER_DASHBOARD_ROUTE}
    component={ApplicationDashboardScreen}
    requiredRoles={[]}
  />,
  <RoleBasedRoute
    key="oneRosterDashboard"
    path={routes.SUPPLIER_OR_DASHBOARD_ROUTE}
    component={SupplierORCheckDashboardScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="products"
    exact={true}
    path={routes.SUPPLIER_OR_PRODUCT_CATALOG_ROUTE}
    component={SupplierProductCatalogScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="productDetail"
    exact={true}
    path={`${routes.SUPPLIER_OR_PRODUCT_CATALOG_ROUTE}/:id/:format`}
    component={SupplierProductDetailScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="productDetail"
    exact={true}
    path={`${routes.SUPPLIER_OR_PRODUCT_CATALOG_ROUTE}/:id`}
    component={SupplierProductDetailScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="startModeling"
    exact={true}
    path={`${routes.SUPPLIER_OR_PRODUCT_CATALOG_ROUTE}/:id/:format/model/start`}
    component={StartProductModelingScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="modelDetail"
    exact={true}
    path={`${routes.SUPPLIER_OR_PRODUCT_CATALOG_ROUTE}/:id/:format/model/:modelId`}
    component={ProductModelingScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="modelFileDetail"
    path={`${routes.SUPPLIER_OR_PRODUCT_CATALOG_ROUTE}/:id/:format/model/:modelId/files/:fileName`}
    component={CsvCFileModelingScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="modelServiceDetail"
    path={`${routes.SUPPLIER_OR_PRODUCT_CATALOG_ROUTE}/:id/:format/model/:modelId/services/:services`}
    component={ProductModelingRestServicesEditScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="supplierModelCharacterizationReport"
    path={`${routes.SUPPLIER_OR_PRODUCT_CATALOG_ROUTE}/:id/:format/model/:modelId/characterization`}
    component={SupplierModelCharacterizationReportScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="createORCharacterization"
    exact={true}
    path={`${routes.SUPPLIER_OR_CHARACTERIZATIONS_ROUTE}/:spec/create`}
    component={CreateCharacterizationScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="createCCCharacterization"
    exact={true}
    path={`${routes.SUPPLIER_CC_CHARACTERIZATIONS_ROUTE}/:spec/create`}
    component={CreateCharacterizationScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="ORComparisons"
    exact={true}
    path={routes.SUPPLIER_OR_COMPARISONS_ROUTE}
    component={ComparisonsScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="ORComparisonReport"
    path={`${routes.SUPPLIER_OR_COMPARISONS_ROUTE}/:format/:id`}
    component={ComparisonReportScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="ORCharacterizations"
    exact={true}
    path={`${routes.SUPPLIER_OR_CHARACTERIZATIONS_ROUTE}`}
    component={ORCharacterizationsScreenWrapper}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="commonCartridgeDashboard"
    path={routes.SUPPLIER_CC_DASHBOARD_ROUTE}
    component={CommonCartridgeCheckDashboardScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="ORCharacterizationReports"
    exact={true}
    path={`${routes.SUPPLIER_OR_CHARACTERIZATIONS_ROUTE}/:format/:id`}
    component={CharacterizationReportRouterScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  <RoleBasedRoute
    key="ORGBPushCharacterizationSession"
    exact={true}
    path={`${routes.SUPPLIER_OR_CHARACTERIZATIONS_ROUTE}/:format/:id/session`}
    component={GradebookPushCharacterizationSessionScreen}
    requiredRoles={[roles.ADMIN_ROLE, roles.CCX_USER_ROLE]}
  />,
  ...getCCScreens(routes.SUPPLIER_CC_CHARACTERIZATIONS_ROUTE),
  <RoleBasedRoute
    key="userDetails"
    path={`${routes.SUPPLIER_USERS_ROUTE}/:id`}
    component={UserDetailsScreen}
    requiredRoles={[]}
  />,
  <RoleBasedRoute
    key="users"
    exact={true}
    path={`${routes.SUPPLIER_USERS_ROUTE}`}
    component={UsersScreen}
    requiredRoles={[roles.ADMIN_ROLE]}
  />,
]

const imsRoutes = [
  <RoleBasedRoute
    key="admin-dashboard"
    path={`${routes.ADMIN_DASHBOARD_ROUTE}`}
    component={AdminDashboardScreen}
    requiredRoles={[]}
  />,
  <RoleBasedRoute
    key="admin-organizations"
    exact={true}
    path={`${routes.ADMIN_ORGANIZATIONS_ROUTE}`}
    component={AdminOrganizationsScreen}
    requiredRoles={[]}
  />,
  <RoleBasedRoute
    key="admin-organization-details"
    exact={true}
    path={`${routes.ADMIN_ORGANIZATIONS_ROUTE}/:id`}
    component={AdminOrganizationDetailsScreen}
    requiredRoles={[]}
  />,
  <RoleBasedRoute
    key="orgUserDetails"
    path={`${routes.ADMIN_ORGANIZATIONS_ROUTE}/:orgId/users/:id`}
    component={UserDetailsScreen}
    requiredRoles={[]}
  />,
  <RoleBasedRoute
    key="sourcesManagement"
    path={routes.ADMIN_SOURCES_ROUTE}
    component={SourcesScreen}
    requiredRoles={[]}
  />,
  <RoleBasedRoute
    key="LTIPlatformsManagement"
    path={routes.ADMIN_LTI_PLATFORMS_ROUTE}
    component={LTIPlatformsScreen}
    requiredRoles={[]}
  />,
  <RoleBasedRoute
    key="eventHeaderConfig"
    path={routes.ADMIN_LANDING_PAGE_URL_ROUTE}
    component={EventHeaderConfigScreen}
    requiredRoles={[]}
  />,
  <RoleBasedRoute
    key="users"
    exact={true}
    path={`${routes.ADMIN_USERS_ROUTE}`}
    component={UsersScreen}
    requiredRoles={[roles.ADMIN_ROLE]}
  />,
  <RoleBasedRoute
    key="userDetails"
    path={`${routes.ADMIN_USERS_ROUTE}/:id`}
    component={UserDetailsScreen}
    requiredRoles={[]}
  />,
]

export const MainRouter = (props: RouteComponentProps) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [props.location])

  const me = useSelector(meSelector)
  const isSupplier = useSelector(isSupplierRoleSelector)
  const isIMSAdmin = useSelector(isIMSAdminRoleSelector)

  let routeComponents = isIMSAdmin
    ? imsRoutes
    : isSupplier
    ? supplierRoutes
    : districtRoutes
  routeComponents = routeComponents.filter((route) =>
    userHasRole(me, route.props.requiredRoles),
  )
  routeComponents.push(
    <Redirect
      key="redirect"
      to={
        isIMSAdmin
          ? routes.ADMIN_DASHBOARD_ROUTE
          : isSupplier
          ? routes.SUPPLIER_DASHBOARD_ROUTE
          : routes.DISTRICT_DASHBOARD_ROUTE
      }
    />,
  )

  return (
    <View data-test="screen-container">
      <NavigationDrawer>
        <Switch>{routeComponents}</Switch>
      </NavigationDrawer>
      <ToastContainer />
    </View>
  )
}

export default MainRouter
