import * as normalizr from 'normalizr'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

export const NOTIFICATIONS_RECORD = 'notifications'

const notifications: records.modules.IModule = {
  record: NOTIFICATIONS_RECORD,
  schema: new normalizr.schema.Entity(
    NOTIFICATIONS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(),
  ),
  api: {
    create: () => 'notifications',
    get: (id) => `notifications/${id}`,
    getList: (params) => `users/${R.pathOr('0', ['id'], params)}/notifications`,
    update: (id) => `notifications/${id}/read`,
    delete: (id) => `notifications/${id}`,
  },
}

export default notifications
