import { utils } from '@ims/1edtech-frontend-common'

import {
  getRoute,
  DASHBOARD_ROUTE,
  CHARACTERIZATIONS_ROUTE,
} from 'domains/application/navigation/routes'
import * as formats from 'domains/formats/constants/formats'
import {
  COMMON_CARTRIDGE_BASE_NAV_ROUTE,
  ONEROSTER_BASE_NAV_ROUTE,
} from 'domains/specifications/constants/specNavigation'

export const getCharacterizationsListRoute = (
  format: formats.AnySpecFormatType,
) => {
  switch (formats.ANY_FORMAT_TO_SPEC_FORMAT_MAP[format]) {
    case formats.COMMON_CARTRIDGE_V1P2:
    case formats.COMMON_CARTRIDGE_V1P3:
    case formats.THIN_COMMON_CARTRIDGE_V1P2:
    case formats.THIN_COMMON_CARTRIDGE_V1P3:
      return getRoute(`/${COMMON_CARTRIDGE_BASE_NAV_ROUTE}${DASHBOARD_ROUTE}`)
    default:
      return getRoute(`/${ONEROSTER_BASE_NAV_ROUTE}${CHARACTERIZATIONS_ROUTE}`)
  }
}

export const getCharacterizationRoute = (
  id: string | number,
  format: formats.AnySpecFormatType,
  urlParts: string | string[] = '',
  queryString = {},
) => {
  const isGBPush =
    format ===
    formats.ANY_FORMAT_TO_SPEC_FORMAT_MAP[
      formats.ONER_V1P1_REST_PROVIDER_GRADEBOOK_PUSH_OPTIONAL
    ]
  const parts = isGBPush
    ? utils.hasValue(urlParts)
      ? ['session', ...utils.array.ensureArray(urlParts)]
      : 'session'
    : urlParts
  const navFormat = formats.FORMAT_TO_FORMAT_NAV_ROUTE_MAP[format]
  let baseURL = ''
  switch (formats.ANY_FORMAT_TO_SPEC_FORMAT_MAP[format]) {
    case formats.COMMON_CARTRIDGE_V1P2:
    case formats.COMMON_CARTRIDGE_V1P3:
    case formats.THIN_COMMON_CARTRIDGE_V1P2:
    case formats.THIN_COMMON_CARTRIDGE_V1P3:
      baseURL = `/${COMMON_CARTRIDGE_BASE_NAV_ROUTE}${CHARACTERIZATIONS_ROUTE}`
      break
    default:
      baseURL = `/${ONEROSTER_BASE_NAV_ROUTE}${CHARACTERIZATIONS_ROUTE}`
      break
  }
  const route = getRoute(`${baseURL}/${navFormat}`, id, parts, queryString)
  return route
}
