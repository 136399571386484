import { useState } from 'react'
import * as R from 'ramda'

export interface IOpenTableRows {
  OpenTableRows: any
  onCloseRow: (
    id: string | number,
    wasSaved?: boolean,
    wasCleared?: boolean,
  ) => any
  onToggleRow: (id: string | number) => any
}

function useTableRows(): IOpenTableRows {
  const [openRows, setOpenTableRows] = useState({}) // eslint-disable-line

  const onCloseRow = (
    id: string | number,
    wasSaved = true,
    wasCleared = false,
  ) =>
    setOpenTableRows({
      ...openRows,
      [id]: wasSaved && !wasCleared,
    })

  const onToggleRow = (id: string | number) => {
    setOpenTableRows(R.over(R.lensPath([id]), R.not, openRows))
  }

  return {
    OpenTableRows: openRows,
    onCloseRow,
    onToggleRow,
  }
}

interface IProps {
  children: (options: IOpenTableRows) => any
}

export function OpenTableRows(props: IProps) {
  const options = useTableRows()
  return props.children(options)
}
