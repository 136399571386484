import React from 'react'
import * as R from 'ramda'

import { View, Text, ToolTip } from 'lib/components'
import IComparison from 'domains/comparisons/models/IComparison'

interface IProps extends IComparison {
  prop: string
  fallbackProp?: string
}

export default function ComparisonDistrictPropCell(props: IProps) {
  const { id, prop, fallbackProp } = props

  const tooltipId = `${id}-district-${prop}`
  const fallBackValue = R.pathOr('', [fallbackProp || ''], props)
  const value = R.pathOr(fallBackValue, [prop], props)
  return (
    <View flexible="row-v-center">
      <ToolTip id={tooltipId} tip={value} delay={250}>
        <Text textAlign="left" fontSize={13}>
          {value}
        </Text>
      </ToolTip>
    </View>
  )
}
