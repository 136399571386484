import React from 'react'
import * as R from 'ramda'

import ITransformedCharacterizationCSVFile from 'domains/characterizations/models/ITransformedCharacterizationCSVFile'
import { View } from 'lib/components'
import PrintFileReportHeader from 'domains/reports/components/PrintFileReportHeader'
import { getCharacterizationReportFileStatus } from 'domains/characterizations/utils/characterizationReport'
import ITransformedCharacterizationCSVReport from 'domains/characterizations/models/ITransformedCharacterizationCSVReport'
import PrintReportTopLevelError from 'domains/reports/components/PrintReportTopLevelError'
import { RED_REPORT_STATUS } from 'domains/reports/models/IReportStatuses'
import PrintOR1P1CSVCharacterizationFileColumnErrors from 'domains/characterizations/components/DistrictCharacterizationDetails/print/PrintOR1P1CSVCharacterizationFileColumnErrors'
import ICSVSpecification from 'domains/specifications/models/ICSVSpecification'
import CharacterizationFileReportColumnsList from 'domains/characterizations/components/CharacterizationFileReportColumnsList'
import ICSVConsumerModel from 'domains/modeling/models/ICSVConsumerModel'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  file: ITransformedCharacterizationCSVFile
  report: ITransformedCharacterizationCSVReport
  specification: ICSVSpecification
  model?: ICSVConsumerModel
}

export default function PrintOR1P1CSVCharacterizationReportFile(props: IProps) {
  return (
    <View my={3}>
      <PrintFileReportHeader
        name={props.file.fileLabel}
        status={getCharacterizationReportFileStatus(
          props.file.fileLabel,
          props.report,
        )}
        hr={true}
      />
      <View ml={3}>
        {utils.hasValue(props.file.errors) && (
          <PrintReportTopLevelError
            status={RED_REPORT_STATUS}
            title={`The following error(s) with your ${
              props.file.fileLabel ? 'file' : 'fileset'
            } are preventing characterization:`}
            errors={props.file.errors.map(R.prop('description'))}
            dataTest={
              props.file.fileLabel
                ? `${props.file.fileLabel}-file-error`
                : 'fileset-error'
            }
          />
        )}
      </View>
      {Object.keys(props.file.columnErrors).map((column) => (
        <PrintOR1P1CSVCharacterizationFileColumnErrors
          key={`${props.file.fileLabel}-${column}`}
          fileLabel={props.file.fileLabel}
          column={column}
          errors={props.file.columnErrors[column]}
          specification={props.specification}
        />
      ))}

      <CharacterizationFileReportColumnsList
        fileLabel={props.file.fileLabel}
        report={props.report}
        specification={props.specification}
        model={props.model}
      />
    </View>
  )
}
