import orCSVCharacterizations from 'lib/records/modules/orCSVCharacterizations'
import commonCartridgeCharacterizations from 'lib/records/modules/commonCartridgeCharacterizations'
import commonCartridgeCharacterizationReports from 'lib/records/modules/commonCartridgeCharacterizationReports'
import commonCartridgeCharacterizationReportItems from 'lib/records/modules/commonCartridgeCharacterizationReportItems'
import commonCartridgeCharacterizationReportStandards from 'lib/records/modules/commonCartridgeCharacterizationReportStandards'
import commonCartridgeCharacterizationReportResources from 'lib/records/modules/commonCartridgeCharacterizationReportResources'
import oneRosterCSVCharacterizationReports from 'lib/records/modules/oneRosterCSVCharacterizationReports'
import comparisons from 'lib/records/modules/comparisons'
import csvComparisonsReport from 'lib/records/modules/csvComparisonsReport'
import csvCModelCharacterizations from 'lib/records/modules/csvCModelCharacterizations'
import restCModelCharacterizations from 'lib/records/modules/restCModelCharacterizations'
import csvCModelCharacterizationReports from 'lib/records/modules/csvCModelCharacterizationReports'
import csvCModels from 'lib/records/modules/csvCModels'
import formats from 'lib/records/modules/formats'
import modeledProducts from 'lib/records/modules/modeledProducts'
import notifications from 'lib/records/modules/notifications'
import organizations from 'lib/records/modules/organizations'
import orgActions from 'lib/records/modules/orgActions'
import suppliers from 'lib/records/modules/suppliers'
import products from 'lib/records/modules/products'
import productComparisons from 'lib/records/modules/productComparisons'
import users from 'lib/records/modules/users'
import userPreferences from 'lib/records/modules/userPreferences'
import sources from 'lib/records/modules/sources'
import specifications from 'lib/records/modules/specifications'
import orRESTCharacterizations from 'lib/records/modules/orRESTCharacterizations'
import orRESTPushCharacterizations from 'lib/records/modules/orRESTPushCharacterizations'
import restCModels from 'lib/records/modules/restCModels'
import restCharacterizationReports from 'lib/records/modules/restCharacterizationReports'
import restPushCharacterizationReports from 'lib/records/modules/restPushCharacterizationReports'
import restCModelCharacterizationReports from 'lib/records/modules/restCModelCharacterizationReports'
import restComparisonsReport from 'lib/records/modules/restComparisonsReport'
import trustedAppsDetails from 'lib/records/modules/trustedAppsDetails'
import trustedAppsReferenceData from 'lib/records/modules/trustedAppsReferenceData'
import trustedApplications from 'lib/records/modules/trustedApplications'
import imsApplications from 'lib/records/modules/imsApplications'
import imsApplicationOrgs from 'lib/records/modules/imsApplicationOrgs'
import imsCertifications from 'lib/records/modules/imsCertifications'
import ltiConfigurations from 'lib/records/modules/ltiConfigurations'
import ltiPlatforms from 'lib/records/modules/ltiPlatforms'
import trustedAppsSharedWithOrgs from 'lib/records/modules/trustedAppsSharedWithOrgs'
import trustedAppsDashboardsSharedWithUs from 'lib/records/modules/trustedAppsDashboardsSharedWithUs'
import trustedAppsShareableOrgs from 'lib/records/modules/trustedAppsShareableOrgs'
import trustedAppsChangelog from 'lib/records/modules/trustedAppsChangelog'
import userRoles from 'lib/records/modules/userRoles'
import trustedAppsRatings from './trustedAppsRatings'

const modules = {
  [organizations.record]: organizations,
  [orgActions.record]: orgActions,
  [suppliers.record]: suppliers,
  [users.record]: users,
  [userPreferences.record]: userPreferences,
  [sources.record]: sources,
  [ltiPlatforms.record]: ltiPlatforms,
  [notifications.record]: notifications,
  [trustedAppsReferenceData.record]: trustedAppsReferenceData,
  [userRoles.record]: userRoles,

  [formats.record]: formats,
  [specifications.record]: specifications,

  [products.record]: products,
  [modeledProducts.record]: modeledProducts,

  [csvCModels.record]: csvCModels,
  [csvCModelCharacterizations.record]: csvCModelCharacterizations,
  [csvCModelCharacterizationReports.record]: csvCModelCharacterizationReports,

  [orCSVCharacterizations.record]: orCSVCharacterizations,
  [oneRosterCSVCharacterizationReports.record]:
    oneRosterCSVCharacterizationReports,

  [commonCartridgeCharacterizations.record]: commonCartridgeCharacterizations,
  [commonCartridgeCharacterizationReports.record]:
    commonCartridgeCharacterizationReports,
  [commonCartridgeCharacterizationReportItems.record]:
    commonCartridgeCharacterizationReportItems,
  [commonCartridgeCharacterizationReportStandards.record]:
    commonCartridgeCharacterizationReportStandards,
  [commonCartridgeCharacterizationReportResources.record]:
    commonCartridgeCharacterizationReportResources,

  [restCModels.record]: restCModels,
  [restCModelCharacterizations.record]: restCModelCharacterizations,
  [restCModelCharacterizationReports.record]: restCModelCharacterizationReports,

  [orRESTCharacterizations.record]: orRESTCharacterizations,
  [orRESTPushCharacterizations.record]: orRESTPushCharacterizations,
  [restCharacterizationReports.record]: restCharacterizationReports,
  [restPushCharacterizationReports.record]: restPushCharacterizationReports,

  // Comparisons
  [comparisons.record]: comparisons,
  [productComparisons.record]: productComparisons,
  // Comparison Reports
  [csvComparisonsReport.record]: csvComparisonsReport,
  [restComparisonsReport.record]: restComparisonsReport,

  // TrustEd Apps
  [trustedAppsDetails.record]: trustedAppsDetails,
  [trustedApplications.record]: trustedApplications,
  [imsApplications.record]: imsApplications,
  [imsApplicationOrgs.record]: imsApplicationOrgs,
  [imsCertifications.record]: imsCertifications,
  [ltiConfigurations.record]: ltiConfigurations,
  [trustedAppsSharedWithOrgs.record]: trustedAppsSharedWithOrgs,
  [trustedAppsDashboardsSharedWithUs.record]: trustedAppsDashboardsSharedWithUs,
  [trustedAppsShareableOrgs.record]: trustedAppsShareableOrgs,
  [trustedAppsChangelog.record]: trustedAppsChangelog,
  [trustedAppsRatings.record]: trustedAppsRatings,
}

export default modules
