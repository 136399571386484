import * as R from 'ramda'

import {
  AnySpecFormatType,
  ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
} from 'domains/formats/constants/formats'
import { postRequest } from 'lib/api/api'
import { getFullProduct } from 'domains/products/workflows/getFullProduct'
import { getProductFormatModelId } from 'domains/products/utils/productByModel'

export const copyProductModel = async (
  productId: number,
  copyModelId: number,
  format: AnySpecFormatType,
  withDemographics = false,
): Promise<false | number> => {
  const leaf =
    format === ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL
      ? 'restmodels'
      : 'csvmodels'
  const {
    data,
    success,
  } = await postRequest(
    `products/${productId}/${leaf}?copyOf=${copyModelId}${
      withDemographics ? '&withDemographics=true' : ''
    }`,
    { hello: true },
  )

  if (!success) {
    return false
  }

  const product = await getFullProduct(productId, format)
  if (format === ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL) {
    return R.pathOr(false, ['id'], data)
  }

  if (product) {
    return getProductFormatModelId(product, format)
  }

  return false
}
