import React, { PureComponent } from 'react'

import Button from 'lib/components/Button'
import Spinner from 'lib/components/Spinner'
import View from 'lib/components/View'

interface IWizardFooterButtonOptions {
  title: string
  disabled?: boolean
  variant?: string
  onClick?: any
  dataTest?: string
  type?: string
}
interface IProps {
  buttons: IWizardFooterButtonOptions[]
  pending?: boolean
}
export default class WizardFooter extends PureComponent<IProps> {
  renderButton = (options: IWizardFooterButtonOptions) => (
    <Button
      type="button"
      key={options.title}
      ml={2}
      mb={[2, 0]}
      disabled={options.disabled}
      onClick={options.onClick}
      data-test={options.dataTest}
      variant={options.disabled ? 'neutral' : options.variant}
    >
      {options.title}
    </Button>
  )

  render() {
    
    return (
      <View flexible="row-h-end-wrap" px={3} py={2}>
        {this.props.pending && <Spinner />}
        {!this.props.pending && this.props.buttons.map(this.renderButton)}
      </View>
    )
  }
}
