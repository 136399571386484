import React, { PureComponent } from 'react'
import * as R from 'ramda'

import { Text, View } from 'lib/components'
import BaseProductDetailsBreadCrumbs from 'domains/products/components/BaseProductDetailsBreadCrumbs'
import withFullProduct, {
  IWithFullProductProps,
} from 'domains/products/components/withFullProduct'
import CharacterizationsDetailsWidget from 'domains/characterizations/components/DistrictCharacterizationDetails/CharacterizationsDetailsWidget'
import trackCharacterizationAnalytics from 'domains/characterizations/utils/trackCharacterizationAnalytics'
import IAccordionHeaderState from 'domains/characterizations/models/IAccordionHeaderState'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import { ONER_V1P1_CSV_ROSTERING_IMPORT_BULK } from 'domains/formats/constants/formats'
import ITransformedCharacterizationCSVFile from 'domains/characterizations/models/ITransformedCharacterizationCSVFile'
import ReportThreeTopSection from 'domains/reports/components/ReportThreeTopSection'
import CSVCharacterizationReportOverviewWidget from 'domains/characterizations/components/CharacterizationReport/CSVCharacterizationReportOverviewWidget'
import CharacterizationDetailsActionsWidget from 'domains/characterizations/components/CharacterizationReport/CharacterizationDetailsActionsWidget'
import ReportUnavailable from 'domains/reports/components/ReportUnavailable'
import CSVCharacterizationReportFiles from 'domains/characterizations/components/CharacterizationReport/CSVCharacterizationReportFiles'

const WIDGET_HEIGHT = 536

interface IState {
  fileStates: IAccordionHeaderState[]
}

export class SupplierOR1P1CsvCharacterizationReportScreen extends PureComponent<IWithFullProductProps> {
  readonly state: IState = {
    fileStates: R.pathOr<ITransformedCharacterizationCSVFile[]>(
      [],
      ['characterizationReport', 'files'],
      this.props,
    ).map((file) => ({
      name: file.fileLabel,
      open: false,
    })),
  }

  componentDidMount() {
    trackCharacterizationAnalytics('viewed_model_characterization_report', {
      id: this.props.id,
      validationId: this.props.characterizationReport.validationId,
    })
  }

  onFileRequestChange = (name: string) =>
    this.setState(
      R.over(R.lensProp('fileStates'), (fileStates) =>
        fileStates.map((file: any) => ({
          name: file.name,
          open: file.name === name ? !file.open : file.open,
        })),
      ),
    )

  onExpandAll = () =>
    this.setState(
      R.over(
        R.lensProp('fileStates'),
        R.map((file: any) => ({ ...file, open: true })),
      ),
    )

  onCollapseAll = () =>
    this.setState(
      R.over(
        R.lensProp('fileStates'),
        R.map((file: any) => ({ ...file, open: false })),
      ),
    )

  render() {
    const {
      product,
      csvConsumerModel,
      csvConsumerCharacterization,
      characterizationReport,
      specification,
    } = this.props
    if (!csvConsumerCharacterization) {
      return (
        <View variant="screen">
          <DocumentTitle
            title={`Product Characterization - ${this.props.product.name}`}
          />
          <BaseProductDetailsBreadCrumbs
            id={product.id}
            format={R.propOr(
              ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
              'format',
              csvConsumerCharacterization,
            )}
            crumbs={[{ name: 'Characterization' }]}
          />
          <Text variant="title" my={4}>
            Characterization Report Not Found
          </Text>
        </View>
      )
    }

    return (
      <View variant="screen">
        <DocumentTitle
          title={`Product Characterization: ${this.props.product.name}`}
        />
        <BaseProductDetailsBreadCrumbs
          id={product.id}
          format={R.propOr(
            ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
            'format',
            csvConsumerCharacterization,
          )}
          crumbs={[{ name: 'Characterization' }]}
        />
        <View flexible="column">
          <ReportThreeTopSection>
            <CharacterizationsDetailsWidget
              characterization={csvConsumerCharacterization}
              product={product}
              height={WIDGET_HEIGHT}
            />
            <CSVCharacterizationReportOverviewWidget
              report={characterizationReport}
              height={WIDGET_HEIGHT}
            />
            <CharacterizationDetailsActionsWidget
              characterization={csvConsumerCharacterization}
              report={characterizationReport}
              expandAll={this.onExpandAll}
              collapseAll={this.onCollapseAll}
              product={product}
              model={csvConsumerModel}
              format={ONER_V1P1_CSV_ROSTERING_IMPORT_BULK}
              height={WIDGET_HEIGHT}
              shouldHidePrint={true}
            />
          </ReportThreeTopSection>

          <View variant="paper">
            <ReportUnavailable
              unavailable={characterizationReport.unavailable}
            />

            <CSVCharacterizationReportFiles
              report={characterizationReport}
              specification={specification}
              fileStates={this.state.fileStates}
              onFileRequestChange={this.onFileRequestChange}
              model={csvConsumerModel}
            />
          </View>
        </View>
      </View>
    )
  }
}

export default withFullProduct(SupplierOR1P1CsvCharacterizationReportScreen)
