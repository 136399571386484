import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

export const REST_CHARACTERIZATION_REPORTS_RECORD =
  'restCharacterizationReports'

const restCharacterizationReports: records.modules.IModule = {
  record: REST_CHARACTERIZATION_REPORTS_RECORD,
  noResponseDataKey: true,
  schema: new normalizr.schema.Entity(
    REST_CHARACTERIZATION_REPORTS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(['validationId']),
  ),
  api: {
    create: () => '',
    get: (id) => `conformancereports/providerreport/${id}`,
    getList: () => '',
    update: () => '',
    delete: () => '',
  },
}

export default restCharacterizationReports
