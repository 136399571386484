import * as R from 'ramda'
import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

export const USERS_RECORD = 'users'

const ensureUserData = (data: any) => ({
  user: R.compose<any, any, any, any, any, any>(
    R.assoc('contactType', R.propOr('none', 'contactType', data)),
    R.assoc('username', R.propOr('', 'email', data)),
    R.dissoc('orgId'),
    R.dissoc('confirmPassword'),
    R.dissoc('addNew'),
  )(data),
})

const users: records.modules.IModule = {
  record: USERS_RECORD,
  schema: new normalizr.schema.Entity(
    USERS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(),
  ),
  api: {
    create: (data) => `orgs/${R.propOr(0, 'orgId', data)}/users`,
    prepareCreateData: ensureUserData,
    get: (id) => `users/${id}`,
    getList: (_, orgId) => `orgs/${orgId}/users`,
    update: (id) => `users/${id}`,
    prepareUpdateData: ensureUserData,
    delete: (id) => `users/${id}`,
  },
}

export default users
