import React from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { ModalWrapper } from 'lib/components'
import { showToast } from 'lib/utils/toast'
import FormInput from 'lib/components/FormInput'
import useTrustedAppsSupplierOptions from 'domains/trustedApps/hooks/useTrustedAppsSupplierOptions'
import FormSelect from 'lib/components/modern/Select/FormSelect'
import { postRequest } from 'lib/api/api'
import FormLabel from 'lib/components/modern/FormLabel'
import countries from 'domains/application/constants/countries.json'

type FormValues = {
    applicationName: string
    applicationVersion: string
    applicationUrl: string
    applicationPrivacyUrl: string
    applicationTosUrl: string
    supplierName: string
    supplierContact: string
    supplierCountry: string
}
const validationSchema = Yup.object().shape({
    applicationName: Yup.string().required('Application name is required'),
    applicationVersion: Yup.string(),
    applicationUrl: Yup.string().url('Invalid URL').required('URL is required'),
    applicationPrivacyUrl: Yup.string()
        .url('Invalid URL')
        .required('Privacy Policy is required'),
    applicationTosUrl: Yup.string().url('Invalid URL'),
    supplierName: Yup.string().required('Supplier is required'),
    supplierContact: Yup.string().email('Supplier Contact Email must be a valid email'),
    supplierCountry: Yup.string(),
})

type Props = {
    applicationName: string
    isOpen: boolean
    onRequestClose: (saved?: boolean) => void
}

export default function RequestNewAppModal(props: Props) {
    const [
        initialSupplierOptions,
        supplierOptions,
        resetSupplierOptions,
        getSupplierOptions,
    ] = useTrustedAppsSupplierOptions(props.isOpen)

    const countryOptions = Object.values(countries).map((key) => ({
        label: key,
        value: key,
    }))


    const onSubmit = async (
        values: FormValues,
        bag: FormikHelpers<FormValues>,
    ) => {
        const { success } = await postRequest(
            'trustedapps/applicationRequests',
            {
                applicationName: values.applicationName.trimStart(),
                applicationVersion: values.applicationVersion.length
                    ? values.applicationVersion
                    : null,
                applicationUrl: values.applicationUrl,
                applicationPrivacyUrl: values.applicationPrivacyUrl,
                applicationTosUrl: values.applicationTosUrl.length
                    ? values.applicationTosUrl
                    : null,
                supplierName: values.supplierName.length
                    ? values.supplierName
                    : null,
                supplierCountry: values.supplierCountry.length
                    ? values.supplierCountry
                    : null,
                supplierContact: values.supplierContact.length
                    ? values.supplierContact
                    : null,
            },
        )
        bag.setSubmitting(false)
        if (success) {
            props.onRequestClose(true)
            showToast(
                'start',
                'Successfully requested new application for 1EdTech to review',
            )
            bag.resetForm()
        } else showToast('error', 'Failed to update app. Please try again.')
    }

    return (
        <Formik
            initialValues={{
                applicationName: props.applicationName,
                applicationVersion: '',
                applicationUrl: '',
                applicationPrivacyUrl: '',
                applicationTosUrl: '',
                supplierName: '',
                supplierContact: '',
                supplierCountry: '',
            }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
        >
            {(formProps) => {
                return (
                    <ModalWrapper
                        isOpen={props.isOpen}
                        title="Request New Application"
                        actions={[
                            {
                                text: 'Request',
                                variant: 'start',
                                extra: { type: 'submit' },
                                onClick: formProps.submitForm,
                            },
                            {
                                text: 'Cancel',
                                variant: 'neutral',
                                onClick: () => {
                                    formProps.resetForm()
                                    props.onRequestClose()
                                },
                            },
                        ]}
                        pending={formProps.isSubmitting}
                    >
                        <div className="flex flex-col space-y-3 bg-white pb-16">
                            <div>
                                <FormLabel htmlFor="note">
                                    Please Note
                                </FormLabel>
                                <p id="note" className="text-sm text-gray-900">
                                    Your request will go into a queue for
                                    1EdTech to evaluate.  Evaluation of
                                    applications will take some time for us to
                                    complete.
                                </p>
                            </div>
                            <div className="flex items-start justify-center space-x-4 w-full">
                                <div className="flex-flex-col space-y-3 w-full">
                                    <FormInput

                                        label="Application Name"
                                        name="applicationName"
                                        value={formProps.values.applicationName.trimStart()}
                                        valueDataTest="name-value"
                                        placeholder="Application Name"
                                        handleChange={formProps.handleChange}
                                        required
                                    />
                                    <FormInput
                                        label="Application Version"
                                        name="applicationVersion"
                                        value={
                                            formProps.values.applicationVersion.trimStart()
                                        }
                                        valueDataTest="version-value"
                                        placeholder="1.0.0"
                                        handleChange={formProps.handleChange}
                                    />
                                    <FormInput
                                        label="Application or Supplier URL"
                                        name="applicationUrl"
                                        value={formProps.values.applicationUrl}
                                        valueDataTest="url-value"
                                        placeholder="https://application.com"
                                        handleChange={formProps.handleChange}
                                        required
                                    />
                                    <FormInput
                                        label="Application Privacy Policy URL"
                                        name="applicationPrivacyUrl"
                                        value={
                                            formProps.values
                                                .applicationPrivacyUrl
                                        }
                                        valueDataTest="privacy-url-value"
                                        placeholder="https://application.com/privacy"
                                        handleChange={formProps.handleChange}
                                        required
                                    />
                                    <FormInput
                                        label="Application Terms of Service URL"
                                        name="applicationTosUrl"
                                        value={
                                            formProps.values.applicationTosUrl
                                        }
                                        valueDataTest="tos-url-value"
                                        placeholder="https://application.com/terms"
                                        handleChange={formProps.handleChange}
                                    />
                                </div>
                                <div className="flex-flex-col space-y-3 w-full">
                                    <FormSelect
                                        label="Supplier Name"
                                        name="supplierName"
                                        selected={formProps.values.supplierName}
                                        onChange={(value: any) => {
                                            formProps.setFieldValue(
                                                'supplierName',
                                                value || '',
                                            )
                                            resetSupplierOptions()
                                        }}
                                        placeholder="Select or add your own"
                                        options={supplierOptions}
                                        initialOptions={initialSupplierOptions}
                                        onSearchChange={getSupplierOptions}
                                        required
                                        extensible
                                    />
                                    <FormSelect
                                        label="Supplier Country"
                                        name="supplierCountry"
                                        selected={
                                            formProps.values.supplierCountry.trimStart()
                                        }
                                        onChange={(value: any) => {
                                            formProps.setFieldValue(
                                                'supplierCountry',
                                                value || '',
                                            )
                                        }}
                                        placeholder="Select country"
                                        options={countryOptions}
                                        initialOptions={countryOptions}
                                    />
                                    <FormInput
                                        label="Supplier Contact Email"
                                        name="supplierContact"
                                        value={formProps.values.supplierContact}
                                        valueDataTest="supplier-poc-value"
                                        placeholder="privacy@supplier.net"
                                        handleChange={formProps.handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalWrapper>
                )
            }}
        </Formik>
    )
}
