import React from 'react'
import { View, Icon, Text } from 'lib/components'
import { CCReportTypeIcon } from 'domains/commonCartridge/constants/commonCartridge'

interface ILegendItemProps {
  icon: string
  label: string
}

function LegendItem(props: ILegendItemProps) {
  return (
    <View flexible="row-v-center" ml={4}>
      <Icon className={props.icon} mr={2} fontSize={20} />
      <Text>{props.label}</Text>
    </View>
  )
}

export default function CCCharacterizationReportLegend() {
  return (
    <View flexible="row-h-end" mt="-20px">
      <View flexible="row-space-around-wrap" py={2} borderRadius={4}>
        <LegendItem icon={CCReportTypeIcon.Item} label="Item" />
        <LegendItem icon={CCReportTypeIcon.Standard} label="Standard" />
        <LegendItem icon={CCReportTypeIcon.Resource} label="Resource" />
        <LegendItem icon={CCReportTypeIcon.Metadata} label="Metadata" />
      </View>
    </View>
  )
}
