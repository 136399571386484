import React from 'react'

import { IIMSApplicationFilter } from 'domains/trustedApps/components/AddTrustedApps/IMSApplicationsFilter/IIMSApplicationsFilter'
import IMSApplicationsFilterGroup from 'domains/trustedApps/components/AddTrustedApps/IMSApplicationsFilter/IMSApplicationsFilterGroup'

interface IProps {
  filters: string[]
  onFilterChecked: (
    checked: IIMSApplicationFilter | IIMSApplicationFilter[],
  ) => any
}

export default function IMSApplicationsGeneralFilters(props: IProps) {
  const filters: IIMSApplicationFilter[] = [
    {
      label: 'Vetted',
      filterKey: 'vetted',
      value: true,
    },
    {
      label: 'Certified Product',
      filterKey: 'certified',
      value: true,
    },
    {
      label: 'Non-Certified Product',
      filterKey: 'certified',
      value: false,
    },
    {
      label: 'TrustEd Apps Seal',
      filterKey: 'sealed',
      value: true,
    },
  ]

  const certifiedSelected = props.filters.includes('certified=true')
  const nonCertifiedSelected = props.filters.includes('certified=false')
  if (certifiedSelected) {
    filters.splice(2, 1)
  } else if (nonCertifiedSelected) {
    filters.splice(1, 1)
  }

  return (
    <IMSApplicationsFilterGroup
      title="General Filters"
      filters={filters}
      appliedFilters={props.filters}
      onFilterChecked={props.onFilterChecked}
    />
  )
}
