import React from 'react'
import clsx from 'clsx'

import { ToolTip } from 'lib/components'

type CCxCertifiedBadgeProps = {
  showTooltip?: boolean
  className?: any
}

export default function CCxCertifiedBadge(props: CCxCertifiedBadgeProps) {
  const img = (
    <img
      alt="Compatibility Check Certified Badge"
      src="/images/ccxCertifiedBadge.svg"
      className={clsx('w-6 h-6', props.className)}
    />
  )

  if (props.showTooltip) {
    return (
      <ToolTip
        delay={200}
        tip={
          <div className="h-44 flex flex-row items-center">
            <img
              alt="Compatibility Check Certified Badge"
              src="/images/ccxCertifiedBadge.svg"
              className="w-auto h-full"
              width="auto"
              height="100%"
            />
          </div>
        }
      >
        {img}
      </ToolTip>
    )
  }

  return img
}
