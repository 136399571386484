import React from 'react'
import clsx from 'clsx'

import SpecificationDescription from 'domains/modeling/components/SpecificationDescription'
import { ITransformedRESTServiceEndpointCharacterizationReport } from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'

const isPrint = isOnPrintScreen()

interface IProps {
  type?: string
  description?: string
  endpoint?: ITransformedRESTServiceEndpointCharacterizationReport
  attributeName: string
}

export default function CriticalErrorSpecDetails(props: IProps) {
  const endpointName = props.endpoint ? props.endpoint.endpointName : 'unknown'
  return (
    <div className={clsx('flex flex-row text-text', { 'mt-4': !isPrint })}>
      <div className="w-24">
        {isPrint && <p className="font-bold">Name: </p>}
        <p className="font-bold">Type: </p>
        <p className="font-bold mt-2">Description: </p>
      </div>
      <div className="flex flex-col flex-1">
        {isPrint && <p>{props.attributeName}</p>}
        <p
          data-test={`${endpointName}-attribute-error-${props.attributeName}-type`}
        >
          {props.type || 'N/A'}
        </p>
        <p
          className="my-2"
          data-test={`${endpointName}-attribute-error-${props.attributeName}-description`}
        >
          <SpecificationDescription description={props.description || 'N/A'} />
        </p>
      </div>
    </div>
  )
}
