import React from 'react'
import * as R from 'ramda'
import { useHistory } from 'react-router-dom'

import { ICommonCartridgeStandard } from 'domains/commonCartridge/models/ICommonCartridgeStandard'
import { getCharacterizationRoute } from 'domains/characterizations/navigation/routes'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import CCCharacterizationReportRow from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportRow'
import { Text, Span } from 'lib/components'
import {
  CCReportTypeIcon,
  CC_TYPE_STANDARD,
} from 'domains/commonCartridge/constants/commonCartridge'
import useCCCharacterizationReportCrumbs, {
  ICCCrumb,
} from 'domains/commonCartridge/hooks/useCCCharacterizationReportCrumbs'

export const CC_STANDARD_GUID_NOT_SPECIFIED = '(No guid specified)'

interface IProps {
  characterization: ICharacterization
  standard: ICommonCartridgeStandard
}

export default function CCCharacterizationReportStandardRow(props: IProps) {
  const history = useHistory()
  const [crumbs] = useCCCharacterizationReportCrumbs()

  const guid = props.standard.guid || CC_STANDARD_GUID_NOT_SPECIFIED

  const onGoToStandard = () => {
    const route = getCharacterizationRoute(
      props.characterization.id,
      props.characterization.format,
      ['standards', `${props.standard.id}`],
    )
    history.push(route, {
      crumbs: [
        ...crumbs,
        {
          id: props.standard.id,
          type: CC_TYPE_STANDARD,
          title: guid,
        } as ICCCrumb,
      ],
    })
  }

  const id = R.pathOr(0, ['id'], props.standard)
  const resourceCount = R.pathOr(0, ['resourceCount'], props.standard)
  return (
    <CCCharacterizationReportRow
      type="Standard"
      id={id}
      bg="ccReportStandardBG"
      icon={CCReportTypeIcon.Standard}
      title={guid}
      subtitle={props.standard.value || ''}
      onClick={onGoToStandard}
      rightContent={
        <Text variant="caption" mr={2}>
          Resources: <Span fontWeight={700}>{resourceCount}</Span>
        </Text>
      }
    />
  )
}
