import React from 'react'
import clsx from 'clsx'

import {
  ReportStatus,
  STATUS_TO_TEXT_COLOR_MAP,
} from 'domains/reports/models/IReportStatuses'

type CharacterizationSummaryHeaderProps = {
  title: string
  status: ReportStatus
  isSubtitle?: boolean
}

export default function CharacterizationSummaryHeader(
  props: CharacterizationSummaryHeaderProps,
) {
  return (
    <p
      className={clsx(
        'font-bold',
        {
          'text-md': !props.isSubtitle,
          'text-base': props.isSubtitle,
        },
        STATUS_TO_TEXT_COLOR_MAP[props.status],
      )}
    >
      {props.title}
    </p>
  )
}
