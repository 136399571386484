import React from 'react'
import * as R from 'ramda'

import { ICommonCartridgeLOM } from 'domains/commonCartridge/models/lom/ICommonCartridgeLOM'
import useAccordionHeaderState from 'domains/reports/hooks/useAccordionHeaderState'
import { ExpansionPanel, View } from 'lib/components'
import CCCharacterizationReportLOMGeneral from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/CCCharacterizationReportLOMGeneral'
import CCCharacterizationReportLOMLifecycle from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/CCCharacterizationReportLOMLifecycle'
import CCCharacterizationReportLOMMetaMetadata from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/CCCharacterizationReportLOMMetaMetadata'
import CCCharacterizationReportLOMTechnical from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/CCCharacterizationReportLOMTechnical'
import CCCharacterizationReportLOMAnnotation from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/CCCharacterizationReportLOMAnnotation'
import CCCharacterizationReportLOMRelation from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/CCCharacterizationReportLOMRelation'
import CCCharacterizationReportLOMEducational from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/CCCharacterizationReportLOMEducational'
import CCCharacterizationReportLOMKeyDropdowns from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMKeyDropdowns'
import CCCharacterizationReportLOMRights from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/CCCharacterizationReportLOMRights'
import CCCharacterizationReportLOMClassification from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/CCCharacterizationReportLOMClassification'
import { utils } from '@ims/1edtech-frontend-common'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import BlankSlate from 'lib/components/BlankSlate'
import CCCharacterizationReportRow from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportRow'
import {
  CCReportTypeIcon,
  CC_TYPES,
} from 'domains/commonCartridge/constants/commonCartridge'
import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'
import CCCharacterizationReportShowMissingMDSwitch from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportShowMissingMDSwitch'

const noopComponent = () => null

const KEY_IS_MANY = {
  general: false,
  lifecycle: false,
  metaMetadata: false,
  technical: false,
  educational: true,
  relation: true,
  annotation: true,
  classification: true,
  rights: false,
}

const KEY_TO_COMPONENT_MAP = {
  general: CCCharacterizationReportLOMGeneral,
  lifecycle: CCCharacterizationReportLOMLifecycle,
  metaMetadata: CCCharacterizationReportLOMMetaMetadata,
  technical: CCCharacterizationReportLOMTechnical,
  educational: CCCharacterizationReportLOMEducational,
  relation: CCCharacterizationReportLOMRelation,
  annotation: CCCharacterizationReportLOMAnnotation,
  classification: CCCharacterizationReportLOMClassification,
  rights: CCCharacterizationReportLOMRights,
}

interface IProps {
  lom?: ICommonCartridgeLOM
  title?: string
  noBlankslate?: boolean
  parentId: number
  type: CC_TYPES
}

export default function CCCharacterizationReportLOMDropdowns(props: IProps) {
  const { showMissingMetadata, disclosures } = React.useContext(
    ShowMissingMetadataContext,
  )

  const lomKeys = (
    showMissingMetadata
      ? (R.keys(KEY_TO_COMPONENT_MAP) as string[])
      : R.keys(props.lom).filter((key) =>
          utils.hasValue(R.pathOr(null, [key], props.lom)),
        )
  ).map((name: any) => ({ name }))

  const [headerStates, , onToggleHeader] = useAccordionHeaderState(
    lomKeys,
    undefined,
    undefined,
    props.parentId,
  )
  if (!props.lom) {
    return null
  }

  const headers = headerStates.filter(
    (h) =>
      showMissingMetadata || utils.hasValue(R.prop(h.name as any, props.lom!)),
  )

  const onToggleKey = (key: string) => () => onToggleHeader(key)

  const dropdowns = (
    <View>
      {!utils.hasValue(props.lom) && !props.noBlankslate && (
        <BlankSlate title="No Metadata" />
      )}
      {headers.map((headerState, index) => {
        const isDropdown = R.pathOr(false, [headerState.name], KEY_IS_MANY)
        const Component = R.pathOr(
          noopComponent,
          [headerState.name],
          KEY_TO_COMPONENT_MAP,
        ) as any

        const data = R.pathOr(
          isDropdown ? [] : {},
          [headerState.name],
          props.lom,
        ) as any
        const display = isDropdown ? (
          <CCCharacterizationReportLOMKeyDropdowns
            title={headerState.name}
            Component={Component}
            data={data}
          />
        ) : (
          <Component
            title={headerState.name}
            data={data}
            path={`lom.${headerState.name}`}
          />
        )
        return (
          <View
            mt={2}
            key={`${props.type}-${props.parentId}-${headerState.name}-${index}`}
          >
            <ExpansionPanel
              isOpen={headerState.open}
              onRequestChange={onToggleKey(headerState.name)}
              summary={
                <CCCharacterizationReportRow
                  type="Metadata"
                  id={headerState.name}
                  bg="ccReportMetadata"
                  title={headerState.name}
                  icon={CCReportTypeIcon.Metadata}
                  isExpanded={headerState.open}
                  isMissing={R.contains(`lom.${headerState.name}`, disclosures)}
                />
              }
              details={
                <View p={2} pl={3}>
                  {display}
                </View>
              }
            />
          </View>
        )
      })}
    </View>
  )

  if (utils.hasValue(props.title)) {
    return (
      <View variant="paper">
        <View flexible="row-space-between" mb={3}>
          <WidgetHeader title={props.title!} />
          <CCCharacterizationReportShowMissingMDSwitch />
        </View>

        {dropdowns}
      </View>
    )
  }

  return dropdowns
}
