import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { records } from '@ims/1edtech-frontend-common'

import ModelFileColumnEditorModal from 'domains/modeling/modals/AddEditModelFileColumnModal/ModelFileColumnEditorModal'
import { modelFileColumnSelector } from 'domains/modeling/selectors/csvCModelToSpec'
import { CSV_C_MODELS_RECORD } from 'lib/records/modules/csvCModels'
import { ICSVConsumerFileColumn } from 'domains/modeling/models/ICSVConsumerModel'
import { RootState } from 'lib/store/rootReducer'

interface IPropsFromState {
  column: ICSVConsumerFileColumn | null
}
const stateMap = createStructuredSelector<RootState, any, IPropsFromState>({
  column: modelFileColumnSelector(
    records.entitiesSelectors.entityByIdSelector(
      CSV_C_MODELS_RECORD,
      'modelId',
    ),
    'fileName',
    'header',
  ),
})

interface IProps {
  isOpen: boolean
  closeModal: (wasSaved: boolean, wasCleared: boolean) => any
  modelId: string | number
  fileName: string
}

export class EditModelFileColumnModal extends PureComponent<
  IProps & IPropsFromState
> {
  render() {
    return (
      <ModelFileColumnEditorModal {...this.props} column={this.props.column!} />
    )
  }
}

export default connect(stateMap)(EditModelFileColumnModal)
