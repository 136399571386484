import React from 'react'

import ICharacterization from 'domains/characterizations/models/ICharacterization'
import { View, Text, Icon } from 'lib/components'
import {
  STATUS_TO_BADGE_ICON_MAP,
  STATUS_TO_BADGE_VARIANT_MAP,
  GREEN_REPORT_STATUS,
  RED_REPORT_STATUS,
  AMBER_REPORT_STATUS,
  INDETERMINATE_REPORT_STATUS,
} from 'domains/reports/models/IReportStatuses'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'

const STATUS_TO_RESULT: { [key: string]: string } = {
  [GREEN_REPORT_STATUS]: 'This is a conformant @@ file',
  [AMBER_REPORT_STATUS]: 'This @@ file has warnings',
  [RED_REPORT_STATUS]: 'This @@ file is non-conformant',
  [INDETERMINATE_REPORT_STATUS]: 'This @@ file\'s results were indeterminate',
}

interface IProps {
  characterization: ICharacterization
  height: number
}

export default function CCCharcterizationReportStatusWidget(props: IProps) {
  return (
    <DetailsTopWidget
      title="Cartridge Status"
      dataTest="cartridge-status-widget"
      height={props.height}
    >
      <View flexible="column-center" p={3}>
        <Icon
          my={4}
          color={
            STATUS_TO_BADGE_VARIANT_MAP[`${props.characterization.status}`]
          }
          className={
            STATUS_TO_BADGE_ICON_MAP[`${props.characterization.status}`]
          }
          fontSize={100}
        />
        <Text textAlign="center" data-test="cartridge-status-result">
          {`${STATUS_TO_RESULT[`${props.characterization.status}`]}`.replace(
            '@@',
            props.characterization.format,
          )}
        </Text>
      </View>
    </DetailsTopWidget>
  )
}
