import useFormatScreen from 'lib/hooks/useFormatScreen'
import {
  ONER_V1P1_CSV_ROSTERING_EXPORT_BULK,
  ONER_V1P2_CSV_ROSTERING_EXPORT_BULK,
  ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
  ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL,
  ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
} from 'domains/formats/constants/formats'
import OR1P1CSVComparisonReportScreen from 'domains/comparisons/screens/OR1P1CSVComparisonReportScreen'
import OR1P1RESTComparisonReportScreen from 'domains/comparisons/screens/OR1P1RESTComparisonReportScreen'

export default function ComparisonReportScreen(props: any) {
  return useFormatScreen({
    ...props,
    map: {
      [ONER_V1P1_CSV_ROSTERING_EXPORT_BULK]: OR1P1CSVComparisonReportScreen,
      [ONER_V1P2_CSV_ROSTERING_EXPORT_BULK]: OR1P1CSVComparisonReportScreen,
      [ONER_V1P1_CSV_ROSTERING_IMPORT_BULK]: OR1P1CSVComparisonReportScreen,
      [ONER_V1P1_REST_PROVIDER_ROSTERING_OPTIONAL]: OR1P1RESTComparisonReportScreen,
      [ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL]: OR1P1RESTComparisonReportScreen,
    },
  })
}
