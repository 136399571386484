import React from 'react'

import IRestCModelServiceEndpointAttribute from 'domains/modeling/models/IRestCModelServiceEndpointAttribute'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import IRestCModelService from 'domains/modeling/models/IRestCModelService'
import IRestCModelServiceEndpoint from 'domains/modeling/models/IRestCModelServiceEndpoint'
import { Icon } from 'lib/components'
import useRestCSpecServiceEndpointAttributeCell from 'domains/modeling/hooks/useRestCSpecServiceEndpointAttributeCell'
import CreateEditModelMetadataModal from 'domains/modeling/modals/CreateEditModelMetadataModal'
import { OR_1P1_REST_ROSTERING_CONSUMER_SERVICES } from 'domains/modeling/constants/restCOneRoster1p1'
import { useSelector } from 'react-redux'
import { RootState } from 'lib/store/rootReducer'
import { myOrganizationPropSelector } from 'domains/authentication/selectors/organizations'

interface IProps extends IRestCModelServiceEndpointAttribute {
  specification: IRestCSpec
  service: IRestCModelService
  endpoint: IRestCModelServiceEndpoint
  endpointIndex: number
  setFieldValue: any
}

export default function RestCAttributeEditMetadataCell(props: IProps) {
  const { metadata } = props

  const orgName = useSelector((s: RootState) =>
    myOrganizationPropSelector('name', '')(s, props),
  )

  const [, , attributeFormPath] =
    useRestCSpecServiceEndpointAttributeCell(props)
  const [open, setOpen] = React.useState(false)

  if (!metadata) {
    return null
  }

  const onEditMetadata = () => setOpen(true)
  const onCloseMetadata = () => setOpen(false)
  const onSaveMetadata = (
    updatedMetadata: IRestCModelServiceEndpointAttribute,
  ) => {
    props.setFieldValue(`${attributeFormPath}`, updatedMetadata)
  }

  return (
    <>
      <Icon
        data-test={`edit-metadata-${props.name}`}
        className="fas fa-edit"
        onClick={onEditMetadata}
        cursor="pointer"
      />
      {open && (
        <CreateEditModelMetadataModal
          isOpen={open}
          close={onCloseMetadata}
          onSave={onSaveMetadata}
          metadata={props}
          isCreate={false}
          serviceName={
            props.service.name as OR_1P1_REST_ROSTERING_CONSUMER_SERVICES
          }
          orgName={orgName}
        />
      )}
    </>
  )
}
