import { OR1P1Subsets } from 'domains/specifications/constants/oneRoster1p1FileSets'

export enum OR_1P1_REST_ROSTERING_CONSUMER_SERVICES {
  AcademicSessions = 'Academic Sessions',
  GradingPeriods = 'Grading Periods',
  Terms = 'Terms',
  Classes = 'Classes',
  Courses = 'Courses',
  Demographics = 'Demographics',
  Enrollments = 'Enrollments',
  Orgs = 'Orgs',
  Schools = 'Schools',
  Users = 'Users',
  Teachers = 'Teachers',
  Students = 'Students',
}

export enum OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES {
  LineItems = 'Line Items',
  LineItemCategories = 'Line Item Categories',
  Results = 'Results',
}

export enum OR_1P1_REST_RESOURCES_CONSUMER_SERVICES {
  Resources = 'Resources',
}

export const CLASS_ROSTERS_ENDPOINT_NAMES = [
  'getAllAcademicSessions',
  'getAcademicSession',
  'getAllClasses',
  'getClass',
  'getAllCourses',
  'getCourse',
  'getAllGradingPeriods',
  'getGradingPeriod',
  'getAllEnrollments',
  'getEnrollment',
  'getAllOrgs',
  'getOrg',
  'getAllSchools',
  'getSchool',
  'getAllStudents',
  'getStudent',
  'getAllTeachers',
  'getTeacher',
  'getAllTerms',
  'getTerm',
  'getAllUsers',
  'getUser',
  'getCoursesForSchool',
  'getEnrollmentsForClassInSchool',
  'getStudentsForClassInSchool',
  'getTeachersForClassInSchool',
  'getEnrollmentsForSchool',
  'getStudentsForSchool',
  'getTeachersForSchool',
  'getTermsForSchool',
  'getClassesForTerm',
  'getGradingPeriodsForTerm',
  'getClassesForCourse',
  'getClassesForStudent',
  'getClassesForTeacher',
  'getClassesForSchool',
  'getClassesForUser',
  'getStudentsForClass',
  'getTeachersForClass',
]
export const CLASS_ROSTERS_W_DEMOGRAPHICS_ENDPOINT_NAMES = [
  ...CLASS_ROSTERS_ENDPOINT_NAMES,
  'getAllDemographics',
  'getDemographics',
]

export const ALL_CLASS_ROSTERS_ENDPOINT_NAMES = [
  ...CLASS_ROSTERS_ENDPOINT_NAMES,
  ...CLASS_ROSTERS_W_DEMOGRAPHICS_ENDPOINT_NAMES,
]

export const GRADEBOOK_ENDPOINT_NAMES = [
  'getAllLineItems',
  'getLineItem',
  'deleteLineItem',
  'putLineItem',
  'getLineItemsForClass',
  'getAllCategories',
  'getCategory',
  'deleteCategory',
  'putCategory',
  'getAllResults',
  'getResult',
  'deleteResult',
  'putResult',
  'getResultsForClass',
  'getResultsForLineItemForClass',
  'getResultsForStudentForClass',
]

export const RESOURCES_ENDPOINT_NAMES = [
  'getAllResources',
  'getResource',
  'getResourcesForCourse',
  'getResourcesForClass',
]

export const SUBSET_TO_ENDPOINTS_MAP: { [key: string]: string[] } = {
  [OR1P1Subsets.CLASS_ROSTERS_SUBSET]: CLASS_ROSTERS_ENDPOINT_NAMES,
  [OR1P1Subsets.CLASS_ROSTERS_W_DEMOGRAPHICS_SUBSET]:
    CLASS_ROSTERS_W_DEMOGRAPHICS_ENDPOINT_NAMES,
  [OR1P1Subsets.GRADEBOOK]: GRADEBOOK_ENDPOINT_NAMES,
  [OR1P1Subsets.RESOURCES]: RESOURCES_ENDPOINT_NAMES,
}

export const ROSTERING_SERVICE_GROUPINGS_MAP = [
  [
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.AcademicSessions,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.GradingPeriods,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Terms,
  ],
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Classes],
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Courses],
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Demographics],
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Enrollments],
  [
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Orgs,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Schools,
  ],
  [
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Users,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Teachers,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Students,
  ],
]

export const GRADEBOOK_SERVICE_GROUPINGS_MAP = [
  [OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.LineItems],
  [OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.LineItemCategories],
  [OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.Results],
]

export const RESOURCES_SERVICE_GROUPINGS_MAP = [
  [OR_1P1_REST_RESOURCES_CONSUMER_SERVICES.Resources],
]

export const SUBSET_TO_SERVICE_NAMES_MAP: { [key: string]: string[] } = {
  [OR1P1Subsets.CLASS_ROSTERS_SUBSET]: [
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.AcademicSessions,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.GradingPeriods,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Terms,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Classes,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Courses,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Enrollments,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Orgs,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Schools,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Users,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Teachers,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Students,
  ],
  [OR1P1Subsets.CLASS_ROSTERS_W_DEMOGRAPHICS_SUBSET]: [
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.AcademicSessions,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.GradingPeriods,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Terms,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Classes,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Courses,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Demographics,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Enrollments,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Orgs,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Schools,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Users,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Teachers,
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Students,
  ],
  [OR1P1Subsets.GRADEBOOK]: [
    OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.LineItems,
    OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.LineItemCategories,
    OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.Results,
  ],
  [OR1P1Subsets.RESOURCES]: [OR_1P1_REST_RESOURCES_CONSUMER_SERVICES.Resources],
}

export const ACADEMIC_SESSION_SERVICE_PARENT_ENDPOINT = 'getAllAcademicSessions'
export const ACADEMIC_SESSION_SERVICE_ENDPOINTS = [
  'getAllAcademicSessions',
  'getAcademicSession',
]

export const GRADING_PERDIODS_SERVICE_PARENT_ENDPOINT = 'getAllGradingPeriods'
export const GRADING_PERDIODS_SERVICE_ENDPOINTS = [
  'getAllGradingPeriods',
  'getGradingPeriod',
  'getGradingPeriodsForTerm',
]

export const TERMS_SERVICE_PARENT_ENDPOINT = 'getAllTerms'
export const TERMS_SERVICE_ENDPOINTS = [
  'getAllTerms',
  'getTerm',
  'getTermsForSchool',
]

export const CLASSES_SERVICE_PARENT_ENDPOINT = 'getAllClasses'
export const CLASSES_SERVICE_ENDPOINTS = [
  'getAllClasses',
  'getClass',
  'getClassesForTerm',
  'getClassesForCourse',
  'getClassesForStudent',
  'getClassesForTeacher',
  'getClassesForSchool',
  'getClassesForUser',
]

export const COURSES_SERVICE_PARENT_ENDPOINT = 'getAllCourses'
export const COURSES_SERVICE_ENDPOINTS = [
  'getAllCourses',
  'getCourse',
  'getCoursesForSchool',
]

export const DEMOGRAPHICS_SERVICE_PARENT_ENDPOINT = 'getAllDemographics'
export const DEMOGRAPHICS_SERVICE_ENDPOINTS = [
  'getAllDemographics',
  'getDemographics',
]

export const ENROLLMENTS_SERVICE_PARENT_ENDPOINT = 'getAllEnrollments'
export const ENROLLMENTS_SERVICE_ENDPOINTS = [
  'getAllEnrollments',
  'getEnrollment',
  'getEnrollmentsForClassInSchool',
  'getEnrollmentsForSchool',
]

export const ORGS_SERVICE_PARENT_ENDPOINT = 'getAllOrgs'
export const ORGS_SERVICE_ENDPOINTS = ['getAllOrgs', 'getOrg']

export const SCHOOLS_SERVICE_PARENT_ENDPOINT = 'getAllSchools'
export const SCHOOLS_SERVICE_ENDPOINTS = ['getAllSchools', 'getSchool']

export const USERS_SERVICE_PARENT_ENDPOINT = 'getAllUsers'
export const USERS_SERVICE_ENDPOINTS = ['getAllUsers', 'getUser']

export const TEACHERS_SERVICE_PARENT_ENDPOINT = 'getAllTeachers'
export const TEACHERS_SERVICE_ENDPOINTS = [
  'getAllTeachers',
  'getTeacher',
  'getTeachersForSchool',
  'getTeachersForClass',
  'getTeachersForClassInSchool',
]

export const STUDENTS_SERVICE_PARENT_ENDPOINT = 'getAllStudents'
export const STUDENTS_SERVICE_ENDPOINTS = [
  'getAllStudents',
  'getStudent',
  'getStudentsForSchool',
  'getStudentsForClass',
  'getStudentsForClassInSchool',
]

// GRADEBOOK
export const LINE_ITEMS_SERVICE_PARENT_ENDPOINT = 'getAllLineItems'
export const LINE_ITEMS_SERVICE_ENDPOINTS = [
  'getAllLineItems',
  'getLineItem',
  'deleteLineItem',
  'putLineItem',
  'getLineItemsForClass',
]
export const LINE_ITEM_CATEGORIES_SERVICE_PARENT_ENDPOINT = 'getAllCategories'
export const LINE_ITEM_CATEGORIES_SERVICE_ENDPOINTS = [
  'getAllCategories',
  'getCategory',
  'deleteCategory',
  'putCategory',
]
export const RESULTS_SERVICE_PARENT_ENDPOINT = 'getAllResults'
export const RESULTS_SERVICE_ENDPOINTS = [
  'getAllResults',
  'getResult',
  'deleteResult',
  'putResult',
  'getResultsForClass',
  'getResultsForLineItemForClass',
  'getResultsForStudentForClass',
]

export const RESOURCES_SERVICE_PARENT_ENDPOINT = 'getAllResources'
export const RESOURCES_SERVICE_ENDPOINTS = [
  'getAllResources',
  'getResource',
  'getResourcesForCourse',
  'getResourcesForClass',
]

export const SERVICE_ENDPOINTS_MAP: { [key: string]: string[] } = {
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.AcademicSessions]:
    ACADEMIC_SESSION_SERVICE_ENDPOINTS,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.GradingPeriods]:
    GRADING_PERDIODS_SERVICE_ENDPOINTS,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Terms]: TERMS_SERVICE_ENDPOINTS,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Classes]: CLASSES_SERVICE_ENDPOINTS,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Courses]: COURSES_SERVICE_ENDPOINTS,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Demographics]:
    DEMOGRAPHICS_SERVICE_ENDPOINTS,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Enrollments]:
    ENROLLMENTS_SERVICE_ENDPOINTS,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Orgs]: ORGS_SERVICE_ENDPOINTS,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Schools]: SCHOOLS_SERVICE_ENDPOINTS,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Users]: USERS_SERVICE_ENDPOINTS,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Teachers]:
    TEACHERS_SERVICE_ENDPOINTS,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Students]:
    STUDENTS_SERVICE_ENDPOINTS,

  [OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.LineItems]:
    LINE_ITEMS_SERVICE_ENDPOINTS,
  [OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.LineItemCategories]:
    LINE_ITEM_CATEGORIES_SERVICE_ENDPOINTS,
  [OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.Results]: RESULTS_SERVICE_ENDPOINTS,

  [OR_1P1_REST_RESOURCES_CONSUMER_SERVICES.Resources]:
    RESOURCES_SERVICE_ENDPOINTS,
}

export const SERVICE_PARENT_ENDPOINT_MAP: { [key: string]: string } = {
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.AcademicSessions]:
    ACADEMIC_SESSION_SERVICE_PARENT_ENDPOINT,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.GradingPeriods]:
    GRADING_PERDIODS_SERVICE_PARENT_ENDPOINT,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Terms]:
    TERMS_SERVICE_PARENT_ENDPOINT,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Classes]:
    CLASSES_SERVICE_PARENT_ENDPOINT,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Courses]:
    COURSES_SERVICE_PARENT_ENDPOINT,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Demographics]:
    DEMOGRAPHICS_SERVICE_PARENT_ENDPOINT,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Enrollments]:
    ENROLLMENTS_SERVICE_PARENT_ENDPOINT,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Orgs]: ORGS_SERVICE_PARENT_ENDPOINT,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Schools]:
    SCHOOLS_SERVICE_PARENT_ENDPOINT,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Users]:
    USERS_SERVICE_PARENT_ENDPOINT,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Teachers]:
    TEACHERS_SERVICE_PARENT_ENDPOINT,
  [OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Students]:
    STUDENTS_SERVICE_PARENT_ENDPOINT,

  [OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.LineItems]:
    LINE_ITEMS_SERVICE_PARENT_ENDPOINT,
  [OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.LineItemCategories]:
    LINE_ITEM_CATEGORIES_SERVICE_PARENT_ENDPOINT,
  [OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.Results]:
    RESULTS_SERVICE_PARENT_ENDPOINT,

  [OR_1P1_REST_RESOURCES_CONSUMER_SERVICES.Resources]:
    RESOURCES_SERVICE_PARENT_ENDPOINT,
}

export const PARENT_ENDPOINT_TO_SERVICE_MAP: { [key: string]: string } = {
  [ACADEMIC_SESSION_SERVICE_PARENT_ENDPOINT]:
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.AcademicSessions,
  [GRADING_PERDIODS_SERVICE_PARENT_ENDPOINT]:
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.GradingPeriods,
  [TERMS_SERVICE_PARENT_ENDPOINT]:
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Terms,
  [CLASSES_SERVICE_PARENT_ENDPOINT]:
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Classes,
  [COURSES_SERVICE_PARENT_ENDPOINT]:
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Courses,
  [DEMOGRAPHICS_SERVICE_PARENT_ENDPOINT]:
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Demographics,
  [ENROLLMENTS_SERVICE_PARENT_ENDPOINT]:
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Enrollments,
  [ORGS_SERVICE_PARENT_ENDPOINT]: OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Orgs,
  [SCHOOLS_SERVICE_PARENT_ENDPOINT]:
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Schools,
  [USERS_SERVICE_PARENT_ENDPOINT]:
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Users,
  [TEACHERS_SERVICE_PARENT_ENDPOINT]:
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Teachers,
  [STUDENTS_SERVICE_PARENT_ENDPOINT]:
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Students,

  [LINE_ITEMS_SERVICE_PARENT_ENDPOINT]:
    OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.LineItems,
  [LINE_ITEM_CATEGORIES_SERVICE_PARENT_ENDPOINT]:
    OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.LineItemCategories,
  [RESULTS_SERVICE_PARENT_ENDPOINT]:
    OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.Results,

  [RESOURCES_SERVICE_PARENT_ENDPOINT]:
    OR_1P1_REST_RESOURCES_CONSUMER_SERVICES.Resources,
}

export const ENDPOINT_NAME_TO_FRIENDLY_NAME: { [key: string]: string } = {
  getAllAcademicSessions: 'Academic Sessions',
  getAcademicSession: 'Academic Session by Id',
  getAllClasses: 'Classes',
  getClass: 'Class by Id',
  getAllCourses: 'Courses',
  getCourse: 'Course by Id',
  getAllGradingPeriods: 'Grading Periods',
  getGradingPeriod: 'Grading Period by Id',
  getAllEnrollments: 'Enrollments',
  getEnrollment: 'Enrollment by Id',
  getAllDemographics: 'Demographics',
  getDemographics: 'Demographics by Id',
  getAllOrgs: 'Organizations',
  getOrg: 'Organization by Id',
  getAllSchools: 'Schools',
  getSchool: 'School by Id',
  getAllStudents: 'Students',
  getStudent: 'Students by Id',
  getAllTeachers: 'Teachers',
  getTeacher: 'Teachers by Id',
  getAllTerms: 'Terms',
  getTerm: 'Term by Id',
  getAllUsers: 'Users',
  getUser: 'User by Id',
  getCoursesForSchool: 'Courses for School',
  getEnrollmentsForClassInSchool: 'Enrollments for Class in School',
  getStudentsForClassInSchool: 'Students for Class in School',
  getTeachersForClassInSchool: 'Teachers for Class in School',
  getEnrollmentsForSchool: 'Enrollments for School',
  getStudentsForSchool: 'Students for School',
  getTeachersForSchool: 'Teachers for School',
  getTermsForSchool: 'Terms for School',
  getClassesForTerm: 'Classes for Term',
  getGradingPeriodsForTerm: 'Grading Periods for Term',
  getClassesForCourse: 'Classes for Course',
  getClassesForStudent: 'Classes for Student',
  getClassesForTeacher: 'Classes for Teacher',
  getClassesForSchool: 'Classes for School',
  getClassesForUser: 'Classes for User',
  getStudentsForClass: 'Students for Class',
  getTeachersForClass: 'Teachers for Class',

  getAllLineItems: 'Line Items',
  getLineItem: 'Line Item by Id',
  deleteLineItem: 'Delete Line Item',
  putLineItem: 'Update Line Item',
  getLineItemsForClass: 'Line Items for Class',
  getAllCategories: 'Line Item Categories',
  getCategory: 'Line Item Category by Id',
  deleteCategory: 'Delete Line Item Category',
  putCategory: 'Update Line Item Category',
  getAllResults: 'Results',
  getResult: 'Results by Id',
  deleteResult: 'Delete Result',
  putResult: 'Update Result',
  getResultsForClass: 'Results for Class',
  getResultsForLineItemForClass: 'Results for Line Item for Class',
  getResultsForStudentForClass: 'Results for Student for Class',

  getAllResources: 'Resources',
  getResource: 'Resource by Id',
  getResourcesForCourse: 'Resources for Course',
  getResourcesForClass: 'Resources for Class',
}

export const ENDPOINT_FRIENDLY_TO_SHORT_NAME: { [key: string]: string } = {
  'Academic Sessions': 'getAllAcademicSessions',
  'Academic Session by Id': 'getAcademicSession',
  Classes: 'getAllClasses',
  'Class by Id': 'getClass',
  Courses: 'getAllCourses',
  'Course by Id': 'getCourse',
  'Courses by Id': 'getCourse',
  'Grading Periods': 'getAllGradingPeriods',
  'Grading Period by Id': 'getGradingPeriod',
  Enrollments: 'getAllEnrollments',
  'Enrollment by Id': 'getEnrollment',
  Demographics: 'getAllDemographics',
  'Demographics by Id': 'getDemographics',
  Organizations: 'getAllOrgs',
  'Organization by Id': 'getOrg',
  Schools: 'getAllSchools',
  'School by Id': 'getSchool',
  Students: 'getAllStudents',
  'Student by Id': 'getStudent',
  Teachers: 'getAllTeachers',
  'Teacher by Id': 'getTeacher',
  Terms: 'getAllTerms',
  'Term by Id': 'getTerm',
  Users: 'getAllUsers',
  'User by Id': 'getUser',
  'Courses for School': 'getCoursesForSchool',
  'Enrollments for Class in School': 'getEnrollmentsForClassInSchool',
  'Students for Class in School': 'getStudentsForClassInSchool',
  'Teachers for Class in School': 'getTeachersForClassInSchool',
  'Enrollments for School': 'getEnrollmentsForSchool',
  'Students for School': 'getStudentsForSchool',
  'Teachers for School': 'getTeachersForSchool',
  'Terms for School': 'getTermsForSchool',
  'Classes for Term': 'getClassesForTerm',
  'Grading Periods for Term': 'getGradingPeriodsForTerm',
  'Classes for Course': 'getClassesForCourse',
  'Classes for Student': 'getClassesForStudent',
  'Classes for Teacher': 'getClassesForTeacher',
  'Classes for School': 'getClassesForSchool',
  'Classes for User': 'getClassesForUser',
  'Students for Class': 'getStudentsForClass',
  'Teachers for Class': 'getTeachersForClass',

  'Line Items': 'getAllLineItems',
  'Line Item by Id': 'getLineItem',
  'Delete Line Item': 'deleteLineItem',
  'Update Line Item': 'putLineItem',
  'Line Items for Class': 'getLineItemsForClass',
  'Line Item Categories': 'getAllCategories',
  'Line Item Category by Id': 'getCategory',
  'Delete Line Item Category': 'deleteCategory',
  'Update Line Item Category': 'putCategory',
  Results: 'getAllResults',
  'Results by Id': 'getResult',
  'Delete Result': 'deleteResult',
  'Update Result': 'putResult',
  'Results for Class': 'getResultsForClass',
  'Results for Line Item for Class': 'getResultsForLineItemForClass',
  'Results for Student for Class': 'getResultsForStudentForClass',

  Resources: 'getAllResources',
  'Resource by Id': 'getResource',
  'Resources for Course': 'getResourcesForCourse',
  'Resources for Class': 'getResourcesForClass',
}

export const PARAM_TO_DISPLAY_NAME: { [key: string]: string } = {
  sort: 'Sort',
  fields: 'Fields',
  filter: 'Filter',
  orderBy: 'Order By',
  offSet: 'Offset',
  limit: 'Limit',
}

export const ENDPOINT_LEAF_TO_SERVICE_MAP = {
  '/academicSessions': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.AcademicSessions,
  '/academicSessions/{id}':
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.AcademicSessions,

  '/terms': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Terms,
  '/terms/{id}': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Terms,
  '/schools/{school_id}/terms': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Terms,

  '/gradingPeriods': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.GradingPeriods,
  '/gradingPeriods/{id}':
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.GradingPeriods,
  '/terms/{term_id}/gradingPeriods':
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.GradingPeriods,

  '/classes': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Classes,
  '/classes/{id}': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Classes,
  '/terms/{term_id}/classes': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Classes,
  '/courses/{course_id}/classes':
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Classes,
  '/teachers/{teacher_id}/classes':
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Classes,
  '/students/{student_id}/classes':
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Classes,
  '/schools/{school_id}/classes':
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Classes,
  '/users/{user_id}/classes': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Classes,

  '/courses': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Courses,
  '/courses/{id}': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Courses,
  '/schools/{school_id}/courses':
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Courses,

  '/demographics': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Demographics,
  '/demographics/{id}': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Demographics,

  '/enrollments': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Enrollments,
  '/enrollments/{id}': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Enrollments,
  '/schools/{school_id}/classes/{class_id}/enrollments':
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Enrollments,
  '/schools/{school_id}/enrollments':
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Enrollments,

  '/orgs': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Orgs,
  '/orgs/{id}': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Orgs,

  '/schools': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Schools,
  '/schools/{id}': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Schools,

  '/users': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Users,
  '/users/{id}': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Users,

  '/students': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Students,
  '/students/{id}': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Students,
  '/schools/{school_id}/classes/{class_id}/students':
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Students,
  '/schools/{school_id}/students':
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Students,
  '/classes/{class_id}/students':
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Students,

  '/teachers': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Teachers,
  '/teachers/{id}': OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Teachers,
  '/schools/{school_id}/classes/{class_id}/teachers':
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Teachers,
  '/schools/{school_id}/teachers':
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Teachers,
  '/classes/{class_id}/teachers':
    OR_1P1_REST_ROSTERING_CONSUMER_SERVICES.Teachers,

  // GRADEBOOK
  '/lineItems': OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.LineItems,
  '/lineItems/{id}': OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.LineItems,
  '/classes/{class_id}/lineItems':
    OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.LineItems,

  '/categories': OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.LineItemCategories,
  '/categories/{id}':
    OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.LineItemCategories,

  '/results': OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.Results,
  '/results/{id}': OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.Results,
  '/classes/{class_id}/results':
    OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.Results,
  '/classes/{class_id}/lineItems/{li_id}/results':
    OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.Results,
  '/classes/{class_id}/students/{student_id}/results':
    OR_1P1_REST_GRADEBOOK_CONSUMER_SERVICES.Results,

  '/resources': OR_1P1_REST_RESOURCES_CONSUMER_SERVICES.Resources,
  '/resources/{id}': OR_1P1_REST_RESOURCES_CONSUMER_SERVICES.Resources,
  '/courses/{course_id}/resources':
    OR_1P1_REST_RESOURCES_CONSUMER_SERVICES.Resources,
  '/classes/{class_id}/resources':
    OR_1P1_REST_RESOURCES_CONSUMER_SERVICES.Resources,
}
