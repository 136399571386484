import React from 'react'
import * as R from 'ramda'

import { View, Icon, Text } from 'lib/components'

export interface IListLegend {
  icon: any
  iconColor?: string
  label: string
}
interface IProps {
  legends: IListLegend[]
  'data-test'?: string
}

export default function ListLegend(props: IProps) {
  const dataTest = props['data-test'] || 'list-legend'
  return (
    <View
      display="flex"
      flexDirection={['column', 'row']}
      justifyContent={['center', 'flex-start']}
      alignItems={['center', 'center']}
      bg="background"
      py={3}
      px={[3, 0]}
      mt={[3, 0]}
      borderRadius={2}
      data-test={dataTest}
    >
      {props.legends.map((legend: IListLegend) => (
        <View
          flexible="row-v-center"
          mx={[2, 3]}
          my={[3, 0]}
          key={legend.label}
          data-test={`${dataTest}-${legend.label}`}
        >
          {R.is(String, legend.icon) && (
            <Icon className={legend.icon} color={legend.iconColor || 'text'} />
          )}
          {!R.is(String, legend.icon) && legend.icon}

          <Text ml={1}>{legend.label}</Text>
        </View>
      ))}
    </View>
  )
}
