import React from 'react'

import CharacterizationErrorDot from 'domains/characterizations/components/CharacterizationErrorDot'
import { utils } from '@ims/1edtech-frontend-common'
import { ToolTip } from 'lib/components'
import { GREEN_REPORT_STATUS } from 'domains/reports/models/IReportStatuses'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'
import { ITransformedRESTServiceEndpointAttributeCharacterizationReport } from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'

const isPrint = isOnPrintScreen()
const dotSize = isPrint ? 12 : undefined

interface IProps
  extends ITransformedRESTServiceEndpointAttributeCharacterizationReport {
  endpointName: string
}

export default function RESTCharacterizationAttributeWarningCell(
  props: IProps,
) {
  const { attributeName, endpointName, errorMessage, notes, status } = props
  if (
    utils.hasValue(errorMessage) ||
    utils.hasValue(notes) ||
    (status && status !== GREEN_REPORT_STATUS)
  ) {
    if (utils.hasValue(errorMessage) && !isPrint) {
      return (
        <ToolTip
          id={`${endpointName}-${attributeName}-warning`}
          tip={errorMessage}
          place="right"
        >
          <CharacterizationErrorDot color="secondary" size={dotSize} />
        </ToolTip>
      )
    }
    return <CharacterizationErrorDot color="secondary" size={dotSize} />
  }
  return null
}
