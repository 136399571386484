import React, { PureComponent } from 'react'
import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'

import { Image, Spinner, Text, View } from 'lib/components'
import { APPLICATION_NAME } from 'domains/application/constants/application'

interface IProps {
  message?: any
  action?: any
}

export default class AppLoadingScreen extends PureComponent<IProps> {
  render() {
    const { message, action } = this.props
    const hasError = utils.hasValue(message)

    return (
      <View flexible="column-center" bg="white" width="100vw" height="100vh">
        <View animation="fadeIn" animationDelay={100} flexible="column-center">
          <Image src="/images/1EdTechLogo.svg" width={120} height={120} mb={2} />
          <Text textAlign="center" variant="jumbo" color="black" my={32}>
            {APPLICATION_NAME}
          </Text>
        </View>
        {!hasError && (
          <View animation="fadeIn" animationDelay={300} m={3}>
            <Spinner color="secondary" />
          </View>
        )}
        {hasError && (
          <>
            {R.is(String, message) && (
              <Text mb={3} textAlign="center" color="white" maxWidth="300px">
                {message}
              </Text>
            )}
            {!R.is(String, message) && message}
            {action}
          </>
        )}
      </View>
    )
  }
}
