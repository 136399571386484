import React from 'react'
import { View } from 'lib/components'
import Text from 'lib/components/Text'
import colors from 'lib/styles/colors'

interface IProps {
  title: string
  children: any
  divider: boolean
}

export default function ListFilterSection(props: IProps) {
  return (
    <View
      borderBottom={props.divider ? `solid 1px ${colors.neutral}` : ''}
      pb={4}
      mb={3}
    >
      <Text fontSize={16} fontWeight={700} my={2}>
        {props.title}
      </Text>
      {props.children}
    </View>
  )
}
