import React, { PureComponent } from 'react'

import { View, Text, ToolTip } from 'lib/components'
import ProductImageCell from 'domains/products/components/ProductImageCell'
import IComparison from 'domains/comparisons/models/IComparison'

interface IProps extends IComparison {
  imageSize?: number
  maxWidth?: string
  maxWidthArray?: (string | number)[]
}

export default class ComparisonProductLogoNameCell extends PureComponent<IProps> {
  render() {
    const {
      id,
      productId,
      productName,
      productLogo,
      imageSize = 28,
      maxWidth,
      maxWidthArray = [100, 220],
    } = this.props

    return (
      <View flexible="row-v-center">
        <ProductImageCell
          name={productName}
          logo={productLogo}
          size={imageSize}
        />
        <ToolTip id={`${id}-${productId}`} tip={productName} delay={250}>
          <Text
            pl={2}
            textAlign="left"
            overflow="ellipsis"
            maxWidth={maxWidth || maxWidthArray}
            fontSize={13}
            data-test={`${id}-product-name`}
          >
            {productName}
          </Text>
        </ToolTip>
      </View>
    )
  }
}
