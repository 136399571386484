import { utils } from '@ims/1edtech-frontend-common'

interface IMinMaxItem {
  smallestValueLength?: number
  largestValueLength?: number
}

const padLeadZero = (value: any) => `${value}`.padStart(1, '0')

export const getReportMinMaxValue = (item: IMinMaxItem) => {
  if (!utils.hasValue(item.smallestValueLength)) {
    return 'N/A'
  }

  return `${padLeadZero(item.smallestValueLength)}/${padLeadZero(
    item.largestValueLength,
  )}`
}
