import * as R from 'ramda'
import memoize from 'fast-memoize'

import ITransformedRESTCharacterizationReport, {
  ITransformedRESTServiceEndpointCharacterizationReport,
  ITransformedRESTServiceEndpointParamCharacterizationReport,
  ITransformedRESTServiceEndpointAttributeCharacterizationReport,
  ITransformedRESTServiceCharacterizationReport,
} from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import IRESTCharacterizationReport, {
  IRESTCharacterizationReportEndpointValidation,
  IRESTCharacterizationReportParameterCharacterization,
  IRESTCharacterizationReportAttributeCharacterization,
  IRESTCharacterizationReportEndpointValidationError,
} from 'domains/characterizations/models/IRESTCharacterizationReport'
import {
  ENDPOINT_LEAF_TO_SERVICE_MAP,
  PARAM_TO_DISPLAY_NAME,
} from 'domains/modeling/constants/restCOneRoster1p1'
import {
  GREEN_REPORT_STATUS,
  RED_REPORT_STATUS,
  AMBER_REPORT_STATUS,
  GRAY_REPORT_STATUS,
} from 'domains/reports/models/IReportStatuses'
import { utils } from '@ims/1edtech-frontend-common'
import IRESTCharacterizationReportEndpointCharacterization, {
  IRESTCharacterizationReportEndpointWarning,
} from 'domains/characterizations/models/IRESTCharacterizationReportEndpointCharacterization'

const errorMatchesParam = (
  param: string,
  error: IRESTCharacterizationReportEndpointValidationError,
) => {
  if (error.type === 'PARAMETER') {
    return (
      param.replace(' ', '').toLowerCase() ===
      (error.attribute || '').toLowerCase()
    )
  }
  return error.description.includes(param)
}
const anyParams = R.values(
  PARAM_TO_DISPLAY_NAME,
).map((param) => (error: IRESTCharacterizationReportEndpointValidationError) =>
  errorMatchesParam(param, error),
)
const whichParam = (
  error: IRESTCharacterizationReportEndpointValidationError,
) =>
  R.find(
    (param) => errorMatchesParam(param, error),
    R.values(PARAM_TO_DISPLAY_NAME),
  )

const transformEndpointValidations = (
  endpointValidations: IRESTCharacterizationReportEndpointValidation[],
) => {
  let serviceCharacterizations: any = {}
  endpointValidations.forEach(
    (endpointReport: IRESTCharacterizationReportEndpointValidation) => {
      const serviceName = R.pathOr(
        '',
        [endpointReport.urlLeaf],
        ENDPOINT_LEAF_TO_SERVICE_MAP,
      )
      if (utils.hasValue(serviceName)) {
        const currentServiceEndpoints = R.pathOr(
          [],
          [serviceName, 'endpointCharacterizations'],
          serviceCharacterizations,
        )
        const errors = R.pathOr(
          [],
          ['errors'],
          endpointReport,
        ) as IRESTCharacterizationReportEndpointValidationError[]

        const topLevelErrors = R.filter(
          (e) => R.includes(e.type, ['GENERAL', 'EXCEPTION', 'CRITICAL']),
          errors,
        ).map(R.prop('description'))

        const paramErrors = errors.reduce((agg, error) => {
          if (R.anyPass(anyParams)(error)) {
            const param = whichParam(error)
            const stringParam = `${param}`
            const previousErrors = R.pathOr([], [stringParam], agg)
            return R.assoc(
              stringParam,
              [...previousErrors, error.description],
              agg,
            )
          }
          return agg
        }, {})

        const attributeErrors = errors.reduce((agg, error) => {
          if (error.attribute && !R.anyPass(anyParams)(error)) {
            const previousErrors = R.pathOr([], [error.attribute], agg)
            return R.assoc(
              error.attribute,
              [...previousErrors, error.description],
              agg,
            )
          }
          return agg
        }, {})

        serviceCharacterizations = R.assoc(
          serviceName,
          {
            serviceName,
            status: RED_REPORT_STATUS,
            failedCharacterization: true,
            endpointCharacterizations: [
              ...currentServiceEndpoints,
              {
                endpointName: endpointReport.label,
                leaf: endpointReport.urlLeaf,
                status: RED_REPORT_STATUS,
                enabled: endpointReport.isEndpointSupported === 'Yes',
                errors: topLevelErrors,
                securityErrors: R.filter(
                  (e) => R.propOr('', 'type', e) === 'SECURITY',
                  errors,
                ),
                supportedErrors: R.filter(
                  (e) => R.propOr('', 'type', e) === 'INVOCATION',
                  errors,
                ),
                failedCharacterization: true,
                paramCharacterizations: R.keys(paramErrors).map(
                  (paramName) => ({
                    paramName,
                    supported: false,
                    status: RED_REPORT_STATUS,
                    errors: paramErrors[paramName],
                  }),
                ),
                attributeCharacterizations: [],
                attributeErrors,
              } as ITransformedRESTServiceEndpointCharacterizationReport,
            ],
          },
          serviceCharacterizations,
        )
      }
    },
  )
  return serviceCharacterizations
}

const transformEndpointCharacterizations = (
  endpointCharacterizations: IRESTCharacterizationReportEndpointCharacterization[],
  serviceCharacterizations: any,
) => {
  endpointCharacterizations.forEach(
    (endpointReport: IRESTCharacterizationReportEndpointCharacterization) => {
      const serviceName = R.pathOr(
        '',
        [endpointReport.urlLeaf],
        ENDPOINT_LEAF_TO_SERVICE_MAP,
      )
      if (utils.hasValue(serviceName)) {
        const currnetService = R.pathOr(
          {
            serviceName,
            failedCharacterization: false,
            status: GREEN_REPORT_STATUS,
            endpointCharacterizations: [],
          },
          [serviceName],
          serviceCharacterizations,
        ) as ITransformedRESTServiceCharacterizationReport
        const serviceHasErrors = currnetService.status === RED_REPORT_STATUS
        let serviceHasAlerts = currnetService.status !== GREEN_REPORT_STATUS

        const currentServiceEndpoints = R.pathOr(
          [],
          ['endpointCharacterizations'],
          currnetService,
        )

        const endpointHasErrors = false
        const isOptionalAndUnsupported =
          endpointReport.isOptional &&
          endpointReport.isEndpointSupported === 'No'
        let endpointHasAlerts = utils.hasValue(endpointReport.warnings)
        if (endpointHasAlerts && !serviceHasAlerts) {
          serviceHasAlerts = true
        }

        const parameters = R.pathOr(
          [],
          ['parameters'],
          endpointReport,
        ) as IRESTCharacterizationReportParameterCharacterization[]
        const paramCharacterizations = parameters.map((paramReport) => {
          const isConformant =
            (paramReport.isConformant || paramReport.isComformant) === 'Yes'
          if (!isConformant && !endpointHasAlerts) {
            if (!serviceHasAlerts) {
              serviceHasAlerts = true
            }
            endpointHasAlerts = true
          }

          return {
            paramName: paramReport.requestType,
            supported: isConformant,
            status: isConformant ? GREEN_REPORT_STATUS : RED_REPORT_STATUS,
          } as ITransformedRESTServiceEndpointParamCharacterizationReport
        })

        const attributes = R.pathOr(
          [],
          ['attributes'],
          endpointReport,
        ) as IRESTCharacterizationReportAttributeCharacterization[]
        const attributeCharacterizations = attributes.map((attributeReport) => {
          const isConformant = attributeReport.isConformant === 'Yes'
          if (
            (!isConformant || utils.hasValue(attributeReport.warning)) &&
            !endpointHasAlerts
          ) {
            if (!serviceHasAlerts) {
              serviceHasAlerts = true
            }
            endpointHasAlerts = true
          }

          return {
            attributeName: attributeReport.name,
            metadata: attributeReport.metadata,
            status: !isConformant
              ? RED_REPORT_STATUS
              : utils.hasValue(attributeReport.warning)
              ? AMBER_REPORT_STATUS
              : GREEN_REPORT_STATUS,
            multiplicity: attributeReport.multiplicity,
            dataType: attributeReport.dataType.type,
            population: attributeReport.population,
            warning: attributeReport.warning,
            errorMessage: attributeReport.warning,
            largestValueLength: attributeReport.largestValueLength,
            smallestValueLength: attributeReport.smallestValueLength,
            largestNumberOfElements: attributeReport.largestNumberOfElements,
            smallestNumberOfElements: attributeReport.smallestNumberOfElements,
          } as ITransformedRESTServiceEndpointAttributeCharacterizationReport
        })

        let warnings = R.pathOr(
          [],
          ['warnings'],
          endpointReport,
        ) as IRESTCharacterizationReportEndpointWarning[]

        // Product says all warnings when optional endpoint not supported will
        // just be the one general message
        // They are now saying that SECURITY messages should be returned too...
        if (isOptionalAndUnsupported) {
          warnings = warnings.map((warning) => {
            if (warning.type === 'INVOCATION' || warning.type === 'SECURITY') {
              return { ...warning, type: 'GENERAL' }
            }
            return warning
          }) as IRESTCharacterizationReportEndpointWarning[]
        }

        serviceCharacterizations = R.assoc(
          serviceName,
          {
            serviceName,
            status: serviceHasErrors
              ? RED_REPORT_STATUS
              : serviceHasAlerts
              ? AMBER_REPORT_STATUS
              : GREEN_REPORT_STATUS,
            failedCharacterization: false,
            endpointCharacterizations: [
              ...currentServiceEndpoints,
              {
                endpointName: endpointReport.label,
                leaf: endpointReport.urlLeaf,
                status: endpointHasErrors
                  ? RED_REPORT_STATUS
                  : isOptionalAndUnsupported
                  ? GRAY_REPORT_STATUS
                  : endpointHasAlerts
                  ? AMBER_REPORT_STATUS
                  : GREEN_REPORT_STATUS,
                failedCharacterization: false,
                enabled: endpointReport.isEndpointSupported === 'Yes',
                collectionSize: endpointReport.collectionsSize,
                sampleSize: endpointReport.sampleSize,
                isOptional: endpointReport.isOptional,
                isOptionalAndUnsupported,
                securityWarnings: R.filter(
                  (e) => R.propOr('', 'type', e) === 'SECURITY',
                  warnings,
                ),
                supportedWarnings: R.filter(
                  (e) => R.propOr('', 'type', e) === 'INVOCATION',
                  warnings,
                ),
                warnings: warnings.filter(
                  (w) => !R.includes(w.type, ['SECURITY', 'INVOCATION']),
                ),
                paramCharacterizations,
                attributeCharacterizations,
              } as ITransformedRESTServiceEndpointCharacterizationReport,
            ],
          },
          serviceCharacterizations,
        )
      }
    },
  )

  R.keys(serviceCharacterizations).forEach((serviceName) => {
    const service = R.prop(serviceName, serviceCharacterizations)
    const allNAEndpoints = R.all(R.propEq('status', GRAY_REPORT_STATUS))(
      service.endpointCharacterizations,
    )
    if (allNAEndpoints) {
      serviceCharacterizations = R.assoc(
        serviceName as string,
        {
          ...service,
          status: GRAY_REPORT_STATUS,
        },
        serviceCharacterizations,
      )
    }
  })
  return serviceCharacterizations
}

export const transformRESTPCharacterizationReport = memoize(
  (
    report: IRESTCharacterizationReport,
  ): ITransformedRESTCharacterizationReport => {
    const reportServiceValidations = R.pathOr(
      [],
      ['serviceValidations', 0, 'endpoints'],
      report,
    )
    const reportServiceCharacterizations = R.pathOr(
      [],
      ['serviceCharacterisations', 0, 'endpoints'],
      report,
    )

    const transformedValidations = transformEndpointValidations(
      reportServiceValidations,
    )
    const transformedCharacterizations = transformEndpointCharacterizations(
      reportServiceCharacterizations,
      transformedValidations,
    )
    const serviceCharacterizations = R.values(transformedCharacterizations)

    return {
      comparisonId: report.validationId,
      runDate: report.context.testDate,
      unavailable: false,
      failedCharacterization:
        utils.hasValue(reportServiceValidations) ||
        utils.hasValue(report.criticalError),
      hasErrors: R.any(R.propEq('status', RED_REPORT_STATUS))(
        serviceCharacterizations,
      ),
      criticalError: report.criticalError,
      serviceCharacterizations,
    }
  },
)
