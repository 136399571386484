import React from 'react'
import * as R from 'ramda'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { utils, records } from '@ims/1edtech-frontend-common'

import ModalWrapper from 'lib/components/ModalWrapper'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'
import {
  Dropdown,
  Menu,
  SearchBox,
  Span,
  Text,
  View,
  Switch,
} from 'lib/components'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import CharacterizationMenuItem from 'domains/characterizations/components/CharacterizationMenuItem'
import trackComparisonAnalytics from 'domains/comparisons/utils/trackComparisonAnalytics'
import ComparisonDetails from 'domains/comparisons/components/ComparisonDetails'
import ProductMenuItem from 'domains/products/components/ProductMenuItem'
import createComparison from 'domains/comparisons/workflows/createComparison'
import { getComparisonReportRoute } from 'domains/comparisons/navigation/routes'
import IProduct from 'domains/products/models/IProduct'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import { RootState } from 'lib/store/rootReducer'
import { showToast, ERROR_TOAST } from 'lib/utils/toast'
import { ORGANIZATIONS_RECORD } from 'lib/records/modules/organizations'
import { formatIdByNamePropSelector } from 'domains/specifications/selectors/formatSelectors'
import { getRequest } from 'lib/api/api'
import IProductFormat from 'domains/products/models/IProductFormat'
import { isProductFormatSupported } from 'domains/products/utils/isProductFormatSupported'
import { PUBLISHED_PRODUCT_STATUS } from 'domains/products/constants/products'
import { FORMATS_RECORD } from 'lib/records/modules/formats'
import IFormat from 'domains/formats/models/IFormat'
import { AnySpecFormatType } from 'domains/formats/constants/formats'
import {
  GREEN_REPORT_STATUS,
  AMBER_REPORT_STATUS,
} from 'domains/reports/models/IReportStatuses'
import { characterizationByFormatSelector } from 'domains/characterizations/selectors/characterizationByFormatSelector'
import { isSupplierRoleSelector } from 'domains/authentication/selectors/roles'

const dropdownTargetProps = {
  width: '100%',
}

interface IProps {
  id?: string | number
  format?: AnySpecFormatType
  startWithProduct?: boolean
  isOpen: boolean
  closeModal: () => void
}

export default function ComparisonModal(props: IProps) {
  const history = useHistory()
  const searchBox = React.useRef() as any

  const propsProduct = useSelector((s: RootState) =>
    records.entitiesSelectors.entityByIdSelector(PRODUCTS_RECORD, 'id')(
      s,
      props,
    ),
  )
  const propsCharacterization = useSelector((s: RootState) =>
    characterizationByFormatSelector()(s, props),
  )
  const orgId = useSelector((s: RootState) =>
    myOrganizationIdSelector(s, props),
  )
  const isSupplier = useSelector(isSupplierRoleSelector)
  const shareComparisons = useSelector(
    records.entitiesSelectors.entityPropSelector(
      ORGANIZATIONS_RECORD,
      myOrganizationIdSelector,
      'shareComparisons',
      false,
    ),
  )
  const formatId = useSelector((s: RootState) =>
    formatIdByNamePropSelector('format', true)(s, props),
  )
  const formats = useSelector((s: RootState) =>
    records.recordsSelectors.fullRecordsSelector(FORMATS_RECORD)(s, props),
  )

  const [pending, setPending] = React.useState(false)
  const [searchFocused, setSearchFocused] = React.useState(false)

  const [productId, setProductId] = React.useState(0)
  const [products, setProducts] = React.useState<IProduct[]>([])
  const [initialProducts, setInitialProducts] = React.useState<IProduct[]>([])
  const [productName, setProductName] = React.useState('')
  const [product, setProduct] = React.useState<IProduct | null>(
    props.startWithProduct ? propsProduct || null : null,
  )

  const [characterizationId, setCharacterizationId] = React.useState(0)
  const [characterizations, setCharacterizations] = React.useState<
    ICharacterization[]
  >([])
  const [initialCharacterizations, setInitialCharacterizations] =
    React.useState<ICharacterization[]>([])
  const [characterizationName, setCharacterizationName] = React.useState('')
  const [characterization, setCharacterization] =
    React.useState<ICharacterization | null>(
      props.startWithProduct ? null : propsCharacterization || null,
    )

  const [shareComparison, setShareComparison] = React.useState(
    isSupplier ? false : !!shareComparisons,
  )

  const [format, setFormat] = React.useState<AnySpecFormatType | null>(
    props.format || null,
  )

  const [search, setSearch] = React.useState('')
  React.useEffect(() => {
    const fetch = async () => {
      if (props.startWithProduct && product) {
        await onGetCharacterizations()
      } else if (characterization) {
        await onGetProducts()
      }
    }

    fetch()
  }, [search, product, characterization]) // eslint-disable-line

  React.useEffect(() => {
    const update = async () => {
      if (props.isOpen) {
        const event = props.startWithProduct
          ? 'started_comparison_from_product'
          : 'started_comparison_from_characterization'
        trackComparisonAnalytics(event, { product: propsProduct })
        setShareComparison(isSupplier ? false : !!shareComparisons)
        if (props.startWithProduct && product) {
          onGetCharacterizations()
        } else if (characterization) {
          await onGetProducts()
        }
      }
    }
    update()
  }, [props.isOpen, product, characterization, shareComparisons]) // eslint-disable-line

  React.useEffect(() => {
    if (props.startWithProduct) {
      setProduct(propsProduct)
    } else {
      setCharacterization(propsCharacterization)
    }
  }, [props.startWithProduct, propsProduct, propsCharacterization])

  const onGetCharacterizations = async () => {
    const formatsFilter =
      formatId > 0
        ? `format=${formatId}`
        : R.compose<
            IProduct,
            IProductFormat[],
            IProductFormat[],
            IProductFormat[],
            number[],
            string[],
            string
          >(
            R.join(' OR '),
            R.map((fid) => `format=${fid}`),
            R.map((f) => {
              const foundFormat = R.find<IFormat>(
                R.propEq('description', f.format),
              )(formats.items as IFormat[])
              if (foundFormat) {
                return foundFormat.inverse
              }
              return 0
            }),
            R.filter<IProductFormat>(
              (pf) => R.propOr('', 'status', pf) === PUBLISHED_PRODUCT_STATUS,
            ),
            R.filter<IProductFormat>((f) => isProductFormatSupported(f.format)),
            R.propOr([], 'formats'),
          )(product!)
    const response = await getRequest(
      `organizations/${orgId}/characterizations`,
      {
        filter: `status=${GREEN_REPORT_STATUS} OR status=${AMBER_REPORT_STATUS} AND ${formatsFilter}${
          utils.hasValue(search) ? ` AND name=~${search}` : ''
        }`,
        sort: 'name:ascending',
        limit: 250,
      },
    )

    const chars = R.pathOr<ICharacterization[]>(
      [],
      ['data', 'characterizationReport'],
      response,
    )
    setCharacterizations(chars)
    setInitialCharacterizations(
      utils.hasValue(search) ? initialCharacterizations : chars,
    )
  }

  const onGetProducts = async () => {
    let filter = 'productFormatStatus=PUBLISHED'
    if (utils.hasValue(search)) {
      filter = `${filter} AND name=~${search}`
    }
    const char = propsCharacterization as ICharacterization
    const charOrgId = utils.convertToInt(
      R.pathOr(0, ['organization', 'id'], char),
    )
    if (isSupplier && charOrgId === orgId) {
      filter = `${utils.hasValue(filter) ? `${filter} AND ` : ''}orgId=${orgId}`
    }
    const response = await getRequest(`formats/${formatId}/products`, {
      ...(utils.hasValue(filter) ? { filter } : {}),
      sort: 'name:ascending',
      limit: 250,
    })
    const prods = R.pathOr<IProduct[]>([], ['data', 'products'], response)
    setProducts(prods)
    setInitialProducts(utils.hasValue(search) ? initialProducts : prods)
  }

  const onCompare = async () => {
    setPending(true)
    let comparison
    if (props.startWithProduct) {
      trackComparisonAnalytics('compared_product_to_characterization', {
        productId: propsProduct!.id,
        productName: propsProduct!.name,
        characterizationId,
        characterizationName,
      })
      comparison = await createComparison(
        propsProduct!.id,
        characterizationId,
        characterization!.format,
        shareComparison,
      )
    } else {
      trackComparisonAnalytics('compared_characterization_to_product', {
        productId,
        productName,
        characterizationId: propsCharacterization!.id,
        characterizationName: propsCharacterization!.name,
      })
      comparison = await createComparison(
        productId,
        propsCharacterization!.id,
        format!,
        shareComparison,
      )
    }

    setPending(false)
    if (comparison) {
      onCleared()
      history.push(getComparisonReportRoute(comparison.id, comparison.format))
    } else {
      showToast(ERROR_TOAST, 'Failed to run comparison')
    }
  }

  const onCloseModal = () => {
    onCleared()
    props.closeModal()
  }

  const onSearchFocus = () => setSearchFocused(true)

  const onSearchBlur = () => setTimeout(() => setSearchFocused(false), 250)

  const onCleared = () => {
    setSearch('')
    setSearchFocused(false)
    setProductId(0)
    setProductName('')
    setProduct(props.startWithProduct ? propsProduct || null : null)
    setCharacterizationId(0)
    setCharacterizationName('')
    setCharacterization(
      !props.startWithProduct ? propsCharacterization || null : null,
    )
    setFormat(null)
  }

  const onItemSelected = ({ id, name }: any, fromSelect?: boolean) => {
    const item = getProductOrCharacterization(id, fromSelect)
    if (item) {
      trackComparisonAnalytics(
        `selected_${
          props.startWithProduct ? 'charcterization' : 'product'
        }_for_comparison`,
        item,
      )
    }
    if (props.startWithProduct) {
      const char = item as ICharacterization
      setCharacterization(char)
      setCharacterizationId(id)
      setCharacterizationName(name)
      setSearch(name)
      setFormat(char.format)
    } else {
      const prod = item as IProduct
      setProduct(prod)
      setProductId(id)
      setProductName(name)
      setSearch(name)
    }
    const setSearchValue = R.pathOr<(name: string) => any>(
      () => null,
      ['current', 'setValue'],
      searchBox,
    )
    if (setSearchValue) {
      setSearchValue(name)
    }
  }

  const onToggleShareComparison = () => setShareComparison(!shareComparison)

  const getProductOrCharacterization = (
    id: string | number,
    fromSelect?: boolean,
  ) => {
    const productList = fromSelect ? initialProducts : products
    const characterizationList = fromSelect
      ? initialCharacterizations
      : characterizations
    return R.find<IProduct | ICharacterization>(
      (item) => item.id == id, // eslint-disable-line
      props.startWithProduct ? characterizationList : productList,
    )
  }

  const getActions = () => {
    const canLaunch = props.startWithProduct
      ? characterizationId > 0
      : productId > 0
    return [
      {
        text: 'Launch',
        variant: canLaunch ? 'start' : 'neutral',
        onClick: onCompare,
        extra: {
          type: 'submit',
          disabled: !canLaunch,
        },
      },
      {
        text: 'Cancel',
        variant: 'neutral',
        onClick: onCloseModal,
        extra: {
          type: 'button',
        },
      },
    ]
  }

  if (
    (props.startWithProduct && !propsProduct) ||
    (!props.startWithProduct && !propsCharacterization)
  ) {
    return null
  }
  const searchMenuItems = props.startWithProduct
    ? utils.hasValue(search)
      ? characterizations
      : initialCharacterizations
    : utils.hasValue(search)
    ? products
    : initialProducts
  return (
    <ModalWrapper
      isOpen={props.isOpen}
      title={`Launch Comparison with ${
        props.startWithProduct
          ? utils.string.ellipsize(propsProduct!.name, 75)
          : utils.string.ellipsize(propsCharacterization!.name, 75)
      }`}
      actions={getActions()}
      pending={pending}
    >
      <View flexible="row" my={2}>
        <View flexible="column" flex={1}>
          <Text variant="subtitle" fontWeight={700} mb={2}>
            Select {props.startWithProduct ? 'Characterization' : 'Product'}
          </Text>
          {props.startWithProduct && (
            <Text data-test="options-explanation">
              Only characterizations associated with the same specification and
              format(s) as <Span fontWeight={700}>{propsProduct!.name}</Span>{' '}
              will be available for comparison.
            </Text>
          )}
          {!props.startWithProduct && (
            <Text data-test="options-explanation">
              Only products associated with the same specification and format as{' '}
              <Span fontWeight={700}>{propsCharacterization!.name}</Span> will
              be available for comparison.
            </Text>
          )}
        </View>

        {!isSupplier && (
          <View flexible="column-center" ml={3}>
            <Switch
              on={shareComparison}
              onChange={onToggleShareComparison}
              onIconName="fas fa-eye"
              offIconName="fas fa-eye-slash"
              dataTest="share-comparison-switch"
            />
            <Text fontWeight={700} mt={1}>
              Share
            </Text>
          </View>
        )}
      </View>

      <Text mt={4} mb={1} fontWeight={700}>
        Search by Name
      </Text>
      <Dropdown
        target={
          <SearchBox
            name={`compare-${
              props.startWithProduct ? 'characterization' : 'product'
            }-search`}
            ref={searchBox}
            width="100%"
            placeholder="Search..."
            onChange={setSearch}
            onFocus={onSearchFocus}
            onBlur={onSearchBlur}
            onCleared={onCleared}
            itemSelected={
              props.startWithProduct ? characterizationId > 0 : productId > 0
            }
            noIcon={true}
            aria-label={
              props.startWithProduct
                ? 'Search Characterizations'
                : 'Search Products'
            }
            autoFocus={false}
          />
        }
        targetProps={dropdownTargetProps}
        constrainTo="window"
        disableToggleClose={true}
        data-test="comparison-search"
      >
        <>
          {searchFocused && (
            <Menu
              items={searchMenuItems}
              itemData={props.startWithProduct ? {} : { format }}
              component={
                props.startWithProduct
                  ? CharacterizationMenuItem
                  : ProductMenuItem
              }
              onClick={onItemSelected}
            />
          )}
        </>
      </Dropdown>

      <View mt={3} />
      <ComparisonDetails
        product={props.startWithProduct ? product! : product}
        characterization={
          props.startWithProduct ? characterization : propsCharacterization
        }
        format={format || props.format}
      />
    </ModalWrapper>
  )
}
