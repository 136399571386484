import * as R from 'ramda'

import { postRequest, putRequest } from 'lib/api/api'
import { initialize } from 'domains/application/workflows/initialize'
import trackAuthenticationAnalytics from 'domains/authentication/utils/trackAuthenticationAnalytics'
import { history } from 'lib/utils/navigation'
import {
  DASHBOARD_ROUTE,
  getRoute,
} from 'domains/application/navigation/routes'
import store from 'lib/store'
import { RESET_STORE } from 'lib/store/rootReducer'
import { setTokens } from 'lib/api/utils'

export const login = async (
  username: string,
  password: string,
  orgId?: number,
): Promise<boolean> => {
  let response
  if (orgId) {
    response = await putRequest(`login/${orgId}`)
  } else {
    response = await postRequest('oauth2server/clienttoken', {
      username,
      password,
    })
  }

  if (response.success) {
    const redirectUrl = R.pathOr(
      DASHBOARD_ROUTE,
      ['location', 'state', 'redirectUrl'],
      history,
    )

    if (orgId) {
      store.dispatch({ type: RESET_STORE })
      await initialize()
      history.push(getRoute(redirectUrl))
    } else {
      trackAuthenticationAnalytics('successful_login')
      setTokens(response)
      await initialize()
      history.push(getRoute(redirectUrl))
    }

    return true
  } else {
    trackAuthenticationAnalytics('failed_login')
    return false
  }
}
