import { combineReducers } from 'redux'
import { records } from '@ims/1edtech-frontend-common'

import application, {
  ApplicationActions,
  IApplicationState,
} from 'domains/application/ducks/application'

export type RootActions = ApplicationActions &
  records.recordActions.RecordActions &
  records.relatedRecordActions.RelatedRecordActions &
  records.entitiesActions.EntitiesActions

export interface IState {
  readonly application: IApplicationState
  readonly entities: records.entitiesReducers.IEntitiesState
  readonly records: records.recordReducers.IRecordsState
  readonly relatedRecords: records.relatedRecordReducers.IRelatedRecordsState
}

const appReducer = combineReducers({
  application,
  entities: records.entitiesReducers.reducer,
  records: records.recordReducers.reducer,
  relatedRecords: records.relatedRecordReducers.reducer,
})

export const RESET_STORE = '@@root/RESET_STORE'
const rootReducer = (state: any, action: any) => {
  if (action.type === RESET_STORE) {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
