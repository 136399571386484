import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

export const USER_PREFERENCES_RECORD = 'userPreferences'

const userPreferences: records.modules.IModule = {
  record: USER_PREFERENCES_RECORD,
  schema: new normalizr.schema.Entity(
    USER_PREFERENCES_RECORD,
    {},
    { idAttribute: 'userId' },
  ),
  api: {
    create: () => '',
    get: (id) => `users/${id}/preferences`,
    getList: () => '',
    update: (id) => `users/${id}/preferences`,
    delete: () => '',
  },
}

export default userPreferences
