import React from 'react'

import IFileSetCharacterizationColumn from 'domains/characterizations/models/IFileSetCharacterizationColumn'
import { View, ToolTip, ListPlainTextCell } from 'lib/components'
import { WARNING_TO_COLUMN_MAP } from 'domains/characterizations/constants/characterizationReport'
import { findSpecColumn } from 'domains/modeling/selectors/csvCModelToSpec'
import ICSVSpecification from 'domains/specifications/models/ICSVSpecification'
import { isOnPrintScreen } from 'lib/hooks/usePrintOnMountEffect'

const isPrint = isOnPrintScreen()

interface IProps extends IFileSetCharacterizationColumn {
  fileLabel: string
  specification: ICSVSpecification
}

export default function CharacterizationColumnRequiredCell(props: IProps) {
  const {
    fileLabel,
    required,
    specification,
    headerName,
    warningTitle,
    warning,
  } = props
  let display = required
  if (!display) {
    const specColumn = findSpecColumn(specification, fileLabel, headerName)
    display = specColumn ? specColumn.required : ''
  }
  if (warningTitle && WARNING_TO_COLUMN_MAP[warningTitle] === 'Required') {
    const content = (
      <ListPlainTextCell
        color={warning ? (isPrint ? 'secondary' : 'white') : 'text'}
        fontWeight={warning ? 700 : 400}
      >
        {display}
      </ListPlainTextCell>
    )

    if (isPrint) {
      return content
    }

    return (
      <ToolTip id={`${fileLabel}-${headerName}-required`} tip={warning}>
        <View
          bg={warning ? 'secondary' : ''}
          borderRadius={4}
          px={1}
          py="12px"
          maxWidth={100}
        >
          {content}
        </View>
      </ToolTip>
    )
  }

  return <ListPlainTextCell>{display}</ListPlainTextCell>
}
