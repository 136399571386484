import React from 'react'

import { View, Text, Switch } from 'lib/components'
import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'

export default function CCCharacterizationReportShowMissingMDSwitch() {
  const { showMissingMetadata, setShowMissingMetadata } = React.useContext(
    ShowMissingMetadataContext,
  )
  // const isShowMissingMDEnabled = useIsFeatureEnabled('CC_SHOW_MISSING_MD')
  // const isShowMissingMDEnabled = getEnvVariable('MOCK_OAUTH') === 'true'
  const isShowMissingMDEnabled = false
  const toggleShowMissingMD = () => setShowMissingMetadata(!showMissingMetadata)

  if (isShowMissingMDEnabled) {
    return (
      <View mt={[2, 0]} flexible="row-v-center">
        <Switch
          on={showMissingMetadata}
          onChange={toggleShowMissingMD}
          dataTest="show-missing-metadata-switch"
          small={true}
          variant="secondary"
        />
        <Text ml={2} variant="caption">
          Show missing metadata
        </Text>
      </View>
    )
  }

  return null
}
