import React from 'react'

import { ListPlainTextCell } from 'lib/components'
import IProduct from 'domains/products/models/IProduct'
import { isCCXCertified } from 'domains/orgs/models/IOrg'
import CCxCertifiedBadge from 'lib/components/CCxCertifiedBadge'

export default function DistrictProductCatalogSupplierCell(props: IProduct) {
  return (
    <div className="flex flex-row items-center">
      <ListPlainTextCell>{props.organizationName}</ListPlainTextCell>
      {isCCXCertified(props.organizationCertificationLevel) && (
        <CCxCertifiedBadge className="ml-2" showTooltip />
      )}
    </div>
  )
}
