import * as R from 'ramda'
import { utils, records } from '@ims/1edtech-frontend-common'

import {
  PRODUCT_CATALOG_ROUTE,
  getRoute,
} from 'domains/application/navigation/routes'
import store from 'lib/store'
import {
  FORMAT_TO_FORMAT_NAV_ROUTE_MAP,
  AnySpecFormatType,
} from 'domains/formats/constants/formats'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'
import IProduct from 'domains/products/models/IProduct'
import { getProductFormatModelId } from 'domains/products/utils/productByModel'
import IProductFormat from 'domains/products/models/IProductFormat'
import { ONEROSTER_BASE_NAV_ROUTE } from 'domains/specifications/constants/specNavigation'

export const getProductRoute = (
  id: any,
  format: AnySpecFormatType | null = null,
  parts: any[] | string = [],
  queryString = {},
) => {
  const urlParts = format
    ? [
        FORMAT_TO_FORMAT_NAV_ROUTE_MAP[format],
        ...utils.array.ensureArray(parts),
      ]
    : parts
  return getRoute(
    `/${ONEROSTER_BASE_NAV_ROUTE}${PRODUCT_CATALOG_ROUTE}`,
    id,
    urlParts,
    queryString,
  )
}

export const getProductStartModelingRoute = (
  productId: string | number,
  format: AnySpecFormatType,
) => {
  return getProductRoute(productId, format, ['model', 'start'])
}

export const getProductModelingRoute = (
  productId: string | number,
  modelId: string | number,
  product?: IProduct,
) => {
  const theProduct = utils.hasValue(product)
    ? product!
    : (records.entitiesSelectors.entityByIdSelector(
        PRODUCTS_RECORD,
        utils.convertToInt(productId),
      )(store.getState()) as IProduct)
  const format = R.find(
    (f: IProductFormat) =>
      getProductFormatModelId(theProduct, f.format) ===
      utils.convertToInt(modelId),
  )(theProduct.formats)
  return getProductRoute(productId, format ? format.format : null, [
    'model',
    modelId,
  ])
}

export const getProductFileModelingRoute = (
  productId: string | number,
  modelId: string | number,
  fileName: string,
) => [getProductModelingRoute(productId, modelId), 'files', fileName].join('/')

export const getProductModelingServicesRoute = (
  productId: string | number,
  modelId: string | number,
  services: string[],
) =>
  [
    getProductModelingRoute(productId, modelId),
    'services',
    services.join(','),
  ].join('/')

export const getProductModelCharacterizationRoute = (
  productId: string | number,
  modelId: string | number,
) => [getProductModelingRoute(productId, modelId), 'characterization'].join('/')
