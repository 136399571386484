import * as R from 'ramda'
import { utils, records } from '@ims/1edtech-frontend-common'

import { getRecord } from 'lib/records/workflows/getRecord'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'
import store from 'lib/store'
import {
  isRosteredByOtherProduct,
  productHasModelingStatus,
} from 'domains/products/utils/products'
import { CSV_C_MODELS_RECORD } from 'lib/records/modules/csvCModels'
import { getRosteringToolId } from 'domains/products/selectors/rosteringTool'
import { CSV_C_MODEL_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/csvCModelCharacterizations'
import { CSV_C_MODEL_CHARACTERIZATION_REPORTS_RECORD } from 'lib/records/modules/csvCModelCharacterizationReports'
import { updateEntityProperty } from 'lib/store/entities.actions'
import {
  CHARACTERISED_MODELLING_STATUS,
  NOT_MODELLED_MODELLING_STATUS,
} from 'domains/products/constants/products'
import {
  AnyFormatType,
  ANY_FORMAT_TO_SPEC_FORMAT_MAP,
  ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
} from 'domains/formats/constants/formats'
import { getProductFormatModelId } from 'domains/products/utils/productByModel'
import IProduct from 'domains/products/models/IProduct'

export const getFullProduct = async (
  id: number | string,
  format: AnyFormatType,
): Promise<IProduct | undefined> => {
  const specFormat = ANY_FORMAT_TO_SPEC_FORMAT_MAP[format]
  await getRecord(PRODUCTS_RECORD, id)
  const product = records.entitiesSelectors.entityByIdSelector(
    PRODUCTS_RECORD,
    utils.convertToInt(id),
  )(store.getState())

  if (!product) {
    return
  }

  if (isRosteredByOtherProduct(product)) {
    await getRecord(PRODUCTS_RECORD, getRosteringToolId(product))
  }

  if (
    format === ONER_V1P1_CSV_ROSTERING_IMPORT_BULK &&
    !productHasModelingStatus(NOT_MODELLED_MODELLING_STATUS)(
      product,
      specFormat,
    )
  ) {
    const modelId = getProductFormatModelId(product, specFormat)
    if (modelId && format === ONER_V1P1_CSV_ROSTERING_IMPORT_BULK) {
      await getRecord(CSV_C_MODELS_RECORD, modelId, { productId: product.id })
    }
    if (
      productHasModelingStatus(CHARACTERISED_MODELLING_STATUS)(
        product,
        specFormat,
      ) &&
      utils.hasValue(modelId)
    ) {
      const response = await getRecord(
        CSV_C_MODEL_CHARACTERIZATIONS_RECORD,
        modelId,
      )
      if (response.success) {
        const { characterizationLink } = response.data
        store.dispatch(
          updateEntityProperty(
            [PRODUCTS_RECORD, id],
            R.assoc(`${specFormat}-characterizationLink`, characterizationLink),
          ),
        )
        await getRecord(
          CSV_C_MODEL_CHARACTERIZATION_REPORTS_RECORD,
          characterizationLink,
        )
      }
    }
  }
  return product as IProduct
}
