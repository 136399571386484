import React from 'react'
import * as R from 'ramda'

import { getNavigationProp } from 'lib/utils/withNavigationProps'
import {
  AnyNavFormatType,
  FORMAT_NAV_ROUTE_TO_FORMAT_MAP,
} from 'domains/formats/constants/formats'

interface IProps {
  map: any
}

export default function useFormatScreen(props: IProps) {
  const format = getNavigationProp(props, 'format') as string
  const specFormat = FORMAT_NAV_ROUTE_TO_FORMAT_MAP[format as AnyNavFormatType]
  const Component = R.propOr<null, any, any>(null, specFormat, props.map)
  if (!Component) {
    return null
  }
  return <Component {...props} />
}
