import React from 'react'
import * as R from 'ramda'
import { RouteComponentProps } from 'react-router'
import { utils } from '@ims/1edtech-frontend-common'
import clsx from 'clsx'

import {
  Span,
  Text,
  View,
  WizardFooter,
  Spinner,
  Dropdown,
  SearchBox,
  Menu,
} from 'lib/components'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import {
  getProductModelingRoute,
  getProductRoute,
} from 'domains/products/navigation/routes'
import { createCsvCModel } from 'domains/modeling/workflows/createCsvCModel'
import IProduct from 'domains/products/models/IProduct'
import {
  ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
  ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
  ONER_V1P1_REST_CONSUMER_ROSTERING_CORE,
  AnySpecFormatType,
} from 'domains/formats/constants/formats'
import { showToast, ERROR_TOAST } from 'lib/utils/toast'
import { createRestModel } from 'domains/modeling/workflows/createRestModel'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import { OR1P1Subsets } from 'domains/specifications/constants/oneRoster1p1FileSets'
import BaseProductDetailsBreadCrumbs from 'domains/products/components/BaseProductDetailsBreadCrumbs'
import useFullProductByFormat from 'domains/products/hooks/useFullProductByFormat'
import StartModelSelect from 'domains/modeling/components/StartModelSelect'
import { copyProductModel } from 'domains/modeling/workflows/copyProductModel'
import { IModelSummary } from 'domains/modeling/models/IModelSummary'
import CopyModelSearchRow from 'domains/modeling/components/CopyModelSearchRow'
import { getModelsForFormat } from 'domains/modeling/workflows/getModelsForFormat'

function PromptText({
  children,
  className,
}: {
  children: string
  className?: any
}) {
  return <p className={clsx('text-2xl font-light', className)}>{children}</p>
}

export const getTitle = (format: AnySpecFormatType, productName: string) => {
  switch (format) {
    case ONER_V1P1_CSV_ROSTERING_IMPORT_BULK:
      return (
        <Text fontSize={18}>
          CSV Consumer v1.1 Modeling Setup Step{' '}
          <Span fontWeight={700}>
            1: Select Required OneRoster v1.1 CSV Files for {productName}
          </Span>
        </Text>
      )
    case ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL:
    case ONER_V1P1_REST_CONSUMER_ROSTERING_CORE:
      return (
        <Text fontSize={18}>
          REST Consumer v1.1 Modeling Setup Step{' '}
          <Span fontWeight={700}>
            1: Select Functional Mode for {productName}
          </Span>
        </Text>
      )
    default:
      return ''
  }
}

export const getSubtitle = (
  format: AnySpecFormatType,
  subset: OR1P1Subsets,
) => {
  switch (subset) {
    case OR1P1Subsets.CLASS_ROSTERS_W_DEMOGRAPHICS_SUBSET:
      switch (format) {
        case ONER_V1P1_CSV_ROSTERING_IMPORT_BULK:
          return '(with demograpics)'
        default:
          return ''
      }
    default:
      return ''
  }
}

export default function StartProductModelingScreen(props: RouteComponentProps) {
  const searchBox = React.useRef() as any
  const [fileSet, setFileset] = React.useState<OR1P1Subsets | ''>('')
  const onSetSubset = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setFileset(event.target.value as OR1P1Subsets)
  const [pending, setPending] = React.useState(false)
  const [productData, formatData, , productPending] = useFullProductByFormat(
    props,
    'id',
    'format',
  )

  const isRosteringCSV = formatData === ONER_V1P1_CSV_ROSTERING_IMPORT_BULK
  React.useEffect(() => {
    if (!utils.hasValue(fileSet) && utils.hasValue(formatData)) {
      switch (formatData as AnySpecFormatType) {
        case ONER_V1P1_CSV_ROSTERING_IMPORT_BULK:
          setFileset(OR1P1Subsets.CLASS_ROSTERS_SUBSET)
          break
        case ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL:
          setFileset(OR1P1Subsets.CLASS_ROSTERS_W_DEMOGRAPHICS_SUBSET)
          break
      }
    }
  }, [fileSet, formatData])

  const [modelsInitialized, setModelsInitialized] = React.useState(false)
  const [models, setModels] = React.useState<IModelSummary[]>([])
  const [selectedModel, setSelectedModel] = React.useState<
    IModelSummary | undefined
  >()
  const onModelSelected = (model: IModelSummary) => {
    setSelectedModel(model)
    setSearch(model.productName)
    const setSearchValue = R.pathOr<(name: string) => any>(
      () => null,
      ['current', 'setValue'],
      searchBox,
    )
    if (setSearchValue) {
      setSearchValue(model.productName)
    }
  }
  const [search, setSearch] = React.useState('')
  const [searchTouched, setSearchTouched] = React.useState(false)
  const [searchFocused, setSearchFocused] = React.useState(false)
  const onSearchFocus = () => {
    setSearchFocused(true)
    setSearchTouched(true)
  }
  const onSearchBlur = () => setTimeout(() => setSearchFocused(false), 250)

  const getModels = React.useCallback(
    async (newSearch?: string) => {
      const { success, data } = await getModelsForFormat(
        formatData!,
        newSearch || search,
      )
      if (!success) {
        showToast('error', 'Failed to get existing models')
        return
      }
      setModels(data)
      setModelsInitialized(true)
    },
    [search, formatData],
  )

  React.useEffect(() => {
    getModels()
  }, []) // eslint-disable-line

  const onSearch = async (s: string) => {
    setSearch(s)
    setSearchTouched(true)
    await getModels(s)
  }

  const onClear = async () => {
    setSelectedModel(undefined)
    setSearch('')
    await getModels('')
  }

  const [isACopy, setIsACopy] = React.useState(false)
  const onSetIsACopy = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const copy = event.target.value === 'true'
    setIsACopy(copy)
    if (copy) await getModels()
  }

  if (productPending) {
    return <Spinner centered={true} />
  }

  const product = productData as IProduct
  const format = formatData as AnySpecFormatType

  const onNext = async () => {
    setPending(true)

    let modelId: boolean | number = 0
    if (isACopy && selectedModel) {
      modelId = await copyProductModel(
        utils.convertToInt(product.id),
        selectedModel.modelId,
        format,
        fileSet === OR1P1Subsets.CLASS_ROSTERS_W_DEMOGRAPHICS_SUBSET,
      )
    } else {
      switch (format) {
        case ONER_V1P1_CSV_ROSTERING_IMPORT_BULK: {
          const model = await createCsvCModel(
            product.id,
            format,
            fileSet as OR1P1Subsets,
          )
          modelId = model.id
          break
        }
        case ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL:
        case ONER_V1P1_REST_CONSUMER_ROSTERING_CORE: {
          const model = await createRestModel(
            product.id,
            format,
            fileSet as OR1P1Subsets,
          )
          modelId = model.id
          break
        }
      }
    }

    if (!modelId) {
      setPending(false)
      showToast(ERROR_TOAST, 'Failed to create model')
    } else {
      props.history.replace(
        getProductModelingRoute(product.id, modelId as number),
      )
    }
  }

  const onCancel = () => props.history.push(getProductRoute(product.id, format))

  const disabledNext =
    (isRosteringCSV && !fileSet) || (isACopy && !selectedModel)
  return (
    <View variant="screen">
      <DocumentTitle title={`Start Modeling - ${product.name}`} />
      <BaseProductDetailsBreadCrumbs
        id={product.id}
        format={format}
        crumbs={[{ name: 'Modeling' }]}
      />
      <View variant="paper">
        <WidgetHeader
          title={getTitle(format, product.name)}
          dataTest="product-modeling-start-header"
        />

        <div className="mt-16 mb-8 w-full">
          <div className="flex flex-row flex-wrap w-full items-center">
            <PromptText>I want to model Class Rosters</PromptText>
            {isRosteringCSV && (
              <StartModelSelect
                name="subset"
                value={fileSet}
                onChange={onSetSubset}
                options={[
                  {
                    label: 'without Demographics',
                    value: OR1P1Subsets.CLASS_ROSTERS_SUBSET,
                  },
                  {
                    label: 'with Demographics',
                    value: OR1P1Subsets.CLASS_ROSTERS_W_DEMOGRAPHICS_SUBSET,
                  },
                ]}
              />
            )}

            <PromptText className={{ 'ml-2': !isRosteringCSV }}>
              as a
            </PromptText>
            {modelsInitialized && models.length < 1 && (
              <PromptText className="ml-2">New Model</PromptText>
            )}
            {modelsInitialized && models.length > 0 && (
              <StartModelSelect
                name="isACopy"
                value={isACopy}
                onChange={onSetIsACopy}
                options={[
                  { label: 'New Model', value: false },
                  { label: 'Copy of an Existing Model', value: true },
                ]}
              />
            )}
          </div>
        </div>

        {isACopy && (
          <div className="mb-16">
            {!modelsInitialized && <Spinner />}
            {modelsInitialized && !searchTouched && models.length < 1 && (
              <PromptText className="mr-4">
                You do not have any existing models to copy...
              </PromptText>
            )}
            {modelsInitialized && (searchTouched || models.length > 0) && (
              <div className="flex flex-row flex-wrap w-full items-center">
                <PromptText className="mr-4">
                  The model I want to copy is
                </PromptText>
                <Dropdown
                  target={
                    <SearchBox
                      ref={searchBox}
                      name="search"
                      placeholder="Search..."
                      value={search}
                      onChange={onSearch}
                      onFocus={onSearchFocus}
                      onBlur={onSearchBlur}
                      onCleared={onClear}
                      itemSelected={!!selectedModel}
                      noIcon={true}
                      aria-label="Search Products"
                      listenForKeyFocus
                    />
                  }
                  constrainTo="window"
                  disableToggleClose={true}
                  data-test="model-product-search"
                >
                  {searchFocused && (
                    <Menu
                      items={models}
                      component={CopyModelSearchRow}
                      onClick={onModelSelected}
                    />
                  )}
                </Dropdown>
              </div>
            )}
          </div>
        )}

        <WizardFooter
          pending={pending}
          buttons={[
            {
              title: 'Next',
              onClick: onNext,
              variant: !disabledNext ? 'complete' : 'neutral',
              disabled: disabledNext,
              dataTest: 'next-btn',
            },
            { title: 'Cancel', onClick: onCancel, variant: 'neutral' },
          ]}
        />
      </View>
    </View>
  )
}
