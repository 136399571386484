import React from 'react'

import { View, Paper, Text, Span } from 'lib/components'

export default function FreemiumLockedComparisonReport() {
  return (
    <Paper p={3} flexible="column" minHeight={300} mt={3} flex={1}>
      <View flex={1} flexible="column-center">
        <Text
          variant="title"
          fontSize={[36, 28]}
          textAlign="center"
          mb={2}
          data-test="comparison-report-locked"
        >
          To access the full report and take full advantage of IMS member
          benefits{' '}
          <a
            href="https://www.imsglobal.org/imsmembership.html"
            target="_blank"
            rel="noopener noreferrer"
            data-test="comparison-report-locked-link"
          >
            <Span
              variant="title"
              color="tertiary"
              fontSize={[36, 28]}
              textAlign="center"
            >
              join IMS
            </Span>
          </a>
        </Text>
      </View>
    </Paper>
  )
}
