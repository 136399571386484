import React from 'react'

import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import {
  View,
  Paper,
  ListCallToActionButton,
  SearchBox,
  Icon,
  Button,
} from 'lib/components'
import NonTableList from 'lib/components/NonTableList'
import { IMS_APPLICATIONS_RECORD } from 'lib/records/modules/imsApplications'
import IMSApplicationRow from 'domains/trustedApps/components/AddTrustedApps/IMSApplicationRow'
import { IAddTrustedAppSelection } from 'domains/trustedApps/components/AddTrustedApps/models/IAddTrustedAppSelection'
import IMSApplicationsFilter from 'domains/trustedApps/components/AddTrustedApps/IMSApplicationsFilter'
import TrustedAppDetailsModal from 'domains/trustedApps/components/TrustedAppDetailsModal'
import useModalState from 'lib/hooks/useModalState'
import BlankSlateWithOption from 'lib/components/BlankSlateWithOption'
import RequestNewAppModal from 'domains/trustedApps/components/RequestNewAppModal'
import { getRecords } from 'lib/records/workflows/getRecords'
import { TRUSTED_APPS_RECORD } from 'lib/records/modules/trustedApplications'

interface IProps {
  onNext: () => any
  onPrevious: () => any
  selection: IAddTrustedAppSelection[]
  addApp: (applicationId: number) => any
  removeApp: (applicationId: number) => any
  updateApp: (applicationId: number, updates: any) => any
}

export default function AddTrustedAppsStepOne(props: IProps) {
  const [requestModelIsOpen, openRequestModal, closeRequestModal] =
    useModalState()
  const [appSubmitted, setAppSubmitted] = React.useState(false)

  const onAppSubmitted = (saved?: boolean) => {
    if (saved) setAppSubmitted(true)
    closeRequestModal()
  }

  const getTARecords = async () => {
    const response = await getRecords({ record: TRUSTED_APPS_RECORD })
    return response
  }

  const [search, setSearch] = React.useState('')
  React.useEffect(() => {
    getTARecords()
    setAppSubmitted(false)
  }, [search])
  const [filter, setFilter] = React.useState('')
  const selectedCount = props.selection.length
  const nextDisabled = selectedCount < 1

  const [infoIsOpen, openInfo, closeInfo] = useModalState()
  const [infoId, setInfoId] = React.useState(0)
  const showInfo = (id: number) => {
    setInfoId(id)
    openInfo()
  }
  const closeInfoModal = () => {
    setTimeout(() => {
      setInfoId(0)
    }, 250)
    closeInfo()
  }
  return (
    <Paper>
      <View flexible="row-space-between-wrap">
        <WidgetHeader title="Step 1: Select Applications" />

        <div className="mt-1 md:mt-0">
          <ListCallToActionButton
            onClick={props.onNext}
            text={`Next Step${
              nextDisabled ? '' : ` (${selectedCount} selected)`
            }`}
            noIcon={true}
            disabled={nextDisabled}
            data-test="next-btn"
          />
        </div>
      </View>

      <div className="mt-3">
        <SearchBox
          name="apps-search"
          value={search}
          onChange={setSearch}
          width="100%"
          placeholder="Search"
          listenForKeyFocus={!requestModelIsOpen}
        />
      </div>
      <div className="mt-3 flex flex-col md:flex-row">
        <IMSApplicationsFilter setFilter={setFilter} />

        <div className="flex flex-1 ml-3 mt-2 md:mt-0">
          <NonTableList
            record={IMS_APPLICATIONS_RECORD}
            secondaryRecord={TRUSTED_APPS_RECORD}
            RowComponent={IMSApplicationRow}
            usePageBottomListener={true}
            noDivider={true}
            query={search}
            searchTemplate="product_name=~@@ OR org_name=~@@"
            filter={filter}
            rowData={{
              selection: props.selection,
              addApp: props.addApp,
              removeApp: props.removeApp,
              showInfo,
            }}
            blankSlate={
              <BlankSlateWithOption
                icon={
                  <Icon className="fas fa-book text-4xl" color="textLight" />
                }
                title={
                  appSubmitted
                    ? 'Thank you for suggesting a new application to be added to the directory.  Your request will go into a queue for 1EdTech to evaluate.  Evaluation of applications will take some time for us to complete.  We appreciate your patience.'
                    : 'No applications found'
                }
                subtitle={
                  appSubmitted
                    ? undefined
                    : 'If you need this application in your TrustEd Apps list, please submit a new application for 1EdTech to review'
                }
              >
                {appSubmitted && <div />}
                {!appSubmitted && (
                  <Button
                    py={2}
                    px="28px"
                    height="42px"
                    onClick={openRequestModal}
                    variant="complete"
                    data-test="request-app-btn"
                  >
                    <div className="flex flex-row items-center">
                      <Icon className="fas fa-plus-square" color="white" />
                      <p className="text-white ml-2">
                        Request new application called &quot;{search}&quot;
                      </p>
                    </div>
                  </Button>
                )}
              </BlankSlateWithOption>
            }
          />
        </div>
      </div>

      <TrustedAppDetailsModal
        id={infoId}
        isOpen={infoIsOpen}
        onRequestClose={closeInfoModal}
        addApp={props.addApp}
      />

      <RequestNewAppModal
        applicationName={search}
        isOpen={requestModelIsOpen}
        onRequestClose={onAppSubmitted}
      />
    </Paper>
  )
}
