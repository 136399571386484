import { INewCharacterization } from 'domains/characterizations/models/INewCharacterization'
import { createRecord } from 'lib/records/workflows/createRecord'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import store from 'lib/store'
import trackCharacterizationAnalytics from 'domains/characterizations/utils/trackCharacterizationAnalytics'
import { OR_REST_PUSH_CHARACTERIZATIONS_RECORD } from 'lib/records/modules/orRESTPushCharacterizations'
import ICharacterization from 'domains/characterizations/models/ICharacterization'

export const createCharacterizationRESTPush = async (
  characterization: INewCharacterization,
): Promise<false | ICharacterization> => {
  const orgId = myOrganizationIdSelector(store.getState(), {})
  const { data, success } = await createRecord(
    OR_REST_PUSH_CHARACTERIZATIONS_RECORD,
    {
      ...characterization,
      orgId,
    },
  )
  trackCharacterizationAnalytics('submitted_characterization', {
    specification: characterization.format,
    source: characterization.source,
    otherSource: characterization.otherSource,
  })

  return success ? (data as ICharacterization) : success
}
