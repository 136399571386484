import React from 'react'

import { View, Text, ExpansionPanel } from 'lib/components'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import ITransformedCharacterizationCSVReport from 'domains/characterizations/models/ITransformedCharacterizationCSVReport'
import IAccordionHeaderState from 'domains/characterizations/models/IAccordionHeaderState'
import ICSVSpecification from 'domains/specifications/models/ICSVSpecification'
import ICSVConsumerModel from 'domains/modeling/models/ICSVConsumerModel'
import ModelReportDetails from 'domains/modeling/components/ModelReportDetails'
import ReportExpansionHeader from 'domains/reports/components/ReportExpansionHeader'
import { getCharacterizationReportFileStatus } from 'domains/characterizations/utils/characterizationReport'
import CharacterizationFileReport from 'domains/characterizations/components/CharacterizationReport/CharacterizationFileReport'

interface IProps {
  report: ITransformedCharacterizationCSVReport
  specification: ICSVSpecification
  fileStates: IAccordionHeaderState[]
  onFileRequestChange: (name: string) => void
  model?: ICSVConsumerModel
}

export default function CSVCharacterizationReportFiles(props: IProps) {
  const onFileRequestChange = (name: string) => () =>
    props.onFileRequestChange(name)

  const renderFile = (file: IAccordionHeaderState) => (
    <ExpansionPanel
      key={file.name}
      isOpen={file.open}
      onRequestChange={onFileRequestChange(file.name)}
      summary={
        <ReportExpansionHeader
          status={getCharacterizationReportFileStatus(file.name, props.report)}
          name={file.name}
          open={file.open}
        />
      }
      details={
        <CharacterizationFileReport
          isOpen={file.open}
          fileLabel={file.name}
          report={props.report}
          specification={props.specification}
          model={props.model}
        />
      }
    />
  )

  if (props.report.files.length < 1) {
    return null
  }

  return (
    <View>
      <WidgetHeader
        dataTest="characterization-report-files"
        title={
          props.report.hasFileErrors
            ? 'File Errors'
            : 'Characterization Report Details'
        }
      />
      <ModelReportDetails model={props.model} />

      <View my={3}>
        {props.report.hasFileErrors && (
          <Text fontStyle="italic">
            A maximum of 20 critical errors will be shown per file. Please
            resolve and re-characterize to see any additional errors.
          </Text>
        )}
      </View>

      {props.fileStates.map(renderFile)}
    </View>
  )
}
