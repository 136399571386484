import React from 'react'
import { utils } from '@ims/1edtech-frontend-common'

import { View, Icon, Text, Dropdown, Menu } from 'lib/components'
import {
  NOTIFICATION_EVENT_TO_ICON_MAP,
  NOTIFICATION_EVENT_TO_ICON_COLOR_MAP,
} from 'domains/notifications/utils/notificationUtils'
import INotification from 'domains/notifications/models/INotification'
import { CHARACTERIZATION_COMPLETED_NOTIFICATION } from 'domains/notifications/constants/notifications'
import NotificationMessage from 'domains/notifications/components/NotificationMessage'

interface IProps {
  index: number
  notification: INotification
  onClickNotification?: () => any
  onMarkAsRead?: (e?: any) => any
  onDelete?: (e?: any) => any
  onClick?: (e?: any) => any
  onMenuItemClicked?: (item: any) => void
}

export default function NotifcationRowComponent(props: IProps) {
  return (
    <View
      minHeight={64}
      pl={3}
      pr={2}
      py={2}
      display="grid"
      gridTemplateColumns="48px 1fr 12px"
      gridColumnGap={2}
      cursor={props.onClickNotification ? 'pointer' : 'default'}
      hover={props.onClickNotification ? 'clickable-bg' : undefined}
      onClick={props.onClickNotification ? props.onClick : undefined}
      bg={props.notification.read ? 'white' : 'background'}
      data-test={`notification-${props.index}`}
    >
      <View flexible="column-center">
        <Icon
          className={NOTIFICATION_EVENT_TO_ICON_MAP[props.notification.event]}
          color={NOTIFICATION_EVENT_TO_ICON_COLOR_MAP[props.notification.event]}
          fontSize={
            props.notification.event === CHARACTERIZATION_COMPLETED_NOTIFICATION
              ? 38
              : 26
          }
        />
      </View>

      <View flexible="column" pt={2}>
        <View flexible="row-v-center" mb={1} height={18}>
          <Text variant="caption-small" fontWeight={700}>
            {utils.date.formatForDisplay(props.notification.createdTime)}
          </Text>

          {!props.notification.read && (
            <View
              px="6px"
              py="2px"
              flexible="column-center"
              borderRadius="2px"
              ml={2}
              bg="start"
            >
              <Text variant="caption-small" fontWeight={700} color="white">
                NEW
              </Text>
            </View>
          )}
        </View>

        <NotificationMessage
          notification={props.notification}
          fontSize={12}
          lineHeight="16px"
        />
      </View>

      {props.onMenuItemClicked && (
        <Dropdown
          target={
            <View
              data-test={`notification-options-${props.index}`}
              flexible="column-center"
              height="100%"
              width="100%"
            >
              <Icon className="fas fa-ellipsis-v" fontSize={14} />
            </View>
          }
          targetProps={{ id: 'notification-row-menu' }}
          showChevron={false}
        >
          <Menu
            onClick={props.onMenuItemClicked}
            items={[
              {
                name: 'Mark as Read',
                icon: 'fas fa-check-double',
                dataTest: 'notification-mark-as-read',
              },
              {
                name: 'Delete',
                icon: 'fas fa-trash',
                dataTest: 'notification-delete',
              },
            ]}
          />
        </Dropdown>
      )}
    </View>
  )
}
