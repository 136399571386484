import React from 'react'
import * as R from 'ramda'
import { utils } from '@ims/1edtech-frontend-common'

import useAccordionHeaderState from 'domains/reports/hooks/useAccordionHeaderState'
import { ExpansionPanel, View } from 'lib/components'
import CCCharacterizationReportRow from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportRow'
import { CCReportTypeIcon } from 'domains/commonCartridge/constants/commonCartridge'
import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'

interface IProps {
  title: string
  Component: any
  data?: any[]
}

export default function CCCharacterizationReportLOMKeyDropdowns(props: IProps) {
  const { showMissingMetadata } = React.useContext(ShowMissingMetadataContext)
  const items = R.pathOr([], ['data'], props).map((e, i) => ({
    name: `${i + 1}`,
  }))
  const [headerStates, , onToggleHeader] = useAccordionHeaderState(
    utils.hasValue(items) ? items : showMissingMetadata ? [{ name: '1' }] : [],
  )

  if (!utils.hasValue(props.data) && !showMissingMetadata) {
    return null
  }

  const onToggleKey = (key: string) => () => onToggleHeader(key)

  return (
    <View ml={3}>
      {headerStates.map((headerState, index) => {
        const data = R.pathOr({}, [headerState.name], props.data) as any
        return (
          <ExpansionPanel
            key={`${headerState.name}-${index}`}
            isOpen={headerState.open}
            onRequestChange={onToggleKey(headerState.name)}
            summary={
              <View mt={3}>
                <CCCharacterizationReportRow
                  type="Metadata"
                  id={`${props.title} [${headerState.name}]`}
                  bg="ccReportMetadata"
                  title={`${props.title} [${headerState.name}]`}
                  icon={CCReportTypeIcon.Metadata}
                  isExpanded={headerState.open}
                  isMissing={showMissingMetadata && !utils.hasValue(data)}
                />
              </View>
            }
            details={
              <View p={2} pl={3}>
                <props.Component
                  data={props.data![index]}
                  path={`lom.${props.title}.${
                    utils.convertToInt(headerState.name) - 1
                  }`}
                />
              </View>
            }
          />
        )
      })}
    </View>
  )
}
