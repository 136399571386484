import {
  ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL,
  ONER_V1P1_CSV_ROSTERING_IMPORT_BULK,
} from 'domains/formats/constants/formats'
import SupplierOR1P1CsvCharacterizationReportScreen from 'domains/characterizations/screens/SupplierOR1P1CsvCharacterizationReportScreen'
import SupplierOR1P1RestCharacterizationReportScreen from 'domains/characterizations/screens/SupplierOR1P1RestCharacterizationReportScreen'
import useFormatScreen from 'lib/hooks/useFormatScreen'

export default function SupplierModelCharacterizationReportScreen(props: any) {
  return useFormatScreen({
    ...props,
    map: {
      [ONER_V1P1_CSV_ROSTERING_IMPORT_BULK]: SupplierOR1P1CsvCharacterizationReportScreen,
      [ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL]: SupplierOR1P1RestCharacterizationReportScreen,
    },
  })
}
