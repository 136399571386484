import * as R from 'ramda'
import { createSelector } from 'reselect'
import { utils } from '@ims/1edtech-frontend-common'

import {
  SUPPLIER_ROLE,
  DISTRICT_ROLE,
} from 'domains/authentication/constants/roles'
import { applicationPropSelector } from 'domains/application/selectors/application'
import { RootState } from 'lib/store/rootReducer'
import { meSelector } from 'domains/authentication/selectors/me'
import IUser from 'domains/users/models/IUser'
import { ADMIN_ROLE } from 'domains/users/constants/userRoles'

export const hasRoleSelector = (role: string) =>
  createSelector<RootState, string, boolean>(
    applicationPropSelector('role'),
    R.equals(role),
  )

export const isSupplierRoleSelector = hasRoleSelector(SUPPLIER_ROLE)

export const isDistrictRoleSelector = hasRoleSelector(DISTRICT_ROLE)

export const isOrgAdminSelector = createSelector<
  RootState,
  IUser | null,
  boolean
>(meSelector, (me) => {
  const isIMS = R.propEq('ims', true, me as any)
  const isOrgAdmin = utils.array
    .ensureArray(R.pathOr([], ['userRole'], me as any))
    .includes(ADMIN_ROLE)
  return isIMS || isOrgAdmin
})

export const isOrgAdminRegardlessOfIMSUserSelector = createSelector<
  RootState,
  IUser | null,
  boolean
>(meSelector, (me) => R.propEq('admin', true, me as any))

export const isIMSAdminRoleSelector = createSelector<
  RootState,
  IUser | null,
  boolean,
  boolean,
  boolean
>(
  meSelector,
  isSupplierRoleSelector,
  isDistrictRoleSelector,
  (me: IUser | null, isSupplier: boolean, isDistrict: boolean) =>
    !!me && me.ims === true && !isSupplier && !isDistrict,
)
