import React, { PureComponent } from 'react'

import { View, ToolTip, Text } from 'lib/components'
import CharacterizationIcon from 'domains/characterizations/components/CharacterizationIcon'
import IComparison from 'domains/comparisons/models/IComparison'

interface IProps extends IComparison {
  maxWidth?: string
  maxWidthArray?: (string | number)[]
}

export default class ComparisonCharacterizationNameCell extends PureComponent<IProps> {
  render() {
    const {
      id,
      characterizationName,
      characterizationColor,
      maxWidth = 320,
      maxWidthArray,
    } = this.props

    const tooltipId = `${id}-name`
    return (
      <View flexible="row-v-center" pl={2} ml={'-8px'}>
        <CharacterizationIcon
          color={characterizationColor}
          size={50}
          noResponsive={true}
        />
        <ToolTip id={tooltipId} tip={characterizationName} delay={250}>
          <Text
            pl={2}
            textAlign="left"
            overflow="ellipsis"
            maxWidth={maxWidthArray || maxWidth}
            fontSize={13}
            data-test={`${id}-characterization-name`}
          >
            {characterizationName}
          </Text>
        </ToolTip>
      </View>
    )
  }
}
