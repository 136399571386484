import { utils } from '@ims/1edtech-frontend-common'

import { getRecord } from 'lib/records/workflows/getRecord'

export const waitForServer = async (
  record: string,
  id: string | number,
  isComplete: (data: Record<string, unknown>) => boolean,
  wait = 3000,
  maxAttempts = 600,
): Promise<[boolean, boolean, any]> =>
  new Promise(async (resolve) => {
    let responseData: any = {}
    let attempts = 0
    while (!isComplete(responseData) && attempts <= maxAttempts) {
      await utils.wait.waitFor(wait)

      const { success, data } = await getRecord(record, id)

      if (!success) {
        resolve([false, isComplete(data), responseData])
        return
      }
      responseData = data
      attempts++
    }

    resolve([attempts >= maxAttempts, isComplete(responseData), responseData])
  })
