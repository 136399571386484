import React, { PureComponent } from 'react'

import { Icon, Text, View } from 'lib/components'
import { FlexibleOption } from 'lib/components/View'
interface IProps {
  flexible?: FlexibleOption
  icon: string
  iconColor: string
  isMaterialIcon?: boolean
  title: string | number
  subtitle: string
  tertiaryTitle?: string
  fadeDelay?: number
  dataTest?: string
}

export default class SmallWidget extends PureComponent<IProps> {
  render() {
    const {
      flexible = 'column-center',
      icon,
      iconColor,
      isMaterialIcon,
      title,
      subtitle,
      tertiaryTitle,
      fadeDelay = 0,
      dataTest,
    } = this.props
    return (
      <View animation="fadeIn" animationDelay={fadeDelay} flexible={flexible}>
        <View
          variant="paper"
          flexible="row-v-center"
          boxSizing="border-box"
          width="100%"
          minHeight="160px"
          my={2}
          hover="elevation-two"
          data-test={dataTest}
        >
          {isMaterialIcon && (
            <Icon
              className="material-icons"
              color={iconColor}
              fontSize={48}
              mr={3}
            >
              {icon}
            </Icon>
          )}
          {!isMaterialIcon && (
            <Icon className={icon} color={iconColor} fontSize="48px" mr={3} />
          )}

          <View height="56px" width="1px" bg="neutral" mr={4} />

          <View>
            <Text
              variant="jumbo"
              fontWeight={400}
              data-test={`${dataTest}-title`}
            >
              {title}
            </Text>
            <Text
              color="textLight"
              fontWeight={400}
              data-test={`${dataTest}-subtitle`}
            >
              {subtitle}
            </Text>
            <Text
              variant="caption"
              color="text"
              fontWeight={700}
              data-test={`${dataTest}-tertiary-title`}
            >
              {tertiaryTitle}
            </Text>
          </View>
        </View>
      </View>
    )
  }
}
