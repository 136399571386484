import React from 'react'

import { Screen } from 'lib/components'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import BaseBreadCrumbs from 'domains/application/components/BaseBreadCrumbs'
import TrustedAppsList from 'domains/trustedApps/components/TrustedAppsList'

export default function TrustedAppsDashboardScreen() {
  return (
    <Screen>
      <DocumentTitle title="TrustEd Apps" />
      <BaseBreadCrumbs crumbs={[{ name: 'TrustEd Apps' }]} />

      <TrustedAppsList />
    </Screen>
  )
}
