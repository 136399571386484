import * as normalizr from 'normalizr'
import { records } from '@ims/1edtech-frontend-common'

export const REST_COMPARISON_REPORTS_RECORD = 'restComparisonReports'

const restComparisonReports: records.modules.IModule = {
  record: REST_COMPARISON_REPORTS_RECORD,
  noResponseDataKey: true,
  schema: new normalizr.schema.Entity(
    REST_COMPARISON_REPORTS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(['comparisonId']),
  ),
  api: {
    create: () => 'comparisons/rest/report',
    get: (id) => `comparisons/rest/report/${id}`,
    getList: (_, orgId) => `districts/${orgId}/comparisons`,
    update: (id) => `comparisons/rest/report/${id}`,
    delete: (id) => `comparisons/rest/report/${id}`,
  },
}

export default restComparisonReports
