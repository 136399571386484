import React from 'react'
import browser from 'browser-detect'
import { utils, records } from '@ims/1edtech-frontend-common'
import * as R from 'ramda'

import { NotificationPreference } from 'domains/users/models/IUserPreferences'
import { getRequest } from 'lib/api/api'
import trackSessionAnalytics from 'domains/application/utils/trackSessionAnalytics'
import INotification from 'domains/notifications/models/INotification'
import { APPLICATION_NAME } from 'domains/application/constants/application'
import { showToast } from 'lib/utils/toast'
import NotificationMessage from 'domains/notifications/components/NotificationMessage'
import { NOTIFICATION_EVENT_TO_ICON_MAP } from 'domains/notifications/utils/notificationUtils'
import {
  getOnClickNotificationAction,
  getNotificationRoute,
} from 'domains/notifications/workflows/getOnClickNotificationAction'
import { getNotificationCount } from 'domains/notifications/workflows/getNotificationCount'
import { applicationPropSelector } from 'domains/application/selectors/application'
import store from 'lib/store'
import { CHARACTERIZATION_COMPLETED_NOTIFICATION } from 'domains/notifications/constants/notifications'
import { markNotificationAsRead } from 'domains/notifications/workflows/markNotificationAsRead'
import { mergeOrReplaceEntities } from 'lib/records/workflows/entities'
import notificationsModule, {
  NOTIFICATIONS_RECORD,
} from 'lib/records/modules/notifications'
import { history } from 'lib/utils/navigation'

const isDev = process.env.NODE_ENV === 'development'

const notificationIcon = `${R.pathOr(
  'N/A',
  ['location', 'origin'],
  window,
)}/android-chrome-192x192.png`

const NOTIFICATION_CHECK_INTERVAL = isDev ? 3000 : 120000
const NOTIFICATION_INTERVAL = 1500
let interval: any

let tempNotification: INotification | null = null
export const setTempNotification = (notification: INotification) => {
  tempNotification = notification
}

export const cancelNotificationListener = () => clearInterval(interval)

export const listenForNotifications = async (
  preference: NotificationPreference,
) => {
    if(process.env.NODE_ENV !== 'production')
        return
  cancelNotificationListener()
  await getNotificationCount()
  const notCI = !process.env.CI
  // @ts-ignore
  const notRunningInCypress = !window.Cypress
  // @ts-ignore
  if (notCI && notRunningInCypress) {
    if (preference === 'ALERTS') {
      interval = setInterval(
        onFetchUnreadNotifications,
        NOTIFICATION_CHECK_INTERVAL,
      )
    } else {
      interval = setInterval(getNotificationCount, NOTIFICATION_CHECK_INTERVAL)
    }
  }
}

const checkNotificationPromise = () => {
  try {
    Notification.requestPermission().then()
  } catch (e) {
    return false
  }

  return true
}

export const isNotificationsEnabled = () =>
  new Promise(async (resolve) => {
    if (!window.Notification) {
      const browserInfo = browser()
      trackSessionAnalytics('browser_no_notification_support', browserInfo)
      console.log('Browser does not support notifications', browserInfo) // eslint-disable-line
      resolve(false)
      return
    }

    const resolveWithPermission = (permission: NotificationPermission) => {
      resolve(permission === 'granted')
    }
    if (checkNotificationPromise()) {
      Notification.requestPermission().then(resolveWithPermission)
    } else {
      Notification.requestPermission(resolveWithPermission)
    }
  })

let lastCheckTime = Date.now()
export const onFetchUnreadNotifications = async () => {
  if (isDev) {
    cancelNotificationListener()
  }
  await getNotificationCount()
  const meId = applicationPropSelector('me')(store.getState())
  let newNotificationsRequest = await getRequest(
    `users/${meId}/notifications?filter=read=false AND createdTime>=${lastCheckTime}`,
  )
  lastCheckTime = Date.now()
  if (tempNotification) {
    const data = [
      ...R.pathOr([], ['data'], newNotificationsRequest),
      tempNotification,
    ]
    newNotificationsRequest = {
      ...newNotificationsRequest,
      success: true,
      data,
    }
    const items = mergeOrReplaceEntities(true, notificationsModule, data, true)
    store.dispatch(
      records.recordActions.mergeRecordItems(notificationsModule.record, items),
    )
    tempNotification = null
  }

  if (!newNotificationsRequest.success) {
    return
  }

  const notificationsEnabled = await isNotificationsEnabled()
  const notifications = utils.array.ensureArray(
    newNotificationsRequest.data,
  ) as INotification[]
  notifications.forEach(async (notification, index) => {
    // Do not show notifications if they are already viewing the data
    if (notification.event === CHARACTERIZATION_COMPLETED_NOTIFICATION) {
      const route = await getNotificationRoute(notification)
      if (history.location.pathname === route) {
        await markNotificationAsRead(notification.id)
        return
      }
    }

    if (notificationsEnabled) {
      setTimeout(() => {
        const browserNotification = new Notification(APPLICATION_NAME, {
          body: notification.message,
          icon: notificationIcon,
        })
        browserNotification.onclick = getOnClickNotificationAction(
          notification,
          false,
          browserNotification,
        )!
      }, NOTIFICATION_INTERVAL * index)
    } else {
      showToast(
        'info',
        <NotificationMessage
          color="white"
          opacity={0.9}
          fontSize={13}
          notification={notification as INotification}
        />,
        {
          onClick: getOnClickNotificationAction(notification, false),
          iconMap: NOTIFICATION_EVENT_TO_ICON_MAP,
          iconType: notification.event,
        },
      )
    }
  })

  if (notifications.length > 0) {
    const items = mergeOrReplaceEntities(
      true,
      notificationsModule,
      notifications,
      true,
    )
    store.dispatch(
      records.recordActions.mergeRecordItems(NOTIFICATIONS_RECORD, items),
    )
  }
}
