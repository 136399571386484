import * as R from 'ramda'

import { INewCharacterization } from 'domains/characterizations/models/INewCharacterization'
import {
  AnySpecFormatType,
  FORMAT_TO_SPECIFICATION_NAME,
  FORMAT_TO_SPECIFICATION_VERSION,
} from 'domains/formats/constants/formats'
import { utils } from '@ims/1edtech-frontend-common'
import { OAUTH_2_REST_AUTH } from 'domains/formats/constants/OneRosterREST'

export const prepareRESTCharacterizationForServer = (
  data: INewCharacterization,
) => {
  let final: any = R.compose<
    INewCharacterization,
    any,
    any,
    any,
    any,
    any,
    any
  >(
    R.assoc('organization', {
      id: R.prop('orgId', data),
    }),
    R.assoc(
      'specificationName',
      FORMAT_TO_SPECIFICATION_NAME[data.format! as AnySpecFormatType],
    ),
    R.assoc(
      'specificationVersion',
      FORMAT_TO_SPECIFICATION_VERSION[data.format! as AnySpecFormatType],
    ),
    R.assoc('type', 'delta'),
    R.pick([
      'color',
      'notes',
      'label',
      'source',
      'sourceVersion',
      'certifiedSourceLevel',
      'districtName',
      'districtState',
      'districtCountry',
      'format',
      'hostName',
      'consumerKey',
      'consumerSecret',
    ]),
    (d) => {
      let newData = { ...d }
      const optionals = [
        'sourceVersion',
        'districtName',
        'districtState',
        'districtCountry',
      ]
      optionals.forEach((key) => {
        if (!utils.hasValue(R.pathOr(false, [key], newData))) {
          newData = R.dissoc(key, newData)
        }
      })
      return newData
    },
  )(data)

  if (utils.hasValue(data.otherSource)) {
    final = R.assoc('source', data.otherSource, final)
  }

  if (data.restAuthMethod === OAUTH_2_REST_AUTH) {
    final = R.assoc('oauth2server', data.oauth2server, final)
  }

  return final
}
