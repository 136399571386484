import { records } from '@ims/1edtech-frontend-common'
import { createSelector } from 'reselect'

import { RootState } from 'lib/store/rootReducer'
import { isSupplierRoleSelector } from 'domains/authentication/selectors/roles'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'

export const productsListSelector = createSelector<
  RootState,
  any,
  boolean,
  records.recordReducers.IRecordState,
  records.recordReducers.IRecordState,
  records.recordReducers.IRecordState
>(
  isSupplierRoleSelector,
  records.relatedRecordsSelectors.fullRelatedRecordsSelector(
    PRODUCTS_RECORD,
    myOrganizationIdSelector,
  ),
  records.recordsSelectors.fullRecordsSelector(PRODUCTS_RECORD),
  (isSupplier, supplierProducts, districtProducts) =>
    isSupplier ? supplierProducts : districtProducts,
)
