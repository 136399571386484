import React from 'react'
import { useSelector } from 'react-redux'
import { utils, records } from '@ims/1edtech-frontend-common'

import { View } from 'lib/components'
import ICharacterization from 'domains/characterizations/models/ICharacterization'
import { COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_STANDARDS_RECORD } from 'lib/records/modules/commonCartridgeCharacterizationReportStandards'
import { ICommonCartridgeStandard } from 'domains/commonCartridge/models/ICommonCartridgeStandard'
import CCCharacterizationReportStandardRow from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportStandardRow'

interface IProps {
  id: number
  characterization: ICharacterization
}

export default function CCCharacterizationReportTableStandardRow(
  props: IProps,
) {
  const standard = useSelector(
    records.entitiesSelectors.entityByIdSelector(
      COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_STANDARDS_RECORD,
      utils.convertToInt(props.id),
    ),
  ) as ICommonCartridgeStandard

  return (
    <View mb={2}>
      <CCCharacterizationReportStandardRow
        characterization={props.characterization}
        standard={standard}
      />
    </View>
  )
}
