import React from 'react'

import SmallWidget from 'domains/dashboard/components/SmallWidget'

interface IProps {
  productCount: number
  fadeDelay: number
}

export default function TotalProductsWidget(props: IProps) {
  return (
    <SmallWidget
      fadeDelay={props.fadeDelay}
      icon="fas fa-box-open"
      iconColor="#009de3"
      title={props.productCount}
      subtitle="Total Number of Products"
      dataTest="total-products-widget"
    />
  )
}
