import React from 'react'

import { Text, View, Icon } from 'lib/components'
import CCCharacterizationReportDetailsHeaderDetails, {
  ICCCharacterizationReportDetailsHeaderDetailsProps,
} from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/CCCharacterizationReportDetailsHeaderDetails'

interface IProps {
  titleBg: string
  bg: string
  details: ICCCharacterizationReportDetailsHeaderDetailsProps[]
  icon: string
  title: string
  rightTitle?: any
  children?: any
}

export default function CCCharacterizationReportDetailsHeader(props: IProps) {
  return (
    <div className="mt-3">
      <View
        bg={props.titleBg}
        className="flex flex-row items-center justifiy-between p-4"
      >
        <div className="flex flex-1 flex-row items-center">
          <Icon className={props.icon} fontSize={20} mr={2} />
          <Text variant="subtitle" fontWeight={700}>
            {props.title}
          </Text>
        </div>

        {props.rightTitle}
      </View>

      <View bg={props.bg} py={2} px={3} borderRadius="0 0 6px 6px">
        {props.details.map((detail, index) => (
          <CCCharacterizationReportDetailsHeaderDetails
            key={`detail-${index}`}
            {...detail}
          />
        ))}

        {props.children}
      </View>
    </div>
  )
}
