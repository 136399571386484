import React from 'react'
import clsx from 'clsx'

import { ToolTip } from 'lib/components'

type Props = {
  className?: any
}

export default function TrustEdAppsSeal(props: Props) {
  return (
    <ToolTip
      delay={200}
      tip={
        <div className="h-44 flex flex-row items-center">
          <img
            alt="TrustEd Apps Seal"
            src="/images/TrustedAppsIcon.svg"
            className="w-auto h-full"
          />
        </div>
      }
    >
      <img
        alt="TrustEd Apps Seal"
        src="/images/TrustedAppsIcon.svg"
        className={clsx(
          {
            'w-6 h-6': !props.className,
          },
          props.className,
        )}
      />
    </ToolTip>
  )
}
