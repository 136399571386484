import React from 'react'
import clsx from 'clsx'
import * as R from 'ramda'

import { Checkbox } from 'lib/components'
import {
  getFilterString,
  IIMSApplicationFilter,
  IIMSApplicationParentFilter,
} from 'domains/trustedApps/components/AddTrustedApps/IMSApplicationsFilter/IIMSApplicationsFilter'
import { PARENT_FILTER_PREFIX } from './IIMSApplicationsFilter'

interface ICheckboxFilterProps {
  filter: IIMSApplicationFilter
  appliedFilters: string[]
  parentFilter?: IIMSApplicationParentFilter
  isChild?: boolean
  onChange: (event: React.FormEvent<HTMLInputElement>) => any
}
export default function IMSApplicationCheckboxFilter(
  props: ICheckboxFilterProps,
) {
  const isParent = !!props.parentFilter
  let childrenChecked = 0
  if (isParent) {
    const parentFilter = props.parentFilter as IIMSApplicationParentFilter
    parentFilter.children.forEach((f) => {
      if (R.contains(getFilterString(f), props.appliedFilters)) {
        childrenChecked++
      }
    })
  }
  const checked = isParent
    ? childrenChecked === props.parentFilter?.children.length
    : R.contains(getFilterString(props.filter), props.appliedFilters)

  return (
    <div
      className={clsx('flex flex-row items-center', {
        'ml-2': props.isChild,
        'mb-2': isParent,
        'mb-1': !isParent,
      })}
    >
      <Checkbox
        minHeight={26}
        checked={checked}
        isIndeterminate={!checked && childrenChecked > 0}
        name={`${isParent ? PARENT_FILTER_PREFIX : ''}${props.filter.label}`}
        data-parent={isParent}
        onChange={props.onChange}
        data-test={`${props.filter.label}-checkbox`}
      >
        <p className="text-xs">{props.filter.label}</p>
      </Checkbox>
    </div>
  )
}
