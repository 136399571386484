import React from 'react'

import { Image } from 'lib/components'

interface IProps {
  size?: number
}

export default function PreferredPartnerBadge(props: IProps) {
  const { size = 40 } = props

  return (
    <Image
      src="/images/preferred-partner-badge.png"
      width={size}
      height={size}
    />
  )
}
