import {
  DEFAULT_TA_FILTER_STATE,
  TrustedAppsAdvancedSearchFilter,
} from 'domains/trustedApps/components/TrustedAppsAdvancedSearchModal'
import { useState } from 'react'

let cache = DEFAULT_TA_FILTER_STATE

export const clearTrustedAppsFilter = () => {
  cache = DEFAULT_TA_FILTER_STATE
}

export const useTrustedAppsFilter = () => {
  const [_filter, _setFilter] = useState<TrustedAppsAdvancedSearchFilter>(cache)

  const setFilter = (filter: TrustedAppsAdvancedSearchFilter) => {
    cache = filter
    _setFilter(filter)
  }

  return [_filter, setFilter] as const
}
