import * as normalizr from 'normalizr'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

export const COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_STANDARDS_RECORD =
  'commonCartridgeCharacterizationReportStandards'

const commonCartridgeCharacterizationReportStandards: records.modules.IModule = {
  record: COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_STANDARDS_RECORD,
  schema: new normalizr.schema.Entity(
    COMMON_CARTRIDGE_CHARACTERIZATION_REPORT_STANDARDS_RECORD,
    {},
    records.modules.utils.getSchemaOptions(),
  ),
  api: {
    create: () => '',
    get: (id) => `ccsys/standards/${id}`,
    getList: (params, parentId) => {
      const isCartridge = R.propOr(false, 'isCartridge', params)
      if (isCartridge) {
        return `ccsys/cartridges/${parentId}/standards`
      }
      return `ccsys/resources/${parentId}/standards`
    },
    update: () => '',
    delete: () => '',
  },
}

export default commonCartridgeCharacterizationReportStandards
