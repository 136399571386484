import React, { PureComponent } from 'react'
import { createStructuredSelector } from 'reselect'
import { records } from '@ims/1edtech-frontend-common'

import { View, InputLabel, Switch } from 'lib/components'
import BaseBreadCrumbs from 'domains/application/components/BaseBreadCrumbs'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import { ORGANIZATIONS_RECORD } from 'lib/records/modules/organizations'
import { myOrganizationIdSelector } from 'domains/authentication/selectors/organizations'
import { RootState } from 'lib/store/rootReducer'
import { connect } from 'react-redux'
import { setOrgComparisonSharingPref } from 'domains/orgs/workflows/setOrgComparisonSharingPref'
import ReportThreeTopSection from 'domains/reports/components/ReportThreeTopSection'

interface IPropsFromState {
  orgId: number
  shareComparisons: boolean
}

const stateMap = createStructuredSelector<RootState, any, IPropsFromState>({
  orgId: myOrganizationIdSelector,
  shareComparisons: records.entitiesSelectors.entityPropSelector(
    ORGANIZATIONS_RECORD,
    myOrganizationIdSelector,
    'shareComparisons',
    false,
  ),
})

const initialState = {
  pending: false,
}
type IState = Readonly<typeof initialState>

export class OrganizationPreferencesScreen extends PureComponent<IPropsFromState> {
  readonly state: IState = initialState

  onToggleShareComparisons = async () => {
    await setOrgComparisonSharingPref(
      this.props.orgId,
      !this.props.shareComparisons,
    )
  }

  render() {
    const { shareComparisons } = this.props

    return (
      <View variant="screen">
        <DocumentTitle title="Organization" />
        <BaseBreadCrumbs crumbs={[{ name: 'Organization Preferences' }]} />

        <ReportThreeTopSection>
          <DetailsTopWidget
            title="Comparisons"
            dataTest="comparisons-preferences"
            pending={this.state.pending}
          >
            <View my={3}>
              <InputLabel data-test="share-comparisons-label">
                Share comparisons with suppliers by default?
              </InputLabel>
              <View mt={3} />
              <Switch
                on={shareComparisons}
                onChange={this.onToggleShareComparisons}
                dataTest="share-comparisons-toggle"
                onIconName="fas fa-eye"
                offIconName="fas fa-eye-slash"
              />
            </View>
          </DetailsTopWidget>
        </ReportThreeTopSection>
      </View>
    )
  }
}

export default connect(stateMap)(OrganizationPreferencesScreen)
