import React from 'react'
import { useSelector } from 'react-redux'
import { records } from '@ims/1edtech-frontend-common'

import { TRUSTED_APPS_SHARED_WITH_ORGS_RECORD } from 'lib/records/modules/trustedAppsSharedWithOrgs'
import TrustedAppLogo from 'domains/trustedApps/components/TrustedAppsList/TrustedAppLogo'
import { Button } from 'lib/components'

type Props = {
  id: any
  onDeleteShare: (district: any) => Promise<any>
}

export default function TrustedAppsSharedWithOrgRow(props: Props) {
  const id = parseInt(props.id)
  const district = useSelector(
    records.entitiesSelectors.entityByIdSelector(
      TRUSTED_APPS_SHARED_WITH_ORGS_RECORD,
      id,
    ),
  ) as any

  const [pending, setPending] = React.useState(false)
  const [confirmDelete, setConfirmDelete] = React.useState(false)
  const onDelete = () => setConfirmDelete(true)
  const onCancelDelete = () => setConfirmDelete(false)

  const onDeleteComfirmed = async () => {
    setPending(true)
    await props.onDeleteShare(district)
    setConfirmDelete(false)
    setPending(false)
  }

  if (!district) return null

  const baseDataTest = `shared-with-${district.viewerOrgName}`
  return (
    <div
      className="flex flex-row items-center justify-between p-2 bg-background mb-2"
      data-test={baseDataTest}
    >
      <div className="flex flex-row items-center space-x-4">
        <TrustedAppLogo
          title={district.viewerOrgName}
          logo={district.viewerOrgLogoUrl}
        />
        <p className="text-gray-700 font-bold text-xl">
          {district.viewerOrgName}
        </p>
      </div>

      {confirmDelete && (
        <div className="flex flex-row items-center space-x-2">
          <Button
            variant="error"
            onClick={onDeleteComfirmed}
            pending={pending}
            disabled={pending}
            data-test={`${baseDataTest}-confirm-delete`}
          >
            Yes, Stop Sharing
          </Button>
          <i
            className="fas fa-x-circle text-xl text-gray-400 cursor-pointer hover:text-gray-600"
            onClick={onCancelDelete}
            data-test={`${baseDataTest}-cancel`}
          />
        </div>
      )}
      {!confirmDelete && (
        <i
          className="fas fa-x-circle text-xl text-error-30 cursor-pointer hover:text-red-700"
          onClick={onDelete}
          data-test={`${baseDataTest}-delete`}
        />
      )}
    </div>
  )
}
