import { useEffect, useState } from 'react'
import { utils, records } from '@ims/1edtech-frontend-common'

import { useSelector } from 'react-redux'
import { getNavigationParam } from 'lib/utils/navigation'
import IProduct from 'domains/products/models/IProduct'
import { PRODUCTS_RECORD } from 'lib/records/modules/products'
import { getFullProduct } from 'domains/products/workflows/getFullProduct'
import {
  AnyFormatType,
  AnySpecFormatType,
  ANY_FORMAT_TO_SPEC_FORMAT_MAP,
} from 'domains/formats/constants/formats'
import { rosteringToolSelector } from 'domains/products/selectors/rosteringTool'
import { RootState } from 'lib/store/rootReducer'

export default function useFullProductByFormat(
  props: any,
  productIdParam = 'id',
  formatParam = 'format',
): [IProduct | null, AnySpecFormatType | null, IProduct | null, boolean] {
  const [pending, setPending] = useState(true)
  const productId = utils.convertToInt(
    getNavigationParam(props, productIdParam),
  )
  const format = getNavigationParam(props, formatParam) as AnyFormatType

  const product = useSelector(
    records.entitiesSelectors.entityByIdSelector(PRODUCTS_RECORD, productId),
  ) as IProduct | null
  const rosteringTool = useSelector((state: RootState) =>
    rosteringToolSelector(productIdParam)(state, props),
  ) as IProduct | null

  useEffect(() => {
    const fetch = async () => {
      await getFullProduct(productId, format)
      setPending(false)
    }
    fetch()
  }, []) // eslint-disable-line

  return [
    product,
    ANY_FORMAT_TO_SPEC_FORMAT_MAP[format],
    rosteringTool,
    pending,
  ]
}
