import React from 'react'
import * as R from 'ramda'

import { ListPlainTextCell } from 'lib/components'
import IProduct from 'domains/products/models/IProduct'
import { RouteComponentProps } from 'react-router'
import { getProductFormatByName } from 'domains/products/utils/products'
import ProductCatalogExpandedRowCell from 'domains/products/components/ProductCatalog/ProductCatalogExpandedRowCell'

interface IProps extends IProduct, RouteComponentProps {
  isSubRow: boolean
  isSingleProduct: boolean
  isOpen?: boolean
}
export default function ProductCatalogComparisonCountCell(props: IProps) {
  const { isSubRow, isSingleProduct, format } = props
  if (isSubRow || isSingleProduct) {
    const productFormat = getProductFormatByName(format!)(props)
    return (
      <ProductCatalogExpandedRowCell {...props} borderRight={true}>
        <ListPlainTextCell>
          {R.propOr(0, 'comparisonCount', productFormat)}
        </ListPlainTextCell>
      </ProductCatalogExpandedRowCell>
    )
  }
  return (
    <ProductCatalogExpandedRowCell {...props} borderRight={true}>
      <ListPlainTextCell>{props.comparisonCount}</ListPlainTextCell>
    </ProductCatalogExpandedRowCell>
  )
}
