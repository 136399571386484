import React from 'react'

import ITrustedApplication from 'domains/trustedApps/models/ITrustedApplication'
import { DeleteCircle } from 'lib/components'

interface IProps extends ITrustedApplication {
  onClick: (app: ITrustedApplication) => any
}

export default function TrustedApplicationDeleteCell(props: IProps) {
  return (
    <DeleteCircle
      onClick={props.onClick}
      item={props}
      data-test={`delete-app-btn-${props.id}`}
      aria-label={`Delete app: ${props.title}`}
    />
  )
}
