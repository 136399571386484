import * as R from 'ramda'

import { findSpecServiceEndointAttributeByName } from 'domains/modeling/utils/restCOR1p1utils'
import IRestCModelServiceEndpointAttribute from 'domains/modeling/models/IRestCModelServiceEndpointAttribute'

export default function useRestCSpecServiceEndpointAttributeCell(props: any) {
  const specAttribute = findSpecServiceEndointAttributeByName(
    props.specification,
    props.service.name,
    props.endpoint.name,
    props.name,
  )

  const attributeIndex = R.findIndex(
    (attribute: IRestCModelServiceEndpointAttribute) => {
      if (props._internalId) {
        return R.propOr('', '_internalId', attribute) === props._internalId
      }
      return R.propEq('name', props.name, attribute)
    },
    props.endpoint.attributes,
  )
  const endpointFormPath = `endpoints[${props.endpointIndex}]`
  const attributeFormPath = `${endpointFormPath}.attributes[${attributeIndex}]`

  return [specAttribute, attributeIndex, attributeFormPath, endpointFormPath]
}
