import React from 'react'
import * as R from 'ramda'
import useQuery from 'lib/hooks/useQuery'

export const isOnPrintScreen = () =>
  R.pathOr('N/A', ['location', 'href'], window).includes('/print/')

export const usePrintOnMountEffect = (isLoading = false) => {
  const isDebugEnabled = useQuery().get('debug') === 'true'

  React.useEffect(() => {
    if (!isLoading && !isDebugEnabled && isOnPrintScreen()) {
      setTimeout(() => {
        window.print()
        window.close()
      }, 500)
    }
  }, [isLoading, isDebugEnabled])
}

export default usePrintOnMountEffect
