import { HTMLAttributes } from 'react'
import styled from 'styled-components'
import * as SS from 'styled-system'

import colors from 'lib/styles/colors'

export const variantStyle = SS.variant({
  key: 'buttonBadgeStyles',
})

export type ButtonBadgeVariants =
  | 'primary'
  | 'success'
  | 'error'
  | 'paused'
  | 'warning'
  | undefined
  | string

export const buttonBadgeStyles = {
  primary: {
    color: '#fff',
    backgroundColor: colors.primary,
  },
  success: {
    color: '#fff',
    backgroundColor: colors.success,
  },
  error: {
    color: '#fff',
    backgroundColor: colors.error,
  },
  paused: {
    color: '#fff',
    backgroundColor: colors.paused,
  },
  warning: {
    color: '#fff',
    backgroundColor: colors.warningAlt,
  },
  warningAlt: {
    color: '#fff',
    backgroundColor: colors.warningAlt,
  },
}

export interface IHtmlDivElementProps extends HTMLAttributes<HTMLDivElement> {} // eslint-disable-line
interface IProps extends IHtmlDivElementProps {
  variant?: ButtonBadgeVariants
}

// prettier-ignore
const ButtonBadge = styled.div<IProps>`
  position: absolute;
  top: -8px;
  right: 0px;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  font-weight: 700;
  line-height: 1;
  ${variantStyle}
`

ButtonBadge.displayName = 'ButtonBadge'
ButtonBadge.defaultProps = {
  variant: 'primary',
}

export default ButtonBadge
