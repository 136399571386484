import React from 'react'

import useFullRESTCModel from 'domains/modeling/hooks/useFullRESTCModel'
import { getNavigationParam } from 'lib/utils/navigation'
import { Spinner, View } from 'lib/components'
import useFullProductByFormat from 'domains/products/hooks/useFullProductByFormat'
import { DocumentTitle } from 'lib/hooks/useDocumentTitle'
import IRestCModel from 'domains/modeling/models/IRestCModel'
import IProduct from 'domains/products/models/IProduct'
import BaseProductDetailsBreadCrumbs from 'domains/products/components/BaseProductDetailsBreadCrumbs'
import useFullRESTCCharacterization from 'domains/characterizations/hooks/useFullRESTCCharacterization'
import IRestCModelCharacterization from 'domains/characterizations/models/IRestCModelCharacterization'
import ReportThreeTopSection from 'domains/reports/components/ReportThreeTopSection'
import CharacterizationsDetailsWidget from 'domains/characterizations/components/DistrictCharacterizationDetails/CharacterizationsDetailsWidget'
import RESTCharacterizationReportOverviewWidget from 'domains/characterizations/components/CharacterizationReport/RESTCharacterizationReportOverviewWidget'
import ITransformedRESTCharacterizationReport from 'domains/characterizations/models/ITransformedRESTCharacterizationReport'
import WidgetLineItem from 'domains/reports/components/WidgetLineItem'
import ReportStatusIcon from 'domains/reports/components/ReportStatusIcon'
import CharacterizationDetailsActionsWidget from 'domains/characterizations/components/CharacterizationReport/CharacterizationDetailsActionsWidget'
import { ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL } from 'domains/formats/constants/formats'
import ReportUnavailable from 'domains/reports/components/ReportUnavailable'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import ModelReportDetails from 'domains/modeling/components/ModelReportDetails'
import RESTCharacterizationReportServices from 'domains/characterizations/components/CharacterizationReport/RESTCharacterizationReportServices'
import IRestCSpec from 'domains/specifications/models/IRestCSpec'
import useORRESTCharacterizationAccordionHeaderStates from 'domains/reports/hooks/useORRESTCharacterizationAccordionHeaderStates'
import GenericError from 'domains/application/components/GenericError'
import DebugReportView from 'domains/reports/components/DebugReportView'

const WIDGET_HEIGHT = 536

export default function SupplierOR1P1RestCharacterizationReportScreen(
  props: any,
) {
  const format = getNavigationParam(props, 'format')
  const [productData, , , productPending] = useFullProductByFormat(props)
  const [modelData, specificationData, modelPending] = useFullRESTCModel(props)
  const [
    characterizationData,
    characterizationReportData,
    transformedReportData,
    characterizationPending,
  ] = useFullRESTCCharacterization(props)

  const [
    headerStates,
    getHeaderState,
    onToggleHeader,
    onExpandAll,
    onCollapseAll,
  ] = useORRESTCharacterizationAccordionHeaderStates(transformedReportData)

  if (productPending || modelPending || characterizationPending) {
    return <Spinner centered={true} />
  }

  if (!transformedReportData) {
    return <GenericError title="Characterization report not found." />
  }

  const product = productData as IProduct
  const model = modelData as IRestCModel
  const transformedReport = transformedReportData as ITransformedRESTCharacterizationReport
  const characterization = characterizationData as IRestCModelCharacterization
  const specification = specificationData as IRestCSpec

  return (
    <View variant="screen">
      <DocumentTitle title={`Product Characterization: ${product.name}`} />
      <BaseProductDetailsBreadCrumbs
        id={product.id}
        format={format}
        crumbs={[{ name: 'Characterization' }]}
      />
      <ReportThreeTopSection>
        <CharacterizationsDetailsWidget
          product={product}
          characterization={characterization}
          height={WIDGET_HEIGHT}
          hideType={true}
          extra={
            <View mt={4}>
              <WidgetLineItem
                label="Supports OAuth2"
                value={
                  model.oauth2Supported ? (
                    <ReportStatusIcon status="GREEN" fontSize={14} />
                  ) : (
                    <ReportStatusIcon status="UNSUPPORTED" fontSize={14} />
                  )
                }
              />
            </View>
          }
        />
        <RESTCharacterizationReportOverviewWidget
          report={transformedReport}
          height={WIDGET_HEIGHT}
        />
        <CharacterizationDetailsActionsWidget
          characterization={characterization}
          report={transformedReport}
          expandAll={onExpandAll}
          collapseAll={onCollapseAll}
          product={product}
          model={model}
          format={ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL}
          height={WIDGET_HEIGHT}
          shouldHidePrint={true}
        />
      </ReportThreeTopSection>

      <View variant="paper">
        <ReportUnavailable unavailable={transformedReport.unavailable} />
        <WidgetHeader
          dataTest="characterization-report-services"
          title="Characterization Report Details"
        />
        <ModelReportDetails
          model={model}
          format={ONER_V1P1_REST_CONSUMER_ROSTERING_OPTIONAL}
        />

        <RESTCharacterizationReportServices
          report={transformedReport}
          specification={specification}
          model={model}
          headerStates={headerStates}
          getHeaderState={getHeaderState}
          onToggleHeader={onToggleHeader}
        />
      </View>

      <DebugReportView
        rawReport={characterizationReportData}
        title="Charsys Report JSON"
      />
    </View>
  )
}
