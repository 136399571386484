import * as R from 'ramda'

import { OrgMembershipTypesDisplay } from 'domains/orgs/constants/organization'
import { utils } from '@ims/1edtech-frontend-common'
import IIMSApplicationVetting from 'domains/trustedApps/models/IIMSApplicationVetting'

export interface IIMSApplicationVersionCertification {
  imsCertTitle: string
  imsCertType: string
  imsCertDate: number
}

export interface IIMSApplicationVersion {
  version: string
  certs: IIMSApplicationVersionCertification[]
}

export default interface IIMSApplication {
  id: number
  productName: string
  productUrl: string
  productDescription: string
  imsOrgProductUrl: string
  productImage: string
  orgName: string
  orgExternalUrl: string
  orgImsUrl: string
  orgLogoUrl: string
  orgMemberType: OrgMembershipTypesDisplay
  versions: IIMSApplicationVersion[]
  vettings: IIMSApplicationVetting[]
}

export const isIMSAppVetted = (app: IIMSApplication) =>
  utils.hasValue(app.vettings)
export const isIMSAppCertified = (app: IIMSApplication) => {
  const versionHasCerts = (version: IIMSApplicationVersion) =>
    utils.hasValue(R.pathOr([], ['certs'], version))
  return R.any(versionHasCerts)(R.pathOr([], ['versions'], app))
}
export const hasTrustEdAppsSeal = (app: IIMSApplication) => {
  if (!app.versions) {
    return false
  }
  const dataPrivacyCerts = app.versions?.filter((version) => {
    const privacyCerts = version.certs?.filter(
      (cert) => cert.imsCertType === 'privacy_shield',
    )
    return privacyCerts.length > 0
  })
  return dataPrivacyCerts.length > 0
}

export const getIMSAppVettings = (
  app: IIMSApplication,
): IIMSApplicationVetting[] => {
  const vettings = R.pathOr([], ['vettings'], app) as IIMSApplicationVetting[]
  vettings.sort((v1, v2) => (v1.creationDate > v2.creationDate ? -1 : 1))
  return vettings
}
