import React, { PureComponent } from 'react'

import { View, Icon } from 'lib/components'
import DetailsTopWidget from 'domains/application/components/DetailsTopWidget'
import ITransformedCharacterizationCSVReport from 'domains/characterizations/models/ITransformedCharacterizationCSVReport'
import ITransformedCharacterizationCSVFile from 'domains/characterizations/models/ITransformedCharacterizationCSVFile'
import FileReportRow from 'domains/reports/components/FileReportRow'

interface IProps {
  report: ITransformedCharacterizationCSVReport
  height: number
}

export default class CSVCharacterizationReportOverviewWidget extends PureComponent<IProps> {
  renderFileReportRow = (file: ITransformedCharacterizationCSVFile) => (
    <FileReportRow
      key={file.fileLabel}
      fileLabel={file.fileLabel}
      report={this.props.report}
    />
  )

  renderFiles() {
    const { files } = this.props.report

    if (files.length < 1) {
      return (
        <View
          flexible="column-center"
          mt={4}
          data-test="characterization-overview-widget"
        >
          <View
            flexible="column-center"
            borderRadius="50%"
            bg="secondary"
            width={90}
            height={90}
          >
            <Icon
              fontSize={48}
              className="fas fa-question"
              color="white"
              data-test="unkown-status"
            />
          </View>
        </View>
      )
    }

    return files.map(this.renderFileReportRow)
  }

  render() {
    return (
      <DetailsTopWidget
        title="Report Overview"
        dataTest="characterization-overview-widget"
        height={this.props.height}
      >
        <View height="100%" overflowY="auto">
          {this.renderFiles()}
        </View>
      </DetailsTopWidget>
    )
  }
}
