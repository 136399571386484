import React from 'react'

import { View } from 'lib/components'
import WidgetHeader from 'domains/dashboard/components/WidgetHeader'
import ComparisonExpansionButton from 'domains/comparisons/components/ComparisonReport/ComparisonExpansionButton'

interface IProps {
  onExpand: () => any
  onCollapse: () => any
  'data-test': string
}

export default function ComparisonReportDetailsHeader(props: IProps) {
  return (
    <View flexible="row-space-between-wrap" mb={3}>
      <WidgetHeader
        title="Comparison Report Details"
        dataTest={props['data-test']}
      />
      <View flexible="row-wrap" mt={[2, 0]}>
        <ComparisonExpansionButton expand={true} onClick={props.onExpand} />
        <ComparisonExpansionButton expand={false} onClick={props.onCollapse} />
      </View>
    </View>
  )
}
