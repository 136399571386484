import React from 'react'
import * as R from 'ramda'

import { Text, View } from 'lib/components'
import { utils } from '@ims/1edtech-frontend-common'
import { ICommonCartridgeOrComposite } from 'domains/commonCartridge/models/lom/ICommonCartridgeLOMTechnical'
import CCCharacterizationReportLOMDetail from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMDetail'
import CCCharacterizationReportLOMKeyValue from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMKeyValue'
import CCCharacterizationReportLOMSourceValue from 'domains/commonCartridge/components/CommonCartridgeCharacterizationReport/lom/common/CCCharacterizationReportLOMSourceValue'
import { ShowMissingMetadataContext } from 'domains/commonCartridge/contexts/ShowMissingMetadataContext'

interface IProps {
  path: string
  title?: string
  data?: ICommonCartridgeOrComposite
}

export default function CCCharacterizationReportLOMRequirement(props: IProps) {
  const { showMissingMetadata } = React.useContext(ShowMissingMetadataContext)
  if (!showMissingMetadata && !utils.hasValue(props.data)) {
    return null
  }

  const orComposites = R.pathOr(
    showMissingMetadata ? [{}] : [],
    ['orComposite'],
    props.data,
  ) as ICommonCartridgeOrComposite[]

  const inner = (
    <View>
      {orComposites.map((oc, i) => (
        <View key={`${props.path}.orComposite.${i}`}>
          <Text fontWeight={700}>orComposite:</Text>
          <View ml={3}>
            <Text fontWeight={700}>type:</Text>
            <View ml={3} mb={2}>
              <CCCharacterizationReportLOMSourceValue
                path={
                  !utils.hasValue(oc)
                    ? `${props.path}.orComposite.type`
                    : `${props.path}.orComposite.${i}.type`
                }
                data={oc.type}
              />
            </View>

            <Text fontWeight={700}>name:</Text>
            <View ml={3} mb={2}>
              <CCCharacterizationReportLOMSourceValue
                data={oc.name}
                path={
                  !utils.hasValue(oc)
                    ? `${props.path}.orComposite.name`
                    : `${props.path}.orComposite.${i}.name`
                }
              />
            </View>

            <CCCharacterizationReportLOMKeyValue
              path={`${props.path}.version`}
              keyName="minimumVersion"
              keyValue={oc.minimumVersion}
              valueName="maximumVersion"
              value={oc.maximumVersion}
            />
          </View>
        </View>
      ))}
    </View>
  )

  if (utils.hasValue(props.title)) {
    return (
      <CCCharacterizationReportLOMDetail
        path={props.path}
        title={props.title!}
        value={inner}
      />
    )
  }

  return inner
}
