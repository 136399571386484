import React from 'react'

import IProduct from 'domains/products/models/IProduct'
import { View } from 'lib/components'
import colors from 'lib/styles/colors'

interface IProps extends IProduct {
  isSubRow?: boolean
  isSingleProduct?: boolean
  isOpen?: boolean
  isLast?: boolean
  borderLeft?: boolean
  borderRight?: boolean
  borderBottom?: boolean
  noCenter?: boolean
  children: any
}

export default function ProductCatalogExpandedRowCell(props: IProps) {
  if (!props.isSubRow && !props.borderBottom) {
    return props.children
  }
  return (
    <View
      bg={props.borderBottom ? '' : '#fff7ee'}
      width="100%"
      height={68}
      flexible={props.noCenter ? 'column-v-center' : 'column-center'}
      borderLeft={props.borderLeft ? `solid 1px ${colors.neutral}` : ''}
      borderRight={props.borderRight ? `solid 1px ${colors.neutral}` : ''}
      borderBottom={
        props.isLast || props.borderBottom
          ? `solid 1px ${colors.neutral}`
          : 'solid 2px white'
      }
    >
      {props.children}
    </View>
  )
}
