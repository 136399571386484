import React from 'react'
import * as R from 'ramda'
import BottomScrollListener, {
  useBottomScrollListener,
} from 'react-bottom-scroll-listener'
import { useSelector } from 'react-redux'
import { utils, records } from '@ims/1edtech-frontend-common'

import { getRecords, DEFAULT_PAGE_SIZE } from 'lib/records/workflows/getRecords'
import View from 'lib/components/View'
import Spinner from 'lib/components/Spinner'
import BlankSlate from 'lib/components/BlankSlate'

const Divider = () => <View height="2px" width="100%" ml={2} bg="background" />

interface IProps {
  blankSlateText?: string
  blankSlate?: any
  noBlankslate?: boolean
  record?: string
  secondaryRecord?: string
  parentRecordId?: string | number
  nonRecordItems?: any[]
  RowComponent: any
  rowData?: any
  params?: any
  noHorizontalScroll?: boolean
  reInitialize?: boolean
  refreshList?: any
  pageSize?: number
  noPagination?: boolean
  pending?: boolean
  query?: string
  filter?: string
  searchTemplate?: string // "name=@ OR filename=@" -> The query will replace all instances of `@`
  usePageBottomListener?: boolean
  noDivider?: boolean
  'data-test'?: string
}

export default function NonTableList(props: IProps) {
  const [page, setPage] = React.useState(1)
  const [currentQuery, setCurrentQuery] = React.useState(props.query)
  const [currentFilter, setCurrentFilter] = React.useState(props.filter)
  const { record = '', nonRecordItems, secondaryRecord } = props
  const isRecord = nonRecordItems === undefined
  const recordData = useSelector((state: any) =>
    props.parentRecordId
      ? records.relatedRecordsSelectors.relatedRecordSelector(
          record,
          () => props.parentRecordId,
        )(state, props)
      : records.recordsSelectors.recordSelector(record)(state),
  )

  const onGetRecords = async (force?: boolean, getPage?: number) => {
    if (!isRecord) {
      return
    }
    const {
      pageSize = DEFAULT_PAGE_SIZE,
      query = '',
      searchTemplate = '',
      params = {},
      filter = '',
    } = props
    const hasFilter = utils.hasValue(filter)
    const searchFilter = utils.hasValue(query)
      ? searchTemplate.replace(new RegExp('@@', 'g'), query)
      : ''
    const finalFilter = `${hasFilter ? filter : ''}${
      hasFilter && utils.hasValue(searchFilter) ? ' AND ' : ''
    }${searchFilter}`
    const filterParams = utils.hasValue(finalFilter)
      ? { filter: finalFilter }
      : {}
    await getRecords({
      record,
      parentRecordId: props.parentRecordId,
      params: { ...params, ...filterParams },
      force: force || utils.hasValue(filter),
      page: getPage || page,
      pageSize,
    })
    if(secondaryRecord){
        await getRecords({
            record: secondaryRecord
        })
    }
  }

  React.useEffect(() => {
    if (isRecord && !props.pending) {
      setPage(1)
      onGetRecords(true, 1)
    }
  }, [isRecord, props.parentRecordId, props.pending]) // eslint-disable-line

  React.useEffect(() => {
    if (isRecord && props.reInitialize) {
      onGetRecords(true)
    }
  }, [isRecord, props.reInitialize]) // eslint-disable-line

  React.useEffect(() => {
    if (isRecord) {
      onGetRecords(true)
    }
  }, [isRecord, props.refreshList]) // eslint-disable-line

  React.useEffect(() => {
    if (isRecord && props.query !== currentQuery) {
      setPage(1)
      setCurrentQuery(props.query)
      onGetRecords(true, 1)
    }
  }, [isRecord, props.query, currentQuery]) // eslint-disable-line

  React.useEffect(() => {
    if (isRecord && props.filter !== currentFilter) {
      setPage(1)
      setCurrentFilter(props.filter)
      onGetRecords(true, 1)
    }
  }, [isRecord, props.filter, currentFilter]) // eslint-disable-line

  const onGetNextPage = async () => {
    if (
      isRecord &&
      !props.noPagination &&
      R.pathOr(false, ['paging', 'nextPage'], recordData) !== false &&
      !recordData.loading &&
      !recordData.loadingNext
    ) {
      setPage(page + 1)
      await onGetRecords(false, page + 1)
    }
  }

  const items = props.nonRecordItems || R.pathOr([], ['items'], recordData)
  const {
    rowData = {},
    pending = false,
    noBlankslate = false,
    blankSlate,
  } = props

  const showLoading = isRecord && (recordData.loading || pending)
  const content = (
    <div data-test={props['data-test'] || 'list'} className="w-full">
      {showLoading && <Spinner centered={true} />}
      {!showLoading &&
        items.map((id, index) => {
          return (
            <div key={`${id}-${index}`}>
              <props.RowComponent
                key={`${id}-${index}`}
                id={id}
                index={index}
                {...rowData}
              />
              {!props.noDivider && index !== items.length - 1 && <Divider />}
            </div>
          )
        })}
      {recordData.loadingNext && <Spinner centered={true} />}
      {!utils.hasValue(items) &&
        !showLoading &&
        !noBlankslate &&
        blankSlate &&
        blankSlate}
      {!utils.hasValue(items) &&
        !showLoading &&
        !noBlankslate &&
        !blankSlate && (
          <BlankSlate title={props.blankSlateText || 'No results'} />
        )}
    </div>
  )

  const scrollRef = useBottomScrollListener(onGetNextPage, 300, 300)

  if (props.usePageBottomListener) {
    return (
      <BottomScrollListener
        triggerOnNoScroll={false}
        onBottom={onGetNextPage}
        offset={300}
        debounce={300}
      >
        {content}
      </BottomScrollListener>
    )
  }

  return (
    <View
      ref={scrollRef as any}
      height="100%"
      overflowY="auto"
      overflowX={props.noHorizontalScroll ? 'hidden' : 'auto'}
    >
      {content}
    </View>
  )
}
