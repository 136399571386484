import * as normalizr from 'normalizr'
import * as R from 'ramda'
import { records } from '@ims/1edtech-frontend-common'

export const TRUSTED_APPS_REFERENCE_DATA_RECORD = 'trustedAppsReferenceData'

const trustedAppsReferenceData: records.modules.IModule = {
  record: TRUSTED_APPS_REFERENCE_DATA_RECORD,
  schema: new normalizr.schema.Entity(
    TRUSTED_APPS_REFERENCE_DATA_RECORD,
    {},
    {
      idAttribute: () => '1',
      processStrategy: R.assoc('id', 1),
    },
  ),
  api: {
    create: () => 'trustedapps/referencedata',
    get: () => 'trustedapps/referencedata',
    getList: () => 'trustedapps/referencedata',
    update: () => 'trustedapps/referencedata',
    delete: () => 'trustedapps/referencedata',
  },
}

export default trustedAppsReferenceData
