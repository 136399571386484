import React, { useMemo } from 'react'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import * as Yup from 'yup'
import * as R from 'ramda'

import ITrustedApplication, {
  IFullTrustedApplication,
} from 'domains/trustedApps/models/ITrustedApplication'
import {
  ModalWrapper,
  WYSIWYGEditor,
  DatePicker,
  FormInput,
} from 'lib/components'
import { IAddTrustedAppSelection } from 'domains/trustedApps/components/AddTrustedApps/models/IAddTrustedAppSelection'
import FormLabel from 'lib/components/modern/FormLabel'
import { TRUSTED_APPS_RECORD } from 'lib/records/modules/trustedApplications'
import { showToast, SUCCESS_TOAST } from 'lib/utils/toast'
import { updateRecord } from 'lib/records/workflows/updateRecord'
import { utils } from '@ims/1edtech-frontend-common'
import { useQueryCustomQuestions } from '../hooks/useQueryCustomQuestions'
import { ERROR_TOAST } from '../../../lib/utils/toast'
import { GetTrustedAppSurveyQuestionsResponse } from '../models/ITrustedAppsCustomQuestions'
import FormSelect from 'lib/components/modern/Select/FormSelect'

type FormValues = {
  technicalNotes: string
  instructionalNotes: string
  licensingExpirationDate: Date | undefined
  licensingFees: string | undefined
  customQuestions: Record<number, { responseId: number }[]>
}

const validationSchema = Yup.object().shape({
  technicalNotes: Yup.string(),
  instructionalNotes: Yup.string(),
  licensingExpirationDate: Yup.date().nullable(),
  licensingFees: Yup.string().nullable(),
  customQuestions: Yup.object(),
})

type Props = {
  app?:
    | ITrustedApplication
    | IFullTrustedApplication
    | (IAddTrustedAppSelection & {
        title: string
        application?: any
      })
  isOpen: boolean
  onRequestClose: (saved?: boolean) => void
  onAppSaved?: (app: IAddTrustedAppSelection) => any
}

export default function EditAdditionalInfoModal(props: Props) {
  const [customQuestions, customQuestionsLoading] = useQueryCustomQuestions()

  const appTitle = `${
    props.app?.title ||
    R.pathOr('Application', ['application', 'productName'], props.app)
  }`.trim()

  const onSubmit = async (
    values: FormValues,
    bag: FormikHelpers<FormValues>,
  ) => {
    const app = {
      ...props.app,
      instructionalNotes: values.instructionalNotes,
      technicalNotes: values.technicalNotes,
      licensingFees: values.licensingFees,
      licensingExpirationDate: values.licensingExpirationDate
        ? utils.date
            .getMoment(values.licensingExpirationDate)
            .startOf('day')
            .tz('America/New_York')
            .valueOf()
        : null,
      surveyResults: customQuestions?.map((question) => ({
        id: question.id,
        questionText: question.questionText,
        questionType: question.questionType,
        hideFromTeachers: question.hideFromTeachers,
        responses: values.customQuestions[question.id].map((response) => ({
          id: response.responseId,
          responseText: question.responses?.find(
            (r) => r.id === response.responseId,
          )?.responseText,
        })),
      })),
    }

    if (props.onAppSaved) {
      props.onAppSaved(app as IAddTrustedAppSelection)
      bag.setSubmitting(false)
      props.onRequestClose(true)
    } else if (props.app) {
      const success = await updateRecord(
        TRUSTED_APPS_RECORD,
        (props.app as ITrustedApplication).id,
        app,
        true,
      )

      bag.setSubmitting(false)

      if (success) {
        props.onRequestClose(true)
        showToast(
          SUCCESS_TOAST,
          `Successfully saved additional info for ${appTitle}.`,
        )
      } else {
        showToast(ERROR_TOAST, 'Failed to update app. Please try again.')
      }
    }
  }

  const initialCustomQuestions = useMemo((): FormValues['customQuestions'] => {
    if (customQuestions) {
      return customQuestions.reduce((acc, s) => {
        const surveyResult = props.app?.surveyResults?.find(
          (r) => r.id === s.id,
        )

        if (surveyResult) {
          acc[s.id] = surveyResult.responses.map((r) => ({
            responseId: r.id,
          }))
        } else {
          acc[s.id] = []
        }

        return acc
      }, {} as FormValues['customQuestions'])
    }

    return {}
  }, [customQuestions, props.app?.surveyResults])

  const initialValues = useMemo(
    () => ({
      technicalNotes: props.app?.technicalNotes || '',
      instructionalNotes: props.app?.instructionalNotes || '',
      licensingExpirationDate: props.app?.licensingExpirationDate
        ? utils.date.getMoment(props.app.licensingExpirationDate).toDate()
        : undefined,
      licensingFees: props.app?.licensingFees,
      customQuestions: initialCustomQuestions,
    }),
    [initialCustomQuestions, props.app],
  )

  const pending = customQuestionsLoading

  const renderQuestion = (
    question: GetTrustedAppSurveyQuestionsResponse['questions'][number],
    formik: FormikProps<FormValues>,
  ) => {
    const responses = question.responses || []
    const selected = formik.values.customQuestions[question.id]?.[0]

    const setQuestionResponses = (
      responses: FormValues['customQuestions'][number],
    ) => {
      formik.setFieldValue('customQuestions', {
        ...formik.values.customQuestions,
        [question.id]: responses,
      })
    }

    return (
      <FormSelect
        label={question.questionText}
        name={`customQuestions.${question.id}`}
        selected={selected?.responseId}
        onChange={(value: any) => {
          if (value === null) {
            setQuestionResponses([])
          } else {
            setQuestionResponses([{ responseId: value }])
          }
        }}
        placeholder="Select"
        options={responses.map((s) => ({
          label: s.responseText,
          value: s.id,
        }))}
        multiple={false}
        extensible={false}
      />
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formik) => {
        return (
          <ModalWrapper
            isOpen={props.isOpen}
            title={`${appTitle} - Additional Info`}
            actions={
              pending
                ? []
                : [
                    {
                      text: 'Save',
                      variant: 'start',
                      extra: { type: 'submit' },
                      onClick: async () => {
                        await formik.submitForm()
                        formik.resetForm()
                      },
                    },
                    {
                      text: 'Cancel',
                      variant: 'neutral',
                      onClick: () => {
                        props.onRequestClose()
                        formik.resetForm()
                      },
                    },
                  ]
            }
            pending={formik.isSubmitting}
            maxWidth={['100%', 820, 900]}
          >
            <div className="flex flex-col space-y-3 bg-white pb-16">
              <div>
                <FormLabel htmlFor="instructionalNotes">
                  Technical Notes
                </FormLabel>
                <WYSIWYGEditor
                  name="technicalNotes"
                  value={formik.values.technicalNotes}
                  onChange={(value: string) =>
                    formik.setFieldValue('technicalNotes', value)
                  }
                  placeholder="Technical Notes, Resource URLs, etc."
                />
              </div>

              <div>
                <FormLabel htmlFor="instructionalNotes">
                  Instructional Notes
                </FormLabel>
                <WYSIWYGEditor
                  name="instructionalNotes"
                  value={formik.values.instructionalNotes}
                  onChange={(value: string) =>
                    formik.setFieldValue('instructionalNotes', value)
                  }
                  placeholder="Instructional Notes, Resource URLs, etc."
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex flex-col space-y-3">
                  <FormInput
                    label="Licensing Fees"
                    name="licensingFees"
                    placeholder="e.g. $100.00"
                    value={formik.values.licensingFees || ''}
                    handleChange={formik.handleChange}
                    valueDataTest="licensing-fee-field"
                  />
                  <FormLabel htmlFor="licensingExpirationDate">
                    Licensing Expiration Date
                  </FormLabel>
                  <DatePicker
                    date={formik.values.licensingExpirationDate!}
                    handleChange={(value: Date) => {
                      formik.setFieldValue('licensingExpirationDate', value)
                    }}
                    isClearable={true}
                    openToDate={utils.date.getMoment().toDate()}
                    data-test="licensing-expiration-date-input"
                  />
                </div>

                <div className="flex flex-col space-y-3">
                  {customQuestions?.map((question) =>
                    renderQuestion(question, formik),
                  )}
                </div>
              </div>
            </div>
          </ModalWrapper>
        )
      }}
    </Formik>
  )
}
