import React from 'react'

import { View } from 'lib/components'
import SearchMenuLineItem from 'lib/components/SearchMenuLineItem'
import IOrg from 'domains/orgs/models/IOrg'
import DistrictLogo from 'domains/orgs/components/DistrictLogo'

export default function OrgSearchMenuItem(props: IOrg) {
  return (
    <View
      flexible="row-space-between"
      hover="clickable-bg"
      data-test={`searched-org-${props.id}`}
    >
      <SearchMenuLineItem
        options={{
          value: (
            <DistrictLogo logoUrl={props.logourl} name={props.name} size={48} />
          ),
          width: [48],
        }}
      />
      <SearchMenuLineItem
        options={{ value: props.name, width: [170, 170, 300] }}
      />
    </View>
  )
}
