import React from 'react'
import * as R from 'ramda'

import { Button, ListPlainTextCell } from 'lib/components'
import {
  IN_MODELLING_MODELLING_STATUS,
  NOT_MODELLED_MODELLING_STATUS,
  MODELLED_MODELLING_STATUS,
} from 'domains/products/constants/products'
import {
  getProductModelingRoute,
  getProductStartModelingRoute,
} from 'domains/products/navigation/routes'
import {
  isRosteredByOtherProduct,
  productHasModelingStatus,
  isProductCharacterized,
} from 'domains/products/utils/products'
import ModeledByRosteringCell from 'domains/products/components/ModeledByRosteringCell'
import IProduct from 'domains/products/models/IProduct'
import { RouteComponentProps } from 'react-router'
import { getProductFormatModelId } from 'domains/products/utils/productByModel'
import isProductFormatExpired from 'domains/products/utils/isProductFormatExpired'
import { productHasMultipleSupportedFormats } from 'domains/products/utils/productHasMultipleSupportedFormats'
import { expandProductsByFormats } from 'domains/products/utils/expandProductsByFormats'
import { isProductFormatSupported } from 'domains/products/utils/isProductFormatSupported'
import { getFirstSupportedProductFormat } from 'domains/products/utils/getFirstSupportedProductFormat'
import ListItalicTextOnlyCell from 'domains/products/components/ListItalicTextOnlyCell'
import ProductCatalogExpandedRowCell from 'domains/products/components/ProductCatalog/ProductCatalogExpandedRowCell'
import { ONER_V1P1_CSV_ROSTERING_IMPORT_BULK } from 'domains/formats/constants/formats'
import { createRestModel } from 'domains/modeling/workflows/createRestModel'
import { OR1P1Subsets } from 'domains/specifications/constants/oneRoster1p1FileSets'
import { getModelsForFormat } from 'domains/modeling/workflows/getModelsForFormat'

type Props = {
  isSubRow: boolean
  isSingleProduct: boolean
  isOpen?: boolean
} & IProduct &
  RouteComponentProps

export default function ProductCatalogModelingCell(props: Props) {
  const [pending, setPending] = React.useState(false)
  const format = props.format || getFirstSupportedProductFormat(props)!

  const onGoToModeling = async () => {
    const notModelled = productHasModelingStatus(NOT_MODELLED_MODELLING_STATUS)(
      props,
      format,
    )
    if (notModelled) {
      const startModelingRoute = getProductStartModelingRoute(props.id, format)
      if (format !== ONER_V1P1_CSV_ROSTERING_IMPORT_BULK) {
        setPending(true)
        const { success, data } = await getModelsForFormat(format)

        // Create model and go to model screen
        if (!success || data.length < 1) {
          const model = await createRestModel(
            product.id,
            format,
            OR1P1Subsets.CLASS_ROSTERS_W_DEMOGRAPHICS_SUBSET,
          )
          if (model) {
            props.history.push(getProductModelingRoute(props.id, model.id))
            return
          }
        }

        props.history.push(startModelingRoute)
      } else {
        props.history.push(startModelingRoute)
      }
    } else {
      props.history.push(
        getProductModelingRoute(
          props.id,
          getProductFormatModelId(props, format),
        ),
      )
    }
  }

  const product = props
  const { id, isSubRow, isSingleProduct, isOpen } = props
  if (isRosteredByOtherProduct(props)) {
    return (
      <ProductCatalogExpandedRowCell {...props}>
        <ModeledByRosteringCell
          id={id}
          format={format}
          linkTextProps={{
            color: 'primary',
            fontWeight: isOpen ? 700 : 500,
          }}
        />
      </ProductCatalogExpandedRowCell>
    )
  }

  if (
    !isSingleProduct &&
    !isSubRow &&
    productHasMultipleSupportedFormats(product)
  ) {
    const supportedFormats = expandProductsByFormats(product)
    const modeledCount = supportedFormats.reduce(
      (agg, p) => (isProductCharacterized(p, p.format!) ? agg + 1 : agg),
      0,
    )
    return (
      <ProductCatalogExpandedRowCell {...props}>
        <ListPlainTextCell>
          {modeledCount}/{supportedFormats.length}
        </ListPlainTextCell>
      </ProductCatalogExpandedRowCell>
    )
  }

  const supported = isProductFormatSupported(format!)
  if (!supported) {
    return (
      <ProductCatalogExpandedRowCell {...props}>
        <ListItalicTextOnlyCell>
          Format not yet supported
        </ListItalicTextOnlyCell>
      </ProductCatalogExpandedRowCell>
    )
  }

  let variant = 'start'
  let buttonText = 'Begin'
  if (isProductCharacterized(product, format)) {
    variant = 'complete'
    buttonText = 'Complete'
  } else if (
    productHasModelingStatus(IN_MODELLING_MODELLING_STATUS)(product, format) ||
    productHasModelingStatus(MODELLED_MODELLING_STATUS)(product, format)
  ) {
    variant = 'secondary'
    buttonText = 'Incomplete'
  }

  const isExpired = isProductFormatExpired(product, format)
  const isProductRemoved = R.pathOr(false, ['removed'], props)
  const disabled = isExpired || isProductRemoved

  return (
    <ProductCatalogExpandedRowCell {...props}>
      <Button
        variant={disabled || pending ? 'disabled' : variant}
        onClick={onGoToModeling}
        disabled={disabled}
        pending={pending}
        data-test={`product-modeling-${product.id}-${format}`}
      >
        {buttonText}
      </Button>
    </ProductCatalogExpandedRowCell>
  )
}
