import React from 'react'
import { Text, FormInput } from 'lib/components/'
import FormSelect from 'lib/components/modern/Select/FormSelect'

type IProps = {
    question: string
    questionId: string
    answerOptions: any[]
    onSelect: (answer: any,answerText: string, questionId: string) => void
    onNoteChange: (note: string, questionId: string) => void
}

export default function RubricQuestion(props: IProps) {
    const [note, setNote] = React.useState('')
    const [answer, setAnswer] = React.useState<number>()
    const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNote(e.target.value)
        props.onNoteChange(e.target.value, props.questionId)
    }
    const handleSelect = (e: any) => {
        setAnswer(e)
        props.onSelect(e, props.question, props.questionId)
    }

    return (
        <div className="flex flex-col items-start">
            <Text fontSize={16}>{props.question}</Text>
            <div className="w-1/2 space-x-8 p-4 my-4 flex items-end bg-gray-100 rounded-md h-24">
                <FormSelect
                    name={`question ${props.questionId}`}
                    selected={answer}
                    label="Answers"
                    aria-label={`${props.question}`}
                    placeholder="Please select an option"
                    options={props.answerOptions}
                    onChange={handleSelect}
                    small
                />
                <FormInput
                    name={`question.${props.questionId}`}
                    value={note}
                    valueDataTest={`data-test-${props.questionId}`}
                    placeholder="Notes"
                    aria-label={`${props.questionId} Notes Input`}
                    handleChange={handleNoteChange}
                    noMargin
                />
            </div>
        </div>
    )
}
