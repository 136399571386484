import React, { PureComponent } from 'react'

import { BreadCrumbs } from 'lib/components'
import {
  DASHBOARD_ROUTE,
  PRODUCT_CATALOG_ROUTE,
  getRoute,
} from 'domains/application/navigation/routes'
import { IBreadCrumb } from 'lib/components/BreadCrumbs'
import { ONEROSTER_BASE_NAV_ROUTE } from 'domains/specifications/constants/specNavigation'
import { utils } from '@ims/1edtech-frontend-common'

interface IProps {
  crumbs?: IBreadCrumb[]
}

export default class BaseProductsBreadCrumbs extends PureComponent<IProps> {
  render() {
    const { crumbs = [], ...rest } = this.props
    return (
      <BreadCrumbs
        {...rest}
        crumbs={[
          {
            name: 'Home',
            route: getRoute(DASHBOARD_ROUTE),
          },
          {
            name: 'OneRoster Check',
            route: getRoute(`/${ONEROSTER_BASE_NAV_ROUTE}${DASHBOARD_ROUTE}`),
          },
          {
            name: 'Product Catalog',
            route: !utils.hasValue(crumbs)
              ? undefined
              : getRoute(
                  `/${ONEROSTER_BASE_NAV_ROUTE}${PRODUCT_CATALOG_ROUTE}`,
                ),
          },
          ...crumbs,
        ]}
      />
    )
  }
}
