import { utils } from '@ims/1edtech-frontend-common'

import { isRosteredByOtherProduct } from 'domains/products/utils/products'
import { rosteringToolSelector } from 'domains/products/selectors/rosteringTool'
import store from 'lib/store'
import IProduct from 'domains/products/models/IProduct'

export const sortProductsByRosteringMethod = (sortAscending: boolean) => (
  productA: IProduct,
  productB: IProduct,
) => {
  const state = store.getState()
  const aIsRostered = isRosteredByOtherProduct(productA)
  const aProduct = aIsRostered
    ? rosteringToolSelector('id')(state, { id: productA.id })
    : { name: 'Direct' }

  const bIsRostered = isRosteredByOtherProduct(productB)
  const bProduct = bIsRostered
    ? rosteringToolSelector('id')(state, { id: productB.id })
    : { name: 'Direct' }

  const sortByName = utils.comparePropForSort(sortAscending, 'name')
  if (aProduct.name === bProduct.name) {
    return sortByName(productA, productB)
  }

  return sortByName(aProduct, bProduct)
}
